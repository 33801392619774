import { View, Text } from '@react-pdf/renderer';
import { DemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import { styles } from './styles';

interface ReportHeaderProps {
  state: DemurragePageState;
}

export const ReportHeader = ({ state }: ReportHeaderProps) => (
  <View
    fixed
    style={{ paddingBottom: 10 }}
    render={({ pageNumber }) => (
      <View>
        <View style={styles.flexRow}>
          <Text style={styles.title}>Laytime Calculation</Text>
          <Text style={{ fontSize: 10 }}>{`Page ${pageNumber}`}</Text>
        </View>
        <View
          style={{
            ...styles.flexRow,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid'
          }}
        >
          <Text style={{ fontSize: 10, paddingRight: 10 }}>M/V</Text>
          <Text style={{ fontSize: 10 }}>{state.demurrageDeal?.vessel?.name}</Text>
        </View>
      </View>
    )}
  />
);

ReportHeader.displayName = 'ReportHeader';
