/* eslint-disable indent */
import { useLazyQuery } from '@apollo/client';
import { AlertColor } from '@mui/material';
import { GetDealOutput, GET_DEAL } from 'api';
import { GetShexConditionsOutput, GET_SHEX_CONDITIONS } from 'api/graphql/shexCondition';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useSaveLaytimeCalculation } from 'hooks/useSaveLaytimeCalculation';
import {
  dealModel,
  dealPortModel,
  interruptionModel,
  laytimeCalculationCalculatedFields,
  laytimeCalculationModel
} from 'model';
import React, { useState } from 'react';

import { createContext, Dispatch, ReactNode, useCallback, useContext, useEffect } from 'react';
import { getStringTimeFromMinutes } from 'shared/utils';
import { getPort } from 'shared/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useImmerReducer } from 'use-immer';
import { ShexCondition } from './DemurrageContent/ShexConditionsEditor/ShexConditionsEditor';
import { getCalcPosition, getDemurrageCalculation } from './utils';
import { useRecoilState } from 'recoil';
import { pageState } from 'state';
import { useTranslation } from 'react-i18next';
import { TXT_LAYTIME_CALCULATION } from '../../../../../shared/translations';
import _ from 'lodash';

export type DemurrageTabName = 'loading' | 'unloading';

export const getPropName = (demurrageTabName: DemurrageTabName) =>
  demurrageTabName === 'loading' ? 'loadingCalculations' : 'unloadingCalculations';

export interface DemurragePageState {
  selectedTab: DemurrageTabName;
  selectedLaytimeCalculationId?: string;
  selectedLaytimeCalculation?: laytimeCalculationModel;
  demurrageDeal?: dealModel;
  saving?: boolean;
  calculationsPendingToBeSaved: Record<string, string>;
  selectedPort?: dealPortModel;
  interruptions?: interruptionModel[];
  shexConditions?: ShexCondition[];
  fetch: () => void;
}

export const COUNTER_PARTY = 'Counterparty';
export const LOADING_PORT = 'Loading Port name';
export const UNLOADING_PORT = 'Unloading Port name';
export const DEFAULT_DEMURRAGE = 3000;
export const DEFAULT_STATUS = 'AGREED';

const defaultState: DemurragePageState = {
  selectedTab: 'loading',
  calculationsPendingToBeSaved: {},
  fetch: () => {}
};

export const DemurragePageStateContext = createContext<DemurragePageState>(defaultState);

export const DemurragePageDispatchContext = createContext<Dispatch<DemurragePageAction> | undefined>(undefined);

// Action Types
type SetDealAction = {
  type: 'SET_DEAL';
  deal: dealModel;
};

type SetSelectedTabAction = {
  type: 'SET_SELECTED_TAB';
  selectedTab: DemurrageTabName;
};

type SetSelectedLaytimeCalculationIdAction = {
  type: 'SET_SELECTED_LAYTIME_CALCULATION_ID';
  selectedLaytimeCalculationId: string;
};

type DeleteDemurrageCalculationAction = {
  type: 'DELETE_DEMURRAGE_CALCULATION';
  tabName: DemurrageTabName;
  calculationId: string;
};

type UpdateLaytimeCalculationAction = {
  type: 'UPDATE_LAYTIME_CALCULATION';
  calculation: laytimeCalculationModel;
};

type CreateDemurrageCalculationAction = {
  type: 'CREATE_DEMURRAGE_CALCULATION';
  tabName: DemurrageTabName;
  calculation: laytimeCalculationModel;
};

type SetPropertyAction = {
  type: 'SET_PROPERTY';
  name: keyof laytimeCalculationModel;
  value: any;
};

type SetSavingAction = {
  type: 'SET_SAVING';
  value: boolean;
};

type DisplayToastAction = {
  type: 'DISPLAY_TOAST';
  id: string;
  message: string;
  severity: AlertColor;
};

type HideToastAction = {
  type: 'HIDE_TOAST';
  id: string;
};

type RemoveCalculationsFromPendingToBeSavedAction = {
  type: 'REMOVE_CALCULATIONS_FROM_PENDING';
};

type SetInterruptionsAction = {
  type: 'SET_INTERRUPTIONS';
  interruptions: interruptionModel[];
};

type SetShexConditionsAction = {
  type: 'SET_SHEX_CONDITIONS';
  conditions: ShexCondition[];
};

type RefreshCalculationAfterSaveAction = {
  type: 'REFRESH_CALCULATION_AFTER_SAVE';
  payload: laytimeCalculationCalculatedFields;
};

// Action Creators
export const setDeal = (deal: dealModel): SetDealAction => ({
  type: 'SET_DEAL',
  deal
});

export const setSelectedTab = (selectedTab: DemurrageTabName): SetSelectedTabAction => ({
  type: 'SET_SELECTED_TAB',
  selectedTab
});

export const updateLaytimeCalculation = (calculation: laytimeCalculationModel): UpdateLaytimeCalculationAction => ({
  type: 'UPDATE_LAYTIME_CALCULATION',
  calculation
});

export const setSelectedLaytimeCalculationId = (
  selectedLaytimeCalculationId: string
): SetSelectedLaytimeCalculationIdAction => ({
  type: 'SET_SELECTED_LAYTIME_CALCULATION_ID',
  selectedLaytimeCalculationId
});

export const deleteDemurrageCalculation = (
  tabName: DemurrageTabName,
  calculationId: string
): DeleteDemurrageCalculationAction => ({
  type: 'DELETE_DEMURRAGE_CALCULATION',
  calculationId,
  tabName
});

export const createDemurrageCalculation = (
  tabName: DemurrageTabName,
  calculation: laytimeCalculationModel
): CreateDemurrageCalculationAction => ({
  type: 'CREATE_DEMURRAGE_CALCULATION',
  calculation,
  tabName
});

export const setProperty = (name: keyof laytimeCalculationModel, value: any): SetPropertyAction => ({
  type: 'SET_PROPERTY',
  name,
  value
});

export const setSaving = (value: boolean): SetSavingAction => ({
  type: 'SET_SAVING',
  value
});

export const refreshLaytimeCalculationsAfterSave = (
  payload: laytimeCalculationCalculatedFields
): RefreshCalculationAfterSaveAction => ({
  type: 'REFRESH_CALCULATION_AFTER_SAVE',
  payload
});

export const displayToast = (id: string, message: string, severity: AlertColor): DisplayToastAction => ({
  type: 'DISPLAY_TOAST',
  id,
  message,
  severity
});

export const hideToast = (id: string): HideToastAction => ({
  type: 'HIDE_TOAST',
  id
});

export const removeCalculationsFromPendingToBeSaved = (): RemoveCalculationsFromPendingToBeSavedAction => ({
  type: 'REMOVE_CALCULATIONS_FROM_PENDING'
});

export const setInterruptions = (interruptions: interruptionModel[]): SetInterruptionsAction => ({
  type: 'SET_INTERRUPTIONS',
  interruptions
});

export const setShexConditions = (conditions: ShexCondition[]): SetShexConditionsAction => ({
  type: 'SET_SHEX_CONDITIONS',
  conditions
});

// Reducer
export type DemurragePageAction =
  | SetDealAction
  | SetSelectedTabAction
  | SetSelectedLaytimeCalculationIdAction
  | DeleteDemurrageCalculationAction
  | CreateDemurrageCalculationAction
  | UpdateLaytimeCalculationAction
  | SetPropertyAction
  | SetSavingAction
  | DisplayToastAction
  | HideToastAction
  | SetInterruptionsAction
  | RefreshCalculationAfterSaveAction
  | SetShexConditionsAction
  | RemoveCalculationsFromPendingToBeSavedAction;

const reducer = (state: DemurragePageState, action: DemurragePageAction) => {
  switch (action.type) {
    case 'SET_DEAL':
      state.demurrageDeal = action.deal;
      if (
        !state.selectedLaytimeCalculationId &&
        action.deal.loadingCalculations &&
        action.deal.loadingCalculations.length > 0
      ) {
        state.selectedLaytimeCalculationId = action.deal.loadingCalculations[0].id;
      }
      break;
    case 'SET_SELECTED_TAB':
      state.selectedTab = action.selectedTab;
      const calcs =
        action.selectedTab === 'loading'
          ? state.demurrageDeal?.loadingCalculations
          : state.demurrageDeal?.unloadingCalculations;
      state.selectedLaytimeCalculationId = calcs && calcs.length > 0 ? calcs[0].id : undefined;
      break;
    case 'SET_SELECTED_LAYTIME_CALCULATION_ID':
      state.selectedLaytimeCalculationId = action.selectedLaytimeCalculationId;
      break;
    case 'UPDATE_LAYTIME_CALCULATION': {
      if (!state.demurrageDeal || !action.calculation.id) {
        return;
      }
      const calculationIndex = getCalcPosition(action.calculation.id, state.demurrageDeal);
      if (calculationIndex !== undefined && calculationIndex.index >= 0) {
        state.demurrageDeal[calculationIndex.portType]![calculationIndex.index] = { ...action.calculation };
      }
      break;
    }
    case 'CREATE_DEMURRAGE_CALCULATION':
      if (!state.demurrageDeal) {
        return;
      }
      const createCalculations = state.demurrageDeal[getPropName(action.tabName)];
      if (!createCalculations) {
        state.demurrageDeal[getPropName(action.tabName)] = [];
      }

      state.demurrageDeal[getPropName(action.tabName)]?.push(action.calculation);
      state.selectedLaytimeCalculationId = action.calculation.id;

      break;
    case 'DELETE_DEMURRAGE_CALCULATION':
      if (!state.demurrageDeal) {
        return;
      }
      const p = getCalcPosition(action.calculationId, state.demurrageDeal);

      if (p) {
        const calculations = state.demurrageDeal[p!.portType];
        if (calculations) {
          if (p?.index !== undefined && p.index !== -1) {
            state.demurrageDeal[p!.portType]!.splice(p.index, 1);
          }
        }
      }

      break;
    case 'SET_PROPERTY': {
      if (!state.demurrageDeal || !state.selectedLaytimeCalculationId) {
        return;
      }
      const calculationIndex = getCalcPosition(state.selectedLaytimeCalculationId, state.demurrageDeal);
      if (calculationIndex !== undefined && calculationIndex.index >= 0) {
        const prevValue = state.demurrageDeal[calculationIndex.portType]![calculationIndex.index][action.name];
        if (prevValue !== action.value) {
          state.demurrageDeal[calculationIndex.portType]![calculationIndex.index][action.name] = action.value;
          state.calculationsPendingToBeSaved[state.selectedLaytimeCalculationId] = state.selectedLaytimeCalculationId;
        }
      }
      break;
    }
    case 'SET_SAVING':
      state.saving = action.value;
      break;
    case 'REMOVE_CALCULATIONS_FROM_PENDING':
      state.calculationsPendingToBeSaved = {};
      break;
    case 'SET_INTERRUPTIONS':
      state.interruptions = action.interruptions;
      break;
    case 'SET_SHEX_CONDITIONS':
      state.shexConditions = action.conditions;
      break;
    case 'REFRESH_CALCULATION_AFTER_SAVE':
      if (!state.demurrageDeal) {
        return;
      }

      const calcPositionState = getCalcPosition(action.payload.id!, state.demurrageDeal);
      if (calcPositionState) {
        const oldState = state.demurrageDeal![calcPositionState.portType]![calcPositionState.index];
        const refreshCalculation: laytimeCalculationModel = {
          ...oldState,
          ...action.payload
        };
        state.demurrageDeal![calcPositionState.portType]![calcPositionState.index] = refreshCalculation;
      }
      break;
  }
  return state;
};

export const useDemurragePageState = () => {
  const state = useContext(DemurragePageStateContext);
  if (!state) {
    throw new Error('useDemurragePageState must be used inside a DemurragePageStateProvider');
  }
  return state;
};

export const useDemurragePageDispatch = () => {
  const state = useContext(DemurragePageDispatchContext);
  if (!state) {
    throw new Error('useDemurragePageDispatch must be used inside a DemurragePageDispatchProvider');
  }
  return state;
};

interface DemurragePageProviderProps {
  dealId?: string;
  children: ReactNode;
}
export const DemurragePageProvider = ({ children, dealId }: DemurragePageProviderProps) => {
  const [state, dispatch] = useImmerReducer(reducer, { ...defaultState });
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [page, setPage] = useRecoilState(pageState);
  const { t } = useTranslation();
  const [dealName, setDealName] = useState('');
  const [selectedCalculation, setSelectedCalculation] = useState<laytimeCalculationModel>();
  const [getDeal] = useLazyQuery<GetDealOutput>(GET_DEAL, {
    fetchPolicy: 'network-only',
    variables: {
      dealId
    },
    onCompleted: (data) => {
      if (!data || !data.deal) {
        return;
      }
      const deal = data.deal;
      dispatch(setDeal(deal));

      if (!deal) {
        return null;
      }

      let tempDealName: string = deal?.name ?? '';

      if (deal.isQuote) {
        tempDealName = `${deal.vessel?.name ?? ''} [${tempDealName}]`;
      }

      if (deal.loadingCalculations && !_.isEmpty(deal.loadingCalculations) && deal.loadingCalculations[0].id) {
        dispatch(setSelectedTab('loading'));
        dispatch(setSelectedLaytimeCalculationId(deal.loadingCalculations[0].id));
      } else if (
        deal.unloadingCalculations &&
        !_.isEmpty(deal.unloadingCalculations) &&
        deal.unloadingCalculations[0].id
      ) {
        dispatch(setSelectedTab('unloading'));
        dispatch(setSelectedLaytimeCalculationId(deal.unloadingCalculations[0].id));
      }

      setDealName(() => tempDealName);
    },
    onError: apolloErrorHandler
  });
  const { handleSave: handleCalculationSave, saving } = useSaveLaytimeCalculation(dispatch);

  const saveCalculations = useCallback(async (recordsForSaving: Record<string, string>, deal: dealModel) => {
    Object.keys(recordsForSaving).map((id) => {
      const calculation = deal ? getDemurrageCalculation(id, deal) : undefined;
      handleCalculationSave(calculation);
    });
    dispatch(removeCalculationsFromPendingToBeSaved());
  }, []);

  useEffect(() => {
    if (!state.selectedLaytimeCalculationId || !state.demurrageDeal) {
      return;
    }
    const calc = getDemurrageCalculation(state.selectedLaytimeCalculationId, state.demurrageDeal);
    setSelectedCalculation(() => ({ ...calc }));
  }, [state.selectedLaytimeCalculationId, state.demurrageDeal]);

  useEffect(() => {
    getDeal();
  }, [dealId]);

  useEffect(() => {
    dispatch(setSaving(saving));
  }, [saving]);

  useEffect(() => {
    setPage({
      ...page,
      title: `${t(TXT_LAYTIME_CALCULATION)} — ${dealName}`.toUpperCase()
    });
  }, [t, dealName]);

  useDeepCompareEffect(() => {
    saveCalculations(state.calculationsPendingToBeSaved, state.demurrageDeal!);
  }, [state.calculationsPendingToBeSaved]);

  const getFilter = () => {
    const result = {
      laytimeCalculationId: {
        eq: state.selectedLaytimeCalculationId || '00000000-0000-0000-0000-000000000000'
      }
    };
    return result;
  };

  const [getShexConditions] = useLazyQuery<GetShexConditionsOutput>(GET_SHEX_CONDITIONS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter()
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      const conditions = (data.shexConditions || []).map((x) => ({
        ...x,
        fromTime: getStringTimeFromMinutes(x.fromTime),
        untilTime: getStringTimeFromMinutes(x.untilTime)
      }));

      dispatch(setShexConditions(conditions));
    }
  });

  React.useEffect(() => {
    getShexConditions();
  }, []);

  const selectedPort = getPort(state.demurrageDeal, selectedCalculation);

  return (
    <DemurragePageStateContext.Provider
      value={{ ...state, selectedLaytimeCalculation: selectedCalculation, selectedPort, fetch: getDeal }}
    >
      <DemurragePageDispatchContext.Provider value={dispatch}>{children}</DemurragePageDispatchContext.Provider>
    </DemurragePageStateContext.Provider>
  );
};
