import { Alert, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CounterPartySelector, DealWizardContext, NumberTextField, PortSelector } from '..';
import { counterPartyModel, portModel } from '../../model';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_BILL_OF_LADING_DATE,
  TXT_VALID_NOR,
  TXT_LAYCAN_END,
  TXT_LAYCAN_START,
  TXT_QUANTITY,
  TXT_NOR_TENDERED,
  TXT_LOADING_COMMENCED,
  TXT_LOADING_COMPLETED,
  TXT_UNLOADING_COMMENCED,
  TXT_UNLOADING_COMPLETED,
  TXT_PORT,
  TXT_VESSEL_OUTSIDE_LAYCAN,
  TXT_AGENT
} from '../../../../../shared/translations';
import { getDateFormat, getDateTimeFormat, isVesselOutsideLaycan } from 'shared/utils';
import { useContext, useEffect, useState } from 'react';
import { DateTimePicker } from 'components/DateTimePicker/DateTimePicker';
import moment, { Moment } from 'moment';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AddDealInput, AddDealPortInput } from 'api';
import _ from 'lodash';

export interface DealPortEditorContentProps {
  index: number;
  isLoadingPort?: boolean;
}

const getLatestDate = (latestDate: Date | undefined, newDate: Date | undefined): Date | undefined => {
  if (!latestDate) {
    return newDate;
  } else {
    if (newDate && newDate > latestDate) {
      return newDate;
    } else {
      return latestDate;
    }
  }
};

export const DealPortEditorContent = (props: DealPortEditorContentProps) => {
  const { t } = useTranslation();
  const { isDisabled } = useContext(DealWizardContext);
  const [showOutsideLaycanValidation, setShowOutsideLaycanValidation] = useState(false);
  const { control, setValue } = useFormContext<AddDealInput>();

  const watchMassUnit = useWatch({
    control,
    name: 'massUnit'
  });

  const ports = !!props.isLoadingPort ? 'loadingPorts' : 'unloadingPorts';

  const watchPort = useWatch({
    control,
    name: `${ports}.${props.index}.port`
  });

  const watchQuantity = useWatch({
    control,
    name: `${ports}.${props.index}.quantity`
  });

  const watchBOL = useWatch({
    control,
    name: `${ports}.${props.index}.billOfLadingDate`
  });

  const watchValidNor = useWatch({
    control,
    name: `${ports}.${props.index}.validNor`
  });

  const watchLaycanStart = useWatch({
    control,
    name: `${ports}.${props.index}.laycanStartDate`
  });

  const watchLaycanEnd = useWatch({
    control,
    name: `${ports}.${props.index}.laycanEndDate`
  });

  const watchNORTendered = useWatch({
    control,
    name: `${ports}.${props.index}.norTenderedDate`
  });

  const watchCommenced = useWatch({
    control,
    name: `${ports}.${props.index}.commencedDate`
  });

  const watchCompleted = useWatch({
    control,
    name: `${ports}.${props.index}.completedDate`
  });

  const watchAgent = useWatch({
    control,
    name: `${ports}.${props.index}.agent`
  });

  const watchedDates = [
    watchLaycanEnd,
    watchLaycanStart,
    watchBOL,
    watchCommenced,
    watchNORTendered,
    watchValidNor
  ];

  const getPortLatestDate = (): Date | undefined => watchedDates.reduce((prev, curr) => getLatestDate(prev, curr), undefined);

  const [latestDate, setLatestDate] = useState<Date | undefined>(getPortLatestDate());

  type DealPortDates = Pick<
    AddDealPortInput,
    | 'billOfLadingDate'
    | 'commencedDate'
    | 'completedDate'
    | 'laycanEndDate'
    | 'laycanStartDate'
    | 'norTenderedDate'
    | 'validNor'
  >;

  const handleCounterparty = (cParty?: counterPartyModel) => {
    setValue(`${ports}.${props.index}.agentId` as const, cParty?.id);
    setValue(`${ports}.${props.index}.agent` as const, cParty);
  };

  const handlePortChanged = (port: portModel | null) => {
    const newPort = port === null ? undefined : port;
    setValue(`${ports}.${props.index}.portId`, newPort?.id, {
      shouldValidate: true
    });
    setValue(`${ports}.${props.index}.port`, newPort);

    handleCounterparty(undefined);
  };

  const handleDate = (field: keyof DealPortDates) => {
    return (value?: Moment) => {
      setValue(`${ports}.${props.index}.${field}`, value?.toDate());
      setLatestDate(() => value?.toDate());
    };
  };

  let lblCommenced = capitalizeFirstLetterEveryword(
    t(props.isLoadingPort ? TXT_LOADING_COMMENCED : TXT_UNLOADING_COMMENCED)
  );
  let lblCompleted = capitalizeFirstLetterEveryword(
    t(props.isLoadingPort ? TXT_LOADING_COMPLETED : TXT_UNLOADING_COMPLETED)
  );

  const dateFormat = getDateFormat();
  const dateTimeFormat = getDateTimeFormat();
  const lblBOL = `${capitalizeFirstLetterEveryword(t(TXT_BILL_OF_LADING_DATE))} (${dateFormat})`;
  const lblValidNor = `${capitalizeFirstLetterEveryword(t(TXT_VALID_NOR))} (${dateTimeFormat})`;
  const lblLaycanStart = `${capitalizeFirstLetterEveryword(t(TXT_LAYCAN_START))} (${dateFormat})`;
  const lblLaycanEnd = `${capitalizeFirstLetterEveryword(t(TXT_LAYCAN_END))} (${dateFormat})`;
  const lblNORTendered = `${capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED))} (${dateTimeFormat})`;
  lblCommenced = `${lblCommenced} (${dateTimeFormat})`;
  lblCompleted = `${lblCompleted} (${dateTimeFormat})`;

  useEffect(() => {
    setShowOutsideLaycanValidation(
      () =>
        !!props.isLoadingPort &&
        isVesselOutsideLaycan({
          validNor: watchValidNor,
          norTenderedDate: watchNORTendered,
          laycanStartDate: watchLaycanStart,
          laycanEndDate: watchLaycanEnd
        })
    );
  }, [props.isLoadingPort, watchNORTendered, watchLaycanEnd, watchLaycanStart, watchValidNor]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.portId`}
          render={({ fieldState }) => (
            <PortSelector
              label={t(capitalizeFirstLetterEveryword(TXT_PORT))}
              port={watchPort}
              onSelectedChange={handlePortChanged}
              error={!!fieldState.error}
              disabled={isDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.quantity`}
          render={({ fieldState }) => (
            <NumberTextField
              label={capitalizeFirstLetterEveryword(TXT_QUANTITY)}
              defaultValue={watchQuantity || ''}
              onBlur={(e) => {
                const newVal = parseFloat(e.currentTarget.value);
                setValue(`${ports}.${props.index}.quantity`, isNaN(newVal) ? undefined : newVal);
              }}
              error={!!fieldState.error}
              variant="outlined"
              fullWidth
              disabled={isDisabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">{watchMassUnit || ''}</InputAdornment>
              }}
              inputProps={{
                step: '0.001'
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.billOfLadingDate`}
          render={({ fieldState }) => (
            <DateTimePicker
              label={lblBOL}
              dateFormat={dateFormat}
              value={watchBOL ? moment(watchBOL) : undefined}
              onChange={handleDate('billOfLadingDate')}
              disabled={isDisabled}
              openToDate={latestDate}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.norTenderedDate`}
          render={({ fieldState }) => (
            <DateTimePicker
              label={lblNORTendered}
              value={watchNORTendered ? moment(watchNORTendered) : undefined}
              onChange={handleDate('norTenderedDate')}
              disabled={isDisabled}
              dateFormat={dateTimeFormat}
              showTimeInput
              openToDate={latestDate}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.validNor`}
          render={({ fieldState }) => (
            <DateTimePicker
              label={lblValidNor}
              dateFormat={dateTimeFormat}
              value={watchValidNor ? moment(watchValidNor) : undefined}
              onChange={handleDate('validNor')}
              disabled={isDisabled}
              openToDate={latestDate}
              showTimeInput
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {props.isLoadingPort && (
          <Controller
            control={control}
            name={`${ports}.${props.index}.laycanStartDate`}
            render={({ fieldState }) => (
              <DateTimePicker
                label={lblLaycanStart}
                dateFormat={dateFormat}
                value={watchLaycanStart ? moment(watchLaycanStart) : undefined}
                onChange={handleDate('laycanStartDate')}
                disabled={isDisabled}
                openToDate={latestDate}
                error={!!fieldState.error}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.commencedDate`}
          render={({ fieldState }) => (
            <DateTimePicker
              label={lblCommenced}
              value={watchCommenced ? moment(watchCommenced) : undefined}
              onChange={handleDate('commencedDate')}
              disabled={isDisabled}
              dateFormat={dateTimeFormat}
              showTimeInput
              openToDate={latestDate}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.completedDate`}
          render={({ fieldState }) => (
            <DateTimePicker
              label={lblCompleted}
              value={watchCompleted ? moment(watchCompleted) : undefined}
              onChange={handleDate('completedDate')}
              disabled={isDisabled}
              dateFormat={dateTimeFormat}
              showTimeInput
              openToDate={latestDate}
              error={!!fieldState.error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {props.isLoadingPort && (
          <Controller
            control={control}
            name={`${ports}.${props.index}.laycanEndDate`}
            render={({ fieldState }) => (
              <DateTimePicker
                label={lblLaycanEnd}
                dateFormat={dateFormat}
                value={watchLaycanEnd ? moment(watchLaycanEnd) : undefined}
                onChange={handleDate('laycanEndDate')}
                disabled={isDisabled}
                openToDate={latestDate}
                error={!!fieldState.error}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          control={control}
          name={`${ports}.${props.index}.agentId`}
          render={({ fieldState }) => (
            <CounterPartySelector
              label={capitalizeFirstLetterEveryword(t(TXT_AGENT))}
              counterParty={watchAgent}
              counterPartyType="AGENT"
              onChanged={handleCounterparty}
              disabled={isDisabled}
              error={!!fieldState.error}
              allowAdd={true}
              showMaritimeDirectory={true}
              portModel={watchPort}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {showOutsideLaycanValidation && (
          <Alert severity="warning" style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
            {_.capitalize(t(TXT_VESSEL_OUTSIDE_LAYCAN))}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};
