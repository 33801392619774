import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DELETE_SHIPMENT } from '../../../api';
import { useApolloErrorHandler } from '../../../hooks/useApolloErrorHandler';
import _ from 'lodash';
import {
  TXT_DELETE_CONFIRMATION, TXT_DELETE_TEXT, TXT_NO, TXT_SAVING, TXT_YES
} from '../../../../../../shared/translations';
import { dealModel } from '../../../model';

export interface DealDeleteConfirmationProps extends dealModel {
  onCancel: () => void;
  afterSaving: () => void;
};

// eslint-disable-next-line max-len
export const DealDeleteConfirmation = (props: DealDeleteConfirmationProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [deleteDeal, { loading }] = useMutation(DELETE_SHIPMENT, {
    variables: {
      input: {
        id: props.id
      }
    },
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSaving();
      props.onCancel();
    }
  });

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: props.name
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  const onSubmit = () => {
    deleteDeal();
  };

  return (
    <Dialog
      open={true}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>
        {t(TXT_DELETE_CONFIRMATION).toUpperCase()}
      </DialogTitle>
      <DialogContent>
        {txtConfirmation}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onCancel}
          color="secondary"
          disabled={loading}
        >
          {t(TXT_NO)}
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          {
            loading &&
            <CircularProgress
              style={{ marginRight: '5px' }}
              size='20px'
            />
          }
          {
            t(loading ? TXT_SAVING : TXT_YES)
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};
