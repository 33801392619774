/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import { DateTimePicker } from 'components/DateTimePicker/DateTimePicker';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import { cognitiveReadOperationResultExt } from './cognitiveReadOperationResultExt';
import {
  TXT_EDIT,
  TXT_SPLIT,
  TXT_DELETE,
  TXT_TYPE,
  TXT_DATE,
  TXT_NUMBER,
  TXT_TEXT,
  TXT_VALUE,
  TXT_CANCEL,
  TXT_SAVE
} from '../../../../../shared/translations';

export interface OcrChipProps extends cognitiveReadOperationResultExt {
  onEdit?: (value: cognitiveReadOperationResult) => void;
  onDelete?: () => void;
  onSelect?: (isSelected: boolean) => void;
  onSplit?: () => void;
}

export const OcrChip = (props: OcrChipProps) => {
  const { t } = useTranslation();
  const { id, value, type, selected, usedForInterruption, onEdit, onDelete, onSelect, onSplit } = props;
  const [{ isDragging }, drag] = useDrag(() => {
    const item = { id: id, value: value, type: type } as cognitiveReadOperationResult;
    return {
      type: 'CHIP',
      item: { item },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    };
  });

  const dateFormat = getDateFormat();

  const [openDialog, setOpenDialog] = useState(false);
  const [chipType, setChipType] = useState(type);
  const [stringValue, setStringValue] = useState(type === 'System.String' ? value : '');
  const [dateValue, setDateValue] = useState(
    type === 'System.DateTime' || type === 'System.DateOnly' ? moment(value) : undefined
  );
  const [numberValue, setNumberValue] = useState(type === 'System.Int32' ? _.toNumber(value) : 0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHover, setIsHover] = useState(false);
  const [chipColor, setChipColor] = useState<'primary' | 'secondary' | 'default'>('default');
  const open = Boolean(anchorEl);

  const [isSelected, setIsSelected] = useState(selected);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsHover(() => true);
    setAnchorEl(event && event.currentTarget);
  };
  const handleMenuClose = () => {
    setIsHover(() => false);
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    switch (chipType) {
      case 'System.DateTime':
      case 'System.DateOnly':
      case 'System.TimeOnly':
        setChipColor(() => 'primary');
        break;
      case 'System.Int32':
        setChipColor(() => 'secondary');
        break;
      default:
        setChipColor(() => 'default');
        break;
    }
  }, [chipType]);

  useEffect(() => {
    setIsSelected(() => isSelected);
  }, [isSelected]);

  const handleSave = () => {
    if (!!onEdit) {
      switch (type) {
        case 'System.DateTime':
        case 'System.DateOnly':
        case 'System.TimeOnly':
          onEdit({ type: chipType, value: dateValue?.toISOString(), id: props.id });
          break;
        case 'System.Int32':
          onEdit({ type: chipType, value: numberValue.toString(), id: props.id });
          break;
        default:
          onEdit({ type: chipType, value: stringValue, id: props.id });
          break;
      }
    }
    handleCloseDialog();
  };

  const handleDelete = () => {
    handleMenuClose();
    if (!!onDelete) onDelete();
  };

  const handleSplit = () => {
    handleMenuClose();
    if (!!onSplit) onSplit();
  };

  const handleChipClick = () => {
    const selected = !isSelected;
    setIsSelected(() => selected);
    if (!!onSelect) onSelect(selected);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Chip
        disabled={usedForInterruption}
        size="small"
        ref={drag}
        label={props.value}
        color={'default'}
        style={{
          margin: '5px',
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          position: 'relative',
          textDecoration: usedForInterruption ? 'line-through' : 'none'
        }}
        onDelete={handleMenuClick}
        deleteIcon={
          <MoreVertIcon
            style={{
              color: isHover || open ? '#333' : 'transparent', // Change color on hover
              transition: 'color 0.3s ease-in-out' // Smooth color transition
            }}
          />
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleChipClick}
        icon={isSelected ? <DoneIcon /> : undefined}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenDialog}>{capitalizeFirstLetterEveryword(t(TXT_EDIT))}</MenuItem>
        <MenuItem onClick={handleSplit}>{capitalizeFirstLetterEveryword(t(TXT_SPLIT))}</MenuItem>
        <MenuItem onClick={handleDelete}>{capitalizeFirstLetterEveryword(t(TXT_DELETE))}</MenuItem>
      </Menu>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_EDIT))}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="lbl-search-deal-status">{capitalize(t(TXT_TYPE))}</InputLabel>
                <Select
                  labelId="dlbl-search-deal-status"
                  id="demo-simple-select"
                  value={chipType}
                  label="Type"
                  onChange={(event) => setChipType(() => event.target.value)}
                >
                  <MenuItem value={'System.DateTime'}>{capitalizeFirstLetterEveryword(t(TXT_DATE))}</MenuItem>
                  <MenuItem value={'System.Int32'}>{capitalizeFirstLetterEveryword(t(TXT_NUMBER))}</MenuItem>
                  <MenuItem value={'System.String'}>{capitalizeFirstLetterEveryword(t(TXT_TEXT))}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {chipType === 'System.String' && (
                  <TextField
                    label={capitalize(t(TXT_VALUE))}
                    defaultValue={stringValue}
                    onChange={(event) => setStringValue(() => event.target.value)}
                  />
                )}
                {(chipType === 'System.DateTime' || chipType === 'System.DateOnly') && (
                  <DateTimePicker
                    label={capitalize(t(TXT_VALUE))}
                    value={dateValue}
                    dateFormat={dateFormat}
                    onChange={(value) => setDateValue(() => value)}
                  />
                )}
                {chipType === 'System.Int32' && (
                  <TextField
                    label={capitalize(t(TXT_VALUE))}
                    defaultValue={numberValue}
                    onChange={(event) => setNumberValue(() => _.toNumber(event.target.value))}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{capitalize(t(TXT_CANCEL))}</Button>
          <Button onClick={handleSave}>{capitalize(t(TXT_SAVE))}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
