import { Autocomplete, Grid, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { AddDealInput, UpdateDealPortInput } from 'api';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { NumberTextField } from 'components/NumberTextField';
import { useWatch, Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_SUPPLIER, TXT_QUANTITY, TXT_LOADING_PORT, TXT_UNLOADING_PORT, TXT_PORT } from '../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { counterPartyModel } from 'model';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { DealWizardContext } from './DealWizard';
import { IncoTermSelector } from 'components/IncoTermSelector';

interface DealWizardSupplierProps {
  index: number;
  handleRemove: (index: number) => void;
}

interface PortOption extends UpdateDealPortInput {
  portType: string;
}

export const DealWizardSupplier = (props: DealWizardSupplierProps) => {
  const { control, setValue, formState: { errors: formErrors } } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const [portOptions, setPortOptions] = useState<PortOption[]>([]);

  const watchSupplier = useWatch({
    control,
    name: `suppliers.${props.index}`
  });

  const watchLoadingPorts = useWatch({
    control,
    name: 'loadingPorts'
  });

  const watchUnloadingPorts = useWatch({
    control,
    name: 'unloadingPorts'
  });

  const watchMassUnit = useWatch({
    control,
    name: 'massUnit'
  });

  const getSelectedPort = () => {
    const selectedId = watchSupplier.applicableDealPortId;
    if (!selectedId) {
      return null;
    }

    return (portOptions || []).find((port) => port.id === selectedId) || null;
  };

  const handleSupplierChanged = (supplier?: counterPartyModel) => {
    setValue(`suppliers.${props.index}.supplierId`, supplier?.id);
    setValue(`suppliers.${props.index}.supplier`, supplier);
  };

  useEffect(() => {
    setPortOptions([
      ...(watchLoadingPorts || []).map((port) => ({
        ...port,
        portType: capitalizeFirstLetterEveryword(t(TXT_LOADING_PORT))
      })),
      ...(watchUnloadingPorts || []).map((port) => ({
        ...port,
        portType: capitalizeFirstLetterEveryword(t(TXT_UNLOADING_PORT))
      }))
    ]);
  }, [watchLoadingPorts, watchUnloadingPorts]);

  return (
    <fieldset>
      <legend>{`${t(TXT_SUPPLIER).toUpperCase()} #${props.index + 1}`}</legend>
      {!!watchSupplier.canDelete && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginBottom: theme.spacing(1)
          }}
        >
          <IconButton
            aria-label={`delete-split-quantities-${props.index}`}
            onClick={() => props.handleRemove(props.index)}
            disabled={isDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name={`suppliers.${props.index}.supplierId`}
            control={control}
            render={() => (
              <CounterPartySelector
                counterParty={watchSupplier.supplier}
                onChanged={(cParty) => {
                  handleSupplierChanged(cParty);
                }}
                error={!!formErrors.suppliers?.[props.index]?.supplierId}
                label={t(TXT_SUPPLIER)}
                counterPartyType="SUPPLIER"
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <NumberTextField
            label={capitalizeFirstLetterEveryword(TXT_QUANTITY)}
            value={watchSupplier.quantity || ''}
            onChange={(e) => setValue(`suppliers.${props.index}.quantity`, parseFloat(e.currentTarget.value))}
            error={!!formErrors.suppliers?.[props.index]?.quantity}
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            InputProps={{
              endAdornment: <InputAdornment position="end">{watchMassUnit || ''}</InputAdornment>
            }}
            inputProps={{
              step: '0.001'
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name={`suppliers.${props.index}.applicableDealPortId`}
            control={control}
            render={() => (
              <Autocomplete
                id={`suppliers.${props.index}.applicableDealPortId`}
                options={portOptions}
                groupBy={(option) => option.portType}
                getOptionLabel={(option) => option.port?.name || ''}
                value={getSelectedPort()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, port) => {
                  setValue(`suppliers.${props.index}.applicableDealPortId`, port === null ? undefined : port?.id);
                  setValue(`suppliers.${props.index}.quantity`, port === null ? 0 : port?.quantity || 0);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(capitalizeFirstLetterEveryword(TXT_PORT))}
                    error={!!formErrors.suppliers?.[props.index]?.applicableDealPortId}
                  />
                )}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <IncoTermSelector
            incoTerm={watchSupplier.incoTerm}
            onSelectedChange={(term) => {
              setValue(`suppliers.${props.index}.incoTerm`, term === null ? undefined : term);
            }}
            error={!!formErrors.suppliers?.[props.index]?.incoTerm}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};
