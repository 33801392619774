import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TXT_LOADING, TXT_UNLOADING } from '../../../../../../shared/translations';
import {
  DemurrageTabName,
  getPropName,
  setSelectedTab,
  useDemurragePageDispatch,
  useDemurragePageState
} from '../DemurragePageContext';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getCalculationsOrdered, getIncoTerm } from '../utils';

export const LoadingUndloadingTab = () => {
  const { t } = useTranslation();
  const { demurrageDeal, selectedTab } = useDemurragePageState();
  const dispatch = useDemurragePageDispatch();

  const [searchParams] = useSearchParams();
  const calcId = searchParams.get('calcId');

  const showLoadingTab = () => {
    if (!demurrageDeal?.parentLaytimeCalculationId) {
      return true;
    }

    return !_.isEmpty(demurrageDeal.loadingCalculations);
  };

  const showUnloadingTab = () => {
    if (!demurrageDeal?.parentLaytimeCalculationId) {
      return !_.isEmpty(demurrageDeal?.unloadingPorts) && demurrageDeal?.splitQuantities?.some((split) => getIncoTerm(split) !== 'FOB');
    }

    return !_.isEmpty(demurrageDeal.unloadingCalculations);
  };

  useEffect(() => {
    if (!calcId || demurrageDeal?.id === calcId) {
      return;
    }
    if (!getCalculationsOrdered(getPropName(selectedTab), demurrageDeal).find((x) => x.id === calcId)) {
      dispatch(setSelectedTab(selectedTab === 'loading' ? 'unloading' : 'loading'));
    }
  }, [calcId]);

  const handleVerticalTabChange = (event: any, newValue: DemurrageTabName) => {
    dispatch(setSelectedTab(newValue));
  };

  return (
    <Tabs value={selectedTab} onChange={handleVerticalTabChange} variant="fullWidth" sx={{ pb: 1 }}>
      {showLoadingTab() ? <Tab label={t(TXT_LOADING)} value="loading" /> : null}
      {showUnloadingTab() ? <Tab label={t(TXT_UNLOADING)} value="unloading" /> : null}
    </Tabs>
  );
};
