import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const LAYTIME_CALCULATION_COMMENT_ADDED = gql`
  subscription onLaytimeCalculationCommentAdded($entityId: UUID!) {
    result: onLaytimeCalculationCommentAdded(entityId: $entityId) {
      ${BaseCommentQueryData}
    }
  }
`;

export const LAYTIME_CALCULATION_COMMENT_UPDATED = gql`
  subscription onLaytimeCalculationCommentUpdated($entityId: UUID!) {
    result: onLaytimeCalculationCommentUpdated(entityId: $entityId) {
      ${BaseCommentQueryData}
    }
  }
`;

export const LAYTIME_CALCULATION_COMMENT_DELETED = gql`
  subscription onLaytimeCalculationCommentDeleted($entityId: UUID!) {
    deletedId: onLaytimeCalculationCommentDeleted(entityId: $entityId)
  }
`;
