import { Container, Grid } from '@mui/material';
import { DealSelector } from 'components/DealSelector';
import { useTitle } from 'hooks';
import { dealModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { useState } from 'react';
import { TXT_VOYAGE_PROFIT_AND_LOSS } from '../../../../../shared/translations';
import { VoyageProfitAndLossReport } from './VoyageProfitAndLossReport';

export const VoyageProfitAndLoss = () => {
  useTitle(TXT_VOYAGE_PROFIT_AND_LOSS);
  const globalStyles = useGlobalStyles();
  const [selected, setSelected] = useState<dealModel>();

  return (
    <Container component='main' className={globalStyles.container}>
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12}>
          <DealSelector onChanged={(deal) => setSelected(deal)} />
        </Grid>
        <Grid item xs={12}>
          <VoyageProfitAndLossReport deal={selected} />
        </Grid>
      </Grid>
    </Container>
  );
};
