import { useLazyQuery } from '@apollo/client';
import { Button, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRenderCellParams } from '@mui/x-data-grid';
import { DELETE_MARITIME_DIRECTORY, GET_MARITIME_DIRECTORIES, GetMaritimeDirectoriesOutput } from 'api';
import { CounterPartySelector, CountrySelector, DeleteModal, GridNoResultsOverlay, PortSelector } from 'components';
import { useApolloErrorHandler, useDefaultSettings, useTitle } from 'hooks';
import { maritimeDirectoryModel } from 'model/maritimeDirectoryModel';
import { useGlobalStyles } from 'pages/globalStyles';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizeOptions } from 'shared/constants';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ADD,
  TXT_ADDRESSES,
  TXT_DELETE_MARITIME_DIRECTORY,
  TXT_EMAILS,
  TXT_MARITIME_DIRECTORIES,
  TXT_NAME,
  TXT_PHONES,
  TXT_PORT,
  TXT_SEARCH
} from '../../../../../shared/translations';
import AddIcon from '@mui/icons-material/Add';
import { MaritimeDirectoryEditor } from './MaritimeDirectoryEditor';
import _ from 'lodash';
import { MaritimeDirectoryNameColumn } from './MaritimeDirectoryNameColumn';
import { MaritimeDirectoryAddressesColumn } from './MaritimeDirectoryAddressColumn';
import { MaritimeDirectoryEmailsColumn } from './MaritimeDirectoryEmailColumn';
import { MaritimeDirectoryPhonesColumn } from './MaritimeDirectoryPhoneColumn';
import { counterPartyModel, portModel } from 'model';
import { useMaritimeDirectoryStyle } from './useMaritimeDirectoryStyle';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useAuth from 'hooks/useAuth';

interface MaritimeDirectoriesContextProps {
  refreshData: () => void;
}

export const MaritimeDirectoriesContext = createContext<MaritimeDirectoriesContextProps>({
  refreshData: () => {}
});

export const MaritimeDirectories = () => {
  useTitle(TXT_MARITIME_DIRECTORIES);
  const globalStyles = useGlobalStyles();

  const [selected, setSelected] = useState<maritimeDirectoryModel>();
  const [openEditor, setOpenEditor] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [pageNumber, setPageNumber] = useState(1);
  const [defaultSettings, setDefaultSettings] = useDefaultSettings();
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);
  const [data, setData] = useState<GetMaritimeDirectoriesOutput>();

  const [country, setCountry] = useState<string>();
  const [port, setPort] = useState<portModel>();
  const [counterParty, setCounterParty] = useState<counterPartyModel>();
  const [search, setSearch] = useState<string>();

  const style = useMaritimeDirectoryStyle();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    let result = {};

    if (!_.isNil(country) && country.length > 0) {
      result = {
        ...result,
        ports: {
          some: {
            country: {
              eq: country
            }
          }
        }
      };
    }

    if (!!port) {
      result = {
        ...result,
        ports: {
          some: {
            id: {
              eq: port.id
            }
          }
        }
      };
    }

    if (!!counterParty) {
      result = {
        ...result,
        counterParty: {
          id: {
            eq: counterParty.id
          }
        }
      };
    }

    if (!_.isNil(search) && search.length > 0) {
      result = {
        ...result,
        or: [
          {
            contact: {
              name: {
                contains: search
              }
            }
          },
          {
            contact: {
              notes: {
                contains: search
              }
            }
          },
          {
            contact: {
              addresses: {
                some: {
                  address: {
                    contains: search
                  }
                }
              }
            }
          },
          {
            contact: {
              emails: {
                some: {
                  email: {
                    contains: search
                  }
                }
              }
            }
          },
          {
            contact: {
              emails: {
                some: {
                  name: {
                    contains: search
                  }
                }
              }
            }
          },
          {
            contact: {
              phones: {
                some: {
                  phone: {
                    contains: search
                  }
                }
              }
            }
          },
          {
            contact: {
              phones: {
                some: {
                  name: {
                    contains: search
                  }
                }
              }
            }
          }
        ]
      };
    }

    return _.isEqual(result, {})
      ? null
      : {
        and: [{ ...result }]
      };
  };

  const [getMaritimeDirectories, { loading }] = useLazyQuery<GetMaritimeDirectoriesOutput>(GET_MARITIME_DIRECTORIES, {
    fetchPolicy: 'network-only',
    onError: apolloErrorHandler,
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: getFilter()
    },
    onCompleted: (data) => {
      setData(() => ({ ...data }));
    }
  });

  const openCloseEditor = () => setOpenEditor((prev) => !prev);
  const openCloseDelete = () => setOpenDelete((prev) => !prev);

  const onAdd = () => {
    setSelected(() => undefined);
    openCloseEditor();
  };

  const onUpdate = (model: maritimeDirectoryModel) => {
    return () => {
      setSelected(() => ({ ...model }));
      openCloseEditor();
    };
  };

  const onDelete = (model: maritimeDirectoryModel) => {
    return () => {
      setSelected(() => ({ ...model }));
      openCloseDelete();
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      headerClassName: style.tableHeader,
      renderHeader: () => {
        return (
          <Stack direction="row" spacing={1}>
            <AccountBoxIcon />
            <Typography>{capitalizeFirstLetterEveryword(t(TXT_NAME))}</Typography>
          </Stack>
        );
      },
      renderCell: (params: GridRenderCellParams<maritimeDirectoryModel, any>) => {
        return (
          <MaritimeDirectoryNameColumn
            data={params.row}
            onUpdate={onUpdate(params.row)}
            onDelete={onDelete(params.row)}
            showActions={!isGuest}
          />
        );
      }
    },
    {
      field: 'addresses',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      headerClassName: style.tableHeader,
      renderHeader: () => {
        return (
          <Stack direction="row" spacing={1}>
            <HomeIcon />
            <Typography>{capitalizeFirstLetterEveryword(t(TXT_ADDRESSES))}</Typography>
          </Stack>
        );
      },
      renderCell: (params: GridRenderCellParams<maritimeDirectoryModel, any>) => {
        return <MaritimeDirectoryAddressesColumn data={params.row} showActions={!isGuest} />;
      }
    },
    {
      field: 'emails',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      headerClassName: style.tableHeader,
      renderHeader: () => {
        return (
          <Stack direction="row" spacing={1}>
            <EmailIcon />
            <Typography>{capitalizeFirstLetterEveryword(t(TXT_EMAILS))}</Typography>
          </Stack>
        );
      },
      renderCell: (params: GridRenderCellParams<maritimeDirectoryModel, any>) => {
        return <MaritimeDirectoryEmailsColumn data={params.row} showActions={!isGuest} />;
      }
    },
    {
      field: 'phones',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      headerClassName: style.tableHeader,
      renderHeader: () => {
        return (
          <Stack direction="row" spacing={1}>
            <PhoneIcon />
            <Typography>{capitalizeFirstLetterEveryword(t(TXT_PHONES))}</Typography>
          </Stack>
        );
      },
      renderCell: (params: GridRenderCellParams<maritimeDirectoryModel, any>) => {
        return <MaritimeDirectoryPhonesColumn data={params.row} showActions={!isGuest} />;
      }
    }
  ];

  const customFooter = () => (
    <GridFooterContainer
      sx={(theme) => ({
        padding: `0 ${theme.spacing(1)}`,
        display: 'flex',
        justifyContent: !isGuest ? 'space-between' : 'flex-end',
        alignItems: 'center'
      })}
    >
      {!isGuest && (
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onAdd}>
          {capitalizeFirstLetterEveryword(t(TXT_ADD))}
        </Button>
      )}
      <GridFooter
        sx={{
          border: 'none'
        }}
      />
    </GridFooterContainer>
  );

  useEffect(() => {
    getMaritimeDirectories();
  }, []);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  useEffect(() => {
    if (!port) {
      return;
    }
    setCountry(() => port.country);
  }, [port]);

  useEffect(() => {
    if (port?.country === country) {
      return;
    }
    setPort(() => undefined);
  }, [country]);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(2)
        })}
      >
        <MaritimeDirectoriesContext.Provider
          value={{
            refreshData: getMaritimeDirectories
          }}
        >
          {openEditor ? (
            <MaritimeDirectoryEditor {...selected} afterSave={getMaritimeDirectories} onClose={openCloseEditor} />
          ) : null}
          {openDelete ? (
            <DeleteModal
              id={selected?.id}
              mutation={DELETE_MARITIME_DIRECTORY}
              afterSaving={getMaritimeDirectories}
              onCancel={openCloseDelete}
              txtConfirmation={_.capitalize(t(TXT_DELETE_MARITIME_DIRECTORY))}
            />
          ) : null}
          <Stack direction="row" spacing={1}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} md={3}>
                <CountrySelector
                  country={country}
                  onSelectedChange={(x) => setCountry(() => (x === null ? undefined : x))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PortSelector
                  onSelectedChange={(x) => setPort(() => (x === null ? undefined : { ...x }))}
                  label={capitalizeFirstLetterEveryword(t(TXT_PORT))}
                  country={country}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CounterPartySelector
                  counterParty={counterParty}
                  onSelectedChange={(x) => setCounterParty(() => (x === null ? undefined : { ...x }))}
                  counterPartyType="AGENT"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  value={search}
                  onChange={(e) => setSearch(() => e.currentTarget.value)}
                  fullWidth
                  label={capitalizeFirstLetterEveryword(t(TXT_SEARCH))}
                />
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data?.maritimeDirectories.items || []}
                  rowCount={data?.maritimeDirectories.totalCount || 0}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize,
                        page: pageNumber - 1
                      }
                    }
                  }}
                  onPaginationModelChange={(page) => {
                    setPageSize(() => page.pageSize);
                    setPageNumber(() => page.page);
                  }}
                  loading={loading}
                  pageSizeOptions={PageSizeOptions}
                  disableColumnFilter
                  disableColumnMenu
                  paginationMode="server"
                  slots={{
                    noRowsOverlay: GridNoResultsOverlay,
                    footer: customFooter
                  }}
                  getRowHeight={() => 'auto'}
                  sx={{
                    height: 'calc(100vh - 200px)'
                  }}
                  rowSelection={false}
                  showCellVerticalBorder
                  showColumnVerticalBorder
                />
              </Grid>
            </Grid>
          </Stack>
        </MaritimeDirectoriesContext.Provider>
      </Paper>
    </Container>
  );
};
