import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { AddStatementOfFactInput } from 'api';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import {
  CustomTable,
  DateTimePickerWithDrop,
  NumberTextField,
  OcrChip,
  TextFieldWithDrop,
  TimePickerWithDrop
} from 'components';
import { t } from 'i18next';
import _ from 'lodash';
import { statementOfFactInterruptionModel } from 'model';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';
import { getDateFormat, updateDatePart, updateTimePart } from 'shared/utils';
import shortUUID from 'short-uuid';
import { useOcrScanWizardStyle } from './ocrScanWizardStyle';
import {
  TXT_ADD_INTERRUPTION,
  TXT_DESCRIPTION,
  TXT_SINCE,
  TXT_TO,
  TXT_TO_DISCOUNT,
  TXT_DELETE,
  TXT_DATE,
  TXT_TIME
} from '../../../../../../shared/translations';

export const InterruptionsWizard = () => {
  const { control, setValue } = useFormContext<AddStatementOfFactInput>();

  const dateFormat = getDateFormat();
  const style = useOcrScanWizardStyle();

  const watchOcrResult = useWatch({
    control,
    name: 'ocrResult'
  });

  const watchInterruptions = useWatch({
    control,
    name: 'interruptions'
  });

  const [interruptions, setInterruptions] = useState<Record<string, Partial<statementOfFactInterruptionModel>>>(
    {} as Record<string, Partial<statementOfFactInterruptionModel>>
  );
  const [ocrResult, setOcrResult] = useState(watchOcrResult ?? []);
  const [pageNumbers] = useState<number[]>(!!ocrResult ? _.uniq(ocrResult.map((item) => item.pageNumber!)) : []);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>();

  useEffect(() => {
    if (!!watchInterruptions) {
      setInterruptions(() => {
        const transformedInterruptions: Record<string, Partial<statementOfFactInterruptionModel>> = {};
        watchInterruptions.forEach((input, index) => {
          transformedInterruptions[input.id!] = {
            id: input.id,
            statementOfFactId: input.statementOfFactId,
            description: input.description,
            since: input.since,
            to: input.to,
            percentage: input.percentage,
            toDiscount: input.toDiscount
          };
        });
        return transformedInterruptions;
      });
    }
  }, []);

  useEffect(() => {
    const updatedInputs = Object.values(interruptions).map((interruption, index) => ({
      id: interruption.id,
      statementOfFactId: interruption.statementOfFactId,
      description: interruption.description,
      since: interruption.since,
      to: interruption.to,
      percentage: interruption.percentage,
      toDiscount: interruption.toDiscount
    }));
    setValue('interruptions', updatedInputs);
  }, [interruptions]);

  const handleAddInterruption = () => {
    const allInts = Object.values(interruptions);
    const lastInt = allInts.length > 0 ? allInts[allInts.length - 1] : undefined;
    setInterruptions((s) => {
      const id = 'new-' + shortUUID.generate();
      return {
        ...s,
        [id]: {
          id,
          toDiscount: false,
          percentage: 100,
          since: lastInt?.to,
          to: lastInt?.to
        }
      };
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id?: string) => {
    if (!!id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], description: e.target.value }
      }));
    }
  };

  const handleDropInput = (item: cognitiveReadOperationResult, id?: string) => {
    if (!!id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], description: (item as cognitiveReadOperationResult).value }
      }));

      if (!!item?.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
          setValue('ocrResult', newOcrResult);

          return newOcrResult;
        });
      }
    }
  };

  const handleSinceChange = (date: moment.Moment | undefined, id?: string, item?: cognitiveReadOperationResult) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const since = s[id].since ? moment(s[id].since).toDate() : undefined;
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;
        let toHasValue = s[id].to ? true : false;
        const newValue = updateDatePart(since, date?.toDate() || undefined);
        if (toHasValue && to && newValue) {
          const sinceNumber = newValue.valueOf();
          const toNumber = to.valueOf();
          toHasValue = sinceNumber > toNumber ? false : true;
        }
        return {
          ...s,
          [id]: { ...s[id], since: newValue, to: !toHasValue ? newValue : s[id].to }
        };
      });

      if (!!item?.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
          setValue('ocrResult', newOcrResult);

          return newOcrResult;
        });
      }
    }
  };

  const handleToChange = (date: moment.Moment | undefined, id?: string, item?: cognitiveReadOperationResult) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;

        return {
          ...s,
          [id]: { ...s[id], to: updateDatePart(to, date?.toDate() || undefined) }
        };
      });

      if (!!item?.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
          setValue('ocrResult', newOcrResult);

          return newOcrResult;
        });
      }
    }
  };

  const handleInterruptionDelete = (id?: string) => () => {
    if (id) {
      setInterruptions((s) => {
        const newS = { ...s };
        delete newS[id];
        return newS;
      });
    }
  };

  const handlePercentageChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], percentage: event.target.value ? parseFloat(event.target.value) : 0 }
      }));
    }
  };

  const handleToDiscountChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], toDiscount: event.target.checked }
      }));
    }
  };

  const onPageNumberChanged = (_: React.MouseEvent<HTMLElement>, nextLetter: number) => {
    setSelectedPageNumber(() => nextLetter);
    if (!!nextLetter && !!watchOcrResult) {
      const newResult = [...watchOcrResult];
      setOcrResult(() => newResult.filter((x) => x.pageNumber === nextLetter));
    }
  };

  useEffect(() => {
    if (!!watchOcrResult) {
      setOcrResult((r) => watchOcrResult);
    }
  }, [watchOcrResult]);

  const Cell = ({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
    const index = rowIndex + columnIndex;
    const item = !!watchOcrResult && watchOcrResult.length > 0 ? watchOcrResult[index] : null;
    return (
      <div style={style}>
        {item ? (
          <OcrChip
            id={item.id}
            type={item.type}
            value={item.value}
            selected={item.selected}
            usedForInterruption={item.usedForInterruption}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
      <DndProvider backend={HTML5Backend}>
        <Stack direction="row" spacing={1} style={{ height: '100%' }}>
          <ToggleButtonGroup
            orientation="vertical"
            value={selectedPageNumber}
            onChange={onPageNumberChanged}
            exclusive
            className={style.letters}
          >
            {[...pageNumbers].map((x, index) => (
              <ToggleButton key={`pagenumber-${index}`} value={x}>
                {x}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Grid container spacing={2} style={{ height: '100%' }}>
            <Grid item xs={4} style={{ height: '100%', overflow: 'auto', padding: '10px' }}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} md={12}>
                  {ocrResult && ocrResult.length > 0 && (
                    <FixedSizeGrid
                      columnCount={1}
                      columnWidth={450}
                      height={600}
                      rowCount={Math.ceil(ocrResult.length)}
                      rowHeight={35}
                      width={470}
                    >
                      {Cell}
                    </FixedSizeGrid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <Button onClick={handleAddInterruption} color="inherit">
                      <AddIcon />
                      {t(TXT_ADD_INTERRUPTION)}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTable stickyHeader style={{ overflowX: 'auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={500} rowSpan={2}>
                          {t(TXT_DESCRIPTION).toUpperCase()}
                        </TableCell>
                        <TableCell align="center" width={500} colSpan={2}>
                          {t(TXT_SINCE).toUpperCase()}
                        </TableCell>
                        <TableCell align="center" width={500} colSpan={2}>
                          {t(TXT_TO).toUpperCase()}
                        </TableCell>
                        <TableCell align="center" width={200} rowSpan={2}>
                          %
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t(TXT_TO_DISCOUNT).toUpperCase()}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t(TXT_DELETE).toUpperCase()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ top: 57 }} align="center" width={400}>
                          {t(TXT_DATE).toUpperCase()}
                        </TableCell>
                        <TableCell style={{ top: 57 }} align="center">
                          {t(TXT_TIME).toUpperCase()}
                        </TableCell>
                        <TableCell style={{ top: 57 }} align="center" width={400}>
                          {t(TXT_DATE).toUpperCase()}
                        </TableCell>
                        <TableCell style={{ top: 57 }} align="center">
                          {t(TXT_TIME).toUpperCase()}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(interruptions).map((interruption) => {
                        return (
                          <TableRow key={interruption.id}>
                            <TableCell>
                              <TextFieldWithDrop
                                id={'description-' + interruption.id}
                                value={interruption.description || null}
                                onChange={(e) => handleInputChange(e, interruption.id)}
                                onDrop={(item) => handleDropInput(item, interruption.id)}
                                style={{ width: 300 }}
                              />
                            </TableCell>
                            <TableCell>
                              <DateTimePickerWithDrop
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    margin: 'normal',
                                    autoComplete: 'off'
                                  }
                                }}
                                value={!interruption.since ? undefined : moment(interruption.since)}
                                dateFormat={dateFormat}
                                onChanged={(item, value) => handleSinceChange(value, interruption.id, item)}
                                style={{ width: 150 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TimePickerWithDrop
                                value={!interruption.since ? undefined : moment(interruption.since)}
                                onChanged={(item, value) => {
                                  const id = interruption.id;
                                  if (id) {
                                    setInterruptions((s) => {
                                      const since = s[id].since ? moment(s[id].since).toDate() : undefined;
                                      return {
                                        ...s,
                                        [id]: {
                                          ...s[id],
                                          since: updateTimePart(since || undefined, value)
                                        }
                                      };
                                    });

                                    if (!!item?.id) {
                                      setOcrResult((prevOcrResult) => {
                                        const index = prevOcrResult.findIndex((result) => result.id === item.id);
                                        const newOcrResult = [...prevOcrResult];
                                        newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
                                        setValue('ocrResult', newOcrResult);

                                        return newOcrResult;
                                      });
                                    }
                                  }
                                }}
                                style={{ width: 105 }}
                              />
                            </TableCell>
                            <TableCell>
                              <DateTimePickerWithDrop
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    margin: 'normal',
                                    autoComplete: 'off'
                                  }
                                }}
                                value={!interruption.to ? undefined : moment(interruption.to)}
                                dateFormat={dateFormat}
                                onChanged={(item, value) => handleToChange(value, interruption.id, item)}
                                style={{ width: 150 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TimePickerWithDrop
                                value={!interruption.to ? undefined : moment(interruption.to)}
                                onChanged={(item, value) => {
                                  const id = interruption.id;
                                  if (id) {
                                    setInterruptions((s) => {
                                      const to = s[id].to ? moment(s[id].to).toDate() : undefined;
                                      return {
                                        ...s,
                                        [id]: { ...s[id], to: updateTimePart(to || undefined, value) }
                                      };
                                    });

                                    if (!!item?.id) {
                                      setOcrResult((prevOcrResult) => {
                                        const index = prevOcrResult.findIndex((result) => result.id === item.id);
                                        const newOcrResult = [...prevOcrResult];
                                        newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
                                        setValue('ocrResult', newOcrResult);

                                        return newOcrResult;
                                      });
                                    }
                                  }
                                }}
                                style={{ width: 105 }}
                              />
                            </TableCell>
                            <TableCell>
                              <NumberTextField
                                margin="normal"
                                id="percentage"
                                fullWidth
                                value={interruption.percentage}
                                onChange={handlePercentageChange(interruption.id)}
                                style={{ width: 75 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={!!interruption.toDiscount}
                                onChange={handleToDiscountChange(interruption.id)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Stack direction="row" spacing={1}>
                                <IconButton
                                  aria-label="save interruptions"
                                  component="span"
                                  size="large"
                                  sx={{ width: '40px', height: '40px' }}
                                  onClick={handleInterruptionDelete(interruption.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </CustomTable>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </DndProvider>
    </div>
  );
};
