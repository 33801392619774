import { Button } from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ADD } from '../../../../../shared/translations';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

export interface CreateFooterProps {
  openAddEditor?: () => void;
  isGridFooter?: boolean;
  disabled?: boolean;
}

export const AddButton = (props: CreateFooterProps) => {
  const { t } = useTranslation();

  const renderPlainButton = (): JSX.Element => (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={props.openAddEditor}
      disabled={props.disabled}
    >
      {capitalizeFirstLetterEveryword(t(TXT_ADD))}
    </Button>
  );

  const renderFooterWithButton = () => (
    <GridFooterContainer
      sx={(theme) => ({
        padding: `0 ${theme.spacing(1)}`,
        display: 'flex',
        justifyContent: !!props.openAddEditor ? 'space-between' : 'flex-end',
        alignItems: 'center'
      })}
    >
      {!!props.openAddEditor && renderPlainButton()}
      <GridFooter
        sx={{
          border: 'none'
        }}
      />
    </GridFooterContainer>
  );

  return <div>{!props.isGridFooter ? renderPlainButton() : renderFooterWithButton()}</div>;
};
