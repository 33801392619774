import { Button, Grid } from '@mui/material';
import { AddDealInput } from 'api';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_ADD } from '../../../../../shared/translations';
import AddIcon from '@mui/icons-material/Add';
import { DealWizardLoadingPort } from './DealWizardLoadingPort';
import { DealWizardContext } from './DealWizard';
import capitalize from '../../../../../shared/stringUtils/capitalize';
import { CustomError } from 'components/CustomError';

export const DealWizardLoadingPorts = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<AddDealInput>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'loadingPorts'
  });
  const [tempId, setTempId] = useState(-1);
  const { isDisabled } = useContext(DealWizardContext);

  const handleAdd = () => {
    append({
      id: `L${tempId}`
    });
    setTempId(tempId - 1);
  };

  useEffect(() => {
    document.getElementById('frm-deal-wizard')?.scrollIntoView(false);
  }, [tempId]);

  return (
    <Grid container spacing={1}>
      {errors.loadingPorts?.message ? (
        <Grid item xs={12}>
          <CustomError>{capitalize(t(errors.loadingPorts.message))}</CustomError>
        </Grid>
      ) : null}
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} key={`loadingPorts-${field.id}`}>
            <DealWizardLoadingPort handleRemove={remove} index={index} />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAdd} disabled={isDisabled}>
          {t(TXT_ADD)}
        </Button>
      </Grid>
    </Grid>
  );
};
