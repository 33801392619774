import { counterPartyModel, CounterPartyType, dealModel, dealPortModel, splitQuantityModel } from '.';
import { baseEntityModel } from './base';
import { interruptionModel } from './interruptionModel';

export const LaytimeCalculationStatuses = ['DRAFT', 'NEGOTIATING', 'AGREED', 'PROCESSED', 'NOT_APPLICABLE'] as const;
export type LaytimeCalculationStatus = typeof LaytimeCalculationStatuses[number];
export type LaytimeSpecialCondition = 'EVEN' | 'UNLESS';
export type LaytimeCalculationResult = 'DESPATCH' | 'DEMURRAGE';
export type OnceOnDemurrageCondition = 'ALWAYS' | 'SOMETIMES';

export interface laytimeCalculationCalculatedFields extends baseEntityModel {
  permittedTime?: number;
  realLaytimeBegins?: Date;
  totalTimeUsed?: number;
  lessInterruptions?: number;
  netTimeUsed?: number;
  mvOnDemurrage?: Date;
  timeInDemurrage?: number;
  demurrageAmount?: number;
  timeInDespatch?: number;
  despatchAmount?: number;
  isCalculationOverriden?: boolean;
  amountBeforeShare?: number;
  sharedPercentage?: number;
  laytimeCalculationResult?: LaytimeCalculationResult;
  version?: number;
  quantity?: number;
  massUnit?: string
}

export interface laytimeCalculationOverridableFields extends baseEntityModel {
  realLaytimeBeginsOverride?: Date;
  laycanStartDateOverride?: Date;
  laycanEndDateOverride?: Date;
  quantityOverride?: number;
  validNorOverride?: Date;
  completedDateOverride?: Date;
  norTenderedDate?: Date;
  norTenderedDateOverride?: Date;
  commencedDate?: Date;
  commencedDateOverride?: Date;
  laytimeTimeEndsOverride?: Date;
  permittedTimeRoundingOverride?: number;
  deductionOffset?: number;
}

export interface laytimeCalculationModel extends laytimeCalculationCalculatedFields, laytimeCalculationOverridableFields {
  dealPortId?: string;
  dealPort?: dealPortModel;
  calcAgainst?: number;
  status?: LaytimeCalculationStatus;
  statusComment?: string;
  dischargeRate?: number;
  demurragePerDay?: number;
  currency?: string;
  despatchPerDay?: number;
  turnTime?: number;
  interruptions?: interruptionModel[];
  counterParty?: counterPartyModel;
  counterPartyId?: string;
  counterPartyType?: {
    id: string;
    name: CounterPartyType;
  };
  counterPartyTypeId?: string;
  createdDate?: Date;
  specialCondition?: LaytimeSpecialCondition;
  onceOnDemurrageCondition?: OnceOnDemurrageCondition;
  percentage?: number;
  laycanStartDate?: Date;
  laycanEndDate?: Date;
  quantity?: number;
  massUnit?: string;
  dealSupplierId?: string;
  validNor?: Date;
  completedDate?: Date;
  splitQuantities?: splitQuantityModel[];
  hideRemarks?: boolean;
  loadingDeals?: Pick<dealModel, 'id'>[];
  unloadingDeals?: Pick<dealModel, 'id'>[];
}

export interface CalculationEvent {
  from: Date;
  to: Date;
  percentageToCount: number;
  remarks?: string;
  timeUsedInMinutes: number;
  cummulativeUsedTimeInMinutes: number;
  timeUsedFormatted?: string;
  cummulativeUsedTimeFormatted?: string;
  isMilestone?: boolean;
}
