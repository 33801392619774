import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AddBargeTripInput } from 'api';
import { PortSelector } from 'components';
import moment from 'moment';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import { TXT_ARRIVAL_DATE, TXT_COMPLETION_DATE, TXT_PORT, TXT_RELEASE_DATE } from '../../../../../../shared/translations';

interface BargeTripEditorPortProps {
  isLoadingPort?: boolean;
  disabled?: boolean;
}

export const BargeTripEditorPort = (props: BargeTripEditorPortProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<AddBargeTripInput>();

  const watch = useWatch({
    control,
    name: !!props.isLoadingPort ? 'loadingPort' : 'unloadingPort'
  });

  const dateFormat = getDateFormat();

  const port = !!props.isLoadingPort ? 'loadingPort.port' : 'unloadingPort.port';
  const portId = !!props.isLoadingPort ? 'loadingPort.portId' : 'unloadingPort.portId';
  const arrivalTime = !!props.isLoadingPort ? 'loadingPort.arrivalTime' : 'unloadingPort.arrivalTime';
  const completedTime = !!props.isLoadingPort ? 'loadingPort.completedTime' : 'unloadingPort.completedTime';
  const releaseTime = !!props.isLoadingPort ? 'loadingPort.releaseTime' : 'unloadingPort.releaseTime';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={portId}
          render={({ fieldState }) => (
            <PortSelector
              port={watch.port}
              error={!!fieldState.error}
              onSelectedChange={(selectedPort) => {
                setValue(port, selectedPort ?? undefined);
                setValue(portId, selectedPort?.id ?? '', {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={capitalizeFirstLetterEveryword(t(TXT_PORT))}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={arrivalTime}
          render={({ fieldState }) => (
            <DatePicker
              value={!watch.arrivalTime ? null : moment(watch.arrivalTime)}
              format={dateFormat}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!fieldState.error,
                  autoComplete: 'off'
                },
                actionBar: {
                  actions: ['today', 'clear']
                }
              }}
              onChange={(value) => {
                setValue(arrivalTime, value?.toDate() ?? undefined, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={`${capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE))}`}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={completedTime}
          render={({ fieldState }) => (
            <DatePicker
              value={!watch.completedTime ? null : moment(watch.completedTime)}
              format={dateFormat}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!fieldState.error,
                  autoComplete: 'off'
                },
                actionBar: {
                  actions: ['today', 'clear']
                }
              }}
              onChange={(value) => {
                setValue(completedTime, value?.toDate() ?? undefined, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={`${capitalizeFirstLetterEveryword(t(TXT_COMPLETION_DATE))}`}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={releaseTime}
          render={({ fieldState }) => (
            <DatePicker
              value={!watch.releaseTime ? null : moment(watch.releaseTime)}
              format={dateFormat}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!fieldState.error,
                  autoComplete: 'off'
                },
                actionBar: {
                  actions: ['today', 'clear']
                }
              }}
              onChange={(value) => {
                setValue(releaseTime, value?.toDate() ?? undefined, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={`${capitalizeFirstLetterEveryword(t(TXT_RELEASE_DATE))}`}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
