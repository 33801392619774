/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { cognitiveReadOperationResultExt } from 'components';
import { DateTimePicker } from 'components/DateTimePicker/DateTimePicker';
import _ from 'lodash';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import {
  TXT_MERGE,
  TXT_VALUE,
  TXT_TYPE,
  TXT_DATE,
  TXT_NUMBER,
  TXT_TEXT,
  TXT_CANCEL,
  TXT_SAVE
} from '../../../../../../shared/translations';

interface MergeDialogProps {
  handleCloseDialog: () => void;
  handleSuccessMerge: (items: cognitiveReadOperationResultExt[]) => void;
  items: cognitiveReadOperationResultExt[];
}

export const MergeDialog = (props: MergeDialogProps) => {
  const { t } = useTranslation();
  const dateFormat = getDateFormat();

  const [items, setItems] = useState<cognitiveReadOperationResultExt[]>(props.items);

  const [chipType, setChipType] = useState('System.String');
  const [mergeValue, setMergeValue] = useState(props.items.map((x) => x.value).join(' '));
  const [errorText, setErrorText] = useState('');
  const [canMerge, setCanMerge] = useState(true);

  const onSave = () => {
    if (chipType === 'System.DateTime') {
      if (!moment(mergeValue).isValid()) {
        setCanMerge(() => false);
        setErrorText(() => 'Cannot merge text into date');
      } else {
        setCanMerge(() => true);
        setErrorText(() => '');

        const newItems = items.filter((item) => !item.selected);
        newItems.push({ value: moment(mergeValue).toISOString(), type: chipType, selected: false });
        props.handleSuccessMerge && props.handleSuccessMerge(newItems);
        props.handleCloseDialog();
      }
    } else if (chipType === 'System.String') {
      const newItems = items.filter((item) => !item.selected);
      newItems.push({ value: mergeValue, type: chipType, selected: false });
      props.handleSuccessMerge && props.handleSuccessMerge(newItems);
      props.handleCloseDialog();
    } else if (chipType === 'System.Int32') {
      const numberValue = Number.parseFloat(mergeValue);
      if (Number.isNaN(numberValue)) {
        setCanMerge(() => false);
        setErrorText(() => 'Cannot merge text into date');
      } else {
        setCanMerge(() => true);
        setErrorText(() => '');

        const newItems = items.filter((item) => !item.selected);
        newItems.push({ value: numberValue.toString(), type: chipType, selected: false });
        props.handleSuccessMerge && props.handleSuccessMerge(newItems);
        props.handleCloseDialog();
      }
    }
  };

  const handleChipTypeChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'System.DateTime') {
      if (!moment(mergeValue).isValid()) {
        setCanMerge(() => false);
        setErrorText(() => 'Cannot merge text into date');
      } else {
        setCanMerge(() => true);
        setErrorText(() => '');
      }
    } else if (chipType === 'System.Int32') {
      const numberValue = Number.parseFloat(mergeValue);
      if (Number.isNaN(numberValue)) {
        setCanMerge(() => false);
        setErrorText(() => 'Cannot merge text into date');
      } else {
        setCanMerge(() => true);
        setErrorText(() => '');
      }
    } else {
      setCanMerge(() => true);
      setErrorText(() => '');
    }

    setChipType(() => event.target.value);
  };

  return (
    <Dialog open={true} onClose={props.handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_MERGE))}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {chipType === 'System.String' && (
                  <TextField
                    label={capitalize(t(TXT_VALUE))}
                    value={mergeValue}
                    onChange={(event) => setMergeValue(() => event.target.value)}
                    error={!canMerge}
                    helperText={errorText}
                  />
                )}
                {(chipType === 'System.DateTime' || chipType === 'System.DateOnly') && (
                  <DateTimePicker
                    label={capitalize(t(TXT_VALUE))}
                    value={moment(mergeValue)}
                    dateFormat={dateFormat}
                    onChange={(value) => setMergeValue(() => (value ? value.toISOString() : ''))}
                  />
                )}
                {chipType === 'System.Int32' && (
                  <TextField
                    label={capitalize(t(TXT_VALUE))}
                    value={_.toNumber(mergeValue)}
                    onChange={(event) => setMergeValue(() => _.toNumber(event.target.value).toString())}
                    error={!canMerge}
                    helperText={errorText}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="lbl-search-deal-status">{capitalize(t(TXT_TYPE))}</InputLabel>
                  <Select
                    labelId="dlbl-search-deal-status"
                    id="demo-simple-select"
                    value={chipType}
                    label="Type"
                    onChange={handleChipTypeChange}
                  >
                    <MenuItem value={'System.DateTime'}>{capitalizeFirstLetterEveryword(t(TXT_DATE))}</MenuItem>
                    <MenuItem value={'System.Int32'}>{capitalizeFirstLetterEveryword(t(TXT_NUMBER))}</MenuItem>
                    <MenuItem value={'System.String'}>{capitalizeFirstLetterEveryword(t(TXT_TEXT))}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog}>{capitalize(t(TXT_CANCEL))}</Button>
        <Button onClick={onSave} disabled={!canMerge}>
          {capitalize(t(TXT_SAVE))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
