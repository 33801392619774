import { ApolloError } from '@apollo/client';
import { TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InterruptionColumn } from '.';
import { CustomError } from 'components/CustomError';
import { interruptionModel } from 'model';
import { TXT_LOADING } from '../../../../../../../../shared/translations';
import moment from 'moment';
import { formatDuration, getDateTimeFormat } from 'shared/utils';

export interface InterruptionTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  interruptions?: interruptionModel[];
  columns: InterruptionColumn[];
}

export const InterruptionTableBody = ({
  columns,
  loading,
  error,
  interruptions
}: InterruptionTableBodyProps) => {
  const { t } = useTranslation();

  const dateTimeFormat = getDateTimeFormat();

  return (
    <TableBody>
      {loading && (
        <TableRow>
          <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
            <CircularProgress />
            <h3>{t(TXT_LOADING).toUpperCase()}</h3>
          </TableCell>
        </TableRow>
      )}
      {!loading && error && error.message && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <CustomError>{error.message}</CustomError>
          </TableCell>
        </TableRow>
      )}
      {!loading &&
        interruptions &&
        interruptions.map((interruption) => (
          <TableRow key={`row-${interruption.id}`}>
            <TableCell align="left">{interruption.description}</TableCell>
            <TableCell align="left">
              {interruption.since && moment(interruption.since).format(dateTimeFormat)}
            </TableCell>
            <TableCell align="left">{interruption.to && moment(interruption.to).format(dateTimeFormat)}</TableCell>
            <TableCell align="center">{interruption.toDiscount ? 'Yes' : ''}</TableCell>
            <TableCell align="left">{formatDuration(interruption.notCountAsLaytime || 0)}</TableCell>
            <TableCell align="right">{interruption.percentage}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};
