import { IconButton, useTheme } from '@mui/material';
import { AddDealInput } from 'api';
import { DealPortEditorContent } from 'components/DealPortEditorContent';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_LOADING_PORT } from '../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext } from 'react';
import { DealWizardContext } from './DealWizard';

interface DealWizardLoadingPortProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const DealWizardLoadingPort = (props: DealWizardLoadingPortProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);

  const watchLoadingPort = useWatch({
    control: control,
    name: `loadingPorts.${props.index}`
  });

  return (
    <fieldset>
      <legend>{watchLoadingPort.port?.name || `${t(TXT_LOADING_PORT).toUpperCase()} #${props.index + 1}`}</legend>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginBottom: theme.spacing(1)
        }}
      >
        <IconButton
          aria-label={`delete-loading-port-${props.index}`}
          onClick={() => props.handleRemove(props.index)}
          disabled={isDisabled}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <DealPortEditorContent isLoadingPort index={props.index} />
    </fieldset>
  );
};
