import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const ADD_DEAL_COMMENT = gql`
  mutation addDealComment($input: AddDealCommentInput!) {
    result: addDealComment(input: $input) {
      ${BaseCommentQueryData}
    }
  }
`;

export const UPDATE_DEAL_COMMENT = gql`
  mutation updateDealComment($input: UpdateDealCommentInput!) {
    result: updateDealComment(input: $input) {
      ${BaseCommentQueryData}
    }
  }
`;

export const DELETE_DEAL_COMMENT = gql`
  mutation deleteDealComment($input: DeleteDealCommentInput!) {
    result: deleteDealComment(input: $input) 
  }
`;
