import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_API_KEY,
  TXT_CANCEL,
  TXT_CREATE_API_KEY,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../../shared/translations';
import { AddIntegrationInput, ADD_INTEGRATION } from '../../api';
import { useForm, SubmitHandler } from 'react-hook-form';
import { integrationModel } from 'model';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import shortUUID from 'short-uuid';

export interface IntegrationEditorProps extends integrationModel {
  onClose: () => void;
  afterSave: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  key: yup.string().required()
});

export const IntegrationEditor = (props: IntegrationEditorProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddIntegrationInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      key: props.key || shortUUID.generate()
    }
  });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [mutateIntegration, { loading }] = useMutation(
    ADD_INTEGRATION,
    {
      onError: apolloErrorHandler,
      onCompleted: () => {
        props.afterSave();
        props.onClose();
      }
    }
  );

  const onSubmit: SubmitHandler<AddIntegrationInput> = (data) => {
    mutate(data);
  };

  const mutate = (data: AddIntegrationInput) => {
    if (!props.id) {
      return mutateIntegration({
        variables: {
          input: {
            ...data
          }
        }
      });
    }

    return mutateIntegration({
      variables: {
        input: {
          id: props.id,
          ...data
        }
      }
    });
  };

  const title = t(TXT_CREATE_API_KEY);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth={true} onClose={props.onClose}>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="integration-editor-form">
          <TextField
            margin="normal"
            autoFocus
            id="name"
            label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
            fullWidth
            disabled={loading}
            error={!!errors.name}
            defaultValue={props.name}
            {...register('name', { required: true })}
          />
          <TextField
            margin="normal"
            id="key"
            label={t(TXT_API_KEY).toUpperCase()}
            fullWidth
            error={!!errors.key}
            defaultValue={props.key}
            InputProps={{
              readOnly: true
            }}
            {...register('key', { required: true })}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="integration-editor-form">
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
