import { useLazyQuery } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { AddStatementOfFactInput, GET_PORTS, GetPortsOutput } from 'api';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import { DateTimePickerWithDrop, NumberFieldWithDrop, PortSelector, TextFieldWithDrop, UserSelector } from 'components';
import { useApolloErrorHandler } from 'hooks';
import { t } from 'i18next';
import _ from 'lodash';
import { portModel } from 'model';
import moment from 'moment';
import { PortEditor } from 'pages/Ports/PortEditor';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import {
  TXT_VESSEL,
  TXT_PORT,
  TXT_QUANTITY,
  TXT_ARRIVAL_DATE,
  TXT_NOR_TENDERED,
  TXT_TRAFFIC_ANALYST,
  TXT_ASSIGN_TO
} from '../../../../../../shared/translations';

export const StatementOfFactInfoWizard = () => {
  const { control, setValue, setError } = useFormContext<AddStatementOfFactInput>();
  const dateFormat = getDateFormat();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const watchVesselName = useWatch({
    control,
    name: 'vesselName'
  });

  const watchQuantity = useWatch({
    control,
    name: 'quantity'
  });

  const watchPortName = useWatch({
    control,
    name: 'portName'
  });

  const watchArrivalDate = useWatch({
    control,
    name: 'arrivalDate'
  });

  const watchNorTenderedDate = useWatch({
    control,
    name: 'norTenderedDate'
  });

  const watchTrafficSpecialist = useWatch({
    control,
    name: 'trafficSpecialist'
  });

  const watchAssignTo = useWatch({
    control,
    name: 'assignTo'
  });

  const [port, setPort] = useState<portModel | undefined>(undefined);
  const [showPortEditor, setShowPortEditor] = useState(false);

  const [getPort] = useLazyQuery<GetPortsOutput>(GET_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        name: {
          eq: watchPortName
        }
      },
      take: 1,
      skip: 0
    },
    onCompleted: (data) => {
      return data;
    },
    onError: apolloErrorHandler
  });

  const checkPort = async () => {
    if (!!watchPortName) {
      const data = await getPort();
      const ports = data.data?.ports;
      if (ports && ports.items && ports.items.length > 0) {
        const port = ports.items[0];
        setPort(port);
        setValue('portId', port?.id, {
          shouldValidate: true
        });
        setValue('portName', port?.name);
      } else {
        setError('portId', { message: 'Port doesn\'t exist. Select port or resolve to create new port' });
        setPort(undefined);
      }
    }
  };

  useEffect(() => {
    checkPort();
  }, []);

  const handlePortChanged = (port: portModel | null) => {
    const newPort = port === null ? undefined : port;
    setValue('portId', newPort?.id, {
      shouldValidate: true
    });
    setValue('portName', newPort?.name);
    setPort(newPort);
  };

  const showHidePortEditor = () => {
    setShowPortEditor((curr) => !curr);
  };

  const onAfterSavePort = (newPort: portModel) => {
    setPort(() => newPort);
    setValue('portId', newPort?.id, {
      shouldValidate: true
    });
    setValue('portName', newPort?.name);
  };

  return (
    <Grid container spacing={3} direction="row-reverse">
      {showPortEditor && <PortEditor name={watchPortName} onClose={showHidePortEditor} afterSave={onAfterSavePort} />}
      <Grid item xs={12}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <TextFieldWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_VESSEL))}
                value={watchVesselName}
                onChange={(event) => setValue('vesselName', event.target.value)}
                onDrop={(item) => setValue('vesselName', (item as cognitiveReadOperationResult).value)}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={10} lg={10}>
                  <Controller
                    control={control}
                    name={`portId`}
                    render={({ formState, fieldState }) => (
                      <PortSelector
                        label={t(capitalizeFirstLetterEveryword(TXT_PORT))}
                        port={port}
                        onSelectedChange={handlePortChanged}
                        error={!!formState.errors.portId}
                        errorMessage={formState.errors.portId?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={2}>
                  <Button onClick={showHidePortEditor} disabled={!!port}>
                    RESOLVE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <NumberFieldWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
                value={watchQuantity}
                onChange={(event) => setValue('quantity', _.toNumber(event.target.value))}
                onDrop={(item) => setValue('quantity', _.toNumber((item as cognitiveReadOperationResult).value))}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DateTimePickerWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE))}
                value={moment(watchArrivalDate)}
                dateFormat={dateFormat}
                onChanged={(item, value) => setValue('arrivalDate', value?.toISOString())}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DateTimePickerWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED))}
                value={moment(watchNorTenderedDate)}
                dateFormat={dateFormat}
                onChanged={(item, value) => setValue('norTenderedDate', value?.toISOString())}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name={`trafficSpecialistId`}
                control={control}
                render={({ formState }) => (
                  <UserSelector
                    user={watchTrafficSpecialist}
                    label={TXT_TRAFFIC_ANALYST}
                    onChanged={(value) => {
                      setValue('trafficSpecialistId', value?.id);
                      setValue('trafficSpecialist', value);
                    }}
                    error={!!formState.errors.trafficSpecialistId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name={`assignToId`}
                control={control}
                render={({ formState }) => (
                  <UserSelector
                    user={watchAssignTo}
                    label={TXT_ASSIGN_TO}
                    onChanged={(value) => {
                      setValue('assignToId', value?.id);
                      setValue('assignTo', value);
                    }}
                    error={!!formState.errors.assignToId}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </Grid>
    </Grid>
  );
};
