import { Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { AddDealInput } from 'api';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { CurrencySelector } from 'components/CurrencySelector';
import { VesselSelector } from 'components/VesselSelector';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ACTIVE, TXT_CLOSED, TXT_COUNTERPARTY, TXT_DETAILS, TXT_PORTS, TXT_QUICK_CALC, TXT_QUICK_CALC_INFO } from '../../../../../shared/translations';
import { QuoteWizardUnlodingPorts } from './QuoteWizardUnlodingPorts';
import { QuoteWizardLoadingPorts } from './QuoteWizardLoadingPorts';
import { QuoteWizardSplitQuantities } from './QuoteWizardSplitQuantities';
import { QuoteWizardContext } from './QuoteWizard';
import { useContext } from 'react';

export const QuoteWizardInfo = () => {
  const { control, register, setValue, formState } = useFormContext<AddDealInput>();
  const { isDisabled, onCounterPartyChanged, onVesselChanged } = useContext(QuoteWizardContext);
  const { t } = useTranslation();

  const lblActive = capitalizeFirstLetterEveryword(t(TXT_ACTIVE));
  const lblClosed = capitalizeFirstLetterEveryword(t(TXT_CLOSED));
  const lblName = `${capitalizeFirstLetterEveryword(t(TXT_QUICK_CALC))}`;

  const watchName = useWatch({
    control,
    name: 'name'
  });

  const watchShipowner = useWatch({
    control,
    name: 'shipowner'
  });

  const watchVessel = useWatch({
    control,
    name: 'vessel'
  });

  const watchCurrency = useWatch({
    control,
    name: 'currency'
  });

  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        <Controller
          name="isActive"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Typography>{lblClosed}</Typography>
              <Switch onChange={onChange} checked={value} disabled={isDisabled} />
              <Typography>{lblActive}</Typography>
            </>
          )}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <div>{capitalizeFirstLetterEveryword(t(TXT_QUICK_CALC_INFO))}</div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            id="name"
            label={lblName}
            value={watchName || ''}
            fullWidth
            {...register('name')}
            onChange = {(e) => {
              setValue('name', e.target.value);
              setValue(`splitQuantities.${0}.name`, e.target.value);
            }}
            error={!!formState.errors.name}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name="shipownerId"
            control={control}
            render={({ formState }) => (
              <CounterPartySelector
                counterParty={watchShipowner}
                onChanged={onCounterPartyChanged}
                error={!!formState.errors.shipownerId}
                label={t(TXT_COUNTERPARTY)}
                counterPartyType="ALL"
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name="vesselId"
            control={control}
            render={({ formState }) => (
              <VesselSelector
                vessel={watchVessel}
                onChanged={onVesselChanged}
                error={!!formState.errors.vesselId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name="currency"
            control={control}
            render={({ formState }) => (
              <CurrencySelector
                currency={watchCurrency}
                onSelectedChange={(currency) => {
                  setValue('currency', currency || '');
                }}
                error={!!formState.errors.currency}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <div>{capitalizeFirstLetterEveryword(t(TXT_PORTS))}</div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <QuoteWizardLoadingPorts />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <QuoteWizardUnlodingPorts />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <div>{capitalizeFirstLetterEveryword(t(TXT_DETAILS))}</div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <QuoteWizardSplitQuantities />
        </Grid>
      </Grid>
    </div>
  );
};
