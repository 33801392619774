import { Tooltip, IconButton, CircularProgress, Badge, styled, BadgeProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TXT_WHAT_IF_SCENARIOS } from '../../../../../shared/translations';
import BoltIcon from '@mui/icons-material/Bolt';
import { useState, useEffect } from 'react';
import { useApolloErrorHandler } from 'hooks';
import { GET_QUICK_CALC_COUNT, GetQuickCalcCount } from 'api';
import { useLazyQuery } from '@apollo/client';
import { QuickLaytimeCalcEditor } from './QuickLaytimeCalcEditor';
import { QuoteWizard } from 'components/QuoteWizard';

interface QuickLaytimeCalcProps {
  laytimeCalcId: string;
  title: string;
  isLoading?: boolean;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

export const QuickLaytimeCalc = (props: QuickLaytimeCalcProps) => {
  const { t } = useTranslation();
  const [openEditor, setOpenEditor] = useState(false);

  const [quickCount, setQuickCount] = useState(0);

  const { apolloErrorHandler } = useApolloErrorHandler();
  const [getQuickCalcCount, { loading: loadQuickCount }] = useLazyQuery<GetQuickCalcCount>(GET_QUICK_CALC_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      parentLaytimeCalculationId: props.laytimeCalcId
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      setQuickCount(() => data.quickCalcCount);
    }
  });

  const openCloseEditor = () => setOpenEditor((prev) => !prev);

  const onEditorClosed = () => {
    getQuickCalcCount();
    setOpenEditor(false);
  };

  useEffect(() => {
    getQuickCalcCount();
  }, [props.laytimeCalcId]);

  return (
    <>
      {openEditor ? (
        quickCount > 0 ? (
          <QuickLaytimeCalcEditor
            isPortLoading={props.isLoading}
            calculationId={props.laytimeCalcId}
            onCancel={onEditorClosed}
            title={props.title}
          />
        ) : (
          <QuoteWizard
            parentLaytimeCalculationId={props.laytimeCalcId}
            afterSave={onEditorClosed}
            onClose={onEditorClosed}
          />
        )
      ) : null}
      {loadQuickCount ? (
        <CircularProgress size={20} />
      ) : (
        <>
          <Tooltip title={t(TXT_WHAT_IF_SCENARIOS)} placement="bottom">
            <IconButton aria-label="print" size="small" onClick={openCloseEditor}>
              <StyledBadge badgeContent={quickCount} color="warning" max={99}>
                <BoltIcon fontSize="inherit" />
              </StyledBadge>
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};
