import { Typography } from '@mui/material';

export const Copyright = () => {
  const createdYear = 2021;
  const currentYear = new Date().getFullYear();
  const yearText = createdYear != currentYear ?
    `${createdYear} - ${currentYear}` : `${createdYear}`;
  const text = `Copyright © ${yearText}.`;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {text}
    </Typography>
  );
};
