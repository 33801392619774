import { IconButton, Typography } from '@mui/material';
import { LaytimeCalculationStatuses, laytimeChartReportModel } from 'model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getStatusName } from 'shared/utils';
import {
  TXT_BILL_OF_LADING_DATE,
  TXT_RECEIVER,
  TXT_COMPANY,
  TXT_CURRENCY,
  TXT_DISCHARGE_COUNTRY,
  TXT_DISCHARGE_PORT,
  TXT_REPORT_COPIED,
  TXT_LOADING_PORT,
  TXT_LOSS,
  TXT_NET,
  TXT_PRODUCT,
  TXT_PROFIT,
  TXT_SETTLE_STATUS,
  TXT_SHIPOWNER,
  TXT_SUPPLIER,
  TXT_TERM,
  TXT_TRADER,
  TXT_TRAFFIC_SPECIALIST,
  TXT_VESSEL
} from '../../../../../shared/translations';
import { useProfitAndLossReportContentStyles } from './ProfitAndLossReportContentStyle';
import clsx from 'clsx';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useRef } from 'react';

interface ProfitAndLossReportContentProps extends laytimeChartReportModel {}

export const ProfitAndLossReportContent = (props: ProfitAndLossReportContentProps) => {
  const { t } = useTranslation();
  const style = useProfitAndLossReportContentStyles();
  const netLoading = props.loadingSupplier + props.loadingReceiver + props.loadingShipowner;
  const netUnloading = props.unloadingSupplier + props.unloadingReceiver + props.unloadingShipowner;
  const pnl = netLoading + netUnloading;
  const { enqueueSnackbar } = useSnackbar();
  const reportRef = useRef<HTMLDivElement>(null);

  const copyReport = async () => {
    const src = reportRef.current;
    if (src === null) {
      return;
    }
    const innerHtml = src.innerHTML;
    const type = 'text/html';
    const blob = new Blob([innerHtml], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data);
    enqueueSnackbar(<Typography variant="subtitle1">{_.capitalize(t(TXT_REPORT_COPIED))}</Typography>, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      autoHideDuration: 2000
    });
  };

  const getStatusText = (status: string) => {
    const found = LaytimeCalculationStatuses.find((s) => s.toUpperCase() === status.toUpperCase());
    return found ? getStatusName(found, t) : '';
  };

  return (
    <>
      <div className={style.pnlActions}>
        <IconButton size="small" onClick={copyReport}>
          <ContentCopyIcon />
        </IconButton>
      </div>
      <div id="pnl-report" ref={reportRef} className={style.pnlContainer}>
        <div className={style.pnlTitle}>
          <h2>{props.deal.toUpperCase()}</h2>
        </div>
        <div className={style.pnlInfo}>
          <div
            style={{
              flexGrow: 1
            }}
          >
            <table className={style.pnlTable}>
              <tbody>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_TRAFFIC_SPECIALIST))}</td>
                  <td>{props.trafficSpecialist}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_VESSEL))}</td>
                  <td>{props.vessel}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_BILL_OF_LADING_DATE))}</td>
                  <td>{props.blDate ? moment(props.blDate).format('MMM DD, yyyy') : ''}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}</td>
                  <td>{props.client}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_PRODUCT))}</td>
                  <td>{props.product}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              flexGrow: 1
            }}
          >
            <table className={style.pnlTable}>
              <tbody>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_CURRENCY))}</td>
                  <td>{props.currency}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_TERM))}</td>
                  <td>{props.incoTerm}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_TRADER))}</td>
                  <td>{props.trader}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_COMPANY))}</td>
                  <td>{props.company}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_DISCHARGE_COUNTRY))}</td>
                  <td>{props.countryOfDestination}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
        <table className={style.pnlTable}>
          <thead>
            <tr>
              <td style={{ border: '0', width: '250px' }}></td>
              <td className={clsx({ [style.header2]: true })}>
                <div>{capitalizeFirstLetterEveryword(t(TXT_LOADING_PORT))}</div>
                <div>{props.loadingPort}</div>
              </td>
              <td className={clsx({ [style.header2]: true })}>
                <div>{capitalizeFirstLetterEveryword(t(TXT_DISCHARGE_PORT))}</div>
                <div>{props.unloadingPort}</div>
              </td>
              <td className={clsx({ [style.header2]: true })}>{capitalizeFirstLetterEveryword(t(TXT_SETTLE_STATUS))}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={clsx({ [style.header2]: true })}>{capitalizeFirstLetterEveryword(t(TXT_SUPPLIER))}</td>
              <td
                className={clsx(
                  { [style.profit]: props.loadingSupplier >= 0 },
                  { [style.loss]: props.loadingSupplier < 0 }
                )}
              >
                {props.loadingSupplier.toLocaleString()}
              </td>
              <td
                className={clsx(
                  { [style.profit]: props.unloadingSupplier >= 0 },
                  { [style.loss]: props.unloadingSupplier < 0 }
                )}
              >
                {props.unloadingSupplier.toLocaleString()}
              </td>
              <td style={{ textAlign: 'center' }}>{getStatusText(props.settleWithSupplier)}</td>
            </tr>
            <tr>
              <td className={clsx({ [style.header2]: true })}>{capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}</td>
              <td
                className={clsx(
                  { [style.profit]: props.loadingReceiver >= 0 },
                  { [style.loss]: props.loadingReceiver < 0 }
                )}
              >
                {props.loadingReceiver.toLocaleString()}
              </td>
              <td
                className={clsx(
                  { [style.profit]: props.unloadingReceiver >= 0 },
                  { [style.loss]: props.unloadingReceiver < 0 }
                )}
              >
                {props.unloadingReceiver.toLocaleString()}
              </td>
              <td style={{ textAlign: 'center' }}>{getStatusText(props.settleWithReceiver)}</td>
            </tr>
            <tr>
              <td className={clsx({ [style.header2]: true })}>{capitalizeFirstLetterEveryword(t(TXT_SHIPOWNER))}</td>
              <td
                className={clsx(
                  { [style.profit]: props.loadingShipowner >= 0 },
                  { [style.loss]: props.loadingShipowner < 0 }
                )}
              >
                {props.loadingShipowner.toLocaleString()}
              </td>
              <td
                className={clsx(
                  { [style.profit]: props.unloadingShipowner >= 0 },
                  { [style.loss]: props.unloadingShipowner < 0 }
                )}
              >
                {props.unloadingShipowner.toLocaleString()}
              </td>
              <td style={{ textAlign: 'center' }}>{getStatusText(props.settleWithShipowner)}</td>
            </tr>
            <tr>
              <td className={clsx({ [style.header2]: true })}>{capitalizeFirstLetterEveryword(t(TXT_NET))}</td>
              <td className={clsx({ [style.profit]: netLoading >= 0 }, { [style.loss]: netLoading < 0 })}>
                {netLoading.toLocaleString()}
              </td>
              <td className={clsx({ [style.profit]: netUnloading >= 0 }, { [style.loss]: netUnloading < 0 })}>
                {netUnloading.toLocaleString()}
              </td>
              <td style={{ border: '0' }}></td>
            </tr>
            <tr>
              <td className={clsx({ [style.header2]: true })}>{`${capitalizeFirstLetterEveryword(t(TXT_PROFIT))} / ${capitalizeFirstLetterEveryword(
                t(TXT_LOSS)
              )}`}</td>
              <td colSpan={2} className={clsx({ [style.profit]: pnl >= 0 }, { [style.loss]: pnl < 0 })}>{pnl.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
