import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box
} from '@mui/material';
import { interruptionModel } from 'model';
import { ShexCondition } from 'pages/DemurragePage/DemurrageContent/ShexConditionsEditor/ShexConditionsEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_NO, TXT_VESSEL_INFORMATION } from '../../../../../shared/translations';
import { InterruptionsCheckbox } from './InterruptionsCheckbox';
import { PasteCalculationSubmitButton } from './PasteCalculationSubmitButton';
import { ShexConditionsCheckbox } from './ShexConditionsCheckbox';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { useDemurrageService } from 'hooks';

export interface PasteCalculationsModalProps {
  onCancel: () => void;
  txtConfirmation: string;
  id: string;
  onSaved?: () => void;
}

export interface PasteCalculationsModalResponse {
  id: string;
  interruptions: interruptionModel[];
}

export const PasteCalculationsModal = ({ txtConfirmation, onCancel, id, onSaved }: PasteCalculationsModalProps) => {
  const { t } = useTranslation();
  const { calculationReadyToBePastedId } = useDemurrageService();
  const [loadingInterruptions, setLoadingInterruptions] = useState<boolean>(true);
  const [loadingShex, setLoadingShex] = useState<boolean>(false);
  const [interruptions, setInterruptions] = useState<interruptionModel[]>();
  const [shexConditions, setShexConditions] = useState<ShexCondition[]>();
  const loading = loadingInterruptions || loadingShex;
  const [errors, setErrors] = useState<string[]>([]);

  const handleSave = () => {
    if (onSaved) {
      onSaved();
    }
    onCancel();
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>{txtConfirmation}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          label={'' + capitalizeFirstLetterEveryword(t(TXT_VESSEL_INFORMATION))}
          control={<Checkbox checked={true} disabled></Checkbox>}
        ></FormControlLabel>
        <InterruptionsCheckbox
          onChange={(value) => setInterruptions(value)}
          calculationId={calculationReadyToBePastedId}
          onLoadingCompleted={() => setLoadingInterruptions(false)}
        />
        <ShexConditionsCheckbox
          onChange={(value) => setShexConditions(value)}
          calculationId={calculationReadyToBePastedId}
          onLoadingCompleted={() => setLoadingShex(false)}
        />
      </DialogContent>
      <Box paddingLeft={2}>
        {errors.map((e) => (
          <Typography key={e}>{e}</Typography>
        ))}
      </Box>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" disabled={loading}>
          {t(TXT_NO)}
        </Button>
        <PasteCalculationSubmitButton
          calculationId={id}
          interruptions={interruptions}
          shexConditions={shexConditions}
          loading={loading}
          onSaved={handleSave}
          onError={(e) => setErrors(e)}
        />
      </DialogActions>
    </Dialog>
  );
};
