import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { t } from 'i18next';
import { ShexCondition } from 'pages/DemurragePage/DemurrageContent/ShexConditionsEditor/ShexConditionsEditor';
import { GetShexConditionsOutput, GET_SHEX_CONDITIONS } from 'api/graphql/shexCondition';
import { getStringTimeFromMinutes } from 'shared/utils';
import { TXT_CALENDAR_RULES } from '../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';

interface ShexConditionsCheckboxProps {
  calculationId?: string;
  onChange: (shexConditions?: ShexCondition[]) => void;
  onLoadingCompleted: () => void;
}

const getFilter = (calculationId?: string) => {
  const result = {
    laytimeCalculationId: {
      eq: calculationId || '00000000-0000-0000-0000-000000000000'
    }
  };
  return result;
};

export const ShexConditionsCheckbox = (props: ShexConditionsCheckboxProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [shexConditions, setShexConditions] = React.useState<ShexCondition[]>([]);
  const [getData, { data, refetch }] = useLazyQuery<GetShexConditionsOutput>(GET_SHEX_CONDITIONS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(props.calculationId)
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      const conditions = (data.shexConditions || []).map((x) => ({
        ...x,
        fromTime: getStringTimeFromMinutes(x.fromTime),
        untilTime: getStringTimeFromMinutes(x.untilTime)
      }));

      setShexConditions(conditions);
      setLoading(false);
      props.onLoadingCompleted();
    }
  });

  React.useEffect(() => {
    if (!props.calculationId) {
      return;
    }
    fetch();
  }, [!props.calculationId]);

  const fetch = () => {
    if (!refetch) {
      getData();
    } else {
      refetch();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      props.onChange(shexConditions);
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <FormControlLabel
      disabled={loading}
      label={'' + data?.shexConditions.length + ' ' + capitalizeFirstLetterEveryword(t(TXT_CALENDAR_RULES))}
      control={<Checkbox onChange={handleOnChange}></Checkbox>}
    ></FormControlLabel>
  );
};
