import { Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_CONFIRM_PASTE_PARAMS_TEXT, TXT_PASTE } from '../../../../../shared/translations';
import PasteIcon from '@mui/icons-material/ContentPaste';
import { useState } from 'react';
import { useDemurrageService } from 'hooks';
import { PasteCalculationsModal } from 'components/PasteCalculationsModal';
import _ from 'lodash';

interface PasteCalculationProps {
  size?: 'small' | 'medium' | 'large';
  calculationId: string;
  afterSave?: () => void;
}

export const PasteCalculation = (props: PasteCalculationProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { calculationReadyToBePastedId } = useDemurrageService();
  const { t } = useTranslation();

  const openCloseModal = () => setOpenModal((prev) => !prev);

  return (
    <>
      {openModal && (
        <PasteCalculationsModal
          onCancel={openCloseModal}
          txtConfirmation={_.capitalize(t(TXT_CONFIRM_PASTE_PARAMS_TEXT))}
          id={props.calculationId}
          onSaved={props.afterSave}
        />
      )}
      <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_PASTE))}>
        <span>
          <IconButton
            color="primary"
            aria-label="paste"
            size={props.size || 'small'}
            onClick={openCloseModal}
            disabled={!calculationReadyToBePastedId || calculationReadyToBePastedId === props.calculationId}
          >
            <PasteIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
