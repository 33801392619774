import { gql } from '@apollo/client';
import { holidayModel } from 'model';

export interface AddHolidayInput extends Omit<holidayModel, 'id'> { }
export interface UpdateHolidayInput extends AddHolidayInput { }
export interface DeleteHolidayInput { }

export const ADD_HOLIDAY = gql`
  mutation addHoliday($input: AddHolidayInput!) {
    addHoliday(input: $input) {
      id,
      name
    }
  }
`;

export const UPDATE_HOLIDAY = gql`
  mutation updateHoliday($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      id,
      name
    }
  }
`;

export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($input: DeleteHolidayInput!) {
    deleteHoliday(input: $input)
  }
`;
