import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar
} from '@mui/material';
import {
  UPDATE_CONTACT_PHONES,
  UpdateContactPhoneInput,
  UpdateContactPhonesInput,
  UpdateContactPhonesOutput
} from 'api';
import { contactPhoneModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { Fragment, useContext } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ADD, TXT_CANCEL, TXT_NAME, TXT_PHONE, TXT_PHONES, TXT_SAVE, TXT_SAVING } from '../../../../../../shared/translations';
import * as yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useMutation } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks';
import _ from 'lodash';
import { MaritimeDirectoriesContext } from '../MaritimeDirectories';

interface MaritimeDirectoryPhonesEditorProps {
  contactId: string;
  phones: contactPhoneModel[];
  onClose: () => void;
}

const phoneSchema = yup.object().shape({
  strId: yup.string().required(),
  name: yup.string().required(),
  phone: yup.string().required()
});

const schema = yup.object({
  contactId: yup.string().required(),
  updateInputs: yup.array().of(phoneSchema)
});

export const MaritimeDirectoryPhonesEditor = (props: MaritimeDirectoryPhonesEditorProps) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { refreshData } = useContext(MaritimeDirectoriesContext);

  const { control, handleSubmit, register, formState, setValue } = useForm<UpdateContactPhonesInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      contactId: props.contactId,
      updateInputs: (props.phones || []).map((x) => {
        const result: UpdateContactPhoneInput = {
          ..._.omit(x, ['id']),
          strId: x.id!
        };
        return result;
      })
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'updateInputs'
  });

  const [mutate, { loading }] = useMutation<UpdateContactPhonesOutput>(UPDATE_CONTACT_PHONES, {
    onError: apolloErrorHandler,
    onCompleted: () => {
      refreshData();
      props.onClose();
    }
  });

  const onAdd = () => {
    const newPhone: UpdateContactPhoneInput = {
      strId: 'new-phone',
      contactId: props.contactId
    };
    append(newPhone);
  };

  const onSave: SubmitHandler<UpdateContactPhonesInput> = (data) => {
    mutate({
      variables: {
        input: data
      }
    });
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSave)}>
        <AppBar position="relative">
          <Toolbar>{t(TXT_PHONES).toUpperCase()}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              return (
                <Fragment key={field.id}>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={`updateInputs.${index}.name`}
                      render={({ fieldState }) => (
                        <Autocomplete
                          freeSolo
                          options={['Personal', 'Work']}
                          defaultValue={field.name}
                          onInputChange={(_, value) =>
                            setValue(`updateInputs.${index}.name` as const, value || undefined)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                              fullWidth
                              size="small"
                              disabled={loading}
                              error={!!fieldState.error}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id={`updateInputs.${index}.phone`}
                      label={capitalizeFirstLetterEveryword(t(TXT_PHONE))}
                      fullWidth
                      size="small"
                      disabled={loading}
                      {...register(`updateInputs.${index}.phone` as const)}
                      error={!!formState.errors.updateInputs?.[index]?.phone}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    <IconButton
                      className={globalStyles.deleteButton}
                      aria-label="delete phone"
                      component="span"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-between'
          }}
        >
          <Button onClick={onAdd} color="info" startIcon={<AddIcon />} disabled={loading}>
            {t(TXT_ADD)}
          </Button>
          <div>
            <Button onClick={props.onClose} color="secondary" disabled={loading}>
              {t(TXT_CANCEL)}
            </Button>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
              {t(loading ? TXT_SAVING : TXT_SAVE)}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};
