import { Button, CircularProgress, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerTenant, registerUserInput } from '../../api';
import { CustomError } from '../../components';
import useAuth from '../../hooks/useAuth';
import { TXT_BACK_TO_SIGN_IN_PAGE, TXT_REGISTER_A_NEW_TENANT, TXT_SAVE, TXT_SAVING } from '../../../../../shared/translations';
import { RegisterTenantPage } from './RegisterTenantPage';
import _ from 'lodash';
import { useAuthService } from 'hooks/useAuthServices';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { dashboardPath } from 'shared/links';
import { useTitle } from 'hooks';

interface RegisterTenantProps {}

export const RegisterTenant = (props: RegisterTenantProps) => {
  useTitle(TXT_REGISTER_A_NEW_TENANT);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [registerUser, setRegisterUser] = useState<registerUserInput>({});
  const [isValid, setIsValid] = useState(false);
  const [isUserValid, setIsUserValid] = useState(false);
  const [, setAuthState] = useAuth();
  const { signOut, isSignedIn } = useAuthService();
  const theme = useTheme();

  const navigate = useNavigate();

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onAliasChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setAlias(event.currentTarget.value);
  };

  const onRegisterUserChanged = (input: registerUserInput) => {
    setRegisterUser({
      ...input
    });
  };

  const onUserValidated = (valid: boolean) => {
    setIsUserValid(valid);
  };

  const validate = () => {
    const requiredTenantFields = !!name && !!alias;
    setIsValid(requiredTenantFields);
  };

  const onSubmit = async () => {
    if (!isValid || !isUserValid) {
      return;
    }
    setIsSaving(true);
    const signInResponse = await registerTenant({
      name,
      alias,
      ...registerUser
    });
    setIsSaving(false);
    setAuthState({
      ...signInResponse
    });
    setErrorMessage(signInResponse.errorMessage);

    if (!signInResponse.errorMessage) {
      navigate(dashboardPath);
    }
  };

  useEffect(() => {
    validate();
  }, [name, alias, registerUser]);

  useEffect(() => {
    isSignedIn().then((result) => {
      if (result.isSignedIn) {
        navigate(dashboardPath);
      }
    });
  }, []);

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        marginTop: theme.spacing(10)
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!!errorMessage && <CustomError>{_.capitalize(t(errorMessage))}</CustomError>}
        </Grid>
        <Grid item xs={12}>
          <RegisterTenantPage
            name={name}
            onNameChanged={onNameChanged}
            alias={alias}
            onAliasChanged={onAliasChanged}
            onRegisterUserChanged={onRegisterUserChanged}
            onRegisterUserValidated={onUserValidated}
            isSaving={isSaving}
            onSubmit={onSubmit}
          />
        </Grid>
        <Grid item xs={6}>
          <Button onClick={signOut} color="secondary" disabled={isSaving} fullWidth variant="contained">
            {t(TXT_BACK_TO_SIGN_IN_PAGE)}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onSubmit} disabled={isSaving || !isValid || !isUserValid} fullWidth variant="contained">
            {isSaving && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(isSaving ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
