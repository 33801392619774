import { TextField, TextFieldProps } from '@mui/material';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import { useDrop } from 'react-dnd';

export type NumberFieldWithDropProps = {
  onDrop?: (item: cognitiveReadOperationResult) => void;
} & TextFieldProps;

export const NumberFieldWithDrop = (props: NumberFieldWithDropProps) => {
  const { value, onDrop, ...rest } = props;

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'CHIP',
    drop: (value: { item: cognitiveReadOperationResult }) => {
      if (value.item.type === 'System.Int32') {
        !!onDrop && onDrop(value.item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop() && monitor.getItem().item.type === 'System.Int32'
    })
  }));

  return (
    <div>
      <TextField
        value={value}
        inputRef={drop}
        label={props.label}
        fullWidth
        style={{
          backgroundColor: isOver && canDrop ? '#c8e6c9' : 'white'
        }}
        disabled={props.disabled}
        type='number'
        {...rest}
      />
    </div>
  );
};
