import { gql } from '@apollo/client';
import { portModel } from 'model';

export interface AddPortInput extends Omit<portModel, 'id'> {
}

export interface UpdatePortInput extends AddPortInput {
  id: string;
}

export interface DeletePortInput {
  id: string;
}

const portFragment = `
  id
  name
  country
  latitude
  longitude
`;

export interface PortMutationOutput {
  port: portModel;
}

export const ADD_PORT = gql`
  mutation addPort($input: AddPortInput!) {
    port: addPort(input: $input) {
      ${portFragment}
    }
  }
`;

export const UPDATE_PORT = gql`
  mutation updatePort($input: UpdatePortInput!) {
    port: updatePort(input: $input) {
      ${portFragment}
    }
  }
`;

export const DELETE_PORT = gql`
  mutation deletePort($input: DeletePortInput!) {
    deletePort(input: $input)
  }
`;
