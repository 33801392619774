/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CargoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M 4 3 L 4 5 L 2 5 L 2 10 L 7 10 L 7 8 L 8 7 L 8 5 L 5 5 L 5 3 L 4 3 z M 11 8 L 11 10 L 14 10 L 14 8 L 11 8 z M 15 8 L 15 10 L 18 10 L 18 8 L 15 8 z M 2 11 L 2 12.59375 C 2 13.49375 2.3125 14.3 2.8125 15 L 4.25 17 C 3.5562081 16.958474 2.6265193 16.716022 2 16 L 2 18 C 2.7 18.8 3.8 19 4.5 19 C 5.2 19 6.3 18.8 7 18 C 7.7 18.8 8.8 19 9.5 19 C 10.2 19 11.3 18.8 12 18 C 12.7 18.8 13.8 19 14.5 19 C 15.2 19 16.3 18.8 17 18 C 17.7 18.8 18.8 19 19.5 19 C 20.2 19 21.3 18.8 22 18 L 22 16 C 21.3 16.8 20.2 17 19.5 17 C 19.175536 17 18.760424 16.969525 18.34375 16.84375 C 18.993409 16.359107 19.579443 15.776279 20 15.1875 C 21 13.7875 22 11.9 22 11 L 2 11 z M 17 13 L 19 13 L 19 14 L 17 14 L 17 13 z M 2 19 L 2 21 C 2.7 21.8 3.8 22 4.5 22 C 5.2 22 6.3 21.8 7 21 C 7.7 21.8 8.8 22 9.5 22 C 10.2 22 11.3 21.8 12 21 C 12.7 21.8 13.8 22 14.5 22 C 15.2 22 16.3 21.8 17 21 C 17.7 21.8 18.8 22 19.5 22 C 20.2 22 21.3 21.8 22 21 L 22 19 C 21.3 19.8 20.2 20 19.5 20 C 18.8 20 17.7 19.8 17 19 C 16.3 19.8 15.2 20 14.5 20 C 13.8 20 12.7 19.8 12 19 C 11.3 19.8 10.2 20 9.5 20 C 8.8 20 7.7 19.8 7 19 C 6.3 19.8 5.2 20 4.5 20 C 3.8 20 2.7 19.8 2 19 z" />
    </SvgIcon>
  );
};
