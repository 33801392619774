import { Fragment, ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_PORTS, GetPortsOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { portModel } from '../../model';
import _ from 'lodash';
import { TXT_AND_MORE, TXT_CREATE_PORT } from '../../../../../shared/translations';
import { useTranslation } from 'react-i18next';
import { Checkbox, Chip, Link } from '@mui/material';
import { PortEditor } from 'pages/Ports/PortEditor';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface PortSelectorProps {
  id?: string;
  port?: portModel;
  ports?: portModel[];
  label: string;
  error?: boolean;
  showAllSelection?: boolean;
  onSelectedChange?: (port: portModel | null) => void;
  onSelectedPortsChange?: (ports: portModel[] | null) => void;
  disabled?: boolean;
  country?: string;
  allowAdd?: boolean;
  multiple?: boolean;
  errorMessage?: string;
}

const VISIBLE_OPTIONS_COUNT = 5;

export const PortSelector = (props: PortSelectorProps) => {
  const [selected, setSelected] = useState<portModel | null>(props.port || null);
  const [selectedPorts, setSelectedPorts] = useState<portModel[]>(!!props.ports ? [...props.ports] : []);
  const [text, setText] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<portModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);

  const getFilter = () => {
    let result = {};
    if (!_.isNil(text) && text.length > 0) {
      result = {
        ...result,
        name: {
          contains: text
        }
      };
    }

    if (!!props.country) {
      result = {
        ...result,
        country: {
          eq: props.country
        }
      };
    }

    return _.isEqual(result, {}) ? null : { and: [{ ...result }] };
  };

  const [getPorts, { loading }] = useLazyQuery<GetPortsOutput>(GET_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      let portOptions: portModel[] = data?.ports?.items || [];
      const totalCount = data?.ports?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        portOptions = [
          ...portOptions,
          {
            id: 'port-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(
              t(TXT_AND_MORE, {
                count: totalCount - VISIBLE_OPTIONS_COUNT
              })
            )
          }
        ];
      }
      if (!_.isNil(selected)) {
        portOptions = [{ ...selected }, ...portOptions];
      }
      setOptions(() => [..._.uniqBy(portOptions, 'id')]);
    }
  });

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: portModel | portModel[] | null) => {
    if (!!props.multiple) {
      const newValue = value !== null && Array.isArray(value) ? [...value] : [];
      setSelectedPorts(() => newValue);
      props.onSelectedPortsChange && props.onSelectedPortsChange(newValue);
    } else {
      const newValue = value !== null && !Array.isArray(value) ? { ...value } : null;
      setSelected(() => newValue);
      props.onSelectedChange && props.onSelectedChange(newValue);
    }
  };

  const openCloseEditor = () => {
    setOpenCreate((prev) => !prev);
  };

  const noOption = (): ReactNode => {
    if (!props.allowAdd || !text || text.trim().length <= 0) {
      return 'No options';
    }

    return (
      <Link component="button" variant="inherit" onClick={openCloseEditor}>
        {`${_.capitalize(t(TXT_CREATE_PORT))} '${text}'`}
      </Link>
    );
  };

  const onCreated = (newPort: portModel) => {
    setOptions((prev) => [...prev, newPort]);
    onSelectedChange(_, !!props.multiple ? [...selectedPorts, newPort] : newPort);
  };

  const renderTags = () => {
    if (!props.multiple) {
      return undefined;
    }

    return (value: portModel[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return <Chip variant="outlined" label={option.name} key={key} {...tagProps} />;
      });
  };

  useEffect(() => {
    getPorts();
  }, [text, props.country]);

  useEffect(() => {
    setSelected(() => props.port || null);
  }, [props.port]);

  useEffect(() => {
    setSelectedPorts(() => (!!props.ports ? [...props.ports] : []));
  }, [props.ports]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      {openCreate ? <PortEditor name={text} onClose={openCloseEditor} afterSave={onCreated} /> : null}
      <Autocomplete
        id={props.id || 'port-selector'}
        onChange={onSelectedChange}
        onInputChange={onInputChange}
        value={!!props.multiple ? selectedPorts : selected}
        getOptionDisabled={(option) => option.id === 'port-hidden-options-remaining'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ''}
        options={options}
        loading={loading}
        multiple={props.multiple}
        disableCloseOnSelect={props.multiple}
        filterOptions={(x) => x}
        renderOption={
          !props.multiple
            ? undefined
            : (props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              );
            }
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={capitalizeFirstLetterEveryword(props.label ?? '')}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
            error={props.error}
            helperText={props.error ? props.errorMessage : ''}
          />
        )}
        disabled={props.disabled}
        noOptionsText={noOption()}
        renderTags={renderTags()}
      />
    </>
  );
};
