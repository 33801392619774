import { useLazyQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { GET_DEAL_PORTS, GetDealPortsResponse } from 'api';
import { useApolloErrorHandler } from 'hooks';
import { dealPortModel } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_PORT } from '../../../../../shared/translations';

interface DealPortSelectorProps {
  dealId: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  dealPort?: dealPortModel;
  dealPortId?: string;
  onChanged?: (dealPort?: dealPortModel) => void;
  isLoadingPort: boolean;
}

export const DealPortSelector = (props: DealPortSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<dealPortModel | null>(props.dealPort || null);
  const [options, setOptions] = useState<dealPortModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let filter: any[] = [];

    filter = [
      ...filter,
      !props.isLoadingPort ? {
        unloadingDeals: {
          some: {
            id: {
              eq: props.dealId
            }
          }
        }
      } : {
        loadingDeals: {
          some: {
            id: {
              eq: props.dealId
            }
          }
        }
      }
    ];

    return filter.length <= 0 ? null : { and: filter };
  };

  const [fetch, { loading }] = useLazyQuery<GetDealPortsResponse>(GET_DEAL_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      order: [
        {
          port: {
            name: 'ASC' as const
          }
        }
      ]
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      setOptions([...data.dealPorts]);
      if (!!props.dealPortId) {
        const found = data.dealPorts.find((x) => x.id === props.dealPortId) || null;
        setSelected(found);
        onSelectedChange(found);
      }
    }
  });

  const onSelectedChange = (value: dealPortModel | null) => {
    setSelected(value);
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setSelected(() => props.dealPort ?? null);
  }, [props.dealPort]);

  return (
    <Autocomplete
      id="dealPort-selector"
      onChange={(_, value) => onSelectedChange(value)}
      value={selected}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.port?.name || ''}
      options={options}
      loading={loading}
      fullWidth
      disabled={props.disabled || loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_PORT))}
          variant="outlined"
          error={props.error}
        />
      )}
    />
  );
};
