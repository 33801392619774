import {
  TXT_DELETE,
  TXT_EDIT,
  TXT_NAME,
  TXT_REFERENCE,
  TXT_TYPE
} from '../../../../../../shared/translations';

export interface CounterPartyColumn {
  id: 'name' | 'counterPartyType' | 'edit' | 'delete' | 'reference';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const counterPartyColumns: CounterPartyColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '50%',
    align: 'left'
  },
  {
    id: 'reference',
    label: TXT_REFERENCE,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'counterPartyType',
    label: TXT_TYPE,
    maxWidth: '20%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
