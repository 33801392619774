import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {
  GET_STATEMENT_OF_FACT,
  GetStatementOfFactOutput,
  StatementOfFactQueryResult,
  UPDATE_INTERRUPTIONS,
  UPDATE_STATEMENT_OF_FACT,
  UpdateInterruptionInput,
  UpdateInterruptionsOutput
} from 'api';
import { StatementOfFactSelector } from 'components';
import { useApolloErrorHandler } from 'hooks';
import _ from 'lodash';
import { statementOfFactInterruptionModel, statementOfFactModel } from 'model';
import { useDemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { ignoreTimeZone } from 'shared/utils';
import {
  TXT_SELECT_STATEMENT_OF_FACT,
  TXT_STATEMENT_OF_FACT,
  TXT_SAVING,
  TXT_CANCEL,
  TXT_YES
} from '../../../../../../../../shared/translations';

interface LoadInterruptionDialogProps {
  afterSave: (data: UpdateInterruptionsOutput) => void;
  handleCloseDialog: () => void;
  portId?: string;
}
const NEW_ID = '00000000-0000-0000-0000-000000000000';

export const LoadInterruptionDialog = (props: LoadInterruptionDialogProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const { selectedLaytimeCalculation } = useDemurragePageState();

  const [statementOfFact, setStatementOfFact] = useState<statementOfFactModel | undefined>(undefined);
  const [interruptions, setInterruptions] = useState<statementOfFactInterruptionModel[]>([]);

  const [getStatementOfFact] = useLazyQuery<GetStatementOfFactOutput>(GET_STATEMENT_OF_FACT, {
    fetchPolicy: 'network-only',
    variables: {
      statementOfFactId: statementOfFact?.id
    },
    onCompleted: (data) => {
      if (!!data && !!data.statementOfFact && !!data.statementOfFact.interruptions) {
        setInterruptions((prevState) => {
          return data.statementOfFact?.interruptions ?? prevState;
        });
      }
    },
    onError: apolloErrorHandler
  });

  const handleSelectStatementOfFact = (sof: statementOfFactModel | null) => {
    setStatementOfFact((prevState) => {
      return sof ?? prevState;
    });
  };

  useEffect(() => {
    if (!!statementOfFact) {
      getStatementOfFact();
    }
  }, [statementOfFact]);

  const [mutateInterruptions, { loading, error }] = useMutation<UpdateInterruptionsOutput>(UPDATE_INTERRUPTIONS, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data);
    }
  });

  const getInput = (replaceIds: boolean = true): UpdateInterruptionInput[] => {
    return Object.values(interruptions).map(
      (x) =>
        ({
          id: NEW_ID,
          laytimeCalculationId: selectedLaytimeCalculation?.id,
          description: x.description,
          since: ignoreTimeZone(x.since),
          to: ignoreTimeZone(x.to),
          percentage: x.percentage,
          toDiscount: x.toDiscount
        } as UpdateInterruptionInput)
    );
  };

  const [mutateStatementOfFact, { loading: loadingUpdateStatementOfFact }] = useMutation<StatementOfFactQueryResult>(
    UPDATE_STATEMENT_OF_FACT,
    {
      onError: apolloErrorHandler,
      onCompleted: () => {
        const input = getInput();

        return mutateInterruptions({
          variables: {
            laytimeCalculationId: selectedLaytimeCalculation?.id,
            hideRemarks: selectedLaytimeCalculation?.hideRemarks,
            input
          }
        }).finally(() => {
          props.handleCloseDialog();
        });
      }
    }
  );

  const handleSave = () => {
    if (!!statementOfFact && !!selectedLaytimeCalculation) {
      return mutateStatementOfFact({
        variables: {
          input: {
            id: statementOfFact?.id,
            vesselName: statementOfFact?.vesselName,
            arrivalDate: statementOfFact?.arrivalDate,
            norTenderedDate: statementOfFact?.norTenderedDate,
            quantity: statementOfFact?.quantity,
            destination: statementOfFact?.destination,
            portId: statementOfFact?.port?.id,
            ocrContent: JSON.stringify(statementOfFact?.ocrContent),
            interruptions: _.map(statementOfFact?.interruptions, (interruption: statementOfFactInterruptionModel) => ({
              description: interruption.description,
              since: interruption.since,
              to: interruption.to,
              percentage: interruption.percentage,
              toDiscount: interruption.toDiscount,
              statementOfFactId: interruption.statementOfFactId
            })),
            laytimeCalculationId: selectedLaytimeCalculation?.id
          }
        }
      });
    }
  };

  return (
    <Dialog open={true} onClose={() => props.handleCloseDialog()} maxWidth="sm" fullWidth>
      <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_SELECT_STATEMENT_OF_FACT))}</DialogTitle>
      <DialogContent>
        <StatementOfFactSelector
          statementOfFact={statementOfFact}
          label={t(TXT_STATEMENT_OF_FACT)}
          portId={props.portId}
          onSelectedChange={handleSelectStatementOfFact}
        />
      </DialogContent>
      <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
        {loading ||
          (loadingUpdateStatementOfFact && (
            <div>
              <Typography>
                <CircularProgress style={{ marginRight: '5px' }} size="20px" />
                {t(TXT_SAVING)}...
              </Typography>
            </div>
          ))}
        {error?.message && (
          <div>
            <Typography color={'red'} sx={{ width: '100%' }}>
              {error?.message}
            </Typography>
          </div>
        )}
        &nbsp;
      </div>
      <DialogActions>
        <Button onClick={() => props.handleCloseDialog()} disabled={!!error?.message || loading}>
          {capitalizeFirstLetterEveryword(t(TXT_CANCEL))}
        </Button>
        <Button onClick={handleSave} disabled={!!error?.message || loading}>
          {capitalizeFirstLetterEveryword(t(TXT_YES))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
