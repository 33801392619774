import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_DEAL_PROFIT_AND_LOSS } from '../../../../../../shared/translations';
import SavingsIcon from '@mui/icons-material/RequestQuote';
import { useNavigate } from 'react-router-dom';
import { profitAndLossPath } from 'shared/links';

export const DealPnLReportCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea
        onClick={() => navigate(profitAndLossPath)}
        sx={{
          padding: theme.spacing(2)
        }}
      >
        <Stack direction="row" spacing={2}>
          <div>
            <SavingsIcon fontSize="large" />
          </div>
          <Typography variant="h5" align="center">
            {capitalizeFirstLetterEveryword(t(TXT_DEAL_PROFIT_AND_LOSS))}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
