import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_UNITS, GetBusinessUnitsOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_BUSINESS_UNIT } from '../../../../../shared/translations';
import { businessUnitModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';

export interface BusinessUnitSelectorProps {
  businessUnit?: businessUnitModel;
  error?: boolean;
  disabled?: boolean;
  onSelectedChange?: (id: string | null) => void;
  onChanged?: (businessUnit?: businessUnitModel) => void;
}

const VISIBLE_OPTIONS_COUNT = 10;

export const BusinessUnitSelector = (props: BusinessUnitSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<businessUnitModel | null>(props.businessUnit || null);
  const [text, setText] = useState<string | undefined>(props.businessUnit?.name);
  const [options, setOptions] = useState<businessUnitModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    if (_.isNil(text) || text.length <= 0) {
      return null;
    }

    return {
      or: [
        {
          name: {
            contains: text
          }
        },
        {
          initials: {
            contains: text
          }
        }
      ]
    };
  };

  const [getBusinessUnits, { data: businessUnitData, loading, refetch }] = useLazyQuery<GetBusinessUnitsOutput>(
    GET_BUSINESS_UNITS,
    {
      fetchPolicy: 'network-only',
      variables: {
        take: VISIBLE_OPTIONS_COUNT,
        skip: 0,
        where: getFilter()
      },
      onError: apolloErrorHandler
    }
  );

  const fetch = () => {
    if (!refetch) {
      getBusinessUnits();
    } else {
      refetch();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: businessUnitModel | null) => {
    setSelected(value);
    props.onSelectedChange && props.onSelectedChange(value && (value.id as string));
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  useEffect(() => {
    fetch();
  }, [text]);

  useEffect(() => {
    if ((businessUnitData?.businessUnits?.items?.length || 0) > 0) {
      let businessUnitOptions: businessUnitModel[] = (businessUnitData?.businessUnits?.items || []).map(
        (businessUnit) => ({
          id: businessUnit.id,
          name: businessUnit.name
        })
      );
      const totalCount = businessUnitData?.businessUnits?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        businessUnitOptions = [
          ...businessUnitOptions,
          {
            id: 'businessUnit-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(t(TXT_AND_MORE, {
              count: totalCount - VISIBLE_OPTIONS_COUNT
            }))
          }
        ];
      }
      if (!_.isNil(selected)) {
        businessUnitOptions = [{ ...selected }, ...businessUnitOptions];
      }
      setOptions(_.uniqBy(businessUnitOptions, 'id'));
    }
  }, [businessUnitData]);

  return (
    <Autocomplete
      id="businessUnit-selector"
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      value={selected}
      getOptionDisabled={(option) => option.id === 'businessUnit-hidden-options-remaining'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={capitalizeFirstLetterEveryword(t(TXT_BUSINESS_UNIT))}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {/* eslint-disable-next-line max-len */}
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
