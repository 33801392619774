import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import {
  ADD_MARITIME_DIRECTORY,
  AddMaritimeDirectoryInput,
  AddMaritimeDirectoryOutput,
  UPDATE_MARITIME_DIRECTORY
} from 'api';
import { useApolloErrorHandler } from 'hooks';
import { maritimeDirectoryModel } from 'model';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  TXT_CANCEL,
  TXT_COUNTERPARTY,
  TXT_CREATE_MARITIME_DIRECTORY,
  TXT_NAME,
  TXT_NOTES,
  TXT_PORTS,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_MARITIME_DIRECTORY
} from '../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { CounterPartySelector, PortSelector } from 'components';

interface MaritimeDirectoryEditorProps extends maritimeDirectoryModel {
  onClose: () => void;
  afterSave: () => void;
}

const contactSchema = yup.object({
  name: yup.string().required(),
  notes: yup.string()
});

const schema = yup.object({
  portIds: yup
    .array()
    .of(yup.string())
    .test({
      test: (arr) => (arr?.length || 0) > 0
    }),
  counterPartyId: yup.string().required(),
  contactInput: contactSchema
});

export const MaritimeDirectoryEditor = (props: MaritimeDirectoryEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { register, control, handleSubmit, formState, setValue } = useForm<AddMaritimeDirectoryInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      portIds: props.ports?.map((x) => x.id),
      ports: props.ports,
      counterPartyId: props.counterPartyId,
      counterParty: props.counterParty,
      contactInput: {
        strId: props.contact?.id || 'new-contact',
        name: props.contact?.name,
        notes: props.contact?.notes
      }
    }
  });

  const [mutateMaritimeDirectory, { loading }] = useMutation<AddMaritimeDirectoryOutput>(
    !props.id ? ADD_MARITIME_DIRECTORY : UPDATE_MARITIME_DIRECTORY,
    {
      onError: apolloErrorHandler,
      onCompleted: () => {
        props.afterSave();
        props.onClose();
      }
    }
  );

  const mutate = (input: AddMaritimeDirectoryInput) => {
    const addInput = { ..._.omit(input, ['ports', 'counterParty', 'contact']) };
    if (!props.id) {
      return mutateMaritimeDirectory({
        variables: {
          input: addInput
        }
      });
    }

    return mutateMaritimeDirectory({
      variables: {
        input: {
          ...addInput,
          id: props.id
        }
      }
    });
  };

  const onSave: SubmitHandler<AddMaritimeDirectoryInput> = (data) => {
    mutate(data);
  };

  const title = t(props.id ? TXT_UPDATE_MARITIME_DIRECTORY : TXT_CREATE_MARITIME_DIRECTORY);

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSave)();
        e.stopPropagation();
      }}
      >
        <AppBar position="relative">
          <Toolbar>{title.toUpperCase()}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="portIds"
                control={control}
                render={() => (
                  <PortSelector
                    ports={props.ports}
                    label={capitalizeFirstLetterEveryword(t(TXT_PORTS))}
                    onSelectedPortsChange={(ports) => {
                      setValue(
                        'portIds',
                        (ports || []).map((x) => x.id!),
                        {
                          shouldValidate: true
                        }
                      );
                    }}
                    disabled={loading}
                    multiple
                    error={!!formState.errors.portIds}
                    allowAdd
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="counterPartyId"
                control={control}
                render={() => (
                  <CounterPartySelector
                    counterParty={props.counterParty}
                    label={capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTY))}
                    onSelectedChange={(counterParty) => {
                      setValue('counterPartyId', counterParty === null ? undefined : counterParty.id, {
                        shouldValidate: true
                      });
                    }}
                    disabled={loading}
                    error={!!formState.errors.counterPartyId}
                    counterPartyType="AGENT"
                    allowAdd
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                disabled={loading}
                {...register('contactInput.name')}
                error={!!formState.errors.contactInput?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="notes"
                label={capitalizeFirstLetterEveryword(t(TXT_NOTES))}
                fullWidth
                disabled={loading}
                multiline
                minRows={2}
                {...register('contactInput.notes')}
                error={!!formState.errors.contactInput?.notes}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
