import { AppBar, Breakpoint, Button, Dialog, DialogActions, DialogContent, Toolbar } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_CANCEL, TXT_OK } from '../../../../../shared/translations';

interface ConfirmationDialogProps {
  title: ReactNode;
  children: ReactNode;
  onCanceled?: () => void;
  onAccepted?: () => void;
  cancelLabel?: string;
  acceptLabel?: string;
  open: boolean;
  maxWidth?: false | Breakpoint;
  fullWidth?: boolean;
  disableCancelButton?: boolean;
  disableAcceptButton?: boolean;
  hideAcceptButton?: boolean;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const onClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick' && props.onCanceled) {
      props.onCanceled();
    }
  };

  return (
    <Dialog
      open={props.open}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      disableEscapeKeyDown={true}
      onClose={onClose}
    >
      {props.title ? (
        <AppBar position="relative">
          <Toolbar>{props.title}</Toolbar>
        </AppBar>
      ) : null}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onCanceled} color="secondary" disabled={props.disableCancelButton}>
          {props.cancelLabel || t(TXT_CANCEL)}
        </Button>
        {!!props.hideAcceptButton ? null : (
          <Button onClick={props.onAccepted} disabled={props.disableAcceptButton}>
            {props.acceptLabel || t(TXT_OK)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
