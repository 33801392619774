import { gql } from '@apollo/client';
import { tenantModel } from 'model/tenantModel';

export const getTenantFragment = `
  name
  holidayCulture
  fxSpreadsheetId
  dealNamePattern
`;

export type GetTenantOutput = {
  tenant?: tenantModel;
};

export const GET_TENANT = gql`
  query getCurrentTenant {
    tenant {
        ${getTenantFragment}
    }
  }
`;
