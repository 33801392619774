import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import languagePopoverStyle from './LanguagePopoverStyle';
import { ReactNode } from 'react';
import { UKFlagIcon } from '../UKFlagIcon';
import { SpainFlagIcon } from '../SpainFlagIcon';
import { useLanguageState } from '../../hooks/useLanguageState';

type LanguangeOptionType = {
  [key: string]: {
    icon: ReactNode;
    label: string;
  };
};

const languageOptions: LanguangeOptionType = {
  enUS: {
    icon: <UKFlagIcon />,
    label: 'English'
  },
  esES: {
    icon: <SpainFlagIcon />,
    label: 'Español'
  }
};

export const LanguagePopover = () => {
  const classes = languagePopoverStyle();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [, setLanguage] = useLanguageState();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} size="large">
        <Box className={classes.box}>{selectedOption.icon}</Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: classes.paper
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
            <ListItemIcon>
              <Box className={classes.box}>{languageOptions[language].icon}</Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
