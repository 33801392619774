import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetIntegrationOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { IntegrationColumn } from './integrationColumns';
import { integrationModel } from 'model';

export interface IntegrationTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetIntegrationOutput;
  columns: IntegrationColumn[];
  onEdit: (integration: integrationModel) => void;
  onDelete: (integration: integrationModel) => void;
}

export const IntegrationTableBody =
  ({ columns, loading, error, data, onEdit, onDelete }: IntegrationTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.integrations &&
          data.integrations.items &&
          data.integrations.items.length > 0 &&
          data.integrations.items.map((integration) => (
            <TableRow key={`row-${integration.id}`}>
              <TableCell align="left">{integration.name}</TableCell>
              <TableCell align="left">{integration.key}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete integration"
                  component="span"
                  onClick={() => onDelete(integration)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        {!loading && (data?.integrations?.items || []).length <= 0 && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
