import { useRecoilState } from 'recoil';
import { defaultSettingStorageProps, setDefaultSettingsLocalStorage } from 'shared/defaultSettingsLocalStorage';
import defaultSettingState from 'state/defaultSettingsState';

export const useDefaultSettings = (): [defaultSettingStorageProps, (value: defaultSettingStorageProps) => void] => {
  const [defaultSettings, setDefaultSettings] = useRecoilState(defaultSettingState);

  const setSettings = (value: defaultSettingStorageProps) => {
    setDefaultSettings({
      ...value
    });
    setDefaultSettingsLocalStorage({ ...value });
  };

  return [defaultSettings, setSettings];
};
