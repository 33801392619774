import { gql } from '@apollo/client';
import { applicationUserModel } from '../../../model';
import { basePagingResult } from '../base';

export const getUsersFragment = `
  id,
  username,
  isEnabled,
  securityRole,
  allowVesselPortal,
  allowOCR,
  profile {
    fullName,
    initial,
    reference
  }
`;

export const getUsersSelectorFragment = `
  id,
  profile {
    fullName
  }
`;

export type GetUsersOutput = {
  users: basePagingResult<applicationUserModel>;
};

export const GET_USERS = gql`
  query getUsers($skip: Int, $take: Int, $where: ApplicationUserFilterInput) {
    users (skip: $skip, take: $take, where: $where) {
      items {
        ${getUsersFragment}
      },
      totalCount
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($id: UUID!) {
    users (where: {
      id: {
        eq: $id
      }
    }) {
      items {
        id,
        username,
        allowVesselPortal,
        isEnabled,
        securityRole,
        profile {
          fullName,
          initial,
          linkedMicrosoftAccount
          reference
        },
        hasMicrosoftAccountConnected
      },
      totalCount
    }
  }
`;

export const GET_USERS_SELECTOR = gql`
  query getUsers($skip: Int, $take: Int, $where: ApplicationUserFilterInput) {
    users (skip: $skip, take: $take, where: $where) {
      items {
        ${getUsersSelectorFragment}
      },
      totalCount
    }
  }
`;
