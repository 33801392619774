import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CalendarRuleOption, CalendarRuleOptions } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_CALENDAR_RULE } from '../../../../../shared/translations';

interface CalendarRuleProps {
  value?: CalendarRuleOption;
  disabled?: boolean;
  id?: string;
  label?: string;
  hideLabel?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
  size?: 'small' | 'medium';
  onChanged?: (newStatus?: CalendarRuleOption) => void;
}

export const CalendarRule = (props: CalendarRuleProps) => {
  const { t } = useTranslation();
  const id = props.id || 'calendar-rule';
  const labelId = `${id}-lbl-calendar-rule`;
  const [selected, setSelected] = useState<CalendarRuleOption | undefined>(props.value);

  const lblStatus = !!props.hideLabel ? '' : capitalizeFirstLetterEveryword(t(props.label || TXT_CALENDAR_RULE));

  const handleChange = (e: SelectChangeEvent<CalendarRuleOption>) => {
    const newValue = e.target.value as CalendarRuleOption;
    setSelected(newValue);
    props.onChanged && props.onChanged(newValue);
  };

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return (
    <FormControl fullWidth style={props.style} size={props.size} error={props.error}>
      <InputLabel id={labelId}>{lblStatus}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={lblStatus}
        value={selected || ''}
        disabled={props.disabled}
        onChange={handleChange}
      >
        {CalendarRuleOptions.filter((option) => option !== 'CUSTOM' && option !== 'FIXED').map((option, i) => (
          <MenuItem key={`${i}-calendar-rule`} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
