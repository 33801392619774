import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, AppBar, Toolbar, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { UpdateUserInput, UpdateUserOutput, UPDATE_USER } from 'api';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_CANCEL, TXT_SAVING, TXT_SAVE, TXT_UPDATE_USER } from '../../../../../../shared/translations';
import useAuth from 'hooks/useAuth';
import { useMutation } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks';
import { UserProfileContent, userProfileFormSchema } from '../UserProfileContent';
import { useState } from 'react';
import _ from 'lodash';

interface UserEditorProps {
  id: string;
  onClose: () => void;
  afterSave?: () => void;
  disableIsAdmin?: boolean;
  disableIsEnabled?: boolean;
}

export const UserEditor = (props: UserEditorProps) => {
  const { t } = useTranslation();
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const form = useForm<UpdateUserInput>({
    resolver: yupResolver(userProfileFormSchema),
    defaultValues: {
      id: props.id
    }
  });
  const { handleSubmit } = form;

  const { apolloErrorHandler } = useApolloErrorHandler();

  const [auth, setAuth] = useAuth();

  const [mutate, { loading }] = useMutation<UpdateUserOutput>(UPDATE_USER, {
    onCompleted: (data) => {
      if (auth.id === props.id) {
        setAuth({
          ...auth,
          fullName: data.updateUser.profile?.fullName,
          isAdmin: data.updateUser.securityRole === 'ADMIN',
          isOperator: data.updateUser.securityRole === 'USER',
          isGuest: data.updateUser.securityRole === 'GUEST',
          hasMicrosoftAccountConnected: !!data.updateUser.profile?.microsoftSub
        });
      }
      props.afterSave && props.afterSave();
      props.onClose();
    },
    onError: apolloErrorHandler
  });

  const onSave: SubmitHandler<UpdateUserInput> = (input) => {
    mutate({
      variables: {
        input: { ..._.omit(input, 'reNewPassword') }
      }
    });
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <AppBar position="relative">
        <Toolbar>{t(TXT_UPDATE_USER).toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <FormProvider {...form}>
          <form id="frm-update-user" onSubmit={handleSubmit(onSave)}>
            <UserProfileContent
              {...props}
              {...form}
              disableMicrosoft
              isLoadingUserChanged={setIsLoadingUser}
              disabled={loading}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading || isLoadingUser}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="frm-update-user" disabled={loading || isLoadingUser}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
