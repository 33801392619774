import { businessUnitModel, counterPartyTypeModel, maritimeDirectoryModel } from 'model';
import { baseNamedEntityModel } from './base';

export const CounterPartyTypes = {
  ALL: '00000000-0000-0000-0000-000000000000',
  CLIENT: '674a27a2-719f-4d02-bc88-d6145468eab5',
  SHIPOWNER: '843c658e-e227-4269-88fa-bcc4e5a09e69',
  SUPPLIER: '8204ff2a-dca2-4f0d-b0e5-9dad09fd1e56',
  AGENT: '5836f893-9b85-40ee-8b29-6bee12a9c9fa'
};

export type CounterPartyType = keyof typeof CounterPartyTypes;
export interface counterPartyModel extends baseNamedEntityModel {
  counterPartyTypes?: counterPartyTypeModel[];
  dealPopularity?: number;
  maritimeDirectories?: Omit<maritimeDirectoryModel, 'counterParty'>[];
  reference?: string;
  businessUnitId?: string;
  businessUnit?: businessUnitModel;
}
