import { IconButton, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';

interface CopyToClipboardProps {
  tooltip?: string;
  show?: boolean;
  successMessage?: string;
  failedMessage?: string;
  content: string;
  size?: 'small' | 'medium' | 'large';
}

export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const onClicked = async () => {
    try {
      await navigator.clipboard.writeText(props.content);
      enqueueSnackbar(
        <Typography
          variant="subtitle1"
          sx={{
            whiteSpace: 'pre-wrap'
          }}
        >
          {props.successMessage}
        </Typography>,
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 2000
        }
      );
    } catch {
      enqueueSnackbar(
        <Typography
          variant="subtitle1"
          sx={{
            whiteSpace: 'pre-wrap'
          }}
        >
          {props.failedMessage}
        </Typography>,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 2000
        }
      );
    }
  };

  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        onClick={onClicked}
        size={props.size || 'small'}
        sx={{
          visibility: !!props.show ? 'visible' : 'hidden'
        }}
      >
        <ContentCopyIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
