import { useLazyQuery } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_INTEGRATION, GetIntegrationOutput, GET_INTEGRATIONS } from '../../api';
import { DeleteModal, PaginationBar } from '../../components';
import _ from 'lodash';
import { TXT_DELETE_TEXT, TXT_INTEGRATIONS, TXT_SEARCH_BY_NAME } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { IntegrationTable } from './IntegrationTable';
import { useIntegrationStyle } from './integrationStyle';
import { integrationModel } from 'model';
import { IntegrationEditor } from './IntegrationEditor';
import { PageSizeOptions } from 'shared/constants';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { useAdminGuard, useTitle } from 'hooks';

export const Integrations = () => {
  useAdminGuard();
  useTitle(TXT_INTEGRATIONS);
  const interruptionsStyle = useIntegrationStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<integrationModel | undefined>(undefined);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let result = null;

    if (nameFilter && nameFilter.length > 0) {
      result = {
        name: {
          contains: nameFilter
        }
      };
    }
    return result;
  };

  const [getIntegration, { loading, error, data, refetch }] = useLazyQuery<GetIntegrationOutput>(GET_INTEGRATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: getFilter()
    },
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (!refetch) {
      getIntegration();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (integration: integrationModel) => {
    setSelectedIntegration(integration);
    setOpenEditor(true);
  };

  const onDelete = (integration: integrationModel) => {
    setSelectedIntegration(integration);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedIntegration(undefined);
  };

  const openAddEditor = () => {
    setSelectedIntegration(undefined);
    setOpenEditor(true);
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: selectedIntegration?.name
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <IntegrationEditor {...selectedIntegration} onClose={onEditorClosed} afterSave={fetch} />}
      {openDeleteConfirmation && selectedIntegration && (
        <DeleteModal
          afterSaving={fetch}
          onCancel={closeDeleteConfirmation}
          txtConfirmation={txtConfirmation}
          id={selectedIntegration?.id!}
          mutation={DELETE_INTEGRATION}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={interruptionsStyle.content}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  id="search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <IntegrationTable loading={loading} error={error} data={data} onEdit={onEdit} onDelete={onDelete} />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data?.integrations?.totalCount || 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={openAddEditor}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
