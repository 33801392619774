import { applicationUserProfileModel, baseEntityModel } from '.';
import { TXT_ADMIN, TXT_GUEST, TXT_OPERATOR } from '../../../../shared/translations';

export const SecurityRolesValues = ['ADMIN', 'USER', 'GUEST'] as const;
export type SecurityRoles = typeof SecurityRolesValues[number];

export interface applicationUserModel extends baseEntityModel {
  username?: string;
  securityRole?: SecurityRoles;
  isEnabled?: boolean;
  profile?: applicationUserProfileModel;
  hasMicrosoftAccountConnected?: boolean;
  allowVesselPortal?: boolean;
  allowOCR?: boolean;
}

export const SecurityRoleTranslations: Record<SecurityRoles, string> = {
  ADMIN: TXT_ADMIN,
  USER: TXT_OPERATOR,
  GUEST: TXT_GUEST
};
