import { IconButton, Tooltip } from '@mui/material';
import { useDemurrageService } from 'hooks';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_COPY } from '../../../../../shared/translations';
import CopyIcon from '@mui/icons-material/ContentCopy';

interface CopyCalculationProps {
  size?: 'small' | 'medium' | 'large';
  calculationId: string;
}
export const CopyCalculation = (props: CopyCalculationProps) => {
  const { t } = useTranslation();
  const { copyCalculation } = useDemurrageService();

  const handleCopy = () => {
    copyCalculation(props.calculationId);
  };

  return (
    <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_COPY))}>
      <IconButton aria-label="copy" size={props.size || 'small'} onClick={handleCopy}>
        <CopyIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
