import { useLazyQuery, useMutation } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChangeEnablementInput,
  CHANGE_USER_ENABLEMENT,
  GetUsersOutput,
  GET_USERS,
  AllowVesselPortalInput,
  ALLOW_VESSEL_PORTAL,
  AllowOCRInput,
  ALLOW_OCR
} from '../../api';
import { PaginationBar } from '../../components';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { TXT_SEARCH_BY_USERNAME_NAME_INITIAL, TXT_USERS } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import { useUserStyle } from './userStyle';
import { UserTable } from './UserTable';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { UserDeleteConfirmation } from './UserDeleteConfimation';
import { applicationUserModel } from '../../model';
import { InviteUser } from './InviteUser';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useAdminGuard, useTitle } from 'hooks';
import { UserEditor } from './UserEditor';

export const Users = () => {
  useAdminGuard();
  useTitle(TXT_USERS);
  const userStyle = useUserStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameInitialFilter, setNameInitialFilter] = useState('');
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [changeUserEnablement] = useMutation(CHANGE_USER_ENABLEMENT, {
    onCompleted: (data) => fetch(),
    onError: apolloErrorHandler
  });

  const [changeAllowVesselPortal] = useMutation(ALLOW_VESSEL_PORTAL, {
    onCompleted: (data) => fetch(),
    onError: apolloErrorHandler
  });

  const [changeAllowOCR] = useMutation(ALLOW_OCR, {
    onCompleted: (data) => fetch(),
    onError: apolloErrorHandler
  });

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<applicationUserModel | undefined>(undefined);
  const [openEditor, setOpenEditor] = useState(false);

  const [openInviteUsers, setOpenInviteUsers] = useState<boolean>(false);

  const getFilter = () => {
    let result = null;

    if (nameInitialFilter && nameInitialFilter.length > 0) {
      result = {
        or: [
          {
            username: {
              contains: nameInitialFilter
            }
          },
          {
            profile: {
              initial: {
                contains: nameInitialFilter
              }
            }
          },
          {
            profile: {
              fullName: {
                contains: nameInitialFilter
              }
            }
          }
        ]
      };
    }
    return result;
  };

  const [getUsers, { loading, error, data, refetch }] = useLazyQuery<GetUsersOutput>(GET_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: pageSize,
      skip: pageSize * (pageNumber - 1)
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    setNameInitialFilter('');
  }, [t]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameInitialFilter]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  const fetch = () => {
    if (!refetch) {
      getUsers();
    } else {
      refetch();
    }
  };

  const onSearchByNameInitialChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameInitialFilter(event.currentTarget.value || '');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  // eslint-disable-next-line max-len
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onDelete = (user: applicationUserModel) => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };

  const onEdit = (user: applicationUserModel) => {
    setSelectedUser(user);
    setOpenEditor(true);
  };

  const changeEnablement = (input: ChangeEnablementInput) => {
    changeUserEnablement({
      variables: {
        input
      }
    });
  };

  const allowVesselPortal = (input: AllowVesselPortalInput) => {
    changeAllowVesselPortal({
      variables: {
        input
      }
    });
  };

  const allowOCR = (input: AllowOCRInput) => {
    changeAllowOCR({
      variables: {
        input
      }
    });
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const openInviteUserForm = () => {
    setOpenInviteUsers(true);
  };

  const closeInviteIUserForm = () => {
    setOpenInviteUsers(false);
  };

  const closeEditor = () => setOpenEditor(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_USERNAME_NAME_INITIAL));

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && selectedUser && selectedUser.id && (
        <UserEditor id={selectedUser.id} onClose={closeEditor} afterSave={fetch} />
      )}
      {openInviteUsers && <InviteUser onCancel={closeInviteIUserForm} />}
      {openDeleteConfirmation && selectedUser && (
        <UserDeleteConfirmation {...selectedUser} onCancel={closeDeleteConfirmation} afterSaving={fetch} />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={userStyle.content}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={5}>
                <TextField
                  id="user-search-by-nameinitial"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameInitialFilter}
                  onChange={onSearchByNameInitialChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UserTable
                  loading={loading}
                  error={error}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onChangeEnablement={changeEnablement}
                  allowVesselPortal={allowVesselPortal}
                  allowOCR={allowOCR}
                />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data && data.users && data.users.totalCount ? data.users.totalCount : 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={openInviteUserForm}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
