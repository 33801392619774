import { Page, Document, Text, Image } from '@react-pdf/renderer';
import { DemurragePageState } from '../../DemurragePageContext';
import { styles } from './styles';
import { DealInfo } from './DealInfo';
import { EventRow } from './EventRow';
import { ReportHeader } from './ReportHeader';
import { ReportFooter } from './ReportFooter';
import { Totals } from './Totals';
import { CalculationEvent } from 'model';
import { tenantModel } from 'model/tenantModel';
import { getDateFormat } from 'shared/utils';
import moment from 'moment';
import { getAvailableCalculations } from 'shared/calculationUtils';

interface LaytimeCalculationPdfProps {
  state: DemurragePageState;
  events?: CalculationEvent[];
  tenant: tenantModel;
}

const rowsFirstPage = 30;


export const isBreak = (i: number) => {
  if (i === 0) {
    return false;
  }
  if (i === rowsFirstPage) {
    return true;
  }
  return (i - rowsFirstPage) % 50 === 0;
};

// Create Document Component
export const LaytimeCalculationPdf = ({ state, events, tenant }: LaytimeCalculationPdfProps) => {
  const data = events || [];
  const dateFormat = getDateFormat();

  const selections = getAvailableCalculations(state.demurrageDeal);

  const calcHint = selections.find( (x) => x.dealPort.id === state.selectedLaytimeCalculation?.dealPortId &&
  x.counterPartyType.toLowerCase() === state.selectedLaytimeCalculation?.counterPartyType?.name &&
  x.counterParty.id === state.selectedLaytimeCalculation.counterPartyId
  )?.dealsHint;

  let curDate: string;
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Image
          style={{ width: '125px', height: '30px' }}
          src="https://atreuslogosstorage.blob.core.windows.net/logos/IMI-Blue.png"
        ></Image>
        <ReportHeader state={state} />
        <DealInfo state={state} />
        <EventRow isHeaderRow={true} />
        {data.map((x, i) => {
          const strDate = moment(x.from).format(dateFormat);
          const hideDate = strDate === curDate;
          curDate = strDate;
          return <EventRow key={i} event={x} isBreak={isBreak(i)} hideDate={hideDate} />;
        })}
        {state.selectedLaytimeCalculation && (
          <Totals rowNumber={data.length} calculation={state.selectedLaytimeCalculation} deal={state.demurrageDeal} />
        )}
        <ReportFooter />
        <Text render={() => tenant.name} style={{ position: 'absolute', left: 10, top: 770 }} fixed />
        <Text render={() => 'Errors and Omissions Excepted'} style={{ position: 'absolute', left: 210, top: 770 }} fixed />
        <Text render={() => `Internal Ref #: ${calcHint}`} style={{ position: 'absolute', left: 10, top: 780 }} />
      </Page>
    </Document>
  );
};

