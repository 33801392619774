import { gql } from '@apollo/client';

export interface AddTraderInput {
  name: string;
  initials: string;
}

export interface UpdateTraderInput extends AddTraderInput {
  id: string;
}

export interface DeleteTraderInput {
  id: string;
}

export const ADD_TRADER = gql`
  mutation addTrader($input: AddTraderInput!) {
    addTrader(input: $input) {
      id,
      name,
      initials
    }
  }
`;

export const UPDATE_TRADER = gql`
  mutation updateTrader($input: UpdateTraderInput!) {
    updateTrader(input: $input) {
      id,
      name,
      initials
    }
  }
`;

export const DELETE_TRADER = gql`
  mutation deleteTrader($input: DeleteTraderInput!) {
    deleteTrader(input: $input)
  }
`;
