import { baseCommentModel } from 'model';
import { basePagingResult } from './basePagingResult';

export interface baseCommentOutput<T extends baseCommentModel> {
  result: T;
}

export interface baseCommentDeleteOutput {
  deletedId: string;
}

export interface GetCommentsOutput<T> {
  comments: basePagingResult<T>;
}

export const BaseCommentQueryData = `
  id
  senderId
  sender {
    id
    profile {
      fullName
    }
  }
  message
  resolved
  entityId
  parentCommentId
  createdDate
`;
