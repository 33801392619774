import { useMutation } from '@apollo/client';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddInvitationInput, ADD_INVITATION } from '../../../api';
import { CustomError } from '../../../components';
import { useApolloErrorHandler } from '../../../hooks/useApolloErrorHandler';
import { invitationModel } from '../../../model';
import { capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';
import { validateEmail } from '../../../shared/utils';
import {
  ERR_INVALID_EMAILS,
  TXT_EMAILS,
  TXT_INVITATION_SENT,
  TXT_INVITE,
  TXT_INVITE_NEW_USERS,
  TXT_INVITING,
  TXT_NO
} from '../../../../../../shared/translations';
import _ from 'lodash';

interface InviteUserProps {
  onCancel: () => void;
}

type emailType = {
  email: string;
  isValid: boolean;
};

export const InviteUser = (props: InviteUserProps) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<emailType[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [inviting, { loading, error }] = useMutation<invitationModel>(ADD_INVITATION, {
    onError: apolloErrorHandler,
    onCompleted: props.onCancel
  });

  const onChanged = (
    event: ChangeEvent<{}>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setEmails(
      value.map((email) => {
        const result: emailType = {
          email,
          isValid: validateEmail(email)
        };
        return result;
      })
    );
  };

  const validateEmails = () => {
    return emails.length > 0 && emails.filter((email) => !email.isValid).length <= 0;
  };

  const onSubmit = () => {
    if (!validateEmails()) {
      return;
    }
    const promises: Promise<any>[] = [];
    emails.forEach((email) => {
      const input: AddInvitationInput = {
        email: email.email
      };
      promises.push(
        inviting({
          variables: {
            input
          }
        }).then(() => {
          enqueueSnackbar(_.capitalize(t(TXT_INVITATION_SENT, {
            email: email.email
          })), {
            variant: 'success'
          });
        }).catch((reason) => {
          enqueueSnackbar(reason, {
            variant: 'error'
          });
        })
      );
    });
    Promise.all(promises)
      .catch((reason) => {
        console.error(reason);
      });
  };

  const handleTextInputOnKeyDown = (event: any) => {
    switch (event.key) {
    case ',':
    case ';':
    case 'Tab':
    case ' ':
      event.preventDefault();
      event.stopPropagation();
      break;
    default:
    }
  };

  useEffect(() => {
    setIsValid(validateEmails());
  }, [emails]);

  useEffect(() => {
    setErrorMessage(isValid || emails.length <= 0 ? undefined : _.capitalize(t(ERR_INVALID_EMAILS)));
  }, [isValid, emails]);

  const txtTitle = t(TXT_INVITE_NEW_USERS).toUpperCase();
  const txtEmails = capitalizeFirstLetterEveryword(t(TXT_EMAILS));
  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>{txtTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          {!!error && (
            <Grid item xs={12}>
              <CustomError>{error.message}</CustomError>
            </Grid>
          )}
          {!!errorMessage && (
            <Grid item xs={12}>
              <CustomError>{errorMessage}</CustomError>
            </Grid>
          )}
          {!errorMessage && (
            <Grid item xs={12} />
          )}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="invite-emails"
              options={[]}
              value={emails.map((value) => value.email)}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    variant="outlined"
                    label={option}
                    color={validateEmail(option) ? 'default' : 'secondary'}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => {
                params.inputProps.onKeyDown = handleTextInputOnKeyDown;
                return <TextField {...params} variant="outlined" label={txtEmails} disabled={loading} />;
              }}
              onChange={onChanged}
              disabled={loading}
              clearOnBlur
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary" disabled={loading} tabIndex={-1}>
          {t(TXT_NO)}
        </Button>
        <Button onClick={onSubmit} disabled={loading || !isValid} tabIndex={-1}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_INVITING : TXT_INVITE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
