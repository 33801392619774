import { useLazyQuery } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_BUSINESS_UNIT, GetBusinessUnitsOutput, GET_BUSINESS_UNITS } from 'api';
import { DeleteModal, PaginationBar } from 'components';
import _ from 'lodash';
import { TXT_DELETE_TEXT, TXT_BUSINESS_UNITS, TXT_SEARCH_BY_NAME } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useBusinessUnitsStyle } from './businessUnitsStyle';
import { BusinessUnitsTable } from './BusinessUnitsTable';
import { BusinessUnitEditor } from './BusinessUnitEditor';
import { businessUnitModel } from 'model';
import { PageSizeOptions } from 'shared/constants';
import { useAdminGuard, useDefaultSettings, useTitle } from 'hooks';

export const BusinessUnits = () => {
  useAdminGuard();
  useTitle(TXT_BUSINESS_UNITS);
  const businessUnitsStyle = useBusinessUnitsStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<businessUnitModel | undefined>(undefined);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let result = {};

    if (!_.isNil(nameFilter) && nameFilter.length > 0) {
      result = {
        ...result,
        or: [
          {
            name: {
              contains: nameFilter
            }
          },
          {
            initials: {
              contains: nameFilter
            }
          }
        ]
      };
    }

    return _.isEqual(result, {}) ? null : result;
  };

  const [getBusinessUnits, { loading, error, data, refetch }] = useLazyQuery<GetBusinessUnitsOutput>(
    GET_BUSINESS_UNITS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: getFilter(),
        take: pageSize,
        skip: pageSize * (pageNumber - 1)
      },
      onError: apolloErrorHandler
    }
  );

  useEffect(() => {
    setNameFilter(undefined);
  }, [t]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  const fetch = () => {
    if (!refetch) {
      getBusinessUnits();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (businessUnit: businessUnitModel) => {
    setSelectedBusinessUnits(businessUnit);
    setOpenEditor(true);
  };

  const onDelete = (businessUnit: businessUnitModel) => {
    setSelectedBusinessUnits(businessUnit);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedBusinessUnits(undefined);
  };

  const openAddEditor = () => {
    setSelectedBusinessUnits(undefined);
    setOpenEditor(true);
  };

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: selectedBusinessUnits?.name
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <BusinessUnitEditor {...selectedBusinessUnits} onClose={onEditorClosed} afterSave={fetch} />}
      {openDeleteConfirmation && selectedBusinessUnits && (
        <DeleteModal
          afterSaving={fetch}
          onCancel={closeDeleteConfirmation}
          txtConfirmation={txtConfirmation}
          id={selectedBusinessUnits?.id!}
          mutation={DELETE_BUSINESS_UNIT}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={businessUnitsStyle.content}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  id="businessUnits-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter || ''}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <BusinessUnitsTable loading={loading} error={error} data={data} onEdit={onEdit} onDelete={onDelete} />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={
                    data && data.businessUnits && data.businessUnits.totalCount ? data.businessUnits.totalCount : 0
                  }
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={openAddEditor}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
