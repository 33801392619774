import { CssBaseline, StyledEngineProvider, useMediaQuery } from '@mui/material';
import * as locales from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Font } from '@react-pdf/renderer';
import robotoBold from 'fonts/Roboto-Bold.ttf';
import robotoItalic from 'fonts/Roboto-Italic.ttf';
import robotoRegular from 'fonts/Roboto-Regular.ttf';
import robotoThin from 'fonts/Roboto-Thin.ttf';
import { SnackbarProvider } from 'notistack';
import { Quotes } from 'pages/Quote';
import { UserProfile } from 'pages/User/UserProfile';
import { VoyageProfitAndLoss } from 'pages/VoyageProfitAndLoss';
import { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavBar, PrivateRoute } from './components';
import useDarkModeState from './hooks/useDarkMode';
import { useLanguageState } from './hooks/useLanguageState';
import {
  bargeTripsPath,
  bargesPath,
  businessUnitsPath,
  calendarPath,
  changePasswordPath,
  configurationsPath,
  counterPartiesPath,
  dashboardPath,
  dataMaintenancePath,
  dealPath,
  dealPortFormPath,
  forgotPasswordPath,
  integrationsPath,
  interruptionDefinitionsPath,
  invitationPath,
  laycanCalculationPath,
  maritimeDirectoriesPath,
  ocrPath,
  ocrWizardPath,
  portsPath,
  productPath,
  profitAndLossPath,
  publicLaytimeChart,
  quotePath,
  registerNewTenantPath,
  reportsPath,
  signInPath,
  statementOfFactPath,
  tradersPath,
  userPath,
  userProfilePath,
  vesselPath,
  voyageProfitAndLossPath
} from './shared/links';

import {
  BargeTrips,
  Barges,
  BusinessUnits,
  Calendar,
  ChangePassword,
  Configurations,
  CounterPartyPage,
  Dashboard,
  DealPortForm,
  Deals,
  DemurragePage,
  ForgotPassword,
  Integrations,
  InterruptionDefinitions,
  Invitation,
  MaritimeDirectories,
  OcrScan,
  OcrScanWizard,
  Ports,
  Products,
  ProfitAndLoss,
  PublicLaytimeChart,
  RegisterTenant,
  Reports,
  SignIn,
  StatementOfFacts,
  Traders,
  Users,
  Vessels
} from './pages';
import { TXT_DOWNLOAD_LAYTIME_CHART } from '../../../shared/translations';
import DataMaintenance from 'pages/DataMaintenance/DataMaintenance';

type SupportedLocales = keyof typeof locales;

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: robotoRegular
    },
    {
      src: robotoBold,
      fontWeight: 'bold'
    },
    {
      src: robotoThin,
      fontWeight: 'thin'
    },
    {
      src: robotoItalic,
      fontStyle: 'italic'
    }
  ]
});

const App = () => {
  const [darkMode, setDarkMode] = useDarkModeState();
  const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [language] = useLanguageState();

  useEffect(() => {
    if (darkMode === undefined) {
      setDarkMode(systemDarkMode);
    }
  }, [systemDarkMode]);

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: darkMode ? 'dark' : 'light'
          }
        },
        locales[language as SupportedLocales]
      ),
    [darkMode, language]
  );
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <SnackbarProvider>
              <NavBar>
                <Routes>
                  <Route path={signInPath} element={<SignIn />} />
                  <Route path={invitationPath} element={<Invitation />} />
                  <Route path={registerNewTenantPath} element={<RegisterTenant />} />
                  <Route
                    path={publicLaytimeChart}
                    element={<PublicLaytimeChart title={TXT_DOWNLOAD_LAYTIME_CHART} />}
                  />
                  <Route path={forgotPasswordPath} element={<ForgotPassword />} />
                  <Route path={changePasswordPath} element={<ChangePassword />} />
                  <Route path={`${dealPortFormPath}/:linkId`} element={<DealPortForm />} />
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path={dashboardPath} element={<Dashboard />} />
                    <Route path={vesselPath} element={<Vessels />} />
                    <Route path={counterPartiesPath} element={<CounterPartyPage />} />
                    <Route path={userPath} element={<Users />} />
                    <Route path={`${laycanCalculationPath}/:dealId`} element={<DemurragePage />} />
                    <Route path={dealPath} element={<Deals />} />
                    <Route path={quotePath} element={<Quotes />} />
                    <Route path={portsPath} element={<Ports />} />
                    <Route path={productPath} element={<Products />} />
                    <Route path={interruptionDefinitionsPath} element={<InterruptionDefinitions />} />
                    <Route path={reportsPath} element={<Reports />} />
                    <Route path={integrationsPath} element={<Integrations />} />
                    <Route path={tradersPath} element={<Traders />} />
                    <Route path={businessUnitsPath} element={<BusinessUnits />} />
                    <Route path={configurationsPath} element={<Configurations />} />
                    <Route path={userProfilePath} element={<UserProfile />} />
                    <Route path={profitAndLossPath} element={<ProfitAndLoss />} />
                    <Route path={voyageProfitAndLossPath} element={<VoyageProfitAndLoss />} />
                    <Route path={bargesPath} element={<Barges />} />
                    <Route path={bargeTripsPath} element={<BargeTrips />} />
                    <Route path={calendarPath} element={<Calendar />} />
                    <Route path={`${ocrPath}/:statementOfFactId`} element={<OcrScan />} />
                    <Route path={ocrPath} element={<OcrScan />} />
                    <Route path={`${ocrWizardPath}/:statementOfFactId`} element={<OcrScanWizard />} />
                    <Route path={ocrWizardPath} element={<OcrScanWizard />} />
                    <Route path={statementOfFactPath} element={<StatementOfFacts />} />
                    <Route path={maritimeDirectoriesPath} element={<MaritimeDirectories />} />
                    <Route path={dataMaintenancePath} element={<DataMaintenance />} />
                  </Route>
                </Routes>
              </NavBar>
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
};

export default App;
