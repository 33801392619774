import { useMutation } from '@apollo/client';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DELETE_INTERRUPTION_DEFINITION } from '../../api';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';
import { TXT_DELETE_CONFIRMATION, TXT_DELETE_TEXT, TXT_NO, TXT_SAVING, TXT_YES } from '../../../../../shared/translations';
import { interruptionDefinitionModel } from 'model';

export interface InterruptionDeleteConfirmationProps extends interruptionDefinitionModel {
  onCancel: () => void;
  afterSaving: () => void;
}

// eslint-disable-next-line max-len
export const InterruptionDeleteConfirmation = (props: InterruptionDeleteConfirmationProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [deleteInterruption, { loading }] = useMutation(DELETE_INTERRUPTION_DEFINITION, {
    variables: {
      input: {
        id: props.id
      }
    },
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSaving();
      props.onCancel();
    }
  });

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: props.code
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  const onSubmit = () => {
    deleteInterruption();
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>{t(TXT_DELETE_CONFIRMATION).toUpperCase()}</DialogTitle>
      <DialogContent>{txtConfirmation}</DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary" disabled={loading}>
          {t(TXT_NO)}
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_YES)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
