import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_BARGE_LAYTIME_PER_COUNTERPARTY } from '../../../../../../shared/translations';
import { BargeReportFilter, CargoIcon } from 'components';

export const BargeReportCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openFilter, setOpenFilter] = useState(false);

  const showCloseFilter = () => setOpenFilter((prev) => !prev);

  return (
    <>
      {openFilter && <BargeReportFilter onCancel={showCloseFilter} />}
      <Card>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <CardActionArea
            onClick={showCloseFilter}
            sx={{
              padding: theme.spacing(2)
            }}
          >
            <Stack direction="row" spacing={2}>
              <div>
                <CargoIcon fontSize="large" />
              </div>
              <Typography variant="h5" align="center">
                {capitalizeFirstLetterEveryword(t(TXT_BARGE_LAYTIME_PER_COUNTERPARTY))}
              </Typography>
            </Stack>
          </CardActionArea>
        </Stack>
      </Card>
    </>
  );
};
