import { gql } from '@apollo/client';
import { maritimeDirectoryModel } from 'model';
import { UpdateContactInput } from '../contact';

export interface AddMaritimeDirectoryInput
  extends Omit<maritimeDirectoryModel, 'id' | 'contact'> {
  contactInput: UpdateContactInput;
  portIds: string[];
}

export interface UpdateMaritimeDirectoryInput extends AddMaritimeDirectoryInput {
  id: string;
}

export interface DeleteMaritimeDirectoryInput {
  id: string;
}

export interface AddMaritimeDirectoryOutput {
  maritimeDirectory: maritimeDirectoryModel;
}

export const ADD_MARITIME_DIRECTORY = gql`
  mutation addMaritimeDirectory($input: AddMaritimeDirectoryInput!) {
    maritimeDirectory: addMaritimeDirectory(input: $input) {
      id
    }
  }
`;

export const UPDATE_MARITIME_DIRECTORY = gql`
  mutation updateMaritimeDirectory($input: UpdateMaritimeDirectoryInput!) {
    maritimeDirectory: updateMaritimeDirectory(input: $input) {
      id
    }
  }
`;

export const DELETE_MARITIME_DIRECTORY = gql`
  mutation deleteMaritimeDirectory($input: DeleteMaritimeDirectoryInput!) {
    deleteMaritimeDirectory(input: $input)
  }
`;
