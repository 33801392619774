export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL;
export const dashboardPath = '/';
export const signInPath = '/signin';
export const registerTenantPath = '/create-tenant';
export const vesselPath = '/vessels';
export const counterPartiesPath = '/counterparties';
export const productPath = '/products';
export const interruptionDefinitionsPath = '/interruptionDefinitions';
export const dealPath = '/deals';
export const quotePath = '/quotes';
export const portsPath = '/ports';
export const laycanCalculationPath = '/laycancalculation';
export const userPath = '/users';
export const invitationPath = '/invitation';
export const reportsPath = '/reports';
export const integrationsPath = '/integrations';
export const tradersPath = '/traders';
export const businessUnitsPath = '/businessUnits';
export const configurationsPath = '/configurations';
export const registerNewTenantPath = '/register';
export const publicLaytimeChart = '/public/laytimechart/:linkId';
export const userProfilePath = '/user-profile';
export const profitAndLossPath = '/profit-and-loss';
export const voyageProfitAndLossPath = '/voyage-profit-and-loss';
export const bargesPath = '/barges';
export const bargeTripsPath = '/bargeTrips';
export const calendarPath = '/calendar';
export const forgotPasswordPath = '/forgot-password';
export const changePasswordPath = '/change-password';
export const ocrPath = '/ocr';
export const ocrWizardPath = '/ocr-wizard';
export const statementOfFactPath = '/statement-of-fact';
export const maritimeDirectoriesPath = '/maritime-directories';
export const dealPortFormPath = '/deal-port-form';
export const dataMaintenancePath = '/data-maintenance';
