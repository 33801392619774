import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { pageState } from 'state';

export const useTitle = (title: string) => {
  const { t } = useTranslation();
  const [page, setPage] = useRecoilState(pageState);
  useEffect(() => {
    setPage({
      ...page,
      title: capitalizeFirstLetterEveryword(t(title)).toUpperCase()
    });
  }, [t]);
};
