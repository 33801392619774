import { gql } from '@apollo/client';
import { IShexCondition } from './shexConditionQueries';

export type UpdateShexConditionsOutput = {
  updateShexConditions: IShexCondition[];
};

export const UPDATE_SHEX_CONDITIONS = gql`
  mutation updateShexConditions($laytimeCalculationId: UUID!, $input: [UpdateShexConditionInput]!) {
    updateShexConditions(laytimeCalculationId: $laytimeCalculationId, input: $input) {
      description
      remarks
      id
      laytimeCalculationId
      fromDayOfWeek
      fromDate
      fromTime
      untilDayOfWeek
      untilDate
      untilTime
      shexConditionType
      ignore
    }
  }
`;
