import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useMaritimeDirectoryStyle = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      backgroundColor: theme.palette.background.default
    },
    letters: {
      'height': 'calc(100vh - 135px)',
      'overflowX': 'hidden',
      'overflowY': 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none'
    }
  })
);
