import { gql } from '@apollo/client';
import { holidayModel } from 'model';

export interface GetHolidaysOutput {
  holidays?: {
    items?: holidayModel[],
    totalCount?: number;
  }
};

export const GET_HOLIDAYS = gql`
query getHolidays($year: Int!, $take: Int!, $skip: Int!, $where: HolidayFilterInput) {
  holidays(year: $year, take: $take, skip: $skip, where: $where) {
    items {
      id
      name
      date
      culture
    }
    totalCount
  }
}
`;
