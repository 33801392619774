export const TXT_ACTIVE = 'active';
export const TXT_ADD = 'add';
export const TXT_OPTIONAL = 'optional';
export const TXT_SELECTED_COUNTERPARTIES = 'selected counterparties';
export const TXT_INCLUDE_ALL_PARTIES = 'include all counterparties present in a deal';
export const TXT_ADD_COUNTERPARTIES_MESSAGE = 'add one or more counterparties';
export const TXT_EDIT_INTERRUPTIONS = 'edit interruptions';
export const TXT_CALENDAR_RULES = 'calendar rules';
export const TXT_ADMIN = 'administrator';
export const TXT_AGREED = 'agreed';
export const TXT_ALL = 'all';
export const TXT_ALIAS = 'alias';
export const TXT_API_KEY = 'aPI key';
export const TXT_APPLICABLE_PERCENTAGE = 'applicable percentage';
export const TXT_TO_DISCOUNT = 'to discount';
export const TXT_BILL_OF_LADING_DATE = 'bill of lading';
export const TXT_BL = 'bl';
export const TXT_BL_QUANTITY = 'bl quantity';
export const TXT_BUSINESS_UNIT = 'business unit';
export const TXT_INTERNAL_DEAL = 'internal deal';
export const TXT_BUSINESS_UNITS = 'business units';
export const TXT_CALCULATE_AGAINST = 'calculate against';
export const TXT_CALCULATION_RESULTS = 'calculation result';
export const TXT_CALCULATIONS = 'calculations';
export const TXT_CANCEL = 'cancel';
export const TXT_CREATE_ANOTHER = 'create another';
export const TXT_CLEAR = 'clear';
export const TXT_CLIENT = 'client';
export const TXT_CLIENTS = 'clients';
export const TXT_COMMENCED = 'commenced';
export const TXT_COMPLETED = 'completed';
export const TXT_CONFIGURATIONS = 'configurations';
export const TXT_COUNTERPARTIES = 'counterparties';
export const TXT_COUNTERPARTY = 'counterparty';
export const TXT_COUNTERPARTY_TYPE = 'counterparty type';
export const TXT_COUNTERPARTY_TYPES = 'counterparty types';
export const TXT_SHIPOWNER_NOT_SET = 'shipowner has not been set';
export const TXT_PORTS = 'ports';
export const TXT_CLOSED = 'closed';
export const TXT_CONNECT_TO_MICROSOFT_ACCOUNT = 'connect to microsoft account';
export const TXT_COPY = 'copy';
export const TXT_COUNT_AS_LAYTIME = 'count as laytime';
export const TXT_COUNTRY = 'country';
export const TXT_CREATE_API_KEY = 'create an aPI key';
export const TXT_CREATE_BUSINESS_UNIT = 'create a business unit';
export const TXT_CREATE_COUNTERPARTY = 'create a counterparty';
export const TXT_OVERRIDE_LAYTIME_CALC_FINAL_VALUE = 'override final result value';
export const TXT_OVERRIDE_LAYTIME_COMMENCED = 'override laytime commenced';
export const TXT_UNDO_OVERRIDE_LAYTIME_CALC_FINAL_VALUE = 'undo final result override';
export const TXT_CREATE_DEAL = 'create a deal';
export const TXT_CREATE_QUICK_CALCULATION = 'create a quick calculation';
export const TXT_CREATE_PORT = 'create a port';
export const TXT_CREATE_INTERRUPTION = 'create a interruption';
export const TXT_CREATE_TRADER = 'create a trader';
export const TXT_CREATE_PRODUCT = 'create a product';
export const TXT_CREATE_VESSEL = 'create a vessel';
export const TXT_CURRENCY = 'currency';
export const TXT_DARK_THEME = 'dark theme';
export const TXT_DASHBOARD = 'dashboard';
export const TXT_DAY = 'day';
export const TXT_DEALS = 'deals';
export const TXT_QUICK_CALCS = 'quick calculations';
export const TXT_QUICK_CALC = 'quick calculation';
export const TXT_DEAL = 'deal';
export const TXT_DEAL_INFO = 'deal info';
export const TXT_DEAL_NUMBER = 'deal number';
export const TXT_PARENT_NUMBER = 'parent deal number';
export const TXT_TXT_VOYAGE_NAME = 'voyage name';
export const TXT_DESPATCH_DECIMAL_EQUIVALENT = 'despatch decimal equivalent';
export const TXT_DELETE = 'delete';
export const TXT_DELETE_CONFIRMATION = 'delete confirmation';
export const TXT_DELETE_TEXT = 'are you sure you want to delete "{{name}}"?';
export const TXT_CONFIRM_PASTE_PARAMS_TEXT = 'Confirm the data you want to Copy';
export const TXT_IGNORE = 'ignore';
export const TXT_LAYTIME = 'laytime';
export const TXT_DEMURRAGE = 'demurrage';
export const TXT_DEMURRAGE_DECIMAL_EQUIVALENT = 'demurrage decimal equivalent';
export const TXT_DEMURRAGE_P_DAY = 'demurrage p/day';
export const TXT_DEMURRAGE_IN_CURRENCY = 'demurrage final value';
export const TXT_DESCRIPTION = 'description';
export const TXT_REMARKS = 'remarks';
export const TXT_ERRORS = 'errors';
export const TXT_DESPATCH = 'despatch';
export const TXT_DESPATCH_IN_CURRENCY = 'despatch final value';
export const TXT_DESPATCH_P_DAY = 'despatch p/day';
export const TXT_DISABLE = 'disable';
export const TXT_DOCUMENT_USED = 'document used';
export const TXT_DRAFT = 'draft';
export const TXT_EDIT = 'edit';
export const TXT_EMAIL = 'email';
export const TXT_EMAILS = 'emails';
export const TXT_ENABLE = 'enable';
export const TXT_VALID_NOR = 'validNor';
export const TXT_ALWAYS_ON_DEMURRAGE = 'always on demurrage';
export const TXT_NOT_ALWAYS_ON_DEMURRAGE = 'not always on demurrage';
export const TXT_EVEN_IF_SOONER_COMMENCED = 'even if sooner commenced';
export const TXT_FIELD_IS_REQUIRED = 'this field is required';
export const TXT_FREIGHT_PROVIDER = 'freight provider';
export const TXT_FULL_NAME = 'full name';
export const TXT_GENERATING_REPORT = 'generating report';
export const TXT_GOOGLE_ACCOUNT = 'google account';
export const TXT_GO_TO_SIGN_IN_PAGE = 'go to sign in page';
export const TXT_INITIAL = 'initials';
export const TXT_REFERENCE = 'reference';
export const TXT_INTEGRATIONS = 'integrations';
export const TXT_INTERRUPTIONS = 'interruptions';
export const TXT_INTERRUPTION_DEFINITIONS = 'interruption definitions';
export const TXT_INTERRUPTION_DEFINITION = 'interruption definition';
export const TXT_INVITATION_NOT_FOUND = 'the invitation link has been expired or invalid.';
export const TXT_INVITATION_SENT = 'the invitation has been sent to {{email}}.';
export const TXT_INVITE = 'invite';
export const TXT_INVITE_NEW_USERS = 'invite new users';
export const TXT_INVITE_USERS = 'invite users';
export const TXT_INVITING = 'inviting';
export const TXT_INVOICED = 'invoiced';
export const TXT_LAYTIME_CALCULATION = 'laytime calculation';
export const TXT_LAYTIME_CHART = 'laytime chart';
export const TXT_LAYTIME_PER_COUNTERPARTY = 'laytime per counterparty';
export const TXT_SUPPLIER_VS_OWNER_REPORT = 'supplier vs owner';
export const TXT_LAYCAN = 'laycan';
export const TXT_LAYCAN_END = 'laycan end';
export const TXT_LAYCAN_START = 'laycan start';
export const TXT_LESS_INTERRUPTIONS = 'less interruptions';
export const TXT_LIGHT_THEME = 'light theme';
export const TXT_LOADING = 'loading';
export const TXT_LOADING_COMMENCED = 'loading commenced';
export const TXT_LOADING_COMPLETED = 'loading completed';
export const TXT_DISCHARGE_COMMENCED = 'discharge commenced';
export const TXT_DISCHARGE_COMPLETED = 'discharge completed';
export const TXT_LOADING_PORT = 'loading port';
export const TXT_LOADING_PORTS = 'loading ports';
export const TXT_LOADING_RATE = 'loading rate';
export const TXT_DISCHARGE_RATE = 'discharge rate';
export const TXT_MASS_UNIT = 'mass unit';
export const TXT_MICROSOFT_ACCOUNT = 'microsoft account';
export const TXT_MV_ON_DEMURRAGE = 'vessel on demurrage';
export const TXT_NAME = 'name';
export const TXT_NEGOTIATING = 'negotiating';
export const TXT_NOT_APPLICABLE = 'not applicable';
export const TXT_CODE = 'code';
export const TXT_CAPACITY = 'capacity';
export const TXT_NET_TIME_USED = 'net time used';
export const TXT_NO = 'no';
export const TXT_NOR_TENDERED = 'nor tendered';
export const TXT_NOT_ACCEPTED = 'not accepted';
export const TXT_NOT_COUNT_AS_LAYTIME = 'not count as laytime';
export const TXT_NO_DATA_FOUND = 'no data found';
export const TXT_OR = 'or';
export const TXT_PASSWORD = 'password';
export const TXT_PASTE = 'paste';
export const TXT_PERMITTED_TIME = 'allowed time';
export const TXT_PORT = 'port';
export const TXT_PROCESSED = 'processed';
export const TXT_QUANTITY = 'quantity';
export const TXT_SHARED_PERCENTAGE = 'Shared Percentage';
export const TXT_REAL_LT_BEGINS = 'laytime begins';
export const TXT_LAYTIME_ENDS = 'laytime ends';
export const TXT_PERMITTED_TIME_ROUNDING = 'permitted time rounding';
export const TXT_SUPPLIER_CONTRIBUTION = 'supplier contribution';
export const TXT_SUPPLIER_CONTRIBUTION_ERROR = 'supplier’s conttibution does not add to 100%';
export const TXT_MODIFYING_LOCKED_CALC_VALIDATION =
  'The value of this calculation is locked! The changes were saved but the final value did not change.';
export const TXT_RECEIVER = 'receiver';
export const TXT_RECEIVERS = 'receivers';
export const TXT_REGISTER = 'register';
export const TXT_REGISTERING = 'registering';
export const TXT_REGISTER_A_NEW_TENANT = 'register a new tenant';
export const TXT_REGISTER_THE_FIRST_USER = 'register the first user';
export const TXT_REJECTED = 'rejected';
export const TXT_RETYPE_PASSWORD = 're-type password';
export const TXT_REPORTS = 'reports';
export const TXT_ROWS_PER_PAGE = 'rows per page';
export const TXT_SALES_CONTRACT = 'sales contract';
export const TXT_SAVE = 'save';
export const TXT_SAVING = 'saving';
export const TXT_SAVE_AND_CLOSE = 'save & close';
export const TXT_INTERRUPTION_TOO_BIG = 'time span has 48 hours or more';
export const TXT_INTERRUPTION_HAS_DATES_IN_FUTURE = 'dates in the future are not allowed';
export const TXT_SEARCH = 'search';
export const TXT_SEARCH_BY_NAME = 'search by name';
export const TXT_SEARCH_BY_USERNAME_NAME_INITIAL = 'search by username or name or initial';
export const TXT_SHIPOWNER = 'shipowner';
export const TXT_SIGNING_IN = 'signing in';
export const TXT_SIGNING_OUT = 'signing out';
export const TXT_SIGN_IN = 'sign In';
export const TXT_SIGN_OUT = 'sign out';
export const TXT_SINCE = 'since';
export const TXT_STATUS = 'status';
export const TXT_STATUS_COMMENT = 'status comment';
export const TXT_SUBMIT = 'submit';
export const TXT_SUPPLIER = 'supplier';
export const TXT_SUPPLIERS = 'suppliers';
export const TXT_SPECIAL_CONDITION = 'special condition';
export const TXT_ONCEONDEMURRAGE_CONDITION = 'once on demurrage...';
export const TXT_TERM = 'term';
export const TXT_TIME_IN_DESPATCH = 'time in despatch';
export const TXT_TIME_IN_DEMURRAGE = 'time in demurrage';
export const TXT_TO = 'to';
export const TXT_UNTIL = 'until';
export const TXT_FROM = 'from';
export const TXT_TOTAL_TIME_USED = 'total time used';
export const TXT_TRAFFIC_ANALYST = 'traffic analyst';
export const TXT_TRADER = 'trader';
export const TXT_TRADERS = 'traders';
export const TXT_TURN_TIME = 'turn time';
export const TXT_TYPE = 'type';
export const TXT_VESSEL_TYPE = 'vessel type';
export const TXT_UNLOADING = 'unloading';
export const TXT_CHILD_DEAL = 'child deal';
export const TXT_CHILD_DEALS = 'child deals';
export const TXT_UNLOADING_PORT = 'unloading port';
export const TXT_UNLOADING_PORTS = 'unloading ports';
export const TXT_UNLOADING_COMMENCED = 'unloading commenced';
export const TXT_VESSEL_OUTSIDE_LAYCAN = 'vessel is outside of laycan';
export const TXT_UNLOADING_COMPLETED = 'unloading completed';
export const TXT_UNLESS_SOONER_COMMENCED = 'unless sooner commenced';
export const TXT_UPDATE_BUSINESS_UNIT = 'update business unit';
export const TXT_UPDATE_COUNTERPARTY = 'update counterparty';
export const TXT_UPDATE_DEAL = 'update a deal';
export const TXT_UPDATE_QUICK_CALC = 'update a quick calculation';
export const TXT_BATCH_OVERRIDE_TITLE = 'select the calculations to override';
export const TXT_UPDATE_VESSEL = 'update vessel';
export const TXT_UPDATE_PORT = 'update port';
export const TXT_UPDATE_INTERRUPTION = 'update interruption';
export const TXT_UPDATE_PRODUCT = 'update product';
export const TXT_UPDATE_TRADER = 'update trader';
export const TXT_USER = 'user';
export const TXT_USERS = 'users';
export const TXT_VESSEL = 'vessel';
export const TXT_LAKER = 'laker';
export const TXT_VESSELS = 'vessels';
export const TXT_PRODUCT = 'product';
export const TXT_PRODUCTS = 'products';
export const TXT_MASS_UOM_OVERRIDE = 'UOM override';
export const TXT_VESSEL_INFORMATION = 'vessel information';
export const TXT_IMO = 'IMO';
export const TXT_WELCOME = '{{name}}';
export const TXT_WITH = 'with';
export const TXT_X_TO_DISCOUNT = '(x) to discount';
export const TXT_YES = 'yes';
export const TXT_CREATING_LAYTIME_CALCULATION = 'creating laytime calculation';
export const TXT_ADD_LAYTIME_CALCULATION = 'add laytime calculation';
export const TXT_ADD_HOLIDAY = 'add holiday';
export const TXT_NEW_VALUE = 'new value';
export const TXT_OLD_VALUE = 'current value';
export const TXT_PREVIOUS = 'previous';
export const TXT_NEXT = 'next';
export const TXT_VESSEL_INFORMATION_OVERRIDE = 'vessel information override';
export const TXT_FIELD = 'field';
export const TXT_CHANGE_CALCULATION_STATUS = 'change calculation status';
export const TXT_OVERRIDE_ALL_CALCULATIONS = 'override all calculations';
export const TXT_BACK_TO_SIGN_IN_PAGE = 'back to sign in page';
export const TXT_UPDATE_USER = 'update user';
export const TXT_UPDATE_MY_PROFILE = 'update my profile';
export const TXT_SELECTED_ITEMS = '{{count}} calculation(s) selected';
export const TXT_PORT_TYPE = 'port type';
export const TXT_DATE = 'date';
export const TXT_TIME = 'time';
export const TXT_ADD_INTERRUPTION = 'add interruption';
export const TXT_ADD_RECURRENT_SHEX = 'add recurrent shex';
export const TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD = 'the link has been copied to the clipboard';
export const TXT_CLICK_HERE_TO_OPEN = 'Click <0>here</0> to open';
export const TXT_CREATE_SHAREABLELINK = 'create shareable link';
export const TXT_UPDATE_SHAREABLELINK = 'update shareable link';
export const TXT_LINK_ID = 'link id';
export const TXT_DOWNLOAD_LAYTIME_CHART = 'download laytime chart';
export const TXT_DOWNLOAD = 'download';
export const TXT_DOWNLOADING = 'downloading';
export const TXT_LINKED_ACCOUNTS = 'linked accounts';
export const TXT_USER_PROFILE = 'user profile';
export const TXT_CHANGE_PASSWORD = 'change password';
export const TXT_USER_PROFILE_UPDATED_SUCCESSFULLY = 'user profile updated successfully';
export const TXT_LAYTIME_CHART_REPORT_FILTER = 'laytime chart report filter';
export const TXT_LAYTIME_PER_COUNTERPARTY_REPORT_FILTER = 'laytime per counterparty report filter';
export const TXT_SUPPLIER_VS_OWNER_REPORT_FILTER = 'supplier vs owner laytime report filter';
export const TXT_PROFIT_AND_LOSS = 'profit and loss';
export const TXT_DEAL_PROFIT_AND_LOSS = 'deal profit and loss';
export const TXT_VOYAGE_PROFIT_AND_LOSS = 'voyage profit and loss';
export const TXT_TRAFFIC_SPECIALIST = 'traffic specialist';
export const TXT_SETTLE_STATUS = 'settle status';
export const TXT_NET = 'net';
export const TXT_PROFIT = 'profit';
export const TXT_LOSS = 'loss';
export const TXT_COMPANY = 'company';
export const TXT_DISCHARGE_COUNTRY = 'discharge country';
export const TXT_DISCHARGE_PORT = 'discharge port';
export const TXT_REPORT_COPIED = 'report content has been copied to the clipboard';
export const TXT_EXCHANGE_RATE = 'exchange rate';
export const TXT_BARGE = 'barge';
export const TXT_BARGES = 'barges';
export const TXT_HELP = 'help';
export const TXT_CREATE_BARGE = 'create barge';
export const TXT_UPDATE_BARGE = 'update barge';
export const TXT_BARGE_TRIPS = 'barge trips';
export const TXT_CREATE_BARGE_TRIP = 'create barge trip';
export const TXT_UPDATE_BARGE_TRIP = 'update barge trip';
export const TXT_ASSOCIATED_DEAL_NUMBER = 'associated deal number';
export const TXT_DUPLICATING_BARGE = 'duplicating barge...';
export const TXT_ARRIVAL_DATE = 'arrival date';
export const TXT_ARRIVAL_DATE_OVERRIDE = 'arrival date override';
export const TXT_COMPLETION_DATE = 'completion date';
export const TXT_COMPLETION_DATE_OVERRIDE = 'completion date override';
export const TXT_RELEASE_DATE = 'release date';
export const TXT_RELEASE_DATE_OVERRIDE = 'release date override';
export const TXT_AND_MORE = 'and {{count}} more';
export const TXT_FREE_TIME = 'free time';
export const TXT_DAYS = 'days';
export const TXT_HOURS = 'hours';
export const TXT_MINUTES = 'minutes';
export const TXT_TOTAL_DEMURRAGE = 'total demurrage';
export const TXT_OWNER = 'owner';
export const TXT_BARGE_TRIP_INFO = 'barge trip info';
export const TXT_TOTAL = 'total';
export const TXT_CALENDAR_RULE = 'calendar rule';
export const TXT_HOLIDAYS = 'holidays';
export const TXT_CALENDAR = 'calendar';
export const TXT_YEAR = 'year';
export const TXT_EN_US = 'EN_US';
export const TXT_ES_ES = 'ES_ES';
export const TXT_NO_ROWS = 'no rows';
export const TXT_UPDATE = 'update';
export const TXT_CREATE_HOLIDAY = 'create holiday';
export const TXT_UPDATE_HOLIDAY = 'update holiday';
export const TXT_HOLIDAY = 'holiday';
export const TXT_BARGE_REPORT_FILTER = 'barge report filter';
export const TXT_FORGOT_PASSWORD = 'forgot password';
export const TXT_FORGOT_PASSWORD_TITLE = 'forgot your password?';
export const TXT_FORGOT_PASSWORD_TEXT =
  'no worries! enter your email address and we will send you the instructions to reset your password';
export const TXT_VERICIATION_CODE_TITLE = 'input verification code';
export const TXT_VERICIATION_CODE = 'verification code';
export const TXT_VERICIATION_CODE_TEXT =
  'to verify your account, you need to confirm your Atreus account. Please input the verification code that we sent to you';
export const TXT_VERIFY = 'verify';
export const TXT_ENTER_NEW_PASSWORD = 'enter new password to access your account';
export const TXT_BARGE_LAYTIME_PER_COUNTERPARTY = 'barge laytime per counterparty';
export const TXT_OCR = 'ocr';
export const TXT_UPLOADING = 'uploading';
export const TXT_NUMBER = 'number';
export const TXT_TEXT = 'text';
export const TXT_RESULT = 'result';
export const TXT_DRAG_N_DROP_TEXT = 'drag & drop some files here, or click to select files';
export const TXT_FILE_RESTRICTION = 'only image and pdf will be accepted';
export const TXT_EDIT_CURRENT_DEAL = 'edit current deal';
export const TXT_COMMENTS = 'notes';
export const TXT_TYPE_COMMENT_HERE = 'type comment here';
export const TXT_TYPE_REPLY_HERE = 'type reply here';
export const TXT_PRINT = 'print';
export const TXT_RESOLVE = 'resolve';
export const TXT_RESOLVED = 'resolved';
export const TXT_SEND = 'send';
export const TXT_UNRESOLVE = 'unresolve';
export const TXT_DELETE_COMMENT_WITH_REPLIES = 'are you sure you want to delete this comment including all replies?';
export const TXT_DELETE_COMMENT = 'are you sure you want to delete this comment?';
export const TXT_STATEMENT_OF_FACT = 'statement of fact';
export const TXT_DESTINATION = 'destination';
export const TXT_VALUE = 'value';
export const TXT_NOW = 'now';
export const TXT_OK = 'ok';
export const TXT_OCR_RESULT = 'ocr result';
export const TXT_QUICK_CALC_INFO = 'quick calculation info';
export const TXT_DETAILS = 'details';
export const TXT_CLOSE = 'close';
export const TXT_VERSION = 'version';
export const TXT_BACK_TO_PARENT = 'back to parent';
export const TXT_GO_TO = 'go to';
export const TXT_PROMOTE = 'promote';
export const TXT_RESET = 'reset';
export const TXT_CONFIRMATION = 'confirmation';
export const TXT_PROMOTE_CALCULATION_CONFIRMATION = 'are you sure you want to promote this calculation?';
export const TXT_RESET_CALCULATION_CONFIRMATION = 'are you sure you want to reset this calculation?';
export const TXT_WHAT_IF_SCENARIOS = 'What-If Scenarios';
export const TXT_CREATE_WHAT_IF_SCENARIOS = 'Create What-If Scenario';
export const TXT_UPDATE_WHAT_IF_SCENARIOS = 'Update What-If Scenario';
export const TXT_OCR_SCAN = 'ocr scan';
export const TXT_SPLIT = 'split';
export const TXT_MERGE = 'merge';
export const TXT_OPEN_WHAT_IF_SCENARIO = 'Open What-If Scenario';
export const TXT_MARITIME_DIRECTORIES = 'maritime directories';
export const TXT_CREATE_MARITIME_DIRECTORY = 'create maritime directory';
export const TXT_UPDATE_MARITIME_DIRECTORY = 'update maritime directory';
export const TXT_DELETE_MARITIME_DIRECTORY = 'are you sure you want to delete this contact?';
export const TXT_NOTES = 'notes';
export const TXT_ADDRESSES = 'addresses';
export const TXT_PHONES = 'phones';
export const TXT_ADDRESS = 'address';
export const TXT_PHONE = 'phone';
export const TXT_COPIED_TO_CLIPBOARD = 'copied to clipboard';
export const TXT_FAILED_TO_COPY_TO_CLIPBOARD = 'failed to copy to the clipboard';
export const TXT_CONTACT = 'contact';
export const TXT_AGENT = 'agent';
export const TXT_PORT_NOT_FOUND = 'port not found';
export const TXT_LOAD_INTERRUPTIONS = 'load interruptions';
export const TXT_SELECT_STATEMENT_OF_FACT = 'select statement of fact';
export const TXT_SOF_INFO = 'statement of fact information';
export const TXT_READY = 'ready';
export const TXT_USED = 'used';
export const TXT_SUMMARY = 'summary';
export const TXT_ASSIGN_TO = 'assign to';
export const TXT_VESSEL_PORTAL = 'vessel portal';
export const TXT_LATITUDE = 'latitude';
export const TXT_LONGITUDE = 'longitude';
export const TXT_SEND_REQUEST_FORM_TO_AGENT = 'send request form';
export const TXT_REQUEST_FORM = 'request form to agent';
export const TXT_UPDATE_PORT_AGENT = 'update port\'s agent';
export const TXT_PORT_INFORMATION = 'port information';
export const TXT_SAVED = 'saved';
export const TXT_EMAIL_HAS_BEEN_SENT = 'email has been sent to {{email}}';
export const TXT_SEND_EMAIL = 'send email';
export const TXT_SENDING_EMAIL = 'sending email to {{email}}';
export const TXT_CREATING_SHAREABLELINK = 'creating shareable link';
export const TXT_SUBMITTING = 'submitting';
export const TXT_SUBMITTED = 'submitted';
export const TXT_DEVELOPED_BY = 'developed by';
export const TXT_REQUESTED_BY = 'requested by';
export const TXT_RESPONDED = 'responded';
export const TXT_GRANT_ACCESS = 'grant access';
export const TXT_COPY_LINK = 'copy link';
export const TXT_DELETE_SHAREABLE_LINK = 'are you sure you want to delete this link?';
export const TXT_TONNAGE_ON_BOARD = 'tonnage on board';
export const TXT_TONNAGE_PENDING = 'tonnage pending';
export const TXT_EMAIL_ADDRESS = 'email address';
export const TXT_SEARCH_VESSEL = 'search vessel';
export const TXT_NO_VESSEL_FOUND = 'no vessel found';
export const TXT_NO_DEAL_FOUND = 'no deal found';
export const TXT_SEARCH_DEAL = 'search deal';
export const TXT_COURSE = 'course';
export const TXT_SPEED = 'speed';
export const TXT_DRAUGHT = 'draught';
export const TXT_CALLSIGN = 'callsign';
export const TXT_LENGTH = 'length';
export const TXT_BEAM = 'beam';
export const TXT_CROSSINGS = 'crossings';
export const TXT_DISTANCE = 'distance';
export const TXT_VESSEL_TRACKER = 'vessel tracker';
export const TXT_BILL_OF_LADING = 'bill of lading';
export const TXT_PORT_INFORMATION_UPDATED = 'port {{name}} information has been updated';
export const TXT_ETA_OUTSIDE_LAYCAN = 'ETA is outside of laycan';
export const TXT_OPERATOR = 'operator';
export const TXT_GUEST = 'guest';
export const TXT_ROLE = 'role';
export const TXT_PLEASE_SELECT_A_VESSEL = 'please select a vessel';
export const TXT_SEARCH_BY_SHIPMENT_DEAL_VESSEL = 'Search by shipment name, deal number, vessel name or vessel IMO';
export const TXT_CURRENT = 'current';
export const TXT_DIFFERENCE = 'difference';
export const TXT_REFRESH = 'refresh';
export const TXT_LAST_RESPOND = 'last respond';
export const TXT_EDIT_VESSEL = 'edit vessel';
export const TXT_HISTORY = 'history';
export const TXT_LOAD_MORE = 'load more';
export const TXT_LAT_LONG_EXAMPLE = '{{portName}} is located in {{country}} at {{latC}}, {{longC}}. Therefore the latitude is {{lat}} and the longitude is {{long}}.';
export const TXT_UPDATE_PORT_INFO = 'update port info';
export const TXT_SUBMIT_AND_COMPLETE = 'submit and complete';
export const TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_CONFIRMATION = 'are you sure you want to submit and complete this form?';
export const TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_WARNING = 'you will not be able to access this link in the future';
export const TXT_COMPLETE = 'complete';
export const TXT_SENDING = 'sending';
export const TXT_MESSAGE = 'message';
export const TXT_MESSAGES = 'messages';
export const TXT_TYPE_MESSAGE_HERE = 'type message here';
export const TXT_MERGE_PORTS = 'merge ports';
export const TXT_DATA_MAINTENANCE = 'data maintenance';
export const TXT_SOURCE_PORTS = 'source ports';
export const TXT_DESTINATION_PORT = 'destination port';
export const TXT_TEST = 'test';
export const TXT_APPLY = 'apply';
export const TXT_LOG = 'log';
export const TXT_STARTED_AT = 'started at';
export const TXT_FINISHED_AT = 'finished at';
export const TXT_DURATION = 'duration';

export const ERR_INVALID_TENANT = 'ERR_INVALID_TENANT';
export const ERR_UNKNOWN_ERROR = 'ERR_UNKNOWN_ERROR';
export const ERR_INVALID_SENDER_EMAIL = 'ERR_INVALID_SENDER_EMAIL';
export const ERR_INVALID_RECIPIENT_EMAIL = 'ERR_INVALID_RECIPIENT_EMAIL';
export const ERR_TENANT_ALIAS_EXISTED = 'ERR_TENANT_ALIAS_EXISTED';
export const ERR_USER_NOT_FOUND = 'ERR_USER_NOT_FOUND';
export const ERR_USER_DISABLED = 'ERR_USER_DISABLED';
export const ERR_USERNAME_EXISTED = 'ERR_USERNAME_EXISTED';
export const ERR_EMAIL_EXISTED = 'ERR_EMAIL_EXISTED';
export const ERR_USER_INITIAL_EXISTED = 'ERR_USER_INITIAL_EXISTED';
export const ERR_MICROSOFT_ACCOUNT_EXISTED = 'ERR_MICROSOFT_ACCOUNT_EXISTED';
export const ERR_INVALID_EMAILS = 'ERR_INVALID_EMAILS';
export const ERR_WRONG_PASSWORD = 'ERR_WRONG_PASSWORD';
export const ERR_INVALID_SPLIT_QUANTITY_NAME = 'ERR_INVALID_SPLIT_QUANTITY_NAME';
export const ERR_IMO_NOT_DEFINED = 'ERR_IMO_NOT_DEFINED';
export const ERR_PORT_NO_COORDINATES = 'ERR_PORT_NO_COORDINATES';
export const ERR_LOADING_PORT_RULES = 'the vessel trip must have at least one port that has a bill of lading date set';
export const ERR_DEAL_NAME_NOT_MATCH_PATTERN = 'ERR_DEAL_NAME_NOT_MATCH_PATTERN';
