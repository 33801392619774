import { useLazyQuery } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetInterruptionDefinitionsOutput, GET_INTERUPTION_DEFINITIONS } from '../../api';
import { PaginationBar } from '../../components';
import _ from 'lodash';
import { TXT_INTERRUPTION_DEFINITIONS, TXT_SEARCH_BY_NAME } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { InterruptionDefinitionsTable } from './InterruptionDefinitionsTable';
import { InterruptionDeleteConfirmation } from './InterruptionDefinitionDeleteConfirmation';
import { useInterruptionDefinitionsStyle } from './interruptionDefinitionsStyle';
import { interruptionDefinitionModel } from 'model';
import { InterruptionDefinitionEditor } from './InterruptionDefinitionEditor';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useTitle } from 'hooks';
import useAuth from 'hooks/useAuth';

export const InterruptionDefinitions = () => {
  useTitle(TXT_INTERRUPTION_DEFINITIONS);
  const interruptionsStyle = useInterruptionDefinitionsStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedInterruptionDefinitions, setSelectedInterruptionDefinitions] = useState<
    interruptionDefinitionModel | undefined
  >(undefined);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    let result = null;

    if (nameFilter && nameFilter.length > 0) {
      result = {
        or: [
          {
            code: {
              contains: nameFilter
            }
          },
          {
            description: {
              contains: nameFilter
            }
          }
        ]
      };
    }
    return result;
  };

  const [getInterruptionDefinitions, { loading, error, data, refetch }] =
    useLazyQuery<GetInterruptionDefinitionsOutput>(GET_INTERUPTION_DEFINITIONS, {
      fetchPolicy: 'network-only',
      variables: {
        take: pageSize,
        skip: pageSize * (pageNumber - 1),
        where: getFilter()
      },
      onError: apolloErrorHandler
    });

  const fetch = () => {
    if (!refetch) {
      getInterruptionDefinitions();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (interruptionDefinition: interruptionDefinitionModel) => {
    setSelectedInterruptionDefinitions(interruptionDefinition);
    setOpenEditor(true);
  };

  const onDelete = (interruptionDefinition: interruptionDefinitionModel) => {
    setSelectedInterruptionDefinitions(interruptionDefinition);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedInterruptionDefinitions(undefined);
  };

  const onEditorAfterSaving = () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  };

  const openAddEditor = () => {
    setSelectedInterruptionDefinitions(undefined);
    setOpenEditor(true);
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && (
        <InterruptionDefinitionEditor {...selectedInterruptionDefinitions} onClose={onEditorClosed} afterSave={fetch} />
      )}
      {openDeleteConfirmation && selectedInterruptionDefinitions && (
        <InterruptionDeleteConfirmation
          {...selectedInterruptionDefinitions}
          onCancel={closeDeleteConfirmation}
          afterSaving={onEditorAfterSaving}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={interruptionsStyle.content}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  id="search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InterruptionDefinitionsTable
                  loading={loading}
                  error={error}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  showActions={!isGuest}
                />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data?.interruptionDefinitions?.totalCount || 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={!isGuest ? openAddEditor : undefined}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
