import { atom } from 'recoil';

const laytimeCalculationStateKey = 'laytimeCalculationState';

export interface laytimeCalculationStateType {
  calculationReadyToBePastedId?: string;
};

export const defaultlaytimeCalculationState: laytimeCalculationStateType = { };

export const laytimeCalculationState = atom({
  key: laytimeCalculationStateKey,
  default: defaultlaytimeCalculationState
});
