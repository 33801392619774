import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import useExcelAPI, { SupplierVsOwnerLaytimeReportRequest } from 'hooks/useExcelAPI';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_CANCEL, TXT_DOWNLOAD, TXT_DOWNLOADING, TXT_SUPPLIER_VS_OWNER_REPORT_FILTER } from '../../../../../shared/translations';
import { SupplierVsOwnerLaytimeReportFilterContent } from './SupplierVsOwnerLaytimeReportFilterContent';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ignoreTimeZone } from 'shared/utils';

interface SupplierVsOwnerLaytimeReportFilterProps {
  onCancel: () => void;
}

const schema = yup.object().shape({
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  counterParties: yup.array().min(1, 'min 1 item').of(yup.string())
});

export const SupplierVsOwnerLaytimeReportFilter = (props: SupplierVsOwnerLaytimeReportFilterProps) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const defaultStartDate = new Date(`Jan 1 $${currentYear}`);
  const defaultEndDate = new Date(`Dec 31 $${currentYear}`);
  const counterParties : string[] = [];
  const ports : string[] = [];
  const { handleSubmit, setValue, formState, control } = useForm<SupplierVsOwnerLaytimeReportRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      counterParties: counterParties,
      portIds: ports,
      traderId: undefined,
      operatorId: undefined
    },
    reValidateMode: 'onChange'
  });
  const { getSupplierVsOwnerLaytimeReport } = useExcelAPI();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit:SubmitHandler<SupplierVsOwnerLaytimeReportRequest> = async (params) => {
    try {
      setIsLoading(true);
      await getSupplierVsOwnerLaytimeReport({
        startDate: ignoreTimeZone(params.startDate === null ? undefined : params.startDate),
        endDate: ignoreTimeZone(params.endDate === null ? undefined : params.endDate),
        counterParties: params.counterParties,
        portIds: params.portIds,
        traderId: params.traderId,
        operatorId: params.operatorId
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      props.onCancel();
    }
  };

  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle align='center'>{t(TXT_SUPPLIER_VS_OWNER_REPORT_FILTER).toUpperCase()}</DialogTitle>
        <DialogContent>
          <form id="frm-supplier-vs-owner-report" onSubmit={handleSubmit(onSubmit)}>
            <SupplierVsOwnerLaytimeReportFilterContent control={control} setValue={setValue} formState={formState} disabled={isLoading} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="secondary" disabled={isLoading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button disabled={isLoading || !formState.isValid} type="submit" form="frm-supplier-vs-owner-report">
            {isLoading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(isLoading ? TXT_DOWNLOADING : TXT_DOWNLOAD)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
