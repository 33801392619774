import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InterruptionColumn } from './interruptionColumns';

export interface InterruptionTableHeadProps {
  columns: InterruptionColumn[];
};

export const InterruptionTableHead = (props: InterruptionTableHeadProps) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {
          props.columns.map((col) => (
            <TableCell
              key={`head-${col.id}`}
              align={col.align || 'center'}
              style={{ width: col.maxWidth }}
            >
              {t(col.label).toUpperCase()}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};
