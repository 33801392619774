import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const invitationStyle = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10)
  },
  registerButton: {}
}));

export default invitationStyle;
