import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserColumn } from './userColumns';

export interface UserTableHeadProps {
  columns: UserColumn[];
};

export const UserTableHead = (props: UserTableHeadProps) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {
          props.columns.map((col) => (
            <TableCell
              key={`head-${col.id}`}
              align={col.align || 'center'}
              style={{ width: col.maxWidth }}
            >
              {t(col.label).toUpperCase()}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};
