import { Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_DEVELOPED_BY } from '../../../../../shared/translations';

export const CustomFooter = () => {
  const { t } = useTranslation();
  return (
    <Paper
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#121212',
        marginTop: '10px'
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Typography variant="inherit" color="white">
        {`${capitalizeFirstLetterEveryword(t(TXT_DEVELOPED_BY))} `}
      </Typography>
      <Link href="https://atreussoftware.com/" target='_blank'>
        <img src="/img/atreus_software_logo.png" height={30} />
      </Link>
    </Paper>
  );
};
