import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LaytimeCalculationStatus } from 'model';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getStatusName } from 'shared/utils';
import { TXT_STATUS } from '../../../../../shared/translations';

interface CalculationStatusSelectorProps {
  value?: LaytimeCalculationStatus;
  onChanged?: (newStatus: LaytimeCalculationStatus) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
  hideLabel?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
}

export const CalculationStatusSelector = (props: CalculationStatusSelectorProps) => {
  const id = props.id || 'status';
  const labelId = `${id}-lbl-status`;

  const { t } = useTranslation();

  const handleStatusChange = (e: any) => {
    props.onChanged && props.onChanged(e.target.value);
  };

  const lblStatus = !!props.hideLabel ? '' : capitalizeFirstLetterEveryword(t(props.label || TXT_STATUS));

  return (
    <FormControl fullWidth size="small" style={props.style} error={props.error}>
      <InputLabel id={labelId}>{lblStatus}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={lblStatus}
        value={props.value ?? ''}
        disabled={props.disabled}
        onChange={handleStatusChange}
      >
        <MenuItem value={'DRAFT'}>{getStatusName('DRAFT', t)}</MenuItem>
        <MenuItem value={'NEGOTIATING'}>{getStatusName('NEGOTIATING', t)}</MenuItem>
        <MenuItem value={'AGREED'}>{getStatusName('AGREED', t)}</MenuItem>
        <MenuItem value={'PROCESSED'}>{getStatusName('PROCESSED', t)}</MenuItem>
        <MenuItem value={'NOT_APPLICABLE'}>{getStatusName('NOT_APPLICABLE', t)}</MenuItem>
      </Select>
    </FormControl>
  );
};
