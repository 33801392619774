import { Typography, Container, useTheme, Grid, Backdrop, CircularProgress, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { signIn, signInWithMicrosoftAccount } from '../../api';
import { DividerWithLine, MicrosoftLoginButton, Copyright } from '../../components';
import useAuth from '../../hooks/useAuth';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_SIGN_IN,
  TXT_EMAIL,
  TXT_PASSWORD,
  TXT_SIGNING_IN,
  TXT_OR,
  TXT_WITH,
  TXT_FORGOT_PASSWORD
} from '../../../../../shared/translations';
import { AuthenticationResult } from '@azure/msal-browser';
import { CustomError } from '../../components';
import { LogInForm, LoginInputs } from './LogInForm';
import _ from 'lodash';
import useDarkModeState from 'hooks/useDarkMode';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { forgotPasswordPath } from 'shared/links';
import { useNavigate } from 'react-router-dom';

export const SignInPage = () => {
  const { t } = useTranslation();
  const [authState, setAuthState] = useAuth();
  const [darkMode] = useDarkModeState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const theme = useTheme();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [defaultSettings, setDefaultSettings] = useDefaultSettings();
  const navigate = useNavigate();

  const onSubmit = async (data: LoginInputs) => {
    setAuthState({
      ...authState
    });

    setIsSigningIn(true);
    const signInResponse = await signIn({
      username: data.username,
      password: data.password
    });
    setIsSigningIn(false);
    setAuthState({
      ...signInResponse
    });
    setErrorMessage(signInResponse.errorMessage);
  };

  const onReturnedAccountInfo = async (authResult: AuthenticationResult) => {
    const signInResponse = await signInWithMicrosoftAccount({
      token: authResult.idToken
    });
    setAuthState({
      ...authState,
      ...signInResponse
    });

    setErrorMessage(signInResponse.errorMessage);
  };

  const onMicrosoftLoginOpened = () => setIsSigningIn(true);
  const onMicrosoftLoginClosed = () => setIsSigningIn(false);

  const txtSignIn = capitalizeFirstLetterEveryword(t(TXT_SIGN_IN));
  const txtUsername = capitalizeFirstLetterEveryword(t(TXT_EMAIL));
  const txtPassword = capitalizeFirstLetterEveryword(t(TXT_PASSWORD));
  const txtSigningIn = capitalizeFirstLetterEveryword(t(TXT_SIGNING_IN));
  const txtOr = t(TXT_OR).toUpperCase();
  const txtWith = t(TXT_WITH).toUpperCase();

  useEffect(() => {
    if (authState && authState.id && authState.fullName) {
      setDefaultSettings({
        ...defaultSettings,
        trafficSpecialist: {
          id: authState.id,
          profile: {
            fullName: authState.fullName
          }
        }
      });
    }
  }, [authState]);

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(10) }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
        open={isSigningIn}
      >
        <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
          {txtSigningIn}
        </Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container direction="column" spacing={1} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <img src={`/img/${darkMode ? 'logo_name_white_wo_bg.png' : 'logo_name_wo_bg.png'}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{txtSignIn}</Typography>
        </Grid>
        {!!errorMessage && (
          <Grid item xs={12}>
            <CustomError>{_.capitalize(t(errorMessage))}</CustomError>
          </Grid>
        )}
        <Grid item xs={12}>
          <DividerWithLine>
            <Typography component="h6">{txtWith}</Typography>
          </DividerWithLine>
        </Grid>
        <Grid item xs={12}>
          <MicrosoftLoginButton
            onReturnedAccountInfo={onReturnedAccountInfo}
            variant="contained"
            color="primary"
            signinType="loginPopup"
            preSignIn={onMicrosoftLoginOpened}
            postSignIn={onMicrosoftLoginClosed}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <DividerWithLine>
            <Typography component="h6">{txtOr}</Typography>
          </DividerWithLine>
        </Grid>
        <Grid item xs={12}>
          <LogInForm
            txtPassword={txtPassword}
            txtSignIn={txtSignIn}
            txtSigningIn={txtSigningIn}
            txtUsername={txtUsername}
            fontSize="18px"
            isSigningIn={isSigningIn}
            onSubmit={onSubmit}
          />{' '}
          <Button variant="text" onClick={() => navigate(forgotPasswordPath)}>
            {t(TXT_FORGOT_PASSWORD)}
          </Button>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Copyright />
        </Grid>
      </Grid>
    </Container>
  );
};
