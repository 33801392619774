import { gql } from '@apollo/client';
import { bargeTripCounterPartyModel, bargeTripHolidayModel, bargeTripModel, bargeTripPortModel } from 'model';

export interface AddBargeTripCounterPartyInput extends Omit<bargeTripCounterPartyModel, 'id' | 'holidays'> { }
export interface UpdateBargeTripCounterPartyInput extends AddBargeTripCounterPartyInput {
  strId: string;
  holidays?: UpdateBargeTripHolidayInput[];
}

export interface AddBargeTripPortInput extends Omit<bargeTripPortModel, 'id'> { }
export interface UpdateBargeTripPortInput extends AddBargeTripPortInput {
  strId: string;
}

export interface AddBargeTripHolidayInput extends Omit<bargeTripHolidayModel, 'id'> { }
export interface UpdateBargeTripHolidayInput extends AddBargeTripHolidayInput {
  strId: string;
}

export interface AddBargeTripInput extends Omit<bargeTripModel,
  'id' |
  'ownerId' |
  'owner' |
  'receiverId' |
  'receiver' |
  'loadingPortId' |
  'loadingPort' |
  'unloadingPortId' |
  'unloadingPort'> {
  creatingDuplicate: boolean;
  owner: UpdateBargeTripCounterPartyInput;
  receiver: UpdateBargeTripCounterPartyInput;
  loadingPort: UpdateBargeTripPortInput;
  unloadingPort: UpdateBargeTripPortInput;
}

export interface UpdateBargeTripInput extends AddBargeTripInput {
  id: string;
}

export interface DeleteBargeTripInput {
  id: string;
}

export const ADD_BARGE_TRIP = gql`
  mutation addBargeTrip($input: AddBargeTripInput!) {
    addBargeTrip(input: $input) {
      id
    }
  }
`;

export const UPDATE_BARGE_TRIP = gql`
  mutation updateBargeTrip($input: UpdateBargeTripInput!) {
    updateBargeTrip(input: $input) {
      id
    }
  }
`;

export const DELETE_BARGE_TRIP = gql`
  mutation deleteBargeTrip($input: DeleteBargeTripInput!) {
    deleteBargeTrip(input: $input)
  }
`;
