import { HolidayCulture, applicationUserModel } from 'model';

export interface defaultSettingStorageProps {
  trafficSpecialist?: applicationUserModel;
  vesselType: string;
  pageSize: number;
  culture?: HolidayCulture;
}

const localStorageKey = 'atreus_default_settings';

export const resetDefaultSettingsState: defaultSettingStorageProps = {
  trafficSpecialist: undefined,
  pageSize: 10,
  culture: undefined,
  vesselType: 'VESSEL'
};

export const defaultStorage: defaultSettingStorageProps = {
  ...resetDefaultSettingsState
};

export const getDefaultSettingsLocalStorage = () => {
  const storedValue = localStorage.getItem(localStorageKey);
  if (!storedValue || storedValue === null) {
    localStorage.setItem(localStorageKey, JSON.stringify(defaultStorage));
    return defaultStorage;
  }

  return JSON.parse(storedValue) as defaultSettingStorageProps;
};

export const setDefaultSettingsLocalStorage = (value: defaultSettingStorageProps) => {
  localStorage.setItem(localStorageKey, JSON.stringify(value));
};
