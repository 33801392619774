import { useLazyQuery } from '@apollo/client';
import { FormHelperText, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { GET_DEAL_LAYTIME_CALCULATIONS, GetDealLaytimeCalculations } from 'api';
import { useApolloErrorHandler } from 'hooks';
import { dealModel, laytimeCalculationModel } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_CALCULATION_RESULTS,
  TXT_COPY,
  TXT_COUNTERPARTY,
  TXT_CURRENCY,
  TXT_DELETE,
  TXT_DEMURRAGE,
  TXT_DESPATCH,
  TXT_LOADING,
  TXT_PASTE,
  TXT_PORT,
  TXT_PROMOTE,
  TXT_PROMOTE_CALCULATION_CONFIRMATION,
  TXT_RESET,
  TXT_RESET_CALCULATION_CONFIRMATION,
  TXT_STATUS,
  TXT_STATUS_COMMENT,
  TXT_UNLOADING,
  TXT_VERSION
} from '../../../../../shared/translations';
import _ from 'lodash';
import { getCounterPartyLabel } from 'pages/DemurragePage/utils';
import { getStatusName } from 'shared/utils';
import { GridNoResultsOverlay } from 'components/GridNoResultsOverlay';
import { CopyCalculation, DeleteCalculation, PasteCalculation, PromoteCalculation } from 'components';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface DealCalculationsProps extends dealModel {
  showVersion?: boolean;
}

export const DealCalculations = (props: DealCalculationsProps) => {
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [calcs, setCalcs] = useState<laytimeCalculationModel[]>([]);
  const { t } = useTranslation();

  const [getCalcs, { loading }] = useLazyQuery<GetDealLaytimeCalculations>(GET_DEAL_LAYTIME_CALCULATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      dealId: props.id
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      setCalcs(() => [...data.laytimeCalculations]);
    }
  });

  useEffect(() => {
    getCalcs();
  }, []);

  let columns: GridColDef[] = [
    {
      field: 'counterParty',
      flex: 2,
      headerName: capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTY)),
      sortable: false,
      valueGetter: (params: GridValueGetterParams<laytimeCalculationModel, any, GridTreeNodeWithRender>) => {
        return params.row.counterParty?.name;
      }
    },
    {
      field: 'port',
      flex: 2,
      headerName: capitalizeFirstLetterEveryword(t(TXT_PORT)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
        const portType = !_.isEmpty(params.row.dealPort?.loadingDeals) ? TXT_LOADING : TXT_UNLOADING;
        return (
          <Stack direction="column">
            <Typography>
              {`${capitalizeFirstLetterEveryword(t(portType))} — ${params.row.dealPort?.port?.name}`}
            </Typography>
            <FormHelperText>
              {!!params.row.counterPartyType?.name ? getCounterPartyLabel(params.row.counterPartyType.name) : null}
            </FormHelperText>
          </Stack>
        );
      }
    },
    {
      field: 'calculationResult',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_CALCULATION_RESULTS)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
        const isDemurrage = params.row.laytimeCalculationResult === 'DEMURRAGE';
        const amount = (isDemurrage ? params.row.demurrageAmount : params.row.despatchAmount) || 0;
        const amountType = isDemurrage ? TXT_DEMURRAGE : TXT_DESPATCH;
        return (
          <Stack direction="column">
            <Typography>{amount.toLocaleString()}</Typography>
            <FormHelperText>{capitalizeFirstLetterEveryword(t(amountType))}</FormHelperText>
          </Stack>
        );
      }
    },
    {
      field: 'currency',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_CURRENCY)),
      headerAlign: 'center',
      sortable: false,
      align: 'center'
    },
    {
      field: 'status',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_STATUS)),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<laytimeCalculationModel, any, GridTreeNodeWithRender>) => {
        return params.row.status ? getStatusName(params.row.status, t) : '';
      }
    },
    {
      field: 'statusComment',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_STATUS_COMMENT)),
      sortable: false
    }
  ];

  if (!!props.showVersion) {
    columns = [
      ...columns,
      {
        field: 'version',
        flex: 1,
        headerName: capitalizeFirstLetterEveryword(t(TXT_VERSION)),
        headerAlign: 'center',
        align: 'center',
        sortable: false
      }
    ];
  }

  if (props.parentLaytimeCalculationId) {
    columns = [
      ...columns,
      {
        field: 'promote',
        flex: 1,
        headerName: capitalizeFirstLetterEveryword(t(TXT_PROMOTE)),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
          if (!params.row.id || !props.parentLaytimeCalculationId) {
            return null;
          }
          return (
            <PromoteCalculation
              sourceCalculationId={params.row.id}
              targetCalculationId={props.parentLaytimeCalculationId}
              icon={<KeyboardDoubleArrowUpIcon fontSize="inherit" />}
              label={TXT_PROMOTE}
              afterSave={getCalcs}
              txtConfirmation={TXT_PROMOTE_CALCULATION_CONFIRMATION}
            />
          );
        }
      },
      {
        field: 'reset',
        flex: 1,
        headerName: capitalizeFirstLetterEveryword(t(TXT_RESET)),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
          if (!params.row.id || !props.parentLaytimeCalculationId) {
            return null;
          }
          return (
            <PromoteCalculation
              sourceCalculationId={props.parentLaytimeCalculationId}
              targetCalculationId={params.row.id}
              icon={<RestartAltIcon fontSize="inherit" />}
              label={TXT_RESET}
              afterSave={getCalcs}
              txtConfirmation={TXT_RESET_CALCULATION_CONFIRMATION}
            />
          );
        }
      }
    ];
  } else {
    columns = [
      ...columns,
      {
        field: 'copy',
        flex: 1,
        headerName: capitalizeFirstLetterEveryword(t(TXT_COPY)),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
          if (!params.row.id) {
            return null;
          }
          return <CopyCalculation calculationId={params.row.id} />;
        }
      },
      {
        field: 'paste',
        flex: 1,
        headerName: capitalizeFirstLetterEveryword(t(TXT_PASTE)),
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
          if (!params.row.id) {
            return null;
          }
          return <PasteCalculation calculationId={params.row.id} afterSave={getCalcs} />;
        }
      }
    ];
  }

  columns = [
    ...columns,
    {
      field: 'delete',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_DELETE)),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<laytimeCalculationModel, any, any, GridTreeNodeWithRender>) => {
        return <DeleteCalculation calculation={params.row} afterSave={getCalcs} />;
      }
    }
  ];

  return (
    <DataGrid
      columns={columns}
      rows={calcs}
      loading={loading}
      slots={{
        noRowsOverlay: GridNoResultsOverlay
      }}
      slotProps={{
        pagination: {
          hidden: true
        }
      }}
      sx={{
        height: '300px'
      }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
    />
  );
};
