/* eslint-disable max-len */
export const SpainFlagIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" fill="none" />
      <path d="M0 6H36V30H0V6Z" fill="#C60B1E" />
      <path d="M0 12H36V24H0V12Z" fill="#FFC400" />
    </svg>
  );
};
