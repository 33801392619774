import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { businessUnitModel } from 'model';
import { GetBusinessUnitsOutput } from '../../api';
import { CustomTable } from '../../components';
import { businessUnitsColumns } from './businesUnitsColumns';
import { BusinessUnitsTableBody } from './BusinessUnitsTableBody';
import { BusinessUnitsTableHead } from './BusinessUnitsTableHead';

interface BusinessUnitsTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetBusinessUnitsOutput;
  onEdit: (deal: businessUnitModel) => void;
  onDelete: (deal: businessUnitModel) => void;
}

export const BusinessUnitsTable = ({ error, loading, data, onEdit, onDelete, ...rest }: BusinessUnitsTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <BusinessUnitsTableHead columns={businessUnitsColumns} />
        <BusinessUnitsTableBody
          columns={businessUnitsColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </CustomTable>
    </TableContainer>
  );
};
