import { InputAdornment } from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import moment, { Moment } from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
// import { updateTimePart } from 'shared/utils';
// import TimePicker from 'react-time-picker';

export interface TimePickerWithDropProps extends TimePickerProps<Moment> {
  value?: Moment;
  onChanged?: (item?: cognitiveReadOperationResult, value?: string) => void;
  disabled?: boolean;
  showTimeInput?: boolean;
  style?: CSSProperties;
}

export const TimePickerWithDrop = (props: TimePickerWithDropProps) => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  const { value, onChanged } = props;
  const [dateValue, setDateValue] = useState(value);

  // eslint-disable-next-line no-unused-vars
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'CHIP',
    drop: (value: { item: cognitiveReadOperationResult }) => {
      if (value.item.type === 'System.TimeOnly') {
        onChanged && onChanged(value.item, value.item.value);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop() && monitor.getItem().item.type == 'System.TimeOnly'
    })
  }));

  useEffect(() => {
    setDateValue(() => moment(value));
  }, [value]);


  return (
    <TimePicker
      inputRef={drop}
      className="time-picker"
      value={dateValue ?? null}
      // disableClock
      format="HH:mm"
      // clearIcon={null}
      // hourPlaceholder="00"
      // minutePlaceholder="00"
      onChange={(value) => {
        if (value) {
          // onChanged(value);
        } else {
          onChanged && onChanged(undefined);
        }
      }}
      slotProps={{
        textField: {
          fullWidth: true,
          style: { backgroundColor: isOver && canDrop ? '#c8e6c9' : 'white', ...props.style },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
              </InputAdornment>
            )
          }
        }
      }}
    />
  );
};
