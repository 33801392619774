/* eslint-disable quote-props */
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Paper, Stack, styled } from '@mui/material';
import useDealPortFormApi from 'api/dealPortForm/useDealPortFormApi';
import useShareableLink from 'api/shareableLink/useShareableLink';
import axios from 'axios';
import dealPortCommentDto from 'model/dealPortCommentDto';
import { createContext, ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import DealPortCommentBox from './DealPortFormCommentBox';
import { DealPortFormCommentCard } from './DealPortFormCommentCard';
import { useTranslation } from 'react-i18next';
import { TXT_CLOSE } from '../../../../../shared/translations';

interface DealPortFormCommentsProps {
  linkId: string;
  onClose: () => void;
  title?: ReactNode;
}

interface DealPortFormCommentsContextProps {
  dealPortId?: string;
  comments: dealPortCommentDto[];
  loading?: boolean;
  linkId?: string;
  afterSend: (dto: dealPortCommentDto) => void;
}

export const DealPortFormCommentsContext = createContext<DealPortFormCommentsContextProps>({
  comments: [],
  afterSend: () => {}
});

const CommentsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 1),
  overflow: 'auto',
  width: '100%',
  height: '100%',
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
    height: '0.4em'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.getContrastText(theme.palette.background.paper)
  },
  '& > div': {
    marginBottom: '10px'
  },
  backgroundColor: theme.palette.divider
}));

const DealPortFormComments = (props: DealPortFormCommentsProps) => {
  const { t } = useTranslation();
  const [dealPortId, setDealPortId] = useState<string>();
  const [comments, setComments] = useState<dealPortCommentDto[]>([]);
  const [loading, setLoading] = useState(false);

  const { getDealPort } = useShareableLink();
  const { getComments } = useDealPortFormApi();

  const fetchDealPortFromApi = async () => {
    if (!props.linkId) {
      setDealPortId(undefined);
      return;
    }

    try {
      const response = await getDealPort(props.linkId);
      setDealPortId(response.data.dealPortId);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.message);
      } else {
        console.error(e);
      }
    }
  };

  const fetchCommentsFromApi = async () => {
    if (!props.linkId) {
      setComments([]);
      return;
    }

    const data = await getComments(props.linkId);
    setComments(data);
  };

  const sortComment = (a: dealPortCommentDto, b: dealPortCommentDto) => {
    const x = moment(a.createdDate);
    const y = moment(b.createdDate);

    if (x.isBefore(y)) {
      return 1;
    }

    return -1;
  };

  const sortReplies = (a: dealPortCommentDto, b: dealPortCommentDto) => {
    const x = moment(a.createdDate);
    const y = moment(b.createdDate);

    if (x.isBefore(y)) {
      return -1;
    }

    return 1;
  };

  const afterSend = (dto: dealPortCommentDto) => {
    setComments((prev) => [...prev, dto]);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchDealPortFromApi(), fetchCommentsFromApi()]).finally(() => setLoading(false));
  }, [props.linkId]);

  return (
    <DealPortFormCommentsContext.Provider
      value={{
        dealPortId,
        comments,
        loading,
        linkId: props.linkId,
        afterSend
      }}
    >
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth={true}
        disableEscapeKeyDown={true}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            props.onClose();
          }
        }}
      >
        <DialogContent>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <DealPortCommentBox />
            <Divider />
            <Paper
              sx={{
                width: '100%',
                height: '77vh',
                overflow: 'auto',
                boxShadow: 'none'
              }}
            >
              <CommentsContainer id="comments-container">
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <CircularProgress size={50} />
                  </div>
                ) : (
                  <Stack direction="column" spacing={1}>
                    {comments
                      .filter((x) => !x.parentCommentId)
                      .sort(sortComment)
                      .map((comment) => (
                        <DealPortFormCommentCard
                          key={comment.id}
                          comment={comment}
                          replies={comments.filter((x) => x.parentCommentId === comment.id).sort(sortReplies)}
                        />
                      ))}
                  </Stack>
                )}
              </CommentsContainer>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CLOSE)}
          </Button>
        </DialogActions>
      </Dialog>
    </DealPortFormCommentsContext.Provider>
  );
};

export default DealPortFormComments;
