import { useLazyQuery } from '@apollo/client';
import { GET_USERS_SELECTOR, GetUsersOutput } from 'api';
import { useApolloErrorHandler } from 'hooks';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useUserMentionStyle, userMentionClassName } from './userMentionStyle';
import { FormControl, MenuItem } from '@mui/material';

interface UserMentionProps {
  value?: string;
  onChange?: (message: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const UserMention = (props: UserMentionProps) => {
  const { apolloErrorHandler } = useApolloErrorHandler();
  const style = useUserMentionStyle();

  const [getUsers, { loading }] = useLazyQuery<GetUsersOutput>(GET_USERS_SELECTOR, {
    onError: apolloErrorHandler
  });

  const onSearch = async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
    getUsers({
      variables: {
        take: 5,
        where: {
          or: [
            {
              username: {
                contains: query
              }
            },
            {
              profile: {
                fullName: {
                  contains: query
                }
              }
            }
          ]
        }
      },
      onCompleted: (response) => {
        callback(
          response.users.items.map((user) => ({
            id: user.id!,
            display: user.profile?.fullName
          }))
        );
      }
    });
  };

  const renderSuggestion = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    return (
      <MenuItem key={suggestion.id}>
        {highlightedDisplay}
      </MenuItem>
    );
  };

  return (
    <FormControl fullWidth className={style.root}>
      <MentionsInput
        value={props.value || ''}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        className={userMentionClassName}
        placeholder={props.placeholder}
        disabled={props.disabled}
      >
        <Mention trigger="@" isLoading={loading} data={onSearch} renderSuggestion={renderSuggestion} appendSpaceOnAdd />
      </MentionsInput>
    </FormControl>
  );
};
