import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { interruptionColumns, InterruptionTableBody, InterruptionTableHead } from '.';
import { CustomTable } from 'components';
import { interruptionModel } from 'model';

interface InterruptionTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  interruptions?: interruptionModel[];
}

// eslint-disable-next-line max-len
export const InterruptionTable = ({
  error,
  loading,
  interruptions,
  ...rest
}: InterruptionTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <InterruptionTableHead columns={interruptionColumns} />
        <InterruptionTableBody
          columns={interruptionColumns}
          loading={loading}
          error={error}
          interruptions={interruptions}
        />
      </CustomTable>
    </TableContainer>
  );
};
