import { gql } from '@apollo/client';
import { contactEmailModel } from 'model';

export interface AddContactEmailInput extends Omit<contactEmailModel, 'id'> { }

export interface UpdateContactEmailInput extends AddContactEmailInput {
  strId: string;
}

export interface DeleteContactEmailInput {
  id: string;
}

export interface UpdateContactEmailsInput {
  contactId: string;
  updateInputs: UpdateContactEmailInput[];
}

export interface UpdateContactEmailsOutput {
  updateContactEmails: contactEmailModel[];
}

export const UPDATE_CONTACT_EMAILS = gql`
  mutation updateContactEmails($input: UpdateContactEmailsInput!) {
    updateContactEmails(input: $input) {
      id
      name
      email
      contactId
    }
  }
`;
