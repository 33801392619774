import axios from 'axios';
import { apiBaseUrl } from 'shared/links';
import { useAuthService } from './useAuthServices';

export const useAxios = () => {
  const { isSignedIn } = useAuthService();
  const create = async () => {
    const signedIn = await isSignedIn();
    if (!signedIn.isSignedIn || !signedIn.token) {
      throw Error();
    }
    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        Authorization: `Bearer ${signedIn.token}`
      }
    });
  };

  return {
    create
  };
};
