import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ADD_VESSEL, AddVesselInput, UPDATE_VESSEL, VesselMutationResult } from '../../../api';
import { useApolloErrorHandler } from '../../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_VESSEL,
  TXT_IMO,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_VESSEL
} from '../../../../../../shared/translations';
import { vesselModel } from 'model';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VesselTypeSelector } from 'components/VesselTypeSelector/VesselTypeSelector';

export interface VesselEditorProps extends vesselModel {
  onClose: () => void;
  afterSave: (vessel: vesselModel) => void;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required()
    .test({
      test: (str) => (str?.trim()?.length || 0) > 0
    }),
  imo: yup.string(),
  vesselType: yup.string().required()
});

export const VesselEditor = (props: VesselEditorProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue } = useForm<AddVesselInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      imo: props.imo,
      vesselType: props.vesselType ?? 'VESSEL'
    }
  });

  const { apolloErrorHandler } = useApolloErrorHandler();
  const [mutateVessel, { loading }] = useMutation<VesselMutationResult>(props.id ? UPDATE_VESSEL : ADD_VESSEL, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data.vessel);
      props.onClose();
    }
  });

  const onSubmit: SubmitHandler<AddVesselInput> = (data) => {
    if (!props.id) {
      return mutateVessel({
        variables: {
          input: { ...data }
        }
      });
    }

    return mutateVessel({
      variables: {
        input: {
          id: props.id,
          ...data
        }
      }
    });
  };

  const onVesselTypeChanged = (e: any) => {
    setValue('vesselType', e.target.value);
  };

  const title = t(props.id ? TXT_UPDATE_VESSEL : TXT_CREATE_VESSEL);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form
          id="frm-vessel-editor"
          name="frm-vessel-editor"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
            e.stopPropagation();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                {...register('name')}
                disabled={loading}
                error={!!formState.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label={capitalizeFirstLetterEveryword(t(TXT_IMO))}
                fullWidth
                {...register('imo')}
                disabled={loading}
                error={!!formState.errors.imo}
              />
            </Grid>
            <Grid item xs={12}>
              <VesselTypeSelector
                disabled={loading}
                defaultValue={props.vesselType}
                onChange={onVesselTypeChanged}
              ></VesselTypeSelector>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="frm-vessel-editor" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
