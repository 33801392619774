import { useNavigate } from 'react-router-dom';
import { dealPath } from 'shared/links';
import useAuth from './useAuth';

export const useGuestGuard = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();

  if (auth.isGuest) {
    navigate(dealPath);
  }
};
