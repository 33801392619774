import { useRecoilState } from 'recoil';
import { laytimeCalculationState } from 'state';

export const useDemurrageService = () => {
  const [state, setState] = useRecoilState(laytimeCalculationState);
  const resetcalculationReadyToBePastedId = () => {
    setState(() => ({
      ...state,
      calculationReadyToBePastedId: undefined
    }));
  };

  const copyCalculation = (calculationId: string) => {
    setState(() => ({
      ...state,
      calculationReadyToBePastedId: calculationId
    }));
  };

  return {
    ...state,
    copyCalculation,
    resetcalculationReadyToBePastedId
  };
};
