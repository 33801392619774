import { Card, Typography, useTheme, CircularProgress, IconButton, Link, Stack, CardActionArea } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_CLICK_HERE_TO_OPEN, TXT_LAYTIME_CHART, TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD } from '../../../../../../shared/translations';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GetShareableLinksResponse, GET_SHAREABLE_LINKS } from 'api';
import LinkIcon from '@mui/icons-material/Link';
import { useApolloErrorHandler } from 'hooks';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { LaytimeChartShareableLinkEditor } from './LaytimeChartShareableLinkEditor';
import { shareableLinkModel } from 'model';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { LaytimechartReportFilter } from 'components';

export const LaytimeChart = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [auth] = useAuth();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [shareableLink, setShareableLink] = useState<shareableLinkModel | undefined>(undefined);
  const [openEditor, setOpenEditor] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [getShareableLink, { loading: isloadingLink }] = useLazyQuery<GetShareableLinksResponse>(GET_SHAREABLE_LINKS, {
    fetchPolicy: 'network-only',
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      if (!data || !data.shareableLinks || _.isEmpty(data.shareableLinks)) {
        setShareableLink(undefined);
        return;
      }
      setShareableLink({ ...data.shareableLinks[0] });
    },
    variables: {
      where: {
        shareableLinkType: {
          eq: 'LAYTIME_CHART_REPORT'
        }
      }
    }
  });

  const copyLinkToClipboard = async () => {
    let copiedLink = shareableLink?.linkId;
    if (!copiedLink) {
      return;
    }

    const currentURL = new URL(window.location.href);
    copiedLink = `${currentURL.origin}/public/laytimechart/${copiedLink}`;
    navigator.clipboard.writeText(copiedLink);
    enqueueSnackbar(
      <Typography variant="subtitle1">
        {_.capitalize(t(TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD))}.{' '}
        <Trans i18nKey={TXT_CLICK_HERE_TO_OPEN} t={t}>
          <Link href={copiedLink} color="inherit" target="_blank" rel="noreferrer">
            here
          </Link>
        </Trans>
        .
      </Typography>,
      {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 2000
      }
    );
  };

  const showEditor = () => setOpenEditor(true);
  const closeEditor = () => setOpenEditor(false);

  const showFilter = () => setOpenFilter(true);
  const closeFilter = () => setOpenFilter(false);

  useEffect(() => {
    if (auth.isAdmin) {
      getShareableLink();
    }
  }, []);

  return (
    <>
      {openEditor && (
        <LaytimeChartShareableLinkEditor afterSave={getShareableLink} onClose={closeEditor} {...shareableLink} />
      )}
      {openFilter && <LaytimechartReportFilter onCancel={closeFilter} />}
      <Card>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <CardActionArea
            onClick={showFilter}
            sx={{
              padding: theme.spacing(2)
            }}
          >
            <Stack direction="row" spacing={2}>
              <div>
                <TimelineIcon fontSize="large" />
              </div>
              <Typography variant="h5" align="center">
                {capitalizeFirstLetterEveryword(t(TXT_LAYTIME_CHART))}
              </Typography>
            </Stack>
          </CardActionArea>
          {auth.isAdmin && (
            <div>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{
                  padding: theme.spacing(2)
                }}
              >
                {isloadingLink && <CircularProgress size="20px" />}
                {!isloadingLink && !shareableLink && (
                  <div>
                    <IconButton size="small" onClick={showEditor}>
                      <LinkIcon />
                    </IconButton>
                  </div>
                )}
                {!isloadingLink && shareableLink && (
                  <>
                    <div>
                      <IconButton size="small" onClick={copyLinkToClipboard}>
                        <ContentCopyIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton size="small" onClick={showEditor}>
                        <EditIcon />
                      </IconButton>
                    </div>
                  </>
                )}
              </Stack>
            </div>
          )}
        </Stack>
      </Card>
    </>
  );
};
