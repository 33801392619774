import { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GetCounterPartyTypesOutput, GET_COUNTER_PARTY_TYPES } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_CLIENT, TXT_COUNTERPARTY_TYPES, TXT_RECEIVER } from '../../../../../shared/translations';
import { CounterPartyType, CounterPartyTypes, counterPartyTypeModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { Autocomplete, Checkbox, Chip, CircularProgress, TextField } from '@mui/material';
import _ from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface CounterPartyTypeSelectorProps {
  label?: string;
  counterPartyTypes?: counterPartyTypeModel[];
  error?: boolean;
  multiple?: boolean;
  defaultCounterPartyTypes?: CounterPartyType[];
  onSelectedChange: (counterPartyTypes: counterPartyTypeModel[] | null) => void;
}

const VISIBLE_OPTIONS_COUNT = 5;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CounterPartyTypeSelector = (props: CounterPartyTypeSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<counterPartyTypeModel[] | undefined>(props.counterPartyTypes);
  const [options, setOptions] = useState<counterPartyTypeModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [getcounterParties, { loading }] = useLazyQuery<GetCounterPartyTypesOutput>(
    GET_COUNTER_PARTY_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: {
        take: VISIBLE_OPTIONS_COUNT,
        skip: 0
      },
      onError: apolloErrorHandler,
      onCompleted: (data) => {
        if ((data?.counterPartyTypes?.items?.length || 0) > 0) {
          let counterPartyTypeOptions: counterPartyTypeModel[] = data?.counterPartyTypes?.items || [];
          const totalCount = data?.counterPartyTypes?.totalCount || 0;
          if (totalCount > VISIBLE_OPTIONS_COUNT) {
            counterPartyTypeOptions = [
              ...counterPartyTypeOptions,
              {
                id: 'counterPartyType-hidden-options-remaining',
                name: capitalizeFirstLetterEveryword(t(TXT_AND_MORE, {
                  count: totalCount - VISIBLE_OPTIONS_COUNT
                }))
              }
            ];
          }
          if (!_.isNil(selected)) {
            counterPartyTypeOptions = [...selected, ...counterPartyTypeOptions];
          }
          setOptions(_.uniqBy(counterPartyTypeOptions, 'id'));
        }

        if (!!props.defaultCounterPartyTypes && _.isEmpty(props.counterPartyTypes)) {
          const defaultCounterPartyTypesIds = props.defaultCounterPartyTypes.map((x) => CounterPartyTypes[x]);
          setSelected(() => (data.counterPartyTypes?.items || []).filter((x) => defaultCounterPartyTypesIds.some((y) => y === x.id)));
        }
      }
    }
  );

  const getLabel = (name: string) => {
    return name.toLowerCase() === TXT_CLIENT ? TXT_RECEIVER : name;
  };

  const onSelectedChange = (event: object, value: counterPartyTypeModel[] | null) => {
    setSelected(value === null ? undefined : [...value]);
    props.onSelectedChange(value);
  };

  useEffect(() => {
    getcounterParties();
  }, []);

  return (
    <Autocomplete
      id="counterParty-selector"
      multiple
      onChange={onSelectedChange}
      value={selected || []}
      getOptionDisabled={(option) => option.id === 'counterPartyType-hidden-options-remaining'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => t(option.name!).toUpperCase() || ''}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      fullWidth
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={t(getLabel(option.name!)).toUpperCase()} {...getTagProps({ index })} key={option.id} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={capitalizeFirstLetterEveryword(props.label || t(TXT_COUNTERPARTY_TYPES))}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {/* eslint-disable-next-line max-len */}
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
            readOnly: true
          }}
          error={props.error}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {t(getLabel(option.name!)).toUpperCase()}
        </li>
      )}
      clearOnBlur
    />
  );
};
