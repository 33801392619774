import { useLazyQuery } from '@apollo/client';
import { CircularProgress, Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_BARGE_TRIP, GetBargeTripsOutput, GET_BARGE_TRIPS } from 'api';
import {
  CustomCardContainer,
  CustomError,
  DateRange,
  DateRangePicker,
  DeleteModal,
  PaginationBar,
  UserSelector
} from 'components';
import _ from 'lodash';
import {
  TXT_DELETE_TEXT,
  TXT_BARGE_TRIPS,
  TXT_NO_DATA_FOUND,
  TXT_LOADING,
  TXT_TRAFFIC_ANALYST,
  TXT_SEARCH
} from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useBargeTripStyle } from './bargeTripStyle';
import { applicationUserModel, bargeTripModel } from 'model';
import { PageSizeOptions } from 'shared/constants';
import { useDefaultSettings, useTitle } from 'hooks';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import moment from 'moment';
import { ignoreTimeZone } from 'shared/utils';
import { BargeTripTable } from './BargeTripTable';
import { BargeTripEditor } from './BargeTripEditor';
import useAuth from 'hooks/useAuth';

export const BargeTrips = () => {
  useTitle(TXT_BARGE_TRIPS);
  const bargeTripsStyle = useBargeTripStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedBargeTrips, setSelectedBargeTrips] = useState<bargeTripModel | undefined>(undefined);
  const [trafficAnalyst, setTrafficAnalyst] = useState<applicationUserModel | undefined>(
    defaultSettings.trafficSpecialist
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    start: moment().startOf('year').toDate(),
    end: moment().endOf('year').toDate()
  });
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    let result: any[] = [];

    if (!!trafficAnalyst) {
      result = [
        ...result,
        {
          trafficAnalyst: {
            id: {
              eq: trafficAnalyst.id
            }
          }
        }
      ];
    }

    let dateFilter: any[] = [];
    if (dateRange.start) {
      dateFilter = [
        {
          loadingPort: {
            arrivalTime: {
              gte: ignoreTimeZone(dateRange.start)
            }
          }
        }
      ];
    }

    if (dateRange.end) {
      dateFilter = [
        ...dateFilter,
        {
          loadingPort: {
            arrivalTime: {
              lte: ignoreTimeZone(dateRange.end)
            }
          }
        }
      ];
    }

    if (dateFilter.length === 1) {
      result = [...result, dateFilter[0]];
    } else if (dateFilter.length > 1) {
      result = [...result, { and: dateFilter }];
    }

    if (!_.isNil(nameFilter) && nameFilter.length > 0) {
      result = [
        ...result,
        {
          or: [
            {
              barge: {
                name: {
                  contains: nameFilter
                }
              }
            },
            {
              splitQuantityName: {
                contains: nameFilter
              }
            }
          ]
        }
      ];
    }

    if (result.length === 0) {
      return null;
    }

    if (result.length === 1) {
      return result[0];
    }

    return {
      and: result
    };
  };

  const [getBargeTrips, { loading, data, error, refetch }] = useLazyQuery<GetBargeTripsOutput>(GET_BARGE_TRIPS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: pageSize,
      skip: pageSize * (pageNumber - 1)
    },
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (!refetch) {
      getBargeTrips();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (bargeTrip: bargeTripModel) => {
    setSelectedBargeTrips(bargeTrip);
    setOpenEditor(true);
  };

  const onDelete = (bargeTrip: bargeTripModel) => {
    setSelectedBargeTrips(bargeTrip);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedBargeTrips(undefined);
  };

  const openAddEditor = () => {
    setSelectedBargeTrips(undefined);
    setOpenEditor(true);
  };

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: `${selectedBargeTrips?.barge?.name} — ${selectedBargeTrips?.splitQuantityName}`
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  useEffect(() => {
    setNameFilter(undefined);
  }, [t]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      trafficSpecialist: trafficAnalyst
    });
  }, [trafficAnalyst]);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <BargeTripEditor {...selectedBargeTrips} onClose={onEditorClosed} afterSave={fetch} />}
      {openDeleteConfirmation && selectedBargeTrips && (
        <DeleteModal
          afterSaving={fetch}
          onCancel={closeDeleteConfirmation}
          txtConfirmation={txtConfirmation}
          id={selectedBargeTrips?.id!}
          mutation={DELETE_BARGE_TRIP}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={bargeTripsStyle.content}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <UserSelector
                  user={trafficAnalyst}
                  onChanged={setTrafficAnalyst}
                  label={capitalizeFirstLetterEveryword(t(TXT_TRAFFIC_ANALYST))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DateRangePicker value={dateRange} onChanged={setDateRange} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="bargeTrips-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter || ''}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CustomCardContainer>
                  {loading ? (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                      <h3>{t(TXT_LOADING).toUpperCase()}</h3>
                    </div>
                  ) : !!error ? (
                    <CustomError>{error.message}</CustomError>
                  ) : (data?.bargeTrips?.totalCount || 0) >= 0 ? (
                    <BargeTripTable
                      bargeTrips={data?.bargeTrips?.items || []}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      showActions={!isGuest}
                    />
                  ) : (
                    <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
                  )}
                </CustomCardContainer>
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data?.bargeTrips?.totalCount || 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={!isGuest ? openAddEditor : undefined}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
