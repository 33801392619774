import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_PORT_NOT_FOUND, TXT_CANCEL, TXT_YES } from '../../../../../../shared/translations';

interface PortConfirmationDialogProps {
  handleCloseDialog: (confirmation: boolean) => void;
}

export const PortConfirmationDialog = (props: PortConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={() => props.handleCloseDialog(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_PORT_NOT_FOUND))}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The Port does not exist. Do you want to create new port?.
        </DialogContentText>
      </DialogContent>x
      <DialogActions>
        <Button onClick={() => props.handleCloseDialog(false)}>{capitalizeFirstLetterEveryword(t(TXT_CANCEL))}</Button>
        <Button onClick={() => props.handleCloseDialog(true)} autoFocus>
          {capitalizeFirstLetterEveryword(t(TXT_YES))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
