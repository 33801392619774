import { AddDealInput } from 'api';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { QuoteWizardSplitQuantity } from './QuoteWizardSplitQuantity';


export const QuoteWizardSplitQuantities = () => {
  const { control } = useFormContext<AddDealInput>();
  const { fields, remove } = useFieldArray({
    control,
    name: 'splitQuantities'
  });


  return (
    <div>
      {fields.map((field, index) => {
        return (
          <QuoteWizardSplitQuantity key={`splitQuantities-${field.id}`} handleRemove={remove} index={index} />
        );
      })}
    </div>
  );
};
