import { gql } from '@apollo/client';
import { statementOfFactStatus, portModel, statementOfFactModel } from 'model';

export const getStatementOfFactFragment = `
    id
    vesselName
    arrivalDate
    norTenderedDate
    quantity
    destination
    port {
      id
      name
    }
    ocrContent
    interruptions {
      id
      statementOfFactId
      description
      since
      to
      percentage
      toDiscount
    }
    status
    laytimeCalculationId
    trafficSpecialistId
    trafficSpecialist {
      id
      profile{
        id
        initial
        fullName
      }
    }
    assignToId
    assignTo {
      id
      profile{
        id
        initial
        fullName
      }
    }
`;

export const getStatementOfFactsFragment = `
  items {
    ${getStatementOfFactFragment}
  }
  totalCount
`;

export type StatementOfFactQueryResult = {
  id: string;
  vesselName?: string;
  arrivalDate?: Date;
  norTenderedDate?: Date;
  quantity?: number;
  destination?: string;
  port?: portModel;
  status?: statementOfFactStatus;
};

export type GetStatementOfFactsOutput = {
  statementOfFacts?: {
    items?: [StatementOfFactQueryResult];
    totalCount?: number;
  };
};

export type GetStatementOfFactOutput = {
  statementOfFact?: statementOfFactModel;
};

export const GET_STATEMENT_OF_FACTS = gql`
  query getStatementOfFacts($take: Int!, $skip: Int!, $where: StatementOfFactFilterInput) {
    statementOfFacts(take: $take, skip: $skip, where: $where) {
      ${getStatementOfFactsFragment}
    }
  }
`;

export const GET_STATEMENT_OF_FACT = gql`
  query getStatementOfFact($statementOfFactId: UUID!) {
    statementOfFact(statementOfFactId: $statementOfFactId) {
      ${getStatementOfFactFragment}
    }
  }
`;
