import { AddDealInput } from 'api';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { QuoteWizardUnlodingPort } from './QuoteWizardUnlodingPort';

export const QuoteWizardUnlodingPorts = () => {
  const { control } = useFormContext<AddDealInput>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'unloadingPorts'
  });
  const [tempId, setTempId] = useState(-1);

  const handleAdd = () => {
    append({
      id: `U${tempId}`
    });
    setTempId(tempId - 1);
  };

  useEffect(() => {
    if (fields.length == 0) {
      handleAdd();
    }
    document.getElementById('frm-deal-wizard')?.scrollIntoView(false);
  }, [tempId]);

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <QuoteWizardUnlodingPort key={`unloadingPorts-${field.id}`} handleRemove={remove} index={index} />
        );
      })}
    </div>
  );
};
