import { Button, Grid } from '@mui/material';
import { AddDealInput } from 'api';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_ADD } from '../../../../../shared/translations';
import AddIcon from '@mui/icons-material/Add';
import { DealWizardUnlodingPort } from './DealWizardUnlodingPort';
import { DealWizardContext } from './DealWizard';

export const DealWizardUnlodingPorts = () => {
  const { control } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'unloadingPorts'
  });
  const [tempId, setTempId] = useState(-1);

  const handleAdd = () => {
    append({
      id: `U${tempId}`
    });
    setTempId(tempId - 1);
  };

  useEffect(() => {
    document.getElementById('frm-deal-wizard')?.scrollIntoView(false);
  }, [tempId]);

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} key={`unloadingPorts-${field.id}`}>
            <DealWizardUnlodingPort handleRemove={remove} index={index} />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAdd} disabled={isDisabled}>
          {t(TXT_ADD)}
        </Button>
      </Grid>
    </Grid>
  );
};
