import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { GET_USER_PROFILE, GetUsersOutput, UpdateUserInput } from 'api';
import { useApolloErrorHandler } from 'hooks';
import useAuth from 'hooks/useAuth';
import { microsoftTokenClaims, useMicrosoftLogin } from 'hooks/useMicrosoftLogin';
import { SecurityRoles, SecurityRolesValues } from 'model';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getSecurityRolesText } from 'shared/utils';
import * as yup from 'yup';
import {
  TXT_CHANGE_PASSWORD,
  TXT_EMAIL,
  TXT_ENABLE,
  TXT_FULL_NAME,
  TXT_INITIAL,
  TXT_LINKED_ACCOUNTS,
  TXT_MICROSOFT_ACCOUNT,
  TXT_OCR,
  TXT_PASSWORD,
  TXT_REFERENCE,
  TXT_RETYPE_PASSWORD,
  TXT_ROLE,
  TXT_VESSEL_PORTAL
} from '../../../../../../shared/translations';

interface UserProfileContentProps {
  id: string;
  disabled?: boolean;
  disableSecurityRoles?: boolean;
  disableIsEnabled?: boolean;
  disableAllowVesselPortal?: boolean;
  disableAllowOCR?: boolean;
  disableMicrosoft?: boolean;
  isLoadingUserChanged?: (isLoading: boolean) => void;
}

export const userProfileFormSchema = yup.object().shape({
  id: yup.string().required().default(''),
  username: yup.string().required().default(''),
  fullName: yup.string().required().default(''),
  initial: yup.string().required().default(''),
  allowVesselPortal: yup.bool().required(),
  allowOCR: yup.bool().required(),
  securityRole: yup.string().required(),
  isEnabled: yup.bool().required(),
  hasMicrosoftAccountConnected: yup.bool().required(),
  newMicrosoftAccount: yup.string().nullable(),
  newPassword: yup.string().nullable(),
  reference: yup.string().nullable(),
  reNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null])
    .nullable()
});

export const UserProfileContent = (props: UserProfileContentProps) => {
  const { disabled } = props;
  const { control, setValue, formState, register } = useFormContext<UpdateUserInput>();
  const { t } = useTranslation();
  const watch = useWatch({ control });
  const microsoftLogin = useMicrosoftLogin();
  const [auth] = useAuth();
  const [openMicrosoftLogin, setOpenMicrosoftLogin] = useState(false);

  const { apolloErrorHandler } = useApolloErrorHandler();
  const { loading: loadUser } = useQuery<GetUsersOutput>(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.id
    },
    onCompleted: (usersOutput) => {
      if (!usersOutput || !usersOutput.users || (usersOutput.users.items || []).length <= 0) {
        return;
      }
      const user = usersOutput.users.items[0];
      setValue('username', user.username || '');
      setValue('fullName', user.profile?.fullName || '');
      setValue('initial', user.profile?.initial || '');
      setValue('allowVesselPortal', !!user.allowVesselPortal);
      setValue('allowOCR', !!user.allowOCR);
      setValue('securityRole', user.securityRole || 'USER');
      setValue('isEnabled', !!user.isEnabled);
      setValue('hasMicrosoftAccountConnected', !!user.hasMicrosoftAccountConnected);
      setValue('linkedMicrosoftAccount', user.profile?.linkedMicrosoftAccount);
      setValue('reference', user.profile?.reference);
    },
    onError: apolloErrorHandler
  });

  const onMicrosoftChanged = () => {
    const newChecked = !watch.hasMicrosoftAccountConnected;
    if (newChecked) {
      microsoftLogin({
        signinType: 'loginPopup',
        onReturnedAccountInfo: (authResult) => {
          if (!!!authResult || !!!authResult.account) {
            return;
          }
          const microsoftSub = (authResult.idTokenClaims as microsoftTokenClaims).sub;
          setValue('hasMicrosoftAccountConnected', !!microsoftSub);
          setValue('newMicrosoftAccount', microsoftSub);
          setValue('newPassword', undefined);
          setValue('reNewPassword', undefined);
          setValue('linkedMicrosoftAccount', authResult.account.username);
        },
        preSignIn: () => setOpenMicrosoftLogin(true),
        postSignIn: () => setOpenMicrosoftLogin(false)
      });
    } else {
      setValue('hasMicrosoftAccountConnected', false);
      setValue('newMicrosoftAccount', undefined);
    }
  };

  useEffect(() => {
    props.isLoadingUserChanged && props.isLoadingUserChanged(loadUser);
  }, [loadUser]);

  const onSelectedChange = (e: SelectChangeEvent<SecurityRoles>) => {
    const found = SecurityRolesValues.find((display) => display === e.target.value);
    if (!!found) {
      setValue('securityRole', found);
    }
  };

  return (
    <>
      {loadUser && <CircularProgress />}
      {!loadUser && (
        <Stack spacing={2} direction="column">
          <TextField
            fullWidth
            label={capitalizeFirstLetterEveryword(t(TXT_EMAIL))}
            {...register('username')}
            error={!!formState.errors.username}
            disabled={disabled || loadUser}
          />
          <TextField
            fullWidth
            label={capitalizeFirstLetterEveryword(t(TXT_FULL_NAME))}
            {...register('fullName')}
            error={!!formState.errors.fullName}
            disabled={disabled || loadUser}
          />
          <TextField
            fullWidth
            label={capitalizeFirstLetterEveryword(t(TXT_INITIAL))}
            {...register('initial')}
            error={!!formState.errors.initial}
            disabled={disabled}
          />
          <TextField
            fullWidth
            label={capitalizeFirstLetterEveryword(t(TXT_REFERENCE))}
            {...register('reference')}
            error={!!formState.errors.reference}
            disabled={disabled}
          />
          {!watch.hasMicrosoftAccountConnected && (
            <fieldset>
              <legend>{capitalizeFirstLetterEveryword(t(TXT_CHANGE_PASSWORD))}</legend>
              <Stack spacing={2} direction="column">
                <TextField
                  fullWidth
                  label={capitalizeFirstLetterEveryword(t(TXT_PASSWORD))}
                  {...register('newPassword')}
                  error={!!formState.errors.newPassword}
                  disabled={disabled || loadUser}
                  type="password"
                />
                <TextField
                  fullWidth
                  label={capitalizeFirstLetterEveryword(t(TXT_RETYPE_PASSWORD))}
                  {...register('reNewPassword')}
                  error={!!formState.errors.reNewPassword}
                  disabled={disabled || loadUser}
                  type="password"
                />
              </Stack>
            </fieldset>
          )}
          {!props.disableMicrosoft && (
            <fieldset
              style={{
                width: '100%'
              }}
            >
              <legend>{capitalizeFirstLetterEveryword(t(TXT_LINKED_ACCOUNTS))}</legend>
              <FormControlLabel
                control={
                  !openMicrosoftLogin ? (
                    <Switch checked={!!watch.hasMicrosoftAccountConnected} onClick={onMicrosoftChanged} />
                  ) : (
                    <CircularProgress size="20px" />
                  )
                }
                label={`${capitalizeFirstLetterEveryword(t(TXT_MICROSOFT_ACCOUNT))}${
                  watch.linkedMicrosoftAccount ? `: ${watch.linkedMicrosoftAccount}` : ''
                }`}
                labelPlacement="start"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: 0
                }}
              />
            </fieldset>
          )}
          <Stack direction="row" spacing={2}>
            {!props.disableAllowVesselPortal && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!watch.allowVesselPortal}
                    onChange={(event, checked) => setValue('allowVesselPortal', checked)}
                    disabled={disabled}
                  />
                }
                label={capitalizeFirstLetterEveryword(t(TXT_VESSEL_PORTAL))}
              />
            )}
            {!props.disableAllowOCR && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!watch.allowOCR}
                    onChange={(event, checked) => setValue('allowOCR', checked)}
                    disabled={disabled}
                  />
                }
                label={capitalizeFirstLetterEveryword(t(TXT_OCR))}
              />
            )}
            {!props.disableIsEnabled && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!watch.isEnabled}
                    onChange={(event, checked) => setValue('isEnabled', checked)}
                    disabled={props.id === auth.id || disabled}
                  />
                }
                label={capitalizeFirstLetterEveryword(t(TXT_ENABLE))}
              />
            )}
          </Stack>
          {!props.disableSecurityRoles && (
            <div>
              <InputLabel id="select-label">{capitalizeFirstLetterEveryword(t(TXT_ROLE))}</InputLabel>
              <Select
                fullWidth
                labelId="select-label"
                id={'id-' + props.id}
                {...register('securityRole')}
                value={watch.securityRole || 'USER'}
                label={TXT_ROLE}
                onChange={onSelectedChange}
                variant={!!props.disabled ? 'standard' : 'outlined'}
                disabled={props.id === auth.id || disabled || loadUser}
              >
                {SecurityRolesValues.map((display) => (
                  <MenuItem key={`${display}`} value={display} selected={display === watch.securityRole}>
                    {getSecurityRolesText(display)}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </Stack>
      )}
    </>
  );
};
