import { gql } from '@apollo/client';
import { businessUnitModel } from 'model';

export const getBusinessUnitsFragment = `
  items {
    id,
    name,
    initials
  },
  totalCount
`;

export type GetBusinessUnitsOutput = {
  businessUnits?: {
    items?: [businessUnitModel],
    totalCount?: number;
  }
};

export const GET_BUSINESS_UNITS = gql`
  query getBusinessUnits($take: Int!, $skip: Int!, $where: BusinessUnitFilterInput) {
    businessUnits(take: $take, skip: $skip, where: $where) {
      ${getBusinessUnitsFragment}
    }
  }
`;
