import { DatePicker } from '@mui/x-date-pickers';
import { Alert, Button, Stack, Typography, useTheme } from '@mui/material';
import { BargeReportRequest } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ADD,
  TXT_ADD_COUNTERPARTIES_MESSAGE,
  TXT_COUNTERPARTY,
  TXT_FROM,
  TXT_SELECTED_COUNTERPARTIES,
  TXT_UNTIL
} from '../../../../../shared/translations';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { CounterPartySelector } from 'components/CounterPartySelector';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDateFormat } from 'shared/utils';
import { counterPartyModel } from 'model';

interface BargeReportFilterContentProps {
  disabled?: boolean;
}

export const BargeReportFilterContent = (props: BargeReportFilterContentProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<BargeReportRequest>();
  const theme = useTheme();
  const dateFormat = getDateFormat();
  const [counterParty, setcounterParty] = useState<counterPartyModel | undefined>(undefined);
  const [selectedCounterParties, setSelectedCounterParties] = useState<counterPartyModel[]>([]);

  const addCounterParty = () => {
    if (!counterParty || !counterParty.id) {
      return;
    }

    if (selectedCounterParties.some((x)=>x.id === counterParty.id)) {
      return; // element already exist
    }

    const newVal = [...selectedCounterParties.filter((x) => x.id)];
    newVal.push(counterParty);
    setNewCounterpartyCollection(newVal);
  };

  const removeCounterParty = (id: string) => {
    if (!id) {
      return;
    }
    let newVal = [...selectedCounterParties];
    newVal = newVal.filter((x)=> x.id !== id);

    setNewCounterpartyCollection(newVal);
  };

  const setNewCounterpartyCollection = (list:counterPartyModel[]) => {
    setSelectedCounterParties(list);
    setValue('counterParties', list.filter((x)=> x?.id).map((x)=>x.id ?? ''));
    control._updateValid();
  };

  const watch = useWatch({
    control
  });

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        marginTop: theme.spacing(2)
      }}
      width="100%"
    >
      <Controller
        control={control}
        name="startDate"
        render={({ fieldState }) => (
          <DatePicker
            format={dateFormat}
            value={!watch.startDate ? null : moment(watch.startDate)}
            label={`${capitalizeFirstLetterEveryword(t(TXT_FROM))} (${dateFormat})`}
            onChange={(date) => setValue('startDate', date?.toDate())}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!fieldState.error
              }
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        render={({ fieldState }) => (
          <DatePicker
            format={dateFormat}
            value={!watch.endDate ? null : moment(watch.endDate)}
            label={`${capitalizeFirstLetterEveryword(t(TXT_UNTIL))} (${dateFormat})`}
            onChange={(date) => setValue('endDate', date?.toDate())}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!fieldState.error
              }
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Stack direction='column' spacing={2} width='100%'>
        <hr></hr>
        <Stack direction='row' spacing={2} width='100%'>
          <CounterPartySelector
            key={`cpSelector-' + ${(selectedCounterParties?.length??0)} `}
            onChanged={(cParty) => {
              setcounterParty(cParty);
            }}
            label={`${t(TXT_COUNTERPARTY)}`}
            counterPartyType="ALL"
          />
          <Button variant="contained" startIcon={<AddIcon />} onClick={addCounterParty}>{TXT_ADD}</Button>
        </Stack>
        {(selectedCounterParties?.length??0) > 0 && <Typography>{`${capitalizeFirstLetterEveryword(t(TXT_SELECTED_COUNTERPARTIES))}`}:</Typography>}
        {(selectedCounterParties?.length??0) === 0 && <Alert severity="warning"style={{ backgroundColor: 'rgba(0,0,0,0)' }} >{TXT_ADD_COUNTERPARTIES_MESSAGE}</Alert>}
        <div>
          {Object.values(selectedCounterParties).map((cParty, index)=> (
            <Stack key = {cParty?.id} direction='row' spacing={2} width='100%'>
              {cParty.id && <Button startIcon={<DeleteIcon />} onClick={()=>removeCounterParty(cParty.id ?? '')}></Button>}
              {cParty.id && <Typography>{cParty.name}</Typography>}
            </Stack>
          ))}
        </div>
        <hr></hr>
      </Stack>
    </Stack>
  );
};
