import { TextField, Grid, Typography } from '@mui/material';

import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { registerUserInput } from '../../api';
import { RegisterUser } from '../../components';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { TXT_ALIAS, TXT_NAME, TXT_REGISTER_THE_FIRST_USER } from '../../../../../shared/translations';

interface RegisterTenantPageProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isSaving: boolean;
  name: string;
  // eslint-disable-next-line max-len
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  alias: string;
  onAliasChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onRegisterUserChanged: (input: registerUserInput) => void;
  onRegisterUserValidated: (isValid: boolean) => void;
}

export const RegisterTenantPage = (props: RegisterTenantPageProps) => {
  const { t } = useTranslation();

  const txtName = capitalizeFirstLetterEveryword(`${t(TXT_NAME)}*`);
  const txtAlias = capitalizeFirstLetterEveryword(`${t(TXT_ALIAS)}*`);

  return (
    <>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12}>
          <TextField
            autoFocus
            id="tenant-name"
            label={txtName}
            value={props.name || ''}
            onChange={props.onNameChanged}
            fullWidth
            disabled={props.isSaving}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="tenant-alias"
            label={txtAlias}
            value={props.alias || ''}
            onChange={props.onAliasChanged}
            fullWidth
            disabled={props.isSaving}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <fieldset>
        <legend>
          <Typography component="h6">{capitalizeFirstLetterEveryword(t(TXT_REGISTER_THE_FIRST_USER))}</Typography>
        </legend>
        <RegisterUser onChanged={props.onRegisterUserChanged} onValidated={props.onRegisterUserValidated} disabled={props.isSaving} />
      </fieldset>
    </>
  );
};
