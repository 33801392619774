import { AuthenticationResult } from '@azure/msal-browser';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MicrosoftLoginButton } from '..';
import { registerUserInput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { validateEmail } from 'shared/utils';
import {
  TXT_CLEAR,
  TXT_CONNECT_TO_MICROSOFT_ACCOUNT,
  TXT_EMAIL,
  TXT_FULL_NAME,
  TXT_MICROSOFT_ACCOUNT,
  TXT_PASSWORD,
  TXT_RETYPE_PASSWORD
} from '../../../../../shared/translations';
import { microsoftTokenClaims } from 'hooks/useMicrosoftLogin';

interface RegisterUserProps {
  email?: string;
  disabled?: boolean;
  onChanged?: (input: registerUserInput) => void;
  onValidated?: (isValid: boolean) => void;
}

export const RegisterUser = (props: RegisterUserProps) => {
  // eslint-disable-next-line max-len
  const [microsoftAccount, setMicrosoftAcount] = useState<string | undefined>(
    undefined
  );
  // eslint-disable-next-line max-len
  const [microsoftSub, setMicrosoftSub] = useState<string | undefined>(
    undefined
  );
  const [username, setUsername] = useState<string | undefined>(props.email);
  const [fullName, setFullName] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  // eslint-disable-next-line max-len
  const [rePassword, setRePassword] = useState<string | undefined>(undefined);
  const [isValid, setIsValid] = useState(false);

  const { t } = useTranslation();

  const validate = () => {
    const isValidUsername = !!username && validateEmail(username);
    setIsValid(isValidUsername && !!fullName &&
      !!password && !!rePassword &&
      password === rePassword);
  };

  const onReturnedAccountInfo = async (authResult: AuthenticationResult) => {
    if (!!!authResult || !!!authResult.account) {
      return;
    }
    setMicrosoftAcount(authResult.account.username);
    setMicrosoftSub((authResult.idTokenClaims as microsoftTokenClaims).sub);

    setUsername(authResult.account.username);

    setFullName(authResult.account.name);

    // eslint-disable-next-line max-len
    const generatedPassword = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    setPassword(generatedPassword);
    setRePassword(generatedPassword);
  };

  const clearMicrosoftAccount = () => {
    setMicrosoftAcount(undefined);
    setMicrosoftSub(undefined);
    setPassword(undefined);
    setRePassword(undefined);
  };

  const onUsernameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const onFullNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFullName(event.currentTarget.value);
  };

  const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const onRePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setRePassword(event.currentTarget.value);
  };

  useEffect(() => {
    !!props.onChanged &&
      props.onChanged({
        username,
        fullName,
        password,
        rePassword,
        microsoftSub,
        linkedMicrosoftAccount: microsoftAccount
      });
    validate();
  }, [username, fullName, password, rePassword, microsoftSub, microsoftAccount]);

  useEffect(() => {
    !!props.onValidated &&
      props.onValidated(isValid);
  }, [isValid]);

  const txtUsername = capitalizeFirstLetterEveryword(`${t(TXT_EMAIL)}*`);
  const txtFullname = capitalizeFirstLetterEveryword(`${t(TXT_FULL_NAME)}*`);
  const txtPassword = capitalizeFirstLetterEveryword(`${t(TXT_PASSWORD)}*`);
  // eslint-disable-next-line max-len
  const txtRePassword = capitalizeFirstLetterEveryword(
    `${t(TXT_RETYPE_PASSWORD)}*`
  );
  const txtConnectToMicrosoftAccount = t(TXT_CONNECT_TO_MICROSOFT_ACCOUNT);

  return (
    <form>
      <Grid container spacing={2} direction="row">
        {!!microsoftAccount && (
          <>
            <Grid item xs={10}>
              <Typography component="h6">
                {
                  `${capitalizeFirstLetterEveryword(
                    t(TXT_MICROSOFT_ACCOUNT)
                  )}: ${microsoftAccount}`
                }
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                type="button"
                color="secondary"
                variant="contained"
                onClick={clearMicrosoftAccount}
                disabled={props.disabled}
              >
                {t(TXT_CLEAR)}
              </Button>
            </Grid>
          </>
        )}
        {!microsoftAccount && (
          <Grid item xs={12}>
            <MicrosoftLoginButton
              onReturnedAccountInfo={onReturnedAccountInfo}
              variant="contained"
              color="primary"
              signinType="loginPopup"
              fullWidth
              text={txtConnectToMicrosoftAccount}
            />
          </Grid>
        )}
        {
          !microsoftSub &&
          <Grid item xs={12}>
            <TextField
              id="username"
              label={txtUsername}
              value={username || ''}
              onChange={onUsernameChanged}
              fullWidth
              disabled={props.disabled}
              variant="outlined"
              autoComplete='username'
            />
          </Grid>
        }
        {
          !microsoftSub &&
          <Grid item xs={12}>
            <TextField
              id="fullName"
              label={txtFullname}
              value={fullName || ''}
              onChange={onFullNameChanged}
              fullWidth
              disabled={props.disabled}
              variant="outlined"
              autoComplete='name'
            />
          </Grid>
        }
        {
          !microsoftSub &&
          <Grid item xs={12}>
            <TextField
              id="password"
              label={txtPassword}
              value={password || ''}
              onChange={onPasswordChanged}
              fullWidth
              disabled={props.disabled}
              variant="outlined"
              type="password"
              autoComplete='new-password'
            />
          </Grid>
        }
        {
          !microsoftSub &&
          <Grid item xs={12}>
            <TextField
              id="repassword"
              label={txtRePassword}
              value={rePassword || ''}
              onChange={onRePasswordChanged}
              fullWidth
              disabled={props.disabled}
              variant="outlined"
              type="password"
              autoComplete='new-password'
            />
          </Grid>
        }
      </Grid>
    </form>
  );
};
