/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import {
  ADD_STATEMENT_OF_FACT,
  GET_PORTS,
  GET_STATEMENT_OF_FACT,
  GetPortsOutput,
  GetStatementOfFactOutput,
  StatementOfFactQueryResult,
  UPDATE_STATEMENT_OF_FACT,
  UpdateStatementOfFactInterruptionInput,
  analyzeOutput
} from 'api';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import {
  DateTimePickerWithDrop,
  NumberFieldWithDrop,
  OcrChip,
  OverlayLoading,
  TextFieldWithDrop,
  cognitiveReadOperationResultExt
} from 'components';
import { useApolloErrorHandler, useTitle } from 'hooks';
import _, { capitalize } from 'lodash';
import { portModel, statementOfFactInterruptionModel } from 'model';
import moment, { Moment } from 'moment';
import { useGlobalStyles } from 'pages/globalStyles';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { statementOfFactPath } from 'shared/links';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import { DocumentUpload } from '../DocumentUpload';
import { InterruptionDialog } from '../InterruptionDialog';
import { MergeDialog } from '../MergeDialog';
import { SplitDialog } from '../SplitDialog';
import { useOcrScanStyle } from './ocrScanStyle';
import { PortConfirmationDialog } from '../PortConfirmationDialog';
import { PortEditor } from 'pages/Ports/PortEditor';
import {
  TXT_OCR,
  TXT_DRAG_N_DROP_TEXT,
  TXT_FILE_RESTRICTION,
  TXT_RESULT,
  TXT_VESSEL,
  TXT_PORT,
  TXT_QUANTITY,
  TXT_ARRIVAL_DATE,
  TXT_NOR_TENDERED,
  TXT_OCR_RESULT,
  TXT_MERGE,
  TXT_INTERRUPTIONS,
  TXT_SAVING,
  TXT_SAVE
} from '../../../../../../shared/translations';

const mockOcrResult: cognitiveReadOperationResult[] = [
  {
    id: 1,
    pageNumber: 1,
    type: 'System.Int32',
    value: '9'
  },
  {
    id: 2,
    pageNumber: 1,
    type: 'System.String',
    value: 'STATEMENT OF FACTS'
  },
  {
    id: 3,
    pageNumber: 1,
    type: 'System.String',
    value: 'VESSEL NAME'
  },
  {
    id: 4,
    pageNumber: 1,
    type: 'System.String',
    value: 'M/V POLAND PEARL'
  },
  {
    id: 5,
    pageNumber: 1,
    type: 'System.String',
    value: 'QUANTITY'
  },
  {
    id: 6,
    pageNumber: 1,
    type: 'System.String',
    value: 'TONS'
  },
  {
    id: 7,
    pageNumber: 1,
    type: 'System.String',
    value: 'PO REFERENCE'
  },
  {
    id: 8,
    pageNumber: 1,
    type: 'System.String',
    value: 'N/A'
  },
  {
    id: 9,
    pageNumber: 1,
    type: 'System.String',
    value: 'LOADING RATE CONTRACTED'
  },
  {
    id: 10,
    pageNumber: 1,
    type: 'System.String',
    value: 'Ton/day'
  },
  {
    id: 11,
    pageNumber: 1,
    type: 'System.String',
    value: 'PORT OF DESTINATION'
  },
  {
    id: 12,
    pageNumber: 1,
    type: 'System.String',
    value: 'OVET, FLUSHING'
  },
  {
    id: 13,
    pageNumber: 1,
    type: 'System.String',
    value: 'OR DAYS'
  },
  {
    id: 14,
    pageNumber: 1,
    type: 'System.String',
    value: 'CARGO'
  },
  {
    id: 15,
    pageNumber: 1,
    type: 'System.String',
    value: 'DRI C IN BULK'
  },
  {
    id: 16,
    pageNumber: 2,
    type: 'System.String',
    value: 'CONDITIONS'
  },
  {
    id: 17,
    pageNumber: 2,
    type: 'System.String',
    value: 'PORT'
  },
  {
    id: 18,
    pageNumber: 2,
    type: 'System.String',
    value: 'SPN4 POINT LISAS'
  },
  {
    id: 19,
    pageNumber: 2,
    type: 'System.String',
    value: 'DEMURRAGE'
  },
  {
    id: 20,
    pageNumber: 2,
    type: 'System.String',
    value: '(USD/day)'
  },
  {
    id: 21,
    pageNumber: 2,
    type: 'System.String',
    value: 'ARRIVED'
  },
  {
    id: 22,
    pageNumber: 2,
    type: 'System.DateTime',
    value: '2023-07-27T02:30:00.0000000'
  },
  {
    id: 23,
    pageNumber: 2,
    type: 'System.String',
    value: 'DISPATCH'
  },
  {
    id: 24,
    pageNumber: 2,
    type: 'System.String',
    value: '(USD/ay)'
  },
  {
    id: 25,
    pageNumber: 2,
    type: 'System.String',
    value: 'NOR TEND'
  },
  {
    id: 26,
    pageNumber: 2,
    type: 'System.DateTime',
    value: '2023-07-27T03:20:00.0000000'
  },
  {
    id: 27,
    pageNumber: 2,
    type: 'System.String',
    value: 'TT AVAILABLE'
  },
  {
    id: 28,
    pageNumber: 2,
    type: 'System.DateOnly',
    value: '2023-07-27'
  },
  {
    id: 29,
    pageNumber: 2,
    type: 'System.DateOnly',
    value: '2023-09-27'
  },
  {
    id: 30,
    pageNumber: 2,
    type: 'System.TimeOnly',
    value: '02:30:00.0000000'
  },
  {
    id: 31,
    pageNumber: 2,
    type: 'System.TimeOnly',
    value: '02:30:00.0000000'
  },
  {
    id: 32,
    pageNumber: 2,
    type: 'System.String',
    value: 'End of Sea Passage'
  }
];

export const OcrScan = () => {
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const style = useOcrScanStyle();
  const navigate = useNavigate();
  const { statementOfFactId } = useParams();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const dateFormat = getDateFormat();

  useTitle(t(TXT_OCR));

  const [data, setData] = useState<analyzeOutput | undefined>(undefined);
  const [ocrResult, setOcrResult] = useState<cognitiveReadOperationResultExt[]>(mockOcrResult);
  const [originalResult, setOriginalResult] = useState<cognitiveReadOperationResultExt[]>(mockOcrResult);
  const [pageNumbers, setPageNumbers] = useState<number[]>(
    mockOcrResult && mockOcrResult.length > 0 ? _.uniq(mockOcrResult.map((item) => item.pageNumber!)) : []
  );
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [showPortConfirmation, setShowPortConfirmation] = useState(false);
  const [showSplitDialog, setShowSplitDialog] = useState(false);
  const [showInterruptionDialog, setShowInterruptionDialog] = useState(false);
  const [interruptions, setInterruptions] = useState<statementOfFactInterruptionModel[]>([]);
  const [showPortEditor, setShowPortEditor] = useState(false);

  const [vessel, setVessel] = useState<string | undefined>(undefined);
  const [port, setPort] = useState<string | undefined>(undefined);
  const [arrivalDate, setArrivalDate] = useState<Moment | undefined>(undefined);
  const [norTenderedDate, setNorTenderedDate] = useState<Moment | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(0);
  const [splitItem, setSplitItem] = useState<cognitiveReadOperationResultExt | undefined>(undefined);
  const [splitItemIndex, setSplitItemIndex] = useState<number>(0);

  const [getStatementOfFact, { loading: getStatementOfFacLoading }] = useLazyQuery<GetStatementOfFactOutput>(
    GET_STATEMENT_OF_FACT,
    {
      fetchPolicy: 'network-only',
      variables: {
        statementOfFactId
      },
      onCompleted: (data) => {
        if (!!data && !!data.statementOfFact && !!data.statementOfFact.ocrContent) {
          const ocrResultObjects = JSON.parse(data.statementOfFact.ocrContent) as cognitiveReadOperationResultExt[];
          setData(() => ({ statementOfFact: data.statementOfFact, results: ocrResultObjects }));
          setOcrResult(ocrResultObjects);
          setOriginalResult(ocrResultObjects);
          setPageNumbers(() =>
            _.uniq(
              ocrResultObjects && ocrResultObjects.length > 0 ? ocrResultObjects.map((item) => item.pageNumber!) : []
            )
          );

          setVessel(() => data.statementOfFact?.vesselName);
          setPort(() => data.statementOfFact?.port?.name);
          setQuantity(() => data.statementOfFact?.quantity);
          setArrivalDate(() =>
            !data.statementOfFact?.arrivalDate ? undefined : moment(data.statementOfFact?.arrivalDate)
          );
          setNorTenderedDate(() =>
            !data.statementOfFact?.norTenderedDate ? undefined : moment(data.statementOfFact?.norTenderedDate)
          );
        }
      },
      onError: apolloErrorHandler
    }
  );

  useEffect(() => {
    if (!!statementOfFactId) {
      getStatementOfFact();
    }
  }, [statementOfFactId]);

  const [mutateStatementOfFact, { loading }] = useMutation<StatementOfFactQueryResult>(
    statementOfFactId ? UPDATE_STATEMENT_OF_FACT : ADD_STATEMENT_OF_FACT,
    {
      onError: apolloErrorHandler,
      onCompleted: () => {
        navigate(statementOfFactPath);
      }
    }
  );

  const showHideUploadFile = () => {
    setShowUploadFile((curr) => !curr);
  };

  const showHidePortEditor = () => {
    setShowPortEditor((curr) => !curr);
  };

  const showHideMergeDialog = () => {
    setShowMergeDialog((curr) => !curr);
  };

  const showHidePortConfirmationDialog = (confirmation: boolean) => {
    setShowPortConfirmation((curr) => !curr);
    setShowPortEditor((curr) => !curr);
  };

  const showHideSplitDialog = () => {
    setShowSplitDialog((curr) => !curr);
  };

  const showHideInterruptionDialog = () => {
    setShowInterruptionDialog((curr) => !curr);
  };

  const handleFileUpload = async (files: File[], fileRejection: FileRejection[], event: DropEvent): Promise<void> => {
    setData(() => undefined);
    setOcrResult(() => []);
    setOriginalResult(() => []);
    const selectedFile = files[0];
    setFile(() => selectedFile);
    showHideUploadFile();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: {
      'image/*': ['.jpg', '.png'],
      'application/pdf': ['.pdf']
    }
  });

  const onUploadFinish = (data?: analyzeOutput) => {
    if (!!data && !!data.results && data.results.length > 0) {
      setData(data);
      setOcrResult(data.results);
      setOriginalResult(data.results);
      setPageNumbers(() =>
        _.uniq(data.results && data.results.length > 0 ? data.results.map((item) => item.pageNumber!) : [])
      );

      setVessel(() => data.statementOfFact?.vesselName);
      setPort(() => data.statementOfFact?.portName);
      setQuantity(() => data.statementOfFact?.quantity);
      setArrivalDate(() =>
        !data.statementOfFact?.arrivalDate ? undefined : moment(data.statementOfFact?.arrivalDate)
      );
      setNorTenderedDate(() =>
        !data.statementOfFact?.norTenderedDate ? undefined : moment(data.statementOfFact?.norTenderedDate)
      );
      setInterruptions(() => data.statementOfFact?.interruptions ?? []);
    }
  };

  const [getPort] = useLazyQuery<GetPortsOutput>(GET_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        name: {
          eq: port
        }
      },
      take: 1,
      skip: 0
    },
    onCompleted: (data) => {
      return data;
    },
    onError: apolloErrorHandler
  });

  const onSaveStatementOfFact = async () => {
    if (!!port) {
      const data = await getPort();
      const ports = data.data?.ports;
      if (ports?.totalCount === 0) {
        setShowPortConfirmation((curr) => !curr);
      }
    }
  };

  const saveStatementOfFact = (newPort: portModel) => {
    const ocrContent = JSON.stringify(ocrResult);
    const updateInterruptionsInput: UpdateStatementOfFactInterruptionInput[] = _.map(
      interruptions,
      (interruption: statementOfFactInterruptionModel) => ({
        description: interruption.description,
        since: interruption.since,
        to: interruption.to,
        percentage: interruption.percentage,
        toDiscount: interruption.toDiscount,
        statementOfFactId: interruption.statementOfFactId
      })
    );
    if (!statementOfFactId) {
      return mutateStatementOfFact({
        variables: {
          input: {
            vesselName: vessel,
            arrivalDate: arrivalDate,
            norTenderedDate: norTenderedDate,
            quantity: quantity,
            destination: data?.statementOfFact?.destination,
            portId: newPort.id,
            ocrContent: ocrContent,
            interruptions: updateInterruptionsInput
          }
        }
      });
    }
    return mutateStatementOfFact({
      variables: {
        input: {
          id: statementOfFactId,
          vesselName: vessel,
          arrivalDate: arrivalDate,
          norTenderedDate: norTenderedDate,
          quantity: quantity,
          destination: data?.statementOfFact?.destination,
          portId: newPort.id,
          ocrContent: ocrContent,
          interruptions: updateInterruptionsInput
        }
      }
    });
  };

  const handleEditChip = (index: number, result: cognitiveReadOperationResult) => {
    const copyResults = [...ocrResult];
    copyResults[index] = result;
    setOcrResult(() => copyResults);
    setOriginalResult(() => copyResults);
  };

  const handleDeleteChip = (index: number) => {
    const copyResults = [...ocrResult];
    copyResults.splice(index, 1);
    setOcrResult(() => copyResults);
    setOriginalResult(() => copyResults);
  };

  const handleSplitChip = (index: number) => {
    showHideSplitDialog();
    setSplitItem(() => ocrResult[index]);
    setSplitItemIndex(() => index);
  };

  const handleSelectChip = (index: number, isSelected: boolean) => {
    const copyResults = [...ocrResult];
    copyResults[index].selected = isSelected;
    setOcrResult(() => copyResults);
    setOriginalResult(() => copyResults);
  };

  const handleMerge = (items: cognitiveReadOperationResultExt[]) => {
    const selectedIndexes = _.map(_.filter(ocrResult, { selected: true }), (obj, index) => index);
    const copyResults = [...ocrResult];
    _.pullAt(copyResults, selectedIndexes);
    _.forEach(copyResults, (obj) => {
      obj.selected = false;
    });
    setOcrResult(() => _.concat(copyResults, items));
    setOriginalResult(() => _.concat(copyResults, items));
  };

  const handleSplit = (items: cognitiveReadOperationResultExt[]) => {
    const copyResults = [...ocrResult];
    _.pullAt(copyResults, splitItemIndex);
    setOcrResult(() => _.concat(copyResults, items));
    setOriginalResult(() => _.concat(copyResults, items));
  };

  const handleInterruptionSave = (
    items: statementOfFactInterruptionModel[],
    ocrResult: cognitiveReadOperationResultExt[]
  ) => {
    setInterruptions(() => items);
    setOcrResult(() => ocrResult);
  };

  const onPageNumberChanged = (_: React.MouseEvent<HTMLElement>, nextLetter: number) => {
    setSelectedPageNumber(() => nextLetter);
    if (!!nextLetter) setOcrResult(() => originalResult.filter((x) => x.pageNumber === nextLetter));
    else setOcrResult(() => originalResult);
  };

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {showPortEditor && <PortEditor name={port} onClose={showHidePortEditor} afterSave={saveStatementOfFact} />}
      {showPortConfirmation && <PortConfirmationDialog handleCloseDialog={showHidePortConfirmationDialog} />}
      {showUploadFile && <DocumentUpload files={file} onClose={showHideUploadFile} onUploadFinish={onUploadFinish} />}
      {showMergeDialog && (
        <MergeDialog
          handleCloseDialog={showHideMergeDialog}
          items={ocrResult.filter((item) => !!item.selected)}
          handleSuccessMerge={handleMerge}
        />
      )}
      {showSplitDialog && (
        <SplitDialog handleCloseDialog={showHideSplitDialog} item={splitItem} handleSuccessSplit={handleSplit} />
      )}
      {showInterruptionDialog && (
        <InterruptionDialog
          handleCloseDialog={showHideInterruptionDialog}
          items={ocrResult}
          handleSave={handleInterruptionSave}
        />
      )}
      {getStatementOfFacLoading && <OverlayLoading />}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={style.paper}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12}>
                <DndProvider backend={HTML5Backend}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div {...getRootProps({ className: style.dropzone })}>
                        <input {...getInputProps()} />
                        <p>{capitalizeFirstLetterEveryword(t(TXT_DRAG_N_DROP_TEXT))}</p>
                        <em>{capitalizeFirstLetterEveryword(t(TXT_FILE_RESTRICTION))}</em>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{capitalize(t(TXT_RESULT))}</Typography>
                    </Grid>
                    {!!data && !!data.statementOfFact && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4} lg={2}>
                            <TextFieldWithDrop
                              label={capitalizeFirstLetterEveryword(t(TXT_VESSEL))}
                              value={vessel}
                              onChange={(event) => setVessel(() => event.target.value)}
                              onDrop={(item) => setVessel(() => (item as cognitiveReadOperationResult).value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <TextFieldWithDrop
                              label={capitalizeFirstLetterEveryword(t(TXT_PORT))}
                              value={port}
                              onChange={(event) => setPort(() => event.target.value)}
                              onDrop={(item) => setPort(() => (item as cognitiveReadOperationResult).value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <NumberFieldWithDrop
                              label={capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
                              value={quantity}
                              onChange={(event) => setQuantity(() => _.toNumber(event.target.value))}
                              onDrop={(item) =>
                                setQuantity(() => _.toNumber((item as cognitiveReadOperationResult).value))
                              }
                              fullWidth={true}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            <DateTimePickerWithDrop
                              label={capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE))}
                              value={arrivalDate}
                              dateFormat={dateFormat}
                              onChanged={(item, value) => setArrivalDate(() => value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            <DateTimePickerWithDrop
                              label={capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED))}
                              value={norTenderedDate}
                              dateFormat={dateFormat}
                              onChanged={(item, value) => setNorTenderedDate(() => value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{capitalizeFirstLetterEveryword(t(TXT_OCR_RESULT))}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack direction="row" spacing={1}>
                            <ToggleButtonGroup
                              orientation="vertical"
                              value={selectedPageNumber}
                              onChange={onPageNumberChanged}
                              exclusive
                              className={style.letters}
                            >
                              {[...pageNumbers].map((x, index) => (
                                <ToggleButton key={`pagenumber-${index}`} value={x}>
                                  {x}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                            <Grid container spacing={1} direction="row">
                              <Grid item xs={12} md={6}>
                                <Button
                                  disabled={ocrResult.filter((item) => !!item.selected).length < 2}
                                  onClick={showHideMergeDialog}
                                >
                                  {capitalize(t(TXT_MERGE))}
                                </Button>
                                <Button onClick={showHideInterruptionDialog}>{capitalize(t(TXT_INTERRUPTIONS))}</Button>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                {ocrResult && ocrResult.length > 0 && (
                                  <Paper
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      listStyle: 'none',
                                      p: 0.5,
                                      m: 0
                                    }}
                                    component="ul"
                                  >
                                    {ocrResult.map((data, index) => {
                                      return (
                                        <OcrChip
                                          key={index}
                                          id={data.id}
                                          type={data.type}
                                          value={data.value}
                                          selected={data.selected}
                                          usedForInterruption={data.usedForInterruption}
                                          onEdit={(result: cognitiveReadOperationResult) =>
                                            handleEditChip(index, result)
                                          }
                                          onDelete={() => handleDeleteChip(index)}
                                          onSelect={(isSelected) => handleSelectChip(index, isSelected)}
                                          onSplit={() => handleSplitChip(index)}
                                        />
                                      );
                                    })}
                                  </Paper>
                                )}
                              </Grid>
                            </Grid>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" onClick={() => onSaveStatementOfFact()}>
                        {t(loading ? TXT_SAVING : TXT_SAVE)}
                      </Button>
                    </Grid>
                  </Grid>
                </DndProvider>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
