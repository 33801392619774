import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_TRADERS, GetTradersOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_TRADER } from '../../../../../shared/translations';
import { traderModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';

export interface TraderSelectorProps {
  trader?: traderModel;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  onSelectedChange?: (id: string | null) => void;
  onChanged?: (trader?: traderModel) => void;
}

const VISIBLE_OPTIONS_COUNT = 10;

export const TraderSelector = (props: TraderSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<traderModel | null>(props.trader || null);
  const [text, setText] = useState<string | undefined>(props.trader?.name);
  const [options, setOptions] = useState<traderModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    if (_.isNil(text) || text.length <= 0) {
      return null;
    }

    return {
      or: [
        {
          name: {
            contains: text
          }
        },
        {
          initials: {
            contains: text
          }
        }
      ]
    };
  };

  const [getTraders, { data: traderData, loading, refetch }] = useLazyQuery<GetTradersOutput>(GET_TRADERS, {
    fetchPolicy: 'network-only',
    variables: {
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0,
      where: getFilter()
    },
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (!refetch) {
      getTraders();
    } else {
      refetch();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: traderModel | null) => {
    setSelected(value);
    props.onSelectedChange && props.onSelectedChange(value && (value.id as string));
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  const label = capitalizeFirstLetterEveryword(t(props.label || TXT_TRADER));

  useEffect(() => {
    fetch();
  }, [text]);

  useEffect(() => {
    if ((traderData?.traders?.items?.length || 0) > 0) {
      let traderOptions: traderModel[] = (traderData?.traders?.items || []).map((trader) => ({
        id: trader.id,
        name: trader.name
      }));
      const totalCount = traderData?.traders?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        traderOptions = [
          ...traderOptions,
          {
            id: 'trader-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(t(TXT_AND_MORE, {
              count: totalCount - VISIBLE_OPTIONS_COUNT
            }))
          }
        ];
      }
      if (!_.isNil(selected)) {
        traderOptions = [{ ...selected }, ...traderOptions];
      }
      setOptions(_.uniqBy(traderOptions, 'id'));
    }
  }, [traderData]);

  return (
    <Autocomplete
      id="trader-selector"
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      value={selected}
      getOptionDisabled={(option) => option.id === 'trader-hidden-options-remaining'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {/* eslint-disable-next-line max-len */}
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
