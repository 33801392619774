import { useLazyQuery } from '@apollo/client';
import { Checkbox, Grid, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AddStatementOfFactInput, GET_PORTS, GetPortsOutput } from 'api';
import {
  CustomTable,
  DateTimePickerWithDrop,
  NumberFieldWithDrop,
  NumberTextField,
  PortSelector,
  TextFieldWithDrop,
  TimePickerWithDrop,
  UserSelector
} from 'components';
import { StatementOfFactStatusSelector } from 'components/StatementOfFactStatusSelector';
import { useApolloErrorHandler } from 'hooks';
import { t } from 'i18next';
import { portModel, statementOfFactInterruptionModel, statementOfFactStatus } from 'model';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import {
  TXT_VESSEL,
  TXT_PORT,
  TXT_QUANTITY,
  TXT_ARRIVAL_DATE,
  TXT_NOR_TENDERED,
  TXT_STATUS,
  TXT_TRAFFIC_ANALYST,
  TXT_ASSIGN_TO,
  TXT_DESCRIPTION,
  TXT_SINCE,
  TXT_TO,
  TXT_TO_DISCOUNT,
  TXT_DATE,
  TXT_TIME
} from '../../../../../../shared/translations';

export const SummaryWizard = () => {
  const { control, setValue } = useFormContext<AddStatementOfFactInput>();

  const dateFormat = getDateFormat();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const watchVesselName = useWatch({
    control,
    name: 'vesselName'
  });

  const watchQuantity = useWatch({
    control,
    name: 'quantity'
  });

  const watchArrivalDate = useWatch({
    control,
    name: 'arrivalDate'
  });

  const watchNorTenderedDate = useWatch({
    control,
    name: 'norTenderedDate'
  });

  const watchStatus = useWatch({
    control,
    name: 'status'
  });

  const watchTrafficSpecialist = useWatch({
    control,
    name: 'trafficSpecialist'
  });

  const watchAssignTo = useWatch({
    control,
    name: 'assignTo'
  });

  const watchInterruptions = useWatch({
    control,
    name: 'interruptions'
  });

  const watchPortId = useWatch({
    control,
    name: 'portId'
  });

  const [port, setPort] = useState<portModel | undefined>(undefined);
  const [interruptions, setInterruptions] = useState<Record<string, Partial<statementOfFactInterruptionModel>>>(
    {} as Record<string, Partial<statementOfFactInterruptionModel>>
  );

  const [getPort] = useLazyQuery<GetPortsOutput>(GET_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: {
          eq: watchPortId
        }
      },
      take: 1,
      skip: 0
    },
    onCompleted: (data) => {
      return data;
    },
    onError: apolloErrorHandler
  });

  const checkPort = async () => {
    if (!!watchPortId) {
      const data = await getPort();
      const ports = data.data?.ports;
      if (ports && ports.items && ports.items.length > 0) {
        const port = ports.items[0];
        setPort(port);
        setValue('portId', port?.id, {
          shouldValidate: true
        });
        setValue('portName', port?.name);
      }
    }
  };

  useEffect(() => {
    checkPort();
  }, []);

  useEffect(() => {
    if (!!watchInterruptions) {
      setInterruptions(() => {
        const transformedInterruptions: Record<string, Partial<statementOfFactInterruptionModel>> = {};
        watchInterruptions.forEach((input, index) => {
          transformedInterruptions[input.id!] = {
            id: input.id,
            statementOfFactId: input.statementOfFactId,
            description: input.description,
            since: input.since,
            to: input.to,
            percentage: input.percentage,
            toDiscount: input.toDiscount
          };
        });
        return transformedInterruptions;
      });
    }
  }, []);

  useEffect(() => {
    const updatedInputs = Object.values(interruptions).map((interruption, index) => ({
      id: interruption.id,
      statementOfFactId: interruption.statementOfFactId,
      description: interruption.description,
      since: interruption.since,
      to: interruption.to,
      percentage: interruption.percentage,
      toDiscount: interruption.toDiscount
    }));
    setValue('interruptions', updatedInputs);
  }, [interruptions]);

  return (
    <Grid container spacing={3} direction="row-reverse">
      <Grid item xs={12}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <TextFieldWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_VESSEL))}
                value={watchVesselName}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                control={control}
                name={`portId`}
                render={({ formState, fieldState }) => (
                  <PortSelector
                    label={t(capitalizeFirstLetterEveryword(TXT_PORT))}
                    port={port}
                    error={!!formState.errors.portId}
                    errorMessage={formState.errors.portId?.message}
                    disabled={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <NumberFieldWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
                value={watchQuantity}
                disabled={true}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DateTimePickerWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE))}
                value={moment(watchArrivalDate)}
                dateFormat={dateFormat}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DateTimePickerWithDrop
                label={capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED))}
                value={moment(watchNorTenderedDate)}
                dateFormat={dateFormat}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                control={control}
                name={`status`}
                render={({ fieldState }) => (
                  <StatementOfFactStatusSelector
                    label={capitalizeFirstLetterEveryword(t(TXT_STATUS))}
                    value={watchStatus as statementOfFactStatus}
                    onChanged={(value) => setValue('status', value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name={`trafficSpecialistId`}
                control={control}
                render={({ formState }) => (
                  <UserSelector user={watchTrafficSpecialist} label={TXT_TRAFFIC_ANALYST} disabled={true} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name={`assignToId`}
                control={control}
                render={({ formState }) => <UserSelector user={watchAssignTo} label={TXT_ASSIGN_TO} disabled={true} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTable stickyHeader style={{ overflowX: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={500} rowSpan={2}>
                      {t(TXT_DESCRIPTION).toUpperCase()}
                    </TableCell>
                    <TableCell align="center" width={500} colSpan={2}>
                      {t(TXT_SINCE).toUpperCase()}
                    </TableCell>
                    <TableCell align="center" width={500} colSpan={2}>
                      {t(TXT_TO).toUpperCase()}
                    </TableCell>
                    <TableCell align="center" width={200} rowSpan={2}>
                      %
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {t(TXT_TO_DISCOUNT).toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ top: 57 }} align="center" width={400}>
                      {t(TXT_DATE).toUpperCase()}
                    </TableCell>
                    <TableCell style={{ top: 57 }} align="center">
                      {t(TXT_TIME).toUpperCase()}
                    </TableCell>
                    <TableCell style={{ top: 57 }} align="center" width={400}>
                      {t(TXT_DATE).toUpperCase()}
                    </TableCell>
                    <TableCell style={{ top: 57 }} align="center">
                      {t(TXT_TIME).toUpperCase()}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(interruptions).map((interruption) => {
                    return (
                      <TableRow key={interruption.id}>
                        <TableCell>
                          <TextFieldWithDrop
                            id={'description-' + interruption.id}
                            value={interruption.description || null}
                            style={{ width: 300 }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell>
                          <DateTimePickerWithDrop
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                margin: 'normal',
                                autoComplete: 'off'
                              }
                            }}
                            value={!interruption.since ? undefined : moment(interruption.since)}
                            dateFormat={dateFormat}
                            style={{ width: 150 }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TimePickerWithDrop
                            value={!interruption.since ? undefined : moment(interruption.since)}
                            disabled={true}
                            style={{ width: 105 }}
                          />
                        </TableCell>
                        <TableCell>
                          <DateTimePickerWithDrop
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                margin: 'normal',
                                autoComplete: 'off'
                              }
                            }}
                            value={!interruption.to ? undefined : moment(interruption.to)}
                            dateFormat={dateFormat}
                            disabled={true}
                            style={{ width: 150 }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TimePickerWithDrop
                            value={!interruption.to ? undefined : moment(interruption.to)}
                            disabled={true}
                            style={{ width: 105 }}
                          />
                        </TableCell>
                        <TableCell>
                          <NumberTextField
                            margin="normal"
                            id="percentage"
                            fullWidth
                            value={interruption.percentage}
                            disabled={true}
                            style={{ width: 75 }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!interruption.toDiscount} disabled={true} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </CustomTable>
            </Grid>
          </Grid>
        </DndProvider>
      </Grid>
    </Grid>
  );
};
