import { tenantModel } from 'model/tenantModel';
import { atom } from 'recoil';

const tenantStateKey = 'tenantState';

export const defaultTenantState: tenantModel = {
  alias: ''
};

export const tenantState = atom({
  key: tenantStateKey,
  default: defaultTenantState
});
