import { useRecoilState } from 'recoil';
import authState from 'state/authState';
import { getAppLocalStorage, setAppLocalStorage } from '../shared/appLocalStorage';
import { ROLES_ADMIN, ROLES_GUEST, ROLES_USER } from '../shared/roles';
import { Buffer } from 'buffer';

export type AuthStateType = {
  id?: string;
  username?: string;
  fullName?: string;
  accessToken?: string;
  accessTokenValidDate?: Date;
  refreshToken?: string;
  refreshTokenValidDate?: Date;
  hasMicrosoftAccountConnected?: boolean;
  hasGoogleAccountConnected?: boolean;
  isAdmin?: boolean;
  isOperator?: boolean;
  isGuest?: boolean;
  allowOCR?: boolean;
  tenantId?: string;
};

const useAuth = (): [AuthStateType, (value: AuthStateType) => void] => {
  const [auth, setAuth] = useRecoilState(authState);

  const jwtDecode = (token?: string) => {
    if (!!!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString());

    return JSON.parse(jsonPayload);
  };

  const setAuthState = (value: AuthStateType) => {
    const decodedToken = jwtDecode(value.accessToken);
    if (decodedToken) {
      value.isAdmin = decodedToken['role'] === ROLES_ADMIN;
      value.isOperator = decodedToken['role'] === ROLES_USER;
      value.isGuest = decodedToken['role'] === ROLES_GUEST;
      value.tenantId = decodedToken['groupsid'];
    }

    setAuth({ ...value });
    setAppLocalStorage({
      ...getAppLocalStorage(),
      ...value
    });
  };

  return [auth, setAuthState];
};

export default useAuth;
