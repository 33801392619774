import { CounterPartyType, CounterPartyTypes, IncoTerm, dealModel, dealPortModel, laytimeCalculationModel, splitQuantityModel } from 'model';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_RECEIVER, TXT_SHIPOWNER, TXT_SUPPLIER } from '../../../../../shared/translations';
import { t } from 'i18next';
import moment from 'moment';
import _ from 'lodash';

interface CalcPosition {
  portType: 'loadingCalculations' | 'unloadingCalculations';
  index: number;
}
export const getDemurrageCalculation = (
  calculationId: string,
  deal: dealModel
): laytimeCalculationModel | undefined => {
  const calcPosition = getCalcPosition(calculationId, deal);
  if (calcPosition) {
    return deal[calcPosition.portType]![calcPosition.index];
  }
  return undefined;
};

export interface totalTimeFomatted {
  days: number;
  hours: number;
  minutes: number;
}

export const getCalcPosition = (calculationId: string, deal: dealModel): CalcPosition | undefined => {
  let index = deal.loadingCalculations?.findIndex((x) => x.id === calculationId) ?? -1;
  if (index >= 0) {
    return {
      portType: 'loadingCalculations',
      index
    };
  } else {
    index = deal.unloadingCalculations?.findIndex((x) => x.id === calculationId) ?? -1;
    if (index >= 0) {
      return {
        portType: 'unloadingCalculations',
        index
      };
    }
    return undefined;
  }
};

export const getCalculationsOrdered = (portType: 'loadingCalculations' | 'unloadingCalculations', deal?: dealModel) => {
  if (!deal || !deal[portType]) {
    return [];
  }

  const calculations = [...deal[portType]!];

  return calculations;
};

export const getSupplierCount = (portType: 'loadingCalculations' | 'unloadingCalculations', deal?: dealModel) => {
  if (!deal) {
    return 0;
  }
  return (deal[portType]?.filter((x) => x.counterPartyTypeId === CounterPartyTypes.SUPPLIER) || []).length;
};

export const formatMinutes = (minutes?: number) => {
  const totalTimeFormatted = getTotalTimeFormatted(minutes);
  if (totalTimeFormatted === undefined || totalTimeFormatted === null) {
    return '';
  }

  const duration = [];
  duration.push(format2Digits(totalTimeFormatted.hours));
  duration.push(format2Digits(totalTimeFormatted.minutes));
  return format2Digits(totalTimeFormatted.days) + 'd ' + duration.join(':');
};

export const getTotalTimeFormatted = (minutes?: number): totalTimeFomatted | undefined => {
  if (minutes === undefined || minutes === null) {
    return undefined;
  }

  const days = Math.trunc(minutes / 1440);
  let rmin = minutes - days * 1440;
  const hours = Math.trunc(rmin / 60);
  rmin = Math.trunc(rmin - hours * 60);
  const duration = [];
  duration.push(format2Digits(hours));
  duration.push(format2Digits(rmin));

  return {
    days: days,
    hours: hours,
    minutes: rmin
  };
};

export const totalHours = (minutes?: number) => {
  if (minutes === undefined || minutes === null) {
    return '';
  }

  let days = minutes / (60 * 24);

  days = Math.round(days * 1000000) / 1000000;

  return '' + days + ' days';
};

export const format2Digits = (value: number) => ('00' + value).slice(-2);

export const truncateNumber = (num: number, fixed: number) => {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
};

export const getCounterPartyLabel = (ctype: CounterPartyType | undefined): string => {
  if (ctype === undefined) return '';
  const result = [
    { type: 'CLIENT', text: TXT_RECEIVER },
    { type: 'SUPPLIER', text: TXT_SUPPLIER },
    { type: 'SHIPOWNER', text: TXT_SHIPOWNER }
  ];
  return capitalizeFirstLetterEveryword(t(result.filter((x) => x.type.toLowerCase() === ctype.toLowerCase())[0].text));
};

export const getPortLastestDate = (
  selectedLaytimeCalculation: laytimeCalculationModel | undefined,
  selectedPort: dealPortModel | undefined
): Date | undefined => {
  let latestDate: Date | undefined = undefined;

  if (selectedLaytimeCalculation) {
    latestDate = getLastestDate(
      latestDate,
      selectedLaytimeCalculation?.laycanStartDate ? selectedLaytimeCalculation.laycanStartDate : undefined
    );
    latestDate = getLastestDate(
      latestDate,
      selectedLaytimeCalculation?.laycanEndDate ? selectedLaytimeCalculation.laycanEndDate : undefined
    );
  }

  if (selectedPort) {
    latestDate = getLastestDate(latestDate, selectedPort?.laycanEndDate ? selectedPort.laycanEndDate : undefined);
    latestDate = getLastestDate(latestDate, selectedPort?.laycanStartDate ? selectedPort.laycanStartDate : undefined);
    latestDate = getLastestDate(latestDate, selectedPort?.billOfLadingDate ? selectedPort.billOfLadingDate : undefined);
    latestDate = getLastestDate(latestDate, selectedPort?.commencedDate ? selectedPort.commencedDate : undefined);
    latestDate = getLastestDate(latestDate, selectedPort?.norTenderedDate ? selectedPort.norTenderedDate : undefined);
    latestDate = getLastestDate(latestDate, selectedPort?.validNor ? selectedPort.validNor : undefined);
  }

  return latestDate;
};

export const getLastestDate = (latestDate: Date | undefined, newDate: Date | undefined): Date | undefined => {
  if (!latestDate) {
    return newDate;
  }

  if (!!newDate && moment(newDate).isAfter(moment(latestDate))) {
    return newDate;
  }

  return latestDate;
};

export const getIncoTerm = (split: splitQuantityModel): IncoTerm | undefined => {
  if ( _.isNil(split)) {
    return undefined;
  }

  return _.isNil(split.counterParty?.businessUnitId) ? split.incoTerm : 'CIF';
};
