import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStatementOfFactStyle = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      height: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%'
    }
  })
);
