import { gql } from '@apollo/client';
import { shareableLinkModel } from 'model';
import { shareableLinkFragment } from './shareableLinkQueries';

export interface AddShareableLinkInput extends Omit<shareableLinkModel, 'id' | 'linkId' | 'requestorId' | 'requestor'> {
  rePassword?: string;
  updateDealPortAgent?: boolean;
}

export interface UpdateShareableLinkInput extends AddShareableLinkInput {
  id: string;
}

export interface ShareableLinkMutationResponse {
  shareableLink: shareableLinkModel;
}

export const ADD_SHAREABLE_LINK = gql`
  mutation AddShareableLink($input: AddShareableLinkInput!) {
    shareableLink: addShareableLink(input: $input) {
      ${shareableLinkFragment}
    }
  }
`;

export const UPDATE_SHAREABLE_LINK = gql`
  mutation UpdateShareableLink($input: UpdateShareableLinkInput!) {
    shareableLink: updateShareableLink(input: $input) {
      ${shareableLinkFragment}
    }
  }
`;

export interface DeleteShareableLinkInput {
  id: string;
}

export const DELETE_SHAREABLE_LINK = gql`
  mutation DeleteShareableLink($input: DeleteShareableLinkInput!) {
    deleteShareableLink(input: $input)
  }
`;
