import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enUS, esES } from '../../../shared/translations';

const resources = {
  enUS: {
    translation: enUS
  },
  esES: {
    translation: esES
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'enUS',
    fallbackLng: 'enUS',
    interpolation: {
      escapeValue: false
    }
  });
