/* eslint-disable max-len */
export const UKFlagIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <rect width="36" height="36" fill="none" />
        <path d="M0 9V27H36V9H0Z" fill="#012169" />
        <path d="M0 9L36 27L0 9ZM36 9L0 27L36 9Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9754 18L-0.804688 10.61L0.805282 7.39005L18.0003 15.9876L35.1953 7.39005L36.8053 10.61L22.0252 18L36.8053 25.39L35.1953 28.61L18.0003 20.0125L0.805282 28.61L-0.804688 25.39L13.9754 18Z" fill="white" />
        <path d="M0 9L36 27L0 9ZM36 9L0 27L36 9Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.3168 17.9999L-0.536621 10.0732L0.536692 7.92661L18 16.6583L35.4634 7.92661L36.5367 10.0732L20.6833 17.9999L36.5367 25.9266L35.4634 28.0732L18 19.3416L0.536692 28.0732L-0.536621 25.9266L15.3168 17.9999Z" fill="#C8102E" />
        <path d="M18 9V27V9ZM0 18H36H0Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21 21V27H15V21H0V15H15V9H21V15H36V21H21Z" fill="white" />
        <path d="M18 9V27V9ZM0 18H36H0Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.8 19.8V27H16.2V19.8H0V16.2H16.2V9H19.8V16.2H36V19.8H19.8Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
