import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import {
  ADD_SHAREABLE_LINK,
  AddShareableLinkInput,
  GET_SHAREABLE_LINKS,
  GetMaritimeDirectoriesOutput,
  GetShareableLinksResponse,
  ShareableLinkMutationResponse
} from 'api';
import { DealPortSelector } from 'components';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { useApolloErrorHandler } from 'hooks';
import _ from 'lodash';
import { shareableLinkModel } from 'model';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_AGENT,
  TXT_CLOSE,
  TXT_EMAIL,
  TXT_GRANT_ACCESS,
  TXT_LOADING_PORT,
  TXT_REQUEST_FORM,
  TXT_UNLOADING_PORT,
  TXT_UPDATE_PORT_AGENT,
  TXT_VESSEL
} from '../../../../../shared/translations';
import * as yup from 'yup';
import { DealPortRequestFormShareableLinks } from './DealPortRequestFormShareableLinks';
import moment from 'moment';

interface DealPortRequestFormProps {
  dealId: string;
  dealPortId?: string;
  isLoadingPort?: boolean;
  onCancel: () => void;
}

const schema = yup.object({
  dealPortId: yup.string().required(),
  counterPartyId: yup.string().required(),
  receiverEmail: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
    ),
  updateDealPortAgent: yup.boolean()
});

export const DealPortRequestForm = (props: DealPortRequestFormProps) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string[]>([]);
  const [links, setLinks] = useState<shareableLinkModel[]>([]);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<AddShareableLinkInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      dealPortId: props.dealPortId,
      shareableLinkType: 'DEAL_PORT'
    }
  });

  const watchDealPort = useWatch({
    control,
    name: 'dealPort'
  });

  const watchEmail = useWatch({
    control,
    name: 'receiverEmail'
  });

  const watchCounterPartyId = useWatch({
    control,
    name: 'counterPartyId'
  });

  const { apolloErrorHandler } = useApolloErrorHandler();

  const [fetchShareableLinks, { loading: loadShareableLinks }] = useLazyQuery<GetShareableLinksResponse>(
    GET_SHAREABLE_LINKS,
    {
      fetchPolicy: 'network-only',
      onError: apolloErrorHandler,
      onCompleted: (data) => {
        setLinks([...data.shareableLinks]);
      }
    }
  );

  const [fetchMaritimeDirectories, { loading: loadEmails }] = useLazyQuery<GetMaritimeDirectoriesOutput>(
    gql`
      query getMaritimeDirectories($take: Int, $skip: Int, $where:  MaritimeDirectoryFilterInput) {
        maritimeDirectories(take: $take, skip: $skip, where: $where) {
          items {
            contactId
            contact {
              emails {
                email
              }
            }
          }
          totalCount
        }
      }
    `,
    {
      onError: apolloErrorHandler,
      onCompleted: (data) => {
        const emails = data.maritimeDirectories.items
          .map((x) => x.contact?.emails || [])
          .reduce((prev, curr) => prev.concat(curr), [])
          .filter((x) => !!x.email)
          .map((x) => x.email!);
        setEmails(emails);
        setValue('receiverEmail', emails.length > 0 ? emails[0] : undefined);
      }
    }
  );

  const refetchShareableLinks = () => {
    setLinks([]);
    if (!watchDealPort?.id) {
      return;
    }

    fetchShareableLinks({
      variables: {
        where: {
          and: [
            {
              shareableLinkType: {
                eq: 'DEAL_PORT'
              }
            },
            {
              dealPortId: {
                eq: watchDealPort?.id
              }
            },
            {
              expiredDate: {
                gt: moment().format('MMM DD YYYY')
              }
            },
            {
              closed: {
                eq: null
              }
            }
          ]
        },
        order: [
          {
            expiredDate: 'DESC' as const
          }
        ]
      }
    });
  };

  const [save, { loading: isSaving }] = useMutation<ShareableLinkMutationResponse>(ADD_SHAREABLE_LINK, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      refetchShareableLinks();
    }
  });

  const getTitle = () => {
    if (!watchDealPort) {
      return t(TXT_REQUEST_FORM);
    }

    return `${t(
      (watchDealPort.loadingDeals || []).length > 0 ? TXT_LOADING_PORT : TXT_UNLOADING_PORT
    )} — ${TXT_REQUEST_FORM}`;
  };

  const getVessel = () => {
    const deals = (!!props.isLoadingPort ? watchDealPort?.loadingDeals : watchDealPort?.unloadingDeals) || [];
    if (deals.length <= 0) {
      return null;
    }
    const deal = deals[0];
    return deal.vessel?.name;
  };

  const onSubmit: SubmitHandler<AddShareableLinkInput> = (data) => {
    const input = _.omit(data, ['counterParty', 'dealPort']);
    save({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    setValue('receiverEmail', undefined);
    if (watchCounterPartyId) {
      fetchMaritimeDirectories({
        variables: {
          where: {
            counterPartyId: {
              eq: watchCounterPartyId
            }
          },
          skip: 0,
          take: 100
        }
      });
    }
    setValue('updateDealPortAgent', false);
  }, [watchCounterPartyId]);

  useEffect(() => {
    setValue('counterPartyId', watchDealPort?.agentId);
    setValue('counterParty', watchDealPort?.agent);
    setValue(
      'receiverEmail',
      (watchDealPort?.agent?.maritimeDirectories || []).length > 0 &&
        (watchDealPort?.agent?.maritimeDirectories![0].contact?.emails || []).length > 0
        ? watchDealPort?.agent?.maritimeDirectories![0].contact?.emails![0].email
        : undefined
    );
    setValue('updateDealPortAgent', false);
    refetchShareableLinks();
  }, [watchDealPort]);

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <AppBar position="relative">
        <Toolbar>{getTitle().toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form id="frm-dealport-request">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="dealPortId"
                render={({ fieldState }) => (
                  <DealPortSelector
                    dealId={props.dealId}
                    dealPortId={props.dealPortId}
                    isLoadingPort={!!props.isLoadingPort}
                    onChanged={(value) => {
                      setValue('dealPortId', value?.id);
                      setValue('dealPort', value);
                    }}
                    disabled={isSaving || loadShareableLinks}
                    error={!!fieldState.error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={capitalizeFirstLetterEveryword(t(TXT_VESSEL))}
                variant="standard"
                value={getVessel() || ''}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="counterPartyId"
                render={({ fieldState }) => (
                  <CounterPartySelector
                    label={capitalizeFirstLetterEveryword(t(TXT_AGENT))}
                    counterParty={watchDealPort?.agent}
                    counterPartyType="AGENT"
                    onChanged={(c) => {
                      setValue('counterParty', c);
                      setValue('counterPartyId', c?.id, {
                        shouldValidate: true,
                        shouldDirty: true
                      });
                    }}
                    allowAdd
                    error={!!fieldState.error}
                    disabled={isSaving || loadShareableLinks}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={emails}
                value={watchEmail || ''}
                loading={loadEmails}
                freeSolo
                onChange={(_, value) => {
                  setValue('receiverEmail', value || undefined);
                }}
                onInputChange={(_, value) => {
                  setValue('receiverEmail', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={capitalizeFirstLetterEveryword(t(TXT_EMAIL))}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: 'email'
                    }}
                    error={!!errors.receiverEmail}
                    disabled={isSaving || loadEmails}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="updateDealPortAgent"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => setValue('updateDealPortAgent', e.target.checked)}
                        disabled={isSaving || !dirtyFields.counterPartyId || !watchCounterPartyId}
                      />
                    }
                    label={capitalizeFirstLetterEveryword(t(TXT_UPDATE_PORT_AGENT))}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'right'
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                fullWidth={false}
                disabled={isSaving}
              >
                {t(TXT_GRANT_ACCESS)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                height: '500px'
              }}
            >
              <DealPortRequestFormShareableLinks
                links={links}
                disabled={isSaving}
                loading={loadShareableLinks}
                afterDelete={refetchShareableLinks}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary" disabled={isSaving}>
          {t(TXT_CLOSE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
