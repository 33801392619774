import { gql } from '@apollo/client';
import { basePagingResult } from '../base';
import { vesselModel } from 'model';

export const getVesselsFragment = `
  items {
    id
    name
    imo
    vesselType
  },
  totalCount
`;

export type GetVesselsOutput = {
  vessels: basePagingResult<vesselModel>;
};

export const GET_VESSELS = gql`
  query getVessels($name: String, $vesselType: VesselType, $take: Int!, $skip: Int!, $where: VesselFilterInput) {
    vessels(name: $name, vesselType: $vesselType, take: $take, skip: $skip, where: $where) {
      ${getVesselsFragment}
    }
  }
`;
