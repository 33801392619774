import PortIcon from '@mui/icons-material/Anchor';
import ApiIcon from '@mui/icons-material/Api';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import ProductIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import InterruptionsIcon from '@mui/icons-material/ReportProblem';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VisibiltyIcon from '@mui/icons-material/Visibility';
import { Card, CardActionArea, Container, Grid, Typography, useTheme } from '@mui/material';
import { MenuType } from 'components';
import { useTitle } from 'hooks';
import useAuth from 'hooks/useAuth';
import { capitalize } from 'lodash';
import { useGlobalStyles } from 'pages/globalStyles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  bargesPath,
  businessUnitsPath,
  calendarPath,
  counterPartiesPath,
  dataMaintenancePath,
  integrationsPath,
  interruptionDefinitionsPath,
  portsPath,
  productPath,
  statementOfFactPath,
  tradersPath,
  userPath,
  vesselPath
} from 'shared/links';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { tenantState } from 'state/tenantState';
import {
  TXT_BARGE,
  TXT_BUSINESS_UNITS,
  TXT_CALENDAR,
  TXT_CONFIGURATIONS,
  TXT_COUNTERPARTIES,
  TXT_DATA_MAINTENANCE,
  TXT_EXCHANGE_RATE,
  TXT_INTEGRATIONS,
  TXT_INTERRUPTION_DEFINITIONS,
  TXT_PORTS,
  TXT_PRODUCTS,
  TXT_STATEMENT_OF_FACT,
  TXT_TRADERS,
  TXT_USERS,
  TXT_VESSELS
} from '../../../../../shared/translations';
import EngineeringIcon from '@mui/icons-material/Engineering';

export const Configurations = () => {
  useTitle(TXT_CONFIGURATIONS);
  const globalStyles = useGlobalStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [tenant] = useRecoilState(tenantState);
  const googleKey = tenant.fxSpreadsheetId;

  let menus: MenuType[] = [
    {
      key: 'vessels',
      icon: <DirectionsBoatIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_VESSELS)),
      link: vesselPath
    },
    {
      key: 'counterparties',
      icon: <SupervisedUserCircleIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTIES)),
      link: counterPartiesPath
    },
    {
      key: 'ports',
      icon: <PortIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_PORTS)),
      link: portsPath
    },
    {
      key: 'products',
      icon: <ProductIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_PRODUCTS)),
      link: productPath
    },
    {
      key: 'interruptionDefinitions',
      icon: <InterruptionsIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_INTERRUPTION_DEFINITIONS)),
      link: interruptionDefinitionsPath
    },
    {
      key: 'barge',
      icon: <HouseboatIcon fontSize="large" />,
      text: capitalizeFirstLetterEveryword(t(TXT_BARGE)),
      link: bargesPath
    }
  ];

  if (!!auth.isAdmin) {
    const adminMenus: MenuType[] = [
      {
        key: 'integrations',
        icon: <ApiIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_INTEGRATIONS)),
        link: integrationsPath
      },
      {
        key: 'users',
        icon: <PeopleIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_USERS)),
        link: userPath
      },
      {
        key: 'traders',
        icon: <CoPresentIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_TRADERS)),
        link: tradersPath
      },
      {
        key: 'businessUnits',
        icon: <BusinessIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_BUSINESS_UNITS)),
        link: businessUnitsPath
      },
      {
        key: 'calendar',
        icon: <CalendarMonthIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_CALENDAR)),
        link: calendarPath
      },
      {
        key: 'dataMaintenance',
        icon: <EngineeringIcon fontSize="large" />,
        text: capitalizeFirstLetterEveryword(t(TXT_DATA_MAINTENANCE)),
        link: dataMaintenancePath
      }
    ];
    menus = [...menus, ...adminMenus];
  }

  if (!!auth.allowOCR) {
    menus = [
      ...menus,
      {
        key: 'statementOfFact',
        icon: <VisibiltyIcon fontSize="large" />,
        text: capitalize(t(TXT_STATEMENT_OF_FACT)) + ' (Coming soon)',
        link: statementOfFactPath
      }
    ];
  }

  menus = [
    ...menus.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      return 1;
    })
  ];

  const onMenuClicked = (link: string) => navigate(link);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      <Grid container spacing={2}>
        {menus.map((menu) => (
          <Grid key={menu.key} item xs={6} md={4} xl={4}>
            <Card>
              <CardActionArea
                onClick={() => onMenuClicked(menu.link)}
                style={{
                  padding: theme.spacing(2),
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <>{menu.icon}</>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2)
                  }}
                >
                  {menu.text}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={6} md={4}>
          <Card>
            <CardActionArea
              onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${googleKey}`)}
              style={{
                padding: theme.spacing(2),
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <CurrencyExchangeIcon fontSize="large" />
              <Typography
                variant="h5"
                style={{
                  textAlign: 'center',
                  marginTop: theme.spacing(2)
                }}
              >
                {capitalizeFirstLetterEveryword(t(TXT_EXCHANGE_RATE))}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
