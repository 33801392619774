import { TXT_DELETE, TXT_EDIT, TXT_NAME } from '../../../../../shared/translations';

export interface ProductsColumn {
  id: 'name' | 'country' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const productsColumns: ProductsColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%'
  }
];
