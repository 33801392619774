import { gql } from '@apollo/client';
import { businessUnitModel, counterPartyModel } from 'model';
export interface addCounterPartyInput {
  name: string;
  reference?: string;
  counterPartyTypeIds: string[];
  businessUnitId?: string;
  businessUnit?: businessUnitModel;
}

export interface updateCounterPartyInput extends addCounterPartyInput {
  id: string;
}

export interface deleteCounterPartyInput {
  id: string;
}

export interface CounterPartyMutationOutput {
  counterParty: counterPartyModel;
}

export const ADD_COUNTER_PARTY = gql`
  mutation addCounterParty($input: AddCounterPartyInput!) {
    counterParty: addCounterParty(input: $input) {
      id,
      name,
      reference,
      counterPartyTypes {
        id,
        name
      },
      businessUnitId,
      businessUnit {
        id,
        name
      }
    }
  }
`;

export const UPDATE_COUNTER_PARTY = gql`
  mutation updateCounterParty($input: UpdateCounterPartyInput!) {
    counterParty: updateCounterParty(input: $input) {
      id,
      name,
      reference,
      counterPartyTypes {
        id,
        name
      },
      businessUnitId,
      businessUnit {
        id,
        name
      }
    }
  }
`;

export const DELETE_COUNTER_PARTY = gql`
  mutation deleteCounterParty($input: DeleteCounterPartyInput!) {
    deleteCounterParty(input: $input)
  }
`;
