import { gql } from '@apollo/client';
import { traderModel } from 'model';

export const getTradersFragment = `
  items {
    id,
    name,
    initials
  },
  totalCount
`;

export type GetTradersOutput = {
  traders?: {
    items?: [traderModel],
    totalCount?: number;
  }
};

export const GET_TRADERS = gql`
  query getTraders($take: Int!, $skip: Int!, $where: TraderFilterInput) {
    traders(take: $take, skip: $skip, where: $where) {
      ${getTradersFragment}
    }
  }
`;
