import { ReactNode, useEffect, useState } from 'react';
import navBarStyle from './NavBarStyles';
import { useNavigate } from 'react-router-dom';
import { MenuType } from './MenuType';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import {
  AppBar,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import {
  quotePath,
  dealPath,
  reportsPath,
  configurationsPath,
  bargeTripsPath,
  signInPath,
  maritimeDirectoriesPath
} from '../../shared/links';
import useDarkMode from '../../hooks/useDarkMode';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { LanguagePopover } from '../LanguagePopover';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import UserInfo from '../UserInfo';
import { useAuthService } from '../../hooks/useAuthServices';
import { useRecoilState } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { getGraphQLClient } from '../../api/graphql';

import { pageState } from 'state';
import {
  TXT_QUICK_CALCS,
  TXT_DEALS,
  TXT_LIGHT_THEME,
  TXT_DARK_THEME,
  TXT_REPORTS,
  TXT_CONFIGURATIONS,
  TXT_HELP,
  TXT_BARGE_TRIPS,
  TXT_SIGN_IN,
  TXT_MARITIME_DIRECTORIES
} from '../../../../../shared/translations';
import { Box } from '@mui/system';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BoltIcon from '@mui/icons-material/Bolt';
import SummarizeIcon from '@mui/icons-material/BarChart';

import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import { CargoIcon } from 'components';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

interface NavBarProps {
  children?: ReactNode;
}

export const NavBar = (props: NavBarProps) => {
  const classes = navBarStyle();
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useDarkMode();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [graphQLClient, setGraphQLClient] = useState(getGraphQLClient(auth.accessToken));

  const { isSignedIn } = useAuthService();

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    isSignedIn().then((result) => setShowDrawer(result.isSignedIn));
    if (!!!auth.accessToken) {
      return;
    }
    setGraphQLClient(getGraphQLClient(auth.accessToken));
  }, [auth.accessToken]);

  useEffect(() => {
    if (!showDrawer && open) {
      setOpen(false);
    }
  }, [showDrawer]);

  const handleDrawerOpenClose = () => setOpen((prev) => !prev);

  let menus: MenuType[] = [
    {
      key: 'deals',
      icon: <MonetizationOnIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_DEALS)),
      link: dealPath,
      order: 1
    },
    {
      key: 'bargeTrips',
      icon: <CargoIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_BARGE_TRIPS)),
      link: bargeTripsPath,
      order: 5
    },
    {
      key: 'maritimeDirectories',
      icon: <ImportContactsIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_MARITIME_DIRECTORIES)),
      link: maritimeDirectoriesPath,
      order: 6
    },
    {
      key: 'reports',
      icon: <SummarizeIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_REPORTS)),
      link: reportsPath,
      order: 7
    }
  ];

  if (!auth.isGuest) {
    menus = [
      ...menus,
      {
        key: 'quotes',
        icon: <BoltIcon />,
        text: capitalizeFirstLetterEveryword(t(TXT_QUICK_CALCS)),
        link: quotePath,
        order: 2
      }
    ];
  }

  menus = [
    ...menus.sort((a, b) => {
      if ((a.order || 0) < (b.order || 0)) {
        return -1;
      }
      return 1;
    })
  ];

  const bottomMenus: MenuType[] = [
    {
      key: 'help',
      icon: <HelpIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_HELP)),
      link: 'https://sites.google.com/atreuscommodities.com/help/home',
      order: 1
    },
    {
      key: 'config',
      icon: <SettingsIcon />,
      text: capitalizeFirstLetterEveryword(t(TXT_CONFIGURATIONS)),
      link: configurationsPath,
      order: 2
    }
  ];

  const onMenuClicked = (link: string) => navigate(link);
  let txtTheme = darkMode ? `${t(TXT_LIGHT_THEME)}` : `${t(TXT_DARK_THEME)}`;
  txtTheme = capitalizeFirstLetterEveryword(txtTheme);

  const [openedPage] = useRecoilState(pageState);

  useEffect(() => {
    let title = 'Atreus';
    if (openedPage && openedPage.title) {
      title = `${title} — ${capitalizeFirstLetterEveryword(openedPage.title)}`;
    }
    document.title = title;
  }, [openedPage]);

  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={classes.root}>
      <ApolloProvider client={graphQLClient}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" noWrap className={classes.title}>
              {openedPage.title}
            </Typography>
            <LanguagePopover />
            <Tooltip key="darkModeToogle" title={!open ? txtTheme : ''} placement="bottom" arrow>
              <IconButton onClick={() => setDarkMode(!darkMode)} size="large">
                {darkMode ? <BrightnessHighIcon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
            {showDrawer && auth.fullName && <UserInfo />}{' '}
            {!auth.accessToken && (
              <Button variant="text" color="inherit" sx={{ marginY: 2 }} onClick={() => navigate(signInPath)}>
                {capitalizeFirstLetterEveryword(t(TXT_SIGN_IN))}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {showDrawer && (
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
            }}
            open={open}
          >
            <div className={clsx(classes.menuContainer)}>
              <div>
                <List>
                  <ListItemButton key="btnLogo" onClick={handleDrawerOpenClose}>
                    <ListItemIcon>
                      <img src="/img/logo_orange.png" />
                    </ListItemIcon>
                    <ListItemText primary={'Atreus'} />
                  </ListItemButton>
                  {menus.map((menu) => (
                    <Tooltip key={menu.key} title={!open ? menu.text : ''} placement="right" arrow>
                      <ListItemButton key={menu.key} onClick={() => onMenuClicked(menu.link)}>
                        <ListItemIcon>{menu.icon}</ListItemIcon>
                        <ListItemText primary={menu.text} />
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </List>
              </div>
              <div>
                <List>
                  {bottomMenus.map((menu) => (
                    <Tooltip key={menu.key} title={!open ? menu.text : ''} placement="right" arrow>
                      <ListItemButton
                        key={menu.key}
                        onClick={() => (menu.key === 'help' ? openInNewTab(menu.link) : onMenuClicked(menu.link))}
                      >
                        <ListItemIcon>{menu.icon}</ListItemIcon>
                        <ListItemText primary={menu.text} />
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </List>
              </div>
            </div>
          </Drawer>
        )}
        <Box className={classes.content}>{props.children}</Box>
      </ApolloProvider>
    </div>
  );
};
