import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

const NumberTextFieldComponent = (props: TextFieldProps, ref: any) => {
  const handleOnWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.currentTarget.querySelector('input')?.blur();
  };

  return <TextField {...props} inputRef={ref} type="number" onWheel={handleOnWheel} />;
};

export const NumberTextField = forwardRef(NumberTextFieldComponent);
