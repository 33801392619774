import { AuthenticationResult } from '@azure/msal-browser';
import { useAzureAuthenticationContext } from './azure/useAzureAuthenticationContext';

export interface MicrosoftLoginParams {
  signinType: 'loginPopup' | 'loginRedirect';
  text?: string;
  onReturnedAccountInfo: (authenticationResult: AuthenticationResult) => void;
  preSignIn?: () => void;
  postSignIn?: () => void;
}

export type microsoftTokenClaims = {
  sub: string;
};

export const useMicrosoftLogin = () => {
  const azureContext = useAzureAuthenticationContext();
  return (params: MicrosoftLoginParams) => {
    azureContext.signIn(params.signinType, params.onReturnedAccountInfo, params.preSignIn, params.postSignIn);
  };
};
