import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const usePortsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    content: {
      padding: theme.spacing(2)
    },
    filterBar: {
      display: 'flex',
      flexDirection: 'row'
    }
  })
);
