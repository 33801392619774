import { useAxios } from 'hooks';
import { FileMaxSizeInMB } from 'shared/constants';
import { analyzeOutput } from './analyzeOutput';

export const useDocumentApi = () => {
  const api = '/api/document';
  const { create } = useAxios();

  const analyze = async (file: File | null, uploadCallback?: (progress: number) => void) => {
    if (!file || file === null || file.size <= 0) {
      return;
    }

    const formData = new FormData();
    if (file.size > FileMaxSizeInMB) {
      throw Error('File too big');
    }
    formData.append('file', file);

    const axios = await create();
    const response = await axios.post<analyzeOutput>(`${api}/analyze/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'json',
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadCallback && uploadCallback(percentage);
      }
    });

    return response;
  };

  return {
    analyze
  };
};
