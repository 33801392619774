import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Step,
  StepButton,
  Stepper,
  Toolbar,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useForm, SubmitHandler, FormProvider, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ignoreTimeZone } from 'shared/utils';
import _, { capitalize } from 'lodash';
import { AddBargeTripInput, UPDATE_BARGE_TRIP, ADD_BARGE_TRIP, GetBargeTripOutput, GET_BARGE_TRIP } from 'api';
import { useApolloErrorHandler, useDefaultSettings } from 'hooks';
import {
  TXT_UPDATE_BARGE_TRIP,
  TXT_CREATE_BARGE_TRIP,
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE,
  TXT_BARGE_TRIP_INFO,
  TXT_OWNER,
  TXT_RECEIVER,
  TXT_CREATE_ANOTHER
} from '../../../../../../shared/translations';
import { ReactNode, useEffect, useState } from 'react';
import { BargeTripEditorInfo } from './BargeTripEditorInfo';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { BargeTripEditorCounterparty } from './BargeTripEditorCounterparty';
import useAuth from 'hooks/useAuth';

interface stepperItem {
  label: string;
  component: ReactNode;
  error?: () => boolean;
}

export interface BargeTripEditorProps {
  id?: string;
  onClose: () => void;
  afterSave: () => void;
}

const bargeTripLoadingPortSchema = yup.object().shape({
  strId: yup.string().required(),
  portId: yup.string().required(),
  arrivalTime: yup.date().required(),
  completedTime: yup.date().nullable(),
  releaseTime: yup.date().nullable()
});

const bargeTripUnloadingPortSchema = yup.object().shape({
  strId: yup.string().required(),
  portId: yup.string().required(),
  arrivalTime: yup.date().nullable(),
  completedTime: yup.date().nullable(),
  releaseTime: yup.date().nullable()
});

const bargeTripHolidaySchema = yup.object().shape({
  strId: yup.string().required(),
  holiday: yup.date().required()
});

const bargeTripCounterPartySchema = yup.object().shape({
  strId: yup.string().required(),
  counterPartyId: yup.string().required(),
  freeDays: yup.number().required().moreThan(-1),
  demurrageRate: yup.number().required(),
  status: yup.string().required(),
  statusComment: yup.string().nullable(),
  calendarRule: yup.string().required(),
  holidays: yup.array().of(bargeTripHolidaySchema),
  unloadingArrivalTimeOverride: yup.date().nullable(),
  unloadingCompletedTimeOverride: yup.date().nullable(),
  unloadingReleaseTimeOverride: yup.date().nullable()
});

const schema = yup.object().shape({
  bargeId: yup.string().required(),
  vesselId: yup.string().nullable(),
  owner: bargeTripCounterPartySchema,
  receiver: bargeTripCounterPartySchema,
  loadingPort: bargeTripLoadingPortSchema,
  unloadingPort: bargeTripUnloadingPortSchema,
  splitQuantityName: yup.string().nullable(),
  productId: yup.string().required(),
  currency: yup.string().required(),
  quantity: yup.number().nullable().required(),
  trafficAnalystId: yup.string().required(),
  massUnit: yup.string().required()
});

export const BargeTripEditor = (props: BargeTripEditorProps) => {
  const [defaultSettings] = useDefaultSettings();
  const [defaultValues, setDefaultValues] = useState<AddBargeTripInput>({
    currency: 'USD',
    massUnit: 'MT',
    owner: {
      strId: 'new-owner',
      freeDays: 4,
      calendarRule: 'SSHINC'
    },
    receiver: {
      strId: 'new-receiver',
      freeDays: 4,
      calendarRule: 'SSHINC'
    },
    loadingPort: {
      strId: 'new-loading-port',
      arrivalTime: new Date(),
      completedTime: new Date()
    },
    unloadingPort: {
      strId: 'new-unloading-port',
      arrivalTime: new Date(),
      completedTime: new Date()
    },
    trafficAnalyst: defaultSettings.trafficSpecialist,
    trafficAnalystId: defaultSettings.trafficSpecialist?.id,
    creatingDuplicate: false
  });
  const methods = useForm<AddBargeTripInput>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [activeStep, setActiveStep] = useState(0);
  const [createAnother, setCreateAnother] = useState<boolean>(false);
  const theme = useTheme();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const [getBargeTrip, { loading }] = useLazyQuery<GetBargeTripOutput>(GET_BARGE_TRIP, {
    fetchPolicy: 'network-only',
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      const { bargeTrip } = data;
      setDefaultValues(() => ({
        ...bargeTrip,
        creatingDuplicate: false,
        owner: {
          ..._.omit(bargeTrip.owner, ['id']),
          strId: bargeTrip.ownerId!,
          holidays: (bargeTrip.owner?.holidays || []).map((holiday) => ({
            ..._.omit(holiday, ['id']),
            strId: holiday.id!
          }))
        },
        receiver: {
          ..._.omit(bargeTrip.receiver, ['id']),
          strId: bargeTrip.receiverId!,
          holidays: (bargeTrip.receiver?.holidays || []).map((holiday) => ({
            ..._.omit(holiday, ['id']),
            strId: holiday.id!
          }))
        },
        loadingPort: {
          ..._.omit(bargeTrip.loadingPort, ['id']),
          strId: bargeTrip.loadingPortId!
        },
        unloadingPort: {
          ..._.omit(bargeTrip.unloadingPort, ['id']),
          strId: bargeTrip.unloadingPortId!
        }
      }));
    }
  });

  const resetFormForDuplicateBarge = () => {
    methods.setValue('creatingDuplicate', true);
    methods.setValue('bargeId', '');
    setTimeout(() => {
      methods.setValue('creatingDuplicate', false, {
        shouldDirty: true,
        shouldValidate: true
      });
    }, 1000);
  };

  const [mutateBargeTrip, { loading: isSaving }] = useMutation(props.id ? UPDATE_BARGE_TRIP : ADD_BARGE_TRIP, {
    onError: (error) => apolloErrorHandler(error),
    onCompleted: () => {
      props.afterSave();
      if (!createAnother) {
        props.onClose();
      } else {
        resetFormForDuplicateBarge();
      }
    }
  });

  const onSubmit: SubmitHandler<AddBargeTripInput> = async (data) => {
    await mutate(data);
  };

  const mutate = (input: AddBargeTripInput) => {
    input = {
      ..._.omit(input, ['barge', 'trafficAnalyst', 'vessel']),
      owner: {
        ..._.omit(input.owner, ['counterParty']),
        holidays: (input.owner.holidays || []).map((item) => ({
          ...item,
          holiday: ignoreTimeZone(item.holiday)
        }))
      },
      receiver: {
        ..._.omit(input.receiver, ['counterParty']),
        holidays: (input.receiver.holidays || []).map((item) => ({
          ...item,
          holiday: ignoreTimeZone(item.holiday)
        }))
      },
      loadingPort: {
        ..._.omit(input.loadingPort, ['port']),
        arrivalTime: ignoreTimeZone(input.loadingPort.arrivalTime),
        completedTime: ignoreTimeZone(input.loadingPort.completedTime),
        releaseTime: ignoreTimeZone(input.loadingPort.releaseTime)
      },
      unloadingPort: {
        ..._.omit(input.unloadingPort, ['port']),
        arrivalTime: ignoreTimeZone(input.unloadingPort.arrivalTime),
        completedTime: ignoreTimeZone(input.unloadingPort.completedTime),
        releaseTime: ignoreTimeZone(input.unloadingPort.releaseTime)
      }
    };
    if (!props.id) {
      return mutateBargeTrip({
        variables: {
          input
        }
      });
    }

    return mutateBargeTrip({
      variables: {
        input: {
          id: props.id,
          ...input
        }
      }
    });
  };

  const title = t(props.id ? TXT_UPDATE_BARGE_TRIP : TXT_CREATE_BARGE_TRIP);

  const steps: stepperItem[] = [
    {
      label: TXT_BARGE_TRIP_INFO,
      component: <BargeTripEditorInfo disabled={isSaving || isGuest} />,
      error: () => !_.isEmpty(_.omit(methods.formState.errors, ['loadingPort', 'unloadingPort', 'owner', 'receiver']))
    },
    {
      label: TXT_OWNER,
      component: <BargeTripEditorCounterparty isOwner disabled={isSaving || isGuest} />,
      error: () => !_.isEmpty(_.pick(methods.formState.errors, ['owner']))
    },
    {
      label: TXT_RECEIVER,
      component: <BargeTripEditorCounterparty {...props} disabled={isSaving || isGuest} />,
      error: () => !_.isEmpty(_.pick(methods.formState.errors, ['receiver']))
    }
  ];

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const watchCreatingDuplicate = useWatch({
    control: methods.control,
    name: 'creatingDuplicate'
  });

  useEffect(() => {
    if (!!props.id) {
      getBargeTrip({
        variables: {
          id: props.id
        }
      });
    }
  }, [props.id]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
      PaperProps={{
        sx: {
          height: '98vh'
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogTitle>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map((value, index) => {
            return (
              <Step key={`barge-trip-editor-${index}`}>
                <StepButton onClick={handleStep(index)} style={{ justifyContent: 'center' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {value.error && !!value.error() && <WarningIcon style={{ color: 'red' }}></WarningIcon>}
                    <div>{t(value.label).toUpperCase()}</div>
                  </Stack>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              id="bargeTrip-editor-form"
              style={{
                marginTop: theme.spacing(1)
              }}
            >
              {steps.map((value, index) => {
                return (
                  <div key={`barge-trip-editor-detail-${index}`} hidden={activeStep !== index}>
                    {value.component}
                  </div>
                );
              })}
            </form>
          </FormProvider>
        )}
      </DialogContent>
      <DialogActions>
        {!props.id && !loading && !isGuest && (
          <div>
            {capitalize(t(TXT_CREATE_ANOTHER))}
            <Checkbox checked={createAnother} onChange={(x) => setCreateAnother(x.target.checked)}></Checkbox>
          </div>
        )}
        <Button onClick={() => props.onClose()} color="secondary" disabled={isSaving || loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="bargeTrip-editor-form" disabled={isSaving || loading || isGuest}>
          {(isSaving || watchCreatingDuplicate) && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(isSaving || watchCreatingDuplicate ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
