import { gql } from '@apollo/client';
import { vesselModel } from 'model';

export interface AddVesselInput extends Omit<vesselModel, 'id'> { }

export interface UpdateVesselInput extends AddVesselInput {
  id: string;
}

export interface DeleteVesselInput {
  id: string;
}

export interface VesselMutationResult {
  vessel: vesselModel;
}

export const vesselFragment = `
    id
    name
    imo
    vesselType
`;

export const ADD_VESSEL = gql`
  mutation addVesel($input: AddVesselInput!) {
    vessel: addVessel(input: $input) {
      ${vesselFragment}
    }
  }
`;

export const UPDATE_VESSEL = gql`
  mutation updateVessel($input: UpdateVesselInput!) {
    vessel: updateVessel(input: $input) {
      ${vesselFragment}
    }
  }
`;

export const DELETE_VESSEL = gql`
  mutation deleteVesel($input: DeleteVesselInput!) {
    deleteVessel(input: $input)
  }
`;
