import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { invitation, registerUser, registerUserInput } from '../../api';
import { CustomError, RegisterUser } from '../../components';
import useAuth from '../../hooks/useAuth';
import { invitationModel } from '../../model';
import { dashboardPath, signInPath } from '../../shared/links';
import _ from 'lodash';
import { TXT_GO_TO_SIGN_IN_PAGE, TXT_INVITATION_NOT_FOUND, TXT_REGISTER, TXT_REGISTERING } from '../../../../../shared/translations';
import invitationStyle from './invitationStyle';

export const Invitation = () => {
  const urlQuery = new URLSearchParams(useLocation().search);
  const [invitationId] = useState(urlQuery.get('invitationId'));
  const [invitationData, setInvitationData] = useState<invitationModel | undefined>(undefined);
  const [isLoading, setIsloading] = useState(true);
  const [userInput, setUserInput] = useState<registerUserInput>({});
  const [isUserValid, setIsUserValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [, setAuthState] = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = invitationStyle();

  const onUserChanged = (input: registerUserInput) => {
    setUserInput({
      ...input
    });
  };

  const onUserValidated = (isValid: boolean) => {
    setIsUserValid(isValid);
  };

  const goToSignInPage = () => {
    navigate(signInPath);
  };

  const register = () => {
    setIsSaving(true);
    registerUser({
      ...userInput,
      tenantId: invitationData?.tenantId
    }).then((response) => {
      setIsSaving(false);
      setAuthState({
        ...response
      });
      setErrorMessage(response.errorMessage);

      if (!!!response.errorMessage) {
        navigate(dashboardPath);
      }
    });
  };

  useEffect(() => {
    if (invitationId === null) {
      setIsloading(false);
      return;
    }

    invitation(invitationId).then((data) => {
      setInvitationData(data);
      setIsloading(false);
    });
  }, [invitationId]);

  useEffect(() => {
    setUserInput({
      ...userInput,
      username: invitationData?.email
    });
  }, [invitationData]);

  return (
    <Container
      component="main"
      maxWidth='sm'
    >
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {
            isLoading &&
            <Grid item xs={12} style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <CircularProgress />
            </Grid>
          }
          {
            !!!isLoading && !!!invitationData &&
            <Grid item xs={12}>
              <CustomError>
                <Typography variant='body1'>
                  {_.capitalize(t(TXT_INVITATION_NOT_FOUND))}
                </Typography>
              </CustomError>
            </Grid>
          }
          {
            !!!isLoading && !!errorMessage &&
            <Grid item xs={12}>
              <CustomError>
                <Typography variant='body1'>
                  {_.capitalize(t(errorMessage))}
                </Typography>
              </CustomError>
            </Grid>
          }
          {
            !!!isLoading && invitationData &&
            <>
              <Grid item xs={12}>
                <RegisterUser
                  email={invitationData.email}
                  onChanged={onUserChanged}
                  onValidated={onUserValidated}
                  disabled={isSaving}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!isUserValid || isSaving}
                  variant='contained'
                  fullWidth
                  color='secondary'
                  onClick={register}
                >
                  {isSaving && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
                  {t(isSaving ? TXT_REGISTERING : TXT_REGISTER)}
                </Button>
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={goToSignInPage}
              disabled={isSaving}>
              {t(TXT_GO_TO_SIGN_IN_PAGE)}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
