import { Box, Container } from '@mui/material';
import { useParams } from 'react-router';
import { useGlobalStyles } from '../globalStyles';
import { DemurrageContent } from './DemurrageContent';
import { ListComponent } from './ListComponent';
import { DemurragePageProvider } from './DemurragePageContext';

export const DemurragePage = () => {
  const globalStyles = useGlobalStyles();
  const { dealId } = useParams();

  return (
    <DemurragePageProvider dealId={dealId}>
      <Container component="main" maxWidth="xl" className={globalStyles.container}>
        <Box>
          <Box
            height="calc(100vh - 80px)"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            mr={['-16px', '-24px']}
          >
            <ListComponent />
            <Box sx={{ p: 3 }} overflow="auto">
              <DemurrageContent />
            </Box>
          </Box>
        </Box>
      </Container>
    </DemurragePageProvider>
  );
};
