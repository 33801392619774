import {
  TXT_COUNTERPARTY,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING,

  TXT_OPEN_WHAT_IF_SCENARIO,

  TXT_PRODUCT, TXT_QUANTITY, TXT_QUICK_CALC, TXT_UNLOADING, TXT_VESSEL
} from '../../../../../../shared/translations';

export interface QuoteColumn {
  id: 'name' | 'counterParty' | 'vessel' | 'product' | 'loadingPort' | 'unloadingPort' | 'quantity' | 'goTo' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

const defaultColumns: QuoteColumn[] = [
  {
    id: 'name',
    label: TXT_QUICK_CALC,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'counterParty',
    label: TXT_COUNTERPARTY,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'vessel',
    label: TXT_VESSEL,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'product',
    label: TXT_PRODUCT,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'loadingPort',
    label: TXT_LOADING,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'unloadingPort',
    label: TXT_UNLOADING,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'quantity',
    label: TXT_QUANTITY,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'goTo',
    label: TXT_OPEN_WHAT_IF_SCENARIO,
    maxWidth: '5%'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];

export const quoteColumns = (showGoTo: boolean) => {
  if (showGoTo) {
    return defaultColumns;
  }
  return defaultColumns.filter((x) => x.id !== 'goTo');
};
