import { gql } from '@apollo/client';
import { applicationUserModel, SecurityRoles } from 'model';
import { getUsersFragment } from '.';

export interface DeleteUserInput {
  id: string;
}

export interface ChangeEnablementInput {
  userId: string;
  isEnabled: boolean;
}

export interface AllowVesselPortalInput {
  userId: string;
  allowVesselPortal: boolean;
}

export interface AllowOCRInput {
  userId: string;
  allowOCR: boolean;
}

export interface UpdateUserInput {
  id: string;
  username: string;
  fullName: string;
  initial: string;
  allowVesselPortal: boolean;
  allowOCR: boolean;
  securityRole: SecurityRoles;
  isEnabled: boolean;
  hasMicrosoftAccountConnected: boolean;
  newMicrosoftAccount?: string;
  newPassword?: string;
  reNewPassword?: string;
  linkedMicrosoftAccount?: string;
  reference?: string;
}

export const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

export const CHANGE_USER_ENABLEMENT = gql`
  mutation changeUserEnablement($input: ChangeEnablementInput!) {
    changeUserEnablement(input: $input) {
      ${getUsersFragment}
    }
  }
`;

export const ALLOW_VESSEL_PORTAL = gql`
  mutation allowVesselPortal($input: AllowVesselPortalInput!) {
    allowVesselPortal(input: $input) {
      ${getUsersFragment}
    }
  }
`;

export const ALLOW_OCR = gql`
  mutation allowOCR($input: AllowOCRInput!) {
    allowOCR(input: $input) {
      ${getUsersFragment}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id,
      username,
      isEnabled,
      securityRole,
      profile {
        fullName,
        initial,
        microsoftSub,
        reference
      }
    }
  }
`;

export interface UpdateUserOutput {
  updateUser: applicationUserModel;
}
