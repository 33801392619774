import { Typography, IconButton, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useDemurragePageState } from '../DemurragePageContext';
import { getCounterPartyLabel, getDemurrageCalculation } from '../utils';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_DEMURRAGE, TXT_DESPATCH, TXT_PRINT, TXT_VERSION } from '../../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { PrintPreview } from './PrintPreview';
import { formatCurrency, getStatusName } from 'shared/utils';
import { CommentButton, CopyCalculation, DeleteCalculation, PasteCalculation, QuickLaytimeCalc } from 'components';
import { laytimeCalculationCommentModel } from 'model';
import {
  ADD_LAYTIME_CALCULATION_COMMENT,
  DELETE_LAYTIME_CALCULATION_COMMENT,
  GET_LAYTIME_CALCULATION_COMMENTS,
  GET_LAYTIME_CALCULATION_COMMENT_COUNT,
  LAYTIME_CALCULATION_COMMENT_ADDED,
  LAYTIME_CALCULATION_COMMENT_DELETED,
  LAYTIME_CALCULATION_COMMENT_UPDATED,
  UPDATE_LAYTIME_CALCULATION_COMMENT
} from 'api';
import { useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

interface InfoCardProps {
  calculationId: string;
}
export const InfoCard = ({ calculationId }: InfoCardProps) => {
  const { demurrageDeal, selectedTab } = useDemurragePageState();
  const [showButtons, setShowButtons] = useState<boolean>();
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const calcId = searchParams.get('calcId');
  const commentId = searchParams.get('commentId');
  const [auth] = useAuth();
  const { isGuest } = auth;

  const printToggle = () => setShowPrint((prev) => !prev);

  const calculation = demurrageDeal ? getDemurrageCalculation(calculationId, demurrageDeal) : undefined;

  const getCounterPartyText = () => {
    let txt = getCounterPartyLabel(calculation?.counterPartyType?.name);
    if (!!demurrageDeal?.isQuote) {
      txt = `${txt} (${capitalizeFirstLetterEveryword(t(TXT_VERSION))}: ${calculation?.version})`;
    }
    return txt;
  };

  const txtDemurrage = capitalizeFirstLetterEveryword(t(TXT_DEMURRAGE));
  const txtDespatch = capitalizeFirstLetterEveryword(t(TXT_DESPATCH));
  const isDemurrage = calculation?.laytimeCalculationResult === 'DEMURRAGE';
  const calcValue = isDemurrage ? calculation?.demurrageAmount : calculation?.despatchAmount;
  const formattedValue = formatCurrency(calcValue || 0, calculation?.currency || 'USD');
  const counterPartyName = calculation?.counterParty?.name || 'TBD';
  const calcName = [...new Set(calculation?.splitQuantities?.map((split) => split.product?.name || ''))].join(', ');

  return (
    <>
      <PrintPreview open={showPrint} handleClose={printToggle} />
      <Box width="100%" onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
        <Box width="100%" minHeight="120px" display="flex" alignItems="center">
          <Box width="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="row" columnGap={1} alignItems="center">
                <Typography variant="caption">{getCounterPartyText()}</Typography>
                <CommentButton<laytimeCalculationCommentModel>
                  pageTitle={
                    <Stack direction="column" alignItems="center">
                      <Typography>{`${getCounterPartyText()} — ${counterPartyName}`}</Typography>
                      <Typography variant="caption" fontStyle="italic" color="text.success">
                        {t(selectedTab).toUpperCase()}
                      </Typography>
                    </Stack>
                  }
                  entityId={calculationId}
                  getCountQuery={GET_LAYTIME_CALCULATION_COMMENT_COUNT}
                  getCommentsQuery={GET_LAYTIME_CALCULATION_COMMENTS}
                  addCommentMutation={ADD_LAYTIME_CALCULATION_COMMENT}
                  updateCommentMutation={UPDATE_LAYTIME_CALCULATION_COMMENT}
                  deleteCommentMutation={DELETE_LAYTIME_CALCULATION_COMMENT}
                  commentAddedSubscription={LAYTIME_CALCULATION_COMMENT_ADDED}
                  commentUpdatedSubscription={LAYTIME_CALCULATION_COMMENT_UPDATED}
                  commentDeletedSubscription={LAYTIME_CALCULATION_COMMENT_DELETED}
                  size="small"
                  open={calculationId === calcId}
                  goToCommentId={commentId || undefined}
                  disabled={isGuest}
                />
              </Box>
              {!isGuest && (
                <Stack direction="row" spacing={1} visibility={showButtons ? 'visible' : 'hidden'}>
                  {calculation ? <DeleteCalculation calculation={calculation} /> : null}
                  <CopyCalculation calculationId={calculationId} />
                  <PasteCalculation calculationId={calculationId} />
                </Stack>
              )}
            </Box>
            <Typography variant="h6">{counterPartyName}</Typography>
            <Typography variant="subtitle1">{calcName}</Typography>
            <Typography variant="subtitle1">
              {`${isDemurrage ? txtDemurrage : txtDespatch}:  `}
              {formattedValue}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1}>
                {!demurrageDeal?.isQuote && !isGuest ? (
                  <QuickLaytimeCalc
                    laytimeCalcId={calculationId}
                    isLoading={selectedTab === 'loading'}
                    title={`${getCounterPartyLabel(calculation?.counterPartyType?.name)} — ${counterPartyName}`}
                  />
                ) : null}
                <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_PRINT))}>
                  <IconButton aria-label="print" size="small" onClick={printToggle}>
                    <PrintIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Typography variant="caption" textAlign="right" display="block">
                {getStatusName(calculation?.status ?? 'DRAFT', t)}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
