import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Container, Grid, useTheme } from '@mui/material';
import { UpdateUserInput, UpdateUserOutput, UPDATE_USER } from 'api';
import { CustomError } from 'components';
import { useApolloErrorHandler, useTitle } from 'hooks';
import useAuth from 'hooks/useAuth';
import { t } from 'i18next';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { dashboardPath } from 'shared/links';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_SAVING, TXT_SAVE, TXT_USER_PROFILE_UPDATED_SUCCESSFULLY, TXT_UPDATE_MY_PROFILE } from '../../../../../../shared/translations';
import { userProfileFormSchema, UserProfileContent } from '../UserProfileContent';

export const UserProfile = () => {
  useTitle(TXT_UPDATE_MY_PROFILE);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [auth, setAuth] = useAuth();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const form = useForm<UpdateUserInput>({
    resolver: yupResolver(userProfileFormSchema),
    defaultValues: {
      id: auth.id
    }
  });

  const { handleSubmit } = form;

  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutate, { loading }] = useMutation<UpdateUserOutput>(UPDATE_USER, {
    onCompleted: (data) => {
      setAuth({
        ...auth,
        fullName: data.updateUser.profile?.fullName,
        isAdmin: data.updateUser.securityRole === 'ADMIN',
        isOperator: data.updateUser.securityRole === 'USER',
        isGuest: data.updateUser.securityRole === 'GUEST',
        hasMicrosoftAccountConnected: !!data.updateUser.profile?.microsoftSub
      });
      enqueueSnackbar(capitalizeFirstLetterEveryword(TXT_USER_PROFILE_UPDATED_SUCCESSFULLY), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
      navigate(dashboardPath);
    },
    onError: (error) => {
      if (error && error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions?.message) {
        setErrorMessage(_.capitalize(t(error.graphQLErrors[0].extensions.message as string)));
      }
      apolloErrorHandler(error);
    }
  });

  const onSave: SubmitHandler<UpdateUserInput> = (input) => {
    setErrorMessage(undefined);
    mutate({
      variables: {
        input: { ..._.omit(input, 'reNewPassword') }
      }
    });
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        marginTop: theme.spacing(10)
      }}
    >
      <Grid container direction="row-reverse" spacing={2}>
        {errorMessage && (
          <Grid item xs={12}>
            <CustomError>{errorMessage}</CustomError>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormProvider {...form}>
            <form id="frm-update--profile" onSubmit={handleSubmit(onSave)}>
              <UserProfileContent
                id={auth.id || ''}
                {...form}
                disableAllowVesselPortal
                disableSecurityRoles
                disableIsEnabled
                isLoadingUserChanged={setIsLoadingUser}
                disabled={loading}
              />
            </form>
          </FormProvider>
        </Grid>
        <Grid item xs={2}>
          {!isLoadingUser && (
            <Button
              type="submit"
              form="frm-update--profile"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
            >
              {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
              {t(loading ? TXT_SAVING : TXT_SAVE)}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
