import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { statementOfFactStatus } from 'model';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getSOFStatusName } from 'shared/utils';
import { TXT_STATUS } from '../../../../../shared/translations';

interface StatementOfFactStatusSelectorProps {
  value?: statementOfFactStatus;
  onChanged?: (newStatus: statementOfFactStatus) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
  hideLabel?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
}

export const StatementOfFactStatusSelector = (props: StatementOfFactStatusSelectorProps) => {
  const id = props.id || 'status';
  const labelId = `${id}-lbl-status`;

  const { t } = useTranslation();

  const handleStatusChange = (e: any) => {
    props.onChanged && props.onChanged(e.target.value);
  };

  const lblStatus = !!props.hideLabel ? '' : capitalizeFirstLetterEveryword(t(props.label || TXT_STATUS));

  return (
    <FormControl fullWidth size="medium" style={props.style} error={props.error}>
      <InputLabel id={labelId}>{lblStatus}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={lblStatus}
        value={props.value ?? ''}
        disabled={props.disabled}
        onChange={handleStatusChange}
      >
        <MenuItem value={'DRAFT'}>{getSOFStatusName('DRAFT', t)}</MenuItem>
        <MenuItem value={'READY_TO_USE'}>{getSOFStatusName('READY_TO_USE', t)}</MenuItem>
        <MenuItem value={'USED_BY_LAYTIME_CALCULATION'}>{getSOFStatusName('USED_BY_LAYTIME_CALCULATION', t)}</MenuItem>
      </Select>
    </FormControl>
  );
};
