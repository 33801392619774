import { Stack, TablePagination } from '@mui/material';
import { usePaginationBarStyles } from './paginationBarStyles';
import { ReactNode } from 'react';
import { AddButton } from 'components/AddButton';

export interface PaginationBarProps {
  rowsPerPageOptions?: (
    | number
    | {
        value: number;
        label: string;
      }
  )[];
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  className?: string;
  children?: ReactNode;
  openAddEditor?: () => void;
  disabled?: boolean
}

export const PaginationBar = (props: PaginationBarProps) => {
  const { children, openAddEditor, ...rest } = props;
  const classes = usePaginationBarStyles();

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      { openAddEditor ?
        (<AddButton disabled={props.disabled} openAddEditor={openAddEditor} ></AddButton>) : null
      }
      <div>
        {children}
      </div>
      <TablePagination
        {...rest}
        component="div"
        rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 25, 100]}
        className={props.className || classes.pagingBar}
      />
    </Stack>
  );
};
