import { Dialog, AppBar, Toolbar, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { CalculationStatusSelector } from 'components';
import { CalculationStatusCommentSelector } from 'components/CalculationStatusCommentSelector';
import { LaytimeCalculationStatus } from 'model';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_CANCEL, TXT_SAVE, TXT_CHANGE_CALCULATION_STATUS } from '../../../../../../shared/translations';
import { setProperty, useDemurragePageDispatch } from '../DemurragePageContext';

interface ChangeStatusEditorProps {
  oldStatus: LaytimeCalculationStatus;
  oldComment: string;
  onClose: () => void;
}

export const ChangeStatusEditor = (props: ChangeStatusEditorProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(props.oldStatus);
  const [statusComment, setStatusComment] = useState(props.oldComment);
  const dispatch = useDemurragePageDispatch();

  const title = t(TXT_CHANGE_CALCULATION_STATUS);

  const onSave = () => {
    dispatch(setProperty('status', status));
    dispatch(setProperty('statusComment', statusComment));
    props.onClose();
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <Stack direction="column" spacing={3}>
          <CalculationStatusSelector value={status} onChanged={(newStatus) => setStatus(newStatus)}/>
          <CalculationStatusCommentSelector value={statusComment} onChanged={(newComment) => setStatusComment(newComment)}/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          {t(TXT_CANCEL)}
        </Button>
        <Button onClick={onSave}>{t(TXT_SAVE)}</Button>
      </DialogActions>
    </Dialog>
  );
};
