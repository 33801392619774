import axios from 'axios';
import { apiBaseUrl } from '../../../shared/links';
import { verificationCodeInput } from './verificationCodeInput';
import { verificationCodeOutput } from 'api';

export const verifyCode = async (input: verificationCodeInput) => {
  const url = `${apiBaseUrl}/api/user/verifyCode`;
  try {
    const response = await axios.post<verificationCodeOutput>(url, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};
