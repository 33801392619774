import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GetUsersOutput, GET_USERS_SELECTOR } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_USER } from '../../../../../shared/translations';
import { applicationUserModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';

export interface UserSelectorProps {
  id?: string;
  user?: applicationUserModel;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  onSelectedChange?: (user: applicationUserModel | null) => void;
  onChanged?: (user?: applicationUserModel) => void;
}

export const UserSelector = (props: UserSelectorProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<applicationUserModel[]>([]);
  const [text, setText] = useState<string | undefined>(undefined);
  const [selected, setSelected] = useState<applicationUserModel | null>(props.user || null);

  const { apolloErrorHandler } = useApolloErrorHandler();
  const getFilter = () => {
    if (_.isNil(text) || text.length <= 0) {
      return null;
    }

    return {
      profile: {
        fullName: {
          contains: text
        }
      }

    };
  };

  const [getUsers, { data: userData, loading, refetch }] = useLazyQuery<GetUsersOutput>(GET_USERS_SELECTOR, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: 5,
      skip: 0
    },
    onError: apolloErrorHandler
  });

  const onSelectedChange = (event: object, value: applicationUserModel | null) => {
    setSelected(value);
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  const label = capitalizeFirstLetterEveryword(t(props.label || TXT_USER));

  useEffect(() => {
    if ((userData?.users?.items?.length || 0) > 0) {
      const userOptions: applicationUserModel[] = (userData?.users?.items || []).map((user) => ({
        id: user.id,
        profile: {
          fullName: user.profile?.fullName
        }
      }));
      setOptions(_.uniqBy(userOptions, 'id'));
    }
  }, [userData]);


  useEffect(() => {
    fetch();
  }, [text]);

  const fetch = () => {
    if (!refetch) {
      getUsers();
    } else {
      refetch();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };


  useEffect(() => {
    setSelected(() => props.user ?? null);
  }, [props.user]);

  return (
    <Autocomplete
      value={selected}
      id={props.id || 'user-selector'}
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.profile?.fullName || ''}
      options={options}
      loading={loading}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
