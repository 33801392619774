import { useLazyQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { QuoteWizard } from 'components/QuoteWizard';
import { useGuestGuard, useTitle } from 'hooks';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ACTIVE,
  TXT_ALL,
  TXT_CLOSED,
  TXT_QUICK_CALCS,
  TXT_SEARCH_BY_NAME,
  TXT_STATUS,
  TXT_TRAFFIC_ANALYST
} from '../../../../../shared/translations';
import { GET_DEALS, GetDealsOutput } from '../../api';
import { UserSelector } from '../../components';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { applicationUserModel } from '../../model';
import { useGlobalStyles } from '../globalStyles';
import { useQuoteStyle } from './quoteStyle';
import { QuoteTable } from './QuoteTable';

export interface QuoteProps {}

export const Quotes = (props: QuoteProps) => {
  useGuestGuard();
  const dealStyle = useQuoteStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  useTitle(t(TXT_QUICK_CALCS));
  const [nameFilter, setNameFilter] = useState('');
  const [defaultSettings, setDefaultSettings] = useDefaultSettings();
  const [analystFilter, setAnalystFilter] = useState<applicationUserModel | undefined>(
    defaultSettings.trafficSpecialist
  );
  const [status, setStatus] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);
  const [openEditor, setOpenEditor] = useState(false);

  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let result: any[] = [];

    if (nameFilter.length > 0) {
      result = [
        ...result,
        {
          or: [
            {
              name: {
                contains: nameFilter
              }
            },
            {
              vessel: {
                name: {
                  contains: nameFilter
                }
              }
            },
            {
              splitQuantities: {
                some: {
                  name: {
                    contains: nameFilter
                  }
                }
              }
            }
          ]
        }
      ];
    }

    if (analystFilter) {
      result = [
        ...result,
        {
          splitQuantities: {
            some: {
              trafficSpecialistId: {
                eq: analystFilter.id
              }
            }
          }
        }
      ];
    }

    if (status !== 0) {
      result = [
        ...result,
        {
          isActive: {
            eq: status === 1
          }
        }
      ];
    }

    result = [
      ...result,
      {
        isQuote: {
          eq: true
        }
      }
    ];

    return result.length <= 0 ? null : { and: result };
  };

  const [getDeals, { loading, error, data }] = useLazyQuery<GetDealsOutput>(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: getFilter()
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    setNameFilter('');
  }, [t]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      trafficSpecialist: analystFilter
    });
  }, [analystFilter]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
  }, [nameFilter, analystFilter]);

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageSize(pageSize);
    setPageNumber(1);
  };

  const openCloseEditor = () => setOpenEditor((prev) => !prev);

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    setStatus(+event.target.value);
  };

  const lblStatus = capitalizeFirstLetterEveryword(t(TXT_STATUS));

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <QuoteWizard onClose={openCloseEditor} afterSave={getDeals} />}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={dealStyle.content}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={2} xl={2}>
                <FormControl fullWidth>
                  <InputLabel id="lbl-search-deal-status">{lblStatus}</InputLabel>
                  <Select
                    labelId="dlbl-search-deal-status"
                    id="demo-simple-select"
                    value={status}
                    label={lblStatus}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={0}>{capitalizeFirstLetterEveryword(t(TXT_ALL))}</MenuItem>
                    <MenuItem value={1}>{capitalizeFirstLetterEveryword(t(TXT_ACTIVE))}</MenuItem>
                    <MenuItem value={2}>{capitalizeFirstLetterEveryword(t(TXT_CLOSED))}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5} xl={5}>
                <UserSelector
                  id="analyst-filter"
                  user={analystFilter}
                  label={TXT_TRAFFIC_ANALYST}
                  onChanged={(user) => {
                    setAnalystFilter(user === null ? undefined : user);
                  }}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={5} xl={5}>
                <TextField
                  id="deal-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <QuoteTable
                  loading={loading}
                  error={error}
                  data={data}
                  afterSave={getDeals}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  onPageChanged={handleChangePage}
                  onPageSizeChanged={handleChangeRowsPerPage}
                  bodyHeight={'calc(100vh - 260px)'}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
