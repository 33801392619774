import { gql } from '@apollo/client';
import { bargeModel } from 'model';

export interface AddBargeInput {
  name: string;
}

export interface UpdateBargeInput extends AddBargeInput {
  id: string;
}

export interface DeleteBargeInput {
  id: string;
}

export interface AddBargeOutput {
  addBarge: bargeModel;
}

export interface UpdateBargeOutput {
  updateBarge: bargeModel;
}

export const ADD_BARGE = gql`
  mutation addBarge($input: AddBargeInput!) {
    addBarge(input: $input) {
      id,
      name
    }
  }
`;

export const UPDATE_BARGE = gql`
  mutation updateBarge($input: UpdateBargeInput!) {
    updateBarge(input: $input) {
      id,
      name
    }
  }
`;

export const DELETE_BARGE = gql`
  mutation deleteBarge($input: DeleteBargeInput!) {
    deleteBarge(input: $input)
  }
`;
