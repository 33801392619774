import { gql } from '@apollo/client';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import { applicationUserModel } from 'model';

export interface AddStatementOfFactInput {
  vesselName?: string;
  arrivalDate?: string;
  norTenderedDate?: string;
  quantity?: number;
  destination?: string;
  portId?: string;
  portName?: string;
  ocrContent?: string;
  interruptions?: UpdateStatementOfFactInterruptionInput[];
  ocrResult?: cognitiveReadOperationResultExt[];
  laytimeCalculationId?: string;
  status?: string;
  trafficSpecialistId?: string;
  trafficSpecialist?: applicationUserModel;
  assignToId?: string;
  assignTo?: applicationUserModel;
}

export interface cognitiveReadOperationResultExt extends cognitiveReadOperationResult {
  selected?: boolean;
  usedForInterruption?: boolean;
}

export interface UpdateStatementOfFactInterruptionInput {
  id?: string;
  statementOfFactId?: string;
  description?: string;
  since?: Date;
  to?: Date;
  percentage?: number;
  toDiscount?: boolean;
}

export interface UpdateStatementOfFactInput extends AddStatementOfFactInput {
  id: string;
}

export interface DeleteStatementOfFactInput {
  id: string;
}

export const ADD_STATEMENT_OF_FACT = gql`
  mutation addStatementOfFact($input: AddStatementOfFactInput!) {
    addStatementOfFact(input: $input) {
      id
    }
  }
`;

export const UPDATE_STATEMENT_OF_FACT = gql`
  mutation updateStatementOfFact($input: UpdateStatementOfFactInput!) {
    updateStatementOfFact(input: $input) {
      id
    }
  }
`;

export const DELETE_STATEMENT_OF_FACT = gql`
  mutation deleteStatementOfFact($input: DeleteStatementOfFactInput!) {
    deleteStatementOfFact(input: $input)
  }
`;
