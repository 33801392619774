import { useLazyQuery, useSubscription } from '@apollo/client';
import { Badge, CircularProgress, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { DocumentNode } from 'graphql';
import { t } from 'i18next';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_COMMENTS } from '../../../../../shared/translations';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useApolloErrorHandler } from 'hooks';
import { Comments } from 'components/Comments';
import { baseCommentModel } from 'model';
import { baseCommentDeleteOutput, baseCommentOutput } from 'api';

interface CommentButtonProps {
  btnText?: string;
  pageTitle?: ReactNode;
  entityId: string;
  getCountQuery?: DocumentNode;
  getCommentsQuery?: DocumentNode;
  loadingSize?: 'small' | 'medium' | 'large' | number;
  addCommentMutation?: DocumentNode;
  updateCommentMutation?: DocumentNode;
  deleteCommentMutation?: DocumentNode;
  commentAddedSubscription?: DocumentNode;
  commentUpdatedSubscription?: DocumentNode;
  commentDeletedSubscription?: DocumentNode;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  open?: boolean;
  goToCommentId?: string;
}

interface GetCommentCount {
  count: number;
}

export const CommentContext = createContext<
  Omit<CommentButtonProps, 'btnText' | 'getCountQuery'> & {
    onClose:() => void;
      }
      >({
        entityId: '',
        onClose: () => {}
      });

export const CommentButton = <T extends baseCommentModel>(props: CommentButtonProps & IconButtonProps) => {
  const {
    btnText,
    entityId,
    getCountQuery,
    loadingSize,
    getCommentsQuery,
    addCommentMutation,
    updateCommentMutation,
    deleteCommentMutation,
    commentAddedSubscription,
    commentUpdatedSubscription,
    commentDeletedSubscription,
    tooltipPlacement,
    pageTitle,
    open,
    goToCommentId,
    ...rest
  } = props;
  const [commentCount, setCommentCount] = useState(0);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [showComments, setShowComments] = useState(open);

  const [getCount, { loading: gettingCount, refetch: refetchCount }] = useLazyQuery<GetCommentCount>(getCountQuery!, {
    fetchPolicy: 'network-only',
    variables: {
      entityId
    },
    onCompleted: (data) => {
      setCommentCount(() => data.count);
    },
    onError: apolloErrorHandler
  });

  const onCommentChanged = async () => {
    const result = await refetchCount();
    setCommentCount(() => result.data.count);
  };

  commentAddedSubscription &&
    useSubscription<baseCommentOutput<T>>(commentAddedSubscription, {
      variables: {
        entityId
      },
      onData: onCommentChanged,
      onError: apolloErrorHandler
    });

  commentUpdatedSubscription &&
    useSubscription<baseCommentOutput<T>>(commentUpdatedSubscription, {
      variables: {
        entityId
      },
      onData: onCommentChanged,
      onError: apolloErrorHandler
    });

  commentDeletedSubscription &&
    useSubscription<baseCommentDeleteOutput>(commentDeletedSubscription, {
      variables: {
        entityId
      },
      onData: onCommentChanged,
      onError: apolloErrorHandler
    });

  useEffect(() => {
    getCount();
  }, []);

  const showHideComments = () => setShowComments((prev) => !prev);

  return (
    <CommentContext.Provider
      value={{
        entityId,
        addCommentMutation,
        updateCommentMutation,
        deleteCommentMutation,
        commentAddedSubscription,
        commentUpdatedSubscription,
        commentDeletedSubscription,
        getCommentsQuery,
        onClose: showHideComments
      }}
    >
      {showComments ? <Comments<T> title={pageTitle} goToCommentId={goToCommentId} /> : null}
      {gettingCount ? (
        <CircularProgress size={loadingSize || 20} />
      ) : (
        <Tooltip
          title={capitalizeFirstLetterEveryword(t(btnText || TXT_COMMENTS))}
          placement={tooltipPlacement || 'top'}
        >
          <IconButton component="span" {...rest} onClick={showHideComments}>
            <Badge badgeContent={commentCount} color="info" max={99}>
              <QuestionAnswerIcon fontSize="inherit" />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
    </CommentContext.Provider>
  );
};
