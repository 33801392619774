import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PortsColumn } from './portsColumns';

export interface PortTableHeadProps {
  columns: PortsColumn[];
  showActions?: boolean;
}

export const PortsTableHead = (props: PortTableHeadProps) => {
  const { t } = useTranslation();

  const filterColumnsByRole = (): PortsColumn[] => {
    if (!!props.showActions) {
      return props.columns;
    }
    return props.columns.filter((col) => col.id !== 'edit' && col.id !== 'delete');
  };

  return (
    <TableHead>
      <TableRow>
        {filterColumnsByRole().map((col) => (
          <TableCell key={`head-${col.id}`} align={col.align || 'center'} style={{ width: col.maxWidth }}>
            {t(col.label).toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
