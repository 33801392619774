import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { AddStatementOfFactInput, analyzeOutput } from 'api';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import { OcrChip, cognitiveReadOperationResultExt } from 'components';
import { t } from 'i18next';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { DocumentUpload } from '../DocumentUpload';
import { MergeDialog } from '../MergeDialog';
import { SplitDialog } from '../SplitDialog';
import { useOcrScanWizardStyle } from './ocrScanWizardStyle';
import { TXT_DRAG_N_DROP_TEXT, TXT_FILE_RESTRICTION, TXT_OCR_RESULT } from '../../../../../../shared/translations';

export const OcrWizard = () => {
  const style = useOcrScanWizardStyle();
  const { control, setValue } = useFormContext<AddStatementOfFactInput>();

  const [selectedPageNumber, setSelectedPageNumber] = useState<number>();
  const [ocrResult, setOcrResult] = useState<cognitiveReadOperationResultExt[]>([]);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [showSplitDialog, setShowSplitDialog] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [splitItem, setSplitItem] = useState<cognitiveReadOperationResultExt | undefined>(undefined);
  const [splitItemIndex, setSplitItemIndex] = useState<number>(0);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const watchOcrResult = useWatch({
    control,
    name: 'ocrResult'
  });

  const handleFileUpload = async (files: File[], fileRejection: FileRejection[], event: DropEvent): Promise<void> => {
    setOcrResult(() => []);
    const selectedFile = files[0];
    setFile(() => selectedFile);
    setShowUploadFile((curr) => !curr);
    setValue('ocrResult', []);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: {
      'image/*': ['.jpg', '.png'],
      'application/pdf': ['.pdf']
    }
  });

  const onPageNumberChanged = (_: React.MouseEvent<HTMLElement>, nextLetter: number) => {
    setSelectedPageNumber(() => nextLetter);
    if (!!nextLetter) setOcrResult(() => watchOcrResult?.filter((x) => x.pageNumber === nextLetter) ?? []);
    else setOcrResult(() => watchOcrResult ?? []);
  };

  const handleEditChip = (index: number, result: cognitiveReadOperationResult) => {
    const copyResults = [...ocrResult];
    copyResults[index] = result;
    setOcrResult(() => copyResults);
    setValue('ocrResult', copyResults);
  };

  const handleDeleteChip = (index: number) => {
    const copyResults = [...ocrResult];
    copyResults.splice(index, 1);
    setOcrResult(() => copyResults);
    setValue('ocrResult', copyResults);
  };

  const handleSplitChip = (index: number) => {
    setShowSplitDialog((curr) => !curr);
    setSplitItem(() => ocrResult[index]);
    setSplitItemIndex(() => index);
  };

  const handleSelectChip = (index: number, isSelected: boolean) => {
    const copyResults = [...ocrResult];
    copyResults[index].selected = isSelected;
    setOcrResult(() => copyResults);
    setValue('ocrResult', copyResults);
  };

  const onUploadFinish = (data?: analyzeOutput) => {
    if (!!data && !!data.results && data.results.length > 0) {
      setOcrResult(data.results);
      setPageNumbers(() =>
        _.uniq(data.results && data.results.length > 0 ? data.results.map((item) => item.pageNumber!) : [])
      );
      setExpanded(() => !!data.results && data.results.length > 0);

      setValue('vesselName', data.statementOfFact?.vesselName);
      setValue('portName', data.statementOfFact?.portName);
      setValue('quantity', data.statementOfFact?.quantity);
      setValue('arrivalDate', moment(data.statementOfFact?.arrivalDate).toISOString());
      setValue('norTenderedDate', moment(data.statementOfFact?.norTenderedDate).toISOString());
      setValue('interruptions', data.statementOfFact?.interruptions);
      setValue('ocrResult', data.results);
    }
  };

  const handleMerge = (items: cognitiveReadOperationResultExt[]) => {
    const selectedIndexes = _.map(_.filter(ocrResult, { selected: true }), (obj, index) => index);
    const copyResults = [...ocrResult];
    _.pullAt(copyResults, selectedIndexes);
    _.forEach(copyResults, (obj) => {
      obj.selected = false;
    });
    setOcrResult(() => _.concat(copyResults, items));
    setValue('ocrResult', _.concat(copyResults, items));
  };

  const handleSplit = (items: cognitiveReadOperationResultExt[]) => {
    const copyResults = [...ocrResult];
    _.pullAt(copyResults, splitItemIndex);
    setOcrResult(() => _.concat(copyResults, items));
    setValue('ocrResult', _.concat(copyResults, items));
  };

  const Cell = ({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
    const index = rowIndex * 10 + columnIndex;
    const item = !!watchOcrResult && watchOcrResult.length > 0 ? watchOcrResult[index] : null;
    return (
      <div style={style}>
        {item ? (
          <OcrChip
            id={item.id}
            type={item.type}
            value={item.value}
            selected={item.selected}
            usedForInterruption={item.usedForInterruption}
            onEdit={(result: cognitiveReadOperationResult) => handleEditChip(index, result)}
            onDelete={() => handleDeleteChip(index)}
            onSelect={(isSelected) => handleSelectChip(index, isSelected)}
            onSplit={() => handleSplitChip(index)}
          />
        ) : null}
      </div>
    );
  };

  return (
    <Grid container spacing={3} direction="row-reverse" style={{ height: 'inherit' }}>
      {showUploadFile && (
        <DocumentUpload
          files={file}
          onClose={() => setShowUploadFile((curr) => !curr)}
          onUploadFinish={onUploadFinish}
        />
      )}
      {showMergeDialog && (
        <MergeDialog
          handleCloseDialog={() => setShowMergeDialog((curr) => !curr)}
          items={ocrResult.filter((item) => !!item.selected)}
          handleSuccessMerge={handleMerge}
        />
      )}
      {showSplitDialog && (
        <SplitDialog
          handleCloseDialog={() => setShowSplitDialog((curr) => !curr)}
          item={splitItem}
          handleSuccessSplit={handleSplit}
        />
      )}
      <Grid item xs={12} style={{ height: 'inherit' }}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2} style={{ height: 'inherit', alignContent: 'start' }}>
            <Grid item xs={12}>
              <div {...getRootProps({ className: style.dropzone })}>
                <input {...getInputProps()} />
                <p>{capitalizeFirstLetterEveryword(t(TXT_DRAG_N_DROP_TEXT))}</p>
                <em>{capitalizeFirstLetterEveryword(t(TXT_FILE_RESTRICTION))}</em>
              </div>
            </Grid>
            <Grid item xs={12} style={{ height: 'inherit' }}>
              <Accordion
                expanded={expanded}
                onChange={() => setExpanded((prevExpanded) => !prevExpanded)}
                style={{ height: 'inherit' }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{capitalize(t(TXT_OCR_RESULT))}</Typography>
                </AccordionSummary>
                <AccordionDetails ref={contentRef}>
                  <Stack direction="row" spacing={1}>
                    <ToggleButtonGroup
                      orientation="vertical"
                      value={selectedPageNumber}
                      onChange={onPageNumberChanged}
                      exclusive
                      className={style.letters}
                    >
                      {[...pageNumbers].map((x, index) => (
                        <ToggleButton key={`pagenumber-${index}`} value={x}>
                          {x}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    {watchOcrResult && watchOcrResult.length > 0 && (
                      <Grid container spacing={1} direction="row">
                        <Grid item xs={12} md={12}>
                          <FixedSizeGrid
                            columnCount={10}
                            columnWidth={400}
                            height={250}
                            rowCount={Math.ceil(watchOcrResult.length / 10)}
                            rowHeight={35}
                            width={1200}
                          >
                            {Cell}
                          </FixedSizeGrid>
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DndProvider>
      </Grid>
    </Grid>
  );
};
