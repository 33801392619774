import { Container, Stack, useTheme } from '@mui/material';
import { TXT_REPORTS } from '../../../../../shared/translations';
import { useTitle } from 'hooks';
import { LaytimeChart } from './LaytimeChart';
import { DealPnLReportCard } from './DealPnLReportCard';
import { VoyagePnLReportCard } from './VoyagePnLReportCard';
import { LaytimePerCounterpartyReport } from './LaytimePerCounterpartyReport';
import { BargeReportCard } from './BargeReportCard';
import { SupplierVsOwnerLaytimeReport } from './SupplierVsOwnerLaytimeReport';

export const Reports = () => {
  useTitle(TXT_REPORTS);
  const theme = useTheme();

  return (
    <>
      <Container
        component="main"
        maxWidth="md"
        style={{
          marginTop: theme.spacing(10)
        }}
      >
        <Stack spacing={2}>
          <LaytimeChart />
          <LaytimePerCounterpartyReport />
          <SupplierVsOwnerLaytimeReport />
          <DealPnLReportCard />
          <VoyagePnLReportCard/>
          <BargeReportCard/>
        </Stack>
      </Container>
    </>
  );
};
