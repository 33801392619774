import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_MASS_UNIT } from '../../../../../shared/translations';

export interface MassUnitSelectorProps {
  label?: string;
  massUnit?: string;
  error?: boolean;
  onSelectedChange: (massUnit?: string) => void;
  disabled?: boolean;
}

export const MassUnitSelector = (props: MassUnitSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string | undefined>(props.massUnit);
  const options = ['MT', 'ST'];

  const onSelectedChange = (event: object, value?: string) => {
    setSelected(value);
    props.onSelectedChange(value);
  };

  useEffect(() => {
    setSelected(() => props.massUnit);
  }, [props.massUnit]);

  return (
    <Autocomplete
      id="massUnit-selector"
      onChange={onSelectedChange}
      value={selected}
      options={options}
      fullWidth
      disabled={props.disabled}
      disableClearable={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_MASS_UNIT))}
          variant="outlined"
          error={props.error}
        />
      )}
    />
  );
};
