import axios from 'axios';
import { defaultAuthState } from '../../../shared/appLocalStorage';
import { apiBaseUrl } from '../../../shared/links';
import { openIdSignInInput } from './openIdSignInInput';
import { signInOutput } from './signInOutput';

export const signInWithMicrosoftAccount = async (input: openIdSignInInput) => {
  const url = `${apiBaseUrl}/api/user/signin-microsoft`;
  try {
    const response = await axios.post<signInOutput>(url, input);
    return response.data;
  } catch (err: any) {
    const retError: signInOutput = {
      ...defaultAuthState,
      errorMessage: err
    };
    return err.response &&
      err.response.data ?
      err.response.data as signInOutput : retError;
  }
};
