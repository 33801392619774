/* eslint-disable quote-props */
import makeStyles from '@mui/styles/makeStyles';

const microsoftLoginButtonStyle = makeStyles((theme) => ({
  box: {
    display: 'flex',
    height: 20,
    width: 20,
    '& img': {
      width: '100%'
    },
    marginRight: '10px'
  },
  paper: {
    width: 240
  }
}));

export default microsoftLoginButtonStyle;
