import { Tooltip, IconButton } from '@mui/material';
import { laytimeCalculationModel } from 'model';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_DELETE, TXT_DELETE_TEXT } from '../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { DeleteModal } from 'components/DeleteModal';
import { DELETE_LAYTIME_CALCULATION } from 'api';
import {
  DemurragePageDispatchContext,
  DemurragePageStateContext,
  deleteDemurrageCalculation
} from 'pages/DemurragePage/DemurragePageContext';
import _ from 'lodash';
import { useGlobalStyles } from 'pages/globalStyles';
import { useDemurrageService } from 'hooks';

interface DeleteCalculationProps {
  calculation: laytimeCalculationModel;
  size?: 'small' | 'medium' | 'large';
  afterSave?: () => void;
}

export const DeleteCalculation = (props: DeleteCalculationProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useContext(DemurragePageDispatchContext);
  const { selectedTab } = useContext(DemurragePageStateContext);
  const { calculationReadyToBePastedId, resetcalculationReadyToBePastedId } = useDemurrageService();
  const globalStyles = useGlobalStyles();

  if (props.calculation.status !== 'DRAFT') {
    return null;
  }

  const openCloseModal = () => setOpenModal((prev) => !prev);

  let txtDeleteConfirmation = t(TXT_DELETE_TEXT, {
    name: `${props.calculation?.counterPartyType?.name!} - ${props.calculation?.counterParty?.name ?? ''}`
  });
  txtDeleteConfirmation = _.capitalize(txtDeleteConfirmation);

  const handleDeleteClick = () => {
    if (calculationReadyToBePastedId === props.calculation.id) {
      resetcalculationReadyToBePastedId();
    }

    if (dispatch && props.calculation.id) {
      dispatch(deleteDemurrageCalculation(selectedTab, props.calculation.id));
    }

    if (props.afterSave) {
      props.afterSave();
    }
  };

  return (
    <>
      {openModal ? (
        <DeleteModal
          afterSaving={handleDeleteClick}
          onCancel={openCloseModal}
          txtConfirmation={txtDeleteConfirmation}
          id={props.calculation?.id!}
          mutation={DELETE_LAYTIME_CALCULATION}
        />
      ) : null}
      <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_DELETE))}>
        <IconButton
          aria-label="delete"
          size={props.size || 'small'}
          onClick={openCloseModal}
          className={globalStyles.deleteButton}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
};
