import { Card, Typography, useTheme, Stack, CardActionArea } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_LAYTIME_PER_COUNTERPARTY } from '../../../../../../shared/translations';
import TimelineIcon from '@mui/icons-material/Timeline';

import { useState } from 'react';
import { LaytimePerCounterpartyReportFilter } from 'components/LaytimePerCounterpartyReportFilter';

export const LaytimePerCounterpartyReport = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openFilter, setOpenFilter] = useState(false);

  const showFilter = () => setOpenFilter(true);
  const closeFilter = () => setOpenFilter(false);

  return (
    <>
      {openFilter && <LaytimePerCounterpartyReportFilter onCancel={closeFilter} />}
      <Card>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <CardActionArea
            onClick={showFilter}
            sx={{
              padding: theme.spacing(2)
            }}
          >
            <Stack direction="row" spacing={2}>
              <div>
                <TimelineIcon fontSize="large" />
              </div>
              <Typography variant="h5" align="center">
                {capitalizeFirstLetterEveryword(t(TXT_LAYTIME_PER_COUNTERPARTY))}
              </Typography>
            </Stack>
          </CardActionArea>
        </Stack>
      </Card>
    </>
  );
};
