import { Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import { TXT_FROM, TXT_UNTIL } from '../../../../../shared/translations';

export interface DateRange {
  start?: Date;
  end?: Date;
}

interface DateRangePickerProps {
  value?: DateRange;
  startLabel?: string;
  endLabel?: string;
  format?: string;
  error?: boolean;
  onChanged: (range: DateRange) => void;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const format = props.format || getDateFormat();
  const { t } = useTranslation();
  const [start, setStart] = useState<moment.Moment | null>(!!props.value?.start ? moment(props.value.start).isValid() ? moment(props.value.start) : null : null);
  const [end, setEnd] = useState<moment.Moment | null>(!!props.value?.end ? moment(props.value.end).isValid() ? moment(props.value.end) : null : null);

  useEffect(() => {
    props.onChanged({
      start: start?.toDate(),
      end: end?.toDate()
    });
  }, [start, end]);

  return (
    <Stack direction='row' spacing={1} sx={{
      alignItems: 'center'
    }}>
      <DatePicker
        value={start}
        onChange={setStart}
        format={format}
        label={props.startLabel || capitalizeFirstLetterEveryword(t(TXT_FROM))}
        slotProps={{
          textField: {
            fullWidth: true,
            autoComplete: 'off'
          },
          actionBar: {
            actions: ['today', 'clear']
          }
        }}
      />
      <Typography>—</Typography>
      <DatePicker
        value={end}
        onChange={setEnd}
        format={format}
        label={props.startLabel || capitalizeFirstLetterEveryword(t(TXT_UNTIL))}
        slotProps={{
          textField: {
            fullWidth: true,
            autoComplete: 'off'
          },
          actionBar: {
            actions: ['today', 'clear']
          }
        }}
      />
    </Stack>
  );
};
