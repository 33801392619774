import { TXT_DELETE, TXT_EDIT, TXT_INITIAL, TXT_NAME } from '../../../../../shared/translations';

export interface BusinessUnitsColumn {
  id: 'name' | 'initials' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const businessUnitsColumns: BusinessUnitsColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '50%',
    align: 'left'
  },
  {
    id: 'initials',
    label: TXT_INITIAL,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%'
  }
];
