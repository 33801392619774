import { gql } from '@apollo/client';
import { portModel } from 'model';

export const getPortsFragment = `
  items {
    id
    name
    country
    latitude
    longitude
  },
  totalCount
`;

export type GetPortsOutput = {
  ports?: {
    items?: [portModel],
    totalCount?: number;
  }
};

export const GET_PORTS = gql`
  query getPorts($take: Int!, $skip: Int!, $where: PortFilterInput) {
    ports(take: $take, skip: $skip, where: $where) {
      ${getPortsFragment}
    }
  }
`;
