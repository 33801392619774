import { TXT_COUNTRY, TXT_DELETE, TXT_EDIT, TXT_LATITUDE, TXT_LONGITUDE, TXT_NAME } from '../../../../../shared/translations';

export interface PortsColumn {
  id: 'name' | 'country' | 'latitude' | 'longitude' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const portsColumns: PortsColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'country',
    label: TXT_COUNTRY,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'latitude',
    label: TXT_LATITUDE,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'longitude',
    label: TXT_LONGITUDE,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%'
  }
];
