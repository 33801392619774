import { gql } from '@apollo/client';
import { contactPhoneModel } from 'model';

export interface AddContactPhoneInput extends Omit<contactPhoneModel, 'id'> { }

export interface UpdateContactPhoneInput extends AddContactPhoneInput {
  strId: string;
}

export interface DeleteContactPhoneInput {
  id: string;
}

export interface UpdateContactPhonesInput {
  contactId: string;
  updateInputs: UpdateContactPhoneInput[];
}

export interface UpdateContactPhonesOutput {
  updateContactPhones: contactPhoneModel[];
}

export const UPDATE_CONTACT_PHONES = gql`
  mutation updateContactPhones($input: UpdateContactPhonesInput!) {
    updateContactPhones(input: $input) {
      id
      name
      phone
      contactId
    }
  }
`;
