import { useMutation } from '@apollo/client';
import { UPDATE_LAYTIME_CALCULATION, UpdateLaytimeCalculationInput, UpdateLaytimeCalculationOutput } from 'api';
import { laytimeCalculationModel } from 'model';
import { DemurragePageAction, refreshLaytimeCalculationsAfterSave } from 'pages/DemurragePage/DemurragePageContext';
import { Dispatch } from 'react';
import { getUpdateLaytimeCalculationInput } from 'shared/utils';
import { useApolloErrorHandler } from './useApolloErrorHandler';

export const useSaveLaytimeCalculation = (dispatch: Dispatch<DemurragePageAction>) => {
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [updateCalculation, { loading, error }] = useMutation<UpdateLaytimeCalculationOutput>(
    UPDATE_LAYTIME_CALCULATION,
    {
      onError: apolloErrorHandler,
      onCompleted: (calculation) => {
        const {
          id,
          permittedTime,
          realLaytimeBegins,
          totalTimeUsed,
          lessInterruptions,
          netTimeUsed,
          mvOnDemurrage,
          timeInDemurrage,
          demurrageAmount,
          timeInDespatch,
          despatchAmount,
          amountBeforeShare,
          sharedPercentage,
          laytimeCalculationResult,
          quantity,
          massUnit
        } = calculation.updateLaytimeCalculation;
        dispatch(
          refreshLaytimeCalculationsAfterSave({
            id,
            permittedTime,
            realLaytimeBegins,
            totalTimeUsed,
            lessInterruptions,
            netTimeUsed,
            mvOnDemurrage,
            timeInDemurrage,
            demurrageAmount,
            timeInDespatch,
            despatchAmount,
            amountBeforeShare,
            sharedPercentage,
            laytimeCalculationResult,
            quantity,
            massUnit
          })
        );
      }
    }
  );

  const handleSave = (calculation?: laytimeCalculationModel) => {
    if (!calculation) {
      return;
    }

    const updateCalculationInput: UpdateLaytimeCalculationInput = getUpdateLaytimeCalculationInput(calculation);

    updateCalculation({
      variables: {
        input: updateCalculationInput
      }
    });
  };

  return {
    handleSave,
    saving: loading,
    error
  };
};

