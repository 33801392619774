import { signInOutput } from '../api';

export interface appStorageProps extends signInOutput {
  darkMode?: boolean
}

export const localStorageKey = process.env.REACT_APP_LOCALSTORAGE_KEY ||
  'salomon_localstorage';

export const defaultAuthState: appStorageProps = {
  username: undefined,
  fullName: undefined,
  accessToken: undefined,
  accessTokenValidDate: undefined,
  refreshToken: undefined,
  refreshTokenValidDate: undefined,
  hasGoogleAccountConnected: undefined,
  hasMicrosoftAccountConnected: undefined,
  errorMessage: undefined
};

export const defaultStorage: appStorageProps = {
  ...defaultAuthState
};

export const getAppLocalStorage = () => {
  const storedValue = localStorage.getItem(localStorageKey);
  if (!storedValue || storedValue === null) {
    localStorage.setItem(localStorageKey, JSON.stringify(defaultStorage));
    return defaultStorage;
  }

  return JSON.parse(storedValue) as appStorageProps;
};

export const setAppLocalStorage = (value: appStorageProps) => {
  localStorage.setItem(localStorageKey, JSON.stringify(value));
};
