import { Backdrop, Container, Stack, Typography } from '@mui/material';
import { analyzeOutput, useDocumentApi } from 'api';
import { BorderLinearProgress } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_UPLOADING } from '../../../../../shared/translations';

interface DcoumentUploadProps {
  files?: File;
  onClose: () => void;
  onUploadFinish: (data?: analyzeOutput) => void;
}

export const DocumentUpload = (props: DcoumentUploadProps) => {
  const { t } = useTranslation();
  const { analyze } = useDocumentApi();
  const [percentage, setPercentage] = useState(0);

  const uploading = async () => {
    try {
      if (!props.files) {
        return;
      }
      setPercentage(() => 0);
      const response = await analyze(props.files, (progress) => setPercentage(() => progress));

      if (response?.status == 200) {
        props.onUploadFinish(response.data);
      } else {
        console.error('Error analyzing the document');
      }
    } catch (e) {
      console.error('Error sending the document to the API', e);
    } finally {
      props.onClose();
    }
  };

  useEffect(() => {
    (async () => await uploading())();
  }, []);

  return (
    <Backdrop
      open={true}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
    >
      <Container maxWidth="sm">
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography>{capitalizeFirstLetterEveryword(t(TXT_UPLOADING))}</Typography>
          <BorderLinearProgress
            sx={{
              width: '100%'
            }}
            variant="determinate"
            value={percentage}
          />
        </Stack>
      </Container>
    </Backdrop>
  );
};
