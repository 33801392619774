import { TableCell, TableHead, TableRow } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteTableContext } from './QuoteTable';

export const QuoteTableHead = () => {
  const { t } = useTranslation();
  const { columns } = useContext(QuoteTableContext);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={(theme) => ({
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            width: '5%'
          })}
        ></TableCell>
        {columns.map((col) => (
          <TableCell
            key={`head-${col.id}`}
            align={col.align || 'center'}
            sx={(theme) => ({
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              width: col.maxWidth
            })}
          >
            {t(col.label).toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
