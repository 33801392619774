import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_TRADER,
  TXT_FIELD_IS_REQUIRED,
  TXT_INITIAL,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_TRADER
} from '../../../../../shared/translations';
import { AddTraderInput, ADD_TRADER, UPDATE_TRADER } from '../../api';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { traderModel } from 'model';

export interface TraderEditorProps extends traderModel {
  onClose: () => void;
  afterSave: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  initials: yup.string().required()
});

export const TraderEditor = (props: TraderEditorProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddTraderInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      initials: props.initials
    }
  });
  useWatch({ control });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutateTrader, { loading }] = useMutation(props.id ? UPDATE_TRADER : ADD_TRADER, {
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSave();
      props.onClose();
    }
  });

  const onSubmit: SubmitHandler<AddTraderInput> = (data) => {
    mutate(data);
  };

  const mutate = (input: AddTraderInput) => {
    if (!props.id) {
      return mutateTrader({
        variables: {
          input
        }
      });
    }

    return mutateTrader({
      variables: {
        input: {
          id: props.id,
          ...input
        }
      }
    });
  };

  const txtErrorField = _.capitalize(t(TXT_FIELD_IS_REQUIRED));
  const title = t(props.id ? TXT_UPDATE_TRADER : TXT_CREATE_TRADER);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="trader-editor-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                autoFocus
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                disabled={loading}
                error={!!errors.name}
                helperText={errors.name && txtErrorField}
                defaultValue={props.name}
                {...register('name', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                id="initials"
                label={capitalizeFirstLetterEveryword(t(TXT_INITIAL))}
                fullWidth
                disabled={loading}
                error={!!errors.initials}
                helperText={errors.initials && txtErrorField}
                defaultValue={props.initials}
                {...register('initials', { required: true })}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="trader-editor-form" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
