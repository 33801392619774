import { gql } from '@apollo/client';

export const getProductsFragment = `
  items {
    id,
    name
  },
  totalCount
`;

export type ProductQueryResult = {
  id: string;
  name: string;
};

export type GetProductsOutput = {
  products?: {
    items?: [ProductQueryResult];
    totalCount?: number;
  };
};

export const GET_PRODUCTS = gql`
  query getProducts($name: String, $take: Int!, $skip: Int!) {
    products(name: $name, take: $take, skip: $skip) {
      ${getProductsFragment}
    }
  }
`;
