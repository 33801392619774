import useAuth from '../../hooks/useAuth';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_SIGN_OUT, TXT_UPDATE_MY_PROFILE, TXT_WELCOME } from '../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useLazyQuery } from '@apollo/client';
import { GetTenantOutput, GET_TENANT } from 'api';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { Divider, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { tenantState } from 'state/tenantState';
import { useAuthService } from 'hooks/useAuthServices';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';
import { userProfilePath } from 'shared/links';

export const UserInfo = () => {
  const [auth] = useAuth();
  const [open, setOpen] = useState(false);
  const [currentTenant, setCurrentTenant] = useRecoilState(tenantState);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { signOut } = useAuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    fetch();
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const onSetCurrentTenant = (event: GetTenantOutput) => {
    if (event && event.tenant) {
      setCurrentTenant(event.tenant);
    }
  };

  const [getTenant, { refetch }] = useLazyQuery<GetTenantOutput>(GET_TENANT, {
    fetchPolicy: 'network-only',
    onCompleted: onSetCurrentTenant,
    onError: apolloErrorHandler
  });

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSignOut = (event: any) => {
    handleClose(event);
    signOut();
  };

  const fetch = () => {
    if (!refetch) {
      getTenant();
    } else {
      refetch();
    }
  };

  const showProfileEditor = () => navigate(userProfilePath);

  const txtSignOut = capitalizeFirstLetterEveryword(t(TXT_SIGN_OUT));
  const txtUpdateMyProfile = capitalizeFirstLetterEveryword(t(TXT_UPDATE_MY_PROFILE));

  return (
    <div>
      <Button
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="column">
          {t(TXT_WELCOME, {
            name: auth.fullName
          })}
          <Typography textAlign="center" fontSize={12}>
            {currentTenant.name}
          </Typography>
        </Stack>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="user-menu" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={showProfileEditor}>
                    <ListItemIcon>
                      <ManageAccountsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{txtUpdateMyProfile}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{txtSignOut}</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
