import axios from 'axios';
import { useAxios } from 'hooks';
import MergedEntityLog from 'model/mergedEntityLog';
import MergePortsRequestDto from 'model/mergePorts/mergePortsRequestDto';
import { apiBaseUrl } from 'shared/links';

const useMergePortApi = () => {
  const url = `${apiBaseUrl}/api/DataMaintenance/MergePorts`;
  const { create } = useAxios();

  const mergePorts = async (request: MergePortsRequestDto) => {
    const instance = await create();

    try {
      const response = await instance.post<MergedEntityLog[]>(url, request);
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.message);
      } else {
        console.error(e);
      }
    }
  };

  return {
    mergePorts
  };
};

export default useMergePortApi;
