import axios from 'axios';
import { apiBaseUrl } from '../../../shared/links';
import { refreshTokenInput } from './refreshTokenInput';
import { refreshTokenOutput } from './refreshTokenOutput';

export const refreshToken = async (input: refreshTokenInput) => {
  const url = `${apiBaseUrl}/api/user/refreshtoken`;
  try {
    const response = await axios.post<refreshTokenOutput>(url, input);
    return response.data;
  } catch (err: any) {
    const retError: refreshTokenOutput = {
      accessToken: '',
      accessTokenValidDate: new Date(),
      errorMessage: err
    };
    return err.response &&
      err.response.data ?
      err.response.data as refreshTokenOutput : retError;
  }
};
