import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const ADD_LAYTIME_CALCULATION_COMMENT = gql`
  mutation addLaytimeCalculationComment($input: AddLaytimeCalculationCommentInput!) {
    result: addLaytimeCalculationComment(input: $input) {
      ${BaseCommentQueryData}
    }
  }
`;

export const UPDATE_LAYTIME_CALCULATION_COMMENT = gql`
  mutation updateLaytimeCalculationComment($input: UpdateLaytimeCalculationCommentInput!) {
    result: updateLaytimeCalculationComment(input: $input) {
      ${BaseCommentQueryData}
    }
  }
`;

export const DELETE_LAYTIME_CALCULATION_COMMENT = gql`
  mutation deleteLaytimeCalculationComment($input: DeleteLaytimeCalculationCommentInput!) {
    result: deleteLaytimeCalculationComment(input: $input) 
  }
`;
