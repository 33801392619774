import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetTradersOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { TradersColumn } from './tradersColumns';
import { traderModel } from 'model';

export interface TradersTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetTradersOutput;
  columns: TradersColumn[];
  onEdit: (trader: traderModel) => void;
  onDelete: (trader: traderModel) => void;
}

export const TradersTableBody =
  // eslint-disable-next-line max-len
  ({ columns, loading, error, data, onEdit, onDelete }: TradersTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.traders &&
          data.traders.items &&
          data.traders.items.length > 0 &&
          data.traders.items.map((trader) => (
            <TableRow key={`row-${trader.id}`}>
              <TableCell align="left">{trader.name}</TableCell>
              <TableCell align="left">{trader.initials}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit trader"
                  component="span"
                  onClick={() => onEdit(trader)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete trader"
                  component="span"
                  onClick={() => onDelete(trader)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        {!loading && (!data || !data.traders || !data.traders.items || data.traders.items.length <= 0) && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
