import { IconButton, Tooltip } from '@mui/material';
import { PromoteConfirmation } from 'components/PromoteConfirmation';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';

interface PromoteCalculationProps {
  size?: 'small' | 'medium' | 'large';
  sourceCalculationId: string;
  targetCalculationId: string;
  icon: ReactNode;
  label: string;
  afterSave: () => void;
  txtConfirmation: string;
}

export const PromoteCalculation = (props: PromoteCalculationProps) => {
  const { t } = useTranslation();
  const label = capitalizeFirstLetterEveryword(t(props.label));
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const openCloseConfirmation = () => setOpenConfirmation((prev) => !prev);

  return (
    <>
      {openConfirmation ? (
        <PromoteConfirmation
          sourceCalculationId={props.sourceCalculationId}
          targetCalculationId={props.targetCalculationId}
          txtConfirmation={props.txtConfirmation}
          afterSave={props.afterSave}
          onCancel={openCloseConfirmation}
        />
      ) : null}
      <Tooltip title={label}>
        <IconButton aria-label={props.label} size={props.size || 'small'} onClick={openCloseConfirmation}>
          {props.icon}
        </IconButton>
      </Tooltip>
    </>
  );
};
