/* eslint-disable quote-props */
import { Box, Container } from '@mui/material';
import { useGlobalStyles } from 'pages/globalStyles';
import { ReactNode, useState } from 'react';
import { TXT_DATA_MAINTENANCE, TXT_MERGE_PORTS } from '../../../../../shared/translations';
import { useTranslation } from 'react-i18next';
import capitalizeEveryWord from '../../../../../shared/stringUtils/capitalizeEveryWord';
import MergePorts from './MergePorts';
import { useTitle } from 'hooks';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

type DataMaintenanceTabKey = 'mergePorts';

interface DataMaintenanceTab {
  key: DataMaintenanceTabKey;
  text: string;
  component: ReactNode;
}

interface TabPanelProps {
  children: ReactNode;
  index: DataMaintenanceTabKey;
  value: DataMaintenanceTabKey;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        width: '100%'
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: DataMaintenanceTabKey) => {
  return {
    id: `configuration-tab-${index}`,
    'aria-controls': `configuration-tabpanel-${index}`
  };
};

const DataMaintenance = () => {
  useTitle(TXT_DATA_MAINTENANCE);
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();

  const tabs: DataMaintenanceTab[] = [
    {
      key: 'mergePorts',
      text: TXT_MERGE_PORTS,
      component: <MergePorts />
    }
  ];

  const [selectedTab, setSelectedTab] = useState<DataMaintenanceTabKey>(tabs[0].key);

  const handleChange = (_: React.SyntheticEvent, newValue: DataMaintenanceTabKey) => {
    setSelectedTab(newValue);
  };

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          orientation="vertical"
          variant="scrollable"
          sx={{ borderRight: 1, borderColor: 'divider', height: 'calc(100vh - 100px)' }}
        >
          {tabs.map((x) => (
            <Tab key={`tab-${x.key}`} label={capitalizeEveryWord(t(x.text))} {...a11yProps(x.key)} value={x.key} />
          ))}
        </Tabs>
        {tabs.map((x) => (
          <CustomTabPanel key={`tabpanel-${x.key}`} value={selectedTab} index={x.key}>
            {x.component}
          </CustomTabPanel>
        ))}
      </Box>
    </Container>
  );
};

export default DataMaintenance;
