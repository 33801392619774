import { gql } from '@apollo/client';

export type GetCountriesOutput = {
  countries: string[];
};

export const GET_COUNTRIES = gql`
  query getCountries {
    countries
  }
`;
