import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest
} from '@azure/msal-browser';
import { useEffect, useState } from 'react';

import { MSAL_CONFIG } from './azure-authentication-config';

export type signInType = 'loginPopup' | 'loginRedirect';

export interface AzureAuthenticationContext {
  accountInfo?: AccountInfo;
  authResult?: AuthenticationResult;
  signIn: (signInType: signInType,
    callback: (authenticationResult: AuthenticationResult) => void,
    preSignIn?: () => void,
    postSignIn?: () => void) => void;
  signOut: () => void;
};

export const useAzureAuthenticationContext = () => {
  const myMSALObj = new PublicClientApplication(MSAL_CONFIG);
  // eslint-disable-next-line max-len
  const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
  // eslint-disable-next-line max-len
  const [authResult, setAuthResult] = useState<AuthenticationResult | undefined>(undefined);
  // eslint-disable-next-line max-len
  const [loginRequest] = useState<PopupRequest>({
    scopes: [],
    prompt: 'select_account'
  });
  // eslint-disable-next-line max-len
  const [loginRedirectRequest] = useState<RedirectRequest>({
    ...loginRequest,
    redirectStartPage: window.location.href
  });

  const getAccount = (): AccountInfo | undefined => {
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      return undefined;
    }

    if (currentAccounts.length >= 1) {
      return currentAccounts[0];
    }
  };

  useEffect(() => {
    // eslint-disable-next-line max-len
    if (authResult !== undefined && authResult !== null && authResult.account !== null) {
      setAccount({
        ...authResult.account
      });
    } else {
      setAccount(getAccount());
    }
  }, [authResult]);

  // eslint-disable-next-line max-len
  const signIn = (signInType: signInType, callback: (authenticationResult: AuthenticationResult) => void,
    preSignIn?: () => void,
    postSignIn?: () => void) => {
    preSignIn && preSignIn();

    if (signInType === 'loginPopup') {
      myMSALObj
        .loginPopup(loginRequest)
        .then((resp: AuthenticationResult) => {
          setAuthResult(resp);
          callback(resp);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => postSignIn && postSignIn());
    } else if (signInType === 'loginRedirect') {
      myMSALObj.loginRedirect(loginRedirectRequest).finally(() => postSignIn && postSignIn());
    }
  };

  const signOut = () => {
    const logOutRequest: EndSessionRequest = {
      account
    };

    myMSALObj.logoutRedirect(logOutRequest);
  };

  const result: AzureAuthenticationContext = {
    accountInfo: account,
    authResult,
    signIn,
    signOut
  };

  return result;
};
