/* eslint-disable quote-props */
import makeStyles from '@mui/styles/makeStyles';

const googleLoginButtonStyle = makeStyles((theme) => ({
  box: {
    display: 'flex',
    height: 20,
    width: 20,
    '& img': {
      width: '100%'
    }
  },
  paper: {
    width: 240
  }
}));

export default googleLoginButtonStyle;
