import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { verificationCodeInput, verifyCode } from 'api';
import _ from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { changePasswordPath } from 'shared/links';
import { TXT_VERICIATION_CODE, TXT_VERICIATION_CODE_TEXT, TXT_VERICIATION_CODE_TITLE, TXT_VERIFY } from '../../../../../shared/translations';
import * as yup from 'yup';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { TXT_CANCEL } from '../../../../../shared/translations';
import { useState } from 'react';
import { CustomError } from 'components';

export interface VerificationCodeDialogProps {
  email: string;
  verificationCode: string;
  onClose: () => void;
}

const schema = yup.object({
  verificationCode: yup.string().required()
});

export const VerificationCodeDialog = (props: VerificationCodeDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<verificationCodeInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: props.email,
      verificationCode: ''
    }
  });

  const onSave: SubmitHandler<verificationCodeInput> = async (data) => {
    setIsSubmitting(true);

    try {
      const verifyCodeResponse = await verifyCode({
        email: data.email,
        verificationCode: data.verificationCode
      });

      if (!!verifyCodeResponse.verificationCode) {
        props.onClose();

        navigate(
          `${changePasswordPath}?username=${verifyCodeResponse.email}&code=${verifyCodeResponse.verificationCode}`
        );
      }
    } catch (err: any) {
      setErrorMessage(err?.response?.data?.Message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSave)}>
        <AppBar position="relative">
          <Toolbar>{capitalizeFirstLetterEveryword(t(TXT_VERICIATION_CODE_TITLE))}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid item xs={12}>
            <Typography variant="body1">{_.capitalize(t(TXT_VERICIATION_CODE_TEXT))}</Typography>
          </Grid>
          {!!errorMessage && (
            <Grid item xs={12}>
              <CustomError>{_.capitalize(t(errorMessage))}</CustomError>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="verificationCode"
                label={capitalizeFirstLetterEveryword(t(TXT_VERICIATION_CODE))}
                fullWidth
                {...register('verificationCode', { required: true })}
                error={!!formErrors.verificationCode}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={isSubmitting}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t(TXT_VERIFY)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
