import { gql } from '@apollo/client';

export const getShexConditionsFragment = `
  laytimeCalculationId
  description
  remarks
  id
  fromDayOfWeek
  fromDate
  fromTime
  untilDayOfWeek
  untilDate
  untilTime
  shexConditionType
  ignore
`;

export interface IShexCondition {
  description: string;
  remarks: string;
  id: string;
  laytimeCalculationId?: string;
  fromDayOfWeek?: number; // From what day of the week the condition start
  fromDate?: Date;
  fromTime?: number;
  untilDayOfWeek?: number;
  untilDate?: Date;
  untilTime?: number;
  shexConditionType: 'RECURRENT' | 'FIXED';
  ignore: boolean;
}

export type GetShexConditionsOutput = {
  shexConditions: IShexCondition[];
};

export const GET_SHEX_CONDITIONS = gql`
  query getShexConditions($where: ShexConditionFilterInput) {
    shexConditions(where: $where) {
      ${getShexConditionsFragment}
    }
  }
`;
