import { Box, Button, ClickAwayListener, IconButton, InputAdornment, Paper, Popper, Stack } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, StaticDatePicker } from '@mui/x-date-pickers';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import moment, { Moment } from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_NOW, TXT_OK } from '../../../../../shared/translations';

export interface DateTimePickerWithDropProps extends DateTimePickerProps<Moment> {
  value?: Moment;
  onChanged?: (item?: cognitiveReadOperationResult, value?: Moment) => void;
  disabled?: boolean;
  id?: string;
  dateFormat: string;
  showTimeInput?: boolean;
  label?: string;
  openToDate?: Date;
  style?: CSSProperties;
}

export const DateTimePickerWithDrop = (props: DateTimePickerWithDropProps) => {
  const { t } = useTranslation();

  const { value, disabled, dateFormat, label, onChanged, id, openToDate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [dateValue, setDateValue] = useState(value);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'CHIP',
    drop: (value: { item: cognitiveReadOperationResult }) => {
      if (value.item.type === 'System.DateTime' || value.item.type === 'System.DateOnly') {
        setDateValue(() => moment(value.item.value));
        onChanged && onChanged(value.item, moment(value.item.value));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop:
        monitor.canDrop() &&
        (monitor.getItem().item.type == 'System.DateTime' || monitor.getItem().item.type == 'System.DateOnly')
    })
  }));

  const handleChange = (date: moment.Moment | null, initializeTime: boolean) => {
    if (date) {
      const newDate = date;
      if (!value && initializeTime) {
        newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      }
      onChanged && onChanged(undefined, newDate);
    } else {
      onChanged && onChanged(undefined);
    }
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget.parentElement?.parentElement!);
    setOpen((s) => !s);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNow = () => {
    if (onChanged) {
      const newDate = moment();
      onChanged(undefined, newDate);
    }
  };

  useEffect(() => {
    setDateValue(() => moment(value));
  }, [value]);

  return (
    <>
      <DateTimePicker
        inputRef={drop}
        disabled={disabled}
        format={dateFormat}
        disableOpenPicker
        onChange={(x) => handleChange(x, false)}
        value={dateValue || null}
        slotProps={{
          textField: {
            fullWidth: true,
            label,
            id,
            style: { backgroundColor: isOver && canDrop ? '#c8e6c9' : 'white', ...props.style },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClick}></IconButton>
                </InputAdornment>
              )
            }
          }
        }}
      />
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 9000 }}>
          <Paper>
            <Stack>
              <Stack direction="row">
                <Box borderRight="1px solid lightgrey">
                  <StaticDatePicker
                    referenceDate={moment(openToDate)}
                    displayStaticWrapperAs="desktop"
                    onChange={(x) => handleChange(x, true)}
                    value={dateValue || null}
                    views={['day']}
                  />
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" borderTop="1px solid lightgrey">
                <Button onClick={handleNow} size="small">
                  {capitalizeFirstLetterEveryword(t(TXT_NOW))}
                </Button>
                <Button onClick={handleClose}>{capitalizeFirstLetterEveryword(t(TXT_OK))}</Button>
              </Stack>
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};
