import { Button, TextField } from '@mui/material';
import { forgotPasswordInput } from 'api';
import { useForm } from 'react-hook-form';
import { validateEmail } from 'shared/utils';

export interface ForgotPasswordFormProps {
  txtEmail: string;
  txtSubmit: string;
  fontSize: string;
  isSubmitting: boolean;
  onSubmit: (data: forgotPasswordInput) => void;
}

export const ForgotPasswordForm = ({ txtEmail, txtSubmit, isSubmitting, onSubmit }: ForgotPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<forgotPasswordInput>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={txtEmail}
        autoComplete="email"
        autoFocus
        {...register('email', { required: true, validate: validateEmail })}
        error={!!errors.email}
        disabled={isSubmitting}
      />
      <Button type="submit" fullWidth variant="contained" color="primary" sx={{ marginY: 2 }} disabled={isSubmitting}>
        {txtSubmit}
      </Button>
    </form>
  );
};
