import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(10),
      maxWidth: 'unset'
    },
    paperContainer: {
      padding: theme.spacing(2)
    },
    searchTextbox: {
      width: '100%'
    },
    editButton: {
      'color': theme.palette.action.active,
      '&:hover': {
        color: theme.palette.warning.main
      }
    },
    deleteButton: {
      'color': theme.palette.action.active,
      '&:hover': {
        color: theme.palette.error.main
      }
    },
    fabContainer: {
      'position': 'absolute',
      'bottom': theme.spacing(3),
      'right': theme.spacing(3),
      'display': 'flex',
      'flexDirection': 'column',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    datagridContainer: {
      'height': 'calc(100vh - 260px)',
      '& .MuiDataGrid-columnHeaderTitle': {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1
      }
    }
  })
);
