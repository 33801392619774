import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { apiBaseUrl, wsBaseUrl } from '../../../shared/links';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = createHttpLink({
  uri: `${apiBaseUrl}/graphql`
});

const getAuthLink = (token?: string) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token || ''}`
      }
    };
  });
};

const wsLink = (token?: string) => new GraphQLWsLink(createClient({
  url: `${wsBaseUrl}/graphql`,
  connectionParams: {
    authToken: `Bearer ${token || ''}`
  }
}));

const splitLink = (token?: string) => split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink(token),
  getAuthLink(token).concat(httpLink)
);

export const getGraphQLClient = (token?: string) => {
  return new ApolloClient({
    link: splitLink(token),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only'
      }
    }
  });
};
