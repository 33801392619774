import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useDealCardStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    content: {
      padding: theme.spacing(2)
    },
    actionButtons: {
      'text-align': 'center'
    },
    statusBox: {
      'text-align': 'center'
    },
    status: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);
