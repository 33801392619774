import { Box, Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import { baseCommentModel } from 'model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_DELETE_COMMENT, TXT_DELETE_COMMENT_WITH_REPLIES, TXT_RESOLVED } from '../../../../../shared/translations';
import { CommentBox } from 'components/CommentBox';
import _, { capitalize } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGlobalStyles } from 'pages/globalStyles';
import useAuth from 'hooks/useAuth';
import { useContext, useEffect, useState } from 'react';
import { DeleteModal } from 'components/DeleteModal';
import { CommentContext } from 'components/CommentButton';
import { UserMention } from 'components/UserMention';

interface CommentCardProps<T extends baseCommentModel> {
  comment: T;
  afterSend: (input: T) => void;
  afterDelete: (input: T) => void;
  onReplyEditMode?: (editMode: boolean) => void;
  replies?: T[];
}

export const CommentCard = <T extends baseCommentModel>(props: CommentCardProps<T>) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const [auth] = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const { deleteCommentMutation } = useContext(CommentContext);
  const [replyEditMode, setReplyEditMode] = useState(false);

  const changeEditMode = () => setEditMode((prev) => !prev);
  const changeDeleteMode = () => setDeleteMode((prev) => !prev);

  const afteEdit = (comment: T) => {
    changeEditMode();
    props.afterSend && props.afterSend(comment);
  };

  const afterDelete = () => {
    props.afterDelete(props.comment);
  };

  useEffect(() => {
    props.onReplyEditMode && props.onReplyEditMode(editMode);
  }, [editMode]);

  return (
    <>
      {deleteMode && !!props.comment.id && deleteCommentMutation ? (
        <DeleteModal
          onCancel={changeDeleteMode}
          input={{
            id: props.comment.id,
            entityId: props.comment.entityId
          }}
          txtConfirmation={capitalize(
            t(_.isEmpty(props.replies) ? TXT_DELETE_COMMENT : TXT_DELETE_COMMENT_WITH_REPLIES)
          )}
          mutation={deleteCommentMutation}
          afterSaving={afterDelete}
        />
      ) : null}
      <Card
        sx={(theme) => ({
          borderLeft: `2px solid ${theme.palette.divider}`,
          boxShadow: 'none',
          borderRadius: 0,
          overflow: 'initial'
        })}
        id={props.comment.id}
      >
        <CardContent>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography variant="subtitle2" component="div">
                {props.comment.sender?.profile?.fullName}
              </Typography>
              <Typography variant="caption" component="div" fontStyle="italic">
                {moment(props.comment.createdDate).format('LL')}
              </Typography>
            </Stack>
            {!!props.comment.resolved ? (
              <Typography variant="caption" component="div" fontStyle="italic">
                {capitalizeFirstLetterEveryword(t(TXT_RESOLVED))}
              </Typography>
            ) : null}
          </Stack>
          <Divider />
          <Stack
            direction="column"
            spacing={1}
            sx={(theme) => ({
              marginTop: theme.spacing(1)
            })}
          >
            {props.comment.senderId === auth.id && !replyEditMode && !editMode ? (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit comment"
                  onClick={changeEditMode}
                  component="span"
                  size="small"
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete comment"
                  onClick={changeDeleteMode}
                  component="span"
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            ) : null}
            {editMode ? (
              <CommentBox {...props.comment} afterSend={afteEdit} onCancelEditMode={changeEditMode} />
            ) : (
              <UserMention value={props.comment.message} disabled />
            )}
          </Stack>
          {!_.isEmpty(props.replies) ? (
            <Box paddingLeft={(theme) => theme.spacing(3)} marginTop={(theme) => theme.spacing(1)}>
              {props.replies?.map((reply) => (
                <CommentCard<T>
                  key={reply.id}
                  comment={reply}
                  afterSend={props.afterSend}
                  afterDelete={props.afterDelete}
                  onReplyEditMode={(isReplyEditing) => setReplyEditMode(() => isReplyEditing)}
                />
              ))}
            </Box>
          ) : null}
        </CardContent>
        {!props.comment.parentCommentId && !replyEditMode && !editMode ? (
          <CommentBox afterSend={props.afterSend} parentComment={props.comment} />
        ) : null}
      </Card>
    </>
  );
};
