import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { GridNoResultsOverlay } from 'components/GridNoResultsOverlay';
import { shareableLinkModel } from 'model';
import { Trans, useTranslation } from 'react-i18next';
import capitalize, { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_CLICK_HERE_TO_OPEN,
  TXT_COPY_LINK,
  TXT_COUNTERPARTY,
  TXT_DELETE,
  TXT_DELETE_SHAREABLE_LINK,
  TXT_EMAIL,
  TXT_EMAIL_HAS_BEEN_SENT,
  TXT_LAST_RESPOND,
  TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD,
  TXT_REQUESTED_BY,
  TXT_RESPONDED,
  TXT_SENDING_EMAIL,
  TXT_SEND_EMAIL
} from '../../../../../shared/translations';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import { useSnackbar } from 'notistack';
import useShareableLink from 'api/shareableLink/useShareableLink';
import { AxiosError } from 'axios';
import './DealPortRequestFormShareableLinks.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { DELETE_SHAREABLE_LINK } from 'api';
import { DeleteModal } from 'components';
import moment from 'moment';

interface DealPortRequestFormShareableLinksProps {
  disabled?: boolean;
  loading?: boolean;
  links: shareableLinkModel[];
  afterDelete: () => void;
}

const dateTimeFormat = 'DD MMM YYYY HH:mm';

export const DealPortRequestFormShareableLinks = (props: DealPortRequestFormShareableLinksProps) => {
  const { t } = useTranslation();
  const { sendDealPortEmail } = useShareableLink();
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<shareableLinkModel>();
  const [openDelete, setShowDelete] = useState(false);

  const toggleDelete = () => setShowDelete((prev) => !prev);

  const onCopyLink = (linkId?: string) => {
    if (!linkId) {
      return;
    }
    const currentURL = new URL(window.location.href);
    linkId = `${currentURL.origin}/deal-port-form/${linkId}`;
    navigator.clipboard.writeText(linkId);
    enqueueSnackbar(
      <Typography variant="subtitle1">
        {capitalize(t(TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD))}.{' '}
        <Trans i18nKey={TXT_CLICK_HERE_TO_OPEN} t={t}>
          <Link href={linkId} color="inherit" target="_blank" rel="noreferrer"></Link>
        </Trans>
        .
      </Typography>,
      {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 5000
      }
    );
  };

  const onSendEmail = async (shareableLink: shareableLinkModel) => {
    if (!shareableLink.linkId || !shareableLink.receiverEmail) {
      return;
    }

    try {
      enqueueSnackbar(
        capitalize(
          t(TXT_SENDING_EMAIL, {
            email: shareableLink.receiverEmail
          })
        ),
        {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }
      );
      await sendDealPortEmail(shareableLink.linkId);
      enqueueSnackbar(
        capitalize(
          t(TXT_EMAIL_HAS_BEEN_SENT, {
            email: shareableLink.receiverEmail
          })
        ),
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 5000
        }
      );
    } catch (e: any) {
      const error = e as AxiosError;
      if (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 5000
        });
      }
    }
  };

  const onDelete = (link: shareableLinkModel) => {
    setSelected(link);
    if (link.id) {
      toggleDelete();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'counterParty',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTY)),
      sortable: false,
      valueGetter: (params: GridValueGetterParams<shareableLinkModel, any>) => {
        return params.row.counterParty?.name;
      }
    },
    {
      field: 'receiverEmail',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_EMAIL)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<shareableLinkModel, any>) => (
        <Link href={`mailto:${params.row.receiverEmail}`}>{params.row.receiverEmail}</Link>
      )
    },
    {
      field: 'requestedBy',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_REQUESTED_BY)),
      sortable: false,
      valueGetter: (params: GridValueGetterParams<shareableLinkModel, any>) => {
        return params.row.requestor?.profile?.fullName;
      }
    },
    {
      field: 'hasResponded',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_RESPONDED)),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<shareableLinkModel, any>) =>
        !!params.row.hasResponded ? (
          <CheckCircleIcon color="success" fontSize="small" />
        ) : (
          <CancelIcon color="error" fontSize="small" />
        )
    },
    {
      field: 'lastRespond',
      flex: 1,
      headerName: capitalizeFirstLetterEveryword(t(TXT_LAST_RESPOND)),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: GridValueGetterParams<shareableLinkModel, any>) =>
        !!params.row.hasResponded && params.row.modifiedDate ? moment(params.row.modifiedDate).format(dateTimeFormat) : '-'
    },
    {
      field: 'actions',
      flex: 1,
      headerName: '',
      sortable: false,
      align: 'center',
      cellClassName: 'action-cell',
      renderCell: (params: GridRenderCellParams<shareableLinkModel, any>) => (
        <Stack direction="row" spacing={1} alignContent="center" justifyContent="center">
          <Tooltip
            title={capitalize(t(TXT_SEND_EMAIL))}
            onClick={() => onSendEmail(params.row)}
            disableHoverListener={!params.row.receiverEmail}
          >
            <IconButton size="medium" disabled={!params.row.receiverEmail}>
              <EmailIcon fontSize="inherit" color={params.row.receiverEmail ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title={capitalize(t(TXT_COPY_LINK))}>
            <IconButton size="medium" onClick={() => onCopyLink(params.row.linkId)}>
              <LinkIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={capitalize(t(TXT_DELETE))}>
            <IconButton size="medium" onClick={() => onDelete(params.row)}>
              <DeleteIcon fontSize="inherit" color="warning" />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    }
  ];

  const afterDelete = () => {
    setSelected(undefined);
    toggleDelete();
    props.afterDelete();
  };

  return (
    <>
      {selected?.id && openDelete ? (
        <DeleteModal
          afterSaving={afterDelete}
          input={{
            id: selected.id
          }}
          mutation={DELETE_SHAREABLE_LINK}
          onCancel={toggleDelete}
          txtConfirmation={capitalize(t(TXT_DELETE_SHAREABLE_LINK)) || ''}
        />
      ) : null}
      <DataGrid
        columns={columns}
        rows={props.links}
        loading={props.loading}
        slots={{
          noRowsOverlay: GridNoResultsOverlay
        }}
        slotProps={{
          pagination: {
            hidden: true
          }
        }}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableRowSelectionOnClick
      />
    </>
  );
};
