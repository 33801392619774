import { gql } from '@apollo/client';
import { counterPartyTypeModel } from '../../../model';

export const getCounterPartyTypesFragment = `
  items {
    id,
    name
  },
  totalCount
`;

export type GetCounterPartyTypesOutput = {
  counterPartyTypes?: {
    items?: [counterPartyTypeModel],
    totalCount?: number;
  }
};

export const GET_COUNTER_PARTY_TYPES = gql`
  query getCounterPartyTypes($take: Int!, $skip: Int!, $where: CounterPartyTypeFilterInput) {
    counterPartyTypes(take: $take, skip: $skip, where: $where) {
      ${getCounterPartyTypesFragment}
    }
  }
`;
