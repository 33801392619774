import { useLazyQuery } from '@apollo/client';
import { Container, Grid, IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetVesselsOutput, GET_VESSELS } from '../../api';
import { GridNoResultsOverlay } from '../../components';
import _ from 'lodash';
import {
  TXT_DELETE,
  TXT_NAME,
  TXT_SEARCH,
  TXT_UPDATE,
  TXT_VESSELS,
  TXT_IMO,
  TXT_VESSEL_TYPE
} from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import { useVesselStyle } from './vesselStyle';
import SearchIcon from '@mui/icons-material/Search';
import { VesselEditor } from './VesselEditor';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { VesselDeleteConfirmation } from './VesselDeleteConfimation';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useTitle } from 'hooks';
import { vesselModel } from 'model';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddButton } from 'components/AddButton';
import { VesselTypeSelector } from 'components/VesselTypeSelector';
import useAuth from 'hooks/useAuth';

export const Vessels = () => {
  useTitle(TXT_VESSELS);
  const vesselStyle = useVesselStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('VESSEL');

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState<vesselModel | undefined>(undefined);

  const [data, setData] = useState<GetVesselsOutput>();

  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    const filter: any[] = [{ vesselType: { eq: typeFilter } }];

    if (nameFilter && nameFilter.length > 0) {
      filter.push({
        or: [{ name: { contains: nameFilter } }, { imo: { contains: nameFilter } }]
      });
    }

    return { and: filter };
  };

  const [getVessels, { loading }] = useLazyQuery<GetVesselsOutput>(GET_VESSELS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: pageSize,
      skip: pageSize * pageNumber
    },
    onCompleted: (data) => {
      setData(() => ({ ...data }));
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    setPageNumber(() => 0);
  }, [nameFilter, typeFilter]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
    setPageNumber(() => 0);
  }, [pageSize]);

  useEffect(() => {
    getVessels();
  }, []);

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const onSearchByTypeChanged = (e: any) => {
    setTypeFilter(e.target.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH));
  const lblVesselType = _.capitalize(t(TXT_VESSEL_TYPE));
  const lblIMO = _.capitalize(t(TXT_IMO));

  const onEdit = (vessel: vesselModel) => () => {
    setSelectedVessel(vessel);
    setOpenEditor(true);
  };

  const onDelete = (vessel: vesselModel) => () => {
    setSelectedVessel(vessel);
    setOpenDeleteConfirmation(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 2,
      sortable: true,
      headerName: capitalizeFirstLetterEveryword(t(TXT_NAME))
    },
    {
      field: 'imo',
      flex: 2,
      sortable: true,
      headerName: lblIMO
    },
    {
      field: 'vesselType',
      flex: 2,
      sortable: false,
      headerName: lblVesselType
    },
    {
      field: 'update',
      flex: 1,
      minWidth: 75,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_UPDATE)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<vesselModel, any>) => {
        return (
          <IconButton
            className={globalStyles.editButton}
            aria-label="update vessel"
            component="span"
            onClick={onEdit(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      }
    },
    {
      field: 'delete',
      flex: 1,
      minWidth: 75,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_DELETE)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<vesselModel, any>) => {
        return (
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="delete vessel"
            component="span"
            onClick={onDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedVessel(undefined);
  };

  const openAddEditor = () => {
    setSelectedVessel(undefined);
    setOpenEditor(true);
  };

  const filterColumnsByRole = (): GridColDef[] => {
    if (!isGuest) {
      return columns;
    }
    return columns.filter((col) => col.field !== 'update' && col.field !== 'delete');
  };

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <VesselEditor {...selectedVessel} onClose={onEditorClosed} afterSave={() => getVessels()} />}
      {openDeleteConfirmation && selectedVessel && (
        <VesselDeleteConfirmation {...selectedVessel} onCancel={closeDeleteConfirmation} afterSaving={getVessels} />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={vesselStyle.content}>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} md={5} xl={5}>
                <TextField
                  id="vessel-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2} xl={2}>
                <VesselTypeSelector onChange={onSearchByTypeChanged} disabled={loading}></VesselTypeSelector>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data?.vessels?.items || []}
                  rowCount={data?.vessels?.totalCount || 0}
                  columns={filterColumnsByRole()}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize,
                        page: pageNumber
                      }
                    }
                  }}
                  checkboxSelection={false}
                  pageSizeOptions={PageSizeOptions}
                  loading={loading}
                  onPaginationModelChange={(page) => {
                    setPageSize(() => page.pageSize);
                    setPageNumber(() => page.page);
                  }}
                  disableColumnFilter
                  disableColumnMenu
                  paginationMode="server"
                  slots={{
                    noRowsOverlay: GridNoResultsOverlay,
                    footer: () => (
                      <AddButton
                        disabled={loading}
                        openAddEditor={!isGuest ? openAddEditor : undefined}
                        isGridFooter={true}
                      />
                    )
                  }}
                  sx={{
                    height: 'calc(100vh - 270px)'
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
