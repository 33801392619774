import { gql } from '@apollo/client';
import { integrationModel } from 'model';

export const getIntegrationsFragment = `
  items {
    id,
    name,
    key
  },
  totalCount
`;

export type GetIntegrationOutput = {
  integrations?: {
    items?: integrationModel[];
    totalCount?: number;
  };
};

export const GET_INTEGRATIONS = gql`
  query getIntegrations($take: Int!, $skip: Int!, $where: IntegrationFilterInput) {
    integrations(take: $take, skip: $skip, where: $where) {
      ${getIntegrationsFragment}
    }
  }
`;
