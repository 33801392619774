import { IconButton, Link } from '@mui/material';
import { contactAddressModel, maritimeDirectoryModel } from 'model';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useGlobalStyles } from 'pages/globalStyles';
import { MaritimeDirectoryAddressesEditor } from './MaritimeDirectoryAddressesEditor';
import { MaritimeDirectoryContactDetailRow } from '../MaritimeDirectoryContactDetailRow';

interface MaritimeDirectoryAddressesColumnProps {
  data: maritimeDirectoryModel;
  showActions?: boolean;
}

export const MaritimeDirectoryAddressesColumn = (props: MaritimeDirectoryAddressesColumnProps) => {
  const [isHover, setIsHover] = useState(false);
  const [maxNumberOfRows] = useState(5);
  const [showEditor, setShowEditor] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const globalStyles = useGlobalStyles();

  const openCloseEditor = () => setShowEditor((prev) => !prev);

  const data = props.data.contact?.addresses || [];

  const sanitizeCollection = (source: contactAddressModel[]): contactAddressModel[] => {
    if (showMore) {
      return source;
    }

    const newCollection = source.slice(0, maxNumberOfRows);
    for (let i = 0; i < maxNumberOfRows - source.length; i++) {
      newCollection.push({
        contactId: undefined,
        id: 'address-undefined-' + i,
        name: '',
        address: ''
      } as contactAddressModel);
    }
    return newCollection;
  };

  return (
    <div
      onMouseOver={() => setIsHover(() => true)}
      onMouseOut={() => setIsHover(() => false)}
      style={{
        width: '100%',
        position: 'relative',
        height: '100%'
      }}
    >
      {showEditor ? (
        <MaritimeDirectoryAddressesEditor
          contactId={props.data.contactId!}
          addresses={props.data.contact?.addresses || []}
          onClose={openCloseEditor}
        />
      ) : null}
      {props.showActions && (
        <IconButton
          className={globalStyles.editButton}
          aria-label="update address"
          component="span"
          sx={{
            visibility: isHover ? 'visible' : 'hidden',
            position: 'absolute',
            top: 0,
            right: 0
          }}
          size="small"
          onClick={openCloseEditor}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      )}
      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          {sanitizeCollection(data).map((x, index) => (
            <MaritimeDirectoryContactDetailRow
              key={`address-${x.id}`}
              index={index}
              totalRows={data.length}
              name={x.name}
              content={<Link href="#">{x.address}</Link>}
              toBeCopied={x.address}
              onShowMore={() => setShowMore(true)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
