import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ADD_HOLIDAY, UPDATE_HOLIDAY, AddHolidayInput } from 'api';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_HOLIDAY,
  TXT_DESCRIPTION,
  TXT_HOLIDAY,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_HOLIDAY
} from '../../../../../../shared/translations';
import { holidayModel } from 'model';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { getDateFormat, ignoreTimeZone } from 'shared/utils';

export interface CalendarEditorProps extends holidayModel {
  onClose: () => void;
  afterSave: () => void;
}

const schema = yup.object({
  name: yup.string().required(),
  date: yup.date().required()
});

export const CalendarEditor = (props: CalendarEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutateHoliday, { loading }] = useMutation<holidayModel>(props.id ? UPDATE_HOLIDAY : ADD_HOLIDAY, {
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSave();
      props.onClose();
    }
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors }
  } = useForm<AddHolidayInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      date: props.date
    }
  });

  const mutate = (input: AddHolidayInput) => {
    let mutateData: any = {
      name: input.name,
      date: moment(input.date).format('YYYY-MM-DD'),
      culture: props.culture
    };

    if (!!props.id) {
      mutateData = {
        ...mutateData,
        id: props.id
      };
    }

    return mutateHoliday({
      variables: {
        input: {
          ...mutateData
        }
      }
    });
  };

  const onSave: SubmitHandler<AddHolidayInput> = (data) => {
    mutate(data);
  };

  const title = t(props.id ? TXT_UPDATE_HOLIDAY : TXT_CREATE_HOLIDAY);
  const dateFormat = getDateFormat();

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSave)}>
        <AppBar position="relative">
          <Toolbar>{title.toUpperCase()}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="date"
                render={({ fieldState }) => (
                  <DatePicker
                    format={dateFormat}
                    defaultValue={!!props.date ? moment(props.date) : null}
                    label={capitalizeFirstLetterEveryword(TXT_HOLIDAY)}
                    slotProps={{
                      textField: {
                        error: !!fieldState.error,
                        fullWidth: true,
                        disabled: loading
                      }
                    }}
                    onChange={(value) => {
                      setValue('date', value === null ? undefined : ignoreTimeZone(value.toDate()), {
                        shouldDirty: true
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_DESCRIPTION))}
                fullWidth
                disabled={loading}
                {...register('name', { required: true })}
                error={!!formErrors.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
