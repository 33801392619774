import { gql } from '@apollo/client';

export interface AddProductInput {
  name: string;
}

export interface UpdateProductInput extends AddProductInput {
  id: string;
}

export interface DeleteProductInput {
  id: string;
}

export const ADD_PRODUCT = gql`
  mutation addProduct($input: AddProductInput!) {
    addProduct(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input)
  }
`;
