import { IconButton, Typography } from '@mui/material';
import { laytimeChartReportListModel, laytimeChartReportModel } from 'model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';

import {
  TXT_BILL_OF_LADING_DATE,
  TXT_RECEIVER,
  TXT_CURRENCY,
  TXT_DISCHARGE_COUNTRY,
  TXT_REPORT_COPIED,
  TXT_PRODUCT,
  TXT_SHIPOWNER,
  TXT_SUPPLIER,
  TXT_TERM,
  TXT_TRADER,
  TXT_VESSEL,
  TXT_DEAL_NUMBER,
  TXT_PROFIT_AND_LOSS,
  TXT_UNLOADING,
  TXT_LOADING,
  TXT_NET
} from '../../../../../shared/translations';
import { useVoyageProfitAndLossReportContentStyles } from './VoyageProfitAndLossReportContentStyle';
import clsx from 'clsx';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useRef } from 'react';

interface VoyageProfitAndLossReportContentProps extends laytimeChartReportListModel {}

export const VoyageProfitAndLossReportContent = (props: VoyageProfitAndLossReportContentProps) => {
  const { t } = useTranslation();
  const style = useVoyageProfitAndLossReportContentStyles();

  const getRowLoadingNet = (row:laytimeChartReportModel): number => {
    return row.loadingSupplier + row.loadingReceiver + row.loadingShipowner;
  };

  const getRowUnloadingNet = (row:laytimeChartReportModel): number => {
    return row.unloadingSupplier + row.unloadingReceiver + row.unloadingShipowner;
  };

  const getRowPnL = (row:laytimeChartReportModel): number => {
    return getRowLoadingNet(row) + getRowUnloadingNet(row);
  };

  const billOfLadingDate = props.results.find( (x) => true)?.blDate;
  const pnl = props.results.reduce( (a, x) => a + getRowPnL(x), 0);
  const clients = _.uniqBy(props.results, 'client' ).map((x) => x.client).join(',');
  const products = _.uniqBy(props.results, 'product' ).map((x) => x.product).join(',');
  const terms = _.uniqBy(props.results, 'incoTerm' ).map((x) => x.incoTerm).join(',');
  const traders = _.uniqBy(props.results, 'trader' ).map((x) => x.trader).join(',');
  const currencies = _.uniqBy(props.results, 'currency' ).map((x) => x.currency).join(',');
  const countryOfDestination = _.uniqBy(props.results, 'countryOfDestination' ).map((x) => x.countryOfDestination).join(',');
  const { enqueueSnackbar } = useSnackbar();
  const reportRef = useRef<HTMLDivElement>(null);

  const copyReport = async () => {
    const src = reportRef.current;
    if (src === null) {
      return;
    }
    const innerHtml = src.innerHTML;
    const type = 'text/html';
    const blob = new Blob([innerHtml], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data);
    enqueueSnackbar(<Typography variant="subtitle1">{_.capitalize(t(TXT_REPORT_COPIED))}</Typography>, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      autoHideDuration: 2000
    });
  };

  return (
    <>
      <div className={style.pnlActions}>
        <IconButton size="small" onClick={copyReport}>
          <ContentCopyIcon />
        </IconButton>
      </div>
      <div id="pnl-report" ref={reportRef} className={style.pnlContainer}>
        <div className={style.pnlTitle}>
          <h2>{props.voyageName.toUpperCase()}</h2>
        </div>
        <div className={style.pnlInfo}>
          <div
            style={{
              flexGrow: 1, maxWidth: '50%'
            }}
          >
            <table className={style.pnlTable}>
              <tbody>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_VESSEL))}</td>
                  <td>{props.vessel}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_BILL_OF_LADING_DATE))}</td>
                  <td>{billOfLadingDate ? moment(billOfLadingDate).format('MMM DD, yyyy') : ''}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}</td>
                  <td>{clients}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_PRODUCT))}</td>
                  <td>{products}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ flexGrow: 1, maxWidth: '50%' }}>
            <table className={style.pnlTable}>
              <tbody>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_CURRENCY))}</td>
                  <td>{currencies}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_TERM))}</td>
                  <td>{terms}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_TRADER))}</td>
                  <td>{traders}</td>
                </tr>
                <tr>
                  <td className={style.pnlInfoDesc}>{capitalizeFirstLetterEveryword(t(TXT_DISCHARGE_COUNTRY))}</td>
                  <td>{countryOfDestination}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
        <br></br>
        <table className={style.pnlTable}>
          <thead>
            <tr>
              <td style={{ border: '0' }}></td>
              <td className={clsx({ [style.header2]: true })} colSpan={4} align='center'>{capitalizeFirstLetterEveryword(t(TXT_LOADING))}</td>
              <td className={clsx({ [style.header2]: true })} colSpan={4} align='center'>{capitalizeFirstLetterEveryword(t(TXT_UNLOADING))}</td>
              <td style={{ border: '0' }}></td>
              <td style={{ border: '0' }}></td>
            </tr>
            <tr className={clsx({ [style.header2]: true })}>
              <td >{capitalizeFirstLetterEveryword(t(TXT_DEAL_NUMBER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_SUPPLIER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_SHIPOWNER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_NET))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_SUPPLIER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_SHIPOWNER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_NET))}</td>
              <td>{capitalizeFirstLetterEveryword(t(TXT_PROFIT_AND_LOSS))}</td>
            </tr>
          </thead>
          <tbody>
            { Object.values(props.results).map((row, index) => {
              const loadingPnl = getRowLoadingNet(row);
              const unLoadingPnl = getRowUnloadingNet(row);
              const rowPnl = getRowPnL(row);

              return (
                <tr key={index}>
                  <td>{row.deal}</td>
                  <td
                    className={clsx(
                      { [style.profit]: row.loadingSupplier >= 0 },
                      { [style.loss]: row.loadingSupplier < 0 }
                    )}
                  >
                    {row.loadingSupplier.toLocaleString()}
                  </td>
                  <td
                    className={clsx(
                      { [style.profit]: row.loadingShipowner >= 0 },
                      { [style.loss]: row.loadingShipowner < 0 }
                    )}
                  >
                    {row.loadingShipowner.toLocaleString()}
                  </td>
                  <td
                    className={clsx(
                      { [style.profit]: row.loadingReceiver >= 0 },
                      { [style.loss]: row.loadingReceiver < 0 }
                    )}
                  >
                    {row.loadingReceiver.toLocaleString()}
                  </td>
                  <td className={clsx({ [style.profit]: loadingPnl >= 0 }, { [style.loss]: loadingPnl < 0 })}>{loadingPnl.toLocaleString()}</td>
                  <td
                    className={clsx(
                      { [style.profit]: row.unloadingSupplier >= 0 },
                      { [style.loss]: row.unloadingSupplier < 0 }
                    )}
                  >
                    {row.unloadingSupplier.toLocaleString()}
                  </td>
                  <td
                    className={clsx(
                      { [style.profit]: row.unloadingShipowner >= 0 },
                      { [style.loss]: row.unloadingShipowner < 0 }
                    )}
                  >
                    {row.unloadingShipowner.toLocaleString()}
                  </td>
                  <td
                    className={clsx(
                      { [style.profit]: row.unloadingReceiver >= 0 },
                      { [style.loss]: row.unloadingReceiver < 0 }
                    )}
                  >
                    {row.unloadingReceiver.toLocaleString()}
                  </td>
                  <td className={clsx({ [style.profit]: unLoadingPnl >= 0 }, { [style.loss]: unLoadingPnl < 0 })}>{unLoadingPnl.toLocaleString()}</td>
                  <td className={clsx({ [style.profit]: rowPnl >= 0 }, { [style.loss]: rowPnl < 0 })}>{rowPnl.toLocaleString()}</td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={9}></td>
              <td className={clsx({ [style.profit]: pnl >= 0 }, { [style.loss]: pnl < 0 })}>{pnl.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
