import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ADD_COUNTER_PARTY,
  CounterPartyMutationOutput,
  UPDATE_COUNTER_PARTY,
  addCounterPartyInput
} from '../../../api';
import { useApolloErrorHandler } from '../../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_COUNTERPARTY,
  TXT_NAME,
  TXT_REFERENCE,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_COUNTERPARTY
} from '../../../../../../shared/translations';
import { CounterPartyType, CounterPartyTypes, counterPartyModel } from '../../../model';
import { BusinessUnitSelector, CounterPartyTypeSelector } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface CounterPartyEditorProps extends counterPartyModel {
  defaultCounterPartyTypes?: CounterPartyType[];
  onClose: () => void;
  afterSave: (newCounterParty: counterPartyModel) => void;
}

const schema = yup.object({
  name: yup.string().required(),
  reference: yup.string(),
  counterPartyTypeIds: yup.array().of(yup.string()).required().min(1),
  businessUnitId: yup.string().nullable()
});

export const CounterPartyEditor = (props: CounterPartyEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutateCounterParty, { loading }] = useMutation<CounterPartyMutationOutput>(
    props.id ? UPDATE_COUNTER_PARTY : ADD_COUNTER_PARTY,
    {
      onError: apolloErrorHandler,
      onCompleted: (data) => {
        props.afterSave(data.counterParty);
        props.onClose();
      }
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    setValue
  } = useForm<addCounterPartyInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      reference: props.reference,
      counterPartyTypeIds: !!props.counterPartyTypes
        ? props.counterPartyTypes.map((type) => type.id!)
        : !!props.defaultCounterPartyTypes && !props.id
          ? props.defaultCounterPartyTypes.map((x) => CounterPartyTypes[x])
          : [],
      businessUnitId: props.businessUnitId
    }
  });

  const mutate = (input: addCounterPartyInput) => {
    if (!props.id) {
      return mutateCounterParty({
        variables: {
          input
        }
      });
    }

    return mutateCounterParty({
      variables: {
        input: {
          ...input,
          id: props.id
        }
      }
    });
  };

  const onSave: SubmitHandler<addCounterPartyInput> = (data) => {
    mutate(data);
  };

  const title = t(props.id ? TXT_UPDATE_COUNTERPARTY : TXT_CREATE_COUNTERPARTY);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSave)();
          e.stopPropagation();
        }}
      >
        <AppBar position="relative">
          <Toolbar>{title.toUpperCase()}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                disabled={loading}
                {...register('name', { required: true })}
                error={!!formErrors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="reference"
                label={capitalizeFirstLetterEveryword(t(TXT_REFERENCE))}
                fullWidth
                disabled={loading}
                {...register('reference')}
                error={!!formErrors.reference}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="counterPartyTypeIds"
                control={control}
                render={() => (
                  <CounterPartyTypeSelector
                    counterPartyTypes={props.counterPartyTypes}
                    onSelectedChange={(types) => {
                      setValue('counterPartyTypeIds', types === null ? [] : types.map((type) => type.id!));
                    }}
                    error={!!formErrors.counterPartyTypeIds}
                    defaultCounterPartyTypes={props.defaultCounterPartyTypes}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="businessUnitId"
                control={control}
                render={() => (
                  <BusinessUnitSelector
                    businessUnit={props.businessUnit}
                    onChanged={(businessUnit) => {
                      setValue('businessUnitId', businessUnit?.id);
                    }}
                    error={!!formErrors.businessUnitId}
                    disabled={loading}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
