export const CalendarRuleOptions = ['SSHINC', 'SHEX', 'FHEX', 'FSHEX', 'SATNOON', 'SSHEX', 'SATAFTSHEX', 'CUSTOM', 'FIXED'] as const;
export type CalendarRuleOption = (typeof CalendarRuleOptions)[number];

export const useCalendarRule = () => {
  const getStartDefaultValue = (value?: CalendarRuleOption): number | undefined => {
    const shexDesc = value ?? '';

    switch (shexDesc) {
    case 'SHEX':
      return 0; // SUN

    case 'FHEX':
    case 'FSHEX':
      return 5; // Friday

    case 'SATNOON':
    case 'SATAFTSHEX':
    case 'SSHEX':
      return 6; // SAT
    }

    return undefined;
  };

  const getEndDefaultValue = (value?: CalendarRuleOption): number | undefined => {
    const shexDesc = value ?? '';

    switch (shexDesc) {
    case 'SHEX':
    case 'SATAFTSHEX':
    case 'SSHEX':
      return 0; // SUN

    case 'FHEX':
      return 5; // Friday

    case 'FSHEX':
    case 'SATNOON':
      return 6; // SAT
    }
    return undefined;
  };

  const getStartDefaultTimeValue = (value?: CalendarRuleOption): string | undefined => {
    const shexDesc = value ?? '';

    switch (shexDesc) {
    case 'SATAFTSHEX':
    case 'SATNOON':
      return '12:00'; // 12:00

    case 'SHEX':
    case 'SSHEX':
    case 'FSHEX':
    case 'FHEX':
      return '00:00'; // SUN
    }
    return undefined;
  };

  return {
    getStartDefaultValue,
    getEndDefaultValue,
    getStartDefaultTimeValue
  };
};
