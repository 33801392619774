import { ApolloError } from '@apollo/client';
import {
  Grid,
  Card,
  CardProps,
  CircularProgress,
  IconButton,
  Typography,
  CardContent,
  CardHeader,
  CardActionArea
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetDealsOutput } from 'api';
import { CustomError } from 'components/CustomError';
import _ from 'lodash';
import {
  TXT_LOADING,
  TXT_NO_DATA_FOUND,
  TXT_QUANTITY,
  TXT_LOADING_PORT,
  TXT_LOADING_PORTS,
  TXT_UNLOADING_PORT,
  TXT_DRAFT,
  TXT_NEGOTIATING,
  TXT_PROCESSED,
  TXT_AGREED,
  TXT_RECEIVERS,
  TXT_RECEIVER,
  TXT_UNLOADING_PORTS,
  TXT_BILL_OF_LADING_DATE,
  TXT_NOT_APPLICABLE
} from '../../../../../../shared/translations';
import { useGlobalStyles } from '../../globalStyles';
import { useDealCardStyle } from './dealCardStyle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomCardContainer } from 'components';
import { useNavigate } from 'react-router-dom';
import { laycanCalculationPath } from 'shared/links';
import { dealModel } from 'model';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getBLDate, getLoadingQuantity, getPorts, getReceivers } from 'shared/utils';
import LaunchIcon from '@mui/icons-material/Launch';

interface DealCardsProps extends CardProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetDealsOutput;
  onEdit?: (deal: dealModel) => void;
  onDelete?: (deal: dealModel) => void;
}

export const DealCards = (props: DealCardsProps) => {
  const style = useDealCardStyle();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();

  const txtLoadingPort = capitalizeFirstLetterEveryword(t(TXT_LOADING_PORT));
  const txtLoadingPorts = capitalizeFirstLetterEveryword(t(TXT_LOADING_PORTS));
  const txtUnloadingPort = capitalizeFirstLetterEveryword(t(TXT_UNLOADING_PORT));
  const txtUnloadingPorts = capitalizeFirstLetterEveryword(t(TXT_UNLOADING_PORTS));

  const txtQuantity = capitalizeFirstLetterEveryword(t(TXT_QUANTITY));
  const txtReceivers = capitalizeFirstLetterEveryword(t(TXT_RECEIVERS));
  const txtReceiver = capitalizeFirstLetterEveryword(t(TXT_RECEIVER));
  const txtBLDate = capitalizeFirstLetterEveryword(t(TXT_BILL_OF_LADING_DATE));

  const txtDraft = t(TXT_DRAFT);
  const txtNegotiating = t(TXT_NEGOTIATING);
  const txtProcessed = t(TXT_PROCESSED);
  const txtAgreed = t(TXT_AGREED);
  // const txtNotApplicable = t(TXT_NOT_APPLICABLE);

  return (
    <>
      {props.loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <h3>{t(TXT_LOADING).toUpperCase()}</h3>
        </div>
      )}
      {!props.loading &&
        (!props.data || !props.data.deals || !props.data.deals.items || props.data.deals.items.length <= 0) &&
        !props.loading && <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>}
      {!props.loading &&
        props.data &&
        props.data.deals &&
        props.data.deals.items &&
        props.data.deals.items.length > 0 && (
        <CustomCardContainer>
          {props.data.deals.items.map((deal) => {
            const receivers = getReceivers(deal);
            const unloadingPorts = getPorts(deal.unloadingPorts ?? []);
            const loadingPorts = getPorts(deal.loadingPorts ?? []);
            const blDate = getBLDate(deal.loadingPorts ?? []);
            const loadingQuantity = getLoadingQuantity(deal.loadingPorts ?? []);
            return (
              <div key={deal.id}>
                <Card className={style.card} variant="outlined">
                  <CardActionArea onClick={() => navigate(`${laycanCalculationPath}/${deal.id}`)}>
                    <CardHeader
                      disableTypography
                      title={
                        <Grid container spacing={2}>
                          <Grid item xs={11}>
                            <Typography variant="h5">{deal.name}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              textAlign: 'end'
                            }}
                          >
                            <LaunchIcon />
                          </Grid>
                        </Grid>
                      }
                      style={{ borderBottom: '1px solid' }}
                    />
                  </CardActionArea>
                  <CardContent>
                    <Grid container direction="row" alignItems="center" spacing={1}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">
                          {`${loadingPorts.length <= 1 ? txtLoadingPort : txtLoadingPorts}: `}
                          {loadingPorts.join(', ')}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`${unloadingPorts.length <= 1 ? txtUnloadingPort : txtUnloadingPorts}: `}
                          {unloadingPorts.join(', ')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1">
                          {`${receivers.length <= 1 ? txtReceiver : txtReceivers}: ` + receivers}
                        </Typography>
                        <Typography variant="subtitle1" paddingRight={'15px'}></Typography>
                        <Typography variant="subtitle1">{`${txtBLDate}: ${blDate}`}</Typography>
                        <Typography variant="subtitle1">
                          {`${txtQuantity}: ${(loadingQuantity || 0.0).toLocaleString(undefined, {
                            minimumFractionDigits: 0
                          })} ${deal.massUnit}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} md={2.4} className={style.status}>
                            <Typography variant="button">{txtDraft}</Typography>
                            <Typography variant="h6">{deal.calculationCounts?.draft}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2.4} className={style.status}>
                            <Typography variant="button">{txtNegotiating}</Typography>
                            <Typography variant="h6">{deal.calculationCounts?.negotiating}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2.4} className={style.status}>
                            <Typography variant="button">{txtAgreed}</Typography>
                            <Typography variant="h6">{deal.calculationCounts?.agreed}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2.4} className={style.status}>
                            <Typography variant="button">{txtProcessed}</Typography>
                            <Typography variant="h6">{deal.calculationCounts?.processed}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2.4} className={style.status}>
                            <Typography variant="button" noWrap>
                              {TXT_NOT_APPLICABLE}
                            </Typography>
                            <Typography variant="h6">{deal.calculationCounts?.notApplicable}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {!!props.onEdit && (
                        <Grid item xs={6} md={1} className={style.actionButtons}>
                          <IconButton
                            className={globalStyles.editButton}
                            aria-label="edit deal"
                            component="span"
                            onClick={() => props.onEdit && props.onEdit(deal)}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      )}
                      {!!props.onDelete && (
                        <Grid item xs={6} md={1} className={style.actionButtons}>
                          <IconButton
                            className={globalStyles.deleteButton}
                            aria-label="delete deal"
                            component="span"
                            onClick={() => props.onDelete && props.onDelete(deal)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </CustomCardContainer>
      )}
    </>
  );
};
