import { atom } from 'recoil';

const pageStateKey = 'pageState';

export type PageStateType = {
  title: string;
  isSigningIn: boolean;
};

export const defaultPageState: PageStateType = {
  title: '',
  isSigningIn: false
};

export const pageState = atom({
  key: pageStateKey,
  default: defaultPageState
});
