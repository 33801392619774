import { Box, Button, ButtonProps } from '@mui/material';
import { MicrosoftIcon } from '../MicrosoftIcon';
import microsoftLoginButtonStyle from './microsoftLoginButtonStyle';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMicrosoftLogin } from 'hooks/useMicrosoftLogin';

export interface MicrosoftLoginButtonProps extends ButtonProps {
  signinType: 'loginPopup' | 'loginRedirect';
  text?: string;
  onReturnedAccountInfo: (authenticationResult: AuthenticationResult) => void;
  preSignIn?: () => void;
  postSignIn?: () => void;
}

export const MicrosoftLoginButton = (
  // eslint-disable-next-line max-len
  { signinType, onClick, onReturnedAccountInfo, preSignIn, postSignIn, text, ...rest }: MicrosoftLoginButtonProps
) => {
  const classes = microsoftLoginButtonStyle();
  const microsoftLogin = useMicrosoftLogin();
  const onClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    microsoftLogin({ signinType, onReturnedAccountInfo, preSignIn, postSignIn });
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button {...rest} onClick={onClicked}>
      <Box className={classes.box}>
        <MicrosoftIcon />
      </Box>
      {!!text ? text : 'Microsoft'}
    </Button>
  );
};
