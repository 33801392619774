/* eslint-disable indent */
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { getDateFormat, getDateTimeFormat, ignoreTimeZone } from 'shared/utils';
import { OverrideVesselInfoInput, OverrideVesselInfoOutput, OVERRIDE_VESSEL_INFO } from 'api';
import { baseEntityModel } from 'model';
import moment from 'moment';
import {
  refreshLaytimeCalculationsAfterSave,
  useDemurragePageDispatch,
  useDemurragePageState
} from 'pages/DemurragePage/DemurragePageContext';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_BL_QUANTITY,
  TXT_CANCEL,
  TXT_CLEAR,
  TXT_FIELD,
  TXT_LAYCAN_END,
  TXT_LAYCAN_START,
  TXT_LOADING_COMMENCED,
  TXT_LOADING_COMPLETED,
  TXT_NEW_VALUE,
  TXT_NOR_TENDERED,
  TXT_OLD_VALUE,
  TXT_REAL_LT_BEGINS,
  TXT_LAYTIME_ENDS,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UNLOADING_COMMENCED,
  TXT_UNLOADING_COMPLETED,
  TXT_VALID_NOR,
  TXT_VESSEL_INFORMATION_OVERRIDE,
  TXT_MODIFYING_LOCKED_CALC_VALIDATION,
  TXT_PERMITTED_TIME_ROUNDING,
  TXT_DAYS,
  TXT_HOURS,
  TXT_MINUTES
} from '../../../../../../shared/translations';
import { DateTimePicker } from 'components/DateTimePicker/DateTimePicker';
import * as yup from 'yup';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomTable, NumberTextField, textFieldDefaultProps } from 'components';
import { useGlobalStyles } from 'pages/globalStyles';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { formatMinutes, getTotalTimeFormatted } from '../utils';

export interface VesselOverrideEditorProps extends baseEntityModel {
  onClose: () => void;
}

const schema = yup.object().shape({
  laycanStartDateOverride: yup.string().nullable(),
  laycanEndDateOverride: yup.string().nullable(),
  quantityOverride: yup
    .number()
    .nullable()
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
  validNorOverride: yup.string().nullable(),
  completedDateOverride: yup.string().nullable(),
  laytimeTimeEndsOverride: yup.string().nullable(),
  realLaytimeBeginsOverride: yup.string().nullable(),
  norTenderedDateOverride: yup.string().nullable(),
  commencedDateOverride: yup.string().nullable(),
  permittedTimeRoundingOverride: yup.number().nullable(),
  deductionOffset: yup.number().nullable()
});

export const VesselOverrideEditor = (props: VesselOverrideEditorProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedLaytimeCalculation, demurrageDeal, selectedTab } = useDemurragePageState();
  const dispatch = useDemurragePageDispatch();
  const { t } = useTranslation();
  const title = t(TXT_VESSEL_INFORMATION_OVERRIDE);
  const globalStyles = useGlobalStyles();

  const inputRefDays = useRef<HTMLInputElement>(null);
  const inputRefHours = useRef<HTMLInputElement>(null);
  const inputRefMinutes = useRef<HTMLInputElement>(null);

  const oldDeductionOffset = selectedLaytimeCalculation?.deductionOffset;
  const totaltimeFormatted = getTotalTimeFormatted(oldDeductionOffset);
  const [daysOffset, setDaysOffset] = useState<number | undefined>(totaltimeFormatted?.days);
  const [hoursOffset, setHoursOffset] = useState<number | undefined>(totaltimeFormatted?.hours);
  const [minutesOffset, setMinutesOffset] = useState<number | undefined>(totaltimeFormatted?.minutes);

  const [latestDate, setLatestDate] = useState<Date | undefined>(undefined);
  const [isLoading] = useState<boolean>(selectedTab === 'loading');
  const dealPort = (isLoading ? demurrageDeal?.loadingPorts : demurrageDeal?.unloadingPorts)?.find(
    (port) => port.id === selectedLaytimeCalculation?.dealPortId
  );

  const showLockedCalcValidation = () => {
    if (selectedLaytimeCalculation?.isCalculationOverriden) {
      enqueueSnackbar(<Typography variant="subtitle1">{_.capitalize(t(TXT_MODIFYING_LOCKED_CALC_VALIDATION))}</Typography>, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 10000
      });
    }
  };

  const updateDeductionOffset = () => {
    if (daysOffset || hoursOffset || minutesOffset) {
      const days = (daysOffset??0) * (60*24);
      const hours = (hoursOffset??0) * 60;
      const mins = (minutesOffset??0);

      setValue('deductionOffset', days + hours + mins );
    } else {
      setValue('deductionOffset', undefined);
    }
  };

  const clearDeductionOffsetControls = () => {
      setDaysOffset(undefined);
      setHoursOffset(undefined);
      setMinutesOffset(undefined);

      if (inputRefDays && inputRefDays.current) {
        inputRefDays.current.value = 'undefined';
      }

      if (inputRefHours && inputRefHours.current) {
        inputRefHours.current.value = 'undefined';
      }

      if (inputRefMinutes && inputRefMinutes.current) {
        inputRefMinutes.current.value = 'undefined';
      }
  };

  useEffect(()=>{
      updateDeductionOffset();
    },
  [daysOffset, hoursOffset, minutesOffset]);

  const dateFormat = getDateFormat();
  const dateTimeFormat = getDateTimeFormat();

  const { apolloErrorHandler } = useApolloErrorHandler();
  const [mutate, { loading }] = useMutation<OverrideVesselInfoOutput>(OVERRIDE_VESSEL_INFO, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      dispatch(refreshLaytimeCalculationsAfterSave(data.overrideVesselInfo));
      showLockedCalcValidation();
      props.onClose();
    }
  });

  const { register, control, getValues, setValue, handleSubmit } = useForm<OverrideVesselInfoInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      laytimeCalculationId: selectedLaytimeCalculation?.id,
      laycanStartDateOverride: selectedLaytimeCalculation?.laycanStartDateOverride,
      laycanEndDateOverride: selectedLaytimeCalculation?.laycanEndDateOverride,
      quantityOverride: selectedLaytimeCalculation?.quantityOverride,
      validNorOverride: selectedLaytimeCalculation?.validNorOverride,
      completedDateOverride: selectedLaytimeCalculation?.completedDateOverride,
      laytimeTimeEndsOverride: selectedLaytimeCalculation?.laytimeTimeEndsOverride,
      realLaytimeBeginsOverride: selectedLaytimeCalculation?.realLaytimeBeginsOverride,
      norTenderedDateOverride: selectedLaytimeCalculation?.norTenderedDateOverride,
      commencedDateOverride: selectedLaytimeCalculation?.commencedDateOverride,
      permittedTimeRoundingOverride: selectedLaytimeCalculation?.permittedTimeRoundingOverride,
      deductionOffset: selectedLaytimeCalculation?.deductionOffset
    }
  });

  useWatch({ control });

  const onSave: SubmitHandler<OverrideVesselInfoInput> = (data) => {
    const input: OverrideVesselInfoInput = {
      ...data,
      laycanStartDateOverride: ignoreTimeZone(data.laycanStartDateOverride),
      laycanEndDateOverride: ignoreTimeZone(data.laycanEndDateOverride),
      validNorOverride: ignoreTimeZone(data.validNorOverride),
      completedDateOverride: ignoreTimeZone(data.completedDateOverride),
      laytimeTimeEndsOverride: ignoreTimeZone(data.laytimeTimeEndsOverride),
      realLaytimeBeginsOverride: ignoreTimeZone(data.realLaytimeBeginsOverride),
      norTenderedDateOverride: ignoreTimeZone(data.norTenderedDateOverride),
      commencedDateOverride: ignoreTimeZone(data.commencedDateOverride)
    };
    mutate({
      variables: {
        input
      }
    });
  };

  const oldLaycanStart =
    (selectedLaytimeCalculation?.laycanStartDate || dealPort?.laycanStartDate) &&
    moment(selectedLaytimeCalculation?.laycanStartDate || dealPort?.laycanStartDate);
  const oldLaycanEnd =
    (selectedLaytimeCalculation?.laycanEndDate || dealPort?.laycanEndDate) &&
    moment(dealPort?.laycanEndDate || selectedLaytimeCalculation?.laycanEndDate);
  const oldValidNor =
    (selectedLaytimeCalculation?.validNor || dealPort?.validNor) &&
    moment(selectedLaytimeCalculation?.validNor || dealPort?.validNor);
  const oldCompleted =
    (selectedLaytimeCalculation?.completedDate || dealPort?.completedDate) &&
    moment(selectedLaytimeCalculation?.completedDate || dealPort?.completedDate);
  const oldlaytimeTimeEnds =
    (selectedLaytimeCalculation?.laytimeTimeEndsOverride) &&
    moment(selectedLaytimeCalculation?.laytimeTimeEndsOverride || dealPort?.completedDate);
  const oldRealTimeBegins =
    selectedLaytimeCalculation?.realLaytimeBegins && moment(selectedLaytimeCalculation.realLaytimeBegins);
  const oldNorTendered =
    (selectedLaytimeCalculation?.norTenderedDate || dealPort?.norTenderedDate) &&
    moment(selectedLaytimeCalculation?.norTenderedDate || dealPort?.norTenderedDate);
  const oldCommenced =
    (selectedLaytimeCalculation?.commencedDate || dealPort?.commencedDate) &&
    moment(selectedLaytimeCalculation?.commencedDate || dealPort?.commencedDate);
  const oldQuantity = selectedLaytimeCalculation?.quantity || dealPort?.quantity;

  const oldRoundingPrecission = selectedLaytimeCalculation?.permittedTimeRoundingOverride;
  const uom = selectedLaytimeCalculation?.massUnit ?? demurrageDeal?.massUnit;

  const rows: {
    field: keyof OverrideVesselInfoInput;
    title: string;
    oldValue?: string;
    editor: ReactNode;
    onClear?: () => void;
  }[] = [
    {
      field: 'laycanStartDateOverride',
      title: TXT_LAYCAN_START,
      oldValue: oldLaycanStart?.format(dateFormat),
      editor: isLoading && (
        <DateTimePicker
          dateFormat={dateFormat}
          value={getValues().laycanStartDateOverride ? moment(getValues().laycanStartDateOverride) : undefined}
          onChange={(date) => setValue('laycanStartDateOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
        />
      )
    },
    {
      field: 'laycanEndDateOverride',
      title: TXT_LAYCAN_END,
      oldValue: oldLaycanEnd?.format(dateFormat),
      editor: isLoading && (
        <DateTimePicker
          dateFormat={dateFormat}
          value={getValues().laycanEndDateOverride ? moment(getValues().laycanEndDateOverride) : undefined}
          onChange={(date) => setValue('laycanEndDateOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
        />
      )
    },
    {
      field: 'quantityOverride',
      title: TXT_BL_QUANTITY,
      oldValue: `${oldQuantity?.toLocaleString()} ${uom}`,
      editor: (
        <NumberTextField
          {...textFieldDefaultProps}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">{uom}</InputAdornment>
          }}
          inputProps={{
            step: '0.001'
          }}
          {...register('quantityOverride')}
          disabled={loading}
        />
      )
    },
    {
      field: 'commencedDateOverride',
      title: selectedTab === 'loading' ? TXT_LOADING_COMMENCED : TXT_UNLOADING_COMMENCED,
      oldValue: oldCommenced?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().commencedDateOverride ? moment(getValues().commencedDateOverride) : undefined}
          onChange={(date) => setValue('commencedDateOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'completedDateOverride',
      title: selectedTab === 'loading' ? TXT_LOADING_COMPLETED : TXT_UNLOADING_COMPLETED,
      oldValue: oldCompleted?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().completedDateOverride ? moment(getValues().completedDateOverride) : undefined}
          onChange={(date) => setValue('completedDateOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'norTenderedDateOverride',
      title: TXT_NOR_TENDERED,
      oldValue: oldNorTendered?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().norTenderedDateOverride ? moment(getValues().norTenderedDateOverride) : undefined}
          onChange={(date) => setValue('norTenderedDateOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'validNorOverride',
      title: TXT_VALID_NOR,
      oldValue: oldValidNor?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().validNorOverride ? moment(getValues().validNorOverride) : undefined}
          onChange={(date) => setValue('validNorOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'realLaytimeBeginsOverride',
      title: TXT_REAL_LT_BEGINS,
      oldValue: oldRealTimeBegins?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().realLaytimeBeginsOverride ? moment(getValues().realLaytimeBeginsOverride) : undefined}
          onChange={(date) => setValue('realLaytimeBeginsOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'laytimeTimeEndsOverride',
      title: TXT_LAYTIME_ENDS,
      oldValue: oldCompleted?.format(dateTimeFormat),
      editor: (
        <DateTimePicker
          dateFormat={dateTimeFormat}
          value={getValues().laytimeTimeEndsOverride ? moment(getValues().laytimeTimeEndsOverride) : undefined}
          onChange={(date) => setValue('laytimeTimeEndsOverride', date?.toDate())}
          disabled={loading}
          openToDate={latestDate}
          showTimeInput
        />
      )
    },
    {
      field: 'permittedTimeRoundingOverride',
      title: TXT_PERMITTED_TIME_ROUNDING,
      oldValue: `${oldRoundingPrecission?.toLocaleString() ?? ''}`,
      editor: (
        <NumberTextField
          {...textFieldDefaultProps}
          fullWidth
          {...register('permittedTimeRoundingOverride')}
        />
      )
    },
    {
      field: 'deductionOffset',
      title: 'Deduction Offset',
      oldValue: `${formatMinutes(oldDeductionOffset) ?? ''}`,
      onClear: clearDeductionOffsetControls,
      editor: (
        <Stack direction='row' spacing={0} >
          <NumberTextField
            id='days'
            ref={inputRefDays}
            label={capitalizeFirstLetterEveryword(t(TXT_DAYS))}
            style={{ maxWidth: '70px', minWidth: '70px' }}
            {...textFieldDefaultProps}
            value={daysOffset}
            onChange={ (e) => {
              const newVal = parseFloat(e.target.value);
              setDaysOffset(newVal);
            }}
            inputProps={{
              step: '1',
              min: 0
            }}
          />
          <div>&nbsp;&nbsp;</div>
          <NumberTextField
            id='hours'
            ref={inputRefHours}
            label={capitalizeFirstLetterEveryword(t(TXT_HOURS))}
            style={{ maxWidth: '70px', minWidth: '70px' }}
            {...textFieldDefaultProps}
            value={hoursOffset}
            onChange={ (e) => {
              const newVal = parseFloat(e.target.value);
              setHoursOffset(newVal);
            }}
            inputProps={{
              step: '1',
              min: 0,
              max: 23
            }}
          />
          <div>&nbsp;&nbsp;</div>
          <NumberTextField
            id='minutes'
            ref={inputRefMinutes}
            label={capitalizeFirstLetterEveryword(t(TXT_MINUTES))}
            style={{ maxWidth: '70px', minWidth: '70px' }}
            {...textFieldDefaultProps}
            value={minutesOffset}
            onChange={ (e) => {
              const newVal = parseFloat(e.target.value);
              setMinutesOffset(newVal);
            }}
            inputProps={{
              step: '1',
              min: 0,
              max: 59
            }}
          />
        </Stack>
      )
    }
  ];

  const watchLaycanStart = useWatch({
    control,
    name: 'laycanStartDateOverride'
  });

  const watchLaycanEnd = useWatch({
    control,
    name: 'laycanEndDateOverride'
  });

  const watchValidNor = useWatch({
    control,
    name: 'validNorOverride'
  });

  const watchCompletedDate = useWatch({
    control,
    name: 'completedDateOverride'
  });

  const WatchLaytimeEndsDate = useWatch({
    control,
    name: 'laytimeTimeEndsOverride'
  });

  const watchRTBegins = useWatch({
    control,
    name: 'realLaytimeBeginsOverride'
  });

  const watchNorTendered = useWatch({
    control,
    name: 'norTenderedDateOverride'
  });

  const watchCommenced = useWatch({
    control,
    name: 'commencedDateOverride'
  });

  useEffect(() => {
    setLatestDate(
      _.max([
        oldLaycanStart?.toDate(),
        watchLaycanStart,
        oldLaycanEnd?.toDate(),
        watchLaycanEnd,
        oldValidNor?.toDate(),
        watchValidNor,
        oldCompleted?.toDate(),
        watchCompletedDate,
        oldlaytimeTimeEnds?.toDate(),
        WatchLaytimeEndsDate,
        oldRealTimeBegins?.toDate(),
        watchRTBegins,
        oldNorTendered?.toDate(),
        watchNorTendered,
        oldCommenced?.toDate(),
        watchCommenced
      ])
    );
  }, [
    watchLaycanStart,
    watchLaycanEnd,
    watchValidNor,
    watchCompletedDate,
    WatchLaytimeEndsDate,
    watchRTBegins,
    watchNorTendered,
    watchCommenced
  ]);

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form id="frm-vessel-info-override" onSubmit={handleSubmit(onSave)}>
          <CustomTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t(TXT_FIELD).toUpperCase()}</TableCell>
                <TableCell>{t(TXT_OLD_VALUE).toUpperCase()}</TableCell>
                <TableCell>{t(TXT_NEW_VALUE).toUpperCase()}</TableCell>
                <TableCell
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {t(TXT_CLEAR).toUpperCase()}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .filter((x) => x.editor)
                .map((row) => (
                  <TableRow key={row.field}>
                    <TableCell>{capitalizeFirstLetterEveryword(t(row.title))}</TableCell>
                    <TableCell>{row.oldValue}</TableCell>
                    <TableCell>{row.editor}</TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <IconButton
                        aria-label="editFinalResult"
                        className={globalStyles.deleteButton}
                        component="div"
                        onClick={() => {
                          setValue(row.field, undefined);
                          if (row.onClear) {
                            row.onClear();
                          }
                        }}
                        size="small"
                        disabled={!getValues()[row.field] || loading}
                      >
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </CustomTable>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="frm-vessel-info-override" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
