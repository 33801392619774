import { useLazyQuery } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetCounterPartiesOutput, GET_COUNTER_PARTIES } from '../../api';
import { CounterPartyTypeSelector, PaginationBar } from '../../components';
import _ from 'lodash';
import { TXT_COUNTERPARTIES, TXT_SEARCH_BY_NAME } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import { useCounterPartyStyle } from './counterPartyStyle';
import { CounterPartyTable } from './CounterPartyTable';
import SearchIcon from '@mui/icons-material/Search';
import { CounterPartyEditor } from './CounterPartyEditor';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { CounterPartyDeleteConfirmation } from './CounterPartyDeleteConfimation';
import { counterPartyModel, counterPartyTypeModel } from 'model';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useTitle } from 'hooks';
import useAuth from 'hooks/useAuth';

export const CounterPartyPage = () => {
  useTitle(TXT_COUNTERPARTIES);
  const counterPartyStyle = useCounterPartyStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState('');

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedCounterParty, setSelectedCounterParty] = useState<counterPartyModel | undefined>(undefined);
  const [counterPartyTypes, setCounterPartyTypes] = useState<counterPartyTypeModel[] | null>(null);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    if (counterPartyTypes === null || counterPartyTypes.length <= 0) {
      return null;
    }
    return {
      counterPartyTypes: {
        some: {
          id: {
            in: counterPartyTypes.map((type) => type.id)
          }
        }
      }
    };
  };

  const [getCounterParties, { loading, error, data, refetch }] = useLazyQuery<GetCounterPartiesOutput>(
    GET_COUNTER_PARTIES,
    {
      fetchPolicy: 'network-only',
      variables: {
        name: nameFilter || null,
        take: pageSize,
        skip: pageSize * (pageNumber - 1),
        where: getFilter()
      },
      onError: apolloErrorHandler
    }
  );

  const fetch = () => {
    if (!refetch) {
      getCounterParties();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (counterParty: counterPartyModel) => {
    setSelectedCounterParty(counterParty);
    setOpenEditor(true);
  };

  const onDelete = (counterParty: counterPartyModel) => {
    setSelectedCounterParty(counterParty);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedCounterParty(undefined);
  };

  const onEditorAfterSaving = () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  };

  const openAddEditor = () => {
    setSelectedCounterParty(undefined);
    setOpenEditor(true);
  };

  const onSeletedCounterPartyTypesChanged = (values: counterPartyTypeModel[] | null) => {
    setCounterPartyTypes(values);
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter, counterPartyTypes]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <CounterPartyEditor {...selectedCounterParty} onClose={onEditorClosed} afterSave={fetch} />}
      {openDeleteConfirmation && selectedCounterParty && (
        <CounterPartyDeleteConfirmation
          {...selectedCounterParty}
          onCancel={closeDeleteConfirmation}
          afterSaving={onEditorAfterSaving}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={counterPartyStyle.content}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12} md={6} lg={6}>
                <CounterPartyTypeSelector multiple onSelectedChange={onSeletedCounterPartyTypesChanged} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="counterParty-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CounterPartyTable
                  loading={loading}
                  error={error}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  showActions={!isGuest}
                />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={
                    data && data.counterParties && data.counterParties.totalCount ? data.counterParties.totalCount : 0
                  }
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={!isGuest ? openAddEditor : undefined}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
