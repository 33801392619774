import { useLazyQuery } from '@apollo/client';
import { Backdrop, CircularProgress, Typography, useTheme } from '@mui/material';
import { GetLaytimeChartForDealReportOutput, GET_LAYTIME_CHART_DEAL_REPORT } from 'api';
import { CustomError } from 'components';
import { useApolloErrorHandler } from 'hooks';
import { t } from 'i18next';
import { dealModel } from 'model';
import { useEffect } from 'react';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { VoyageProfitAndLossReportContent } from './VoyageProfitAndLossReportContent';

interface VoyageProfitAndLossReportProps {
  deal?: dealModel;
}

export const VoyageProfitAndLossReport = (props: VoyageProfitAndLossReportProps) => {
  if (!props.deal || !props.deal.id) {
    return null;
  }

  const theme = useTheme();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [getReport, { loading, data }] = useLazyQuery<GetLaytimeChartForDealReportOutput>(GET_LAYTIME_CHART_DEAL_REPORT, {
    fetchPolicy: 'network-only',
    variables: {
      dealId: props.deal.id
    },
    onCompleted: (resp) => {
    },
    onError: (err) => {
      return apolloErrorHandler;
    }
  });

  useEffect(() => {
    if (!props.deal || !props.deal.id) {
      return;
    }
    getReport();
  }, [props.deal]);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
          open={loading}
        >
          <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
            {capitalizeFirstLetterEveryword(t(TXT_LOADING))}
          </Typography>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {
        !loading && !data &&
        <CustomError>
          {capitalizeFirstLetterEveryword(t(TXT_NO_DATA_FOUND))}
        </CustomError>
      }
      {
        !loading && !!data &&
        <div>
          <VoyageProfitAndLossReportContent {...data.laytimeChartForDealReport} />
        </div>
      }
    </>
  );
};
