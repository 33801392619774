import { Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AddBargeTripInput } from 'api';
import { CalculationStatusSelector, CalendarRule, CounterPartySelector, NumberTextField } from 'components';
import { CalculationStatusCommentSelector } from 'components/CalculationStatusCommentSelector';
import _ from 'lodash';
import { useGlobalStyles } from 'pages/globalStyles';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ARRIVAL_DATE_OVERRIDE,
  TXT_COMPLETION_DATE_OVERRIDE,
  TXT_DAY,
  TXT_DAYS,
  TXT_DEMURRAGE_P_DAY,
  TXT_FREE_TIME,
  TXT_HOLIDAYS,
  TXT_OWNER,
  TXT_RECEIVER,
  TXT_RELEASE_DATE_OVERRIDE
} from '../../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { generate as generateUUID } from 'short-uuid';
import { getDateFormat } from 'shared/utils';
import moment from 'moment';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface BargeTripEditorCounterpartyProps {
  isOwner?: boolean;
  disabled?: boolean;
}

export const BargeTripEditorCounterparty = (props: BargeTripEditorCounterpartyProps) => {
  const { isOwner } = props;
  const { control, setValue } = useFormContext<AddBargeTripInput>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: isOwner ? 'owner.holidays' : 'receiver.holidays'
  });
  const [holidayDate, setHolidayDate] = useState<moment.Moment | null>(null);
  const globalStyles = useGlobalStyles();
  const dateFormat = getDateFormat();


  const { t } = useTranslation();

  const watch = useWatch({
    control,
    name: isOwner ? 'owner' : 'receiver'
  });

  const watchCurrency = useWatch({
    control,
    name: 'currency'
  });

  const newHolidayPrefix = 'new-holiday';

  const onHolidayDateChanged = (newDate: moment.Moment | null) => {
    setHolidayDate(() => newDate);
  };

  const addNewHoliday = () => {
    if (!holidayDate) {
      return;
    }

    append({
      strId: `${newHolidayPrefix}-${generateUUID()}`,
      holiday: holidayDate.toDate()
    });

    setHolidayDate(() => null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.counterPartyId' : 'receiver.counterPartyId'}
          render={({ fieldState }) => (
            <CounterPartySelector
              counterParty={watch.counterParty}
              error={!!fieldState.error}
              onSelectedChange={(cp) => {
                setValue(!!isOwner ? 'owner.counterParty' : 'receiver.counterParty', cp ?? undefined);
                setValue(!!isOwner ? 'owner.counterPartyId' : 'receiver.counterPartyId', cp?.id ?? '', {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={capitalizeFirstLetterEveryword(t(isOwner ? TXT_OWNER : TXT_RECEIVER))}
              disabled={!!props.disabled}
              counterPartyType="ALL"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.freeDays' : 'receiver.freeDays'}
          render={({ fieldState }) => (
            <NumberTextField
              fullWidth
              label={capitalizeFirstLetterEveryword(t(TXT_FREE_TIME))}
              value={watch.freeDays ?? ''}
              error={!!fieldState.error}
              onChange={(e) => {
                const newValue = e.target.value;
                setValue(
                  !!isOwner ? 'owner.freeDays' : 'receiver.freeDays',
                  newValue.trim().length <= 0 || _.isNaN(newValue) ? undefined : +newValue,
                  {
                    shouldDirty: true
                  }
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {capitalizeFirstLetterEveryword(t((watch.freeDays || 0) > 1 ? TXT_DAYS : TXT_DAY))}
                  </InputAdornment>
                )
              }}
              inputProps={{
                step: '1',
                min: 0
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.demurrageRate' : 'receiver.demurrageRate'}
          render={({ fieldState }) => (
            <NumberTextField
              fullWidth
              label={capitalizeFirstLetterEveryword(t(TXT_DEMURRAGE_P_DAY))}
              value={watch.demurrageRate ?? ''}
              error={!!fieldState.error}
              onChange={(e) => {
                const newValue = e.target.value;
                setValue(
                  !!isOwner ? 'owner.demurrageRate' : 'receiver.demurrageRate',
                  newValue.trim().length <= 0 || _.isNaN(newValue) ? undefined : parseFloat(newValue),
                  {
                    shouldDirty: true
                  }
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {watchCurrency} / {capitalizeFirstLetterEveryword(t(TXT_DAY))}
                  </InputAdornment>
                )
              }}
              inputProps={{
                step: '0.001'
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.calendarRule' : 'receiver.calendarRule'}
          render={({ fieldState }) => (
            <CalendarRule
              size="small"
              value={watch.calendarRule}
              onChanged={(newRule) => setValue(!!isOwner ? 'owner.calendarRule' : 'receiver.calendarRule', newRule)}
              error={!!fieldState.error}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.status' : 'receiver.status'}
          render={({ fieldState }) => (
            <CalculationStatusSelector
              value={watch.status}
              onChanged={(newStatus) =>
                setValue(!!isOwner ? 'owner.status' : 'receiver.status', newStatus, {
                  shouldDirty: true,
                  shouldValidate: true
                })
              }
              error={!!fieldState.error}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={!!isOwner ? 'owner.statusComment' : 'receiver.statusComment'}
          render={({ fieldState }) => (
            <CalculationStatusCommentSelector
              value={watch.statusComment}
              onChanged={(newComment) =>
                setValue(!!isOwner ? 'owner.statusComment' : 'receiver.statusComment', newComment, {
                  shouldDirty: true,
                  shouldValidate: true
                })
              }
              error={!!fieldState.error}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {!isOwner && (
          <Controller
            control={control}
            name={!!isOwner ? 'owner.unloadingArrivalTimeOverride' : 'receiver.unloadingArrivalTimeOverride'}
            render={({ fieldState }) => (
              <DatePicker
                value={!watch.unloadingArrivalTimeOverride ? null : moment(watch.unloadingArrivalTimeOverride)}
                format={dateFormat}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!fieldState.error,
                    autoComplete: 'off'
                  },
                  field: {
                    clearable: true
                  }
                }}
                onChange={(value) => {
                  setValue(!!isOwner ? 'owner.unloadingArrivalTimeOverride' : 'receiver.unloadingArrivalTimeOverride', value?.toDate() ?? undefined, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }}
                label={`${capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE_OVERRIDE))}`}
                disabled={!!props.disabled}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        { !isOwner && (
          <Controller
            control={control}
            name={!!isOwner ? 'owner.unloadingCompletedTimeOverride' : 'receiver.unloadingCompletedTimeOverride'}
            render={({ fieldState }) => (
              <DatePicker
                value={!watch.unloadingCompletedTimeOverride ? null : moment(watch.unloadingCompletedTimeOverride)}
                format={dateFormat}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!fieldState.error,
                    autoComplete: 'off'
                  },
                  field: {
                    clearable: true
                  }
                }}
                onChange={(value) => {
                  setValue(!!isOwner ? 'owner.unloadingCompletedTimeOverride' : 'receiver.unloadingCompletedTimeOverride', value?.toDate() ?? undefined, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }}
                label={`${capitalizeFirstLetterEveryword(t(TXT_COMPLETION_DATE_OVERRIDE))}`}
                disabled={!!props.disabled}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        { !isOwner && (
          <Controller
            control={control}
            name={!!isOwner ? 'owner.unloadingReleaseTimeOverride' : 'receiver.unloadingReleaseTimeOverride'}
            render={({ fieldState }) => (
              <DatePicker
                value={!watch.unloadingReleaseTimeOverride ? null : moment(watch.unloadingReleaseTimeOverride)}
                format={dateFormat}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!fieldState.error,
                    autoComplete: 'off'
                  },
                  field: {
                    clearable: true
                  }
                }}
                onChange={(value) => {
                  setValue(!!isOwner ? 'owner.unloadingReleaseTimeOverride' : 'receiver.unloadingReleaseTimeOverride', value?.toDate() ?? undefined, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }}
                label={`${capitalizeFirstLetterEveryword(t(TXT_RELEASE_DATE_OVERRIDE))}`}
                disabled={!!props.disabled}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={4}>
        <fieldset>
          <legend>{capitalizeFirstLetterEveryword(t(TXT_HOLIDAYS))}</legend>
          <Grid container spacing={1} alignItems="center">
            {fields.map((holiday, index) => (
              <Grid key={holiday.id} item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>{moment(holiday.holiday).format(dateFormat)}</Typography>
                  <IconButton
                    className={globalStyles.deleteButton}
                    aria-label="delete holiday"
                    component="span"
                    onClick={() => remove(index)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Grid>
            ))}
            <Grid item xs={10}>
              <DatePicker
                disabled={!!props.disabled}
                format={dateFormat}
                label={dateFormat.toUpperCase()}
                value={holidayDate}
                onChange={onHolidayDateChanged}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small'
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                component="span"
                size="large"
                onClick={addNewHoliday}
                color="info"
                disabled={!!props.disabled || !holidayDate}
              >
                <AddCircleIcon />
              </IconButton>
            </Grid>
          </Grid>
        </fieldset>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={4}></Grid>
    </Grid>
  );
};
