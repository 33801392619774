import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Toolbar
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_CREATE_PRODUCT,
  TXT_FIELD_IS_REQUIRED,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_PRODUCT
} from '../../../../../shared/translations';
import { ADD_PRODUCT, UPDATE_PRODUCT } from '../../api';
import _ from 'lodash';

export interface ProductEditorProps {
  id?: string;
  name?: string;
  onClose: () => void;
  afterSave: () => void;
}

export const ProductEditor = (props: ProductEditorProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState(props.name);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutateProduct, { loading }] = useMutation(props.id ? UPDATE_PRODUCT : ADD_PRODUCT, {
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSave();
      props.onClose();
    }
  });

  const validateName = () => !!name && name.trim().length > 0;
  const [isNameValid, setIsNameValid] = useState(validateName());
  useEffect(() => {
    setIsNameValid(validateName());
  }, [name]);

  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const validate = () => {
    return validateName();
  };

  const mutate = () => {
    if (!props.id) {
      return mutateProduct({
        variables: {
          input: {
            name
          }
        }
      });
    }

    return mutateProduct({
      variables: {
        input: {
          id: props.id,
          name
        }
      }
    });
  };

  const onSave = () => {
    if (!validate()) {
      return;
    }

    mutate();
  };

  const txtErrorField = _.capitalize(t(TXT_FIELD_IS_REQUIRED));
  const title = t(props.id ? TXT_UPDATE_PRODUCT : TXT_CREATE_PRODUCT);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth={true} onClose={props.onClose}>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            autoFocus
            id="name"
            label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
            value={name || ''}
            onChange={onNameChanged}
            fullWidth
            disabled={loading}
            error={!name || name.trim().length <= 0}
            helperText={!isNameValid && txtErrorField}
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button autoFocus color="inherit" onClick={onSave}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
