import _ from 'lodash';
import { dealModel, calculationSelectionModel, laytimeCalculationModel } from 'model';
import { getIncoTerm } from 'pages/DemurragePage/utils';

export const getAvailableCalculations = (deal?: dealModel): calculationSelectionModel[] => {
  if (!deal) {
    return [];
  }

  let selections: calculationSelectionModel[] = [
    ...getReceivers(deal, true),
    ...getReceivers(deal),
    ...getShipowners(deal, true),
    ...getShipowners(deal),
    ...getSuppliers(deal, true),
    ...getSuppliers(deal)
  ];

  const onlyFOB = !deal.splitQuantities?.some((split) => getIncoTerm(split) !== 'FOB');

  if (onlyFOB) {
    // If only FOB, show only the loading ones and without Shipowner
    selections = [...selections.filter((item) => !!item.isLoading && item.counterPartyType !== 'SHIPOWNER')];
  }

  if (deal.parentLaytimeCalculationId) {
    let calc: laytimeCalculationModel | undefined = undefined;
    if (!_.isEmpty(deal.loadingCalculations)) {
      calc = deal.loadingCalculations![0];
    } else if (!_.isEmpty(deal.unloadingCalculations)) {
      calc = deal.unloadingCalculations![0];
    }

    if (calc) {
      selections = [
        ...selections.filter(
          (x) =>
            calc?.counterPartyType?.name.toLowerCase() === x.counterPartyType.toLowerCase() &&
            calc!.counterPartyId === x.counterParty.id && calc!.dealPortId === x.dealPort.id
        )
      ];
    }
  }

  return selections;
};

const getReceivers = (deal: dealModel, isLoading?: boolean): calculationSelectionModel[] => {
  if (!deal.splitQuantities || _.isEmpty(deal.splitQuantities)) {
    return [];
  }

  let receivers = [...deal.splitQuantities];

  // For loading port, non-FOB splits are not needed
  if (!!isLoading) {
    receivers = [...receivers.filter((split) => getIncoTerm(split) === 'FOB')];
  }

  // we dont need receiver calcs for Internal business units
  receivers = [...receivers.filter((split) => _.isEmpty(split.counterParty?.businessUnitId))];


  const groupedReceivers = _.groupBy(receivers, (split) => {
    return `${!!isLoading ? split.loadingPortId : split.unloadingPortId}${split.counterPartyId}`;
  });

  const items: calculationSelectionModel[] = [];
  _.forEach(groupedReceivers, (value) => {
    if (_.isEmpty(value)) {
      return;
    }
    const newItem: calculationSelectionModel = {
      counterPartyType: 'CLIENT',
      counterParty: value[0].counterParty!,
      splits: [...value],
      dealsHint: Array.from(new Set(value.map((x) => x.name))).join(','),
      dealPort: !!isLoading ? value[0].loadingPort! : value[0].unloadingPort!,
      isLoading
    };
    items.push(newItem);
  });

  return items;
};

const getShipowners = (deal: dealModel, isLoading?: boolean): calculationSelectionModel[] => {
  if (!deal.shipowner) {
    return [];
  }

  const ports = !!isLoading ? deal.loadingPorts : deal.unloadingPorts;
  if (_.isEmpty(ports)) {
    return [];
  }

  return (ports || []).map((port) => {
    const dealsHint = !!isLoading
      ? deal.splitQuantities?.filter((x) => x.loadingPortId === port.id)
      : deal.splitQuantities?.filter((x) => x.unloadingPortId === port.id);

    const item: calculationSelectionModel = {
      counterPartyType: 'SHIPOWNER',
      counterParty: deal.shipowner!,
      dealPort: port,
      dealsHint: Array.from(new Set(dealsHint?.map((x) => x.name))).join(','),
      isLoading
    };
    return item;
  });
};

const getSuppliers = (deal: dealModel, isLoading?: boolean): calculationSelectionModel[] => {
  const ports = !!isLoading ? deal.loadingPorts : deal.unloadingPorts;

  if (_.isEmpty(deal.suppliers) || !ports) {
    return [];
  }

  const applicableSuppliers = (deal.suppliers || [])
    .filter((supplier) => ports.some((port) => port.id === supplier.applicableDealPort?.id));

  if (!isLoading) {
    const applicableSuppliersWithIncoTerm = applicableSuppliers
      .filter((supplier) => !_.isNil(supplier.incoTerm));
    if ( applicableSuppliersWithIncoTerm.length > 0 && !applicableSuppliersWithIncoTerm?.some((supplier) => supplier.incoTerm === 'CFR')) {
      return [];
    } else if (applicableSuppliersWithIncoTerm.length === 0 && !deal.splitQuantities?.some((split) => split.incoTerm === 'CFR')) {
      return [];
    }
  }

  return applicableSuppliers
    .map((dealSupplier) => {
      const dealsHint = !!isLoading
        ? deal.splitQuantities?.filter((x) => x.loadingPortId === dealSupplier.applicableDealPort?.id)
        : deal.splitQuantities?.filter((x) => x.unloadingPortId === dealSupplier.applicableDealPort?.id);

      const item: calculationSelectionModel = {
        counterPartyType: 'SUPPLIER',
        counterParty: dealSupplier.supplier!,
        dealSupplierId: dealSupplier.id,
        dealSupplier,
        dealPort: dealSupplier.applicableDealPort!,
        dealsHint: Array.from(new Set(dealsHint?.map((x) => x.name))).join(','),
        isLoading
      };
      return item;
    });
};
