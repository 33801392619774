import { Box, CircularProgress, Divider, Drawer, IconButton, Stack, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { CommentContext } from 'components/CommentButton';
import { CommentBox } from 'components/CommentBox';
import { OnDataOptions, useLazyQuery, useSubscription } from '@apollo/client';
import { GetCommentsOutput, baseCommentDeleteOutput, baseCommentOutput } from 'api';
import { useApolloErrorHandler } from 'hooks';
import { baseCommentModel } from 'model';
import { CommentCard } from 'components/CommentCard';
import moment from 'moment';

interface CommentsProps {
  title?: ReactNode;
  goToCommentId?: string;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const CommentsContainer = styled('div')(({ theme }) => ({
  'padding': theme.spacing(2, 1),
  'overflow': 'auto',
  'width': '100%',
  'height': '100%',
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
    height: '0.4em'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.getContrastText(theme.palette.background.paper)
  },
  '& > div': {
    marginBottom: '10px'
  },
  'backgroundColor': theme.palette.divider
}));

export const Comments = <T extends baseCommentModel>(props: CommentsProps) => {
  const drawerWidth = 450;
  const { getCommentsQuery, entityId, onClose, commentAddedSubscription, commentUpdatedSubscription, commentDeletedSubscription } = useContext(CommentContext);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [pageIndex] = useState(0);
  const [comments, setComments] = useState<T[]>([]);
  const [, setHasMoreData] = useState(false);
  const pageSize = 300;

  const [getComments, { loading }] = useLazyQuery<GetCommentsOutput<T>>(getCommentsQuery!, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageIndex * pageSize,
      where: {
        entityId: {
          eq: entityId
        }
      }
    },
    onCompleted: (data) => {
      setComments(() => [...data.comments.items]);
      setHasMoreData(() => !!data.comments.pageInfo?.hasNextPage);
    },
    onError: apolloErrorHandler
  });

  const afterSend = (comment: T) => {
    const excluded = comments.filter((x) => x.id !== comment.id);
    setComments(() => [comment, ...excluded]);
  };

  const afterDelete = (comment: T) => {
    const excluded = comments.filter((x) => x.id !== comment.id || x.parentCommentId === comment.id);
    setComments(() => [...excluded]);
  };

  const sortComment = (a: T, b: T) => {
    const x = moment(a.createdDate);
    const y = moment(b.createdDate);

    if (x.isBefore(y)) {
      return 1;
    }

    return -1;
  };

  const sortReplies = (a: T, b: T) => {
    const x = moment(a.createdDate);
    const y = moment(b.createdDate);

    if (x.isBefore(y)) {
      return -1;
    }

    return 1;
  };

  const onCommentAddedAndUpdated = (dataOptions: OnDataOptions<baseCommentOutput<T>>) => {
    const result = dataOptions.data.data?.result;
    if (!result) {
      return;
    }

    afterSend(result);
  };

  const onCommentDeleted = (dataOptions: OnDataOptions<baseCommentDeleteOutput>) => {
    const deletedId = dataOptions.data.data?.deletedId;
    if (!deletedId) {
      return;
    }
    const data: baseCommentModel = {
      id: deletedId
    };
    afterDelete(data as T);
  };

  commentAddedSubscription && useSubscription<baseCommentOutput<T>>(commentAddedSubscription, {
    variables: {
      entityId
    },
    onData: onCommentAddedAndUpdated,
    onError: apolloErrorHandler
  });

  commentUpdatedSubscription && useSubscription<baseCommentOutput<T>>(commentUpdatedSubscription, {
    variables: {
      entityId
    },
    onData: onCommentAddedAndUpdated,
    onError: apolloErrorHandler
  });

  commentDeletedSubscription && useSubscription<baseCommentDeleteOutput>(commentDeletedSubscription, {
    variables: {
      entityId
    },
    onData: onCommentDeleted,
    onError: apolloErrorHandler
  });

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (!props.goToCommentId || loading) {
      return;
    }

    const selectedComment = document.getElementById(props.goToCommentId);
    if (!selectedComment) {
      return;
    }
    selectedComment.scrollIntoView();
  }, [comments]);

  return (
    <Drawer
      sx={{
        'width': drawerWidth,
        'flexShrink': 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
      variant="persistent"
      anchor="right"
      PaperProps={{
        sx: (theme) => ({
          marginTop: theme.spacing(8)
        })
      }}
      open={true}
    >
      <DrawerHeader>
        <IconButton onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
        <Box display="flex" width="100%" justifyContent="center">
          {props.title}
        </Box>
      </DrawerHeader>
      <Divider />
      <CommentBox<T> afterSend={afterSend} />
      <Divider />
      <CommentsContainer id='comments-container'>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : (
          <Stack direction="column" spacing={1}>
            {comments
              .filter((x) => !x.parentCommentId)
              .sort(sortComment)
              .map((comment) => (
                <CommentCard<T>
                  key={comment.id}
                  comment={comment}
                  afterSend={afterSend}
                  afterDelete={afterDelete}
                  replies={comments.filter((x) => x.parentCommentId === comment.id).sort(sortReplies)}
                />
              ))}
          </Stack>
        )}
      </CommentsContainer>
    </Drawer>
  );
};
