import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import useExcelAPI, { BargeReportRequest } from 'hooks/useExcelAPI';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_BARGE_REPORT_FILTER, TXT_CANCEL, TXT_DOWNLOAD, TXT_DOWNLOADING } from '../../../../../shared/translations';
import { BargeReportFilterContent } from './BargeReportFilterContent';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ignoreTimeZone } from 'shared/utils';

interface BargeReportFilterProps {
  onCancel: () => void;
}

const schema = yup.object().shape({
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  counterParties: yup.array().min(1, 'min 1 item')
});

export const BargeReportFilter = (props: BargeReportFilterProps) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const methods = useForm<BargeReportRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: new Date(`Jan 1 $${currentYear}`),
      endDate: new Date(`Dec 31 $${currentYear}`),
      counterParties: []
    },
    reValidateMode: 'onChange'
  });
  const { getBargeReport } = useExcelAPI();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<BargeReportRequest> = async (params) => {
    try {
      setIsLoading(true);
      await getBargeReport({
        ...params,
        startDate: ignoreTimeZone(params.startDate)!,
        endDate: ignoreTimeZone(params.endDate)!,
        counterParties: params.counterParties
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      props.onCancel();
    }
  };

  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle align="center">{t(TXT_BARGE_REPORT_FILTER).toUpperCase()}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form id="frm-barge-filter" onSubmit={methods.handleSubmit(onSubmit)}>
              <BargeReportFilterContent disabled={isLoading} />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="secondary" disabled={isLoading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button disabled={isLoading || !methods.formState.isValid} type="submit" form="frm-barge-filter">
            {isLoading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(isLoading ? TXT_DOWNLOADING : TXT_DOWNLOAD)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
