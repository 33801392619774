import { Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { AddDealInput } from 'api';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { CurrencySelector } from 'components/CurrencySelector';
import { MassUnitSelector } from 'components/MassUnitSelector';
import { VesselSelector } from 'components/VesselSelector';
import { useContext, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ACTIVE, TXT_CLOSED, TXT_NAME, TXT_SHIPOWNER } from '../../../../../shared/translations';
import { DealWizardContext } from './DealWizard';

export const DealWizardInfo = () => {
  const { control, register, setValue, formState } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();

  const lblActive = capitalizeFirstLetterEveryword(t(TXT_ACTIVE));
  const lblClosed = capitalizeFirstLetterEveryword(t(TXT_CLOSED));
  const lblName = capitalizeFirstLetterEveryword(t(TXT_NAME));

  const watchName = useWatch({
    control,
    name: 'name'
  });

  const watchShipowner = useWatch({
    control,
    name: 'shipowner'
  });

  const watchVessel = useWatch({
    control,
    name: 'vessel'
  });

  const watchSplitQuantities = useWatch({
    control,
    name: 'splitQuantities'
  });

  const watchCurrency = useWatch({
    control,
    name: 'currency'
  });

  const watchMassUnit = useWatch({
    control,
    name: 'massUnit'
  });

  useEffect(() => {
    let newName = watchVessel?.name || '';

    if (watchSplitQuantities && watchSplitQuantities.length > 0) {
      const splitQuantityNames = watchSplitQuantities.filter((split) => !!split.name).map((split) => split.name);
      const uniqueNames = Array.from(new Set(splitQuantityNames));

      if (uniqueNames.length > 0) {
        newName = `${newName} [${uniqueNames.join(', ')}]`;
      }
    }

    setValue('name', newName);
  }, [watchVessel, watchSplitQuantities]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          <Controller
            name="isActive"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Typography>{lblClosed}</Typography>
                <Switch onChange={onChange} checked={value} disabled={isDisabled} />
                <Typography>{lblActive}</Typography>
              </>
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextField
          id="name"
          label={lblName}
          value={watchName || ''}
          variant="standard"
          fullWidth
          {...register('name')}
          error={!!formState.errors.name}
          disabled={isDisabled}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          name="shipownerId"
          control={control}
          render={({ formState }) => (
            <CounterPartySelector
              counterParty={watchShipowner}
              onChanged={(cParty) => {
                setValue('shipownerId', cParty?.id ?? '');
                setValue('shipowner', cParty);
              }}
              error={!!formState.errors.shipownerId}
              label={t(TXT_SHIPOWNER)}
              counterPartyType="SHIPOWNER"
              disabled={isDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          name="vesselId"
          control={control}
          render={({ formState }) => (
            <VesselSelector
              vessel={watchVessel}
              onChanged={(vessel) => {
                setValue('vesselId', vessel?.id || '');
                setValue('vessel', vessel);
              }}
              error={!!formState.errors.vesselId}
              disabled={isDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          name="currency"
          control={control}
          render={({ formState }) => (
            <CurrencySelector
              currency={watchCurrency}
              onSelectedChange={(currency) => {
                setValue('currency', currency || '');
              }}
              error={!!formState.errors.currency}
              disabled={isDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Controller
          name="massUnit"
          control={control}
          render={({ formState }) => (
            <MassUnitSelector
              massUnit={watchMassUnit}
              onSelectedChange={(unit) => {
                setValue('massUnit', unit || '');
              }}
              error={!!formState.errors.massUnit}
              disabled={isDisabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
