import { IconButton, useTheme } from '@mui/material';
import { AddDealInput } from 'api';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { DealPortEditorContent } from 'components/DealPortEditorContent';
import { TXT_UNLOADING_PORT } from '../../../../../shared/translations';
import { useContext } from 'react';
import { DealWizardContext } from './DealWizard';

interface DealWizardUnlodingPortProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const DealWizardUnlodingPort = (props: DealWizardUnlodingPortProps) => {
  const { control } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const watchUnloadingPort = useWatch({
    control,
    name: `unloadingPorts.${props.index}`
  });

  return (
    <fieldset>
      <legend>{watchUnloadingPort.port?.name || `${t(TXT_UNLOADING_PORT).toUpperCase()} #${props.index + 1}`}</legend>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginBottom: theme.spacing(1)
        }}
      >
        <IconButton
          aria-label={`delete-unloading-port-${props.index}`}
          onClick={() => props.handleRemove(props.index)}
          disabled={isDisabled}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <DealPortEditorContent index={props.index} />
    </fieldset>
  );
};
