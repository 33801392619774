import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { interruptionDefinitionModel } from 'model';
import { GetInterruptionDefinitionsOutput } from '../../api';
import { CustomTable } from '../../components';
import { InterruptionDefinitionsColumns } from './interruptionDefinitionColumns';
import { InterruptionDefinitionsTableBody } from './InterruptionDefinitionsTableBody';
import { InterruptionDefinitionsTableHead } from './InterruptionDefinitionsTableHead';

interface InterruptionDefinitionsTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetInterruptionDefinitionsOutput;
  onEdit: (interruptionDefinition: interruptionDefinitionModel) => void;
  onDelete: (interruptionDefinition: interruptionDefinitionModel) => void;
  showActions?: boolean;
}

export const InterruptionDefinitionsTable = ({
  error,
  loading,
  data,
  onEdit,
  onDelete,
  showActions,
  ...rest
}: InterruptionDefinitionsTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <InterruptionDefinitionsTableHead columns={InterruptionDefinitionsColumns} showActions={showActions} />
        <InterruptionDefinitionsTableBody
          columns={InterruptionDefinitionsColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          showActions={showActions}
        />
      </CustomTable>
    </TableContainer>
  );
};
