import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  TextField
} from '@mui/material';
import { AddShareableLinkInput, ADD_SHAREABLE_LINK, UpdateShareableLinkInput, UPDATE_SHAREABLE_LINK } from 'api';
import { useApolloErrorHandler } from 'hooks';
import _ from 'lodash';
import { shareableLinkModel } from 'model';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE,
  TXT_UPDATE_SHAREABLELINK,
  TXT_CREATE_SHAREABLELINK,
  TXT_PASSWORD,
  TXT_RETYPE_PASSWORD
} from '../../../../../../shared/translations';
import * as yup from 'yup';

interface LaytimeChartShareableLinkEditorProps extends shareableLinkModel {
  afterSave: () => void;
  onClose: () => void;
}

const schema = yup.object().shape({
  password: yup.string().required(),
  rePassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null])
});

export const LaytimeChartShareableLinkEditor = (props: LaytimeChartShareableLinkEditorProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<AddShareableLinkInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      shareableLinkType: 'LAYTIME_CHART_REPORT',
      password: '',
      rePassword: ''
    }
  });
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutate, { loading }] = useMutation(props.id ? UPDATE_SHAREABLE_LINK : ADD_SHAREABLE_LINK, {
    onError: apolloErrorHandler,
    onCompleted: () => {
      props.afterSave();
      props.onClose();
    }
  });

  const onSave: SubmitHandler<AddShareableLinkInput> = (data) => {
    let input: AddShareableLinkInput | UpdateShareableLinkInput = { ..._.omit(data, 'rePassword') };
    if (props.id) {
      input = {
        ...input,
        id: props.id
      };
    }
    mutate({
      variables: {
        input
      }
    });
  };

  const txtTitle = t(!!props.id ? TXT_UPDATE_SHAREABLELINK : TXT_CREATE_SHAREABLELINK);

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{txtTitle.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form id="frm-shareablelink-editor" onSubmit={handleSubmit(onSave)}>
          <Stack spacing={2}>
            <TextField
              label={capitalizeFirstLetterEveryword(t(TXT_PASSWORD))}
              fullWidth
              type="password"
              {...register('password')}
              error={!!formErrors.password}
              disabled={loading}
            />
            <TextField
              label={capitalizeFirstLetterEveryword(t(TXT_RETYPE_PASSWORD))}
              fullWidth
              type="password"
              {...register('rePassword')}
              error={!!formErrors.rePassword}
              disabled={loading}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="frm-shareablelink-editor" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
