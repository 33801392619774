import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { OverrideFinalValueLaytimeCalculationInput, OVERRIDE_LAYTIME_CALCULATION_FINAL_RESULT } from 'api';
import { NumberTextField } from 'components';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { baseEntityModel, LaytimeCalculationResult } from 'model';
import {
  refreshLaytimeCalculationsAfterSave,
  useDemurragePageDispatch
} from 'pages/DemurragePage/DemurragePageContext';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { formatCurrency } from 'shared/utils';
import {
  TXT_CANCEL,
  TXT_NEW_VALUE,
  TXT_OLD_VALUE,
  TXT_OVERRIDE_LAYTIME_CALC_FINAL_VALUE,
  TXT_UNDO_OVERRIDE_LAYTIME_CALC_FINAL_VALUE,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../../../shared/translations';
import * as yup from 'yup';

export interface FinalResultEditorProps extends baseEntityModel {
  // extends laytimeCalculationModel
  action: string;
  oldCalcResult?: LaytimeCalculationResult;
  oldValue?: number;
  newValue?: number;
  currency: string;
  onClose: () => void;
}

export const FinalResultEditor = (props: FinalResultEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const title =
    props.action === 'OVERRIDE'
      ? t(TXT_OVERRIDE_LAYTIME_CALC_FINAL_VALUE)
      : t(TXT_UNDO_OVERRIDE_LAYTIME_CALC_FINAL_VALUE);
  const dispatch = useDemurragePageDispatch();

  const [mutateLaytimeCalculationFinalResult, { loading }] = useMutation(OVERRIDE_LAYTIME_CALCULATION_FINAL_RESULT, {
    onError: apolloErrorHandler,
    onCompleted: (calculation) => {
      dispatch(refreshLaytimeCalculationsAfterSave(calculation.overrideLaytimeCalculationFinalResult));
      props.onClose();
    }
  });

  const schema = yup.object({
    newValue: props.action === 'OVERRIDE' ? yup.string().required() : yup.string().notRequired(),
    result: yup.string().required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<OverrideFinalValueLaytimeCalculationInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: props.id,
      newValue: props.newValue,
      result: props.oldCalcResult
    }
  });

  const mutateOverride = (submitInput: OverrideFinalValueLaytimeCalculationInput) => {
    return mutateLaytimeCalculationFinalResult({
      variables: {
        input: submitInput
      }
    });
  };

  const [errorMessage, setErrorMessage] = useState('');

  const onSave: SubmitHandler<any> = (data) => {
    if (props.action === 'UNDO' && data.newValue) {
      setErrorMessage('You must clear the value first');
      return false;
    }

    if (props.action === 'OVERRIDE' && !data.newValue) {
      setErrorMessage('You must provide a value');
      return false;
    }

    mutateOverride({ id: data.id, newValue: parseFloat(data.newValue), result: data.result });
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <form id="frm1" onSubmit={handleSubmit(onSave)}>
        <AppBar position="relative">
          <Toolbar>{title.toUpperCase()}</Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="oldValue"
                label={capitalizeFirstLetterEveryword(t(TXT_OLD_VALUE))}
                fullWidth
                disabled
                defaultValue={
                  capitalizeFirstLetterEveryword(props.oldCalcResult ?? '') +
                  ':  ' +
                  formatCurrency(props.oldValue ?? 0, props.currency ?? 'USD')
                }
              />
            </Grid>
            <Grid item xs={12} hidden={props.action === 'UNDO'}>
              <InputLabel id="lbl-result">Select new result:</InputLabel>
              <Select
                labelId="lbl-result"
                id="result"
                defaultValue={props.oldCalcResult}
                style={{ width: '100%' }}
                label="Select new result:"
                {...register('result', { required: true })}
              >
                <MenuItem value={'DEMURRAGE'}>Demurrage</MenuItem>
                <MenuItem value={'DESPATCH'}>Despatch</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                autoFocus
                margin="dense"
                id="newValue"
                label={capitalizeFirstLetterEveryword(t(TXT_NEW_VALUE))}
                fullWidth
                disabled={loading}
                inputProps={{
                  step: '0.001'
                }}
                {...register('newValue', { required: true })}
                error={!!formErrors.newValue || errorMessage.length > 0}
              />
              <Typography>{errorMessage}&nbsp;</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" form="frm1" disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
