import { TableContainer, TableProps } from '@mui/material';
import { CustomTable } from '../../../components';
import { UserUIProps } from '../UserUIProps';
import { userColumns } from './userColumns';
import { UserTableBody } from './UserTableBody';
import { UserTableHead } from './UserTableHead';

// eslint-disable-next-line max-len
export const UserTable = ({ error, loading, data, onEdit, onDelete, onChangeEnablement, allowVesselPortal, allowOCR, ...rest }: (UserUIProps & TableProps)) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <UserTableHead
          columns={userColumns}
        />
        <UserTableBody
          columns={userColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onChangeEnablement={onChangeEnablement}
          allowVesselPortal={allowVesselPortal}
          allowOCR={allowOCR}
        />
      </CustomTable>
    </TableContainer>
  );
};
