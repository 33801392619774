import { useLazyQuery } from '@apollo/client';
import { Backdrop, CircularProgress, Typography, useTheme } from '@mui/material';
import { GetLaytimeChartForSplitReportOutput, GET_LAYTIME_CHART_SPLIT_REPORT } from 'api';
import { CustomError } from 'components';
import { useApolloErrorHandler } from 'hooks';
import { t } from 'i18next';
import { splitQuantityModel } from 'model';
import { useEffect } from 'react';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { ProfitAndLossReportContent } from './ProfitAndLossReportContent';

interface ProfitAndLossReportProps {
  splitQuantity?: splitQuantityModel;
}

export const ProfitAndLossReport = (props: ProfitAndLossReportProps) => {
  if (!props.splitQuantity || !props.splitQuantity.id) {
    return null;
  }

  const theme = useTheme();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [getReport, { loading, data }] = useLazyQuery<GetLaytimeChartForSplitReportOutput>(GET_LAYTIME_CHART_SPLIT_REPORT, {
    fetchPolicy: 'network-only',
    variables: {
      splitQuantityId: props.splitQuantity.id
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    if (!props.splitQuantity || !props.splitQuantity.id) {
      return;
    }
    getReport();
  }, [props.splitQuantity]);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
          open={loading}
        >
          <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
            {capitalizeFirstLetterEveryword(t(TXT_LOADING))}
          </Typography>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {
        !loading && !data &&
        <CustomError>
          {capitalizeFirstLetterEveryword(t(TXT_NO_DATA_FOUND))}
        </CustomError>
      }
      {
        !loading && !!data &&
        <ProfitAndLossReportContent {...data.laytimeChartForSplitReport} />
      }
    </>
  );
};
