import { Fragment, ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_COUNTER_PARTIES, GetCounterPartiesOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_COUNTERPARTY, TXT_CREATE_COUNTERPARTY, TXT_SAVED } from '../../../../../shared/translations';
import { counterPartyModel, CounterPartyType, maritimeDirectoryModel, CounterPartyTypes, portModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';
import { Link } from '@mui/material';
import { CounterPartyEditor } from 'pages/CounterParty/CounterPartyEditor';
import { MaritimeDirectoryEditor } from 'pages/MaritimeDirectories/MaritimeDirectoryEditor';
import { useSnackbar } from 'notistack';

export interface CounterPartySelectorProps {
  label?: string;
  counterParty?: counterPartyModel;
  error?: boolean;
  counterPartyType: CounterPartyType;
  onSelectedChange?: (counterParty: counterPartyModel | null) => void;
  onChanged?: (counterParty?: counterPartyModel) => void;
  disabled?: boolean;
  allowAdd?: boolean;
  showMaritimeDirectory?: boolean;
  portModel?: portModel;
}

const VISIBLE_OPTIONS_COUNT = 5;

export const CounterPartySelector = (props: CounterPartySelectorProps) => {
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);
  const [openCreateMaritimeDirectory, setOpenCreateMaritimeDirectory] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const maritimeAgent = (portModel?: portModel) : maritimeDirectoryModel => {
    const assignedPorts: portModel[] = [];
    if (portModel) {
      assignedPorts.push(portModel);
    }

    const result : maritimeDirectoryModel = {
      ports: assignedPorts,
      counterParty: selected ? selected : undefined,
      counterPartyId: selected ? selected.id : undefined
    };

    return result;
  };


  const [selected, setSelected] = useState<counterPartyModel | null>(props.counterParty || null);
  const [text, setText] = useState<string | undefined>(props.counterParty?.name);
  const [options, setOptions] = useState<counterPartyModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let filter: any[] = [];

    if (props.counterPartyType !== 'ALL') {
      filter = [
        ...filter,
        {
          counterPartyTypes: {
            some: {
              id: {
                eq: CounterPartyTypes[props.counterPartyType!]
              }
            }
          }
        }
      ];
    }

    if (!!props.portModel?.country) {
      filter = [
        ...filter,
        {
          maritimeDirectories: {
            some: {
              ports: {
                some: {
                  country: {
                    eq: props.portModel.country
                  }
                }
              }
            }
          }
        }
      ];
    }

    return filter.length <= 0 ? null : { and: filter };
  };

  const [getcounterParties, { loading }] = useLazyQuery<GetCounterPartiesOutput>(GET_COUNTER_PARTIES, {
    fetchPolicy: 'network-only',
    variables: {
      name: text,
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0,
      where: getFilter()
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      let counterPartyOptions: counterPartyModel[] = data?.counterParties?.items || [];
      const totalCount = data?.counterParties?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        counterPartyOptions = [
          ...counterPartyOptions,
          {
            id: 'counterParty-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(
              t(TXT_AND_MORE, {
                count: totalCount - VISIBLE_OPTIONS_COUNT
              })
            )
          }
        ];
      }
      if (!_.isNil(selected)) {
        counterPartyOptions = [{ ...selected }, ...counterPartyOptions];
      }
      setOptions(_.uniqBy(counterPartyOptions, 'id'));
    }
  });

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: counterPartyModel | null) => {
    setSelected(value);
    props.onSelectedChange && props.onSelectedChange(value);
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  const openCloseEditor = () => {
    setOpenCreate((prev) => !prev);
  };

  const noOption = (): ReactNode => {
    if (!props.allowAdd || !text || text.trim().length <= 0) {
      return 'No options';
    }

    return (
      <Link component="button" variant="inherit" onClick={openCloseEditor}>
        {`${_.capitalize(t(TXT_CREATE_COUNTERPARTY))} '${text}'`}
      </Link>
    );
  };

  const onCreated = (newCounterParty: counterPartyModel) => {
    setOptions((prev) => [...prev, newCounterParty]);
    onSelectedChange(_, newCounterParty);

    if (props.showMaritimeDirectory) {
      setOpenCreateMaritimeDirectory(true);
    }
  };

  useEffect(() => {
    getcounterParties();
  }, [text]);

  useEffect(() => {
    setSelected(() => props.counterParty ?? null);
  }, [props.counterParty]);

  return (
    <>
      {openCreate ? (
        <CounterPartyEditor
          name={text}
          defaultCounterPartyTypes={!!props.counterPartyType ? [props.counterPartyType] : undefined}
          onClose={openCloseEditor}
          afterSave={onCreated}
        />
      ) : null}

      {openCreateMaritimeDirectory ? (
        <MaritimeDirectoryEditor {...maritimeAgent(props.portModel) }
          onClose={() => {
            setOpenCreateMaritimeDirectory(false);
          }}
          afterSave={() => {
            enqueueSnackbar(TXT_SAVED, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              autoHideDuration: 2000
            });
          }}
        />
      ) : null}


      <Autocomplete
        id="counterParty-selector"
        onChange={onSelectedChange}
        onInputChange={onInputChange}
        value={selected}
        getOptionDisabled={(option) => option.id === 'counterParty-hidden-options-remaining'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ''}
        options={options}
        loading={loading}
        filterOptions={(x) => x}
        fullWidth
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_COUNTERPARTY))}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {/* eslint-disable-next-line max-len */}
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
            error={props.error}
          />
        )}
        noOptionsText={noOption()}
      />
    </>
  );
};
