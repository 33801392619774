import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Step,
  StepButton,
  Stepper,
  useTheme
} from '@mui/material';
import {
  ADD_STATEMENT_OF_FACT,
  AddStatementOfFactInput,
  GET_STATEMENT_OF_FACT,
  GetStatementOfFactOutput,
  StatementOfFactQueryResult,
  UPDATE_STATEMENT_OF_FACT,
  cognitiveReadOperationResultExt
} from 'api';
import { useApolloErrorHandler, useTitle } from 'hooks';
import _ from 'lodash';
import { statementOfFactInterruptionModel } from 'model';
import moment from 'moment';
import { useGlobalStyles } from 'pages/globalStyles';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { statementOfFactPath } from 'shared/links';
import * as yup from 'yup';
import { InterruptionsWizard } from './InterruptionsWizard';
import { OcrWizard } from './OcrWizard';
import { StatementOfFactInfoWizard } from './StatementOfFactInfoWizard';
import { useOcrScanWizardStyle } from './ocrScanWizardStyle';
import { SummaryWizard } from './SummaryWizard';
import {
  TXT_OCR,
  TXT_OCR_SCAN,
  TXT_SOF_INFO,
  TXT_INTERRUPTIONS,
  TXT_SUMMARY,
  TXT_PREVIOUS,
  TXT_NEXT,
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE
} from '../../../../../../shared/translations';

const cognitiveReadOperationResultSchema = {
  id: yup.string(),
  pageNumber: yup.number().min(0),
  type: yup.string(),
  value: yup.string(),
  selected: yup.bool().default(false),
  usedForInterruption: yup.bool().default(false)
};

const statementOfFactInterruptionSchema = {
  id: yup.string(),
  statementOfFactId: yup.string(),
  description: yup.string(),
  since: yup.date().nullable().default(null),
  to: yup.date().nullable().default(null),
  percentage: yup.number().min(0),
  toDiscount: yup.bool().default(false)
};

const schema = yup.object().shape({
  vesselName: yup.string(),
  arrivalDate: yup.string(),
  norTenderedDate: yup.string(),
  quantity: yup.number().min(0),
  destination: yup.string(),
  portId: yup.string(),
  ocrContent: yup.string(),
  interruptions: yup.array().of(yup.object().shape(statementOfFactInterruptionSchema)),
  ocrResult: yup.array().of(yup.object().shape(cognitiveReadOperationResultSchema)),
  status: yup.string(),
  trafficSpecialistId: yup.string(),
  assignToId: yup.string()
});

interface stepperItem {
  label: string;
  component: ReactNode;
  error?: () => boolean;
}

interface OcrScanWizardContextProps {
  isDisabled?: boolean;
}

export const OcrScanWizardContext = createContext<OcrScanWizardContextProps>({});

export const OcrScanWizard = () => {
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const style = useOcrScanWizardStyle();
  const navigate = useNavigate();
  const { statementOfFactId } = useParams();
  const { apolloErrorHandler } = useApolloErrorHandler();

  useTitle(t(TXT_OCR));

  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const methods = useForm<AddStatementOfFactInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      vesselName: undefined,
      arrivalDate: undefined,
      norTenderedDate: undefined,
      destination: undefined,
      quantity: undefined,
      portId: undefined,
      ocrContent: undefined,
      interruptions: [],
      portName: undefined,
      status: 'DRAFT',
      ocrResult: [],
      trafficSpecialist: undefined,
      assignTo: undefined
    }
  });

  const { handleSubmit, formState, trigger, setValue } = methods;

  const [mutateStatementOfFact, { loading }] = useMutation<StatementOfFactQueryResult>(
    statementOfFactId ? UPDATE_STATEMENT_OF_FACT : ADD_STATEMENT_OF_FACT,
    {
      onError: (error) => {
        if (error && error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions?.message) {
          setErrorMessage(_.capitalize(t(error.graphQLErrors[0].extensions.message as string)));
          setTimeout(() => setErrorMessage(undefined), 5000);
        }
        apolloErrorHandler;
      },
      onCompleted: () => {
        navigate(statementOfFactPath);
      }
    }
  );

  const steps: stepperItem[] = [
    {
      label: TXT_OCR_SCAN,
      component: <OcrWizard />,
      error: () => !_.isEmpty(_.omit(formState.errors))
    },
    {
      label: TXT_SOF_INFO,
      component: <StatementOfFactInfoWizard />,
      error: () => !_.isEmpty(_.pick(formState.errors))
    },
    {
      label: TXT_INTERRUPTIONS,
      component: <InterruptionsWizard />,
      error: () => !_.isEmpty(_.pick(formState.errors))
    },
    {
      label: TXT_SUMMARY,
      component: <SummaryWizard />,
      error: () => !_.isEmpty(_.pick(formState.errors))
    }
  ];

  const handleStep = (step: number) => async () => {
    await trigger();
    setActiveStep(step);
  };

  const [getStatementOfFact, { loading: getStatementOfFacLoading }] = useLazyQuery<GetStatementOfFactOutput>(
    GET_STATEMENT_OF_FACT,
    {
      fetchPolicy: 'network-only',
      variables: {
        statementOfFactId
      },
      onCompleted: (data) => {
        if (!!data && !!data.statementOfFact && !!data.statementOfFact.ocrContent) {
          const ocrResultObjects = JSON.parse(data.statementOfFact.ocrContent) as cognitiveReadOperationResultExt[];
          setValue('vesselName', data.statementOfFact?.vesselName);
          setValue('portName', data.statementOfFact?.port?.name);
          setValue('quantity', data.statementOfFact?.quantity);
          setValue('arrivalDate', moment(data.statementOfFact?.arrivalDate).toISOString());
          setValue('norTenderedDate', moment(data.statementOfFact?.norTenderedDate).toISOString());
          setValue('interruptions', data.statementOfFact?.interruptions);
          setValue('ocrResult', ocrResultObjects);
          setValue('status', data.statementOfFact.status);
          setValue('trafficSpecialistId', data.statementOfFact.trafficSpecialistId);
          setValue('trafficSpecialist', data.statementOfFact.trafficSpecialist);
          setValue('assignToId', data.statementOfFact.assignToId);
          setValue('assignTo', data.statementOfFact.assignTo);

          if (!!ocrResultObjects && ocrResultObjects.length > 0) {
            setActiveStep(1);
          }

          if (
            !!data.statementOfFact &&
            data.statementOfFact.interruptions &&
            data.statementOfFact.interruptions.length > 0
          ) {
            setActiveStep(3);
          }
        }
      },
      onError: apolloErrorHandler
    }
  );

  useEffect(() => {
    if (!!statementOfFactId) {
      getStatementOfFact();
    }
  }, [statementOfFactId]);

  const onSave: SubmitHandler<AddStatementOfFactInput> = (data) => {
    if (!statementOfFactId) {
      return mutateStatementOfFact({
        variables: {
          input: {
            vesselName: data.vesselName,
            arrivalDate: data.arrivalDate,
            norTenderedDate: data.norTenderedDate,
            quantity: data.quantity,
            destination: data?.destination,
            portId: data.portId,
            status: data.status,
            trafficSpecialistId: data.trafficSpecialistId,
            assignToId: data.assignToId,
            ocrContent: JSON.stringify(data.ocrResult),
            interruptions: _.map(data.interruptions, (interruption: statementOfFactInterruptionModel) => ({
              description: interruption.description,
              since: interruption.since,
              to: interruption.to,
              percentage: interruption.percentage,
              toDiscount: interruption.toDiscount,
              statementOfFactId: interruption.statementOfFactId
            }))
          }
        }
      });
    }
    return mutateStatementOfFact({
      variables: {
        input: {
          id: statementOfFactId,
          vesselName: data.vesselName,
          arrivalDate: data.arrivalDate,
          norTenderedDate: data.norTenderedDate,
          quantity: data.quantity,
          destination: data?.destination,
          portId: data.portId,
          status: data.status,
          trafficSpecialistId: data.trafficSpecialistId,
          assignToId: data.assignToId,
          ocrContent: JSON.stringify(data.ocrResult),
          interruptions: _.map(data.interruptions, (interruption: statementOfFactInterruptionModel) => ({
            description: interruption.description,
            since: interruption.since,
            to: interruption.to,
            percentage: interruption.percentage,
            toDiscount: interruption.toDiscount,
            statementOfFactId: interruption.statementOfFactId
          }))
        }
      }
    });
  };

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={style.paper}>
            <Grid container spacing={2} style={{ height: '100%', alignContent: 'space-between' }}>
              <Grid item xs={12} style={{ height: '95%' }}>
                <Grid container spacing={2} style={{ height: 'inherit', alignContent: 'start' }}>
                  <Grid item xs={12}>
                    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                      {steps.map((value, index) => {
                        return (
                          <Step key={`ocrscan-wizard-${index}`}>
                            <StepButton onClick={handleStep(index)} style={{ justifyContent: 'center' }}>
                              <Stack direction="row" spacing={1} alignItems="center">
                                {value.error && !!value.error() && <WarningIcon style={{ color: 'red' }}></WarningIcon>}
                                <div>{t(value.label).toUpperCase()}</div>
                              </Stack>
                            </StepButton>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} style={{ height: 'inherit' }}>
                    <FormProvider {...methods}>
                      <OcrScanWizardContext.Provider
                        value={{
                          isDisabled: loading || getStatementOfFacLoading
                        }}
                      >
                        <form
                          id="frm-ocrscan-wizard"
                          onSubmit={handleSubmit(onSave)}
                          style={{
                            marginTop: theme.spacing(1),
                            height: 'inherit'
                          }}
                        >
                          {steps.map((value, index) => {
                            if (activeStep !== index) return null;
                            return (
                              <div
                                key={`ocrscan-wizard-detail-${index}`}
                                // hidden={activeStep !== index}
                                style={{ height: 'inherit' }}
                              >
                                {value.component}
                              </div>
                            );
                          })}
                        </form>
                      </OcrScanWizardContext.Provider>
                    </FormProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Button
                      onClick={handleStep(activeStep - 1)}
                      color="info"
                      disabled={loading || activeStep <= 0}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      {t(TXT_PREVIOUS)}
                    </Button>
                    <Button
                      onClick={handleStep(activeStep + 1)}
                      color="info"
                      disabled={loading || activeStep >= steps.length - 1}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      {t(TXT_NEXT)}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    {errorMessage && <Alert severity="error">{_.capitalize(errorMessage)}</Alert>}
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={() => navigate(statementOfFactPath)} color="secondary" disabled={loading}>
                        {t(TXT_CANCEL)}
                      </Button>
                      <Button type="submit" form="frm-ocrscan-wizard" disabled={loading || !!errorMessage}>
                        {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
                        {t(loading || errorMessage ? TXT_SAVING : TXT_SAVE)}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
