import { useRecoilState } from 'recoil';
import { darkModeState } from 'state/darkModeState';
import { getAppLocalStorage, setAppLocalStorage } from '../shared/appLocalStorage';

const useDarkModeState = (): [boolean | undefined, (value: boolean) => void] => {
  const [useDarkMode, setUseDarkMode] = useRecoilState(darkModeState);

  const setDarkMode = (newValue: boolean) => {
    setUseDarkMode(newValue);
    setAppLocalStorage({
      ...getAppLocalStorage(),
      darkMode: newValue
    });
  };

  return [useDarkMode, setDarkMode];
};

export default useDarkModeState;
