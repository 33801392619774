import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { CustomTable } from 'components';
import { CounterPartyTypes, bargeLaytimeCalculationModel, bargeTripModel } from 'model';
import moment from 'moment';
import { useGlobalStyles } from 'pages/globalStyles';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ARRIVAL_DATE,
  TXT_BARGE,
  TXT_COMPLETION_DATE,
  TXT_DAY,
  TXT_DAYS,
  TXT_DELETE,
  TXT_EDIT,
  TXT_ERRORS,
  TXT_LOADING,
  TXT_OWNER,
  TXT_PORTS,
  TXT_PRODUCT,
  TXT_QUANTITY,
  TXT_RECEIVER,
  TXT_SHIPOWNER,
  TXT_TOTAL_DEMURRAGE,
  TXT_UNLOADING
} from '../../../../../shared/translations';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface BargeTripTableProps {
  bargeTrips: bargeTripModel[];
  onEdit: (deal: bargeTripModel) => void;
  onDelete: (deal: bargeTripModel) => void;
  showActions?: boolean;
}

export const BargeTripTable = (props: BargeTripTableProps) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const theme = useTheme();
  const generateToolTipForPnl = (trip: bargeTripModel) => {
    if (trip.error) {
      return trip.error;
    }

    if (!trip.bargeLaytimeCalculations) {
      return '';
    }

    const calcs = Object.values(trip.bargeLaytimeCalculations);
    let result = '' + capitalizeFirstLetterEveryword(TXT_SHIPOWNER) + '\n';
    calcs
      .filter((x) => x.counterPartyTypeId === CounterPartyTypes.SHIPOWNER)
      .map((calc) => {
        result += generateToolTipForCalc(calc, trip.owner?.demurrageRate ?? 0, trip.currency ?? '');
      });

    result += '\n';
    result += '' + capitalizeFirstLetterEveryword(TXT_RECEIVER) + '\n';
    calcs
      .filter((x) => x.counterPartyTypeId === CounterPartyTypes.CLIENT)
      .map((calc) => {
        result += generateToolTipForCalc(calc, trip.receiver?.demurrageRate ?? 0, trip.currency ?? '');
      });
    return result;
  };

  const generateToolTipForCalc = (calc: bargeLaytimeCalculationModel, rate: number, currency: string) => {
    const rowCalc = `${capitalizeFirstLetterEveryword(calc.isLoadingCalc ? TXT_LOADING : TXT_UNLOADING)}: ${
      calc.daysInDemurrage
    } ${capitalizeFirstLetterEveryword(t((calc.daysInDemurrage || 0) > 1 ? TXT_DAYS : TXT_DAY))} @ ${
      rate || 0
    } ${currency} = ${calc.totalDemurrage?.toLocaleString()} ${currency}`;
    return rowCalc + '\n';
  };

  return (
    <CustomTable stickyHeader size="small">
      <TableHead>
        <TableRow
          sx={{
            backgroundColor: theme.palette.background.paper
          }}
        >
          <TableCell
            sx={{
              minWidth: '300px',
              position: 'sticky',
              left: 0,
              backgroundColor: theme.palette.background.default,
              zIndex: 10
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_BARGE))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_OWNER))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_RECEIVER))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '250px',
              textAlign: 'center'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_PORTS))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_PRODUCT))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '200px'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_COMPLETION_DATE))}
          </TableCell>
          <TableCell
            sx={{
              minWidth: '300px',
              textAlign: 'center'
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_TOTAL_DEMURRAGE))}
          </TableCell>
          <TableCell
            sx={{
              width: 100,
              textAlign: 'center',
              position: 'sticky',
              right: 80,
              backgroundColor: theme.palette.background.default,
              zIndex: 10
            }}
          >
            {capitalizeFirstLetterEveryword(t(TXT_EDIT))}
          </TableCell>
          {props.showActions && (
            <TableCell
              sx={{
                width: 100,
                textAlign: 'center',
                position: 'sticky',
                right: 0,
                backgroundColor: theme.palette.background.default,
                zIndex: 10
              }}
            >
              {capitalizeFirstLetterEveryword(t(TXT_DELETE))}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.bargeTrips.map((trip) => (
          <TableRow key={trip.id}>
            <TableCell
              sx={{
                minWidth: '300px',
                position: 'sticky',
                left: 0,
                backgroundColor: theme.palette.background.default
              }}
            >
              {`${trip.barge?.name}${(trip.splitQuantityName || '').length > 0 ? ` [${trip.splitQuantityName}]` : ''}`}
            </TableCell>
            <TableCell>{trip.owner?.counterParty?.name}</TableCell>
            <TableCell>{trip.receiver?.counterParty?.name}</TableCell>
            <TableCell
              sx={{
                textAlign: 'center'
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography color="inherit" variant="inherit">
                  {trip.loadingPort?.port?.name}
                </Typography>
                <ArrowForwardIcon />
                <Typography color="inherit" variant="inherit">
                  {trip.unloadingPort?.port?.name}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>{trip.product?.name}</TableCell>
            <TableCell>{`${trip.quantity?.toLocaleString()} ${trip.massUnit}`}</TableCell>
            <TableCell>{moment(trip.loadingPort?.arrivalTime).format('LL')}</TableCell>
            <TableCell>
              {trip.loadingPort?.completedTime ? moment(trip.loadingPort?.completedTime).format('LL') : null}
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              <Tooltip title="" placement="top-start">
                <div>
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre-line' }}>{generateToolTipForPnl(trip)}</div>}
                    placement="top-start"
                  >
                    <Typography color="inherit" variant="inherit">
                      {`${
                        trip.error
                          ? capitalizeFirstLetterEveryword(TXT_ERRORS)
                          : trip.pnL?.toLocaleString() + ' ' + trip.currency
                      } `}
                    </Typography>
                  </Tooltip>
                </div>
              </Tooltip>
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
                position: 'sticky',
                right: 80,
                backgroundColor: theme.palette.background.default
              }}
            >
              <IconButton
                className={globalStyles.editButton}
                aria-label="edit deal"
                component="span"
                onClick={() => props.onEdit(trip)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </TableCell>
            {props.showActions && (
              <TableCell
                sx={{
                  textAlign: 'center',
                  position: 'sticky',
                  right: 0,
                  backgroundColor: theme.palette.background.default
                }}
              >
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete deal"
                  component="span"
                  onClick={() => props.onDelete(trip)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </CustomTable>
  );
};
