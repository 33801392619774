import { AddDealInput } from 'api';
import { useWatch, useFormContext, Controller } from 'react-hook-form';
import { PortSelector } from 'components/PortSelector';
import { TXT_LOADING_PORT } from '../../../../../shared/translations';
import { t } from 'i18next';
import { useContext } from 'react';
import { QuoteWizardContext } from './QuoteWizard';

interface QuoteWizardLoadingPortProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const QuoteWizardLoadingPort = (props: QuoteWizardLoadingPortProps) => {
  const { control } = useFormContext<AddDealInput>();
  const { onLoadingPortChanged, isDisabled } = useContext(QuoteWizardContext);

  const watchLoadingPort = useWatch({
    control,
    name: `loadingPorts.${props.index}`
  });

  return (
    <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
      <Controller
        control={control}
        name={`loadingPorts.${props.index}.portId`}
        render={({ formState }) => (
          <PortSelector
            label={t(TXT_LOADING_PORT)}
            port={watchLoadingPort.port}
            onSelectedChange={onLoadingPortChanged(props.index)}
            error={!!formState.errors.loadingPorts?.[props.index]?.portId}
            disabled={isDisabled}
          />
        )}
      />
    </fieldset>
  );
};
