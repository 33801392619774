import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { GetProductsOutput, ProductQueryResult } from '../../api';
import { CustomTable } from '../../components';
import { productsColumns } from './productsColumns';
import { ProductsTableBody } from './ProductsTableBody';
import { ProductsTableHead } from './ProductsTableHead';

interface ProductsTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetProductsOutput;
  onEdit: (deal: ProductQueryResult) => void;
  onDelete: (deal: ProductQueryResult) => void;
  showActions?: boolean;
}

export const ProductsTable = ({ error, loading, data, onEdit, onDelete, showActions, ...rest }: ProductsTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <ProductsTableHead columns={productsColumns} showActions={showActions} />
        <ProductsTableBody
          columns={productsColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          showActions={showActions}
        />
      </CustomTable>
    </TableContainer>
  );
};
