import { useMutation } from '@apollo/client';
import { Delete, Add } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Dialog,
  AppBar,
  Toolbar,
  DialogContent,
  Typography,
  TextField,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  Autocomplete,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Tooltip,
  Alert,
  Checkbox
} from '@mui/material';
import { UpdateShexConditionsOutput, UPDATE_SHEX_CONDITIONS } from 'api/graphql/shexCondition';
import { DayOfTheWeekSelector } from 'components/DayOfTheWeekSelector/DayOfTheWeekSelector';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { baseEntityModel } from 'model';
import moment, { Moment } from 'moment';
import { useDemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import { getLastestDate, getPortLastestDate } from 'pages/DemurragePage/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { getUpdateShexConditionsInput, updateDatePart } from 'shared/utils';
import shortUUID from 'short-uuid';
import {
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE,
  TXT_FROM,
  TXT_UNTIL,
  TXT_DESCRIPTION,
  TXT_ADD_HOLIDAY,
  TXT_REMARKS,
  TXT_ADD_RECURRENT_SHEX,
  TXT_DELETE,
  TXT_TIME,
  TXT_DAY,
  TXT_DATE,
  TXT_INTERRUPTION_TOO_BIG,
  TXT_INTERRUPTION_HAS_DATES_IN_FUTURE,
  TXT_SAVE_AND_CLOSE,
  TXT_CALENDAR_RULES,
  TXT_IGNORE
} from '../../../../../../../shared/translations';
import { CustomTable } from 'components';
import _ from 'lodash';
import { CalendarRuleOptions, useCalendarRule } from 'hooks';
import useAuth from 'hooks/useAuth';

export interface ShexCondition extends baseEntityModel {
  description: string;
  remarks: string;
  laytimeCalculationId?: string;
  fromDayOfWeek?: number; // From what day of the week the condition start
  fromDate?: Date;
  fromTime?: string;
  untilDayOfWeek?: number;
  untilDate?: Date;
  untilTime?: string;
  ignore: boolean;
  shexConditionType: 'RECURRENT' | 'FIXED';
}

export interface LoadingConditionsEditorProps {
  onClose: () => void;
  afterSave: (data: UpdateShexConditionsOutput) => void;
  shexConditions?: ShexCondition[];
}

export interface ShexConditionError {
  id?: string;
  warningOnly?: boolean;
  hasErrors?: boolean;
  since?: boolean;
  to?: boolean;
  description?: boolean;
  errorMessage?: string;
}

export const ShexConditionEditor = (props: LoadingConditionsEditorProps) => {
  const options = CalendarRuleOptions;
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { t } = useTranslation();
  const { selectedLaytimeCalculationId, selectedLaytimeCalculation, selectedPort } = useDemurragePageState();
  const [errors, setErrors] = useState<ShexConditionError[]>([]);
  const [valid, setValid] = useState<boolean>(false);
  const [latestDate, setLatestDate] = useState<Date | undefined>(
    getPortLastestDate(selectedLaytimeCalculation, selectedPort)
  );
  const [conditions, setConditions] = useState<Record<string, ShexCondition>>(
    (props.shexConditions || []).reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id!]: curr
      }),
      {} as Record<string, ShexCondition>
    )
  );

  const [auth] = useAuth();
  const { isGuest } = auth;

  const [mutateShexConditions, { loading, error }] = useMutation<UpdateShexConditionsOutput>(UPDATE_SHEX_CONDITIONS, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data);
    }
  });

  useEffect(() => {
    const latestShexDate = Object.values(conditions)
      .map((x) => x.fromDate)
      .reduce((prev, curr) => getLastestDate(prev, curr), undefined);
    setLatestDate(getLastestDate(latestDate, latestShexDate));

    setValid(validateConditions());
  }, [conditions]);

  const validateConditions = (): boolean => {
    let isValidForm = true;
    setErrors([]);
    const input = getUpdateShexConditionsInput(Object.values(conditions), false);
    const newErrors: ShexConditionError[] = input
      .map((x) => {
        const rowErrors: ShexConditionError = {
          id: x.id,
          hasErrors: false
        };
        const fc = !(x.fromDate || x.fromDayOfWeek !== undefined);
        const uc = !(x.untilDate || x.untilDayOfWeek !== undefined);
        const ft = x.fromTime === undefined;
        const ut = x.untilTime === undefined;
        if (fc) {
          isValidForm = false;
          rowErrors.since = true;
        }

        if (uc) {
          isValidForm = false;
          rowErrors.hasErrors = true;
          rowErrors.to = true;
        }

        if (ft) {
          rowErrors.hasErrors = true;
          isValidForm = false;
        }

        if (ut) {
          rowErrors.hasErrors = true;
          isValidForm = false;
        }

        if (_.isEmpty(x.description)) {
          isValidForm = false;
          rowErrors.hasErrors = true;
          rowErrors.description = true;
        }

        if (x.fromDate && x.untilDate) {
          const since = x.fromDate.getTime();
          const to = x.untilDate.getTime();
          const now = Date.now();
          const differenceInTime = to - since;

          // To calculate the no. of days between two dates
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          if (since > now || to > now) {
            isValidForm = false;
            rowErrors.errorMessage = _.capitalize(TXT_INTERRUPTION_HAS_DATES_IN_FUTURE);
            rowErrors.hasErrors = true;
          } else if (differenceInDays >= 2) {
            // more than 48 hours
            rowErrors.warningOnly = true;
            rowErrors.errorMessage = _.capitalize(TXT_INTERRUPTION_TOO_BIG);
            rowErrors.hasErrors = true;
          }
        }

        return rowErrors;
      })
      .filter((x) => x.hasErrors);
    setErrors(newErrors);
    return input && isValidForm;
  };

  const addCondition = (isFixed?: boolean) => () => {
    setConditions((c) => {
      const id = 'new-' + shortUUID.generate();
      const newobj: ShexCondition = {
        id,
        description: isFixed ? 'FIXED' : '',
        remarks: 'Weekend/Holiday',
        shexConditionType: isFixed ? 'FIXED' : 'RECURRENT',
        laytimeCalculationId: selectedLaytimeCalculationId,
        fromTime: '00:00',
        untilTime: '23:59',
        ignore: false
      };
      if (!isFixed) {
        newobj.fromDayOfWeek = 1;
        newobj.untilDayOfWeek = 1;
      }
      return {
        ...c,
        [id]: newobj
      };
    });
  };

  const deleteCondition = (id: string) => () => {
    setConditions((c) => {
      const newC = { ...c };
      delete newC[id];
      return newC;
    });
  };

  const handleSave = (closeForm: boolean) => {
    const input = getUpdateShexConditionsInput(Object.values(conditions));
    return mutateShexConditions({
      variables: {
        laytimeCalculationId: selectedLaytimeCalculationId,
        input
      }
    }).finally(() => {
      if (closeForm) {
        props.onClose();
      }
    });
  };

  const handleFromDateChange = (id?: string) => (date: Moment | null) => {
    if (id && date?.isValid()) {
      setConditions((s) => {
        const since = s[id].fromDate ? moment(s[id].fromDate).toDate() : undefined;
        const toHasValue = s[id].untilDate ? true : false;
        const newValue = updateDatePart(since, date?.toDate() || undefined);
        return {
          ...s,
          [id]: { ...s[id], fromDate: newValue, untilDate: !toHasValue ? newValue : s[id].untilDate }
        };
      });
    }
  };

  const handleUntilDateChange = (id?: string) => (date: Moment | null) => {
    if (id && date?.isValid()) {
      setConditions((s) => {
        const untilDate = s[id].untilDate ? moment(s[id].untilDate).toDate() : undefined;

        return {
          ...s,
          [id]: { ...s[id], untilDate: updateDatePart(untilDate, date?.toDate() || undefined) }
        };
      });
    }
  };

  const handleRemarksChange = (id: string, value: string) => {
    setConditions((s) => ({
      ...s,
      [id]: { ...s[id], remarks: value }
    }));
  };

  const handleFromTimeChange = (id: string) => (value: TimePickerValue) => {
    setConditions((s) => ({
      ...s,
      [id]: { ...s[id], fromTime: value as string }
    }));
  };

  const handleUntilTimeChange = (id: string) => (value: TimePickerValue) => {
    setConditions((s) => ({
      ...s,
      [id]: { ...s[id], untilTime: value as string }
    }));
  };

  const handleChangeFromDayOfWeek = (id: string) => (value?: number) => {
    setConditions((s) => ({
      ...s,
      [id]: { ...s[id], fromDayOfWeek: value }
    }));
  };

  const handleChangeUntilDayOfWeek = (id: string) => (value?: number) => {
    setConditions((s) => ({
      ...s,
      [id]: { ...s[id], untilDayOfWeek: value }
    }));
  };

  const handleInputChange = (e: any, data: any, id?: string) => {
    handleDescriptionChange(id, data);
    return data;
  };

  const handleToDiscountChange = (id?: string) => (event: any) => {
    if (id) {
      setConditions((s) => ({
        ...s,
        [id]: { ...s[id], ignore: event.target.checked }
      }));
    }
  };

  const getOptions = (isFixed: boolean): string[] => {
    if (isFixed) {
      return options.filter((x) => x === 'FIXED');
    } else {
      return options.filter((x) => x !== 'FIXED');
    }
  };

  const { getStartDefaultValue, getStartDefaultTimeValue, getEndDefaultValue } = useCalendarRule();
  const handleDescriptionChange = (id?: string, value?: any) => {
    if (id) {
      const fromDateOfWeek = getStartDefaultValue(value);
      const fromStartTime = getStartDefaultTimeValue(value);
      const untilDateOfWeek = getEndDefaultValue(value);

      const condition = conditions[id];
      const newShex = { ...condition };
      newShex.description = value;
      newShex.fromDayOfWeek = fromDateOfWeek !== undefined ? fromDateOfWeek : newShex.fromDayOfWeek;
      newShex.fromTime = fromStartTime !== undefined ? fromStartTime : newShex.fromTime;
      newShex.untilDayOfWeek = untilDateOfWeek !== undefined ? untilDateOfWeek : newShex.untilDayOfWeek;

      setConditions((s) => ({
        ...s,
        [id]: {
          ...newShex
        }
      }));
    }
  };

  return (
    <Dialog open={true} maxWidth="xl" fullWidth={true}>
      <AppBar position="relative">
        <Toolbar>{t(TXT_CALENDAR_RULES).toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <CustomTable stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={400} rowSpan={2}>
                {t(TXT_DESCRIPTION).toUpperCase()}
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {t(TXT_FROM).toUpperCase()}
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {t(TXT_UNTIL).toUpperCase()}
              </TableCell>
              <TableCell align="center" rowSpan={2} width={500}>
                {t(TXT_REMARKS).toUpperCase()}
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                {t(TXT_IGNORE).toUpperCase()}
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                {t(TXT_DELETE).toUpperCase()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ top: 57 }} align="center" width={250}>
                {`${t(TXT_DAY).toUpperCase()} / ${t(TXT_DATE).toUpperCase()}`}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center">
                {t(TXT_TIME).toUpperCase()}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center" width={250}>
                {`${t(TXT_DAY).toUpperCase()} / ${t(TXT_DATE).toUpperCase()}`}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center">
                {t(TXT_TIME).toUpperCase()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(conditions).map((condition) => {
              const rowErrors: ShexConditionError = errors.find((x: ShexConditionError) => x.id === condition.id) ?? {};
              return (
                <TableRow key={condition.id}>
                  <TableCell>
                    <Autocomplete
                      id={'desc-' + condition.id}
                      disabled={loading || condition.shexConditionType === 'FIXED' || isGuest}
                      freeSolo
                      autoSelect
                      value={condition.description || null}
                      onInputChange={(e, data) => {
                        if (e?.type === 'click') {
                          handleInputChange(e, data, condition.id);
                        }
                      }}
                      options={getOptions(condition.shexConditionType === 'FIXED')}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth margin="normal" error={!!rowErrors.description} />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {condition.shexConditionType === 'FIXED' ? (
                      <DatePicker
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            margin: 'normal',
                            error: !!rowErrors.since,
                            autoComplete: 'off'
                          }
                        }}
                        value={!condition.fromDate ? null : moment(condition.fromDate)}
                        onChange={handleFromDateChange(condition.id!)}
                        referenceDate={latestDate ? moment(latestDate) : moment()}
                        disabled={loading || isGuest}
                      />
                    ) : (
                      <DayOfTheWeekSelector
                        onChange={handleChangeFromDayOfWeek(condition.id!)}
                        value={condition.fromDayOfWeek ?? 0}
                        disabled={loading || isGuest}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      className="time-picker"
                      value={condition.fromTime || ''}
                      disableClock
                      format="HH:mm"
                      clearIcon={null}
                      hourPlaceholder="00"
                      minutePlaceholder="00"
                      onChange={handleFromTimeChange(condition.id!)}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell>
                    {condition.shexConditionType === 'FIXED' ? (
                      <DatePicker
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            margin: 'normal',
                            error: !!rowErrors.to,
                            autoComplete: 'off'
                          }
                        }}
                        value={!condition.untilDate ? null : moment(condition.untilDate)}
                        onChange={handleUntilDateChange(condition.id!)}
                        referenceDate={latestDate ? moment(latestDate) : moment()}
                        disabled={loading || isGuest}
                      />
                    ) : (
                      <DayOfTheWeekSelector
                        onChange={handleChangeUntilDayOfWeek(condition.id!)}
                        value={condition.untilDayOfWeek ?? 0}
                        disabled={loading || isGuest}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      className="time-picker"
                      value={condition.untilTime || ''}
                      disableClock
                      format="HH:mm"
                      clearIcon={null}
                      hourPlaceholder="00"
                      minutePlaceholder="00"
                      onChange={handleUntilTimeChange(condition.id!)}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      margin="normal"
                      id={'remarks-' + condition.id}
                      disabled={loading || isGuest}
                      value={condition.remarks || ''}
                      onChange={(e) => {
                        handleRemarksChange(condition.id!, e.currentTarget.value);
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      disabled={loading || isGuest}
                      checked={!!condition.ignore}
                      onChange={handleToDiscountChange(condition.id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="delete condition"
                        component="span"
                        size="large"
                        sx={{ width: '40px', height: '40px' }}
                        onClick={deleteCondition(condition.id!)}
                        disabled={loading || isGuest}
                      >
                        <Delete />
                      </IconButton>
                      {rowErrors.errorMessage && (
                        <Tooltip title={rowErrors.errorMessage}>
                          <Alert severity={rowErrors.warningOnly ? 'warning' : 'error'}></Alert>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </CustomTable>
      </DialogContent>
      <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
        {loading && (
          <div>
            <Typography>
              <CircularProgress style={{ marginRight: '5px' }} size="20px" />
              {t(TXT_SAVING)}...
            </Typography>
          </div>
        )}
        {error?.message && (
          <div>
            <Typography color={'red'} sx={{ width: '100%' }}>
              {error?.message}
            </Typography>
          </div>
        )}
        &nbsp;
      </div>
      <DialogActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <Stack direction="row" spacing={1}>
          <Button onClick={addCondition()} color="inherit" disabled={loading || isGuest}>
            <Add />
            {t(TXT_ADD_RECURRENT_SHEX)}
          </Button>
          <Button onClick={addCondition(true)} color="inherit" disabled={loading || isGuest}>
            <Add />
            {t(TXT_ADD_HOLIDAY)}
          </Button>
        </Stack>
        <div>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button
            form="interruption-editor-form"
            onClick={() => handleSave(false)}
            disabled={!valid || !!error?.message || loading || isGuest}
          >
            {t(TXT_SAVE)}
          </Button>
          <Button
            form="interruption-editor-form"
            onClick={() => handleSave(true)}
            disabled={!valid || !!error?.message || loading || isGuest}
          >
            {t(TXT_SAVE_AND_CLOSE)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
