import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { traderModel } from 'model';
import { GetTradersOutput } from '../../api';
import { CustomTable } from '../../components';
import { tradersColumns } from './tradersColumns';
import { TradersTableBody } from './TradersTableBody';
import { TradersTableHead } from './TradersTableHead';

interface TradersTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetTradersOutput;
  onEdit: (deal: traderModel) => void;
  onDelete: (deal: traderModel) => void;
}

export const TradersTable = ({ error, loading, data, onEdit, onDelete, ...rest }: TradersTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <TradersTableHead columns={tradersColumns} />
        <TradersTableBody
          columns={tradersColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </CustomTable>
    </TableContainer>
  );
};
