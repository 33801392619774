import axios from 'axios';
import { apiBaseUrl } from 'shared/links';
import saveAs from 'file-saver';
import { useAuthService } from './useAuthServices';


export interface LaytimeChartReportRequest {
  linkId?: string;
  password?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  counterParties: (string | null)[]
  traderId?: string,
  operatorId?: string,
  showChildDeals?: boolean;
  portIds?: (string | undefined)[]
}

export interface LaytimePerCounterpartyReportRequest {
  startDate?: Date | null;
  endDate?: Date | null;
  counterParties: (string | null)[];
  showAllDealCounterparties: boolean;
  traderId?: string,
  operatorId?: string
  portIds?: (string | undefined)[]
}

export interface SupplierVsOwnerLaytimeReportRequest {
  startDate?: Date | null;
  endDate?: Date | null;
  counterParties: (string | null)[];
  traderId?: string,
  operatorId?: string,
  portIds?: (string | undefined)[]
}

export interface BargeReportRequest {
  startDate?: Date;
  endDate?: Date;
  counterParties?: (string | null)[];
}

const useExcelAPI = () => {
  const { isSignedIn } = useAuthService();
  const qs = require('qs');

  const getLaytimeChart = async (params: LaytimeChartReportRequest) => {
    if (!params) {
      return;
    }
    try {
      const signedIn = await isSignedIn();
      if (!signedIn.isSignedIn || !signedIn.token) {
        throw Error();
      }

      const response = await axios.get<any>(`${apiBaseUrl}/api/excel/laytimechart`, {
        headers: {
          Authorization: `Bearer ${signedIn.token}`
        },
        responseType: 'blob',
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            encodeValuesOnly: true
          });
        }
      });

      const filename =
        response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim() || 'laytime.xlsx';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
    } catch (error: any) {
      console.error(error);
    }
  };

  const getSupplierVsOwnerLaytimeReport = async (params: SupplierVsOwnerLaytimeReportRequest) => {
    if (!params) {
      return;
    }
    try {
      const signedIn = await isSignedIn();
      if (!signedIn.isSignedIn || !signedIn.token) {
        throw Error();
      }

      const response = await axios.get<any>(`${apiBaseUrl}/api/excel/supplierVsOwnerLaytimeChart`, {
        headers: {
          Authorization: `Bearer ${signedIn.token}`
        },
        responseType: 'blob',
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            encodeValuesOnly: true
          });
        }
      });

      const filename =
        response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim() || 'supplierVsOwnerLaytimeChart.xlsx';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
    } catch (error: any) {
      console.error(error);
    }
  };

  const getLaytimePerCounterpartyreport = async (params: LaytimePerCounterpartyReportRequest) => {
    if (!params) {
      return;
    }
    try {
      const signedIn = await isSignedIn();
      if (!signedIn.isSignedIn || !signedIn.token) {
        throw Error();
      }

      const response = await axios.get<any>(`${apiBaseUrl}/api/excel/laytimeByCounterParty`, {
        headers: {
          Authorization: `Bearer ${signedIn.token}`
        },
        responseType: 'blob',
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            encodeValuesOnly: true
          });
        }
      });

      const filename =
        response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim() || 'laytimeByCounterparty.xlsx';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
    } catch (error: any) {
      console.error(error);
    }
  };

  const getBargeReport = async (params: BargeReportRequest) => {
    if (!params) {
      return;
    }
    try {
      const signedIn = await isSignedIn();
      if (!signedIn.isSignedIn || !signedIn.token) {
        throw Error();
      }

      const response = await axios.get<any>(`${apiBaseUrl}/api/excel/barge`, {
        headers: {
          Authorization: `Bearer ${signedIn.token}`
        },
        responseType: 'blob',
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            encodeValuesOnly: true
          });
        }
      });

      const filename =
        response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim() || 'barge.xlsx';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
    } catch (error: any) {
      console.error(error);
    }
  };

  return {
    getLaytimeChart,
    getLaytimePerCounterpartyreport,
    getBargeReport,
    getSupplierVsOwnerLaytimeReport
  };
};

export default useExcelAPI;
