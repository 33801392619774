import { applicationUserModel, businessUnitModel, counterPartyModel, productModel, traderModel } from 'model';
import { dealPortModel } from './dealPortModel';

export const incoTermOptions = ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAP', 'DPU', 'DDP', 'DAT', 'NONE'] as const;

export type IncoTerm = typeof incoTermOptions[number];

export interface splitQuantityModel {
  id?: string;
  dealId?: string;
  deal?: {
    id?: string;
    name?: string;
  }
  name?: string;
  quantity?: number;
  counterPartyId?: string;
  counterParty?: counterPartyModel;
  unloadingPortId?: string;
  unloadingPort?: dealPortModel;
  loadingPortId?: string;
  loadingPort?: dealPortModel;
  product?: productModel;
  incoTerm?: IncoTerm;
  canDelete?: boolean;
  trafficSpecialistId?: string;
  trafficSpecialist?: applicationUserModel;
  traderId?: string;
  trader?: traderModel;
  businessUnitId?: string;
  businessUnit?: businessUnitModel;
  parentSplitQuantityName?: string;
}
