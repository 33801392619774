import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CAPACITY,
  TXT_CODE,
  TXT_CREATE_INTERRUPTION,
  TXT_DESCRIPTION,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_INTERRUPTION
} from '../../../../../shared/translations';
import { AddInterruptionDefinitionInput, ADD_INTERRUPTION_DEFINITION, UPDATE_INTERRUPTION_DEFINITION } from '../../api';
import { useForm, SubmitHandler } from 'react-hook-form';
import { interruptionDefinitionModel } from 'model';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PercentIcon from '@mui/icons-material/Percent';

export interface InterruptionEditorProps extends interruptionDefinitionModel {
  onClose: () => void;
  afterSave: () => void;
}

const schema = yup.object().shape({
  code: yup.string().required(),
  description: yup.string().required(),
  operatingCapacity: yup.number().required()
});

export const InterruptionDefinitionEditor = (props: InterruptionEditorProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddInterruptionDefinitionInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: props.code,
      description: props.description,
      operatingCapacity: props.operatingCapacity
    }
  });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [mutateInterruption, { loading }] = useMutation(
    props.id ? UPDATE_INTERRUPTION_DEFINITION : ADD_INTERRUPTION_DEFINITION,
    {
      onError: apolloErrorHandler,
      onCompleted: () => {
        props.afterSave();
        props.onClose();
      }
    }
  );

  const onSubmit: SubmitHandler<AddInterruptionDefinitionInput> = (data) => {
    mutate(data);
  };

  const mutate = (data: AddInterruptionDefinitionInput) => {
    if (!props.id) {
      return mutateInterruption({
        variables: {
          input: {
            ...data
          }
        }
      });
    }

    return mutateInterruption({
      variables: {
        input: {
          id: props.id,
          ...data
        }
      }
    });
  };

  const title = t(props.id ? TXT_UPDATE_INTERRUPTION : TXT_CREATE_INTERRUPTION);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth={true} onClose={props.onClose}>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="interruption-editor-form">
          <TextField
            margin="normal"
            autoFocus
            id="code"
            label={capitalizeFirstLetterEveryword(t(TXT_CODE))}
            fullWidth
            disabled={loading}
            error={!!errors.code}
            defaultValue={props.code}
            {...register('code', { required: true })}
          />
          <TextField
            margin="normal"
            id="description"
            label={capitalizeFirstLetterEveryword(t(TXT_DESCRIPTION))}
            fullWidth
            disabled={loading}
            error={!!errors.description}
            defaultValue={props.description}
            {...register('description', { required: true })}
          />
          <TextField
            margin="normal"
            id="operatingCapacity"
            label={capitalizeFirstLetterEveryword(t(TXT_CAPACITY))}
            fullWidth
            disabled={loading}
            error={!!errors.operatingCapacity}
            defaultValue={props.operatingCapacity}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PercentIcon />
                </InputAdornment>
              )
            }}
            {...register('operatingCapacity', { required: true })}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="interruption-editor-form">
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
