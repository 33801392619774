import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetInterruptionDefinitionsOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { InterruptionDefinitionsColumn } from './interruptionDefinitionColumns';
import NumberFormat from 'react-number-format';
import { interruptionDefinitionModel } from 'model';

export interface InterruptionDefinitionsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetInterruptionDefinitionsOutput;
  columns: InterruptionDefinitionsColumn[];
  onEdit: (interruptionDefinition: interruptionDefinitionModel) => void;
  onDelete: (interruptionDefinition: interruptionDefinitionModel) => void;
  showActions?: boolean;
}

export const InterruptionDefinitionsTableBody =
  // eslint-disable-next-line max-len
  ({ columns, loading, error, data, onEdit, onDelete, showActions }: InterruptionDefinitionsTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.interruptionDefinitions &&
          data.interruptionDefinitions.items &&
          data.interruptionDefinitions.items.length > 0 &&
          data.interruptionDefinitions.items.map((interruptionDefinition) => (
            <TableRow key={`row-${interruptionDefinition.id}`}>
              <TableCell align="left">{interruptionDefinition.code}</TableCell>
              <TableCell align="left">
                <NumberFormat
                  value={interruptionDefinition.operatingCapacity}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell align="left">{interruptionDefinition.description}</TableCell>
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.editButton}
                    aria-label="edit port"
                    component="span"
                    onClick={() => onEdit(interruptionDefinition)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              )}
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.deleteButton}
                    aria-label="delete port"
                    component="span"
                    onClick={() => onDelete(interruptionDefinition)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        {!loading && (data?.interruptionDefinitions?.items || []).length <= 0 && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
