import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { cognitiveReadOperationResult } from 'api/document/cognitiveReadOperationResult';
import {
  CustomTable,
  DateTimePickerWithDrop,
  NumberTextField,
  OcrChip,
  TextFieldWithDrop,
  TimePickerWithDrop,
  cognitiveReadOperationResultExt
} from 'components';
import { capitalize } from 'lodash';
import { statementOfFactInterruptionModel } from 'model';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat, ignoreTimeZone, updateDatePart, updateTimePart } from 'shared/utils';
import shortUUID from 'short-uuid';
import { useInterruptionDialogStyle } from './interruptionDialogStyle';
import _ from 'lodash';
import {
  TXT_ADD_INTERRUPTION,
  TXT_CANCEL,
  TXT_DATE,
  TXT_DELETE,
  TXT_DESCRIPTION,
  TXT_INTERRUPTIONS,
  TXT_SAVE,
  TXT_SINCE,
  TXT_TIME,
  TXT_TO,
  TXT_TO_DISCOUNT
} from '../../../../../../shared/translations';

interface InterruptionDialogProps {
  handleCloseDialog: () => void;
  items: cognitiveReadOperationResultExt[];
  handleSave: (items: statementOfFactInterruptionModel[], ocrResult: cognitiveReadOperationResultExt[]) => void;
  interruptions?: statementOfFactInterruptionModel[];
}

export const InterruptionDialog = (props: InterruptionDialogProps) => {
  const { t } = useTranslation();
  const style = useInterruptionDialogStyle();

  const dateFormat = getDateFormat();

  const [interruptions, setInterruptions] = useState<Record<string, Partial<statementOfFactInterruptionModel>>>(
    {} as Record<string, Partial<statementOfFactInterruptionModel>>
  );
  const [pageNumbers] = useState<number[]>(_.uniq(props.items.map((item) => item.pageNumber!)));
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>();
  const [ocrResult, setOcrResult] = useState<cognitiveReadOperationResultExt[]>(
    props.items.map((value) => ({
      ...value,
      selected: false,
      usedForInterruption: false
    }))
  );

  const onSave = () => {
    const interruptionItems = Object.values(interruptions).map(
      (x) =>
        ({
          id: x.id,
          description: x.description,
          since: ignoreTimeZone(x.since),
          to: ignoreTimeZone(x.to),
          percentage: x.percentage,
          toDiscount: x.toDiscount
        } as statementOfFactInterruptionModel)
    );
    props.handleSave(interruptionItems, ocrResult);
    props.handleCloseDialog();
  };

  const handleAddInterruption = () => {
    const allInts = Object.values(interruptions);
    const lastInt = allInts.length > 0 ? allInts[allInts.length - 1] : undefined;
    setInterruptions((s) => {
      const id = 'new-' + shortUUID.generate();
      return {
        ...s,
        [id]: {
          id,
          toDiscount: false,
          percentage: 100,
          since: lastInt?.to,
          to: lastInt?.to
        }
      };
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id?: string) => {
    if (!!id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], description: e.target.value }
      }));
    }
  };

  const handleDropInput = (item: cognitiveReadOperationResult, id?: string) => {
    if (!!id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], description: (item as cognitiveReadOperationResult).value }
      }));

      if (!!item.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
          return newOcrResult;
        });
      }
    }
  };

  const handleSinceChange = (date: moment.Moment | undefined, id?: string, item?: cognitiveReadOperationResult) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const since = s[id].since ? moment(s[id].since).toDate() : undefined;
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;
        let toHasValue = s[id].to ? true : false;
        const newValue = updateDatePart(since, date?.toDate() || undefined);
        if (toHasValue && to && newValue) {
          const sinceNumber = newValue.valueOf();
          const toNumber = to.valueOf();
          toHasValue = sinceNumber > toNumber ? false : true;
        }
        return {
          ...s,
          [id]: { ...s[id], since: newValue, to: !toHasValue ? newValue : s[id].to }
        };
      });

      if (!!item?.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };

          return newOcrResult;
        });
      }
    }
  };

  const handleToChange = (date: moment.Moment | undefined, id?: string, item?: cognitiveReadOperationResult) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;

        return {
          ...s,
          [id]: { ...s[id], to: updateDatePart(to, date?.toDate() || undefined) }
        };
      });

      if (!!item?.id) {
        setOcrResult((prevOcrResult) => {
          const index = prevOcrResult.findIndex((result) => result.id === item.id);
          const newOcrResult = [...prevOcrResult];
          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };

          return newOcrResult;
        });
      }
    }
  };

  const handleInterruptionDelete = (id?: string) => () => {
    if (id) {
      setInterruptions((s) => {
        const newS = { ...s };
        delete newS[id];
        return newS;
      });
    }
  };

  const handlePercentageChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], percentage: event.target.value ? parseFloat(event.target.value) : 0 }
      }));
    }
  };

  const handleToDiscountChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], toDiscount: event.target.checked }
      }));
    }
  };

  const onPageNumberChanged = (_: React.MouseEvent<HTMLElement>, nextLetter: number) => {
    setSelectedPageNumber(() => nextLetter);
    if (!!nextLetter) setOcrResult(() => props.items.filter((x) => x.pageNumber === nextLetter));
    else setOcrResult(() => props.items);
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleCloseDialog}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '88vh',
          height: '88vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_INTERRUPTIONS))}</DialogTitle>
      <DialogContent sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <DndProvider backend={HTML5Backend}>
          <Stack direction="row" spacing={1} style={{ height: '100%' }}>
            <ToggleButtonGroup
              orientation="vertical"
              value={selectedPageNumber}
              onChange={onPageNumberChanged}
              exclusive
              className={style.letters}
            >
              {[...pageNumbers].map((x, index) => (
                <ToggleButton key={`pagenumber-${index}`} value={x}>
                  {x}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Grid container spacing={2} style={{ height: '100%' }}>
              <Grid item xs={4} style={{ height: '100%', overflow: 'auto', padding: '10px' }}>
                <Grid container spacing={1} direction="row">
                  <Grid item xs={12} md={12}>
                    {ocrResult && ocrResult.length > 0 && (
                      <Paper
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          p: 0.5,
                          m: 0,
                          overflowY: 'auto'
                        }}
                        component="ul"
                      >
                        {ocrResult.map((data, index) => {
                          return (
                            <OcrChip
                              key={index}
                              id={data.id}
                              type={data.type}
                              value={data.value}
                              selected={data.selected}
                              usedForInterruption={data.usedForInterruption}
                            />
                          );
                        })}
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTable stickyHeader style={{ overflowX: 'auto' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" width={500} rowSpan={2}>
                            {t(TXT_DESCRIPTION).toUpperCase()}
                          </TableCell>
                          <TableCell align="center" width={500} colSpan={2}>
                            {t(TXT_SINCE).toUpperCase()}
                          </TableCell>
                          <TableCell align="center" width={500} colSpan={2}>
                            {t(TXT_TO).toUpperCase()}
                          </TableCell>
                          <TableCell align="center" width={200} rowSpan={2}>
                            %
                          </TableCell>
                          <TableCell align="center" rowSpan={2}>
                            {t(TXT_TO_DISCOUNT).toUpperCase()}
                          </TableCell>
                          <TableCell align="center" rowSpan={2}>
                            {t(TXT_DELETE).toUpperCase()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ top: 57 }} align="center" width={400}>
                            {t(TXT_DATE).toUpperCase()}
                          </TableCell>
                          <TableCell style={{ top: 57 }} align="center">
                            {t(TXT_TIME).toUpperCase()}
                          </TableCell>
                          <TableCell style={{ top: 57 }} align="center" width={400}>
                            {t(TXT_DATE).toUpperCase()}
                          </TableCell>
                          <TableCell style={{ top: 57 }} align="center">
                            {t(TXT_TIME).toUpperCase()}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(interruptions).map((interruption) => {
                          return (
                            <TableRow key={interruption.id}>
                              <TableCell>
                                <TextFieldWithDrop
                                  id={'description-' + interruption.id}
                                  value={interruption.description || null}
                                  onChange={(e) => handleInputChange(e, interruption.id)}
                                  onDrop={(item) => handleDropInput(item, interruption.id)}
                                  style={{ width: 300 }}
                                />
                              </TableCell>
                              <TableCell>
                                <DateTimePickerWithDrop
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      margin: 'normal',
                                      autoComplete: 'off'
                                    }
                                  }}
                                  value={!interruption.since ? undefined : moment(interruption.since)}
                                  dateFormat={dateFormat}
                                  onChanged={(item, value) => handleSinceChange(value, interruption.id, item)}
                                  style={{ width: 150 }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <TimePickerWithDrop
                                  value={!interruption.since ? undefined : moment(interruption.since)}
                                  onChanged={(item, value) => {
                                    const id = interruption.id;
                                    if (id) {
                                      setInterruptions((s) => {
                                        const since = s[id].since ? moment(s[id].since).toDate() : undefined;
                                        return {
                                          ...s,
                                          [id]: {
                                            ...s[id],
                                            since: updateTimePart(since || undefined, value)
                                          }
                                        };
                                      });

                                      if (!!item?.id) {
                                        setOcrResult((prevOcrResult) => {
                                          const index = prevOcrResult.findIndex((result) => result.id === item.id);
                                          const newOcrResult = [...prevOcrResult];
                                          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
                                          return newOcrResult;
                                        });
                                      }
                                    }
                                  }}
                                  style={{ width: 105 }}
                                />
                              </TableCell>
                              <TableCell>
                                <DateTimePickerWithDrop
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      margin: 'normal',
                                      autoComplete: 'off'
                                    }
                                  }}
                                  value={!interruption.to ? undefined : moment(interruption.to)}
                                  dateFormat={dateFormat}
                                  onChanged={(item, value) => handleToChange(value, interruption.id, item)}
                                  style={{ width: 150 }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <TimePickerWithDrop
                                  value={!interruption.to ? undefined : moment(interruption.to)}
                                  onChanged={(item, value) => {
                                    const id = interruption.id;
                                    if (id) {
                                      setInterruptions((s) => {
                                        const to = s[id].to ? moment(s[id].to).toDate() : undefined;
                                        return {
                                          ...s,
                                          [id]: { ...s[id], to: updateTimePart(to || undefined, value) }
                                        };
                                      });

                                      if (!!item?.id) {
                                        setOcrResult((prevOcrResult) => {
                                          const index = prevOcrResult.findIndex((result) => result.id === item.id);
                                          const newOcrResult = [...prevOcrResult];
                                          newOcrResult[index] = { ...newOcrResult[index], usedForInterruption: true };
                                          return newOcrResult;
                                        });
                                      }
                                    }
                                  }}
                                  style={{ width: 105 }}
                                />
                              </TableCell>
                              <TableCell>
                                <NumberTextField
                                  margin="normal"
                                  id="percentage"
                                  fullWidth
                                  value={interruption.percentage}
                                  onChange={handlePercentageChange(interruption.id)}
                                  style={{ width: 75 }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={!!interruption.toDiscount}
                                  onChange={handleToDiscountChange(interruption.id)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Stack direction="row" spacing={1}>
                                  <IconButton
                                    aria-label="save interruptions"
                                    component="span"
                                    size="large"
                                    sx={{ width: '40px', height: '40px' }}
                                    onClick={handleInterruptionDelete(interruption.id)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </CustomTable>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </DndProvider>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Button onClick={handleAddInterruption} color="inherit">
            <AddIcon />
            {t(TXT_ADD_INTERRUPTION)}
          </Button>
        </div>
        <div>
          <Button onClick={props.handleCloseDialog}>{capitalize(t(TXT_CANCEL))}</Button>
          <Button onClick={onSave}>{capitalize(t(TXT_SAVE))}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
