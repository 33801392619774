import { useLazyQuery } from '@apollo/client';
import {
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetDealsOutput, GET_DEALS } from '../../api';
import { DealWizard, PaginationBar, UserSelector } from '../../components';
import _ from 'lodash';
import {
  TXT_SEARCH_BY_NAME,
  TXT_TRAFFIC_ANALYST,
  TXT_STATUS,
  TXT_ACTIVE,
  TXT_ALL,
  TXT_CLOSED,
  TXT_DEALS
} from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import { useDealStyle } from './dealStyle';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { DealDeleteConfirmation } from './DealDeleteConfimation';
import { DealCards } from './DealCards';
import { applicationUserModel, dealModel } from '../../model';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useTitle } from 'hooks';
import { VesselTypeSelector } from 'components/VesselTypeSelector/VesselTypeSelector';
import useAuth from 'hooks/useAuth';

export interface DealsProps {}

export const Deals = (props: DealsProps) => {
  const dealStyle = useDealStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  useTitle(t(TXT_DEALS));
  const [auth] = useAuth();
  const [nameFilter, setNameFilter] = useState('');

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [analystFilter, setAnalystFilter] = useState<applicationUserModel | undefined>(
    defaultSettings.trafficSpecialist
  );

  const [status, setStatus] = useState(1);
  const [typeFilter, setTypeFilter] = useState<string>(defaultSettings.vesselType);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<dealModel | undefined>(undefined);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let result: any[] = [];

    if (nameFilter.length > 0) {
      result = [
        ...result,
        {
          or: [
            {
              name: {
                contains: nameFilter
              }
            },
            {
              vessel: {
                name: {
                  contains: nameFilter
                }
              }
            },
            {
              splitQuantities: {
                some: {
                  name: {
                    contains: nameFilter
                  }
                }
              }
            }
          ]
        }
      ];
    }

    if (analystFilter) {
      result = [
        ...result,
        {
          splitQuantities: {
            some: {
              trafficSpecialistId: {
                eq: analystFilter.id
              }
            }
          }
        }
      ];
    }

    if (typeFilter) {
      result = [
        ...result,
        {
          vessel: {
            and: {
              vesselType: {
                eq: typeFilter
              }
            }
          }
        }
      ];
    }

    if (status !== 0) {
      result = [
        ...result,
        {
          isActive: {
            eq: status === 1
          }
        }
      ];
    }

    result = [
      ...result,
      {
        isQuote: {
          eq: false
        }
      }
    ];

    return result.length <= 0 ? null : { and: result };
  };

  const [getDeals, { loading, error, data }] = useLazyQuery<GetDealsOutput>(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: getFilter()
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    setNameFilter('');
  }, [t]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      trafficSpecialist: analystFilter
    });
  }, [analystFilter]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      vesselType: typeFilter
    });
  }, [typeFilter]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
  }, [nameFilter, analystFilter, typeFilter]);

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const onSearchByTypeChanged = (e: any) => {
    setTypeFilter(e.target.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (deal: dealModel) => {
    setSelectedDeal(deal);
    setOpenEditor(true);
  };

  const onDelete = (deal: dealModel) => {
    setSelectedDeal(deal);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedDeal(undefined);
  };

  const openAddEditor = () => {
    setSelectedDeal(undefined);
    setOpenEditor(true);
  };

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    setStatus(+event.target.value);
  };

  const lblStatus = capitalizeFirstLetterEveryword(t(TXT_STATUS));

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <DealWizard {...selectedDeal} onClose={onEditorClosed} afterSave={getDeals} />}
      {openDeleteConfirmation && selectedDeal && (
        <DealDeleteConfirmation {...selectedDeal} onCancel={closeDeleteConfirmation} afterSaving={getDeals} />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={dealStyle.content}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="lbl-search-deal-status">{lblStatus}</InputLabel>
                  <Select
                    labelId="dlbl-search-deal-status"
                    id="demo-simple-select"
                    value={status}
                    label={lblStatus}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={0}>{capitalizeFirstLetterEveryword(t(TXT_ALL))}</MenuItem>
                    <MenuItem value={1}>{capitalizeFirstLetterEveryword(t(TXT_ACTIVE))}</MenuItem>
                    <MenuItem value={2}>{capitalizeFirstLetterEveryword(t(TXT_CLOSED))}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <UserSelector
                  id="analyst-filter"
                  user={analystFilter}
                  label={TXT_TRAFFIC_ANALYST}
                  onChanged={(user) => {
                    setAnalystFilter(user === null ? undefined : user);
                  }}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <VesselTypeSelector onChange={onSearchByTypeChanged} disabled={loading}></VesselTypeSelector>
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <TextField
                  id="deal-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DealCards
                  loading={loading}
                  error={error}
                  data={data}
                  onEdit={onEdit}
                  onDelete={!auth.isGuest ? onDelete : undefined}
                />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data && data.deals && data.deals.totalCount ? data.deals.totalCount : 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={!auth.isGuest ? openAddEditor : undefined}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
