import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const GET_LAYTIME_CALCULATION_COMMENT_COUNT = gql`
  query getLaytimeCalculationCommentCount($entityId: UUID!) {
    count: laytimeCalculationCommentCount(entityId: $entityId)
  }
`;

export const GET_LAYTIME_CALCULATION_COMMENTS = gql`
  query getLaytimeCalculationComments($skip: Int, $take: Int, $where: LaytimeCalculationCommentFilterInput) {
    comments: laytimeCalculationComments(skip: $skip, take: $take, where: $where) {
      items {
        ${BaseCommentQueryData}
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;
