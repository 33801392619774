import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { bargeModel } from 'model';
import { GetBargesOutput } from '../../api';
import { CustomTable } from '../../components';
import { bargesColumns } from './bargesColumns';
import { BargesTableBody } from './BargesTableBody';
import { BargesTableHead } from './BargesTableHead';

interface BargesTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetBargesOutput;
  onEdit: (deal: bargeModel) => void;
  onDelete: (deal: bargeModel) => void;
  showActions?: boolean;
}

export const BargesTable = ({ error, loading, data, onEdit, onDelete, showActions, ...rest }: BargesTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <BargesTableHead columns={bargesColumns} showActions={showActions} />
        <BargesTableBody
          columns={bargesColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          showActions={showActions}
        />
      </CustomTable>
    </TableContainer>
  );
};
