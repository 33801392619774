import { Link, Typography } from '@mui/material';
import { CopyToClipboard } from 'components';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_COPIED_TO_CLIPBOARD, TXT_COPY, TXT_FAILED_TO_COPY_TO_CLIPBOARD } from '../../../../../shared/translations';
import _ from 'lodash';

interface MaritimeDirectoryContactDetailRowProps {
  index: number;
  totalRows: number;
  name?: string;
  content?: ReactNode;
  toBeCopied?: string;
  onShowMore: () => void;
}

export const MaritimeDirectoryContactDetailRow = (props: MaritimeDirectoryContactDetailRowProps) => {
  const [onHover, setOnHover] = useState(false);
  const [maxNumberOfRows] = useState(5);
  const [displayShowMoreButtom, setDisplayShowMoreButtom] = useState((props.index+1) === maxNumberOfRows && props.totalRows > maxNumberOfRows);
  const { t } = useTranslation();

  const memoizedCopyToClipboard = useMemo(() => {
    return (
      <CopyToClipboard
        content={props.toBeCopied || ''}
        tooltip={capitalizeFirstLetterEveryword(t(TXT_COPY))}
        successMessage={_.capitalize(`${t(TXT_COPIED_TO_CLIPBOARD)}`)}
        failedMessage={_.capitalize(t(TXT_FAILED_TO_COPY_TO_CLIPBOARD))}
        show={onHover && (props.name??'').length >0 }
      />
    );
  }, [props.name, props.content, onHover]);

  return (
    <tr onMouseOver={() => setOnHover(() => true)} onMouseOut={() => setOnHover(() => false)}>
      <td
        style={{
          verticalAlign: 'baseline'
        }}
      >
        <div>
          <Typography>
            {props.name}
            {memoizedCopyToClipboard}
          </Typography>
          <div>{props.content}</div>
          { !displayShowMoreButtom && (
            <div style={{
              height: '5pt',
              visibility: 'hidden'
            }}>
            </div>
          )}
          { displayShowMoreButtom && (
            <div style={{
              textAlign: 'end'
            }}>
              <Link href='#' onClick={() => {
                setDisplayShowMoreButtom(false);
                props.onShowMore();
              }}>Show {props.totalRows - maxNumberOfRows} More...
              </Link>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
