import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { integrationModel } from 'model';
import { GetIntegrationOutput } from '../../api';
import { CustomTable } from '../../components';
import { IntegrationColumns } from './integrationColumns';
import { IntegrationTableBody } from './IntegrationTableBody';
import { IntegrationTableHead } from './IntegrationTableHead';

interface IntegrationTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetIntegrationOutput;
  onEdit: (integration: integrationModel) => void;
  onDelete: (integration: integrationModel) => void;
}

export const IntegrationTable = ({ error, loading, data, onEdit, onDelete, ...rest }: IntegrationTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <IntegrationTableHead columns={IntegrationColumns} />
        <IntegrationTableBody
          columns={IntegrationColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </CustomTable>
    </TableContainer>
  );
};
