import { Grid, InputAdornment } from '@mui/material';
import { AddDealInput } from 'api';
import { IncoTermSelector } from 'components/IncoTermSelector';
import { NumberTextField } from 'components/NumberTextField';
import { ProductSelector } from 'components/ProductSelector';
import { UserSelector } from 'components/UserSelector';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_QUANTITY, TXT_TRAFFIC_ANALYST } from '../../../../../shared/translations';
import { useContext } from 'react';
import { QuoteWizardContext } from './QuoteWizard';
import { useTranslation } from 'react-i18next';

interface QuoteWizardSplitQuantityProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const QuoteWizardSplitQuantity = (props: QuoteWizardSplitQuantityProps) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<AddDealInput>();
  const { isDisabled, onTermChanged, onQuantityChanged, onProductChanged, onTrafficAnalystChanged } = useContext(QuoteWizardContext);

  const watchSplitQuantity = useWatch({
    control,
    name: `splitQuantities.${props.index}`
  });

  const watchMassUnit = useWatch({
    control,
    name: 'massUnit'
  });

  return (
    <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <IncoTermSelector
            incoTerm={watchSplitQuantity.incoTerm}
            onSelectedChange={onTermChanged(props.index)}
            error={!!formState.errors.splitQuantities?.[props.index]?.incoTerm}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            control={control}
            name={`splitQuantities.${props.index}.quantity`}
            render={({ formState }) => (
              <NumberTextField
                label={capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
                value={watchSplitQuantity.quantity || ''}
                onChange={(e) => onQuantityChanged(props.index)(parseFloat(e.currentTarget.value))}
                error={!!formState.errors.splitQuantities?.[props.index]?.quantity}
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{watchMassUnit || ''}</InputAdornment>
                }}
                inputProps={{
                  step: '0.001'
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            control={control}
            name={`splitQuantities.${props.index}.productId`}
            render={({ formState }) => (
              <ProductSelector
                product={watchSplitQuantity.product}
                onChanged={onProductChanged(props.index)}
                error={!!formState.errors.splitQuantities?.[props.index]?.productId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Controller
            name={`splitQuantities.${props.index}.trafficSpecialistId`}
            control={control}
            render={({ formState }) => (
              <UserSelector
                user={watchSplitQuantity.trafficSpecialist}
                label={TXT_TRAFFIC_ANALYST}
                onChanged={onTrafficAnalystChanged(props.index)}
                error={!!formState.errors.splitQuantities?.[props.index]?.trafficSpecialistId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};
