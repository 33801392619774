import { Fragment, ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_BARGES, GetBargesOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_BARGE, TXT_CREATE_BARGE } from '../../../../../shared/translations';
import { bargeModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';
import { Link } from '@mui/material';
import { BargeEditor } from 'pages/Barge/BargeEditor';

export interface BargeSelectorProps {
  barge?: bargeModel;
  error?: boolean;
  disabled?: boolean;
  allowAdd?: boolean;
  onSelectedChange?: (id: string | null) => void;
  onChanged?: (barge?: bargeModel) => void;
}

const VISIBLE_OPTIONS_COUNT = 10;

export const BargeSelector = (props: BargeSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<bargeModel | null>(props.barge || null);
  const [text, setText] = useState<string | undefined>(props.barge?.name);
  const [options, setOptions] = useState<bargeModel[]>([]);
  const [openCreate, setOpenCreate] = useState(false);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    if (_.isNil(text) || text.length <= 0) {
      return null;
    }

    return {
      name: {
        contains: text
      }
    };
  };

  const [getBarges, { data: bargeData, loading, refetch }] = useLazyQuery<GetBargesOutput>(GET_BARGES, {
    fetchPolicy: 'network-only',
    variables: {
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0,
      where: getFilter()
    },
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (!refetch) {
      getBarges();
    } else {
      refetch();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (_: object, value: bargeModel | null) => {
    setSelected(value);
    props.onSelectedChange && props.onSelectedChange(value && (value.id as string));
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  const noOption = (): ReactNode => {
    if (!props.allowAdd || !text || text.trim().length <= 0) {
      return 'No options';
    }

    return (
      <Link
        component="button"
        variant="inherit"
        onClick={openCloseEditor}
      >
        {`${capitalizeFirstLetterEveryword(t(TXT_CREATE_BARGE))} '${text}'`}
      </Link>
    );
  };

  const afterBargeCreated = (newBarge: bargeModel) => {
    setText((() => newBarge.name));
    onSelectedChange(_, newBarge);
  };

  const openCloseEditor = () => {
    setOpenCreate(() => !openCreate);
  };

  useEffect(() => {
    fetch();
  }, [text]);

  useEffect(() => {
    const hasData = (bargeData?.barges?.totalCount || 0) > 0;
    if (!hasData && props.allowAdd) {
      setOptions(() => []);
    } else if (hasData) {
      let bargeOptions: bargeModel[] = (bargeData?.barges?.items || []).map((barge) => ({
        id: barge.id,
        name: barge.name
      }));
      const totalCount = bargeData?.barges?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        bargeOptions = [
          ...bargeOptions,
          {
            id: 'barge-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(
              t(TXT_AND_MORE, {
                count: totalCount - VISIBLE_OPTIONS_COUNT
              })
            )
          }
        ];
      }
      if (!_.isNil(selected)) {
        bargeOptions = [{ ...selected }, ...bargeOptions];
      }
      setOptions(_.uniqBy(bargeOptions, 'id'));
    }
  }, [bargeData]);

  useEffect(() => {
    setSelected(() => props.barge ?? null);
  }, [props.barge]);

  return (
    <>
      {openCreate && <BargeEditor name={text} onClose={openCloseEditor} afterSave={afterBargeCreated} />}
      <Autocomplete
        id="barge-selector"
        onChange={onSelectedChange}
        onInputChange={onInputChange}
        value={selected}
        getOptionDisabled={(option) => option.id === 'barge-hidden-options-remaining'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ''}
        options={options}
        loading={loading}
        filterOptions={(x) => x}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={capitalizeFirstLetterEveryword(t(TXT_BARGE))}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
            error={props.error}
          />
        )}
        disabled={props.disabled}
        noOptionsText={noOption()}
      />
    </>
  );
};
