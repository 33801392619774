import { Button, Grid } from '@mui/material';
import { AddDealInput } from 'api';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_ADD } from '../../../../../shared/translations';
import AddIcon from '@mui/icons-material/Add';
import { DealWizardSplitQuantity } from './DealWizardSplitQuantity';
import { DealWizardContext } from './DealWizard';

export const DealWizardSplitQuantities = () => {
  const { control } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'splitQuantities'
  });
  const [tempId, setTempId] = useState(-1);

  const watchLoadingPorts = useWatch({
    control,
    name: 'loadingPorts'
  });

  const handleAdd = () => {
    let loadingQuantity = 0;
    if (watchLoadingPorts && watchLoadingPorts.length > 0) {
      loadingQuantity = watchLoadingPorts[0].quantity ?? 0;
    }
    append({
      id: `${tempId}`,
      canDelete: true,
      quantity: loadingQuantity,
      incoTerm: 'CIF'
    });
    setTempId(tempId - 1);
  };

  useEffect(() => {
    document.getElementById('frm-deal-wizard')?.scrollIntoView(false);
  }, [tempId]);

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} key={`splitQuantities-${field.id}`}>
            <DealWizardSplitQuantity handleRemove={remove} index={index} />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAdd} disabled={isDisabled}>
          {t(TXT_ADD)}
        </Button>
      </Grid>
    </Grid>
  );
};
