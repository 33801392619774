import { Fragment, ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { statementOfFactModel } from 'model';
import { GET_STATEMENT_OF_FACTS, GetStatementOfFactsOutput } from 'api';
import { TXT_AND_MORE, TXT_CREATE_PORT } from '../../../../../shared/translations';

export interface StatementOfFactSelectorProps {
  id?: string;
  portId?: string;
  statementOfFact?: statementOfFactModel;
  statementOfFacts?: statementOfFactModel[];
  label: string;
  error?: boolean;
  showAllSelection?: boolean;
  onSelectedChange?: (statementOfFact: statementOfFactModel | null) => void;
  onSelectedStatementOfFactsChange?: (statementOfFacts: statementOfFactModel[] | null) => void;
  disabled?: boolean;
  allowAdd?: boolean;
  multiple?: boolean;
}

const VISIBLE_OPTIONS_COUNT = 5;

export const StatementOfFactSelector = (props: StatementOfFactSelectorProps) => {
  const [selected, setSelected] = useState<statementOfFactModel | null>(props.statementOfFact || null);
  const [selectedStatementOfFacts, setSelectedStatementOfFacts] = useState<statementOfFactModel[]>(
    !!props.statementOfFacts ? [...props.statementOfFacts] : []
  );
  const [text, setText] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<statementOfFactModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { t } = useTranslation();
  // const [openCreate, setOpenCreate] = useState(false);

  const getFilter = () => {
    let result = {};
    if (!_.isNil(text) && text.length > 0) {
      result = {
        ...result,
        vesselName: {
          contains: text
        }
      };
    }

    if (!!props.portId) {
      result = {
        ...result,
        portId: {
          eq: props.portId
        }
      };
    }

    result = {
      ...result,
      status: {
        eq: 'READY_TO_USE'
      }
    };

    return _.isEqual(result, {}) ? null : { and: [{ ...result }] };
  };

  const [getStatementOfFacts, { loading }] = useLazyQuery<GetStatementOfFactsOutput>(GET_STATEMENT_OF_FACTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      let sofOptions: statementOfFactModel[] = data?.statementOfFacts?.items || [];
      const totalCount = data?.statementOfFacts?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        sofOptions = [
          ...sofOptions,
          {
            id: 'statementOfFact-hidden-options-remaining',
            vesselName: capitalizeFirstLetterEveryword(
              t(TXT_AND_MORE, {
                count: totalCount - VISIBLE_OPTIONS_COUNT
              })
            )
          }
        ];
      }
      if (!_.isNil(selected)) {
        sofOptions = [{ ...selected }, ...sofOptions];
      }
      setOptions(() => [..._.uniqBy(sofOptions, 'id')]);
    }
  });

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: statementOfFactModel | statementOfFactModel[] | null) => {
    if (!!props.multiple) {
      const newValue = value !== null && Array.isArray(value) ? [...value] : [];
      setSelectedStatementOfFacts(() => newValue);
      props.onSelectedStatementOfFactsChange && props.onSelectedStatementOfFactsChange(newValue);
    } else {
      const newValue = value !== null && !Array.isArray(value) ? { ...value } : null;
      setSelected(() => newValue);
      props.onSelectedChange && props.onSelectedChange(newValue);
    }
  };

  const openCloseEditor = () => {
    // setOpenCreate((prev) => !prev);
  };

  const noOption = (): ReactNode => {
    if (!props.allowAdd || !text || text.trim().length <= 0) {
      return 'No options';
    }

    return (
      <Link component="button" variant="inherit" onClick={openCloseEditor}>
        {`${_.capitalize(t(TXT_CREATE_PORT))} '${text}'`}
      </Link>
    );
  };

  // const onCreated = (newStatementOfFact: statementOfFactModel) => {
  //   setOptions((prev) => [...prev, newStatementOfFact]);
  //   onSelectedChange(_, !!props.multiple ? [...selectedStatementOfFacts, newStatementOfFact] : newStatementOfFact);
  // };

  useEffect(() => {
    getStatementOfFacts();
  }, [text, props.portId]);

  useEffect(() => {
    setSelected(() => props.statementOfFact || null);
  }, [props.statementOfFact]);

  useEffect(() => {
    setSelectedStatementOfFacts(() => (!!props.statementOfFacts ? [...props.statementOfFacts] : []));
  }, [props.statementOfFacts]);

  return (
    <>
      {/* {openCreate ? <StatementOfFactEditor name={text} onClose={openCloseEditor} afterSave={onCreated} /> : null} */}
      <Autocomplete
        id={props.id || 'statementOfFact-selector'}
        onChange={onSelectedChange}
        onInputChange={onInputChange}
        value={!!props.multiple ? selectedStatementOfFacts : selected}
        getOptionDisabled={(option) => option.id === 'statementOfFact-hidden-options-remaining'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.vesselName || ''}
        options={options}
        loading={loading}
        multiple={props.multiple}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            label={capitalizeFirstLetterEveryword(props.label ?? '')}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
            error={props.error}
          />
        )}
        disabled={props.disabled}
        noOptionsText={noOption()}
      />
    </>
  );
};
