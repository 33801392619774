import { View, Text } from '@react-pdf/renderer';
import { dealModel, laytimeCalculationModel } from 'model';
import { formatMinutes, totalHours, truncateNumber } from 'pages/DemurragePage/utils';
import { formatCurrency } from 'shared/utils';
import { isBreak } from './LaytimeCalculationPdf';
import { styles } from './styles';
import _ from 'lodash';

interface TotalsProps {
  rowNumber: number;
  calculation: laytimeCalculationModel;
  deal?: dealModel;
}

export const Totals = ({ rowNumber, calculation, deal }: TotalsProps) => {
  const amountPerDay = calculation.timeInDemurrage ? calculation.demurragePerDay : calculation.despatchPerDay;
  const amount = calculation.timeInDemurrage ? calculation.demurrageAmount : calculation.despatchAmount;
  let sharePercentage = calculation.sharedPercentage ?? 0;
  sharePercentage = Math.round(sharePercentage * 100000000) / 100000000;
  const amountBeforeShare = calculation.amountBeforeShare;
  const currency = calculation?.currency || 'USD';
  const portQuantity = calculation?.quantity ?? 0;


  let rowIndex = ['PermittedTime', 'DeductionOffset', 'NetTimeUsed', 'TimeLost', 'Time', 'ShareName', 'SharePercentage'];
  if (calculation.deductionOffset) {
    rowIndex = _.remove(rowIndex, 'DeductionOffset');
  }

  return (
    <>
      <View style={[styles.flexRow, { borderTop: 1 }]} break={isBreak(rowNumber + rowIndex.indexOf('PermittedTime'))}>
        <Text style={{ width: 100 }}>Time allowed:</Text>
        <Text>{`${formatMinutes(calculation.permittedTime)}   ${
          calculation.permittedTime ? '(' + totalHours(calculation.permittedTime) + ')' : ''
        }`}</Text>
      </View>
      {calculation.deductionOffset ? (
        <View style={styles.flexRow} break={isBreak(rowNumber + rowIndex.indexOf('DeductionOffset'))}>
          <Text style={{ width: 100 }}>Deduction:</Text>
          <Text>{`${formatMinutes(calculation.deductionOffset)}   ${
            calculation.deductionOffset ? '(' + totalHours(calculation.deductionOffset) + ')' : ''
          }`}</Text>
        </View>
      ) : null}
      <View style={[styles.flexRow, { borderBottom: 1 }]} break={isBreak(rowNumber + rowIndex.indexOf('NetTimeUsed'))}>
        <Text style={{ width: 100 }}>Time used:</Text>
        <Text>{`${formatMinutes(calculation.netTimeUsed)}   ${
          calculation.netTimeUsed ? '(' + totalHours(calculation.netTimeUsed) + ')' : ''
        }`}</Text>
      </View>
      <View style={styles.flexRow} break={isBreak(rowNumber + rowIndex.indexOf('TimeLost'))}>
        <Text style={{ width: 100 }}>Time lost:</Text>
        <Text>{`${formatMinutes(calculation.timeInDemurrage)}   ${
          calculation.timeInDemurrage ? '(' + totalHours(calculation.timeInDemurrage) + ')' : ''
        }`}</Text>
      </View>
      <View style={[styles.flexRow, { borderBottom: 1, fontWeight: 'bold' }]} break={isBreak(rowNumber + rowIndex.indexOf('Time'))}>
        <Text style={{ width: 100 }}>{calculation.timeInDemurrage ? 'Demurrage' : 'Despatch'} due:</Text>
        <Text style={{ width: 100 }}>
          {formatMinutes(calculation.timeInDemurrage || calculation.timeInDespatch)} hrs at
        </Text>
        <Text style={{ width: 200 }}>
          {formatCurrency(amountPerDay || 0, currency)} {currency + '/day'}{' '}
        </Text>
        <Text style={{ fontWeight: 'bold' }}>
          {formatCurrency(amountBeforeShare ? amountBeforeShare : amount || 0, currency)}
        </Text>
      </View>
      {amountBeforeShare ? (
        <div>
          <View style={[styles.flexRow, { fontWeight: 'bold' }]} break={isBreak(rowNumber + rowIndex.indexOf('ShareName'))}>
            <Text>Share of {calculation?.counterParty?.name}</Text>
          </View>
          <View style={[styles.flexRow, { borderBottom: 1, fontWeight: 'bold' }]} break={isBreak(rowNumber + rowIndex.indexOf('SharePercentage'))}>
            <Text style={{ width: 100 }}></Text>
            <Text style={{ width: 100 }}></Text>
            <Text style={{ width: 200 }}>
              Quantity: {portQuantity} @ {truncateNumber(sharePercentage, 3)} % = {Math.round(portQuantity * sharePercentage) / 100}
            </Text>
            <Text style={{ fontWeight: 'bold' }}>{formatCurrency(amount || 0, currency)}</Text>
          </View>
        </div>
      ) : null}
    </>
  );
};
