import { gql } from '@apollo/client';
import { bargeModel } from 'model';

export const getBargesFragment = `
  items {
    id,
    name
  },
  totalCount
`;

export type GetBargesOutput = {
  barges?: {
    items?: [bargeModel],
    totalCount?: number;
  }
};

export const GET_BARGES = gql`
  query getBarges($take: Int!, $skip: Int!, $where: BargeFilterInput) {
    barges(take: $take, skip: $skip, where: $where) {
      ${getBargesFragment}
    }
  }
`;
