import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import { DemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import moment from 'moment';
import { DaysOfWeek, formatNumber, getDateTimeFormat, getShexConditions } from 'shared/utils';
import { TXT_ALWAYS_ON_DEMURRAGE, TXT_NOT_ALWAYS_ON_DEMURRAGE } from '../../../../../../../shared/translations';
import { dealPortModel } from 'model';
import { getProducts } from 'shared/utils';
import _ from 'lodash';
interface DealInfoProps {
  state: DemurragePageState;
}

const InfoText: React.FC = ({ children }) => <Text style={{ width: 80 }}>{children}</Text>;

export const DealInfo = ({ state }: DealInfoProps) => {
  const calc = state.selectedLaytimeCalculation;
  const dateFormat = getDateTimeFormat();
  const laodingUnloadingText = state.selectedTab === 'loading' ? 'Loading' : 'Discharging ';
  const onceOnDemurrageCondition = calc?.onceOnDemurrageCondition ?? 'ALWAYS';
  const onceOnDemurrageConditionDesc =
    'Once on demurrage, ' +
    (onceOnDemurrageCondition === 'ALWAYS' ? TXT_ALWAYS_ON_DEMURRAGE : TXT_NOT_ALWAYS_ON_DEMURRAGE);

  const currency = calc?.currency;
  const getDealPortInfo = (dealPort?: dealPortModel) => {
    const info = `${dealPort?.port?.name}`;
    return dealPort?.port?.country ? `${info}, ${dealPort?.port?.country}` : info;
  };

  const shexSummary = getShexConditions(state.shexConditions);
  const getPort = (isLoading: boolean) => {
    if (!calc) {
      return undefined;
    }

    const calcType = (calc?.counterPartyType?.name ?? '').toUpperCase();
    const ports = (isLoading ? state.demurrageDeal?.loadingPorts : state.demurrageDeal?.unloadingPorts) || [];

    if ((ports?.length ?? 0) === 0) {
      return undefined;
    }

    if (calcType === 'SHIPOWNER' || calcType === 'SUPPLIER') {
      const shipownerPort = ports.find((dealPort) => dealPort.id === calc.dealPortId);
      if (!shipownerPort && (ports?.length ?? 0) > 0) {
        return isLoading ? ports[0] : ports[ports.length - 1];
      } else {
        return shipownerPort;
      }
    }

    if (!_.isEmpty(calc.splitQuantities)) {
      const portId = isLoading ? calc.splitQuantities![0].loadingPortId : calc.splitQuantities![0].unloadingPortId;
      return ports.find((dealPort) => dealPort.id === portId);
    }

    return undefined;
  };

  const loadingPort = getPort(true);
  const unloadingPort = getPort(false);

  let route = '';
  const leg1 = loadingPort ? getDealPortInfo(loadingPort) : '';
  const leg2 = unloadingPort ? getDealPortInfo(unloadingPort) : '';
  route = `${leg1 + ' / ' + leg2}`;

  const quantity = calc?.quantityOverride || calc?.quantity || state.selectedPort?.quantity;
  const norTenderedDate = calc?.norTenderedDateOverride || calc?.norTenderedDate || state.selectedPort?.norTenderedDate;
  const validNor = calc?.validNorOverride || calc?.validNor || state.selectedPort?.validNor;
  const commencedDate = calc?.commencedDateOverride || calc?.commencedDate || state.selectedPort?.commencedDate;
  const realLaytimeBegins = calc?.realLaytimeBeginsOverride || calc?.realLaytimeBegins;
  const completedDate = calc?.completedDateOverride || calc?.completedDate || state.selectedPort?.completedDate;
  const laytimeEnds = calc?.laytimeTimeEndsOverride;
  const uom = calc?.massUnit ?? state.demurrageDeal?.massUnit;
  return (
    <>
      <View style={styles.flexRow}>
        <InfoText>Partner</InfoText>
        <Text>{calc?.counterParty?.name}</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>From/To</InfoText>
        <Text>{route}</Text>
      </View>
      <View
        style={{
          ...styles.flexRow,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid'
        }}
      >
        <InfoText>Details</InfoText>
        <Text>{onceOnDemurrageConditionDesc}</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>{laodingUnloadingText} at</InfoText>
        <Text>{`${state.selectedPort?.port?.name}, ${state.selectedPort?.port?.country}`}</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>Cargo</InfoText>
        <Text style={{ width: 40 }}>{uom}</Text>
        <Text>
          {formatNumber(quantity)} {getProducts(state.demurrageDeal, calc)}
        </Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>Allowance</InfoText>
        <Text style={{ width: 40 }}>{uom}/day</Text>
        <Text style={{ width: 40 }}>{formatNumber(calc?.dischargeRate)}</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>Demurrage rate</InfoText>
        <Text style={{ width: 40 }}>{currency + '/day'}</Text>
        <Text>{formatNumber(calc?.demurragePerDay)}</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>Despatch rate</InfoText>
        <Text style={{ width: 40 }}>{currency + '/day'}</Text>
        <Text>{formatNumber(calc?.despatchPerDay)}</Text>
      </View>
      {shexSummary && (
        <View style={styles.flexRow}>
          <InfoText>Terms: </InfoText>
          <Text>{shexSummary ? '' + shexSummary : ''}</Text>
        </View>
      )}
      {state.shexConditions
        ?.filter((x) => x.shexConditionType === 'RECURRENT')
        .map((shex) => {
          const from = shex.fromDayOfWeek !== undefined ? DaysOfWeek[shex.fromDayOfWeek] : '';
          const fromTime = shex.fromTime !== undefined ? shex.fromTime : '';
          const until = shex.untilDayOfWeek !== undefined ? DaysOfWeek[shex.untilDayOfWeek] : '';
          const untilTime = shex.untilTime !== undefined ? shex.untilTime : '';
          return (
            <View style={styles.flexRow} key={shex.id}>
              <InfoText>&nbsp;</InfoText>
              <Text>{`from ${from} ${fromTime} until ${until} ${untilTime}`}</Text>
            </View>
          );
        })}

      <View style={styles.flexRow}>
        <InfoText>{norTenderedDate ? moment(norTenderedDate).format(dateFormat) : 'N/A'}</InfoText>
        <Text>Notice Tendered</Text>
      </View>
      {validNor && (
        <View style={styles.flexRow}>
          <InfoText>{validNor ? moment(validNor).format(dateFormat) : 'N/A'}</InfoText>
          <Text>NOR Valid/Accepted</Text>
        </View>
      )}
      <View style={styles.flexRow}>
        <InfoText>{commencedDate ? moment(commencedDate).format(dateFormat) : 'N/A'}</InfoText>
        <Text>{laodingUnloadingText} commenced</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>{realLaytimeBegins ? moment(realLaytimeBegins).format(dateFormat) : 'N/A'}</InfoText>
        <Text>Laytime commenced</Text>
      </View>
      <View style={styles.flexRow}>
        <InfoText>{completedDate ? moment(completedDate).format(dateFormat) : 'N/A'}</InfoText>
        <Text>{laytimeEnds ? laodingUnloadingText : 'Operations'} completed</Text>
      </View>
      {laytimeEnds && (
        <View style={styles.flexRow}>
          <InfoText>{laytimeEnds ? moment(laytimeEnds).format(dateFormat) : 'N/A'}</InfoText>
          <Text>Laytime Ends</Text>
        </View>
      )}
    </>
  );
};
