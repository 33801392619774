/* eslint-disable indent */
import { useMutation } from '@apollo/client';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { AddLaytimeCalculationInput, ADD_LAYTIME_CALCULATION } from 'api';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { calculationSelectionModel, CounterPartyTypes, laytimeCalculationModel } from 'model';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ADD_LAYTIME_CALCULATION, TXT_CREATING_LAYTIME_CALCULATION } from '../../../../../../shared/translations';
import {
  createDemurrageCalculation,
  getPropName,
  useDemurragePageDispatch,
  useDemurragePageState
} from '../DemurragePageContext';
import { getCounterPartyLabel } from '../utils';
import { getAvailableCalculations } from 'shared/calculationUtils';

export const AddButton = () => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const { demurrageDeal, selectedTab } = useDemurragePageState();
  const dispatch = useDemurragePageDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [addLaytimeCalculation, { loading }] = useMutation<{
    addLaytimeCalculation: laytimeCalculationModel;
  }>(ADD_LAYTIME_CALCULATION, {
    onError: apolloErrorHandler,
    onCompleted: (calculation: any) => {
      onLaytimeCalculationCreated(calculation.addLaytimeCalculation);
    }
  });

  const getMenuItems = () => {
    return getAvailableCalculations(demurrageDeal).filter(
      (item) => (selectedTab === 'loading' && !!item.isLoading) ||
        (selectedTab === 'unloading' && !item.isLoading)
    );
  };

  const menuItems = useMemo(getMenuItems, [
    selectedTab,
    demurrageDeal?.splitQuantities,
    demurrageDeal?.suppliers,
    demurrageDeal?.unloadingPorts,
    demurrageDeal?.loadingPorts,
    demurrageDeal?.shipowner
  ]);

  const onLaytimeCalculationCreated = (calculation: laytimeCalculationModel) => {
    dispatch(createDemurrageCalculation(selectedTab, calculation));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const addCalculationByCounterParty = (menu: calculationSelectionModel) => {
    const port = menu.dealPort;

    if (!port) {
      return;
    }
    const addCalculationInput: AddLaytimeCalculationInput = {
      dealPortId: port.id!,
      counterPartyId: menu.counterParty!.id!,
      counterPartyTypeId: CounterPartyTypes[menu.counterPartyType],
      laycanStartDate: port.laycanStartDate,
      laycanEndDate: port.laycanEndDate,
      splitQuantityIds: (menu.splits || []).map((split) => split.id!),
      dealSupplierId: menu.dealSupplierId,
      currency: demurrageDeal?.currency ?? 'USD'
    };
    addLaytimeCalculation({
      variables: {
        input: addCalculationInput
      }
    });
  };

  const handleItemClick = (menu: calculationSelectionModel) => () => {
    addCalculationByCounterParty(menu);

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuLabel = (menu: calculationSelectionModel) => {
    let label = getCounterPartyLabel(menu.counterPartyType);
    label = `${label}: ${menu.counterParty?.name}@${menu.dealPort?.port?.name}`;

    return label;
  };

  return (
    <Box width="100%" textAlign="center" p={1} sx={{ borderBottom: 1 }}>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        disabled={loading}
        style={{ minWidth: '250px' }}
      >
        {loading
          ? capitalizeFirstLetterEveryword(t(TXT_CREATING_LAYTIME_CALCULATION))
          : capitalizeFirstLetterEveryword(t(TXT_ADD_LAYTIME_CALCULATION))}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {menuItems.map((menu) => {
          return (
            <MenuItem
              key={`${menu.counterPartyType}-${menu.counterParty?.id}-${menu.dealPort?.id}`}
              onClick={handleItemClick(menu)}
              disabled={
                demurrageDeal && !demurrageDeal.isQuote &&
                demurrageDeal![getPropName(selectedTab)] !== null &&
                demurrageDeal![getPropName(selectedTab)]!.findIndex((calculation) => {
                  return (
                    calculation.counterPartyType?.name.toLowerCase() === menu.counterPartyType.toLowerCase() &&
                    calculation.counterParty?.id === menu.counterParty?.id &&
                    calculation.dealPortId === menu.dealPort?.id
                  );
                }) >= 0
              }
            >
              {getMenuLabel(menu)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
