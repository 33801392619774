import axios from 'axios';
import addDealPortCommentDto from 'model/addDealPortCommentDto';
import dealPortCommentDto from 'model/dealPortCommentDto';
import { apiBaseUrl } from 'shared/links';

const useDealPortFormApi = () => {
  const baseUrl = `${apiBaseUrl}/api/dealportform`;

  const getComments = async (linkId: string) => {
    try {
      const response = await axios.get<dealPortCommentDto[]>(`${baseUrl}/comments`, {
        params: {
          linkId
        }
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.message);
      } else {
        console.error(e);
      }
      return [];
    }
  };

  const getCommentsCount = async (linkId: string) => {
    try {
      const response = await axios.get<number>(`${baseUrl}/commentscount`, {
        params: {
          linkId
        }
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.message);
      } else {
        console.error(e);
      }
      return 0;
    }
  };

  const addComment = async (linkId: string, dto: addDealPortCommentDto) => {
    try {
      const response = await axios.post<dealPortCommentDto>(`${baseUrl}/comment`, dto, {
        params: {
          linkId
        }
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.message);
      } else {
        console.error(e);
      }
    }
  };

  return {
    getComments,
    addComment,
    getCommentsCount
  };
};

export default useDealPortFormApi;
