import { ApolloError } from '@apollo/client';
import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetDealsOutput } from '../../../api';
import { CustomError } from '../../../components/CustomError';
import { TXT_LOADING, TXT_NO_ROWS } from '../../../../../../shared/translations';
import _ from 'lodash';
import { QuoteTableRow } from './QuoteTableRow';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { useContext } from 'react';
import { QuoteTableContext } from './QuoteTable';

export interface QuoteTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetDealsOutput;
}

export const QuoteTableBody = ({ loading, error, data }: QuoteTableBodyProps) => {
  const { t } = useTranslation();
  const { columns, openDetailIndex } = useContext(QuoteTableContext);

  return (
    <TableBody>
      {loading && (
        <TableRow>
          <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
            <CircularProgress />
            <h3>{t(TXT_LOADING).toUpperCase()}</h3>
          </TableCell>
        </TableRow>
      )}
      {!loading && error && error.message && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <CustomError>{error.message}</CustomError>
          </TableCell>
        </TableRow>
      )}
      {!loading && data?.deals && !_.isEmpty(data.deals.items)
        ? (data.deals.items || []).map((deal, index) => (
          <QuoteTableRow key={`quick-calc-${deal.id}`} deal={deal} defaultOpenDetail={index === openDetailIndex} />
        ))
        : null}
      {!loading && (!data || !data.deals || !data.deals.items || data.deals.items.length <= 0) && (
        <TableRow>
          <TableCell colSpan={columns.length + 1} align="center">
            {capitalizeFirstLetterEveryword(t(TXT_NO_ROWS))}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
