import { gql } from '@apollo/client';

export interface UpdateInterruptionInput {
  id: string;
  laytimeCalculationId: string;
  description: string;
  since?: Date;
  to?: Date;
  percentage: number;
  toDiscount: boolean;
}

export type UpdateInterruptionsOutput = {
  updateInterruptions: {
    laytimeCalculationId: string;
    description: string;
    since?: Date;
    to?: Date;
    percentage: number;
    toDiscount: boolean;
  }[];
};

export const UPDATE_INTERRUPTIONS = gql`
  mutation updateInterruptions($laytimeCalculationId: UUID!, $hideRemarks: Boolean!, $input: [UpdateInterruptionInput]!) {
    updateInterruptions(laytimeCalculationId: $laytimeCalculationId, hideRemarks: $hideRemarks, input: $input) {
      id
      description
      since
      to
      percentage
      toDiscount
      laytimeCalculationId
    }
  }
`;
