import {
  TXT_DELETE,
  TXT_EDIT,
  TXT_EMAIL,
  TXT_ENABLE,
  TXT_FULL_NAME,
  TXT_INITIAL,
  TXT_OCR,
  TXT_ROLE,
  TXT_VESSEL_PORTAL
} from '../../../../../../shared/translations';

export interface UserColumn {
  id: 'username' | 'initial' | 'fullName' | 'allowVesselPortal' | 'allowOCR' | 'securityRole' | 'enable' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const userColumns: UserColumn[] = [
  {
    id: 'username',
    label: TXT_EMAIL,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'initial',
    label: TXT_INITIAL,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'fullName',
    label: TXT_FULL_NAME,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'securityRole',
    label: TXT_ROLE,
    maxWidth: '20%',
    align: 'center'
  },
  {
    id: 'allowVesselPortal',
    label: TXT_VESSEL_PORTAL,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'allowOCR',
    label: TXT_OCR,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'enable',
    label: TXT_ENABLE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
