import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS, GetProductsOutput } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_AND_MORE, TXT_PRODUCT } from '../../../../../shared/translations';
import { productModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';

export interface ProductSelectorProps {
  product?: productModel;
  error?: boolean;
  disabled?: boolean;
  onSelectedChange?: (id: string | null) => void;
  onChanged?: (product?: productModel) => void;
}

const VISIBLE_OPTIONS_COUNT = 5;

export const ProductSelector = (props: ProductSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<productModel | null>(props.product || null);
  const [text, setText] = useState<string | undefined>(props.product?.name);
  const [options, setOptions] = useState<productModel[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [getProducts, { data: productData, loading, refetch }] = useLazyQuery<GetProductsOutput>(GET_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      name: text,
      take: VISIBLE_OPTIONS_COUNT,
      skip: 0
    },
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (!refetch) {
      getProducts();
    } else {
      refetch();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: productModel | null) => {
    setSelected(value);
    props.onSelectedChange && props.onSelectedChange(value && (value.id as string));
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  useEffect(() => {
    fetch();
  }, [text]);

  useEffect(() => {
    if ((productData?.products?.items?.length || 0) > 0) {
      let productOptions: productModel[] = (productData?.products?.items || []).map((product) => ({
        id: product.id,
        name: product.name
      }));
      const totalCount = productData?.products?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        productOptions = [
          ...productOptions,
          {
            id: 'product-hidden-options-remaining',
            name: capitalizeFirstLetterEveryword(t(TXT_AND_MORE, {
              count: totalCount - VISIBLE_OPTIONS_COUNT
            }))
          }
        ];
      }
      if (!_.isNil(selected)) {
        productOptions = [{ ...selected }, ...productOptions];
      }
      setOptions(_.uniqBy(productOptions, 'id'));
    }
  }, [productData]);

  useEffect(() => {
    setSelected(() => props.product ?? null);
  }, [props.product]);

  return (
    <Autocomplete
      id="product-selector"
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      value={selected}
      getOptionDisabled={(option) => option.id === 'product-hidden-options-remaining'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={capitalizeFirstLetterEveryword(t(TXT_PRODUCT))}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {/* eslint-disable-next-line max-len */}
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
