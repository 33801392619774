import { atom } from 'recoil';
import { getDefaultSettingsLocalStorage } from 'shared/defaultSettingsLocalStorage';

const defaultSettingStateKey = 'defaultSetting';

const defaultSettingState = atom({
  key: defaultSettingStateKey,
  default: getDefaultSettingsLocalStorage()
});

export default defaultSettingState;
