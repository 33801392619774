import { TextFieldProps } from '@mui/material';

export * from './NavBar';
export * from './LanguagePopover';
export * from './Copyright';
export * from './DividerWithLine';
export * from './MicrosoftLoginButton';
export * from './GoogleLoginButton';
export * from './UserInfo';
export * from './PrivateRoute';
export * from './CargoIcon';
export * from './PaginationBar';
export * from './CustomTable';
export * from './CustomCardContainer';
export * from './UKFlagIcon';
export * from './MicrosoftIcon';
export * from './GoogleIcon';
export * from './CounterPartySelector';
export * from './VesselSelector';
export * from './PortSelector';
export * from './CustomError';
export * from './RegisterUser';
export * from './SpainFlagIcon';
export * from './ProductSelector';
export * from './UserSelector';
export * from './TabPanel';
export * from './DealPortEditorContent';
export * from './CounterPartyTypeSelector';
export * from './InterruptionDefinitionSelector';
export * from './DeleteModal';
export * from './CurrencySelector';
export * from './MassUnitSelector';
export * from './CountrySelector';
export * from './TraderSelector';
export * from './IncoTermSelector';
export * from './BusinessUnitSelector';
export * from './DealWizard';
export * from './NumberTextField';
export * from './CalculationStatusSelector';
export * from './LaytimechartReportFilter';
export * from './SplitQuantitySelector';
export * from './BargeSelector';
export * from './DateRangePicker';
export * from './CalendarRule';
export * from './GridNoResultsOverlay';
export * from './BargeReportFilter';
export * from './OcrChip';
export * from './BorderLinearProgress';
export * from './CommentButton';
export * from './Comments';
export * from './CommentBox';
export * from './CommentCard';
export * from './TextFieldWithDrop';
export * from './DateTimePickerWithDrop';
export * from './NumberFieldWithDrop';
export * from './UserMention';
export * from './QuickLaytimeCalc';
export * from './DealCalculations';
export * from './CopyCalculation';
export * from './PasteCalculation';
export * from './DeleteCalculation';
export * from './DeleteModal';
export * from './PromoteCalculation';
export * from './OverlayLoading';
export * from './PromoteConfirmation';
export * from './TimePickerWithDrop';
export * from './CopyToClipboard';
export * from './StatementOfFactSelector';
export * from './DealPortRequestForm';
export * from './DealPortSelector';
export * from './CustomFooter';

export const textFieldDefaultProps: TextFieldProps = {
  size: 'small',
  InputLabelProps: {
    shrink: true
  }
};
