import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { GetCounterPartiesOutput } from '../../../api';
import { CustomTable } from '../../../components';
import { counterPartyModel } from '../../../model';
import { counterPartyColumns } from './counterPartyColumns';
import { CounterPartyTableBody } from './CounterPartyTableBody';
import { CounterPartyTableHead } from './CounterPartyTableHead';

interface CounterPartyTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetCounterPartiesOutput;
  onEdit: (vessel: counterPartyModel) => void;
  onDelete: (vessel: counterPartyModel) => void;
  showActions?: boolean;
}

// eslint-disable-next-line max-len
export const CounterPartyTable = ({
  error,
  loading,
  data,
  onEdit,
  onDelete,
  showActions,
  ...rest
}: CounterPartyTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <CounterPartyTableHead columns={counterPartyColumns} showActions={showActions} />
        <CounterPartyTableBody
          columns={counterPartyColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          showActions={showActions}
        />
      </CustomTable>
    </TableContainer>
  );
};
