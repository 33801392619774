import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductsColumn } from './productsColumns';

export interface ProductTableHeadProps {
  columns: ProductsColumn[];
  showActions?: boolean;
}

export const ProductsTableHead = (props: ProductTableHeadProps) => {
  const { t } = useTranslation();

  const filterColumnsByRole = (): ProductsColumn[] => {
    if (!!props.showActions) {
      return props.columns;
    }
    return props.columns.filter((col) => col.id !== 'edit' && col.id !== 'delete');
  };

  return (
    <TableHead>
      <TableRow>
        {filterColumnsByRole().map((col) => (
          <TableCell key={`head-${col.id}`} align={col.align || 'center'} style={{ width: col.maxWidth }}>
            {t(col.label).toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
