import { gql } from '@apollo/client';

export interface AddInterruptionDefinitionInput {
  code: string;
  operatingCapacity: number;
  description: string;
}

export interface UpdateInterruptionDefinitionInput extends AddInterruptionDefinitionInput {
  id: string;
}

export interface DeleteInterruptionDefinitionInput {
  id: string;
}

export const ADD_INTERRUPTION_DEFINITION = gql`
  mutation addInterruptionDefinition($input: AddInterruptionDefinitionInput!) {
    addInterruptionDefinition(input: $input) {
      id
      code
      operatingCapacity
      description
    }
  }
`;

export const UPDATE_INTERRUPTION_DEFINITION = gql`
  mutation updateInterruptionDefinition($input: UpdateInterruptionDefinitionInput!) {
    updateInterruptionDefinition(input: $input) {
      id
      code
      operatingCapacity
      description
    }
  }
`;

export const DELETE_INTERRUPTION_DEFINITION = gql`
  mutation deleteInterruptionDefinition($input: DeleteInterruptionDefinitionInput!) {
    deleteInterruptionDefinition(input: $input)
  }
`;
