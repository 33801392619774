import { useLazyQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useTheme
} from '@mui/material';
import { DELETE_HOLIDAY, GET_HOLIDAYS, GET_TENANT, GetHolidaysOutput, GetTenantOutput } from 'api';
import { useApolloErrorHandler, useDefaultSettings, useTitle } from 'hooks';
import { HolidayCulture, HolidayCultureOptions, holidayModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_ADD,
  TXT_CALENDAR,
  TXT_DATE,
  TXT_DELETE,
  TXT_DELETE_TEXT,
  TXT_DESCRIPTION,
  TXT_UPDATE,
  TXT_YEAR
} from '../../../../../shared/translations';
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import moment from 'moment';
import { PageSizeOptions } from 'shared/constants';
import { DeleteModal, GridNoResultsOverlay } from 'components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { CalendarEditor } from './CalendarEditor';
import { useCalendarStyle } from './calendarStyle';

export const Calendar = () => {
  useTitle(TXT_CALENDAR);
  const globalStyles = useGlobalStyles();
  const today = new Date();
  const currentYear = today.getFullYear();
  const startYear = currentYear - 2;
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const theme = useTheme();
  const style = useCalendarStyle();

  const [pageNumber, setPageNumber] = useState(1);
  const [defaultSettings, setDefaultSettings] = useDefaultSettings();
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);
  const [culture, setCulture] = useState<HolidayCulture>('EN_US');
  const [year, setYear] = useState(currentYear);
  const [data, setData] = useState<GetHolidaysOutput | undefined>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selected, setSelected] = useState<holidayModel | undefined>();
  const [showEditor, setShowEditor] = useState(false);

  const cultureLabelId = 'culture-label';
  const cultureLabelTxt = capitalizeFirstLetterEveryword(t(TXT_CALENDAR));

  const calendarLabelId = 'calendar-label';
  const calendarLabelTxt = capitalizeFirstLetterEveryword(t(TXT_YEAR));

  const [getTenant, { loading: loadCulture }] = useLazyQuery<GetTenantOutput>(GET_TENANT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.tenant) {
        return;
      }
      setCulture(() => data.tenant?.holidayCulture || 'EN_US');
    },
    onError: apolloErrorHandler
  });

  const customFooter = () => (
    <GridFooterContainer
      sx={{
        padding: `0 ${theme.spacing(1)}`
      }}
    >
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onCreate}>
        {capitalizeFirstLetterEveryword(t(TXT_ADD))}
      </Button>
      <GridFooter
        sx={{
          border: 'none'
        }}
      />
    </GridFooterContainer>
  );

  const getFilter = () => ({
    culture: {
      eq: culture
    }
  });

  const [getHolidays, { loading, refetch }] = useLazyQuery<GetHolidaysOutput>(GET_HOLIDAYS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: pageSize,
      skip: pageSize * (pageNumber - 1)
    },
    onCompleted: (data) => {
      setData(data);
    },
    onError: apolloErrorHandler
  });

  const onCreate = () => {
    setSelected(undefined);
    setShowEditor(true);
  };

  const onUpdate = (model: holidayModel) => {
    return () => {
      setSelected(() => model);
      setShowEditor(() => true);
    };
  };

  const closeEditor = () => setShowEditor(() => false);
  const closeDeleteConfirmation = () => setIsDeleting(() => false);

  const onDelete = (model: holidayModel) => {
    return () => {
      setSelected(() => model);
      setIsDeleting(() => true);
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'holiday',
      headerName: capitalizeFirstLetterEveryword(t(TXT_DATE)),
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<holidayModel, Date>) => {
        return moment(params.row.date).format('MMMM DD YYYY');
      }
    },
    {
      field: 'name',
      headerName: capitalizeFirstLetterEveryword(t(TXT_DESCRIPTION)),
      flex: 1,
      minWidth: 200
    },
    {
      field: 'update',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_UPDATE)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<holidayModel, any>) => {
        return (
          <IconButton
            className={globalStyles.editButton}
            aria-label="delete project"
            component="span"
            onClick={onUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      }
    },
    {
      field: 'delete',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_DELETE)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<holidayModel, any>) => {
        return (
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="delete project"
            component="span"
            onClick={onDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];

  useEffect(() => {
    getTenant();
  }, []);

  useEffect(() => {
    getHolidays({
      variables: {
        year
      }
    });
  }, [year]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  let txtDeleteConfirmation = t(TXT_DELETE_TEXT, {
    name: selected?.name
  });
  txtDeleteConfirmation = _.capitalize(txtDeleteConfirmation);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {isDeleting && !!selected && (
        <DeleteModal
          txtConfirmation={txtDeleteConfirmation}
          onCancel={closeDeleteConfirmation}
          id={selected.id!}
          mutation={DELETE_HOLIDAY}
          afterSaving={refetch}
        />
      )}
      {showEditor && <CalendarEditor {...selected} culture={culture} afterSave={refetch} onClose={closeEditor} />}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={style.paper}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                {loadCulture ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth disabled>
                    <InputLabel id={cultureLabelId}>{cultureLabelTxt}</InputLabel>
                    <Select
                      labelId={cultureLabelId}
                      label={cultureLabelTxt}
                      value={culture}
                      onChange={(e) => setCulture(() => e.target.value as HolidayCulture)}
                    >
                      {HolidayCultureOptions.map((item) => (
                        <MenuItem key={item} value={item}>
                          {t(item)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <InputLabel id={calendarLabelId}>{calendarLabelTxt}</InputLabel>
                  <Select
                    labelId={calendarLabelId}
                    label={calendarLabelTxt}
                    value={year}
                    onChange={(e) => setYear(() => +e.target.value)}
                  >
                    {[...Array(6).keys()].map((i) => {
                      const option = startYear + i;
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={globalStyles.datagridContainer}>
                <DataGrid
                  rows={data?.holidays?.items || []}
                  rowCount={data?.holidays?.totalCount || 0}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize,
                        page: pageNumber - 1
                      }
                    }
                  }}
                  checkboxSelection={false}
                  pageSizeOptions={PageSizeOptions}
                  loading={loading}
                  onPaginationModelChange={(page) => {
                    setPageSize(() => page.pageSize);
                    setPageNumber(() => page.page);
                  }}
                  disableColumnFilter
                  disableColumnMenu
                  paginationMode="server"
                  slots={{
                    footer: customFooter,
                    noRowsOverlay: GridNoResultsOverlay
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
