import { Box, Button, Grid } from '@mui/material';
import { PortSelector } from 'components';
import { useTranslation } from 'react-i18next';
import capitalizeEveryWord from '../../../../../shared/stringUtils/capitalizeEveryWord';
import {
  TXT_APPLY,
  TXT_DESTINATION_PORT,
  TXT_DURATION,
  TXT_FINISHED_AT,
  TXT_LOG,
  TXT_SAVED,
  TXT_SOURCE_PORTS,
  TXT_STARTED_AT,
  TXT_SUBMITTING,
  TXT_TEST
} from '../../../../../shared/translations';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Controller, useForm } from 'react-hook-form';
import MergePortsRequestDto from 'model/mergePorts/mergePortsRequestDto';
import { portModel } from 'model';
import useMergePortApi from 'api/dataMaintenance/useMergePortApi';
import { useState } from 'react';

const MergePorts = () => {
  const { t } = useTranslation();
  const { mergePorts } = useMergePortApi();

  const [log, setLog] = useState('');

  const { control, setValue, handleSubmit } = useForm<MergePortsRequestDto>();

  const onSourcePortsChanged = (ports: portModel[] | null) => {
    setValue(
      'sourcePortIds',
      (ports || []).map((x) => x.id!)
    );
  };

  const onDestinationChanged = (port: portModel | null) => {
    setValue('targetPortId', port?.id || '');
  };

  const onApply = (dryRun: boolean) => async (data: MergePortsRequestDto) => {
    const request: MergePortsRequestDto = {
      ...data,
      dryRun
    };

    setLog(capitalizeEveryWord(t(TXT_SUBMITTING))!);

    const start = new Date();
    setLog((prev) => `${prev}\r\n${capitalizeEveryWord(t(TXT_STARTED_AT))}: ${start}`);

    const response = await mergePorts(request);

    const finish = new Date();

    setLog((prev) => `${prev}\r\n${JSON.stringify(response, null, 2)}`);
    if (response && !dryRun) {
      setLog((prev) => `${prev}\r\n${capitalizeEveryWord(t(TXT_SAVED))}`);
    }
    setLog((prev) => `${prev}\r\n${capitalizeEveryWord(t(TXT_FINISHED_AT))}: ${finish}`);
    setLog((prev) => `${prev}\r\n${capitalizeEveryWord(t(TXT_DURATION))}: ${finish.getTime() - start.getTime()} milliseconds`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          component="div"
          display="grid"
          columnGap={1}
          gridTemplateColumns="2fr .1fr 2fr .5fr .5fr"
          alignItems="center"
        >
          <Controller
            control={control}
            name="sourcePortIds"
            rules={{
              minLength: 1,
              required: true
            }}
            render={({ fieldState }) => (
              <PortSelector
                label={capitalizeEveryWord(t(TXT_SOURCE_PORTS))!}
                multiple
                onSelectedPortsChange={onSourcePortsChanged}
                error={!!fieldState.error}
              />
            )}
          />
          <ArrowRightAltIcon />
          <Controller
            control={control}
            name="targetPortId"
            rules={{
              required: true,
              minLength: 1
            }}
            render={({ fieldState }) => (
              <PortSelector
                label={capitalizeEveryWord(t(TXT_DESTINATION_PORT))!}
                onSelectedChange={onDestinationChanged}
                error={!!fieldState.error}
              />
            )}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit(onApply(true))}>
            {t(TXT_TEST)}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSubmit(onApply(false))}>
            {t(TXT_APPLY)}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box component="fieldset" border={1} height="calc(100vh - 300px)" overflow="auto">
          <legend>{capitalizeEveryWord(t(TXT_LOG))}</legend>
          <Box component="pre" fontFamily="inherit">
            {log}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MergePorts;
