import { ApolloError } from '@apollo/client';
import { Chip, CircularProgress, IconButton, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetCounterPartiesOutput } from '../../../api';
import { CustomError } from '../../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../../shared/translations';
import { useGlobalStyles } from '../../globalStyles';
import { CounterPartyColumn } from './counterPartyColumns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { counterPartyModel } from '../../../model';

export interface CounterPartyTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetCounterPartiesOutput;
  columns: CounterPartyColumn[];
  onEdit: (counterParty: counterPartyModel) => void;
  onDelete: (counterParty: counterPartyModel) => void;
  showActions?: boolean;
}

export const CounterPartyTableBody = ({
  columns,
  loading,
  error,
  data,
  onEdit,
  onDelete,
  showActions
}: CounterPartyTableBodyProps) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const theme = useTheme();

  return (
    <TableBody>
      {loading && (
        <TableRow>
          <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
            <CircularProgress />
            <h3>{t(TXT_LOADING).toUpperCase()}</h3>
          </TableCell>
        </TableRow>
      )}
      {!loading && error && error.message && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <CustomError>{error.message}</CustomError>
          </TableCell>
        </TableRow>
      )}
      {!loading &&
        data &&
        data.counterParties &&
        data.counterParties.items &&
        data.counterParties.items.length > 0 &&
        data.counterParties.items.map((counterParty) => (
          <TableRow key={`row-${counterParty.id}`}>
            <TableCell align="left">{counterParty.name}</TableCell>
            <TableCell align="left">{counterParty.reference}</TableCell>
            <TableCell>
              {(counterParty.counterPartyTypes || []).map((counterPartyType) => (
                <Chip
                  key={`${counterParty.id}-${counterPartyType.id}`}
                  label={t(
                    counterPartyType.name!.toLowerCase() === 'client' ? 'receiver' : counterPartyType.name!
                  ).toUpperCase()}
                  style={{ marginLeft: theme.spacing(1) }}
                />
              ))}
            </TableCell>
            {!!showActions && (
              <TableCell align="center">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit counterParty"
                  component="span"
                  onClick={() => onEdit(counterParty)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            )}
            {!!showActions && (
              <TableCell align="center">
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete counterParty"
                  component="span"
                  onClick={() => onDelete(counterParty)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
      {!loading &&
        (!data || !data.counterParties || !data.counterParties.items || data.counterParties.items.length <= 0) && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
