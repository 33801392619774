import { Table, TableContainer, TableProps } from '@mui/material';
import { ReactNode } from 'react';
import { useCustomTableStyles } from './customTableStyles';

export interface CustomTableProps extends TableProps {
  children?: ReactNode
}

export const CustomTable = ({ children, ...rest }: CustomTableProps) => {
  const classes = useCustomTableStyles();
  return (
    <TableContainer
      className={classes.container}
    >
      <Table
        {...rest}
      >
        {children}
      </Table>
    </TableContainer>
  );
};
