import { useLazyQuery, useMutation } from '@apollo/client';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import {
  Alert,
  AppBar,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  GET_INTERUPTION_DEFINITIONS,
  GetInterruptionDefinitionsOutput,
  UPDATE_INTERRUPTIONS,
  UpdateInterruptionInput,
  UpdateInterruptionsOutput
} from 'api';
import { CustomTable, NumberTextField } from 'components';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import _ from 'lodash';
import { interruptionDefinitionModel, interruptionModel, laytimeCalculationModel } from 'model';
import moment, { Moment } from 'moment';
import { useDemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import { getLastestDate, getPortLastestDate } from 'pages/DemurragePage/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import { ignoreTimeZone, updateDatePart, updateTimePart } from 'shared/utils';
import shortUUID from 'short-uuid';
import './interruptionEditor.css';
import {
  TXT_INTERRUPTIONS,
  TXT_INTERRUPTION_HAS_DATES_IN_FUTURE,
  TXT_INTERRUPTION_TOO_BIG,
  TXT_DESCRIPTION,
  TXT_SINCE,
  TXT_TO,
  TXT_TO_DISCOUNT,
  TXT_DELETE,
  TXT_DATE,
  TXT_TIME,
  TXT_SAVING,
  TXT_ADD_INTERRUPTION,
  TXT_CANCEL,
  TXT_SAVE,
  TXT_SAVE_AND_CLOSE
} from '../../../../../../../../shared/translations';
import useAuth from 'hooks/useAuth';

export interface InterruptionEditorProps {
  onClose: () => void;
  afterSave: (data: UpdateInterruptionsOutput) => void;
  laytimeCalculation?: laytimeCalculationModel;
  interruptions: interruptionModel[];
  onLoadInterruption: () => void;
}

export interface InterruptionError {
  id?: string;
  hasErrors?: boolean;
  warningOnly?: boolean;
  since?: boolean;
  to?: boolean;
  description?: boolean;
  percentage?: boolean;
  errorMessage?: string;
}

const NEW_ID = '00000000-0000-0000-0000-000000000000';
const VISIBLE_OPTIONS_COUNT = 50;

export const InterruptionEditor = (props: InterruptionEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const rawInterruptions = props.interruptions.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id!]: curr
    }),
    {} as Record<string, Partial<interruptionModel>>
  );
  const { selectedLaytimeCalculation, selectedPort } = useDemurragePageState();
  const [valid, setValid] = useState<boolean>(false);
  const [latestDate, setLatestDate] = useState<Date | undefined>(
    getPortLastestDate(selectedLaytimeCalculation, selectedPort)
  );
  const [options, setOptions] = useState<interruptionDefinitionModel[]>([]);
  const [mutateInterruptions, { loading, error }] = useMutation<UpdateInterruptionsOutput>(UPDATE_INTERRUPTIONS, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data);
    }
  });
  const [errors, setErrors] = useState<InterruptionError[]>([]);
  const [hideRemarks, setHideRemarks] = useState<boolean | undefined>(selectedLaytimeCalculation?.hideRemarks);
  const [interruptions, setInterruptions] = useState<Record<string, Partial<interruptionModel>>>(rawInterruptions);
  const [interruptionsCount, setInterruptionsCount] = useState<number>(0);

  const [auth] = useAuth();
  const { isGuest } = auth;

  const [getInterruptionDefinitions, { data: interruptionDefinitionData, refetch }] =
    useLazyQuery<GetInterruptionDefinitionsOutput>(GET_INTERUPTION_DEFINITIONS, {
      fetchPolicy: 'network-only',
      variables: {
        take: VISIBLE_OPTIONS_COUNT,
        skip: 0
      },
      onError: apolloErrorHandler
    });

  const fetch = () => {
    if (refetch) {
      refetch();
    } else {
      getInterruptionDefinitions();
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const allInts = Object.values(interruptions);

    if (allInts && allInts.length > 0) {
      const itemId = allInts[allInts.length - 1]?.id ?? '';
      const element = document.getElementById('description-' + itemId);
      element?.focus();
    }
  }, [interruptionsCount]);

  useEffect(() => {
    if ((interruptionDefinitionData?.interruptionDefinitions?.items?.length || 0) > 0) {
      let interruptionDefinitionOptions: interruptionDefinitionModel[] =
        interruptionDefinitionData?.interruptionDefinitions?.items || [];
      const totalCount = interruptionDefinitionData?.interruptionDefinitions?.totalCount || 0;
      if (totalCount > VISIBLE_OPTIONS_COUNT) {
        interruptionDefinitionOptions = [
          ...interruptionDefinitionOptions,
          {
            id: 'interruptionDefinition-hidden-options-remaining',
            code: '000',
            description: `And ${totalCount - VISIBLE_OPTIONS_COUNT} more`
          }
        ];
      }

      setOptions(_.uniqBy(interruptionDefinitionOptions, 'id'));
    }
  }, [interruptionDefinitionData]);

  const title = t(TXT_INTERRUPTIONS);

  const handleInterruptionDelete = (id?: string) => () => {
    if (id) {
      setInterruptions((s) => {
        const newS = { ...s };
        delete newS[id];
        return newS;
      });
    }
  };

  const handleSinceChange = (id?: string) => (date: Moment | null) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const since = s[id].since ? moment(s[id].since).toDate() : undefined;
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;
        let toHasValue = s[id].to ? true : false;
        const newValue = updateDatePart(since, date?.toDate() || undefined);
        if (toHasValue && to && newValue) {
          const sinceNumber = newValue.valueOf();
          const toNumber = to.valueOf();
          toHasValue = sinceNumber > toNumber ? false : true;
        }
        return {
          ...s,
          [id]: { ...s[id], since: newValue, to: !toHasValue ? newValue : s[id].to }
        };
      });
    }
  };

  const handleToChange = (id?: string) => (date: Moment | null) => {
    if (id && date?.isValid()) {
      setInterruptions((s) => {
        const to = s[id].to ? moment(s[id].to).toDate() : undefined;

        return {
          ...s,
          [id]: { ...s[id], to: updateDatePart(to, date?.toDate() || undefined) }
        };
      });
    }
  };

  const handleDescriptionChange = (id?: string, value?: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], description: value }
      }));
    }
  };

  const handlePercentageChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], percentage: event.target.value ? parseFloat(event.target.value) : 0 }
      }));
    }
  };

  const handleToDiscountChange = (id?: string) => (event: any) => {
    if (id) {
      setInterruptions((s) => ({
        ...s,
        [id]: { ...s[id], toDiscount: event.target.checked }
      }));
    }
  };

  const handleSave = (closeForm: boolean) => {
    const input = getInput();

    return mutateInterruptions({
      variables: {
        laytimeCalculationId: selectedLaytimeCalculation?.id,
        hideRemarks: hideRemarks,
        input
      }
    }).finally(() => {
      if (closeForm) {
        props.onClose();
      }
    });
  };

  const handleAddInterruption = () => {
    const allInts = Object.values(interruptions);
    const lastInt = allInts.length > 0 ? allInts[allInts.length - 1] : undefined;
    setInterruptions((s) => {
      const id = 'new-' + shortUUID.generate();
      return {
        ...s,
        [id]: {
          id,
          laytimeCalculationId: selectedLaytimeCalculation?.id,
          toDiscount: false,
          percentage: 100,
          since: lastInt?.to,
          to: lastInt?.to
        }
      };
    });

    setInterruptionsCount(allInts.length - 1);
  };

  useEffect(() => {
    Object.values(interruptions).map((interruption) => {
      if (interruption) {
        setLatestDate(getLastestDate(latestDate, interruption.since ? interruption.since : undefined));
      }
    });
    setValid(validateInterruptions());
  }, [interruptions]);

  const validateInterruptions = (): boolean => {
    let isValidForm = true;
    setErrors([]);
    const input = getInput(false);
    const newErrors = input
      .map((x) => {
        const rowErrors: InterruptionError = {
          id: x.id,
          hasErrors: false
        };

        if (!x.description) {
          isValidForm = false;
          rowErrors.description = true;
          rowErrors.hasErrors = true;
        }

        if (!(x.percentage !== undefined)) {
          isValidForm = false;
          rowErrors.percentage = true;
          rowErrors.hasErrors = true;
        }

        if (!x.since) {
          isValidForm = false;
          rowErrors.since = true;
          rowErrors.hasErrors = true;
        }

        if (!x.to) {
          isValidForm = false;
          rowErrors.to = true;
          rowErrors.hasErrors = true;
        }

        if (x.to && x.since) {
          const since = x.since.getTime();
          const to = x.to.getTime();
          const now = Date.now();
          const differenceInTime = to - since;

          // To calculate the no. of days between two dates
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          if (since > now || to > now) {
            isValidForm = false;
            rowErrors.errorMessage = _.capitalize(TXT_INTERRUPTION_HAS_DATES_IN_FUTURE);
            rowErrors.hasErrors = true;
          } else if (differenceInDays >= 2) {
            // more than 48 hours
            rowErrors.errorMessage = _.capitalize(TXT_INTERRUPTION_TOO_BIG);
            rowErrors.hasErrors = true;
            rowErrors.warningOnly = true;
          }
        }

        return rowErrors;
      })
      .filter((x) => x.hasErrors);

    setErrors(newErrors);
    return input && isValidForm;
  };

  const getInput = (replaceIds: boolean = true): UpdateInterruptionInput[] => {
    return Object.values(interruptions).map(
      (x) =>
        ({
          id: replaceIds && x.id?.includes('new-') ? NEW_ID : x.id,
          laytimeCalculationId: x.laytimeCalculationId,
          description: x.description,
          since: ignoreTimeZone(x.since),
          to: ignoreTimeZone(x.to),
          percentage: x.percentage,
          toDiscount: x.toDiscount
        } as UpdateInterruptionInput)
    );
  };

  const handleInputChange = (e: any, data: any, id?: string) => {
    handleDescriptionChange(id, data);
    return data;
  };

  const interruptionDescFilterOption = createFilterOptions<string | undefined>({
    matchFrom: 'start',
    stringify: (option) => option || '',
    ignoreCase: true
  });

  return (
    <Dialog open={true} maxWidth="lg" fullWidth={true}>
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <CustomTable stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={400} rowSpan={2}>
                {t(TXT_DESCRIPTION).toUpperCase()}
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {t(TXT_SINCE).toUpperCase()}
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {t(TXT_TO).toUpperCase()}
              </TableCell>
              <TableCell align="center" width={150} rowSpan={2}>
                %
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                {t(TXT_TO_DISCOUNT).toUpperCase()}
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                {t(TXT_DELETE).toUpperCase()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ top: 57 }} align="center" width={250}>
                {t(TXT_DATE).toUpperCase()}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center">
                {t(TXT_TIME).toUpperCase()}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center" width={250}>
                {t(TXT_DATE).toUpperCase()}
              </TableCell>
              <TableCell style={{ top: 57 }} align="center">
                {t(TXT_TIME).toUpperCase()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(interruptions).map((interruption) => {
              const rowErrors: InterruptionError =
                errors.find((x: InterruptionError) => x.id === interruption.id) ?? {};
              const sinceTime = interruption?.since
                ? moment(interruption.since).hours() + ':' + moment(interruption.since).minutes()
                : '';
              const toTime = interruption?.to
                ? moment(interruption.to).hours() + ':' + moment(interruption.to).minutes()
                : '';
              return (
                <TableRow key={interruption.id}>
                  <TableCell>
                    <Autocomplete
                      id={'description-' + interruption.id}
                      disabled={loading || isGuest}
                      freeSolo
                      autoSelect
                      value={interruption.description || null}
                      onInputChange={(e, data, id) => handleInputChange(e, data, interruption.id)}
                      options={options.map((option: interruptionDefinitionModel | undefined) => option?.description)}
                      filterOptions={interruptionDescFilterOption}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth margin="normal" error={!!rowErrors.description} />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                          error: !!rowErrors.since,
                          autoComplete: 'off'
                        }
                      }}
                      value={!interruption.since ? null : moment(interruption.since)}
                      onChange={handleSinceChange(interruption.id)}
                      referenceDate={latestDate ? moment(latestDate) : moment()}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      className="time-picker"
                      value={sinceTime}
                      disableClock
                      format="HH:mm"
                      clearIcon={null}
                      hourPlaceholder="00"
                      minutePlaceholder="00"
                      onChange={(value) => {
                        const id = interruption.id;
                        if (id) {
                          setInterruptions((s) => {
                            const since = s[id].since ? moment(s[id].since).toDate() : undefined;
                            return {
                              ...s,
                              [id]: { ...s[id], since: updateTimePart(since || undefined, value as string) }
                            };
                          });
                        }
                      }}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                          error: !!rowErrors.to,
                          autoComplete: 'off'
                        }
                      }}
                      value={!interruption.to ? null : moment(interruption.to)}
                      onChange={handleToChange(interruption.id)}
                      referenceDate={latestDate ? moment(latestDate) : moment()}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      className="time-picker"
                      value={toTime}
                      disableClock
                      format="HH:mm"
                      clearIcon={null}
                      hourPlaceholder="00"
                      minutePlaceholder="00"
                      onChange={(value) => {
                        const id = interruption.id;
                        if (id) {
                          setInterruptions((s) => {
                            const to = s[id].to ? moment(s[id].to).toDate() : undefined;
                            return {
                              ...s,
                              [id]: { ...s[id], to: updateTimePart(to || undefined, value as string) }
                            };
                          });
                        }
                      }}
                      disabled={loading || isGuest}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberTextField
                      margin="normal"
                      id="percentage"
                      fullWidth
                      disabled={loading || isGuest}
                      error={!!rowErrors.percentage}
                      value={interruption.percentage}
                      onChange={handlePercentageChange(interruption.id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      disabled={loading || isGuest}
                      checked={!!interruption.toDiscount}
                      onChange={handleToDiscountChange(interruption.id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="save interruptions"
                        component="span"
                        size="large"
                        sx={{ width: '40px', height: '40px' }}
                        onClick={handleInterruptionDelete(interruption.id)}
                        disabled={loading || isGuest}
                      >
                        <Delete />
                      </IconButton>
                      {rowErrors.errorMessage && (
                        <Tooltip title={rowErrors.errorMessage}>
                          <Alert severity={rowErrors.warningOnly ? 'warning' : 'error'}></Alert>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </CustomTable>
      </DialogContent>
      <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
        {loading && (
          <div>
            <Typography>
              <CircularProgress style={{ marginRight: '5px' }} size="20px" />
              {t(TXT_SAVING)}...
            </Typography>
          </div>
        )}
        {error?.message && (
          <div>
            <Typography color={'red'} sx={{ width: '100%' }}>
              {error?.message}
            </Typography>
          </div>
        )}
        &nbsp;
      </div>
      <DialogActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Button onClick={handleAddInterruption} color="inherit" disabled={loading || isGuest}>
            <Add />
            {t(TXT_ADD_INTERRUPTION)}
          </Button>
        </div>
        <div>
          Hide Remarks after vessel on demurrage
          <Checkbox
            defaultChecked={hideRemarks}
            onChange={(x) => {
              setHideRemarks(x.target.checked);
            }}
            disabled={loading || isGuest}
          ></Checkbox>
        </div>
        <div>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button
            form="interruption-editor-form"
            onClick={() => handleSave(false)}
            disabled={!valid || !!error?.message || loading || isGuest}
          >
            {t(TXT_SAVE)}
          </Button>
          <Button
            form="interruption-editor-form"
            onClick={() => handleSave(true)}
            disabled={!valid || !!error?.message || loading || isGuest}
          >
            {t(TXT_SAVE_AND_CLOSE)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
