import { Backdrop, Typography, CircularProgress, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_LOADING } from '../../../../../shared/translations';
import { useAuthService } from '../../hooks/useAuthServices';
import { signInPath } from '../../shared/links';

export const PrivateRoute = () => {
  const [auth] = useAuth();
  const { isSignedIn } = useAuthService();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    isSignedIn()
      .then((result) => setHasValidToken(result.isSignedIn))
      .finally(() => setIsLoading(false));
  }, [auth.accessToken]);

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
          open={isLoading}
        >
          <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
            {capitalizeFirstLetterEveryword(t(TXT_LOADING))}
          </Typography>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoading && hasValidToken && <Outlet />}
      {!isLoading && !hasValidToken && (
        <Navigate
          to={{
            pathname: signInPath
          }}
          state={{
            redirectTo: location.pathname
          }}
        />
      )}
    </>
  );
};
