import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { grey } from '@mui/material/colors';

export const useCustomCardContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      'maxHeight': '66vh',
      'overflow': 'auto',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey[600]
      },
      '& > div': {
        marginBottom: '10px'
      }
    }
  })
);
