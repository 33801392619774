import { useNavigate } from 'react-router-dom';
import { dashboardPath } from 'shared/links';
import useAuth from './useAuth';

export const useAdminGuard = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();

  if (!auth.isAdmin) {
    navigate(dashboardPath);
  }
};
