import { gql } from '@apollo/client';
import { splitQuantityModel } from 'model';
import { basePagingResult } from '../base';

export interface GetSplitQuantitiesSelectorOutput {
  splitQuantities: basePagingResult<splitQuantityModel>;
}

export const GET_SPLIT_QUANTITIES_SELECTOR = gql`
  query getSplitQuantities($where: SplitQuantityFilterInput) {
    splitQuantities(where: $where) {
      items {
        id
        name
        deal {
          id
          name
        }
      }
    }
  }
`;
