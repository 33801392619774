import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetPortsOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { PortsColumn } from './portsColumns';
import { portModel } from 'model';

export interface PortsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetPortsOutput;
  columns: PortsColumn[];
  onEdit: (port: portModel) => void;
  onDelete: (port: portModel) => void;
  showActions?: boolean;
}

export const PortsTableBody =
  // eslint-disable-next-line max-len
  ({ columns, loading, error, data, onEdit, onDelete, showActions }: PortsTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.ports &&
          data.ports.items &&
          data.ports.items.length > 0 &&
          data.ports.items.map((port) => (
            <TableRow key={`row-${port.id}`}>
              <TableCell align="left">{port.name}</TableCell>
              <TableCell align="left">{port.country}</TableCell>
              <TableCell align="center">{port.latitude}</TableCell>
              <TableCell align="center">{port.longitude}</TableCell>
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.editButton}
                    aria-label="edit port"
                    component="span"
                    onClick={() => onEdit(port)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              )}
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.deleteButton}
                    aria-label="delete port"
                    component="span"
                    onClick={() => onDelete(port)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        {!loading && (!data || !data.ports || !data.ports.items || data.ports.items.length <= 0) && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
