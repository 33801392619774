import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAuthService } from '../../hooks/useAuthServices';
import { defaultPageState, pageState } from 'state/pageState';
import { dealPath } from '../../shared/links';
import { SignInPage } from './SignInPage';
import useAuth from 'hooks/useAuth';
import { Backdrop, Typography, CircularProgress, useTheme } from '@mui/material';
import { t } from 'i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_LOADING } from '../../../../../shared/translations';

export const SignIn = () => {
  const [auth] = useAuth();
  const { state } = useLocation();
  const { isSignedIn } = useAuthService();
  const [, setPage] = useRecoilState(pageState);
  const [isLoading, setIsLoading] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    isSignedIn()
      .then((result) => setHasValidToken(result.isSignedIn))
      .finally(() => setIsLoading(false));
  }, [auth.accessToken]);

  useEffect(() => setPage(defaultPageState), []);

  let targetPath = dealPath;
  if (!!state?.redirectTo) {
    targetPath = state.redirectTo;
  }

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
          open={isLoading}
        >
          <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
            {capitalizeFirstLetterEveryword(t(TXT_LOADING))}
          </Typography>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoading && !hasValidToken && <SignInPage />}
      {!isLoading && hasValidToken && <Navigate to={targetPath} />}
    </>
  );
};
