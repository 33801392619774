import { ApolloError } from '@apollo/client';
import { TableContainer, TableProps } from '@mui/material';
import { portModel } from 'model';
import { GetPortsOutput } from '../../api';
import { CustomTable } from '../../components';
import { portsColumns } from './portsColumns';
import { PortsTableBody } from './PortsTableBody';
import { PortsTableHead } from './PortsTableHead';

interface PortsTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetPortsOutput;
  onEdit: (deal: portModel) => void;
  onDelete: (deal: portModel) => void;
  showActions?: boolean;
}

export const PortsTable = ({ error, loading, data, onEdit, onDelete, showActions, ...rest }: PortsTableProps) => {
  return (
    <TableContainer>
      <CustomTable {...rest} stickyHeader>
        <PortsTableHead columns={portsColumns} showActions={showActions} />
        <PortsTableBody
          columns={portsColumns}
          loading={loading}
          error={error}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          showActions={showActions}
        />
      </CustomTable>
    </TableContainer>
  );
};
