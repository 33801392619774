import {
  Dialog,
  AppBar,
  Toolbar,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import { CustomError, LaytimechartReportFilterContent } from 'components';
import { LaytimeChartReportRequest, useShareableLinkDownloader, useTitle } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { ERR_UNKNOWN_ERROR, TXT_PASSWORD, TXT_DOWNLOADING, TXT_DOWNLOAD } from '../../../../../shared/translations';
import { PublicProtectedLinkProps } from './PublicLink';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ignoreTimeZone } from 'shared/utils';

interface PublicLaytimeChartProps extends Omit<PublicProtectedLinkProps, 'shareableLinkType'> {}

const schema = yup.object().shape({
  linkId: yup.string().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  password: yup.string().required()
});

export const PublicLaytimeChart = (props: PublicLaytimeChartProps) => {
  useTitle(props.title);
  const { linkId } = useParams();
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const defaultStartDate = new Date(`Jan 1 $${currentYear}`);
  const defaultEndDate = new Date(`Dec 31 $${currentYear}`);
  const { register, handleSubmit, setValue, formState, control } = useForm<LaytimeChartReportRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      linkId,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      password: ''
    }
  });
  const [loading, setLoading] = useState(false);
  const [reqError, setReqError] = useState<string | undefined>();
  const { downloadLaytimeChart } = useShareableLinkDownloader();

  const downloadFile: SubmitHandler<LaytimeChartReportRequest> = async (params) => {
    if (!linkId) {
      return;
    }
    setLoading(true);
    setReqError(undefined);
    try {
      await downloadLaytimeChart({
        ...params,
        startDate: ignoreTimeZone(params.startDate === null ? undefined : params.startDate),
        endDate: ignoreTimeZone(params.endDate === null ? undefined : params.endDate)
      });
    } catch (error: any) {
      error?.response?.data
        ?.text()
        .then((text: string) => {
          setReqError(text);
        })
        .catch(() => {
          setReqError(ERR_UNKNOWN_ERROR);
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth disableEscapeKeyDown={true}>
      <AppBar position="relative">
        <Toolbar>{t(props.title).toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        {!!reqError && <CustomError>{capitalizeFirstLetterEveryword(t(reqError))}</CustomError>}
        <form id="frm-public-laytime-chart" onSubmit={handleSubmit(downloadFile)}>
          <LaytimechartReportFilterContent control={control} setValue={setValue} formState={formState} disabled={loading} publicMode={true}>
            <TextField
              label={capitalizeFirstLetterEveryword(t(TXT_PASSWORD))}
              fullWidth
              type="password"
              disabled={loading}
              {...register('password')}
              error={!!formState.errors.password}
            />
          </LaytimechartReportFilterContent>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} type="submit" form="frm-public-laytime-chart">
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_DOWNLOADING : TXT_DOWNLOAD)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
