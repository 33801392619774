import { TXT_DESCRIPTION, TXT_NOT_COUNT_AS_LAYTIME, TXT_SINCE, TXT_TO, TXT_TO_DISCOUNT } from '../../../../../../../../shared/translations';

export interface InterruptionColumn {
  id: 'description' | 'since' | 'to' | 'toDiscount' | 'notLaytime' | 'laytime' | 'percentage';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const interruptionColumns: InterruptionColumn[] = [
  {
    id: 'description',
    label: TXT_DESCRIPTION,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'since',
    label: TXT_SINCE,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'toDiscount',
    label: TXT_TO_DISCOUNT,
    maxWidth: '5%',
    align: 'left'
  },
  {
    id: 'notLaytime',
    label: TXT_NOT_COUNT_AS_LAYTIME,
    maxWidth: '5%',
    align: 'left'
  },
  {
    id: 'percentage',
    label: '%',
    maxWidth: '5%',
    align: 'right'
  }
];
