import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton, Link, TableCell, TableRow } from '@mui/material';
import { DealCalculations } from 'components';
import { dealModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { laycanCalculationPath } from 'shared/links';
import { getLoadingQuantity, getPorts, getProducts, getReceivers } from 'shared/utils';
import { QuoteTableContext } from './QuoteTable';

interface QuoteTableRowProps {
  deal: dealModel;
  defaultOpenDetail?: boolean;
}

export const QuoteTableRow = (props: QuoteTableRowProps) => {
  const { onEdit, onDelete, columns } = useContext(QuoteTableContext);
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();

  const [openDetail, setOpenDetail] = useState(!!props.defaultOpenDetail);

  const receivers = getReceivers(props.deal);
  const products = getProducts(props.deal);
  const unloadingPorts = getPorts(props.deal.unloadingPorts ?? []);
  const loadingPorts = getPorts(props.deal.loadingPorts ?? []);
  const loadingQuantity = getLoadingQuantity(props.deal.loadingPorts ?? []);

  const openCloseDetail = () => setOpenDetail((prev) => !prev);

  const onEditClicked = () => onEdit(props.deal);
  const onDeleteClicked = () => onDelete(props.deal);

  const onGoTo = () => navigate(`${laycanCalculationPath}/${props.deal.id}`);

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton size="small" onClick={openCloseDetail}>
            {!openDetail ? <ChevronRightIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Link
            color="inherit"
            onClick={onGoTo}
            style={{
              cursor: 'pointer'
            }}
          >
            {props.deal.name}
          </Link>
        </TableCell>
        <TableCell align="left">{receivers}</TableCell>
        <TableCell align="left">{props.deal.vessel?.name}</TableCell>
        <TableCell align="left">{products}</TableCell>
        <TableCell align="left">{loadingPorts}</TableCell>
        <TableCell align="left">{unloadingPorts}</TableCell>
        <TableCell align="left">{`${loadingQuantity} ${props.deal.massUnit}`}</TableCell>
        {columns.some((x) => x.id === 'goTo') ? (
          <TableCell align="center">
            <IconButton aria-label="go to" component="span" onClick={onGoTo} size="small">
              <LaunchIcon />
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell align="center">
          <IconButton
            className={globalStyles.editButton}
            aria-label="edit quote"
            component="span"
            onClick={onEditClicked}
            size="small"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="delete quote"
            component="span"
            onClick={onDeleteClicked}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {openDetail && props.deal.id ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1}>
            <DealCalculations {...props.deal} showVersion={!!props.deal.isQuote} />
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
