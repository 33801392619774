import {
  CircularProgress,
  IconButton,
  Link,
  Switch,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../components/CustomError';
import {
  TXT_LOADING,
  TXT_NO_DATA_FOUND
} from '../../../../../../shared/translations';
import { useGlobalStyles } from '../../globalStyles';
import { UserColumn } from './userColumns';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { UserUIProps } from '../UserUIProps';
import useAuth from '../../../hooks/useAuth';
import EditIcon from '@mui/icons-material/Edit';
import { getSecurityRolesText } from 'shared/utils';

export interface UserTableBodyProps extends UserUIProps {
  columns: UserColumn[];
}

export const UserTableBody =
  ({ columns, loading, error, data, onEdit, onDelete, onChangeEnablement, allowVesselPortal, allowOCR }: UserTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();
    const [auth] = useAuth();

    return (
      <TableBody>
        {
          loading &&
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </TableCell>
          </TableRow>
        }
        {
          !loading && error && error.message &&
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </TableCell>
          </TableRow>
        }
        {
          !loading &&
          data &&
          data.users &&
          data.users.items &&
          data.users.items.length > 0 &&
          data.users.items.map((user) => (
            <TableRow key={`row-${user.id}`}>
              <TableCell align='left'>
                <Link
                  href={`mailto:${user.username}`}
                  color='inherit'
                >
                  {user.username}
                </Link>
              </TableCell>
              <TableCell align='left'>
                {user.profile?.initial}
              </TableCell>
              <TableCell align='left'>
                {user.profile?.fullName}
              </TableCell>
              <TableCell align='center'>
                {getSecurityRolesText(user.securityRole)}
              </TableCell>
              <TableCell align='center'>
                <Switch
                  checked={user.allowVesselPortal}
                  onChange={(event, checked) =>
                    allowVesselPortal({
                      userId: user.id!,
                      allowVesselPortal: checked
                    })}
                />
              </TableCell>
              <TableCell align='center'>
                <Switch
                  checked={user.allowOCR}
                  onChange={(event, checked) =>
                    allowOCR({
                      userId: user.id!,
                      allowOCR: checked
                    })}
                />
              </TableCell>
              <TableCell align='center'>
                <Switch
                  checked={user.isEnabled}
                  onChange={(event, checked) => onChangeEnablement({
                    userId: user.id!,
                    isEnabled: checked
                  })}
                  // eslint-disable-next-line max-len
                  disabled={user.username?.toLowerCase() === auth.username?.toLowerCase()}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit trader"
                  component="span"
                  onClick={() => onEdit(user)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align='center'>
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete user"
                  component="span"
                  onClick={() => onDelete(user)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.users ||
            !data.users.items ||
            data.users.items.length <= 0) &&
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>
                {_.capitalize(t(TXT_NO_DATA_FOUND))}
              </CustomError>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  };
