import { Container, Grid } from '@mui/material';
import { SplitQuantitySelector } from 'components';
import { useTitle } from 'hooks';
import { splitQuantityModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { useState } from 'react';
import { TXT_DEAL_PROFIT_AND_LOSS } from '../../../../../shared/translations';
import { ProfitAndLossReport } from './ProfitAndLossReport';

export const ProfitAndLoss = () => {
  useTitle(TXT_DEAL_PROFIT_AND_LOSS);
  const globalStyles = useGlobalStyles();
  const [selected, setSelected] = useState<splitQuantityModel>();

  return (
    <Container component='main' className={globalStyles.container}>
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12}>
          <SplitQuantitySelector onChanged={(splitQuantity) => setSelected(splitQuantity)} />
        </Grid>
        <Grid item xs={12}>
          <ProfitAndLossReport splitQuantity={selected} />
        </Grid>
      </Grid>
    </Container>
  );
};
