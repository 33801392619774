import { gql } from '@apollo/client';

export interface AddIntegrationInput {
  name: string;
  key: string;
}

export interface DeleteIntegrationInput {
  id: string;
}

export const ADD_INTEGRATION = gql`
  mutation addIntegration($input: AddIntegrationInput!) {
    addIntegration(input: $input) {
      id
      name
      key
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($input: DeleteIntegrationInput!) {
    deleteIntegration(input: $input)
  }
`;
