import { IconButton, Link } from '@mui/material';
import { contactPhoneModel, maritimeDirectoryModel } from 'model';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useGlobalStyles } from 'pages/globalStyles';
import { MaritimeDirectoryPhonesEditor } from './MaritimeDirectoryPhonesEditor';
import { MaritimeDirectoryContactDetailRow } from '../MaritimeDirectoryContactDetailRow';

interface MaritimeDirectoryPhonesColumnProps {
  data: maritimeDirectoryModel;
  showActions?: boolean;
}

export const MaritimeDirectoryPhonesColumn = (props: MaritimeDirectoryPhonesColumnProps) => {
  const [maxNumberOfRows] = useState(5);
  const [isHover, setIsHover] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const globalStyles = useGlobalStyles();

  const openCloseEditor = () => setShowEditor((prev) => !prev);
  const data = props.data.contact?.phones || [];

  const sanitizeCollection = (source: contactPhoneModel[]): contactPhoneModel[] => {
    if (showMore) {
      return source;
    }

    const newCollection = source.slice(0, maxNumberOfRows);
    for (let i = 0; i < maxNumberOfRows - source.length; i++) {
      newCollection.push({
        contactId: undefined,
        id: 'phone-undefined-' + i,
        name: '',
        phone: ''
      } as contactPhoneModel);
    }
    return newCollection;
  };

  return (
    <div
      onMouseOver={() => setIsHover(() => true)}
      onMouseOut={() => setIsHover(() => false)}
      style={{
        width: '100%',
        position: 'relative',
        height: '100%'
      }}
    >
      {showEditor ? (
        <MaritimeDirectoryPhonesEditor
          contactId={props.data.contactId!}
          phones={props.data.contact?.phones || []}
          onClose={openCloseEditor}
        />
      ) : null}
      {props.showActions && (
        <IconButton
          className={globalStyles.editButton}
          aria-label="update address"
          component="span"
          sx={{
            visibility: isHover ? 'visible' : 'hidden',
            position: 'absolute',
            top: 0,
            right: 0
          }}
          size="small"
          onClick={openCloseEditor}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      )}
      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          {sanitizeCollection(data).map((x, index) => (
            <MaritimeDirectoryContactDetailRow
              key={`phone-${x.id}`}
              index={index}
              totalRows={data.length}
              name={x.name}
              content={<Link href={`tel:${x.phone}`}>{x.phone}</Link>}
              toBeCopied={x.phone}
              onShowMore={() => setShowMore(true)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
