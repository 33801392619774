import { IconButton, List, ListItemButton, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingUndloadingTab } from './LoadingUnloadingTab';
import {
  getPropName,
  setSelectedLaytimeCalculationId,
  useDemurragePageDispatch,
  useDemurragePageState
} from '../DemurragePageContext';
import { InfoCard } from './InfoCard';

import { AddButton } from './AddButton';
import { getCalculationsOrdered } from '../utils';
import EditIcon from '@mui/icons-material/Edit';
import { useGlobalStyles } from 'pages/globalStyles';
import { useState } from 'react';
import { CommentButton, DealPortRequestForm, DealWizard } from 'components';
import { BatchOverrideEditor } from 'components/BatchOverrideEditor';
import { t } from 'i18next';
import {
  TXT_BACK_TO_PARENT,
  TXT_EDIT_CURRENT_DEAL,
  TXT_OVERRIDE_ALL_CALCULATIONS,
  TXT_QUICK_CALC,
  TXT_SEND_REQUEST_FORM_TO_AGENT
} from '../../../../../../shared/translations';
import { QuoteWizard } from 'components/QuoteWizard';
import BoltIcon from '@mui/icons-material/Bolt';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import CalculateIcon from '@mui/icons-material/Calculate';
import {
  ADD_DEAL_COMMENT,
  DEAL_COMMENT_ADDED,
  DEAL_COMMENT_DELETED,
  DEAL_COMMENT_UPDATED,
  DELETE_DEAL_COMMENT,
  GET_DEAL_COMMENTS,
  GET_DEAL_COMMENT_COUNT,
  UPDATE_DEAL_COMMENT
} from 'api';
import { dealCommentModel } from 'model';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { laycanCalculationPath } from 'shared/links';
import ShareIcon from '@mui/icons-material/Share';
import useAuth from 'hooks/useAuth';

export const ListComponent = () => {
  const { selectedTab, selectedLaytimeCalculation, demurrageDeal: deal, fetch } = useDemurragePageState();
  const [openEditor, setOpenEditor] = useState(false);
  const globalStyles = useGlobalStyles();
  const [openOverrideEditor, setOpenOverrideEditor] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [showRequestForm, setShowRequestForm] = useState<boolean>(false);
  const calcId = searchParams.get('calcId');
  const commentId = searchParams.get('commentId');
  const navigate = useNavigate();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const dispatch = useDemurragePageDispatch();

  const handleSelectedChange = (id: string) => {
    dispatch(setSelectedLaytimeCalculationId(id));
  };

  if (!deal) {
    return null;
  }

  let dealName: string = deal?.name ?? '';

  if (deal.isQuote) {
    dealName = `${deal.vessel?.name ?? ''} [${dealName}]`;
  }

  const requestFormToggle = () => {
    setShowRequestForm((prev) => !prev);
  };

  return (
    <>
      {showRequestForm && selectedLaytimeCalculation?.dealPortId && deal?.id ? (
        <DealPortRequestForm
          dealId={deal.id!}
          onCancel={requestFormToggle}
          dealPortId={selectedLaytimeCalculation?.dealPortId}
          isLoadingPort={selectedTab === 'loading'}
        />
      ) : null}
      {openOverrideEditor && deal && (
        <BatchOverrideEditor deal={deal} onClose={() => setOpenOverrideEditor(false)} afterSave={fetch} />
      )}
      <List
        sx={(theme) => ({
          borderRight: 1,
          borderColor: 'divider',
          py: theme.spacing(1),
          minWidth: '300px',
          maxWidth: '300px',
          overflow: 'auto'
        })}
      >
        <Stack direction="column" alignItems="center" spacing={2} sx={{ paddingRight: '10px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              width: '100%'
            }}
          >
            {!deal.parentDealId ? (
              <div></div>
            ) : (
              <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_BACK_TO_PARENT))} placement="top">
                <IconButton
                  aria-label="back to parent"
                  component="span"
                  onClick={() => navigate(`${laycanCalculationPath}/${deal.parentDealId}`)}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            )}
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{
                width: '100%'
              }}
              alignItems="center"
            >
              <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_EDIT_CURRENT_DEAL))} placement="top">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit vessel"
                  component="span"
                  onClick={() => {
                    setOpenEditor(() => true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {deal && !deal.isQuote && !isGuest ? (
                <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_OVERRIDE_ALL_CALCULATIONS))} placement="top">
                  <IconButton
                    aria-label="override calcs"
                    component="span"
                    onClick={() => {
                      setOpenOverrideEditor(() => true);
                    }}
                  >
                    <CalculateIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              {!isGuest && (
                <CommentButton<dealCommentModel>
                  pageTitle={dealName}
                  entityId={deal.id || ''}
                  getCountQuery={GET_DEAL_COMMENT_COUNT}
                  getCommentsQuery={GET_DEAL_COMMENTS}
                  addCommentMutation={ADD_DEAL_COMMENT}
                  updateCommentMutation={UPDATE_DEAL_COMMENT}
                  deleteCommentMutation={DELETE_DEAL_COMMENT}
                  commentAddedSubscription={DEAL_COMMENT_ADDED}
                  commentUpdatedSubscription={DEAL_COMMENT_UPDATED}
                  commentDeletedSubscription={DEAL_COMMENT_DELETED}
                  open={deal.id === calcId}
                  goToCommentId={commentId || undefined}
                />
              )}
              {!deal?.isQuote && !!auth.isAdmin && selectedLaytimeCalculation?.dealPortId ? (
                <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_SEND_REQUEST_FORM_TO_AGENT))}>
                  <IconButton aria-label="send-request-form" component="div" onClick={requestFormToggle} size="small">
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          </Stack>
          {deal && deal.isQuote && (
            <Stack direction="row" alignItems="center">
              <BoltIcon />
              <Typography>{`${capitalizeFirstLetterEveryword(t(TXT_QUICK_CALC))} `}</Typography>
            </Stack>
          )}
        </Stack>
        <LoadingUndloadingTab />
        {!isGuest && <AddButton />}
        <List>
          {deal &&
            getCalculationsOrdered(getPropName(selectedTab), deal).map((calc, index) => (
              <ListItemButton
                key={calc.id || index}
                disableRipple
                selected={selectedLaytimeCalculation?.id === calc.id}
                onClick={() => handleSelectedChange(calc.id!)}
                divider={true}
              >
                <InfoCard calculationId={calc.id!} />
              </ListItemButton>
            ))}
        </List>
        {openEditor && !deal.isQuote && <DealWizard {...deal} onClose={() => setOpenEditor(false)} afterSave={fetch} />}
        {openEditor && deal.isQuote && <QuoteWizard {...deal} onClose={() => setOpenEditor(false)} afterSave={fetch} />}
      </List>
    </>
  );
};
