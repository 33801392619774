import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const userMentionClassName = 'user-mention-style';

const paddingTopBottom = '8.5px';
const paddingLeftRight = '14px';
const padding = `${paddingTopBottom} ${paddingLeftRight}`;

export const useUserMentionStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [`& .${userMentionClassName}__control`]: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        border: '0px'
      },
      [`& .${userMentionClassName}__highlighter`]: {
        padding,
        '& strong': {
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.info.dark : theme.palette.info.light
        },
        'letterSpacing': 'inherit',
        'lineHeight': 1.21
      },
      [`& .${userMentionClassName}__input`]: {
        'color': theme.palette.text.primary,
        padding,
        'borderRadius': '4px',
        'border': `1px solid ${theme.palette.divider}`,
        'transition': theme.transitions.create('border-color', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeInOut
        }),
        '&:focus': {
          outline: 'none',
          border: `2px solid ${theme.palette.primary.main}`
        },
        '&:disabled': {
          color: theme.palette.text.primary,
          border: `1px dotted ${theme.palette.primary.main}`
        },
        '&:hover:not(:disabled):not(:focus)': {
          border: `1px solid ${theme.palette.text.primary}`
        }
      },
      [`& .${userMentionClassName}__suggestions`]: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        border: `1px solid ${theme.palette.action.disabledBackground}`,
        boxShadow: `0 0 8px ${theme.palette.action.disabled}`,
        width: 'max-content',
        maxHeight: 300,
        overflow: 'auto'
      },
      [`& .${userMentionClassName}__suggestions__item`]: {
        display: 'flex',
        alignItems: 'center',
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      },

      [`& .${userMentionClassName}__suggestions__item--focused`]: {
        backgroundColor: theme.palette.action.selected
      }
    }
  })
);
