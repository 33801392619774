import { gql } from '@apollo/client';
import { counterPartyModel } from '../../../model';

export const getCounterPartiesFragment = `
  items {
    id,
    name,
    reference,
    counterPartyTypes {
      id,
      name
    },
    businessUnitId,
    businessUnit {
      id,
      name
    }
  },
  totalCount
`;

export type GetCounterPartiesOutput = {
  counterParties?: {
    items?: [counterPartyModel],
    totalCount?: number;
  }
};

export const GET_COUNTER_PARTIES = gql`
  query getCounterParties($name: String, $take: Int!, $skip: Int!, $where: CounterPartyFilterInput) {
    counterParties(name: $name, take: $take, skip: $skip, where: $where) {
      ${getCounterPartiesFragment}
    }
  }
`;
