import { atom } from 'recoil';
import { AuthStateType } from 'hooks/useAuth';
import { getAppLocalStorage } from 'shared/appLocalStorage';

const authStateKey = 'auth';

const authState = atom({
  key: authStateKey,
  default: getAppLocalStorage() as AuthStateType
});

export default authState;
