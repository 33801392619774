import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetBargesOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { BargesColumn } from './bargesColumns';
import { bargeModel } from 'model';

export interface BargesTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetBargesOutput;
  columns: BargesColumn[];
  onEdit: (barge: bargeModel) => void;
  onDelete: (barge: bargeModel) => void;
  showActions?: boolean;
}

export const BargesTableBody =
  // eslint-disable-next-line max-len
  ({ columns, loading, error, data, onEdit, onDelete, showActions }: BargesTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.barges &&
          data.barges.items &&
          data.barges.items.length > 0 &&
          data.barges.items.map((barge) => (
            <TableRow key={`row-${barge.id}`}>
              <TableCell align="left">{barge.name}</TableCell>
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.editButton}
                    aria-label="edit barge"
                    component="span"
                    onClick={() => onEdit(barge)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              )}
              {!!showActions && (
                <TableCell align="center">
                  <IconButton
                    className={globalStyles.deleteButton}
                    aria-label="delete barge"
                    component="span"
                    onClick={() => onDelete(barge)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        {!loading && (!data || !data.barges || !data.barges.items || data.barges.items.length <= 0) && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
