import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TXT_LAKER, TXT_VESSEL, TXT_VESSEL_TYPE } from '../../../../../shared/translations';
import { useTranslation } from 'react-i18next';
import { VesselType } from 'model';
import _ from 'lodash';

export interface VesselTypeSelectorProps {
  defaultValue?: VesselType;
  error?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

export const VesselTypeSelector = (props: VesselTypeSelectorProps) => {
  const { t } = useTranslation();
  const lblVesselType = _.capitalize(t(TXT_VESSEL_TYPE));

  return (
    <FormControl fullWidth>
      <InputLabel id="lbl-vessel-search-by-type">{lblVesselType}</InputLabel>
      <Select
        id="vessel-search-by-type"
        labelId="lbl-vessel-search-by-type"
        label={lblVesselType}
        disabled={props.disabled}
        onChange={props.onChange}
        defaultValue={props.defaultValue ?? 'VESSEL'}
        error={props.error}
      >
        <MenuItem value={'VESSEL'}>{_.capitalize(t(TXT_VESSEL))}</MenuItem>
        <MenuItem value={'LAKER'}>{_.capitalize(t(TXT_LAKER))}</MenuItem>
      </Select>
    </FormControl>
  );
};
