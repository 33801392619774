import { Lock } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { useDemurragePageState } from 'pages/DemurragePage/DemurragePageContext';
import { FinalResultEditor } from 'pages/DemurragePage/FinalResultEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { formatCurrency, formatDuration, getDuration } from 'shared/utils';
import {
  TXT_TOTAL_TIME_USED,
  TXT_LESS_INTERRUPTIONS,
  TXT_NET_TIME_USED,
  TXT_TIME_IN_DESPATCH,
  TXT_DESPATCH_IN_CURRENCY,
  TXT_TIME_IN_DEMURRAGE,
  TXT_DESPATCH_DECIMAL_EQUIVALENT,
  TXT_DEMURRAGE_DECIMAL_EQUIVALENT,
  TXT_DEMURRAGE_IN_CURRENCY
} from '../../../../../../../shared/translations';
import { useGlobalStyles } from '../../../globalStyles';
import useAuth from 'hooks/useAuth';

export const Calculations = () => {
  const { t } = useTranslation();
  const { selectedLaytimeCalculation: selectedCalc, saving } = useDemurragePageState();
  const globalStyles = useGlobalStyles();
  const theme = useTheme();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const [openEditor, setOpenEditor] = useState(false);
  const isDemurrage = selectedCalc?.laytimeCalculationResult === 'DEMURRAGE';
  const calcValue = isDemurrage ? selectedCalc?.demurrageAmount : selectedCalc?.despatchAmount;
  const action = selectedCalc?.isCalculationOverriden ? 'UNDO' : 'OVERRIDE';
  const onFinalResultEditor = () => {
    setOpenEditor(true);
  };

  const onFinalResultEditorClosed = () => {
    setOpenEditor(false);
  };

  const sharePercentage = selectedCalc?.sharedPercentage
    ? Math.round(selectedCalc?.sharedPercentage * 100) / 100
    : undefined;

  return (
    <Box py={2} sx={{ position: 'relative' }}>
      {saving && (
        <Box sx={{ width: '100%', position: 'absolute', left: 0, top: 0 }}>
          <LinearProgress />
        </Box>
      )}
      {openEditor && (
        <FinalResultEditor
          {...{
            action,
            id: selectedCalc?.id,
            oldValue: calcValue,
            newValue: action === 'UNDO' ? calcValue : undefined,
            oldCalcResult: selectedCalc?.laytimeCalculationResult,
            currency: selectedCalc?.currency ?? 'USD'
          }}
          onClose={onFinalResultEditorClosed}
        />
      )}
      <Typography variant="h6" py={2}>
        Calculation Results
      </Typography>
      <Box>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <Box bgcolor={theme.palette.background.default} p={1}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{capitalizeFirstLetterEveryword(t(TXT_TOTAL_TIME_USED))}</TableCell>
                    <TableCell>
                      <Typography>
                        {selectedCalc?.totalTimeUsed ? formatDuration(selectedCalc?.totalTimeUsed) : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{capitalizeFirstLetterEveryword(t(TXT_LESS_INTERRUPTIONS))}</TableCell>
                    <TableCell>
                      <Typography>
                        {selectedCalc?.lessInterruptions ? formatDuration(selectedCalc?.lessInterruptions) : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{capitalizeFirstLetterEveryword(t(TXT_NET_TIME_USED))}</TableCell>
                    <TableCell>
                      <Typography>
                        {selectedCalc?.netTimeUsed ? formatDuration(selectedCalc?.netTimeUsed) : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor={theme.palette.background.default} p={1}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {capitalizeFirstLetterEveryword(t(isDemurrage ? TXT_TIME_IN_DEMURRAGE : TXT_TIME_IN_DESPATCH))}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {selectedCalc?.timeInDemurrage ? formatDuration(selectedCalc.timeInDemurrage) : ''}
                        {selectedCalc?.timeInDespatch ? formatDuration(selectedCalc.timeInDespatch) : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {capitalizeFirstLetterEveryword(
                        t(isDemurrage ? TXT_DEMURRAGE_DECIMAL_EQUIVALENT : TXT_DESPATCH_DECIMAL_EQUIVALENT)
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {selectedCalc?.timeInDespatch
                          ? getDuration(selectedCalc.timeInDespatch).asDays().toFixed(3)
                          : ''}
                        {selectedCalc?.timeInDemurrage
                          ? getDuration(selectedCalc.timeInDemurrage).asDays().toFixed(3)
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {capitalizeFirstLetterEveryword(
                        t(isDemurrage ? TXT_DEMURRAGE_IN_CURRENCY : TXT_DESPATCH_IN_CURRENCY)
                      )}
                      &nbsp;({selectedCalc?.currency})
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        {selectedCalc?.amountBeforeShare ? (
                          <Typography>
                            {formatCurrency(selectedCalc?.amountBeforeShare, selectedCalc?.currency || 'USD')}@
                            {sharePercentage}%
                          </Typography>
                        ) : null}
                        <Typography>{formatCurrency(calcValue ?? 0, selectedCalc?.currency || 'USD')}</Typography>
                        {!!calcValue && !isGuest && (
                          <IconButton
                            aria-label="editFinalResult"
                            className={globalStyles.editButton}
                            component="span"
                            onClick={() => selectedCalc?.status === 'DRAFT' && onFinalResultEditor()}
                            size="small"
                          >
                            {selectedCalc?.isCalculationOverriden ? <Lock /> : <ModeEditIcon />}
                          </IconButton>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
