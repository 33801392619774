import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GetSplitQuantitiesSelectorOutput, GET_SPLIT_QUANTITIES_SELECTOR } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_DEAL_NUMBER } from '../../../../../shared/translations';
import { splitQuantityModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import _ from 'lodash';

export interface SplitQuantitySelectorProps {
  splitQuantity?: splitQuantityModel;
  error?: boolean;
  disabled?: boolean;
  onChanged?: (splitQuantity?: splitQuantityModel) => void;
}

export const SplitQuantitySelector = (props: SplitQuantitySelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<splitQuantityModel | null>(props.splitQuantity || null);
  const [text, setText] = useState<string | undefined>(props.splitQuantity?.name);
  const [options, setOptions] = useState<splitQuantityModel[]>([]);

  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    if (_.isNil(text) || text.length <= 0) {
      return null;
    }

    let filter: any[] = [
      {
        name: {
          contains: text
        }
      }
    ];

    if (selected !== null) {
      filter = [
        ...filter,
        {
          id: {
            eq: selected.id
          }
        }
      ];
    }

    return filter.length > 1
      ? {
        or: [...filter]
      }
      : filter[0];
  };

  const [getSplitQuantities, { loading, refetch }] = useLazyQuery<GetSplitQuantitiesSelectorOutput>(
    GET_SPLIT_QUANTITIES_SELECTOR,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: getFilter()
      },
      onCompleted: (data) => setOptions([...data.splitQuantities.items]),
      onError: apolloErrorHandler
    }
  );

  const fetch = () => {
    if (refetch) {
      refetch();
    } else {
      getSplitQuantities();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: splitQuantityModel | null) => {
    setSelected(value);
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  useEffect(() => {
    fetch();
  }, [text]);

  return (
    <Autocomplete
      id="splitQuantity-selector"
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      value={selected}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      groupBy={(option) => option.deal?.name || ''}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={capitalizeFirstLetterEveryword(t(TXT_DEAL_NUMBER))}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
