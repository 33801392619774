import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const GET_DEAL_COMMENT_COUNT = gql`
  query getDealCommentCount($entityId: UUID!) {
    count: dealCommentCount(entityId: $entityId)
  }
`;

export const GET_DEAL_COMMENTS = gql`
  query getDealComments($skip: Int, $take: Int, $where: DealCommentFilterInput) {
    comments: dealComments(skip: $skip, take: $take, where: $where) {
      items {
        ${BaseCommentQueryData}
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;
