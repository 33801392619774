import { Text } from '@react-pdf/renderer';
import moment from 'moment';
import { getDateTimeFormat } from 'shared/utils';
import { styles } from './styles';

export const ReportFooter = () => (
  <Text
    render={() => `Calculated by Atreus on ${moment(new Date()).format(getDateTimeFormat())}`}
    style={styles.footer}
    fixed
  />
);

ReportFooter.displayName = 'ReportFooter';
