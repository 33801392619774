import { gql } from '@apollo/client';
import { contactAddressModel } from 'model';

export interface AddContactAddressInput extends Omit<contactAddressModel, 'id'> { }

export interface UpdateContactAddressInput extends AddContactAddressInput {
  strId: string;
}

export interface DeleteContactAddressInput {
  id: string;
}

export interface UpdateContactAddressesInput {
  contactId: string;
  updateInputs: UpdateContactAddressInput[];
}

export interface UpdateContactAddressesOutput {
  updateContactAddresses: contactAddressModel[];
}

export const UPDATE_CONTACT_ADDRESSES = gql`
  mutation updateContactAddresses($input: UpdateContactAddressesInput!) {
    updateContactAddresses(input: $input) {
      id
      name
      address
      contactId
    }
  }
`;
