import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_BARGE,
  TXT_FIELD_IS_REQUIRED,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_BARGE
} from '../../../../../shared/translations';
import { AddBargeInput, ADD_BARGE, UPDATE_BARGE, AddBargeOutput, UpdateBargeOutput } from '../../api';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { bargeModel } from 'model';

export interface BargeEditorProps extends bargeModel {
  onClose: () => void;
  afterSave: (barge: bargeModel) => void;
}

const schema = yup.object().shape({
  name: yup.string().required()
});

export const BargeEditor = (props: BargeEditorProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddBargeInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name
    }
  });
  useWatch({ control });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutateBarge, { loading }] = useMutation<AddBargeOutput | UpdateBargeOutput>(props.id ? UPDATE_BARGE : ADD_BARGE, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(props.id ? (data as UpdateBargeOutput).updateBarge : (data as AddBargeOutput).addBarge);
      props.onClose();
    }
  });

  const onSubmit: SubmitHandler<AddBargeInput> = (data) => {
    mutate(data);
  };

  const mutate = (input: AddBargeInput) => {
    if (!props.id) {
      return mutateBarge({
        variables: {
          input
        }
      });
    }

    return mutateBarge({
      variables: {
        input: {
          id: props.id,
          ...input
        }
      }
    });
  };

  const txtErrorField = _.capitalize(t(TXT_FIELD_IS_REQUIRED));
  const title = t(props.id ? TXT_UPDATE_BARGE : TXT_CREATE_BARGE);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="barge-editor-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                autoFocus
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                disabled={loading}
                error={!!errors.name}
                helperText={errors.name && txtErrorField}
                defaultValue={props.name}
                {...register('name', { required: true })}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="barge-editor-form" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
