import { gql } from '@apollo/client';
import { CounterPartyType, dealModel, laytimeCalculationModel, LaytimeCalculationStatus } from 'model';
import { getDealFragment } from '.';
import { UpdateDealPortInput, UpdateSplitQuantityInput } from '../dealPort';
import { UpdateDealSupplierInput } from '../dealSupplier';

export interface AddDealInput
  extends Omit<
    dealModel,
    | 'id'
    | 'loadingPorts'
    | 'unloadingPorts'
    | 'isActive'
    | 'isQuote'
    | 'calculationCounts'
    | 'splitQuantities'
    | 'suppliers'
  > {
  isActive: boolean;
  isQuote: boolean;
  loadingPorts?: UpdateDealPortInput[];
  unloadingPorts?: UpdateDealPortInput[];
  splitQuantities?: UpdateSplitQuantityInput[];
  suppliers?: UpdateDealSupplierInput[];
}

export interface UpdateDealInput extends AddDealInput {
  id: string;
}

export interface DeleteDealInput {
  id: string;
}

export const ADD_DEAL = gql`
  mutation addDeal($input: AddDealInput!) {
    addDeal(input: $input) {
      ${getDealFragment}
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation updateDeal($input: UpdateDealInput!) {
    updateDeal(input: $input) {
      ${getDealFragment}
    }
  }
`;

export const DELETE_SHIPMENT = gql`
  mutation deleteDeal($input: DeleteDealInput!) {
    deleteDeal(input: $input)
  }
`;

export const BATCH_OVERRIDE_CALCULATIONS = gql`
  mutation batchOverrideLaytimeCalculation($input: BatchOverrideInput!) {
    batchOverrideLaytimeCalculation(input: $input){
      ${getDealFragment}
    }
  }
`;

export interface BatchOverrideLaytimeCalculationInput {
  counterPartyType: CounterPartyType;
  counterPartyId?: string;
  splitQuantityIds?: string[];
  dealSupplierId?: string;
  dealPortId?: string;
  newValue: number | null;
  statusValue: LaytimeCalculationStatus;
  statusComment: string;
  currency: string;
}

export interface BatchOverrideInput {
  dealId?: string;
  overrides: BatchOverrideLaytimeCalculationInput[];
}

export type BatchOverrideOutput = {
  results: {
    loadingCalculations?: laytimeCalculationModel[];
    unloadingCalculations?: laytimeCalculationModel[];
  }[];
};
