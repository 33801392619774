import { gql } from '@apollo/client';

export interface AddBusinessUnitInput {
  name: string;
  initials: string;
}

export interface UpdateBusinessUnitInput extends AddBusinessUnitInput {
  id: string;
}

export interface DeleteBusinessUnitInput {
  id: string;
}

export const ADD_BUSINESS_UNIT = gql`
  mutation addBusinessUnit($input: AddBusinessUnitInput!) {
    addBusinessUnit(input: $input) {
      id,
      name,
      initials
    }
  }
`;

export const UPDATE_BUSINESS_UNIT = gql`
  mutation updateBusinessUnit($input: UpdateBusinessUnitInput!) {
    updateBusinessUnit(input: $input) {
      id,
      name,
      initials
    }
  }
`;

export const DELETE_BUSINESS_UNIT = gql`
  mutation deleteBusinessUnit($input: DeleteBusinessUnitInput!) {
    deleteBusinessUnit(input: $input)
  }
`;
