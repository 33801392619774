import { refreshToken, signIn } from '../api';
import useAuth from './useAuth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { signInPath } from '../shared/links';
import { defaultAuthState } from '../shared/appLocalStorage';
import { useDefaultSettings } from './useDefaultSettings';
import { resetDefaultSettingsState } from 'shared/defaultSettingsLocalStorage';

export const useAuthService = () => {
  const [auth, setAuth] = useAuth();
  const [, setDefaultSettings] = useDefaultSettings();
  const navigate = useNavigate();

  const signOut = () => {
    setDefaultSettings({
      ...resetDefaultSettingsState
    });
    setAuth({
      ...defaultAuthState
    });
    navigate(signInPath);
  };

  const isSignedIn = async () => {
    const signedIn = !!auth.username &&
      !!auth.refreshToken;
    if (!signedIn) {
      return {
        isSignedIn: false,
        token: undefined
      };
    }
    const needRefreshToken = signedIn &&
      auth.accessTokenValidDate &&
      moment().isAfter(moment(auth.accessTokenValidDate));
    if (!needRefreshToken) {
      return {
        isSignedIn: true,
        token: auth.accessToken
      };
    }

    let result = true;
    let token = undefined;
    try {
      const response = await refreshToken({
        username: auth.username!,
        refreshToken: auth.refreshToken!
      });
      if (!!response.errorMessage) {
        throw Error();
      }
      setAuth({
        ...auth,
        ...response
      });
      token = response.accessToken;
    } catch {
      result = false;
      token = undefined;
      signOut();
    }

    return {
      isSignedIn: result,
      token
    };
  };

  return {
    isSignedIn,
    signIn,
    refreshToken,
    signOut
  };
};
