import axios from 'axios';
import { apiBaseUrl } from '../../../shared/links';
import { forgotPasswordInput } from './forgotPasswordInput';
import { forgotPasswordOutput } from './forgotPasswordOutput';

export const forgotPassword = async (input: forgotPasswordInput) => {
  const url = `${apiBaseUrl}/api/user/forgotPassword`;
  try {
    const response = await axios.post<forgotPasswordOutput>(url, input);
    return response.data;
  } catch (err: any) {
    throw err;
  }
};
