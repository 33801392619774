import { ApolloError } from '@apollo/client';
import { Button, Divider, TableContainer, TableProps } from '@mui/material';
import { DELETE_SHIPMENT, GetDealsOutput } from '../../../api';
import { CustomTable, DeleteModal, PaginationBar } from '../../../components';
import { QuoteColumn, quoteColumns } from './quoteColumns';
import { QuoteTableBody } from './QuoteTableBody';
import { QuoteTableHead } from './QuoteTableHead';
import { PageSizeOptions } from 'shared/constants';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { createContext, useState } from 'react';
import { dealModel } from 'model';
import { QuoteWizard } from 'components/QuoteWizard';
import { TXT_ADD, TXT_DELETE_TEXT } from '../../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import _ from 'lodash';

interface QuoteTableProps extends TableProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetDealsOutput;
  afterSave: () => void;
  onPageChanged: (page: number) => void;
  onPageSizeChanged: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  bodyHeight?: string;
  parentLaytimeCalculationId?: string;
  disableAdd?: boolean;
  openDetailIndex?: number;
  disablePagination?: boolean;
  afterDelete?: () => void;
}

interface QuoteTableContextProps {
  columns: QuoteColumn[];
  deal?: dealModel;
  onEdit: (deal: dealModel) => void;
  onDelete: (deal: dealModel) => void;
  afterSave: () => void;
  openDetailIndex?: number;
}

export const QuoteTableContext = createContext<QuoteTableContextProps>({
  onEdit: () => {},
  onDelete: () => {},
  afterSave: () => {},
  columns: []
});

// eslint-disable-next-line max-len
export const QuoteTable = ({
  error,
  loading,
  data,
  afterSave,
  onPageChanged,
  onPageSizeChanged,
  pageNumber,
  pageSize,
  bodyHeight,
  parentLaytimeCalculationId,
  disableAdd,
  openDetailIndex,
  disablePagination,
  afterDelete,
  ...rest
}: QuoteTableProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<dealModel>();
  const [openEditor, setOpenEditor] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleChangePage = (_: unknown, newPage: number) => {
    onPageChanged(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPageSizeChanged(+event.target.value);
    onPageChanged(1);
  };

  const openCloseEditor = () => setOpenEditor((prev) => !prev);
  const openCloseDelete = () => setOpenDelete((prev) => !prev);

  const onCloseEditor = () => {
    openCloseEditor();
    setSelected(() => undefined);
  };

  const onCloseDelete = () => {
    openCloseDelete();
    setSelected(() => undefined);
  };

  const onEdit = (deal: dealModel) => {
    setSelected(() => ({ ...deal }));
    openCloseEditor();
  };
  const onDelete = (deal: dealModel) => {
    setSelected(() => ({ ...deal }));
    openCloseDelete();
  };

  return (
    <QuoteTableContext.Provider
      value={{
        deal: selected,
        onEdit,
        onDelete,
        afterSave,
        columns: quoteColumns(!!parentLaytimeCalculationId),
        openDetailIndex
      }}
    >
      {openEditor && (
        <QuoteWizard
          {...selected}
          parentLaytimeCalculationId={parentLaytimeCalculationId}
          onClose={onCloseEditor}
          afterSave={afterSave}
        />
      )}
      {openDelete && (
        <DeleteModal
          afterSaving={afterDelete || afterSave}
          mutation={DELETE_SHIPMENT}
          id={selected?.id}
          onCancel={onCloseDelete}
          txtConfirmation={_.capitalize(
            t(TXT_DELETE_TEXT, {
              name: selected?.name
            })
          )}
        />
      )}
      <TableContainer
        style={{
          height: bodyHeight
        }}
      >
        <CustomTable {...rest} stickyHeader>
          <QuoteTableHead />
          <QuoteTableBody loading={loading} error={error} data={data} />
        </CustomTable>
      </TableContainer>
      {!disablePagination ? (
        <>
          <Divider />
          <PaginationBar
            rowsPerPageOptions={PageSizeOptions}
            count={data && data.deals && data.deals.totalCount ? data.deals.totalCount : 0}
            page={loading ? 0 : pageNumber - 1}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          >
            {disableAdd ? null : (
              <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={openCloseEditor}>
                {capitalizeFirstLetterEveryword(t(TXT_ADD))}
              </Button>
            )}
          </PaginationBar>
        </>
      ) : null}
    </QuoteTableContext.Provider>
  );
};
