import axios from 'axios';
import { defaultAuthState } from '../../../shared/appLocalStorage';
import { apiBaseUrl } from '../../../shared/links';
import { registerTenantInput } from './registerTenantInput';
import { signInOutput } from '../SignIn';

// eslint-disable-next-line max-len
export const registerTenant = async (input: registerTenantInput) => {
  const url = `${apiBaseUrl}/api/tenant/register`;
  try {
    const response = await axios.post<signInOutput>(url, input);
    return response.data;
  } catch (err: any) {
    const retError: signInOutput = {
      ...defaultAuthState,
      errorMessage: err
    };
    return err.response &&
      err.response.data ?
      err.response.data as signInOutput : retError;
  }
};
