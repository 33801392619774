import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CounterPartyColumn } from './counterPartyColumns';

export interface CounterPartyTableHeadProps {
  columns: CounterPartyColumn[];
  showActions?: boolean;
}

export const CounterPartyTableHead = (props: CounterPartyTableHeadProps) => {
  const { t } = useTranslation();

  const filterColumnsByRole = (): CounterPartyColumn[] => {
    if (!!props.showActions) {
      return props.columns;
    }
    return props.columns.filter((col) => col.id !== 'edit' && col.id !== 'delete');
  };

  return (
    <TableHead>
      <TableRow>
        {filterColumnsByRole().map((col) => (
          <TableCell key={`head-${col.id}`} align={col.align || 'center'} style={{ width: col.maxWidth }}>
            {t(col.label).toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
