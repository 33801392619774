import { UpdateDealPortFormInput } from 'api/graphql';
import axios from 'axios';
import { dealPortModel, shareableLinkModel } from 'model';
import { apiBaseUrl } from 'shared/links';

const useShareableLink = () => {
  const baseUrl = `${apiBaseUrl}/api/shareablelink`;

  const getDealPort = (linkId: string) =>
    axios.get<shareableLinkModel>(`${baseUrl}/dealport`, {
      params: {
        linkId
      }
    });

  const updateDealPortForm = (linkId: string, input: UpdateDealPortFormInput) =>
    axios.post<dealPortModel>(`${baseUrl}/dealport`, input, {
      params: {
        linkId
      }
    });

  const sendDealPortEmail = (linkId: string) =>
    axios.post(`${baseUrl}/dealport/email`, null, {
      params: {
        linkId
      }
    });

  return {
    getDealPort,
    updateDealPortForm,
    sendDealPortEmail
  };
};

export default useShareableLink;
