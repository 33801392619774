import { gql } from '@apollo/client';
import { dealPortModel } from 'model';

const dealFragment = `
  id
  vesselId
  vessel {
    id
    name
  }
`;

export interface GetDealPortResponse {
  dealPort: dealPortModel;
}

export const GET_DEAL_PORT = gql`
  query getDealPort($where: DealPortFilterInput) {
    dealPort(where: $where) {
      id
      loadingDeals {
        ${dealFragment}
      }
      unloadingDeals {
        ${dealFragment}
      }
      portId
      port {
        id
        name
      }
      agentId
      agent {
        id
        name
      }
    }
  }
`;

export interface GetDealPortsResponse {
  dealPorts: dealPortModel[];
}

export const GET_DEAL_PORTS = gql`
  query getDealPorts($where: DealPortFilterInput, $order: [DealPortSortInput!]) {
    dealPorts(where: $where, order: $order) {
      id
      loadingDeals {
        ${dealFragment}
      }
      unloadingDeals {
        ${dealFragment}
      }
      portId
      port {
        id
        name
      }
      agentId
      agent {
        id
        name
      }
    }
  }
`;
