import { ReactNode } from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';

export const CustomError = (props: { children: ReactNode } & AlertProps) => {
  const { children, ...rest } = props;
  return (
    <Alert
      severity='error'
      sx={{ width: '100%' }}
      {...rest}
    >
      {children}
    </Alert>
  );
};
