import { ApolloError } from '@apollo/client';
import { useAuthService } from './useAuthServices';

export const useApolloErrorHandler = () => {
  const { isSignedIn } = useAuthService();

  const apolloErrorHandler = async (error?: ApolloError) => {
    if (!error) {
      return;
    }

    if (
      error.graphQLErrors.length > 0 &&
      error.graphQLErrors.some((x) => x.extensions?.code === 'AUTH_NOT_AUTHORIZED')
    ) {
      await isSignedIn();
    } else {
      console.error(error.message, error.graphQLErrors);
    }
  };

  return {
    apolloErrorHandler
  };
};
