import axios, { AxiosResponse } from 'axios';
import saveAs from 'file-saver';
import _ from 'lodash';
import { apiBaseUrl } from 'shared/links';
import { LaytimeChartReportRequest } from './useExcelAPI';

export const useShareableLinkDownloader = () => {
  const saveFile = (response: AxiosResponse<any, any>, defaultFilename: string) => {
    const filename =
      response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim() || defaultFilename;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    saveAs(url, filename);
  };

  const downloadLaytimeChart = async (params: LaytimeChartReportRequest) => {
    if (!params.linkId) {
      return;
    }
    try {
      const response = await axios.post<any>(
        `${apiBaseUrl}/api/reports/laytimechart/${params.linkId}`,
        {
          ..._.omit(params, ['linkId'])
        },
        {
          responseType: 'blob'
        }
      );
      saveFile(response, 'laytime.xlsx');
    } catch (error: any) {
      throw error;
    }
  };

  return {
    downloadLaytimeChart
  };
};
