import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DaysOfWeek } from 'shared/utils';

interface DayOfTheWeekSelectorProps {
  onChange: (day: number) => void;
  value?: number;
  disabled?: boolean;
}

export const DayOfTheWeekSelector = ({ onChange, value, disabled }: DayOfTheWeekSelectorProps) => {
  const handleChange = (e: SelectChangeEvent<number>) => {
    onChange(e.target.value as number);
  };

  return (
    <FormControl fullWidth>
      <Select onChange={handleChange} sx={{ marginTop: '16px', marginBottom: '8px' }} value={value} disabled={disabled}>
        {DaysOfWeek.map((name, index) => (
          <MenuItem key={name} value={index}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
