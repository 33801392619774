import { gql } from '@apollo/client';
import { shareableLinkModel } from 'model';

export const shareableLinkFragment = `
  id
  linkId
`;

export interface GetShareableLinksResponse {
  shareableLinks: shareableLinkModel[];
}

export const GET_SHAREABLE_LINKS = gql`
  query getShareableLinks($where: ShareableLinkFilterInput, $order: [ShareableLinkSortInput!]) {
    shareableLinks(where: $where, order: $order) {
      id
      linkId
      receiverEmail
      counterPartyId
      counterParty {
        id
        name
      }
      requestorId
      requestor {
        profile {
          fullName
        }
      }
      hasResponded
      modifiedDate
    }
  }
`;

export interface GetShareableLinkResponse {
  shareableLink?: shareableLinkModel;
}

export const GET_SHAREABLE_LINK = gql`
  query getShareableLink($where: ShareableLinkFilterInput) {
    shareableLink(where: $where) {
      ${shareableLinkFragment}
    }
  }
`;
