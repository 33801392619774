import axios from 'axios';
import { registerUserInput } from '.';
import { signInOutput } from '..';
import { apiBaseUrl } from '../../../shared/links';

export const registerUser = async (input: registerUserInput) => {
  const url = `${apiBaseUrl}/api/user/register`;
  try {
    const response = await axios.post<signInOutput>(url, input);
    return response.data;
  } catch (error: any) {
    return error.response.data as signInOutput;
  }
};
