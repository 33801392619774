import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useProfitAndLossReportContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    pnlActions: {
      display: 'flex',
      flex: '0 0 100%',
      justifyContent: 'flex-end'
    },
    pnlContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      gap: theme.spacing(2)
    },
    pnlTitle: {
      flex: '0 0 100%',
      textAlign: 'center'
    },
    pnlInfo: {
      flex: '0 0 100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2)
    },
    pnlInfoDesc: {
      width: '250px',
      backgroundColor: theme.palette.divider,
      fontWeight: 'bold'
    },
    pnlTable: {
      'width': '100%',
      'border-spacing': 0,
      'border-collapse': 'collapse',
      '& td': {
        padding: theme.spacing(1),
        margin: 0,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
      }
    },
    profit: {
      color: theme.palette.success.main,
      textAlign: 'right'
    },
    loss: {
      color: theme.palette.error.main,
      textAlign: 'right'
    },
    header2: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.divider,
      textAlign: 'center'
    }
  })
);
