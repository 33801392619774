export const isOutsideLaycan = (
  date?: Date,
  laycanStart?: Date,
  laycanEnd?: Date
) => {
  if (!date) {
    return false;
  }

  const time = new Date(date).getTime();
  const start = laycanStart && new Date(laycanStart).getTime();
  const end = laycanEnd && new Date(laycanEnd).getTime();

  if (!!end && time > end) {
    return true;
  }

  if (!!start && time < start) {
    return true;
  }

  return false;
};
