import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontSize: 8,
    fontFamily: 'Roboto',
    margin: 20,
    marginTop: 30,
    marginRight: 30
  },
  text: {
    height: 15
  },
  row: {
    borderBottom: 1,
    height: '20',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRight: 1
  },
  rowHeader: {
    backgroundColor: '#D3D3D3',
    borderTop: 1,
    borderBottom: 1,
    height: '30',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRight: 1
  },
  footer: { position: 'absolute', right: 40, top: 770 },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '92%',
    height: 14,
    alignItems: 'center'
  },
  title: {
    width: 300,
    marginLeft: 200,
    fontSize: 10
  }
});
