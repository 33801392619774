import { gql } from '@apollo/client';
import { maritimeDirectoryModel } from 'model/maritimeDirectoryModel';
import { basePagingResult } from '../base';

export interface GetMaritimeDirectoriesOutput {
  maritimeDirectories: basePagingResult<maritimeDirectoryModel>;
}

export interface GetMaritimeDirectoryResponse {
  maritimeDirectory?: maritimeDirectoryModel;
}

const maritimeDirectoryFragment = `
  id
  ports {
    id
    name
    country
  }
  counterPartyId
  counterParty {
    id
    name
    dealPopularity
  }
  contactId
  contact {
    id
    name
    notes
    addresses {
      id
      name
      address
      contactId
    }
    emails {
      id
      name
      email
      contactId
    }
    phones {
      id
      name
      phone
      contactId
    }
  }
`;

export const GET_MARITIME_DIRECTORIES = gql`
  query getMaritimeDirectories($take: Int, $skip: Int, $where:  MaritimeDirectoryFilterInput) {
    maritimeDirectories(take: $take, skip: $skip, where: $where) {
      items {
        ${maritimeDirectoryFragment}
      }
      totalCount
    }
  }
`;
