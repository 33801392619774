import { gql } from '@apollo/client';
import { bargeTripModel } from 'model';

const bargeTripsCounterPartyFragment = `
  id
  counterPartyId
  counterParty {
    id
    name
    businessUnitId
  }
  demurrageRate
`;

export const getBargeTripsFragment = `
  items {
    id,
    bargeId
    barge {
      id
      name
    }
    vesselId
    vessel {
      id
      name
    }
    ownerId
    owner {
      ${bargeTripsCounterPartyFragment}
    }
    receiverId
    receiver {
      ${bargeTripsCounterPartyFragment}
    }
    splitQuantityName
    loadingPortId
    loadingPort {
      id
      portId
      port {
        id
        name
      }
      arrivalTime
      completedTime
    }
    unloadingPortId
    unloadingPort {
      id
      portId
      port {
        id
        name
      }
    }
    productId
    product {
      id
      name
    }
    currency
    quantity
    trafficAnalystId
    trafficAnalyst {
      id
      profile {
        fullName
      }
    }
    massUnit
    pnL
    error
    bargeLaytimeCalculations {
      counterPartyTypeId
      freeTime
      totalDemurrage
      daysInDemurrage
      isLoadingCalc
    }
  },
  totalCount
`;

export type GetBargeTripsOutput = {
  bargeTrips?: {
    items?: [bargeTripModel],
    totalCount?: number;
  }
};

export const GET_BARGE_TRIPS = gql`
  query getBargeTrips($take: Int!, $skip: Int!, $where: BargeTripFilterInput) {
    bargeTrips(take: $take, skip: $skip, where: $where) {
      ${getBargeTripsFragment}
    }
  }
`;

const bargeTripCounterPartyFragment = `
  id
  counterPartyId
  counterParty {
    id
    name
    businessUnitId
  }
  freeDays
  demurrageRate
  status
  statusComment
  calendarRule
  unloadingArrivalTimeOverride
  unloadingCompletedTimeOverride
  unloadingReleaseTimeOverride
  holidays {
    id
    holiday
  }
`;

const bargeTripPortFragment = `
  id
  portId
  port {
    id
    name
  }
  arrivalTime
  completedTime
  releaseTime
`;

export type GetBargeTripOutput = {
  bargeTrip: bargeTripModel;
};

export const GET_BARGE_TRIP = gql`
  query getBargeTrip($id: UUID!) {
    bargeTrip(id: $id) {
      id,
      bargeId
      barge {
        id
        name
      }
      vesselId
      vessel {
        id
        name
      }
      ownerId
      owner {
        ${bargeTripCounterPartyFragment}
      }
      receiverId
      receiver {
        ${bargeTripCounterPartyFragment}
      }
      splitQuantityName
      loadingPortId
      loadingPort {
        ${bargeTripPortFragment}
      }
      unloadingPortId
      unloadingPort {
        ${bargeTripPortFragment}
      }
      productId
      product {
        id
        name
      }
      currency
      quantity
      trafficAnalystId
      trafficAnalyst {
        id
        profile {
          fullName
        }
      }
      massUnit
      pnL
      bargeLaytimeCalculations {
        counterPartyTypeId
        freeTime
        totalDemurrage
        daysInDemurrage
        isLoadingCalc
      }
    }
  }
`;
