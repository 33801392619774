import axios from 'axios';
import { apiBaseUrl } from '../../../shared/links';
import { changePasswordInput } from './changePasswordInput';
import { changePasswordOutput } from './changePasswordOutput';

export const changePassword = async (input: changePasswordInput) => {
  const url = `${apiBaseUrl}/api/user/changePassword`;
  try {
    const response = await axios.post<changePasswordOutput>(url, input);
    return response.data;
  } catch (err: any) {
    const error: changePasswordOutput = {
      errorMessage: err?.response?.data?.Message ?? 'Bad Request'
    };
    return error;
  }
};
