import { gql } from '@apollo/client';
import { BaseCommentQueryData } from '../base';

export const DEAL_COMMENT_ADDED = gql`
  subscription onDealCommentAdded($entityId: UUID!) {
    result: onDealCommentAdded(entityId: $entityId) {
      ${BaseCommentQueryData}
    }
  }
`;

export const DEAL_COMMENT_UPDATED = gql`
  subscription onDealCommentUpdated($entityId: UUID!) {
    result: onDealCommentUpdated(entityId: $entityId) {
      ${BaseCommentQueryData}
    }
  }
`;

export const DEAL_COMMENT_DELETED = gql`
  subscription onDealCommentDeleted($entityId: UUID!) {
    deletedId: onDealCommentDeleted(entityId: $entityId)
  }
`;
