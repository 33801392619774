import { ApolloError } from '@apollo/client';
import { CircularProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetBusinessUnitsOutput } from '../../api';
import { CustomError } from '../../components/CustomError';
import { TXT_LOADING, TXT_NO_DATA_FOUND } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { BusinessUnitsColumn } from './businesUnitsColumns';
import { businessUnitModel } from 'model';

export interface BusinessUnitsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: GetBusinessUnitsOutput;
  columns: BusinessUnitsColumn[];
  onEdit: (businessUnit: businessUnitModel) => void;
  onDelete: (businessUnit: businessUnitModel) => void;
}

export const BusinessUnitsTableBody =
  // eslint-disable-next-line max-len
  ({ columns, loading, error, data, onEdit, onDelete }: BusinessUnitsTableBodyProps) => {
    const { t } = useTranslation();
    const globalStyles = useGlobalStyles();

    return (
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
              <CircularProgress />
              <h3>{t(TXT_LOADING).toUpperCase()}</h3>
            </TableCell>
          </TableRow>
        )}
        {!loading && error && error.message && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{error.message}</CustomError>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          data &&
          data.businessUnits &&
          data.businessUnits.items &&
          data.businessUnits.items.length > 0 &&
          data.businessUnits.items.map((businessUnit) => (
            <TableRow key={`row-${businessUnit.id}`}>
              <TableCell align="left">{businessUnit.name}</TableCell>
              <TableCell align="left">{businessUnit.initials}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.editButton}
                  aria-label="edit businessUnit"
                  component="span"
                  onClick={() => onEdit(businessUnit)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  className={globalStyles.deleteButton}
                  aria-label="delete businessUnit"
                  component="span"
                  onClick={() => onDelete(businessUnit)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        {!loading && (!data || !data.businessUnits || !data.businessUnits.items || data.businessUnits.items.length <= 0) && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CustomError>{_.capitalize(t(TXT_NO_DATA_FOUND))}</CustomError>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
