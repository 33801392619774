import { Button, Stack, TextField } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import capitalizeEveryWord from '../../../../../shared/stringUtils/capitalizeEveryWord';
import { TXT_MESSAGE, TXT_SEND, TXT_SENDING, TXT_TYPE_MESSAGE_HERE } from '../../../../../shared/translations';
import { DealPortFormCommentsContext } from './DealPortFormComments';
import useDealPortFormApi from 'api/dealPortForm/useDealPortFormApi';
import addDealPortCommentDto from 'model/addDealPortCommentDto';

interface DealPortCommentBoxProps {
  parentCommentId?: string;
}

const DealPortCommentBox = (props: DealPortCommentBoxProps) => {
  const { t } = useTranslation();
  const { linkId, dealPortId, afterSend } = useContext(DealPortFormCommentsContext);
  const { addComment } = useDealPortFormApi();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const onChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  const onSend = async () => {
    if (!dealPortId || !linkId) {
      return;
    }

    const dto: addDealPortCommentDto = {
      dealPortId,
      comment,
      parentCommentId: props.parentCommentId
    };

    setLoading(true);
    const data = await addComment(linkId, dto);
    if (data) {
      afterSend(data);
      setComment('');
    }
    setLoading(false);
  };

  return (
    <Stack direction="column" p={1} spacing={1}>
      <TextField
        fullWidth
        multiline
        maxRows={5}
        onChange={onChanged}
        label={capitalizeEveryWord(t(TXT_MESSAGE))}
        placeholder={capitalizeEveryWord(t(TXT_TYPE_MESSAGE_HERE))}
        value={comment}
        disabled={loading}
      />
      <div
        style={{
          textAlign: 'right'
        }}
      >
        <Button
          startIcon={<SendIcon />}
          disabled={comment.trim().length <= 0 || loading}
          variant="contained"
          size="small"
          color="primary"
          onClick={onSend}
        >
          {capitalizeEveryWord(t(loading ? TXT_SENDING : TXT_SEND))}
        </Button>
      </div>
    </Stack>
  );
};

export default DealPortCommentBox;
