import { Container, Grid, Typography, useTheme } from '@mui/material';
import useDarkModeState from 'hooks/useDarkMode';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { forgotPassword, forgotPasswordInput } from '../../api';
import { Copyright, CustomError } from '../../components';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_EMAIL,
  TXT_FORGOT_PASSWORD,
  TXT_FORGOT_PASSWORD_TEXT,
  TXT_FORGOT_PASSWORD_TITLE
} from '../../../../../shared/translations';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { useState } from 'react';
import { VerificationCodeDialog } from './VerificationCodeDialog';
import { browserName, browserVersion } from 'react-device-detect';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [darkMode] = useDarkModeState();
  const theme = useTheme();

  const [openVerificationCodeDialog, setOpenVerificationCodeDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onSubmit = async (data: forgotPasswordInput) => {
    setIsSubmitting(true);

    try {
      const response = await forgotPassword({
        email: data.email,
        deviceType: `${browserName} ${browserVersion}`
      });

      if (!!response.email) {
        setEmail(response.email);
      }

      if (!!response.verificationCode) {
        setVerificationCode(response.verificationCode);
      }

      setOpenVerificationCodeDialog(true);
    } catch (err: any) {
      setErrorMessage(err?.response?.data?.Message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onEditorClosed = () => {
    setOpenVerificationCodeDialog(false);
  };

  const txtForgotPassword = capitalizeFirstLetterEveryword(t(TXT_FORGOT_PASSWORD));
  const txtUsername = capitalizeFirstLetterEveryword(t(TXT_EMAIL));

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(10) }}>
      {openVerificationCodeDialog && (
        <VerificationCodeDialog email={email} verificationCode={verificationCode} onClose={onEditorClosed} />
      )}
      <Grid container direction="column" spacing={1} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <img src={`/img/${darkMode ? 'logo_name_orange_wo_bg.png' : 'logo_name_wo_bg.png'}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{capitalizeFirstLetterEveryword(t(TXT_FORGOT_PASSWORD_TITLE))}</Typography>
          <Typography variant="body1">{_.capitalize(t(TXT_FORGOT_PASSWORD_TEXT))}</Typography>
        </Grid>
        {!!errorMessage && (
          <Grid item xs={12}>
            <CustomError>{_.capitalize(t(errorMessage))}</CustomError>
          </Grid>
        )}
        <Grid item xs={12}>
          <ForgotPasswordForm
            txtSubmit={txtForgotPassword}
            txtEmail={txtUsername}
            fontSize="18px"
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Copyright />
        </Grid>
      </Grid>
    </Container>
  );
};
