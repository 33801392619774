import { Grid, InputAdornment, TextField } from '@mui/material';
import { AddBargeTripInput } from 'api';
import {
  BargeSelector,
  CurrencySelector,
  MassUnitSelector,
  NumberTextField,
  ProductSelector,
  UserSelector,
  VesselSelector
} from 'components';
import _ from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ASSOCIATED_DEAL_NUMBER, TXT_QUANTITY, TXT_TRAFFIC_ANALYST, TXT_DUPLICATING_BARGE, TXT_LOADING_PORT, TXT_UNLOADING_PORT } from '../../../../../../shared/translations';
import { BargeTripEditorPort } from './BargeTripEditorPort';

interface BargeTripEditorInfoProps {
  disabled?: boolean;
}

export const BargeTripEditorInfo = (props: BargeTripEditorInfoProps) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
    register
  } = useFormContext<AddBargeTripInput>();

  const watch = useWatch({
    control
  });

  const watchVessel = useWatch({
    control,
    name: 'vessel'
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name="bargeId"
          render={({ fieldState }) => (
            <div>
              {' '}
              {!watch.creatingDuplicate && (
                <BargeSelector
                  barge={watch.barge}
                  error={!!fieldState.error}
                  onChanged={(barge) => {
                    setValue('barge', barge);
                    setValue('bargeId', barge?.id);
                  }}
                  disabled={!!props.disabled}
                  allowAdd
                />
              )}
              {!!watch.creatingDuplicate && <div>{TXT_DUPLICATING_BARGE}</div>}
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          id="splitQuantityName"
          label={capitalizeFirstLetterEveryword(t(TXT_ASSOCIATED_DEAL_NUMBER))}
          fullWidth
          disabled={!!props.disabled}
          error={!!errors.splitQuantityName}
          helperText={errors.splitQuantityName?.message}
          {...register('splitQuantityName')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          name="vesselId"
          control={control}
          render={(fieldState) => (
            <VesselSelector
              vessel={watchVessel}
              onChanged={(vessel) => {
                setValue('vesselId', vessel?.id || undefined);
                setValue('vessel', vessel);
              }}
              error={!!fieldState.fieldState.error}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name="currency"
          render={({ fieldState }) => (
            <CurrencySelector
              currency={watch.currency}
              error={!!fieldState.error}
              onSelectedChange={(currency) => {
                setValue('currency', currency ?? '', {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name="massUnit"
          render={({ fieldState }) => (
            <MassUnitSelector
              massUnit={watch.massUnit}
              error={!!fieldState.error}
              onSelectedChange={(unit) => {
                setValue('massUnit', unit ?? '', {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name="quantity"
          render={({ fieldState }) => (
            <NumberTextField
              fullWidth
              label={capitalizeFirstLetterEveryword(t(TXT_QUANTITY))}
              value={watch.quantity ?? ''}
              error={!!fieldState.error}
              onChange={(e) => {
                const newValue = e.target.value;
                setValue('quantity',
                  newValue.trim().length <= 0 || _.isNaN(newValue) ? undefined : parseFloat(newValue),
                  {
                    shouldDirty: true
                  }
                );
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{watch.massUnit}</InputAdornment>
              }}
              inputProps={{
                step: '0.001'
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="productId"
          render={({ fieldState }) => (
            <ProductSelector
              product={watch.product}
              error={!!fieldState.error}
              onChanged={(product) => {
                setValue('product', product);
                setValue('productId', product?.id);
              }}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="trafficAnalystId"
          render={({ fieldState }) => (
            <UserSelector
              user={watch.trafficAnalyst}
              error={!!fieldState.error}
              onChanged={(user) => {
                setValue('trafficAnalyst', user);
                setValue('trafficAnalystId', user?.id, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              label={capitalizeFirstLetterEveryword(t(TXT_TRAFFIC_ANALYST))}
              disabled={!!props.disabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <fieldset>
          <legend>{capitalizeFirstLetterEveryword(t(TXT_LOADING_PORT))}</legend>
          <BargeTripEditorPort isLoadingPort disabled={props.disabled} />
        </fieldset>
      </Grid>
      <Grid item xs={12} md={6}>
        <fieldset>
          <legend>{capitalizeFirstLetterEveryword(t(TXT_UNLOADING_PORT))}</legend>
          <BargeTripEditorPort disabled={props.disabled} />
        </fieldset>
      </Grid>
    </Grid>
  );
};
