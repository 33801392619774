import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

interface OverlayLoadingProps {
  open?: boolean;
  children?: ReactNode;
}

export const OverlayLoading = (props: OverlayLoadingProps) => {
  // return (
  //   <Backdrop
  //     sx={{
  //       color: '#fff',
  //       zIndex: (theme) => theme.zIndex.drawer + 1,
  //       display: 'flex',
  //       flexDirection: 'column',
  //       rowGap: (theme) => theme.spacing(2)
  //     }}
  //     open={!!props.open}
  //   >
  //     {props.children}
  //     <CircularProgress color="inherit" />
  //   </Backdrop>
  // );
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999, // Ensure the loading overlay is on top of everything
        pointerEvents: 'auto' // Allow pointer events on the loading overlay itself
      }}
    >
      <div style={{ position: 'absolute', zIndex: 99999 }}>
        <CircularProgress />
      </div>
    </div>
  );
};
