import { useLazyQuery } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GET_DEALS, GetDealsOutput } from 'api';
import { QuoteWizard } from 'components/QuoteWizard';
import { useApolloErrorHandler, useDefaultSettings } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_CLOSE, TXT_WHAT_IF_SCENARIOS } from '../../../../../shared/translations';
import { QuoteTable } from 'pages/Quote/QuoteTable';

interface QuickLaytimeCalcEditorProps {
  calculationId: string;
  title: string;
  isPortLoading?: boolean;
  onCancel: () => void;
}

export const QuickLaytimeCalcEditor = (props: QuickLaytimeCalcEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [calcs, setCalcs] = useState<GetDealsOutput>();
  const [openWizard, setOpenWizard] = useState(false);

  const [defaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [getCalcs, { loading }] = useLazyQuery<GetDealsOutput>(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: {
        parentLaytimeCalculationId: {
          eq: props.calculationId
        }
      }
    },
    onError: apolloErrorHandler,
    onCompleted: (data) => setCalcs(() => ({ ...data }))
  });

  const openCloseWizard = () => setOpenWizard((prev) => !prev);

  useEffect(() => {
    getCalcs();
  }, []);

  return (
    <>
      {openWizard ? (
        <QuoteWizard
          parentLaytimeCalculationId={props.calculationId}
          onClose={openCloseWizard}
          afterSave={getCalcs}
        />
      ) : null}
      <Dialog open={true} maxWidth={false} fullWidth>
        <DialogTitle>{`${t(TXT_WHAT_IF_SCENARIOS)} [${props.title}]`}</DialogTitle>
        <DialogContent>
          <QuoteTable
            loading={loading}
            afterSave={getCalcs}
            onPageChanged={(page) => setPageNumber(() => page)}
            onPageSizeChanged={(pageSize) => setPageSize(() => pageSize)}
            pageNumber={pageNumber}
            pageSize={pageSize}
            data={calcs}
            bodyHeight='500px'
            parentLaytimeCalculationId={props.calculationId}
            disableAdd
            openDetailIndex={0}
            disablePagination
            afterDelete={props.onCancel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="secondary">
            {t(TXT_CLOSE)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
