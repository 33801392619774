import { Container, Grid, Typography, useTheme } from '@mui/material';
import useDarkModeState from 'hooks/useDarkMode';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword, changePasswordInput } from '../../api';
import { Copyright, CustomError } from '../../components';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { TXT_ENTER_NEW_PASSWORD, TXT_PASSWORD, TXT_RETYPE_PASSWORD, TXT_SUBMIT } from '../../../../../shared/translations';
import { ChangePasswordForm } from './ChangePasswordForm';
import { signInPath } from 'shared/links';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [darkMode] = useDarkModeState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: changePasswordInput) => {
    setIsSubmitting(true);
    const changePasswordResponse = await changePassword({
      email: queryParams.get('username')!,
      verificationCode: queryParams.get('code')!,
      password: data.password,
      repassword: data.repassword
    });

    if (!!changePasswordResponse.errorMessage) {
      setErrorMessage(changePasswordResponse.errorMessage);
    } else {
      navigate(signInPath);
    }

    setIsSubmitting(false);
  };

  const txtSubmit = capitalizeFirstLetterEveryword(t(TXT_SUBMIT));
  const txtPassword = capitalizeFirstLetterEveryword(`${t(TXT_PASSWORD)}*`);
  const txtRePassword = capitalizeFirstLetterEveryword(`${t(TXT_RETYPE_PASSWORD)}*`);

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(10) }}>
      <Grid container direction="column" spacing={1} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <img src={`/img/${darkMode ? 'logo_name_orange_wo_bg.png' : 'logo_name_wo_bg.png'}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{_.capitalize(t(TXT_ENTER_NEW_PASSWORD))}</Typography>
        </Grid>
        {!!errorMessage && (
          <Grid item xs={12}>
            <CustomError>{_.capitalize(t(errorMessage))}</CustomError>
          </Grid>
        )}
        <Grid item xs={12}>
          <ChangePasswordForm
            txtSubmit={txtSubmit}
            txtPassword={txtPassword}
            txtRePassword={txtRePassword}
            fontSize="18px"
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Copyright />
        </Grid>
      </Grid>
    </Container>
  );
};
