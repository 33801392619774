import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import {
  CopyCalculationsOutput,
  COPY_LAYTIME_CALCULATION,
  GetLaytimeCalculationCalculatedOutput,
  GET_LAYTIME_CALCULATION_CALCULATED
} from 'api';
import { t } from 'i18next';
import { TXT_YES } from '../../../../../shared/translations';
import { interruptionModel } from 'model';
import { ShexCondition } from 'pages/DemurragePage/DemurrageContent/ShexConditionsEditor/ShexConditionsEditor';
import { useAuthService } from 'hooks/useAuthServices';
import {
  DemurragePageDispatchContext,
  refreshLaytimeCalculationsAfterSave,
  setInterruptions,
  setShexConditions,
  updateLaytimeCalculation
} from 'pages/DemurragePage/DemurragePageContext';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { getStringTimeFromMinutes } from 'shared/utils';
import { IShexCondition } from 'api/graphql/shexCondition';
import { useDemurrageService } from 'hooks';
import { useContext, useState } from 'react';

interface PasteCalculationSubmitButtonProps {
  calculationId?: string;
  interruptions?: interruptionModel[];
  shexConditions?: ShexCondition[];
  loading: boolean;
  onSaved: () => void;
  onError: (errors: string[]) => void;
}

export function convertShexConditionsFromResponse(response: IShexCondition[]): ShexCondition[] {
  return (response || []).map((x) => ({
    ...x,
    fromTime: getStringTimeFromMinutes(x.fromTime),
    untilTime: getStringTimeFromMinutes(x.untilTime)
  }));
}

export const PasteCalculationSubmitButton = ({
  calculationId,
  interruptions,
  shexConditions,
  loading,
  onSaved,
  onError
}: PasteCalculationSubmitButtonProps) => {
  const { isSignedIn } = useAuthService();
  const dispatch = useContext(DemurragePageDispatchContext);
  const { calculationReadyToBePastedId } = useDemurrageService();
  const [, setErrors] = useState<string[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const handleError =
    (source: 'Interruptions' | 'Shex Conditions' | 'Laytime Calculation') => (error?: ApolloError) => {
      if (!!!error) {
        return;
      }
      if (!!error.graphQLErrors || !!error.message) {
        isSignedIn();
      }
      setErrors((e) => {
        const newErrors = [...e, 'Error saving ' + source];
        onError(newErrors);
        return newErrors;
      });
    };

  const [loadLaytimeCalculationCalculatedFields] = useLazyQuery<GetLaytimeCalculationCalculatedOutput>(
    GET_LAYTIME_CALCULATION_CALCULATED,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: {
            eq: calculationId
          }
        }
      },
      onError: apolloErrorHandler,
      onCompleted: (calculation) => {
        if (!dispatch || calculation.laytimeCalculations.length <= 0) {
          return;
        }
        dispatch(refreshLaytimeCalculationsAfterSave(calculation.laytimeCalculations[0]));
      }
    }
  );

  const [mutateCopyPaste, { loading: saving }] = useMutation<CopyCalculationsOutput>(COPY_LAYTIME_CALCULATION, {
    onError: handleError('Laytime Calculation'),
    onCompleted: async (data) => {
      if (!!dispatch) {
        dispatch(setInterruptions(data.copyCalculations.interruptions));
        dispatch(setShexConditions(convertShexConditionsFromResponse(data.copyCalculations.shexConditions)));
        dispatch(updateLaytimeCalculation(data.copyCalculations.calculation));
        await loadLaytimeCalculationCalculatedFields();
      }
      onSaved();
    }
  });

  const onSubmit = () => {
    setErrors(() => []);
    onError([]);

    // Save Calculation
    if (!calculationReadyToBePastedId || !calculationId) {
      return;
    }

    mutateCopyPaste({
      variables: {
        input: {
          sourceId: calculationReadyToBePastedId,
          destinationId: calculationId,
          copyInterruptions: interruptions ? true : false,
          copyShexs: shexConditions ? true : false
        }
      }
    });
  };

  return (
    <Button onClick={onSubmit} disabled={loading || saving || !calculationId}>
      {saving && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
      {t(TXT_YES)}
    </Button>
  );
};
