import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useCustomTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: '66vh'
    }
  })
);
