import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  AppBar,
  Toolbar,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  DialogTitle,
  Stepper,
  Step,
  StepButton,
  useTheme,
  Stack,
  Alert
} from '@mui/material';
import { AddDealInput, ADD_DEAL, UPDATE_DEAL } from 'api';
import { t } from 'i18next';
import { dealModel } from 'model';
import { ReactNode, createContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE,
  TXT_CREATE_DEAL,
  TXT_UPDATE_DEAL,
  TXT_DEAL_INFO,
  TXT_UNLOADING_PORTS,
  TXT_RECEIVERS,
  TXT_PREVIOUS,
  TXT_NEXT,
  TXT_SUPPLIERS,
  TXT_LOADING_PORTS,
  ERR_LOADING_PORT_RULES,
  ERR_DEAL_NAME_NOT_MATCH_PATTERN,
  TXT_FIELD_IS_REQUIRED
} from '../../../../../shared/translations';
import * as yup from 'yup';
import { DealWizardInfo } from './DealWizardInfo';
import { DealWizardSplitQuantities } from './DealWizardSplitQuantities';
import { DealWizardUnlodingPorts } from './DealWizardUnlodingPorts';
import _ from 'lodash';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMutation } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { ignoreTimeZone } from 'shared/utils';
import { AddDealPortInput } from 'api/graphql/dealPort';
import { DealWizardSuppliers } from './DealWizardSuppliers';
import { DealWizardLoadingPorts } from './DealWizardLoadingPorts';
import WarningIcon from '@mui/icons-material/WarningAmber';
import useAuth from 'hooks/useAuth';
import { useRecoilState } from 'recoil';
import { tenantState } from 'state/tenantState';

interface DealWizardProps extends dealModel {
  onClose: () => void;
  afterSave: () => void;
}

interface stepperItem {
  label: string;
  component: ReactNode;
  error?: () => boolean;
}

interface DealWizardContextProps {
  isDisabled?: boolean;
}

export const DealWizardContext = createContext<DealWizardContextProps>({});

export const DealWizard = (props: DealWizardProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [auth] = useAuth();
  const { isGuest } = auth;
  const [tenant] = useRecoilState(tenantState);

  const dealPortSchema = {
    portId: yup.string().required(),
    quantity: yup.number().min(0),
    billOfLadingDate: yup.date().nullable().default(null),
    validNor: yup.date().nullable().default(null),
    laycanStartDate: yup.date().nullable().default(null),
    laycanEndDate: yup.date().nullable().default(null),
    norTenderedDate: yup.date().nullable().default(null),
    commencedDate: yup.date().nullable().default(null),
    completedDate: yup.date().nullable().default(null),
    agentId: yup.string().nullable()
  };

  const splitQuantitySchema = {
    name: !tenant.dealNamePattern ?
      yup.string().required(TXT_FIELD_IS_REQUIRED) :
      yup.string().required(TXT_FIELD_IS_REQUIRED).test({
        test: (name) => !!name?.match(new RegExp(tenant.dealNamePattern!, 'ig')),
        message: ERR_DEAL_NAME_NOT_MATCH_PATTERN
      }),
    counterPartyId: yup.string().required(),
    quantity: yup.number().min(0),
    unloadingPortId: yup.string().required(),
    loadingPortId: yup.string().required(),
    productId: yup.string().required(),
    traderId: yup.string().required(),
    businessUnitId: yup.string().required(),
    trafficSpecialistId: yup.string().required(),
    parentSplitQuantityName: yup.string().nullable()
  };

  const dealSupplierSchema = {
    supplierId: yup.string().required(),
    applicableDealPortId: yup.string().required(),
    quantity: yup.number().nullable().min(0)
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    isActive: yup.bool().required().default(true),
    isQuote: yup.bool().required().default(false),
    splitQuantities: yup.array().of(yup.object().shape(splitQuantitySchema)),
    suppliers: yup.array().of(yup.object().shape(dealSupplierSchema)),
    shipownerId: yup.string(),
    vesselId: yup.string().required(),
    loadingPorts: yup
      .array()
      .of(yup.object().shape(dealPortSchema))
      .test({
        message: ERR_LOADING_PORT_RULES,
        test: (arr) => {
          const x = arr || [];
          return x.length > 0 && x.some((a) => !!a.billOfLadingDate);
        }
      }),
    unloadingPorts: yup.array().of(yup.object().shape(dealPortSchema)),
    currency: yup.string().required(),
    massUnit: yup.string().required()
  });

  const methods = useForm<AddDealInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      isActive: props.isActive === undefined ? true : props.isActive,
      isQuote: props.isQuote === undefined ? false : props.isQuote,
      shipownerId: props.shipowner?.id,
      shipowner: props.shipowner,
      vesselId: props.vessel?.id,
      vessel: props.vessel,
      loadingPorts: props.loadingPorts ? [...props.loadingPorts] : [],
      unloadingPorts: props.unloadingPorts ? [...props.unloadingPorts] : [],
      currency: props.currency || 'USD',
      massUnit: props.massUnit || 'MT',
      splitQuantities: props.splitQuantities
        ? props.splitQuantities.map((split) => _.omit(split, ['unloadingPort', 'loadingPort']))
        : [],
      suppliers: props.suppliers ? props.suppliers.map((split) => _.omit(split, ['loadingPort'])) : []
    }
  });

  const { handleSubmit, formState, trigger } = methods;

  const { apolloErrorHandler } = useApolloErrorHandler();
  const [mutate, { loading }] = useMutation<dealModel>(props.id ? UPDATE_DEAL : ADD_DEAL, {
    onError: (error) => {
      if (error && error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions?.message) {
        setErrorMessage(_.capitalize(t(error.graphQLErrors[0].extensions.message as string)));
      }
      apolloErrorHandler(error);
    },
    onCompleted: () => {
      props.afterSave();
      props.onClose();
    }
  });

  const steps: stepperItem[] = [
    {
      label: TXT_DEAL_INFO,
      component: <DealWizardInfo />,
      error: () =>
        !_.isEmpty(_.omit(formState.errors, ['loadingPorts', 'unloadingPorts', 'splitQuantities', 'suppliers']))
    },
    {
      label: TXT_LOADING_PORTS,
      component: <DealWizardLoadingPorts />,
      error: () => !_.isEmpty(_.pick(formState.errors, ['loadingPorts']))
    },
    {
      label: TXT_UNLOADING_PORTS,
      component: <DealWizardUnlodingPorts />,
      error: () => !_.isEmpty(_.pick(formState.errors, ['unloadingPorts']))
    },
    {
      label: TXT_SUPPLIERS,
      component: <DealWizardSuppliers />,
      error: () => !_.isEmpty(_.pick(formState.errors, ['suppliers']))
    },
    {
      label: TXT_RECEIVERS,
      component: <DealWizardSplitQuantities />,
      error: () => !_.isEmpty(_.pick(formState.errors, ['splitQuantities']))
    }
  ];

  const modifiedDealPortInput = (port: AddDealPortInput) => {
    return {
      ..._.omit(port, ['id', 'port', 'agent']),
      billOfLadingDate: ignoreTimeZone(port.billOfLadingDate),
      commencedDate: ignoreTimeZone(port.commencedDate),
      completedDate: ignoreTimeZone(port.completedDate),
      validNor: ignoreTimeZone(port.validNor),
      laycanEndDate: ignoreTimeZone(port.laycanEndDate),
      laycanStartDate: ignoreTimeZone(port.laycanStartDate),
      norTenderedDate: ignoreTimeZone(port.norTenderedDate)
    };
  };

  const onSave: SubmitHandler<AddDealInput> = (data) => {
    const options = ['counterParty', 'product', 'unloadingPortId', 'loadingPortId', 'canDelete'];
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    const variableInput = {
      ..._.omit(data, [
        'client',
        'shipowner',
        'supplier',
        'vessel',
        'product',
        'trafficSpecialist',
        'trader',
        'businessUnit',
        'loadingPorts',
        'unloadingPorts',
        'splitQuantities'
      ]),
      loadingPorts: data.loadingPorts
        ? data.loadingPorts.map((port) => ({ ...modifiedDealPortInput(port), strId: port.id }))
        : null,
      unloadingPorts: data.unloadingPorts
        ? data.unloadingPorts.map((port) => ({ ...modifiedDealPortInput(port), strId: port.id }))
        : null,
      splitQuantities: data.splitQuantities
        ? data.splitQuantities.map((split) => ({
          ..._.omit(split, options),
          strUnloadingPortId: split.unloadingPortId,
          strLoadingPortId: split.loadingPortId
        }))
        : undefined,
      suppliers: data.suppliers
        ? data.suppliers.map((supplier) => ({
          ..._.omit(supplier, ['supplier', 'canDelete', 'applicableDealPortId', 'applicableDealPort']),
          id: supplier.id?.startsWith('-') ? emptyGuid : supplier.id,
          dealId: props.id || emptyGuid,
          strApplicableDealPortId: supplier.applicableDealPortId
        }))
        : undefined
    };

    setErrorMessage(undefined);
    if (props.id) {
      mutate({
        variables: {
          input: {
            id: props.id,
            ...variableInput,
            splitQuantities: variableInput.splitQuantities
              ? variableInput.splitQuantities.map((split) => ({
                ...split,
                id: split.id?.startsWith('-') ? emptyGuid : split.id,
                dealId: props.id
              }))
              : null
          }
        }
      });
      return;
    }

    mutate({
      variables: {
        input: {
          ...variableInput,
          splitQuantities: variableInput.splitQuantities
            ? variableInput.splitQuantities.map((split) => ({
              ..._.omit(split, ['id'])
            }))
            : null
        }
      }
    });
  };

  const handleStep = (step: number) => async () => {
    await trigger();
    setActiveStep(step);
  };

  const txtTitle = t(props.id ? TXT_UPDATE_DEAL : TXT_CREATE_DEAL);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
      PaperProps={{
        sx: {
          maxHeight: '95vh',
          height: '95vh'
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{txtTitle.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogTitle>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map((value, index) => {
            return (
              <Step key={`deal-wizard-${index}`}>
                <StepButton onClick={handleStep(index)} style={{ justifyContent: 'center' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {value.error && !!value.error() && <WarningIcon style={{ color: 'red' }}></WarningIcon>}
                    <div>{t(value.label).toUpperCase()}</div>
                  </Stack>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <DealWizardContext.Provider
            value={{
              isDisabled: loading || isGuest
            }}
          >
            <form
              id="frm-deal-wizard"
              onSubmit={handleSubmit(onSave)}
              style={{
                marginTop: theme.spacing(1)
              }}
            >
              {steps.map((value, index) => {
                return (
                  <div key={`deal-wizard-detail-${index}`} hidden={activeStep !== index}>
                    {value.component}
                  </div>
                );
              })}
            </form>
          </DealWizardContext.Provider>
        </FormProvider>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Button
            onClick={handleStep(activeStep - 1)}
            color="info"
            disabled={loading || activeStep <= 0}
            startIcon={<ArrowBackIosIcon />}
          >
            {t(TXT_PREVIOUS)}
          </Button>
          <Button
            onClick={handleStep(activeStep + 1)}
            color="info"
            disabled={loading || activeStep >= steps.length - 1}
            endIcon={<ArrowForwardIosIcon />}
          >
            {t(TXT_NEXT)}
          </Button>
        </div>
        {errorMessage && (
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {_.capitalize(errorMessage)}
          </Alert>
        )}
        <div>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button type="submit" form="frm-deal-wizard" disabled={loading || !!errorMessage || isGuest}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading || errorMessage ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
