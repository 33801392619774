import { useLazyQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import {
  GET_INTERUPTIONS,
  GET_LAYTIME_CALCULATION_CALCULATED,
  GetInterruptionsOutput,
  GetLaytimeCalculationCalculatedOutput
} from 'api';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useSnackbar } from 'notistack';
import {
  refreshLaytimeCalculationsAfterSave,
  setInterruptions,
  setShexConditions,
  useDemurragePageDispatch,
  useDemurragePageState
} from 'pages/DemurragePage/DemurragePageContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize, { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { formatDuration, getShexConditions, getShexConditionsFromResponse } from 'shared/utils';
import { ShexConditionEditor } from '../ShexConditionsEditor/ShexConditionsEditor';
import { InterruptionEditor } from './InterruptionEditor';
import { InterruptionTable } from './InterruptionTable';
import { LoadInterruptionDialog } from './LoadInterruptionDialog';
import useAuth from 'hooks/useAuth';
import {
  TXT_MODIFYING_LOCKED_CALC_VALIDATION,
  TXT_INTERRUPTIONS,
  TXT_NOT_COUNT_AS_LAYTIME,
  TXT_CALENDAR_RULES,
  TXT_LOAD_INTERRUPTIONS,
  TXT_EDIT_INTERRUPTIONS
} from '../../../../../../../shared/translations';

export const Interruptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [auth] = useAuth();

  const [showEditor, setShowEditor] = useState(false);
  const [showShexEditor, setShowShexEditor] = useState(false);
  const [showLoadInterruption, setShowLoadInterruption] = useState(false);
  const { selectedLaytimeCalculationId, interruptions, shexConditions, selectedLaytimeCalculation } =
    useDemurragePageState();
  const dispatch = useDemurragePageDispatch();

  const showLockedCalcValidation = () => {
    if (selectedLaytimeCalculation?.isCalculationOverriden) {
      enqueueSnackbar(
        <Typography variant="subtitle1">{capitalize(t(TXT_MODIFYING_LOCKED_CALC_VALIDATION))}</Typography>,
        {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 10000
        }
      );
    }
  };

  const [totalNotLaytime, setTotalNotLaytime] = useState(0);

  const { apolloErrorHandler } = useApolloErrorHandler();

  const [loadLaytimeCalculationCalculatedFields] = useLazyQuery<GetLaytimeCalculationCalculatedOutput>(
    GET_LAYTIME_CALCULATION_CALCULATED,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: {
            eq: selectedLaytimeCalculationId
          }
        }
      },
      onError: apolloErrorHandler,
      onCompleted: (calculation) => {
        if (calculation.laytimeCalculations.length <= 0) {
          return;
        }
        dispatch(refreshLaytimeCalculationsAfterSave(calculation.laytimeCalculations[0]));
      }
    }
  );

  const shexSummary = getShexConditions(shexConditions);

  const getFilter = () => {
    const result = {
      laytimeCalculationId: {
        eq: selectedLaytimeCalculationId || '00000000-0000-0000-0000-000000000000'
      }
    };
    return result;
  };

  const [getInterruptionDefinitions, { loading, error, data, refetch }] = useLazyQuery<GetInterruptionsOutput>(
    GET_INTERUPTIONS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: getFilter()
      },
      onError: apolloErrorHandler,
      onCompleted: (data) => loadLaytimeCalculationCalculatedFields()
    }
  );

  const fetch = () => {
    if (!refetch) {
      getInterruptionDefinitions();
    } else {
      refetch();
    }
  };

  const addInterruption = () => {
    setShowEditor(true);
  };

  const handleShowHideLoadInterruption = () => {
    setShowLoadInterruption((curr) => !curr);
  };

  useEffect(() => {
    if (!selectedLaytimeCalculationId) {
      return;
    }
    fetch();
  }, [selectedLaytimeCalculationId]);

  useEffect(() => {
    if (!data) {
      setTotalNotLaytime(0);
      dispatch(setInterruptions([]));
      return;
    }
    setTotalNotLaytime(data.interruptions.reduce((prev, current) => prev + (current.notCountAsLaytime || 0), 0));
    dispatch(setInterruptions(data.interruptions));
  }, [data]);

  useEffect(() => {
    if (!interruptions) {
      setTotalNotLaytime(0);
      return;
    }
    setTotalNotLaytime(interruptions.reduce((prev, current) => prev + (current.notCountAsLaytime || 0), 0));
  }, [interruptions]);
  return (
    <Accordion>
      {showEditor && (
        <InterruptionEditor
          onClose={() => setShowEditor(false)}
          afterSave={(data) => {
            fetch();
            dispatch(setInterruptions(data.updateInterruptions));
            loadLaytimeCalculationCalculatedFields();
            showLockedCalcValidation();
          }}
          interruptions={interruptions || []}
          onLoadInterruption={handleShowHideLoadInterruption}
        />
      )}
      {showShexEditor && (
        <ShexConditionEditor
          afterSave={(data) => {
            fetch();
            const conditions = getShexConditionsFromResponse(data);
            dispatch(setShexConditions(conditions));
            loadLaytimeCalculationCalculatedFields();
            showLockedCalcValidation();
          }}
          onClose={() => setShowShexEditor(false)}
          shexConditions={shexConditions}
        />
      )}
      {showLoadInterruption && (
        <LoadInterruptionDialog
          handleCloseDialog={handleShowHideLoadInterruption}
          afterSave={(data) => {
            fetch();
            dispatch(setInterruptions(data.updateInterruptions));
            loadLaytimeCalculationCalculatedFields();
            showLockedCalcValidation();
          }}
          portId={selectedLaytimeCalculation?.dealPort?.id}
        />
      )}
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="interruptions-content" id="interruptions-header">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2}>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_INTERRUPTIONS))}</Typography>
              </TableCell>
              <TableCell>{capitalizeFirstLetterEveryword(t(TXT_NOT_COUNT_AS_LAYTIME))}</TableCell>
              <TableCell>{formatDuration(totalNotLaytime)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionSummary>
      <AccordionDetails>
        {selectedLaytimeCalculation && selectedLaytimeCalculation.status === 'DRAFT' && (
          <Stack spacing={2} alignItems="center" justifyContent="space-between" direction="row" mb={2}>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setShowShexEditor(true)}
              disabled={!selectedLaytimeCalculationId}
            >
              {`${t(TXT_CALENDAR_RULES)}: ${shexSummary}`}
            </Button>
            {!!auth.allowOCR && (
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={handleShowHideLoadInterruption}
                disabled={!selectedLaytimeCalculationId}
              >
                {t(TXT_LOAD_INTERRUPTIONS)}
              </Button>
            )}
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={addInterruption}
              disabled={!selectedLaytimeCalculationId}
            >
              {t(TXT_EDIT_INTERRUPTIONS)}
            </Button>
          </Stack>
        )}
        <InterruptionTable loading={loading} error={error} interruptions={interruptions || []} />
      </AccordionDetails>
    </Accordion>
  );
};
