import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { UpdateDealPortFormInput } from 'api';
import useShareableLink from 'api/shareableLink/useShareableLink';
import { AxiosError } from 'axios';
import { CustomFooter, NumberTextField, OverlayLoading } from 'components';
import { useTitle } from 'hooks';
import { shareableLinkModel } from 'model';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getLastestDate } from 'pages/DemurragePage/utils';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getDateFormat, getDateTimeFormat, ignoreTimeZone } from 'shared/utils';
import {
  TXT_BILL_OF_LADING_DATE,
  TXT_COMPANY,
  TXT_LOADING,
  TXT_LOADING_COMMENCED,
  TXT_LOADING_COMPLETED,
  TXT_NOR_TENDERED,
  TXT_PORT,
  TXT_PORT_INFORMATION,
  TXT_QUANTITY,
  TXT_TONNAGE_PENDING,
  TXT_REQUESTED_BY,
  TXT_SUBMIT,
  TXT_SUBMITTED,
  TXT_SUBMITTING,
  TXT_TONNAGE_ON_BOARD,
  TXT_UNLOADING_COMMENCED,
  TXT_UNLOADING_COMPLETED,
  TXT_VALID_NOR,
  TXT_VESSEL,
  TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_CONFIRMATION,
  TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_WARNING,
  TXT_SUBMIT_AND_COMPLETE,
  TXT_COMPLETE
} from '../../../../../shared/translations';
import * as yup from 'yup';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import capitalize from '../../../../../shared/stringUtils/capitalize';
import capitalizeEveryWord from '../../../../../shared/stringUtils/capitalizeEveryWord';
import ChatIcon from '@mui/icons-material/Chat';
import DealPortFormComments from 'components/DealPortFormComments/DealPortFormComments';
import useDealPortFormApi from 'api/dealPortForm/useDealPortFormApi';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

const schema = yup.object().shape({
  eta: yup.date().nullable(),
  etb: yup.date().nullable(),
  etc: yup.date().nullable(),
  billOfLadingDate: yup.date().nullable(),
  norTenderedDate: yup.date().nullable(),
  validNor: yup.date().nullable(),
  commencedDate: yup.date().nullable(),
  completedDate: yup.date().nullable(),
  tonnageOnBoard: yup.number().nullable(),
  tonnagePending: yup.number().nullable(),
  close: yup.bool()
});

export const DealPortForm = () => {
  useTitle(TXT_PORT_INFORMATION);
  const { t } = useTranslation();
  const { linkId } = useParams();
  const { getDealPort, updateDealPortForm } = useShareableLink();
  const { getCommentsCount } = useDealPortFormApi();
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shareableLink, setShareableLink] = useState<shareableLinkModel>();
  const [latestDate, setLatestDate] = useState<Date>();
  const [commentCount, setCommentCount] = useState(0);

  const { reset, control, setValue, handleSubmit, formState } = useForm<UpdateDealPortFormInput>({
    resolver: yupResolver(schema)
  });
  const { enqueueSnackbar } = useSnackbar();

  const [showSubmitCompleteConfirmation, setShowSubmitCompleteConfirmation] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const toggleSubmitCompleteConfirmation = () => setShowSubmitCompleteConfirmation((prev) => !prev);
  const toggleChat = () => setShowChat((prev) => !prev);

  const dealPort = shareableLink?.dealPort;

  const watchETA = useWatch({
    control,
    name: 'eta'
  });

  const watchETB = useWatch({
    control,
    name: 'etb'
  });

  const watchETC = useWatch({
    control,
    name: 'etc'
  });

  const watchBOL = useWatch({
    control,
    name: 'billOfLadingDate'
  });

  const watchNORTendered = useWatch({
    control,
    name: 'norTenderedDate'
  });

  const watchValidNor = useWatch({
    control,
    name: 'validNor'
  });

  const watchCommenced = useWatch({
    control,
    name: 'commencedDate'
  });

  const watchCompleted = useWatch({
    control,
    name: 'completedDate'
  });

  const watchClose = useWatch({
    control,
    name: 'close'
  });

  const dates = [
    watchETA,
    watchETB,
    watchETC,
    watchBOL,
    watchNORTendered,
    watchValidNor,
    watchCommenced,
    watchCompleted
  ];

  const dateFormat = getDateFormat();
  const dateTimeFormat = getDateTimeFormat();

  const fetch = async () => {
    if (!linkId) {
      return;
    }

    setIsFetching((prev) => !prev);
    try {
      const response = await getDealPort(linkId);
      setShareableLink({ ...response.data });
    } catch (e: any) {
      const error = e as AxiosError;
      enqueueSnackbar(error.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
    } finally {
      setIsFetching((prev) => !prev);
    }
  };

  const getDeal = () => {
    const loadingDeals = dealPort?.loadingDeals || [];
    const unloadingDeals = dealPort?.unloadingDeals || [];

    const deals = loadingDeals.length > 0 ? loadingDeals : unloadingDeals;

    if (deals.length <= 0) {
      return null;
    }

    return deals[0];
  };

  const getVessel = () => {
    const deal = getDeal();

    if (!deal) {
      return null;
    }

    return deal.vessel?.name;
  };

  const getMassUnit = () => {
    const deal = getDeal();

    if (!deal) {
      return null;
    }

    return deal.massUnit;
  };

  const isLoading = () => {
    return (dealPort?.loadingDeals || []).length > 0;
  };

  const onSaveClicked = () => {
    if (!watchClose) {
      handleSubmit(onSave)();
    } else {
      setShowSubmitCompleteConfirmation(true);
    }
  };

  const onSubmitCompleteConfirmed = () => {
    toggleSubmitCompleteConfirmation();
    handleSubmit(onSave)();
  };

  const onSave: SubmitHandler<UpdateDealPortFormInput> = async (data) => {
    if (!linkId) {
      return;
    }
    setIsSaving((prev) => !prev);
    try {
      await updateDealPortForm(linkId, {
        ...data,
        eta: ignoreTimeZone(data.eta),
        etb: ignoreTimeZone(data.etb),
        etc: ignoreTimeZone(data.etc),
        billOfLadingDate: ignoreTimeZone(data.billOfLadingDate),
        norTenderedDate: ignoreTimeZone(data.norTenderedDate),
        validNor: ignoreTimeZone(data.validNor),
        commencedDate: ignoreTimeZone(data.commencedDate),
        completedDate: ignoreTimeZone(data.completedDate)
      });
      enqueueSnackbar(capitalizeEveryWord(t(TXT_SUBMITTED)), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
    } catch (e: any) {
      const error = e as AxiosError;
      enqueueSnackbar(error.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });
    } finally {
      setIsSaving((prev) => !prev);
    }
  };

  const getPortLastestDate = (): Date | undefined =>
    dates.reduce((prev, curr) => getLastestDate(prev, curr), undefined);

  const updateCommentsCount = async () => {
    if (!linkId) {
      return;
    }
    const count = await getCommentsCount(linkId);
    setCommentCount(count);
  };

  useEffect(() => {
    fetch();
  }, [linkId]);

  useEffect(() => {
    updateCommentsCount();

    const interval = setInterval(updateCommentsCount, 1000 * 60 * 5); // every 5 mins

    return () => {
      clearInterval(interval);
    };
  }, [linkId]);

  useEffect(() => {
    reset({
      ...dealPort
    });
  }, [dealPort]);

  useEffect(() => {
    setLatestDate(getPortLastestDate());
  }, dates);

  return (
    <>
      {showSubmitCompleteConfirmation ? (
        <ConfirmationDialog
          open
          title={t(TXT_SUBMIT_AND_COMPLETE).toUpperCase()}
          onCanceled={toggleSubmitCompleteConfirmation}
          onAccepted={onSubmitCompleteConfirmed}
        >
          <Stack direction="column">
            <Typography>{capitalize(t(TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_CONFIRMATION))}</Typography>
            <Alert severity="warning">{capitalize(t(TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_WARNING))}</Alert>
          </Stack>
        </ConfirmationDialog>
      ) : null}
      <Container
        component="main"
        maxWidth="xl"
        sx={(theme) => ({
          mt: theme.spacing(12),
          mb: theme.spacing(10)
        })}
      >
        {isFetching || isSaving ? (
          <OverlayLoading open={true}>
            {capitalizeEveryWord(t(isFetching ? TXT_LOADING : TXT_SUBMITTING))}
          </OverlayLoading>
        ) : null}
        {showChat && linkId ? <DealPortFormComments onClose={toggleChat} linkId={linkId} /> : null}
        <form id="frm-request-form" name="frm-request-form">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'end'
              }}
            >
              <IconButton onClick={toggleChat}>
                <StyledBadge badgeContent={commentCount} color="secondary">
                  <ChatIcon fontSize="inherit" />
                </StyledBadge>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={capitalizeEveryWord(t(TXT_COMPANY))}
                variant="standard"
                value={dealPort?.tenant?.name || ''}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={capitalizeEveryWord(t(TXT_REQUESTED_BY))}
                variant="standard"
                value={shareableLink?.requestor?.profile?.fullName || ''}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={capitalizeEveryWord(t(TXT_PORT))}
                variant="standard"
                value={dealPort?.port?.name || ''}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={capitalizeEveryWord(t(TXT_VESSEL))}
                variant="standard"
                value={getVessel() || ''}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label={capitalizeEveryWord(t(TXT_QUANTITY))}
                variant="standard"
                margin="dense"
                value={(dealPort?.quantity || 0).toLocaleString()}
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: <Typography>{getMassUnit()}</Typography>
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="tonnageOnBoard"
                render={({ field, fieldState }) => (
                  <NumberTextField
                    value={field.value || ''}
                    onChange={(e) =>
                      setValue('tonnageOnBoard', +e.currentTarget.value, {
                        shouldDirty: true
                      })
                    }
                    label={capitalizeEveryWord(t(TXT_TONNAGE_ON_BOARD))}
                    InputProps={{
                      endAdornment: <Typography>{getMassUnit()}</Typography>
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!!fieldState.error}
                    fullWidth
                    disabled={isSaving}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="tonnagePending"
                render={({ field, fieldState }) => (
                  <NumberTextField
                    value={field.value || ''}
                    onChange={(e) =>
                      setValue('tonnagePending', +e.currentTarget.value, {
                        shouldDirty: true
                      })
                    }
                    label={capitalizeEveryWord(t(TXT_TONNAGE_PENDING))}
                    InputProps={{
                      endAdornment: <Typography>{getMassUnit()}</Typography>
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!!fieldState.error}
                    fullWidth
                    disabled={isSaving}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="eta"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('eta', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`ETA (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="etb"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('etb', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`ETB (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="etc"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('etc', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`ETC (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="billOfLadingDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    format={dateFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('billOfLadingDate', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`${capitalizeEveryWord(t(TXT_BILL_OF_LADING_DATE))} (${dateFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="norTenderedDate"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('norTenderedDate', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`${capitalizeEveryWord(t(TXT_NOR_TENDERED))} (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="validNor"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('validNor', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`${capitalizeEveryWord(t(TXT_VALID_NOR))} (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="commencedDate"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('commencedDate', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`${capitalizeEveryWord(
                      t(isLoading() ? TXT_LOADING_COMMENCED : TXT_UNLOADING_COMMENCED)
                    )} (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="completedDate"
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    format={dateTimeFormat}
                    value={!!field.value ? moment(field.value) : null}
                    onChange={(value) => {
                      setValue('completedDate', value?.toDate(), { shouldDirty: true });
                    }}
                    label={`${capitalizeEveryWord(
                      t(isLoading() ? TXT_LOADING_COMPLETED : TXT_UNLOADING_COMPLETED)
                    )} (${dateTimeFormat})`}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!fieldState.error,
                        disabled: isSaving
                      },
                      field: {
                        clearable: true
                      }
                    }}
                    referenceDate={!!latestDate ? moment(latestDate) : undefined}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                alignContent: 'center'
              }}
            >
              <Controller
                control={control}
                name="close"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!field.value}
                        onChange={(_, checked) =>
                          setValue('close', checked, {
                            shouldDirty: true
                          })
                        }
                      />
                    }
                    label={capitalizeEveryWord(t(TXT_COMPLETE))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={isFetching || isSaving || !formState.isDirty}
                fullWidth
                onClick={onSaveClicked}
                color="primary"
              >
                {isSaving && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
                {t(TXT_SUBMIT)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <CustomFooter />
    </>
  );
};
