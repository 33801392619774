import { TXT_CAPACITY, TXT_CODE, TXT_DELETE, TXT_DESCRIPTION, TXT_EDIT } from '../../../../../shared/translations';

export interface InterruptionDefinitionsColumn {
  id: 'code' | 'capacity' | 'description' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const InterruptionDefinitionsColumns: InterruptionDefinitionsColumn[] = [
  {
    id: 'code',
    label: TXT_CODE,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'capacity',
    label: TXT_CAPACITY,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'description',
    label: TXT_DESCRIPTION,
    maxWidth: '20%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%'
  }
];
