import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Container, Grid, IconButton, InputAdornment, Paper, TextField, useTheme } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { DELETE_STATEMENT_OF_FACT, GET_STATEMENT_OF_FACTS, GetStatementOfFactsOutput } from 'api';
import { DeleteModal, GridNoResultsOverlay } from 'components';
import { StatementOfFactStatusSelector } from 'components/StatementOfFactStatusSelector';
import { useApolloErrorHandler, useDefaultSettings, useTitle } from 'hooks';
import _ from 'lodash';
import { statementOfFactModel, statementOfFactStatus } from 'model';
import moment from 'moment';
import { useGlobalStyles } from 'pages/globalStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageSizeOptions } from 'shared/constants';
import { ocrWizardPath } from 'shared/links';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getSOFStatusName } from 'shared/utils';
import { useStatementOfFactStyle } from './statementOfFactStyle';
import {
  TXT_ADD,
  TXT_ARRIVAL_DATE,
  TXT_DELETE,
  TXT_DELETE_TEXT,
  TXT_EDIT,
  TXT_NOR_TENDERED,
  TXT_PORT,
  TXT_SEARCH_BY_NAME,
  TXT_STATEMENT_OF_FACT,
  TXT_STATUS,
  TXT_VESSEL
} from '../../../../../shared/translations';

export const StatementOfFacts = () => {
  useTitle(TXT_STATEMENT_OF_FACT);
  const globalStyles = useGlobalStyles();
  const sofStyle = useStatementOfFactStyle();
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const theme = useTheme();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [defaultSettings, setDefaultSettings] = useDefaultSettings();
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selected, setSelected] = useState<statementOfFactModel | undefined>();
  const [nameFilter, setNameFilter] = useState('');
  const [status, setStatus] = useState<statementOfFactStatus>('DRAFT');

  const customFooter = () => (
    <GridFooterContainer
      sx={{
        padding: `0 ${theme.spacing(1)}`
      }}
    >
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => navigate(ocrWizardPath)}>
        {capitalizeFirstLetterEveryword(t(TXT_ADD))}
      </Button>
      <GridFooter
        sx={{
          border: 'none'
        }}
      />
    </GridFooterContainer>
  );

  const getFilter = () => {
    let filter: any[] = [];

    if ((nameFilter?.trim()?.length || 0) > 0) {
      filter = [
        ...filter,
        {
          or: [
            {
              vesselName: {
                contains: nameFilter
              }
            }
          ]
        }
      ];
    }

    if (!!status) {
      filter = [
        ...filter,
        {
          status: {
            eq: status
          }
        }
      ];
    }

    return filter.length <= 0 ? null : { and: filter };
  };

  const [getStatementOfFacts, { loading, data, refetch }] = useLazyQuery<GetStatementOfFactsOutput>(
    GET_STATEMENT_OF_FACTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: getFilter(),
        take: pageSize,
        skip: pageSize * (pageNumber - 1)
      },
      onError: apolloErrorHandler
    }
  );

  const closeDeleteConfirmation = () => setIsDeleting(() => false);

  const onDelete = (model: statementOfFactModel) => {
    return () => {
      setSelected(() => model);
      setIsDeleting(() => true);
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'vesselName',
      headerName: capitalizeFirstLetterEveryword(t(TXT_VESSEL)),
      flex: 1,
      minWidth: 200
    },
    {
      field: 'arrivalDate',
      headerName: capitalizeFirstLetterEveryword(t(TXT_ARRIVAL_DATE)),
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<statementOfFactModel, Date>) => {
        return moment(params.row.arrivalDate).format('MMMM DD YYYY');
      }
    },
    {
      field: 'norTenderedDate',
      headerName: capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED)),
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<statementOfFactModel, Date>) => {
        return moment(params.row.norTenderedDate).format('MMMM DD YYYY');
      }
    },
    {
      field: 'portName',
      headerName: capitalizeFirstLetterEveryword(t(TXT_PORT)),
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams<statementOfFactModel, Date>) => {
        return params.row.port?.name;
      }
    },
    {
      field: 'status',
      headerName: capitalizeFirstLetterEveryword(t(TXT_STATUS)),
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams<statementOfFactModel, Date>) => {
        if (params.row.status) return getSOFStatusName(params.row.status, t);
      }
    },
    {
      field: 'edit',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_EDIT)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<statementOfFactModel, any>) => {
        return (
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="edit"
            component="span"
            disabled={params.row.status && params.row.status === 'USED_BY_LAYTIME_CALCULATION'}
            onClick={() => navigate(`${ocrWizardPath}/${params.row.id}`)}
          >
            <EditIcon />
          </IconButton>
        );
      }
    },
    {
      field: 'delete',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      headerName: capitalizeFirstLetterEveryword(t(TXT_DELETE)),
      sortable: false,
      renderCell: (params: GridRenderCellParams<statementOfFactModel, any>) => {
        return (
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="delete"
            component="span"
            disabled={params.row.status && params.row.status === 'USED_BY_LAYTIME_CALCULATION'}
            onClick={onDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  const fetch = () => {
    if (!refetch) {
      getStatementOfFacts();
    } else {
      refetch();
    }
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter, status]);

  let txtDeleteConfirmation = t(TXT_DELETE_TEXT, {
    name: selected?.vesselName
  });
  txtDeleteConfirmation = _.capitalize(txtDeleteConfirmation);

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(() => event.currentTarget.value || '');
  };

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {isDeleting && !!selected && (
        <DeleteModal
          txtConfirmation={txtDeleteConfirmation}
          onCancel={closeDeleteConfirmation}
          id={selected.id!}
          mutation={DELETE_STATEMENT_OF_FACT}
          afterSaving={fetch}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={sofStyle.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={6}>
                <StatementOfFactStatusSelector
                  label={capitalizeFirstLetterEveryword(t(TXT_STATUS))}
                  value={status}
                  onChanged={(value) => setStatus(() => value)}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  id="statement-of-fact-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} className={globalStyles.datagridContainer}>
                <DataGrid
                  rows={data?.statementOfFacts?.items || []}
                  rowCount={data?.statementOfFacts?.totalCount || 0}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize,
                        page: pageNumber - 1
                      }
                    }
                  }}
                  checkboxSelection={false}
                  pageSizeOptions={PageSizeOptions}
                  loading={loading}
                  onPaginationModelChange={(page) => {
                    setPageSize(() => page.pageSize);
                    setPageNumber(() => page.page);
                  }}
                  disableColumnFilter
                  disableColumnMenu
                  paginationMode="server"
                  slots={{
                    footer: customFooter,
                    noRowsOverlay: GridNoResultsOverlay
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
