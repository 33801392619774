import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_TERM } from '../../../../../shared/translations';
import { incoTermOptions } from 'model';

export interface IncoTermSelectorProps {
  label?: string;
  incoTerm?: string;
  error?: boolean;
  onSelectedChange: (incoTerm: string | null) => void;
  disabled?: boolean;
  helperText?: string;
}

export const IncoTermSelector = (props: IncoTermSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string | null>(props.incoTerm || null);

  const onSelectedChange = (_: object, value: string | null) => {
    setSelected(value);
    props.onSelectedChange(value);
  };

  useEffect(() => {
    onSelectedChange({}, props.incoTerm || null);
  }, [props.incoTerm]);

  return (
    <Autocomplete
      id="incoTerm-selector"
      onChange={onSelectedChange}
      value={selected}
      options={incoTermOptions}
      fullWidth
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_TERM))}
          variant="outlined"
          error={props.error}
          helperText={props.helperText}
          FormHelperTextProps={{
            style: {
              marginLeft: '0',
              textAlign: 'right'
            }
          }}
        />
      )}
    />
  );
};
