import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const DividerWithLine = (props: { children?: ReactNode }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box borderBottom={`2px solid rgba(145, 158, 171, 0.24)`} width="100%" />
      {props.children && (
        <Box
          component="span"
          paddingTop={theme.spacing(1)}
          paddingBottom={theme.spacing(1)}
          paddingRight={theme.spacing(2)}
          paddingLeft={theme.spacing(2)}
          color="#637381"
        >
          {props.children}
        </Box>
      )}
      <Box borderBottom={`2px solid rgba(145, 158, 171, 0.24)`} width="100%" />
    </Box>
  );
};
