import { CalendarMonthTwoTone } from '@mui/icons-material';
import { StaticDatePicker, DateTimePicker as DtPicker } from '@mui/x-date-pickers';
import {
  InputAdornment,
  IconButton,
  Popper,
  Paper,
  Stack,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Button,
  ClickAwayListener,
  Typography
} from '@mui/material';
import moment, { Moment } from 'moment';
import { useState } from 'react';

export interface DateTimePickerProps {
  value?: Moment;
  onChange: (value?: Moment) => void;
  disabled?: boolean;
  id?: string;
  dateFormat: string;
  showTimeInput?: boolean;
  label?: string;
  openToDate?: Date;
  error?: boolean;
}

export const DateTimePicker = ({
  label,
  showTimeInput,
  dateFormat,
  id,
  value,
  onChange,
  disabled,
  openToDate,
  error
}: DateTimePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleChange = (date: moment.Moment | null, initializeTime: boolean) => {
    if (date) {
      const newDate = date;
      if (!value && initializeTime) {
        newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      }
      onChange(newDate);
    } else {
      onChange(undefined);
    }
  };

  const height = 290;

  const hours = value ? value.hours() : 0;
  const minutes = value ? value.minutes() : 0;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget.parentElement?.parentElement!);
    setOpen((s) => !s);
  };

  const handleHoursChange = (hrs: number) => () => {
    if (value && onChange) {
      const newDate = moment(value).hours(hrs).minutes(minutes);
      onChange(newDate);
    }
  };

  const handleMinutesChange = (min: number) => () => {
    if (value && onChange) {
      const newDate = moment(value).hours(hours).minutes(min);
      onChange(newDate);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNow = () => {
    if (onChange) {
      const newDate = moment();
      onChange(newDate);
    }
  };

  return (
    <>
      <DtPicker
        disabled={disabled}
        format={dateFormat}
        disableOpenPicker
        onChange={(x) => handleChange(x, false)}
        value={value || null}
        slotProps={{
          textField: {
            error,
            fullWidth: true,
            label,
            id,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClick}>
                    <CalendarMonthTwoTone />
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        }}
      />

      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 9000 }}>
          <Paper>
            <Stack>
              <Stack direction="row">
                <Box borderRight="1px solid lightgrey">
                  <StaticDatePicker
                    referenceDate={!!openToDate ? moment(openToDate) : undefined}
                    displayStaticWrapperAs="desktop"
                    onChange={(x) => handleChange(x, true)}
                    value={value || null}
                    views={['day']}
                  />
                </Box>
                {showTimeInput && (
                  <Box>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center', height: '50px', justifyContent: 'center' }}
                    >
                      Time
                    </Typography>
                    <Stack direction="row" borderTop="1px solid lightgrey">
                      <List
                        className="disable-scrollbars"
                        dense
                        sx={{ borderRight: '1px solid lightgrey', height, overflowY: 'scroll' }}
                      >
                        {new Array(24).fill(null).map((x, index) => (
                          <ListItemButton
                            key={'hour-' + index}
                            selected={index === hours}
                            autoFocus={index === hours}
                            onClick={handleHoursChange(index)}
                          >
                            <ListItemText primary={('0' + index).slice(-2)} />
                          </ListItemButton>
                        ))}
                      </List>
                      <List dense className="disable-scrollbars" sx={{ height, overflowY: 'scroll' }}>
                        {new Array(60).fill(null).map((x, index) => (
                          <ListItemButton
                            key={'minute-' + index}
                            selected={index === minutes}
                            autoFocus={index === minutes}
                            onClick={handleMinutesChange(index)}
                          >
                            <ListItemText primary={('0' + index).slice(-2)} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Stack>
                  </Box>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" borderTop="1px solid lightgrey">
                <Button onClick={handleNow} size="small">
                  Now
                </Button>
                <Button onClick={handleClose}>OK</Button>
              </Stack>
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};
