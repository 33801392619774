import { gql } from '@apollo/client';
import { laytimeChartReportListModel, laytimeChartReportModel } from 'model';

export interface GetLaytimeChartForSplitReportOutput {
    laytimeChartForSplitReport: laytimeChartReportModel;
}

export interface GetLaytimeChartForDealReportOutput {
    laytimeChartForDealReport: laytimeChartReportListModel;
}

export const GET_LAYTIME_CHART_SPLIT_REPORT = gql`
  query getLaytimeChartForSplitReport($splitQuantityId: UUID!) {
    laytimeChartForSplitReport(splitQuantityId: $splitQuantityId) {
      deal
      trafficSpecialist
      vessel
      client
      product
      loadingSupplier
      loadingReceiver
      loadingShipowner
      unloadingSupplier
      unloadingReceiver
      unloadingShipowner
      currency
      incoTerm
      trader
      company
      countryOfDestination
      blDate
      settleWithSupplier
      settleWithReceiver
      settleWithShipowner
      loadingPort
      unloadingPort
    }
  }`;

export const GET_LAYTIME_CHART_DEAL_REPORT = gql`
  query getLaytimeChartForDealReport($dealId: UUID!) {
    laytimeChartForDealReport(dealId: $dealId) {
      voyageName
      vessel
      results {
        deal
        trafficSpecialist
        vessel
        client
        product
        loadingSupplier
        loadingReceiver
        loadingShipowner
        unloadingSupplier
        unloadingReceiver
        unloadingShipowner
        currency
        incoTerm
        trader
        company
        countryOfDestination
        blDate
        settleWithSupplier
        settleWithReceiver
        settleWithShipowner
        loadingPort
        unloadingPort
      }
    }
  }
`;
