import axios from 'axios';
import { invitationModel } from '../../model';
import { apiBaseUrl } from '../../shared/links';

export const invitation = async (id: string) => {
  try {
    const response = await axios.get<invitationModel>(`${apiBaseUrl}/api/invitation/${id}`);
    return response.data;
  } catch (error: any) {
    console.error(error);
  }
  return undefined;
};
