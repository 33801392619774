import { Divider, IconButton, Link, Stack, Typography } from '@mui/material';
import { maritimeDirectoryModel } from 'model';
import { useGlobalStyles } from 'pages/globalStyles';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_COUNTRY, TXT_NOTES, TXT_PORTS } from '../../../../../../shared/translations';
import GradeIcon from '@mui/icons-material/Grade';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';

interface MaritimeDirectoryNameColumnProps {
  data: maritimeDirectoryModel;
  onUpdate: () => void;
  onDelete: () => void;
  showActions?: boolean;
}

export const MaritimeDirectoryNameColumn = (props: MaritimeDirectoryNameColumnProps) => {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();

  return (
    <Stack
      direction="column"
      component="div"
      onMouseOver={() => setIsHover(() => true)}
      onMouseOut={() => setIsHover(() => false)}
      sx={{
        width: '100%',
        height: '100%',
        paddingTop: '10px'
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{props.data.counterParty?.name}</Typography>
        {props.showActions && (
          <IconButton
            className={globalStyles.editButton}
            aria-label="update maritime directory"
            component="span"
            onClick={props.onUpdate}
            sx={{
              visibility: isHover ? 'visible' : 'hidden'
            }}
            size="small"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      {(props.data.counterParty?.dealPopularity || 0) > 0 ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <GradeIcon />
          <Typography variant="body1">{(props.data.counterParty?.dealPopularity || 0).toLocaleString()}</Typography>
        </Stack>
      ) : null}
      <Typography fontSize={'18px'}>
        {`${capitalizeFirstLetterEveryword(t(TXT_PORTS))}: ${(props.data.ports || []).map((x) => x.name).join(', ')}`}
      </Typography>
      <Typography fontSize={'18px'}>
        {`${capitalizeFirstLetterEveryword(t(TXT_COUNTRY))}: ${_.uniq(
          (props.data.ports || []).map((x) => x.country ?? '')
        ).join(', ')}`}
      </Typography>
      <Divider />
      <br></br>
      <Typography variant="subtitle1">{props.data.contact?.name}</Typography>
      <br></br>
      {`${capitalizeFirstLetterEveryword(t(TXT_NOTES))}:`}
      <Link
        href="#"
        sx={{
          textDecoration: 'none',
          cursor: 'default'
        }}
      >
        {props.data.contact?.notes}
      </Link>
      <Stack direction="row" justifyContent="space-between">
        <div></div>
        {props.showActions && (
          <IconButton
            className={globalStyles.deleteButton}
            aria-label="delete maritime directory"
            component="span"
            onClick={props.onDelete}
            sx={{
              visibility: isHover ? 'visible' : 'hidden'
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
