import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useOcrScanWizardStyle = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      height: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      height: '88vh'
    },
    dropzone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    letters: {
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none'
    }
  })
);
