import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { changePasswordInput } from 'api';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface ForgotPasswordFormProps {
  txtPassword: string;
  txtRePassword: string;
  txtSubmit: string;
  fontSize: string;
  isSubmitting: boolean;
  onSubmit: (data: changePasswordInput) => void;
}

const schema = yup.object().shape({
  password: yup.string().required().default(''),
  repassword: yup
    .string()
    .required()
    .default('')
    .oneOf([yup.ref('password'), null])
});

export const ChangePasswordForm = ({
  txtPassword,
  txtRePassword,
  txtSubmit,
  isSubmitting,
  onSubmit
}: ForgotPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<changePasswordInput>({
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={txtPassword}
        type="password"
        autoComplete="password"
        autoFocus
        {...register('password', { required: true })}
        error={!!errors.password}
        disabled={isSubmitting}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={txtRePassword}
        type="password"
        autoComplete="repassword"
        {...register('repassword', { required: true })}
        error={!!errors.repassword}
        disabled={isSubmitting}
      />
      <Button type="submit" fullWidth variant="contained" color="primary" sx={{ marginY: 2 }} disabled={isSubmitting}>
        {txtSubmit}
      </Button>
    </form>
  );
};
