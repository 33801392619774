import { Alert, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useDemurragePageState, useDemurragePageDispatch, setProperty, getPropName } from '../DemurragePageContext';
import { useTranslation } from 'react-i18next';
import {
  TXT_BL_QUANTITY,
  TXT_DEMURRAGE_P_DAY,
  TXT_DESPATCH_P_DAY,
  TXT_DISCHARGE_RATE,
  TXT_LAYCAN_END,
  TXT_LAYCAN_START,
  TXT_LOADING_COMMENCED,
  TXT_LOADING_COMPLETED,
  TXT_LOADING_RATE,
  TXT_MV_ON_DEMURRAGE,
  TXT_NAME,
  TXT_NOR_TENDERED,
  TXT_PERMITTED_TIME,
  TXT_REAL_LT_BEGINS,
  TXT_LAYTIME_ENDS,
  TXT_TURN_TIME,
  TXT_UNLOADING_COMMENCED,
  TXT_UNLOADING_COMPLETED,
  TXT_VESSEL_INFORMATION,
  TXT_DAY,
  TXT_VALID_NOR,
  TXT_MODIFYING_LOCKED_CALC_VALIDATION,
  TXT_VESSEL_OUTSIDE_LAYCAN,
  TXT_EDIT
} from '../../../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import moment from 'moment';
import { formatDuration, getDateFormat, getDateTimeFormat, isVesselOutsideLaycan } from 'shared/utils';
import { NumberTextField, textFieldDefaultProps } from 'components';
import { useEffect, useState } from 'react';
import { useGlobalStyles } from 'pages/globalStyles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import _ from 'lodash';
import { VesselOverrideEditor } from '../VesselOverrideEditor';
import { useSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';

export const VesselInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { demurrageDeal, selectedLaytimeCalculation, selectedTab, selectedPort } = useDemurragePageState();
  const [auth] = useAuth();
  const { isGuest } = auth;
  const [isDisabled, setIsDisabled] = useState(
    !selectedLaytimeCalculation || selectedLaytimeCalculation.status !== 'DRAFT' || isGuest
  );

  const dispatch = useDemurragePageDispatch();
  const dateFormat = getDateFormat();
  const dateTimeFormat = getDateTimeFormat();
  const prop = getPropName(selectedTab);
  const globalStyles = useGlobalStyles();
  const isLoading = selectedTab === 'loading';
  const uom = selectedLaytimeCalculation?.massUnit ?? demurrageDeal?.massUnit;

  const lblCommenced = capitalizeFirstLetterEveryword(
    t(prop === 'loadingCalculations' ? TXT_LOADING_COMMENCED : TXT_UNLOADING_COMMENCED)
  );
  const lblCompleted = capitalizeFirstLetterEveryword(
    t(prop === 'loadingCalculations' ? TXT_LOADING_COMPLETED : TXT_UNLOADING_COMPLETED)
  );
  const lblDischargeRate = capitalizeFirstLetterEveryword(
    t(prop === 'loadingCalculations' ? TXT_LOADING_RATE : TXT_DISCHARGE_RATE)
  );

  const [openEditor, setOpenEditor] = useState(false);

  const onFinalResultEditor = () => {
    setOpenEditor(true);
  };

  const [showOutsideLaycanValidation, setShowOutsideLaycanValidation] = useState(isVesselOutsideLaycan(selectedPort, selectedLaytimeCalculation));

  const showLockedCalcValidation = () => {
    if (selectedLaytimeCalculation?.isCalculationOverriden) {
      enqueueSnackbar(
        <Typography variant="subtitle1">{_.capitalize(t(TXT_MODIFYING_LOCKED_CALC_VALIDATION))}</Typography>,
        {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 10000
        }
      );
    }
  };

  useEffect(() => {
    setIsDisabled(!selectedLaytimeCalculation || selectedLaytimeCalculation.status !== 'DRAFT' || isGuest);
  }, [selectedLaytimeCalculation]);

  useEffect(() => {
    setShowOutsideLaycanValidation(isVesselOutsideLaycan(selectedPort, selectedLaytimeCalculation));
  }, [
    selectedPort,
    selectedPort?.norTenderedDate,
    selectedLaytimeCalculation?.norTenderedDateOverride,
    selectedPort?.laycanEndDate,
    selectedLaytimeCalculation?.laycanEndDateOverride,
    selectedPort?.laycanStartDate,
    selectedLaytimeCalculation?.laycanStartDateOverride,
    selectedLaytimeCalculation?.laycanEndDate,
    selectedLaytimeCalculation?.laycanEndDateOverride
  ]);

  return (
    <div>
      {openEditor && <VesselOverrideEditor onClose={() => setOpenEditor(false)} />}
      <Grid
        key={selectedLaytimeCalculation?.id + '-' + selectedLaytimeCalculation?.despatchPerDay}
        container
        spacing={2}
        py={2}
      >
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <div>
              <Typography variant="h6" py={2}>
                {capitalizeFirstLetterEveryword(t(TXT_VESSEL_INFORMATION))}
              </Typography>
            </div>
            {!isDisabled && (
              <Tooltip title={capitalizeFirstLetterEveryword(t(TXT_EDIT))}>
                <IconButton
                  aria-label="editFinalResult"
                  className={globalStyles.editButton}
                  component="div"
                  onClick={onFinalResultEditor}
                  size="small"
                >
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>
            )}
            {showOutsideLaycanValidation && (
              <div>
                <Alert severity="warning" style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                  {TXT_VESSEL_OUTSIDE_LAYCAN}
                </Alert>
              </div>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            id="outlined-required"
            label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
            value={demurrageDeal?.vessel?.name || ''}
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          {isLoading && (
            <TextField
              {...textFieldDefaultProps}
              label={`${capitalizeFirstLetterEveryword(t(TXT_LAYCAN_START))} (${dateFormat})${
                !!selectedLaytimeCalculation?.laycanStartDateOverride ? '*' : ''
              }`}
              value={
                selectedLaytimeCalculation?.laycanStartDateOverride ||
                selectedLaytimeCalculation?.laycanStartDate ||
                selectedPort?.laycanStartDate
                  ? moment(
                    selectedLaytimeCalculation?.laycanStartDateOverride ||
                        selectedLaytimeCalculation?.laycanStartDate ||
                        selectedPort?.laycanStartDate
                  ).format(dateFormat)
                  : ''
              }
              InputProps={{
                readOnly: true
              }}
              fullWidth
              variant={isDisabled ? 'standard' : 'filled'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          {isLoading && (
            <TextField
              {...textFieldDefaultProps}
              label={`${capitalizeFirstLetterEveryword(t(TXT_LAYCAN_END))} (${dateFormat})${
                !!selectedLaytimeCalculation?.laycanEndDateOverride ? '*' : ''
              }`}
              value={
                selectedLaytimeCalculation?.laycanEndDateOverride ||
                selectedLaytimeCalculation?.laycanEndDate ||
                selectedPort?.laycanEndDate
                  ? moment(
                    selectedLaytimeCalculation?.laycanEndDateOverride ||
                        selectedLaytimeCalculation?.laycanEndDate ||
                        selectedPort?.laycanEndDate
                  ).format(dateFormat)
                  : ''
              }
              InputProps={{
                readOnly: true
              }}
              fullWidth
              variant={isDisabled ? 'standard' : 'filled'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${capitalizeFirstLetterEveryword(t(TXT_BL_QUANTITY))}${
              !!selectedLaytimeCalculation?.quantityOverride ? '*' : ''
            }`}
            value={(
              selectedLaytimeCalculation?.quantityOverride ||
              selectedLaytimeCalculation?.quantity ||
              selectedPort?.quantity
            )?.toLocaleString()}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="start">{uom}</InputAdornment>
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <NumberTextField
            {...textFieldDefaultProps}
            label={lblDischargeRate}
            id="discharge-rate"
            InputProps={{
              readOnly: isDisabled,
              endAdornment: <InputAdornment position="start">{`${uom}/${_.capitalize(t(TXT_DAY))}`}</InputAdornment>
            }}
            defaultValue={selectedLaytimeCalculation?.dischargeRate || ''}
            onBlur={(e) => {
              dispatch(setProperty('dischargeRate', parseFloat(e.target.value)));
              showLockedCalcValidation();
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'outlined'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <NumberTextField
            {...textFieldDefaultProps}
            label={capitalizeFirstLetterEveryword(t(TXT_DEMURRAGE_P_DAY))}
            id="demurrage-per-day"
            InputProps={{
              readOnly: isDisabled,
              endAdornment: <InputAdornment position="start">{selectedLaytimeCalculation?.currency}</InputAdornment>
            }}
            defaultValue={selectedLaytimeCalculation?.demurragePerDay || ''}
            onBlur={(e) => {
              const val = parseFloat(e.target.value);
              dispatch(setProperty('demurragePerDay', val));
              dispatch(setProperty('despatchPerDay', val / 2));
              showLockedCalcValidation();
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'outlined'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <NumberTextField
            {...textFieldDefaultProps}
            label={capitalizeFirstLetterEveryword(t(TXT_DESPATCH_P_DAY))}
            id="despatch-per-day"
            InputProps={{
              readOnly: isDisabled,
              endAdornment: <InputAdornment position="start">{selectedLaytimeCalculation?.currency}</InputAdornment>
            }}
            defaultValue={selectedLaytimeCalculation?.despatchPerDay || ''}
            onBlur={(e) => {
              dispatch(setProperty('despatchPerDay', parseFloat(e.target.value)));
              showLockedCalcValidation();
            }}
            variant={isDisabled ? 'standard' : 'outlined'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <NumberTextField
            {...textFieldDefaultProps}
            label={capitalizeFirstLetterEveryword(t(TXT_TURN_TIME))}
            id="turn-time"
            InputProps={{
              readOnly: isDisabled
            }}
            defaultValue={selectedLaytimeCalculation?.turnTime || ''}
            disabled={!selectedLaytimeCalculation}
            onBlur={(e) => {
              dispatch(setProperty('turnTime', parseFloat(e.target.value)));
              showLockedCalcValidation();
            }}
            variant={isDisabled ? 'standard' : 'outlined'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${lblCommenced} (${dateTimeFormat})${
              !!selectedLaytimeCalculation?.commencedDateOverride ? '*' : ''
            }`}
            value={
              selectedLaytimeCalculation?.commencedDateOverride ||
              selectedLaytimeCalculation?.commencedDate ||
              selectedPort?.commencedDate
                ? moment(
                  selectedLaytimeCalculation?.commencedDateOverride ||
                      selectedLaytimeCalculation?.commencedDate ||
                      selectedPort?.commencedDate
                ).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${lblCompleted} (${dateTimeFormat})${
              !!selectedLaytimeCalculation?.completedDateOverride ? '*' : ''
            }`}
            value={
              selectedLaytimeCalculation?.completedDateOverride ||
              selectedLaytimeCalculation?.completedDate ||
              selectedPort?.completedDate
                ? moment(
                  selectedLaytimeCalculation?.completedDateOverride ||
                      selectedLaytimeCalculation?.completedDate ||
                      selectedPort?.completedDate
                ).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={capitalizeFirstLetterEveryword(t(TXT_PERMITTED_TIME))}
            id="permitted-time"
            value={
              selectedLaytimeCalculation?.permittedTime ? formatDuration(selectedLaytimeCalculation.permittedTime) : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${capitalizeFirstLetterEveryword(t(TXT_MV_ON_DEMURRAGE))} (${dateTimeFormat})`}
            value={
              selectedLaytimeCalculation?.mvOnDemurrage
                ? moment(selectedLaytimeCalculation.mvOnDemurrage).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${capitalizeFirstLetterEveryword(t(TXT_NOR_TENDERED))} (${dateTimeFormat})${
              !!selectedLaytimeCalculation?.norTenderedDateOverride ? '*' : ''
            }`}
            value={
              selectedLaytimeCalculation?.norTenderedDateOverride ||
              selectedLaytimeCalculation?.norTenderedDate ||
              selectedPort?.norTenderedDate
                ? moment(
                  selectedLaytimeCalculation?.norTenderedDateOverride ||
                      selectedLaytimeCalculation?.norTenderedDate ||
                      selectedPort?.norTenderedDate
                ).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${capitalizeFirstLetterEveryword(t(TXT_VALID_NOR))} (${dateTimeFormat})${
              !!selectedLaytimeCalculation?.validNorOverride ? '*' : ''
            }`}
            value={
              selectedLaytimeCalculation?.validNorOverride ||
              selectedLaytimeCalculation?.validNor ||
              selectedPort?.validNor
                ? moment(
                  selectedLaytimeCalculation?.validNorOverride ||
                      selectedLaytimeCalculation?.validNor ||
                      selectedPort?.validNor
                ).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          <TextField
            {...textFieldDefaultProps}
            label={`${capitalizeFirstLetterEveryword(t(TXT_REAL_LT_BEGINS))} (${dateTimeFormat})${
              !!selectedLaytimeCalculation?.realLaytimeBeginsOverride ? '*' : ''
            }`}
            value={
              selectedLaytimeCalculation?.realLaytimeBeginsOverride || selectedLaytimeCalculation?.realLaytimeBegins
                ? moment(
                  selectedLaytimeCalculation?.realLaytimeBeginsOverride ||
                      selectedLaytimeCalculation?.realLaytimeBegins
                ).format(dateTimeFormat)
                : ''
            }
            InputProps={{
              readOnly: true
            }}
            fullWidth
            variant={isDisabled ? 'standard' : 'filled'}
            helperText={
              selectedLaytimeCalculation?.laytimeTimeEndsOverride
                ? TXT_LAYTIME_ENDS + ': ' + selectedLaytimeCalculation?.laytimeTimeEndsOverride
                : undefined
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
