import { TXT_API_KEY, TXT_DELETE, TXT_NAME } from '../../../../../shared/translations';

export interface IntegrationColumn {
  id: 'name' | 'key' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const IntegrationColumns: IntegrationColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'key',
    label: TXT_API_KEY,
    maxWidth: '60%',
    align: 'left'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%'
  }
];
