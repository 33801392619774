import { Button, CircularProgress, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { validateEmail } from 'shared/utils';

export interface LoginFormProps {
  txtUsername: string;
  txtPassword: string;
  txtSigningIn: string;
  txtSignIn: string;
  isSigningIn: boolean;
  fontSize: string;
  onSubmit: (data: LoginInputs) => void;
}

export type LoginInputs = {
  username: string;
  password: string;
};

export const LogInForm = ({
  txtUsername,
  txtPassword,
  isSigningIn,
  txtSignIn,
  txtSigningIn,
  fontSize,
  onSubmit
}: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginInputs>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={txtUsername}
        autoComplete="username"
        autoFocus
        {...register('username', { required: true, validate: validateEmail })}
        error={!!errors.username}
        disabled={isSigningIn}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={txtPassword}
        type="password"
        autoComplete="current-password"
        {...register('password', { required: true })}
        disabled={isSigningIn}
      />
      <Button type="submit" fullWidth variant="contained" color="primary" sx={{ marginY: 2 }} disabled={isSigningIn}>
        {isSigningIn && <CircularProgress color="secondary" style={{ marginRight: '10px' }} size={fontSize} />}
        {isSigningIn ? txtSigningIn : txtSignIn}
      </Button>
    </form>
  );
};
