import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_STATUS_COMMENT } from '../../../../../shared/translations';

interface CalculationStatusCommentSelectorProps {
  value?: string;
  onChanged?: (newStatus: string) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
  hideLabel?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
}

export const CalculationStatusCommentSelector = (props: CalculationStatusCommentSelectorProps) => {
  const id = props.id || 'status-comment';
  const labelId = `${id}-lbl-status-comment`;

  const { t } = useTranslation();

  const handleStatusChange = (e: any) => {
    props.onChanged && props.onChanged(e.target.value);
  };

  const lblStatus = !!props.hideLabel ? '' : capitalizeFirstLetterEveryword(t(props.label || TXT_STATUS_COMMENT));

  return (
    <FormControl fullWidth size="small" style={props.style} error={props.error}>
      <InputLabel id={labelId}>{lblStatus}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={lblStatus}
        value={props.value ?? ''}
        disabled={props.disabled}
        onChange={handleStatusChange}
      >
        <MenuItem value={''}>&nbsp;</MenuItem>
        <MenuItem value={'Waiting for response'}>Waiting for response</MenuItem>
        <MenuItem value={'Holding despatch'}>Holding despatch</MenuItem>
        <MenuItem value={'Holding demurrage (FOB)'}>Holding demurrage (FOB)</MenuItem>
        <MenuItem value={'Free despatch'}>Free despatch</MenuItem>
        <MenuItem value={'Free demurrage'}>Free demurrage</MenuItem>
        <MenuItem value={'Waiting for traders approval'}>Waiting for traders approval</MenuItem>
        <MenuItem value={'Waived / Absorbed'}>Waived / Absorbed</MenuItem>
        <MenuItem value={'Waiting to receive debit note'}>Waiting to receive debit note</MenuItem>
        <MenuItem value={'Disagree'}>Disagree</MenuItem>
        <MenuItem value={'Customary Quick Despatch (CQD)'}>CQD</MenuItem>
      </Select>
    </FormControl>
  );
};
