import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { cognitiveReadOperationResultExt } from 'components';
import { DateTimePicker } from 'components/DateTimePicker/DateTimePicker';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { getDateFormat } from 'shared/utils';
import {
  TXT_SPLIT,
  TXT_VALUE,
  TXT_TYPE,
  TXT_DATE,
  TXT_NUMBER,
  TXT_TEXT,
  TXT_CANCEL,
  TXT_SAVE
} from '../../../../../../shared/translations';

interface SplitDialogProps {
  handleCloseDialog: () => void;
  handleSuccessSplit: (items: cognitiveReadOperationResultExt[]) => void;
  item?: cognitiveReadOperationResultExt;
}

export const SplitDialog = (props: SplitDialogProps) => {
  const { t } = useTranslation();

  const dateFormat = getDateFormat();

  const [items, setItems] = useState<cognitiveReadOperationResultExt[]>(
    props.item ? _.split(props.item.value, ' ').map((text) => ({ value: text, type: 'System.String' })) : []
  );

  const onSave = () => {
    props.handleSuccessSplit && props.handleSuccessSplit(items);
    props.handleCloseDialog();
  };

  const handleAddValue = () => {
    setItems(() => [...items, { value: '', type: 'System.String' }]);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newValue = event.target.value;
    const updatedSplitItems = [...items];
    updatedSplitItems[index].value = newValue;
    setItems(updatedSplitItems);
  };

  const handleDateChange = (value: moment.Moment | undefined, index: number) => {
    const updatedSplitItems = [...items];
    updatedSplitItems[index].value = value ? value.toISOString() : '';
    setItems(updatedSplitItems);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>, index: number) => {
    const newType = event.target.value;
    const updatedSplitItems = [...items];
    updatedSplitItems[index].type = newType;
    setItems(updatedSplitItems);
  };

  const handleDelete = (indexToDelete: number) => {
    const updatedSplitItems = items.filter((_, index) => index !== indexToDelete);
    setItems(updatedSplitItems);
  };

  return (
    <Dialog open={true} onClose={props.handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{capitalizeFirstLetterEveryword(t(TXT_SPLIT))}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button onClick={handleAddValue} component="label" variant="contained" startIcon={<AddIcon />}>
                  {capitalizeFirstLetterEveryword(t(TXT_SPLIT))}
                </Button>
              </Grid>
            </Grid>

            {items &&
              items.map((item, index) => (
                <Grid key={index} container spacing={2} style={{ marginTop: '5px' }}>
                  <Grid item xs={6}>
                    {item.type === 'System.String' && (
                      <TextField
                        style={{ width: '100%' }}
                        label={capitalize(t(TXT_VALUE))}
                        value={item.value}
                        onChange={(event) => handleTextFieldChange(event, index)}
                      />
                    )}
                    {item.type === 'System.DateTime' && (
                      <DateTimePicker
                        label={capitalize(t(TXT_VALUE))}
                        value={moment(item.value)}
                        dateFormat={dateFormat}
                        onChange={(value) => handleDateChange(value, index)}
                      />
                    )}
                    {item.type === 'System.Int32' && (
                      <TextField
                        style={{ width: '100%' }}
                        label={capitalize(t(TXT_VALUE))}
                        value={_.toNumber(item.value)}
                        onChange={(event) => handleTextFieldChange(event, index)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="lbl-search-deal-status">{capitalize(t(TXT_TYPE))}</InputLabel>
                      <Select
                        labelId="dlbl-search-deal-status"
                        id="demo-simple-select"
                        value={item.type}
                        label="Type"
                        onChange={(event) => handleSelectChange(event, index)}
                      >
                        <MenuItem value={'System.DateTime'}>{capitalizeFirstLetterEveryword(t(TXT_DATE))}</MenuItem>
                        <MenuItem value={'System.Int32'}>{capitalizeFirstLetterEveryword(t(TXT_NUMBER))}</MenuItem>
                        <MenuItem value={'System.String'}>{capitalizeFirstLetterEveryword(t(TXT_TEXT))}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog}>{capitalize(t(TXT_CANCEL))}</Button>
        <Button onClick={onSave}>{capitalize(t(TXT_SAVE))}</Button>
      </DialogActions>
    </Dialog>
  );
};
