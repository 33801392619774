import { useMutation } from '@apollo/client';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Toolbar
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import {
  TXT_CANCEL,
  TXT_CREATE_PORT,
  TXT_FIELD_IS_REQUIRED,
  TXT_LATITUDE,
  TXT_LONGITUDE,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING,
  TXT_UPDATE_PORT
} from '../../../../../shared/translations';
import { AddPortInput, ADD_PORT, UPDATE_PORT, PortMutationOutput } from '../../api';
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { portModel } from 'model';
import { CountrySelector } from 'components';

export interface PortEditorProps extends portModel {
  onClose: () => void;
  afterSave: (newPort: portModel) => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  country: yup.string().required(),
  latitude: yup.number().nullable().transform((value) => isNaN(value) ? null : value),
  longitude: yup.number().nullable().transform((value) => isNaN(value) ? null : value)
});

export const PortEditor = (props: PortEditorProps) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<AddPortInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      country: props.country || 'US',
      latitude: props.latitude,
      longitude: props.longitude
    }
  });
  useWatch({ control });
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutatePort, { loading }] = useMutation<PortMutationOutput>(props.id ? UPDATE_PORT : ADD_PORT, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data.port);
      props.onClose();
    }
  });

  const onSubmit: SubmitHandler<AddPortInput> = (data) => {
    mutate(data);
  };

  const mutate = (input: AddPortInput) => {
    if (!props.id) {
      return mutatePort({
        variables: {
          input
        }
      });
    }

    return mutatePort({
      variables: {
        input: {
          id: props.id,
          ...input
        }
      }
    });
  };

  const txtErrorField = _.capitalize(t(TXT_FIELD_IS_REQUIRED));
  const title = t(props.id ? TXT_UPDATE_PORT : TXT_CREATE_PORT);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{title.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
            e.stopPropagation();
          }}
          id="port-editor-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                autoFocus
                id="name"
                label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                fullWidth
                disabled={loading}
                error={!!errors.name}
                helperText={errors.name && txtErrorField}
                defaultValue={props.name}
                {...register('name', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="country"
                control={control}
                render={() => (
                  <CountrySelector
                    country={getValues().country}
                    onSelectedChange={(country) => {
                      setValue('country', country === null ? '' : country);
                    }}
                    error={!!errors.country}
                    disabled={loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={capitalizeFirstLetterEveryword(t(TXT_LATITUDE))}
                {...register('latitude')}
                fullWidth
                disabled={loading}
                error={!!errors.latitude}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={capitalizeFirstLetterEveryword(t(TXT_LONGITUDE))}
                {...register('longitude')}
                fullWidth
                disabled={loading}
                error={!!errors.longitude}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" disabled={loading}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="port-editor-form" disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
