import { GridOverlay, GridOverlayProps } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_NO_ROWS } from '../../../../../shared/translations';

export const GridNoResultsOverlay = React.forwardRef<HTMLDivElement, GridOverlayProps>(function GridNoResultsOverlay(
  props,
  ref
) {
  const { t } = useTranslation();
  const noResultsOverlayLabel = capitalizeFirstLetterEveryword(t(TXT_NO_ROWS));

  return (
    <GridOverlay ref={ref} {...props}>
      {noResultsOverlayLabel}
    </GridOverlay>
  );
});
