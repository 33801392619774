import { AddDealInput } from 'api';
import { useWatch, useFormContext, Controller } from 'react-hook-form';
import { PortSelector } from 'components/PortSelector';
import { TXT_UNLOADING_PORT } from '../../../../../shared/translations';
import { t } from 'i18next';
import { useContext } from 'react';
import { QuoteWizardContext } from './QuoteWizard';

interface QuoteWizardUnlodingPortProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const QuoteWizardUnlodingPort = (props: QuoteWizardUnlodingPortProps) => {
  const { control } = useFormContext<AddDealInput>();
  const { onUnloadingPortChanged, isDisabled } = useContext(QuoteWizardContext);

  const watchUnloadingPort = useWatch({
    control,
    name: `unloadingPorts.${props.index}`
  });

  return (
    <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
      <Controller
        control={control}
        name={`unloadingPorts.${props.index}.portId`}
        render={({ formState }) => (
          <PortSelector
            label={t(TXT_UNLOADING_PORT)}
            port={watchUnloadingPort.port}
            onSelectedChange={onUnloadingPortChanged(props.index)}
            error={!!formState.errors.unloadingPorts?.[props.index]?.portId}
            disabled={isDisabled}
          />
        )}
      />
    </fieldset>
  );
};
