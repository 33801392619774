import { useMutation } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { DocumentNode } from 'graphql';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useTranslation } from 'react-i18next';
import { TXT_DELETE_CONFIRMATION, TXT_NO, TXT_SAVING, TXT_YES } from '../../../../../shared/translations';

export interface DeleteModalProps {
  onCancel: () => void;
  afterSaving: () => void;
  txtConfirmation: string;
  mutation: DocumentNode;
  id?: string;
  input?: {};
}

export const DeleteModal = ({ txtConfirmation, onCancel, id, input, mutation, afterSaving }: DeleteModalProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [mutate, { loading }] = useMutation(mutation, {
    variables: {
      input: !!input
        ? { ...input }
        : {
          id
        }
    },
    onError: apolloErrorHandler,
    onCompleted: () => {
      afterSaving();
      onCancel();
    }
  });

  const onSubmit = () => {
    mutate();
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>{t(TXT_DELETE_CONFIRMATION).toUpperCase()}</DialogTitle>
      <DialogContent>{txtConfirmation}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" disabled={loading}>
          {t(TXT_NO)}
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_YES)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
