import { HTMLAttributes } from 'react';
import { useCustomCardContainerStyles } from './customCardContainerStyle';

// eslint-disable-next-line max-len
export const CustomCardContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  const style = useCustomCardContainerStyles();
  return (
    <div
      className={style.container}
      {...rest}
    >
      {children}
    </div>
  );
};
