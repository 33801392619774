import { Button, CircularProgress, Paper, Stack, capitalize } from '@mui/material';
import {
  TXT_CANCEL,
  TXT_RESOLVE,
  TXT_SEND,
  TXT_TYPE_COMMENT_HERE,
  TXT_TYPE_REPLY_HERE,
  TXT_UNRESOLVE
} from '../../../../../shared/translations';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { AddBaseCommentInput, UpdateBaseCommentInput, baseCommentOutput } from 'api';
import { useApolloErrorHandler } from 'hooks';
import { useContext, useState } from 'react';
import { CommentContext } from 'components/CommentButton';
import { baseCommentModel } from 'model';
import useAuth from 'hooks/useAuth';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import _ from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserMention } from 'components/UserMention';

interface CommentBoxProps<T extends baseCommentModel> extends baseCommentModel {
  parentComment?: T;
  afterSend: (input: T) => void;
  onCancelEditMode?: () => void;
}

export const CommentBox = <T extends baseCommentModel>(props: CommentBoxProps<T>) => {
  const { addCommentMutation, updateCommentMutation, entityId } = useContext(CommentContext);
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const [message, setMessage] = useState<string>(props.message || '');

  const [sendMessage, { loading: sendingMessage }] = useMutation<baseCommentOutput<T>>(
    !!props.id ? updateCommentMutation! : addCommentMutation!,
    {
      onCompleted: (data) => {
        props.afterSend(data.result);
        !props.id && setMessage(() => '');
      },
      onError: apolloErrorHandler
    }
  );

  const [updateResolve, { loading: updatingResolve }] = useMutation<baseCommentOutput<T>>(updateCommentMutation!, {
    onCompleted: (data) => {
      props.afterSend(data.result);
    },
    onError: apolloErrorHandler
  });

  const sendComment = () => {
    if ((message?.length || 0) <= 0) {
      return;
    }

    let input: AddBaseCommentInput | UpdateBaseCommentInput = {
      senderId: props.senderId || auth.id,
      message: message?.trim(),
      entityId: props.entityId || entityId,
      parentCommentId: props.parentCommentId || props.parentComment?.id,
      resolved: !!props.resolved
    };
    if (!!props.id) {
      input = {
        id: props.id,
        ...input
      };
    }

    sendMessage({
      variables: {
        input
      }
    });
  };

  const changeResolve = () => {
    if (!props.parentComment?.id) {
      return;
    }
    const input: UpdateBaseCommentInput = {
      ..._.omit(props.parentComment, ['sender', 'createdDate']),
      id: props.parentComment.id,
      resolved: !props.parentComment.resolved
    };

    updateResolve({
      variables: {
        input
      }
    });
  };

  return (
    <Paper
      sx={(theme) => ({
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: 'none'
      })}
    >
      <Stack direction="column" spacing={1}>
        <UserMention
          value={message}
          onChange={(e) => setMessage(() => e)}
          placeholder={capitalize(t(!props.parentComment ? TXT_TYPE_COMMENT_HERE : TXT_TYPE_REPLY_HERE))}
          disabled={sendingMessage}
        />
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {!!props.parentComment ? (
            <Button
              variant="outlined"
              startIcon={
                updatingResolve ? (
                  <CircularProgress size={20} />
                ) : !!props.parentComment.resolved ? (
                  <RemoveDoneIcon />
                ) : (
                  <DoneAllIcon />
                )
              }
              onClick={changeResolve}
              disabled={updatingResolve}
              size="small"
              color={!!props.parentComment.resolved ? 'error' : 'success'}
            >
              {capitalizeFirstLetterEveryword(t(!!props.parentComment.resolved ? TXT_UNRESOLVE : TXT_RESOLVE))}
            </Button>
          ) : null}
          {props.onCancelEditMode ? (
            <Button
              variant="outlined"
              startIcon={<CancelIcon />}
              onClick={props.onCancelEditMode}
              size="small"
              color="error"
            >
              {capitalizeFirstLetterEveryword(t(TXT_CANCEL))}
            </Button>
          ) : null}
          <Button
            variant="outlined"
            endIcon={sendingMessage ? <CircularProgress size={15} /> : <SendIcon />}
            onClick={sendComment}
            disabled={sendingMessage || (message?.length || 0) <= 0}
            size="small"
          >
            {capitalizeFirstLetterEveryword(t(TXT_SEND))}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
