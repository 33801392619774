import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_COUNTRY } from '../../../../../shared/translations';
import { useApolloErrorHandler } from 'hooks';
import { GET_COUNTRIES, GetCountriesOutput } from 'api/graphql/country';
import { useLazyQuery } from '@apollo/client';

export interface CountrySelectorProps {
  label?: string;
  country?: string;
  error?: boolean;
  onSelectedChange: (country: string | null) => void;
  disabled?: boolean;
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const { t } = useTranslation();
  // const alpha2Codes = getCountryAplha2Codes();
  const [selected, setSelected] = useState<string | null>(props.country || null);
  const [options, setOptions] = useState<string[]>([]);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [getCountries, { loading }] = useLazyQuery<GetCountriesOutput>(GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      setOptions(data.countries);
    }
  });

  if (selected && !options.some((x) => selected)) {
    options.push(selected);
  }

  const onSelectedChange = (event: object, value: string | null) => {
    setSelected(() => value);
    props.onSelectedChange(value);
  };

  useEffect(() => {
    setSelected(() => props.country || null);
  }, [props.country]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Autocomplete
      id="country-selector"
      onChange={onSelectedChange}
      value={selected}
      options={options}
      fullWidth
      disabled={props.disabled}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_COUNTRY))}
          variant="outlined"
          error={props.error}
        />
      )}
    />
  );
};
