import { DatePicker } from '@mui/x-date-pickers';
import { Alert, Button, Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import { LaytimePerCounterpartyReportRequest } from 'hooks';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { TXT_ADD, TXT_ADD_COUNTERPARTIES_MESSAGE, TXT_COUNTERPARTY, TXT_FROM, TXT_INCLUDE_ALL_PARTIES, TXT_OPTIONAL, TXT_PORT, TXT_SELECTED_COUNTERPARTIES, TXT_TRADER, TXT_TRAFFIC_ANALYST, TXT_UNTIL } from '../../../../../shared/translations';
import { Control, Controller, FormState, UseFormSetValue, useWatch } from 'react-hook-form';
import moment from 'moment';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { counterPartyModel } from 'model';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TraderSelector } from 'components/TraderSelector';
import { UserSelector } from 'components/UserSelector';
import { PortSelector } from 'components/PortSelector';

interface LaytimePerCounterpartyReportFilterContentProps {
  control: Control<LaytimePerCounterpartyReportRequest, any>;
  setValue: UseFormSetValue<LaytimePerCounterpartyReportRequest>;
  formState: FormState<LaytimePerCounterpartyReportRequest>;
  disabled?: boolean;
  children?: ReactNode;
}

export const LaytimePerCounterpartyReportFilterContent = (props: LaytimePerCounterpartyReportFilterContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dateFormat = 'MM/YYYY';
  const [counterParty, setcounterParty] = useState<counterPartyModel | undefined>(undefined);
  const [selectedCounterParties, setSelectedCounterParties] = useState<counterPartyModel[]>([]);
  const watch = useWatch({
    control: props.control
  });

  const getFirstDay = (date: moment.Moment) => {
    if (date === null) {
      return null;
    }
    const year = date.year();
    const month = date.month();
    return new Date(year, month, 1);
  };

  const getLastDay = (date: moment.Moment) => {
    if (date === null) {
      return null;
    }
    const year = date.year();
    const month = date.month();
    const day = date.daysInMonth();
    return new Date(year, month, day);
  };

  const addCounterParty = () => {
    if (!counterParty || !counterParty.id) {
      return;
    }

    if (selectedCounterParties.some((x)=>x.id === counterParty.id)) {
      return; // element already exist
    }

    const newVal = [...selectedCounterParties.filter((x) => x.id)];
    newVal.push(counterParty);
    setNewCounterpartyCollection(newVal);
  };

  const removeCounterParty = (id: string) => {
    if (!id) {
      return;
    }
    let newVal = [...selectedCounterParties];
    newVal = newVal.filter((x)=> x.id !== id);

    setNewCounterpartyCollection(newVal);
  };

  const setNewCounterpartyCollection = (list:counterPartyModel[]) => {
    setSelectedCounterParties(list);
    props.setValue('counterParties', list.filter((x)=> x?.id).map((x)=>x.id ?? ''));

    props.control._updateValid();
  };

  const handleIncludeAllPartiesChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    props.setValue('showAllDealCounterparties', checked);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        marginTop: theme.spacing(2)
      }}
      width='100%'
    >
      <Controller
        control={props.control}
        name="startDate"
        render={() => (
          <DatePicker
            views={['month', 'year']}
            format={dateFormat}
            value={!watch.startDate ? null : moment(watch.startDate)}
            label={`${capitalizeFirstLetterEveryword(t(TXT_FROM))} (${dateFormat})`}
            onChange={(date) => props.setValue('startDate', getFirstDay(moment(date)))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!props.formState.errors.startDate
              }
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Controller
        control={props.control}
        name="endDate"
        render={() => (
          <DatePicker
            views={['month', 'year']}
            format={dateFormat}
            value={!watch.endDate ? null : moment(watch.endDate)}
            label={`${capitalizeFirstLetterEveryword(t(TXT_UNTIL))} (${dateFormat})`}
            onChange={(date) => props.setValue('endDate', getLastDay(moment(date)))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!props.formState.errors.endDate
              }
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Controller
        control={props.control}
        name="traderId"
        render={() => (
          <TraderSelector
            trader={undefined}
            label={`${t(TXT_TRADER)} (${t(TXT_OPTIONAL)})`}
            onChanged={(trader) => {
              props.setValue('traderId', trader?.id);
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Controller
        control={props.control}
        name="operatorId"
        render={() => (
          <UserSelector
            user={undefined}
            label={`${t(TXT_TRAFFIC_ANALYST)} (${t(TXT_OPTIONAL)})`}
            onChanged={(user) => {
              props.setValue('operatorId', user?.id);
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Controller
        control={props.control}
        name="portIds"
        render={() => (
          <PortSelector
            multiple={true}
            port={undefined}
            label={`${t(TXT_PORT)} (${t(TXT_OPTIONAL)})`}
            onSelectedPortsChange={(ports) => {
              props.setValue('portIds', ports?.map((x)=> x.id));
              props.control._updateValid();
            }}
            disabled={props.disabled}
          />
        )}
      />
      <Stack direction='column' spacing={2} width='100%'>
        <hr></hr>
        <Stack direction='row' spacing={2} width='100%'>
          <CounterPartySelector
            key={`cpSelector-' + ${(selectedCounterParties?.length??0)} `}
            onChanged={(cParty) => {
              setcounterParty(cParty);
            }}
            label={`${t(TXT_COUNTERPARTY)}`}
            counterPartyType="ALL"
          />
          <Button variant="contained" startIcon={<AddIcon />} onClick={addCounterParty}>{TXT_ADD}</Button>
        </Stack>
        {(selectedCounterParties?.length??0) > 0 && <Typography>{`${capitalizeFirstLetterEveryword(t(TXT_SELECTED_COUNTERPARTIES))}`}:</Typography>}
        {(selectedCounterParties?.length??0) === 0 && <Alert severity="warning"style={{ backgroundColor: 'rgba(0,0,0,0)' }} >{TXT_ADD_COUNTERPARTIES_MESSAGE}</Alert>}
        <div>
          {Object.values(selectedCounterParties).map((cParty, index)=> (
            <Stack key = {cParty?.id} direction='row' spacing={2} width='100%'>
              {cParty.id && <Button startIcon={<DeleteIcon />} onClick={()=>removeCounterParty(cParty.id ?? '')}></Button>}
              {cParty.id && <Typography>{cParty.name}</Typography>}
            </Stack>
          ))}
        </div>
        <hr></hr>
      </Stack>
      <FormControlLabel
        label={'' + t(TXT_INCLUDE_ALL_PARTIES)}
        control={<Checkbox onChange={handleIncludeAllPartiesChange}></Checkbox>}
      ></FormControlLabel>
      {props.children}
    </Stack>
  );
};
