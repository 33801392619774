import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_CURRENCY } from '../../../../../shared/translations';
// import currencyCodes from 'currency-codes';

export interface CurrencySelectorProps {
  label?: string;
  currency?: string;
  error?: boolean;
  onSelectedChange: (currency?: string) => void;
  disabled?: boolean;
}

export const CurrencySelector = (props: CurrencySelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string | undefined>(props.currency);
  // const options = currencyCodes.codes();
  const options = ['USD', 'EUR', 'CAD'];

  const onSelectedChange = (event: object, value?: string) => {
    setSelected(value);
    props.onSelectedChange(value);
  };

  useEffect(() => {
    setSelected(() => props.currency);
  }, [props.currency]);

  return (
    <Autocomplete
      id="currency-selector"
      onChange={onSelectedChange}
      value={selected}
      options={options}
      fullWidth
      disabled={props.disabled}
      disableClearable={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label === '' ? undefined : capitalizeFirstLetterEveryword(props.label || t(TXT_CURRENCY))}
          variant="outlined"
          error={props.error}
        />
      )}
    />
  );
};
