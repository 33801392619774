import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import dealPortCommentDto from 'model/dealPortCommentDto';
import DealPortCommentBox from './DealPortFormCommentBox';

interface DealPortFormCommentCardProps {
  comment: dealPortCommentDto;
  replies?: dealPortCommentDto[];
}

export const DealPortFormCommentCard = (props: DealPortFormCommentCardProps) => {
  return (
    <>
      <Card
        sx={(theme) => ({
          borderLeft: `2px solid ${theme.palette.divider}`,
          boxShadow: 'none',
          borderRadius: 0,
          overflow: 'initial'
        })}
        id={props.comment.id}
      >
        <CardContent>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Stack direction="column">
                <Typography variant="subtitle2" component="div">
                  {props.comment.user?.profile?.fullName || props.comment.counterParty?.name}
                </Typography>
                {(props.comment.email || '').trim().length > 0 ? (
                  <Typography variant="subtitle2" component="a" href={`mailto:${props.comment.email}`}>
                    {props.comment.email}
                  </Typography>
                ) : null}
              </Stack>
              <Typography variant="caption" component="div" fontStyle="italic">
                {moment(props.comment.createdDate).format('LL')}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Box
            component="pre"
            sx={(theme) => ({
              fontFamily: theme.typography.fontFamily
            })}
          >
            {props.comment.comment}
          </Box>
          {!_.isEmpty(props.replies) ? (
            <Box paddingLeft={(theme) => theme.spacing(3)} marginTop={(theme) => theme.spacing(1)}>
              {props.replies?.map((reply) => (
                <DealPortFormCommentCard key={reply.id} comment={reply} />
              ))}
            </Box>
          ) : null}
        </CardContent>
        {!props.comment.parentCommentId ? <DealPortCommentBox parentCommentId={props.comment.id} /> : null}
      </Card>
    </>
  );
};
