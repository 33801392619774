import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const usePaginationBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagingBar: {
      'display': 'inline-flex',
      '& > .MuiTablePagination-toolbar': {
        paddingLeft: theme.spacing(2)
      }
    }
  })
);
