import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import moment from 'moment';
import { CalculationEvent } from 'model';
import { format2Digits } from 'pages/DemurragePage/utils';
import { formatNumber } from 'shared/utils';

interface EventRowProps {
  event?: CalculationEvent;
  isBreak?: boolean;
  hideDate?: boolean;
  isHeaderRow?: boolean;
}

export const EventRow = ({ event, isBreak, hideDate, isHeaderRow }: EventRowProps) => {
  let date = 'Date';
  let daytext = '';
  let from = 'From';
  let to = 'To';
  let remarks: string | undefined = 'Remarks/Description';
  let timeUsed: string | number = 'Time used';
  let totalTime: string | number = 'Total time days hh:mm';
  let percentage: string | number = '% to count';
  let style: any = { ...styles.flexRow, borderBottom: 1, borderTop: 1, height: 28 };

  const styleNormal: any = { width: 260, paddingRight: 10 };
  const styleBold: any = { ...styleNormal, fontWeight: 'bold' };
  const isMilestone: boolean = event?.isMilestone ?? false;

  const alignment: string = isHeaderRow ? 'center' : 'left';
  const styleDate: any = { width: 20, textAlign: alignment };
  const styleDate2: any = { width: 80, textAlign: alignment, paddingRight: 20 };
  if (event) {
    daytext = moment(event.from).format('ddd');
    date = moment(event.from).format('MM/DD/YYYY');
    from = `${format2Digits(moment(event.from).hours())}:${format2Digits(moment(event.from).minutes())}`;
    to = `${format2Digits(moment(event.to).hours())}:${format2Digits(moment(event.to).minutes())}`;

    if (event.to > event.from && to === '00:00') {
      to = '24:00';
    }
    remarks = event.remarks;
    timeUsed = event.timeUsedFormatted || '';
    totalTime = event.cummulativeUsedTimeFormatted || '';
    percentage = formatNumber(event.percentageToCount) || '0.00';
    style = styles.flexRow;
  }
  return (
    <View style={style} break={isBreak}>
      <Text style={styleDate}>{hideDate ? '' : daytext}</Text>
      <Text style={styleDate2}>{hideDate ? '' : date}</Text>
      <Text style={{ width: 40 }}>{from}</Text>
      <Text style={{ width: 40 }}>{!isMilestone ? to : ''}</Text>
      <Text style={{ width: 40, paddingRight: 10, textAlign: 'right' }}>{!isMilestone ? percentage : ''}</Text>
      <Text style={!isMilestone ? styleNormal : styleBold}>{remarks}</Text>
      <Text style={{ width: 40, paddingRight: 10 }}>{!isMilestone ? timeUsed : ''}</Text>
      <Text style={{ width: 60 }}>{!isMilestone ? totalTime : ''}</Text>
    </View>
  );
};

EventRow.displayName = 'EventRow';
