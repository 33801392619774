import { gql } from '@apollo/client';
import { interruptionModel } from 'model';

export const getInterruptionsFragment = `
  id
  laytimeCalculationId
  description
  since
  to
  percentage
  countAsLaytime
  notCountAsLaytime
  toDiscount
`;

export type GetInterruptionsOutput = {
  interruptions: interruptionModel[];
};

export const GET_INTERUPTIONS = gql`
  query getInterruptions($where: InterruptionFilterInput) {
    interruptions(where: $where) {
      ${getInterruptionsFragment}
    }
  }
`;
