/* eslint-disable quote-props */
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const languagePopoverStyle = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    height: 20,
    width: 20,
    '& img': {
      width: '100%'
    },
    alignItems: 'center'
  },
  paper: {
    width: 240,
    minHeight: theme.spacing(9)
  }
}));

export default languagePopoverStyle;
