import { useMutation } from '@apollo/client';
import { FormHelperText, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import {
  BatchOverrideInput,
  BatchOverrideLaytimeCalculationInput,
  BatchOverrideOutput,
  BATCH_OVERRIDE_CALCULATIONS
} from 'api';
import { CalculationStatusCommentSelector } from 'components/CalculationStatusCommentSelector';
import { CalculationStatusSelector } from 'components/CalculationStatusSelector';
import { CustomTable } from 'components/CustomTable';
import { NumberTextField } from 'components/NumberTextField';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import _ from 'lodash';
import {
  batchCalculationSelectionModel,
  counterPartyModel,
  CounterPartyType,
  dealModel,
  dealPortModel,
  LaytimeCalculationStatus,
  splitQuantityModel
} from 'model';
import { getCounterPartyLabel, getIncoTerm } from 'pages/DemurragePage/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableCalculations } from 'shared/calculationUtils';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import shortUUID from 'short-uuid';
import {
  TXT_BATCH_OVERRIDE_TITLE,
  TXT_CALCULATION_RESULTS,
  TXT_CANCEL,
  TXT_COUNTERPARTY,
  TXT_CURRENCY,
  TXT_LOADING,
  TXT_PORT,
  TXT_SAVE,
  TXT_SAVING,
  TXT_SELECTED_ITEMS,
  TXT_SHIPOWNER_NOT_SET,
  TXT_STATUS,
  TXT_STATUS_COMMENT,
  TXT_UNLOADING
} from '../../../../../shared/translations';

export interface BatchOverrideEditorProps {
    onClose: () => void;
    afterSave: (data: BatchOverrideOutput) => void;
    deal: dealModel;
}

const findExistingStatus = (
  deal?: dealModel,
  port?: dealPortModel,
  counterParty?: counterPartyModel,
  counterPartyType?: CounterPartyType
): LaytimeCalculationStatus => {
  if (!port) return 'DRAFT';
  if (!counterParty) return 'DRAFT';
  if (!deal) return 'DRAFT';

  const calc = [...(deal.loadingCalculations || []), ...(deal.unloadingCalculations || [])].find(
    (x) =>
      x.counterPartyId === counterParty.id &&
            x.dealPortId === port.id &&
            x.counterPartyType?.name?.toUpperCase() === counterPartyType
  );

  return calc?.status || 'DRAFT';
};

const findExistingComment = (
  deal?: dealModel,
  port?: dealPortModel,
  counterParty?: counterPartyModel,
  counterPartyType?: CounterPartyType
): string => {
  if (!port) return '';
  if (!counterParty) return '';
  if (!deal) return '';

  const calc = [...(deal.loadingCalculations || []), ...(deal.unloadingCalculations || [])].find(
    (x) =>
      x.counterPartyId === counterParty.id &&
            x.dealPortId === port.id &&
            x.counterPartyType?.name?.toUpperCase() === counterPartyType
  );

  return calc?.statusComment || '';
};

const findExistingCurrency = (
  deal?: dealModel,
  port?: dealPortModel,
  counterParty?: counterPartyModel,
  counterPartyType?: CounterPartyType
): string => {
  if (!port) return 'USD';
  if (!counterParty) return 'USD';
  if (!deal) return 'USD';

  const calc = [...(deal.loadingCalculations || []), ...(deal.unloadingCalculations || [])].find(
    (x) =>
      x.counterPartyId === counterParty.id &&
            x.dealPortId === port.id &&
            x.counterPartyType?.name?.toUpperCase() === counterPartyType
  );

  return calc?.currency || 'USD';
};

const getNewId = () => `new-${shortUUID.generate()}`;

const findExistingValue = (
  deal?: dealModel,
  port?: dealPortModel,
  counterParty?: counterPartyModel,
  counterPartyType?: CounterPartyType
): number | null => {
  if (!port) return null;
  if (!counterParty) return null;
  if (!deal) return null;

  const calc = [...(deal.loadingCalculations || []), ...(deal.unloadingCalculations || [])].find(
    (x) =>
      x.counterPartyId === counterParty.id &&
            x.dealPortId === port.id &&
            x.counterPartyType?.name?.toUpperCase() === counterPartyType
  );

  if (!calc) {
    return null;
  }

  const isDemurrage = calc?.laytimeCalculationResult === 'DEMURRAGE';
  const calcValue = isDemurrage && !!calc.demurrageAmount ? calc.demurrageAmount * -1 : calc.despatchAmount;
  return calcValue || null;
};

const getItemsPerPort = (deal?: dealModel) => {
  if (!deal) return [];
  const selections = getAvailableCalculations(deal);
  return _.sortBy(
    selections.map((item) => {
      const result: batchCalculationSelectionModel = {
        ...item,
        id: getNewId(),
        newValue: findExistingValue(deal, item.dealPort, item.counterParty, item.counterPartyType),
        statusValue: findExistingStatus(deal, item.dealPort, item.counterParty, item.counterPartyType),
        statusComment: findExistingComment(deal, item.dealPort, item.counterParty, item.counterPartyType),
        currency: findExistingCurrency(deal, item.dealPort, item.counterParty, item.counterPartyType)
      };
      return result;
    }),
    'isLoading'
  );
};

export const BatchOverrideEditor = (props: BatchOverrideEditorProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [overrideDtos, setOverrideDtos] = useState<batchCalculationSelectionModel[]>(getItemsPerPort(props.deal));

  const theme = useTheme();

  const [mutateBatchOverride, { loading }] = useMutation<BatchOverrideOutput>(BATCH_OVERRIDE_CALCULATIONS, {
    onError: apolloErrorHandler,
    onCompleted: (data) => {
      props.afterSave(data);
      props.onClose();
    }
  });

  const handleNewValueChange = (id: string, value: number | null) => {
    setOverrideDtos((x) => {
      const newX = [...x];
      const dto = newX.findIndex((y) => y.id === id);
      newX[dto].newValue = value;

      return newX;
    });
  };

  const handleCheckboxChange = (id?: string) => (event: any) => {
    setOverrideDtos((x) => {
      const newX = [...x];
      const dto = newX.findIndex((y) => y.id === id);
      newX[dto].checked = event.target.checked;

      return newX;
    });
  };

  const handleStatusChange = (id?: string) => (newStatus: LaytimeCalculationStatus) => {
    setOverrideDtos((x) => {
      const newX = [...x];
      const dto = newX.findIndex((y) => y.id === id);
      newX[dto].statusValue = newStatus;

      return newX;
    });
  };

  const handleStatusCommentChange = (id?: string) => (newComment: string) => {
    setOverrideDtos((x) => {
      const newX = [...x];
      const dto = newX.findIndex((y) => y.id === id);
      newX[dto].statusComment = newComment;

      return newX;
    });
  };

  const handleCurrencyChange = (id: string, newCurrency: string) => {
    setOverrideDtos((x) => {
      const newX = [...x];
      const dto = newX.findIndex((y) => y.id === id);
      newX[dto].currency = newCurrency;

      return newX;
    });
  };

  const calcNewValueHint = (value?: number | null) => {
    let result: string = 'Unknown';
    if (value) {
      if (value >= 0) {
        result = 'Despatch';
      } else if (value < 0) {
        result = 'Demurrage';
      }
    }

    return result.padEnd(15);
  };

  const txtTitle = t(TXT_BATCH_OVERRIDE_TITLE).toUpperCase();

  const onSubmit = () => {
    const overrides = overrideDtos
      .filter((x) => x.checked)
      .map((x) => {
        const dto: BatchOverrideLaytimeCalculationInput = {
          counterPartyId: x.counterParty.id,
          counterPartyType: x.counterPartyType,
          dealPortId: x.dealPort.id,
          newValue: x.newValue,
          statusValue: x.statusValue,
          statusComment: x.statusComment,
          currency: x.currency,
          dealSupplierId: x.dealSupplier?.id,
          splitQuantityIds: !!x.splits && !_.isEmpty(x.splits) ? x.splits.map((split: splitQuantityModel) => split.id!) : undefined
        };
        return dto;
      });
    const batchInput: BatchOverrideInput = {
      dealId: props.deal.id,
      overrides: overrides
    };
    mutateBatchOverride({
      variables: {
        input: batchInput
      }
    });
  };

  return (
    <Dialog
      open={true}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
      PaperProps={{
        sx: {
          maxHeight: '88vh',
          height: '88vh'
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{txtTitle.toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        <CustomTable stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTY))}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_PORT))}</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>
                  {capitalizeFirstLetterEveryword(t(TXT_CALCULATION_RESULTS))}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_CURRENCY))}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_STATUS))}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{capitalizeFirstLetterEveryword(t(TXT_STATUS_COMMENT))}</Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {overrideDtos.map((menu: batchCalculationSelectionModel) => (
              <TableRow key={menu.id}>
                <TableCell>
                  <Checkbox checked={!!menu.checked} onChange={handleCheckboxChange(menu.id)}></Checkbox>
                </TableCell>
                <TableCell>
                  <Tooltip title={menu?.dealsHint ?? ''}>
                    <Typography>{menu.counterParty?.name}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography>
                    {capitalizeFirstLetterEveryword(t(!!menu.isLoading ? TXT_LOADING : TXT_UNLOADING)) + ' - ' + menu.dealPort?.port?.name}
                  </Typography>
                  <FormHelperText>{getCounterPartyLabel(menu.counterPartyType)}</FormHelperText>
                </TableCell>
                <TableCell>
                  <Stack direction='row' alignItems={'center'} spacing={1}>
                    <NumberTextField
                      fullWidth
                      size={'small'}
                      disabled={!menu.checked}
                      defaultValue={menu.newValue || null}
                      helperText={calcNewValueHint(menu.newValue)}
                      onChange={(e) =>
                        handleNewValueChange(menu.id, e.currentTarget.value ? parseFloat(e.currentTarget.value) : null)
                      }
                      style={{ maxWidth: '150px', minWidth: '150px' }}
                      inputProps={{
                        step: '0.001'
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Select
                    label={TXT_CURRENCY}
                    size={'small'}
                    value={menu?.currency ?? 'USD'}
                    disabled={!menu.checked}
                    onChange={(e) => handleCurrencyChange(menu.id, e.target.value)}
                  >
                    <MenuItem value={'USD'}>USD</MenuItem>
                    <MenuItem value={'EUR'}>EUR</MenuItem>
                    <MenuItem value={'CAD'}>CAD</MenuItem>
                  </Select>
                  <div>&nbsp;</div>
                </TableCell>
                <TableCell>
                  <CalculationStatusSelector
                    value={menu.statusValue}
                    onChanged={handleStatusChange(menu.id)}
                    disabled={!menu.checked}
                    style={{ maxWidth: '180px', minWidth: '180px' }}
                    hideLabel
                  />
                  <div>&nbsp;</div>
                </TableCell>
                <TableCell>
                  <CalculationStatusCommentSelector
                    value={menu.statusComment}
                    onChanged={handleStatusCommentChange(menu.id)}
                    disabled={!menu.checked}
                    style={{ maxWidth: '250px', minWidth: '250px' }}
                    hideLabel
                  />
                  <div>&nbsp;</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </CustomTable>
        {props.deal && !props.deal.shipowner && props.deal.splitQuantities?.some((split) => getIncoTerm(split) !== 'FOB') && (
          <Typography alignSelf={'center'} color={'red'} marginTop={'20px'}>
            {capitalizeFirstLetterEveryword(t(TXT_SHIPOWNER_NOT_SET))}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Button
            disabled
            style={{
              color: theme.palette.text.primary
            }}
          >
            {capitalizeFirstLetterEveryword(
              t(TXT_SELECTED_ITEMS, {
                count: overrideDtos.filter((dto) => !!dto.checked).length
              })
            )}
          </Button>
        </div>
        <div>
          <Button onClick={props.onClose} color="secondary" disabled={loading}>
            {t(TXT_CANCEL)}
          </Button>
          <Button onClick={onSubmit} disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
            {t(loading ? TXT_SAVING : TXT_SAVE)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
