import { useLazyQuery, useMutation } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import {
  GetLaytimeCalculationCalculatedOutput,
  GET_LAYTIME_CALCULATION_CALCULATED,
  CopyCalculationsOutput,
  COPY_LAYTIME_CALCULATION
} from 'api';
import { convertShexConditionsFromResponse } from 'components/PasteCalculationsModal/PasteCalculationSubmitButton';
import { useApolloErrorHandler } from 'hooks';
import {
  DemurragePageDispatchContext,
  refreshLaytimeCalculationsAfterSave,
  setInterruptions,
  setShexConditions,
  updateLaytimeCalculation
} from 'pages/DemurragePage/DemurragePageContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_CONFIRMATION, TXT_NO, TXT_SAVING, TXT_YES } from '../../../../../shared/translations';
import _ from 'lodash';

interface PromoteConfirmationProps {
  txtConfirmation: string;
  sourceCalculationId: string;
  targetCalculationId: string;
  afterSave: () => void;
  onCancel: () => void;
}

export const PromoteConfirmation = (props: PromoteConfirmationProps) => {
  const { t } = useTranslation();
  const { apolloErrorHandler } = useApolloErrorHandler();
  const dispatch = useContext(DemurragePageDispatchContext);

  const [loadLaytimeCalculationCalculatedFields] = useLazyQuery<GetLaytimeCalculationCalculatedOutput>(
    GET_LAYTIME_CALCULATION_CALCULATED,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: {
            eq: props.targetCalculationId
          }
        }
      },
      onError: apolloErrorHandler,
      onCompleted: (calculation) => {
        if (!dispatch || calculation.laytimeCalculations.length <= 0) {
          return;
        }
        dispatch(refreshLaytimeCalculationsAfterSave(calculation.laytimeCalculations[0]));
      }
    }
  );

  const [mutateCopyPaste, { loading }] = useMutation<CopyCalculationsOutput>(COPY_LAYTIME_CALCULATION, {
    onError: apolloErrorHandler,
    onCompleted: async (data) => {
      if (!!dispatch) {
        dispatch(setInterruptions(data.copyCalculations.interruptions));
        dispatch(setShexConditions(convertShexConditionsFromResponse(data.copyCalculations.shexConditions)));
        dispatch(updateLaytimeCalculation(data.copyCalculations.calculation));
        await loadLaytimeCalculationCalculatedFields();
      }
      props.afterSave();
      props.onCancel();
    }
  });

  const onSubmit = () => {
    mutateCopyPaste({
      variables: {
        input: {
          sourceId: props.sourceCalculationId,
          destinationId: props.targetCalculationId,
          copyInterruptions: true,
          copyShexs: true
        }
      }
    });
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>{t(TXT_CONFIRMATION).toUpperCase()}</DialogTitle>
      <DialogContent>{_.capitalize(t(props.txtConfirmation))}</DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="secondary" disabled={loading}>
          {t(TXT_NO)}
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          {loading && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_YES)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
