import { useLazyQuery } from '@apollo/client';
import { Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_PORT, GetPortsOutput, GET_PORTS } from 'api';
import { CountrySelector, DeleteModal, PaginationBar } from 'components';
import _ from 'lodash';
import { TXT_DELETE_TEXT, TXT_PORTS, TXT_SEARCH_BY_NAME } from '../../../../../shared/translations';
import { useGlobalStyles } from '../globalStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { usePortsStyle } from './portsStyle';
import { PortsTable } from './PortsTable';
import { PortEditor } from './PortEditor';
import { portModel } from 'model';
import { useDefaultSettings } from 'hooks/useDefaultSettings';
import { PageSizeOptions } from 'shared/constants';
import { useTitle } from 'hooks';
import useAuth from 'hooks/useAuth';

export const Ports = () => {
  useTitle(TXT_PORTS);
  const portsStyle = usePortsStyle();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
  const [countryFilter, setCountryFilter] = useState<string | null>(null);

  const [defaultSettings, setDefaultSettings] = useDefaultSettings();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedPorts, setSelectedPorts] = useState<portModel | undefined>(undefined);
  const { apolloErrorHandler } = useApolloErrorHandler();
  const [auth] = useAuth();
  const { isGuest } = auth;

  const getFilter = () => {
    let result = {};

    if (!_.isNil(nameFilter) && nameFilter.length > 0) {
      result = {
        ...result,
        name: {
          contains: nameFilter
        }
      };
    }

    if (!_.isNil(countryFilter)) {
      result = {
        ...result,
        country: {
          eq: countryFilter
        }
      };
    }

    return _.isEqual(result, {}) ? null : result;
  };

  const [getPorts, { loading, error, data, refetch }] = useLazyQuery<GetPortsOutput>(GET_PORTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: getFilter(),
      take: pageSize,
      skip: pageSize * (pageNumber - 1)
    },
    onError: apolloErrorHandler
  });

  useEffect(() => {
    setNameFilter(undefined);
  }, [t]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetch();
    }
  }, [nameFilter]);

  useEffect(() => {
    fetch();
  }, [pageSize, pageNumber]);

  useEffect(() => {
    setDefaultSettings({
      ...defaultSettings,
      pageSize
    });
  }, [pageSize]);

  const fetch = () => {
    if (!refetch) {
      getPorts();
    } else {
      refetch();
    }
  };

  const onSearchByNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameFilter(event.currentTarget.value || '');
  };

  const lblSearch = _.capitalize(t(TXT_SEARCH_BY_NAME));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const onEdit = (port: portModel) => {
    setSelectedPorts(port);
    setOpenEditor(true);
  };

  const onDelete = (port: portModel) => {
    setSelectedPorts(port);
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const onEditorClosed = () => {
    setOpenEditor(false);
    setSelectedPorts(undefined);
  };

  const openAddEditor = () => {
    setSelectedPorts(undefined);
    setOpenEditor(true);
  };

  let txtConfirmation = t(TXT_DELETE_TEXT, {
    name: selectedPorts?.name
  });
  txtConfirmation = _.capitalize(txtConfirmation);

  return (
    <Container component="main" maxWidth="xl" className={globalStyles.container}>
      {openEditor && <PortEditor {...selectedPorts} onClose={onEditorClosed} afterSave={fetch} />}
      {openDeleteConfirmation && selectedPorts && (
        <DeleteModal
          afterSaving={fetch}
          onCancel={closeDeleteConfirmation}
          txtConfirmation={txtConfirmation}
          id={selectedPorts?.id!}
          mutation={DELETE_PORT}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={portsStyle.content}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={6} xl={6}>
                <CountrySelector onSelectedChange={(country) => setCountryFilter(country)} />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  id="ports-search-by-name"
                  label={lblSearch}
                  variant="outlined"
                  className={globalStyles.searchTextbox}
                  value={nameFilter || ''}
                  onChange={onSearchByNameChanged}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PortsTable
                  loading={loading}
                  error={error}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  showActions={!isGuest}
                />
                <PaginationBar
                  rowsPerPageOptions={PageSizeOptions}
                  count={data && data.ports && data.ports.totalCount ? data.ports.totalCount : 0}
                  page={loading ? 0 : pageNumber - 1}
                  rowsPerPage={pageSize}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  disabled={loading}
                  openAddEditor={!isGuest ? openAddEditor : undefined}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
