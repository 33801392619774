import { gql } from '@apollo/client';
import { interruptionDefinitionModel } from 'model';

export const getInterruptionDefinitionsFragment = `
  items {
    id,
    code,
    operatingCapacity,
    description
  },
  totalCount
`;

export type GetInterruptionDefinitionsOutput = {
  interruptionDefinitions?: {
    items?: [interruptionDefinitionModel];
    totalCount?: number;
  };
};

export const GET_INTERUPTION_DEFINITIONS = gql`
  query getInterruptionDefinitions($take: Int!, $skip: Int!, $where: InterruptionDefinitionFilterInput) {
    interruptionDefinitions(take: $take, skip: $skip, where: $where) {
      ${getInterruptionDefinitionsFragment}
    }
  }
`;
