import { gql } from '@apollo/client';

export type AddInvitationInput = {
  email: string;
};

export const ADD_INVITATION = gql`
  mutation addInvitation($input: AddInvitationInput!) {
    addInvitation(input: $input) {
      id
    }
  }
`;
